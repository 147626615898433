// To make decryption work, we need to use the same (tweaked) THREE
// everywhere
//import * as THREE from 'three'

let THREE = null

const insert_GLSLBefore = (GLSLSource, GLSLSearched, GLSLInserted) => {
    return GLSLSource.replace(GLSLSearched, GLSLInserted + '\n' + GLSLSearched)
}

const insert_GLSLAfter = (GLSLSource, GLSLSearched, GLSLInserted) => {
    return GLSLSource.replace(GLSLSearched, GLSLSearched + '\n' + GLSLInserted)
}

const tweak_vertexShaderSource = (vertexShaderSource) => {
    let src = vertexShaderSource
    src = insert_GLSLBefore(
        src,
        'void main()',
        'uniform mat4 uMaskMatrix; varying float vZOcclusion;'
    )
    src = insert_GLSLAfter(
        src,
        '#include <project_vertex>',

        `vec4 positionInMaskRef = uMaskMatrix * vec4(transformed, 1.0);
    vZOcclusion = positionInMaskRef.z;
    `
    )
    return src
}

const tweak_fragmentShaderSource = (fragmentShaderSource) => {
    let src = fragmentShaderSource
    src = insert_GLSLBefore(
        src,
        'void main()',
        'uniform vec2 uZOccluderRange; varying float vZOcclusion;'
    )
    src = insert_GLSLAfter(
        src,
        '#include <fog_fragment>',
        `gl_FragColor.a *= smoothstep(uZOccluderRange.x, uZOccluderRange.y, vZOcclusion);
        //gl_FragColor.a = 0.5; // debug: see if opacity is taken into account`
    ) // apply occlusion
    return src
}

const add_ZSmoothOcclusion = (
    mesh,
    maskMatrixWorld,
    zOcclusionStart,
    zOcclusionStop
) => {
    // tweak material
    let mat = mesh.material

    // compute uniforms values:
    const uZOccluderRange = new THREE.Vector2(zOcclusionStart, zOcclusionStop)
    const uMaskMatrixValue = new THREE.Matrix4()

    if (mat.userData.ZSmoothOccluderUniforms) {
        // just update uniforms
        const uniforms = mat.userData.ZSmoothOccluderUniforms
        uniforms.uZOccluderRange.value = uZOccluderRange
        uniforms.uMaskMatrix.value = uMaskMatrixValue
    } else {
        // recompile and tweak mat
        // if we don't do that the material do not recompile:
        mat = mat.clone()
        mesh.material = mat

        mat.needsUpdate = true
        mat.transparent = true
        const ZSmoothOccluderUniforms = {
            uZOccluderRange: {
                value: uZOccluderRange,
            },
            uMaskMatrix: {
                value: uMaskMatrixValue,
            },
        }
        mat.userData.ZSmoothOccluderUniforms = ZSmoothOccluderUniforms
        mat.onBeforeCompile = (shader, renderer) => {
            shader.vertexShader = tweak_vertexShaderSource(shader.vertexShader)
            shader.fragmentShader = tweak_fragmentShaderSource(
                shader.fragmentShader
            )
            // debug a specific mat:
            Object.assign(shader.uniforms, ZSmoothOccluderUniforms)
        }
    }

    // update matrix
    //if (!mesh.onBeforeRender){
    mesh.onBeforeRender = () => {
        uMaskMatrixValue
            .copy(maskMatrixWorld)
            .invert()
            .multiply(mesh.matrixWorld)
    }
    //}
}

const is_candidate = (node) => {
    return (
        (node.isMesh || node.isSkinnedMesh) &&
        node.name !== 'occluder' &&
        (!node.userData || !node.userData.isOccluder)
    )
}

const remove_ZSmoothOcclusion = (model) => {
    model.traverse(function (node) {
        if (!is_candidate(node)) {
            return
        }

        const mat = node.material
        if (mat && mat.userData && mat.userData.ZSmoothOccluderUniforms) {
            mat.onBeforeCompile = () => {}
            mat.needsUpdate = true
            mat.userData.ZSmoothOccluderUniforms = null
            node.onBeforeRender = () => {}
        }
    })
}

const is_anySubMeshWithNameChunk = (threeObj, nameChunk) => {
    let isSuccess = false
    threeObj.traverse((threeNode) => {
        if (!isSuccess && threeNode.name) {
            isSuccess = threeNode.name.includes(nameChunk)
        }
    })
    return isSuccess
}

const init = (model, spec, three) => {
    THREE = three

    if (!spec) {
        remove_ZSmoothOcclusion(model)
        return
    }

    const zOcclusionStart = spec.zEdge - spec.zSmooth * 0.5
    const zOcclusionStop = spec.zEdge + spec.zSmooth * 0.5

    const selectiveNameChunk = '_ZSmooth'
    const isSelective = is_anySubMeshWithNameChunk(model, selectiveNameChunk)

    model.traverse(function (node) {
        if (
            is_candidate(node) &&
            (!isSelective || node.name.includes('_ZSmooth'))
        ) {
            add_ZSmoothOcclusion(
                node,
                model.matrixWorld,
                zOcclusionStart,
                zOcclusionStop
            )
        }
    })
}

export default {
    init,
}
