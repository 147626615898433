import { StarfoxHandler } from '../starfox'
import { SessionActiveEnum } from '../../store/session/types'
import { useCallback, useEffect } from 'react'
import store, { actions } from '../../store'
import { useDispatch } from 'react-redux'
import { HighlightScreenStatus } from '../../state/screen'
import { setGameLobbyError } from '../../store/session/actions'
import { GameLobbyErrorType } from '../../state/alert'
import screenSlice from '../../store/screen/screen'

export const useStarfox = () => {
    const dispatch = useDispatch()

    const fn = useCallback(async () => {
        // first initialize core via starfox. By contract, when starfox.init returns, the server should be ready (or in error state).
        const starfoxHandler = new StarfoxHandler()
        const response = await starfoxHandler.run()

        if (response === SessionActiveEnum.Failed) {
            // Handle connection failure if starfox.run didn't go well, we close the session and redirect to the Home Page
            dispatch(
                screenSlice.actions.setHighlightScreenStatus(
                    HighlightScreenStatus.Failed
                )
            )
        } else if (response === SessionActiveEnum.CannotConnect) {
            // Handle connection not sure (maybe timeout or user doesn't had a disconnection), it could just need refresh
            dispatch(
                screenSlice.actions.setHighlightScreenStatus(
                    HighlightScreenStatus.Disconnected
                )
            )
        } else if (response === SessionActiveEnum.Forbidden) {
            // Handle connection forbidden
            dispatch(
                setGameLobbyError(GameLobbyErrorType.CREATE_ROOM_FORBIDDEN)
            )
        }
        store.dispatch(actions.session.setSessionActive(response))
    }, [dispatch])
    useEffect(() => {
        fn()
        return () => {
            // TODO: cleanup starfox state here. Useful to avoid page refresh when
            // changing app in the future.
        }
    }, [fn])
}
