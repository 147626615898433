import React, { useCallback, useRef, useEffect, useState } from 'react'
import store, { actions } from '../../store'
import { piepie, isEnvDev } from '../../utils'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import DragAndDrop from '../../components/DragAndDrop'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { PieProgress } from '../../components/ui'
import { ConfirmDialog } from '../../state/confirm'
import ConfirmModal from '../../components/ConfirmModal'
import { byog_config } from './config'
import PiereaderHandler from '../../handlers/PiereaderHandler'
import md5 from 'crypto-js/md5'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { Alert, Button } from '../../stories'

import { AlertType } from '../../state/alert'
// TODO: 2 Feb 2022 - We decided to allow all users to use BYOG games untill late 2022
// import { isPremium } from '../../utils/subscription'
import { LocalStorage } from '../../state/storage'

const getFileExtension = (fileName: string): string => {
    const pattern = /\.(tar\.)?[0-9a-z]+$/i
    const [extension] = fileName.match(pattern) ?? ['']
    return extension.toLowerCase()
}

function BYOG() {
    const history = useHistory()
    const { t } = useTranslation()

    const [progress, setProgress] = useState(0)
    const [transfer, setTransfer] = useState(false)
    const [isNoteOpen, setIsNoteOpen] = useState(true)
    const [progressMessage, setProgressMessage] = useState('')
    const [pieReaderHandler, setPieReaderHandler] =
        useState<PiereaderHandler>(null)
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )

    const user = useSelector((state: State) =>
        state.user.extra ? state.user.extra : null
    )
    const features = useSelector((state: State) => state.user.features)

    const isFetched = useRef(false)
    const uploadTask = useRef(null)
    const [system, setSystem] = useState(null)
    const systemRef = useRef(null)
    const inputFileRef = useRef(null)

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
    }, [])

    // extract the file type
    // we upload the game file
    const uploadGame = useCallback(
        async (gameFile: File, piereader = false) => {
            // we check if the file type is allowed
            const fileType = getFileExtension(gameFile.name)
            if (
                !piereader &&
                !byog_config.acceptableTypes[systemRef.current].includes(
                    fileType
                )
            ) {
                piepie.error('[BYOG] imported file not allowed:', fileType)
                inputFileRef.current.value = ''
                setConfirmDialog(ConfirmDialog.BYOGWrongType)
                return
            }
            if (
                !piereader &&
                byog_config.maxsize[systemRef.current] < gameFile.size
            ) {
                piepie.error('[BYOG] imported file too large:', gameFile.size)
                inputFileRef.current.value = ''
                setConfirmDialog(ConfirmDialog.BYOGTooLarge)
                return
            }
            // we convert the file name into base64
            piepie.log(`[BYOG] original file name: ${gameFile.name}`)
            const origin = gameFile.name
            const gameNameMD5 = md5(gameFile.name).toString()
            gameFile = new File([gameFile], gameNameMD5 + fileType)
            store.dispatch(actions.session.setGameName(gameNameMD5))
            store.dispatch(actions.session.setGameFile(gameFile.name))
            store.dispatch(actions.session.setOriginalFileName(origin))
            localStorage.setItem('gameFile', gameFile.name)
            store.dispatch(actions.session.setBYOG(true))
            localStorage.setItem('byog', 'true')
            piepie.log('[BYOG] file imported successfully:', gameFile.name)
            analytics.track('BYOG file imported', {
                game: gameFile.name,
                origin: origin,
                system: systemRef.current,
            })

            // Create firebase reference of the file
            const storageRef = firebase.storage().ref()
            const uid = store.getState().user.user.uid
            const byogRef = storageRef.child(
                `user/${uid}/roms/${gameFile.name}`
            )
            // Query the metadata
            const metadata = await byogRef.getMetadata().catch((e) => {})
            if (
                metadata &&
                metadata.size === gameFile.size &&
                metadata.customMetadata
            ) {
                // We have a firebase file with the same size, it shall be same content right ?
                // let's play right away
                history.push(`/game/${byog_config.session[systemRef.current]}`)
                return
            }
            // Otherwise we upload the file, time to get a coffee
            uploadTask.current = byogRef.put(gameFile)
            setProgressMessage(t('BYOGUploadProgress'))
            uploadTask.current.on(
                'state_changed',
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    piepie.log('[BYOG] upload is ' + progress + '% done')
                    setProgress(progress)
                },
                (error) => {
                    piepie.error('[BYOG] file upload failure:', error)
                    setConfirmDialog(ConfirmDialog.BYOGUploadFailed)
                    setProgress(0)
                },
                () => {
                    const metadata = {
                        customMetadata: {
                            origin: `${origin}`,
                        },
                    }
                    byogRef.updateMetadata(metadata).catch(() => {
                        return
                    })
                    piepie.log('[BYOG] file uploaded successfully')
                    // let's play
                    history.push(
                        `/game/${byog_config.session[systemRef.current]}`
                    )
                }
            )
        },
        [history, t]
    )

    // we get the file then we upload it
    const importHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = Array.from(e.target.files)
            piepie.log('[BYOG] imported files:', files)
            // we get the file name and we save it in the redux store
            const gameFile = files[0]
            uploadGame(gameFile)
        },
        [uploadGame]
    )

    const importClickHandler = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }, [])

    const pieReaderClickHandler = useCallback(async () => {
        // we start the transfer only if the pairing is ok
        if (await pieReaderHandler.request()) {
            setProgressMessage(t('PiereaderTransfer'))
            setTransfer(true)
            const file = await pieReaderHandler.transfer(
                byog_config.piereader[systemRef.current]
            )
            uploadGame(file, true)
        }
    }, [pieReaderHandler, uploadGame, t])

    useEffect(() => {
        if (!isFetched.current) {
            isFetched.current = true
            // we get the console name from the URL
            systemRef.current = decodeURIComponent(window.location.href).split(
                '/byog?'
            )[1]
            if (
                byog_config.cores[systemRef.current] &&
                byog_config.whitelisted[systemRef.current]
            ) {
                store.dispatch(
                    actions.session.setGameCore(
                        byog_config.cores[systemRef.current]
                    )
                )
                setSystem(systemRef.current)
            } else {
                // if the console does not exists, we redirect the user to the home page
                piepie.error(
                    '[BYOG] error console does not exist:',
                    systemRef.current
                )
                history.push('/')
                return
            }
            // TODO: 2 Feb 2022 - We decided to allow all users to use BYOG games untill late 2022
            // if (!isPremium(user) && !features.BYOG) {
            //     // if the user is not a ks/premium user, we redirect him/her to the home page
            //     // it prevents from accessing just through the URL
            //     piepie.error(
            //         '[BYOG] error not a enabled for BYOG:',
            //         user?.Role,
            //         '|| Features.BYOG is',
            //         features.BYOG
            //     )
            //     history.push('/')
            //     return
            // }
            piepie.log('[BYOG] accessing BYOG page:', systemRef.current)

            // we setup the file input
            inputFileRef.current = document.createElement('input')
            inputFileRef.current.setAttribute('type', 'file')
            inputFileRef.current.onchange = importHandler
            inputFileRef.current.setAttribute(
                'accept',
                byog_config.acceptableTypes[systemRef.current].join(',')
            )

            // set the piereader handler
            setPieReaderHandler(new PiereaderHandler())
            return () => {
                // on unmount we cancel the upload if there is one
                if (pieReaderHandler) pieReaderHandler.deinit()
            }
        }
    }, [history, pieReaderHandler, user, features, importHandler])

    useEffect(() => {
        // clean session ID from storage on boot
        localStorage.removeItem(LocalStorage.sessionID)
        return () => {
            // on unmount we cancel the upload if there is one
            if (uploadTask.current) uploadTask.current.cancel()
        }
    }, [])

    return (
        <div className={style.byogZone}>
            {!progress && !transfer ? (
                <DragAndDrop setFile={uploadGame}>
                    <div className={style.byogContent}>
                        <img
                            src={`${process.env.PUBLIC_URL}/cloud_upload.svg`}
                            alt=""
                            className={style.byogCloud}
                        />
                        <div className={style.byogDragAndDrop}>
                            {t('BYOGDragAndDrop')}
                        </div>

                        {system && (
                            <div className={style.byogAcceptableFiles}>
                                {t('BYOGAcceptableFiles')}{' '}
                                {byog_config.acceptableTypes[system].join(', ')}
                            </div>
                        )}
                        <div className={style.byogOr}>{t('BYOGOr')}</div>
                        <Button
                            variant="ellipticalLarge"
                            onClick={importClickHandler}
                            className={style.importBtn}
                            id="signinbtn"
                        >
                            {t('BYOGUploadGame')}
                        </Button>
                        <br />
                    </div>
                    {isEnvDev && (
                        <div
                            className={style.piereader}
                            onClick={pieReaderClickHandler}
                        >
                            <span className={style.piereaderText}>
                                {t('PiereaderClick')}
                            </span>
                        </div>
                    )}
                </DragAndDrop>
            ) : (
                <div className={style.byogLoading}>
                    <img
                        src={`${process.env.PUBLIC_URL}/piepie_loading.gif`}
                        alt=""
                        className={style.piepieLoading}
                    />
                    {progress > 0 && (
                        <PieProgress
                            bgColor={'primary'}
                            completed={progress}
                            display={true}
                            text={true}
                            className={style.byogProgressBar}
                            message={progressMessage}
                        />
                    )}
                </div>
            )}
            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalClose}
                onClose={onConfirmModalClose}
            />
            {isNoteOpen && (
                <Alert
                    className={style.byogAlert}
                    type={AlertType.Warning}
                    message="BYOGExperimentalAlert"
                    autoClose={false}
                    // TODO: with animate false onClose function is not being called.
                    // animate={false}
                    onClose={() => setIsNoteOpen(false)}
                />
            )}
        </div>
    )
}

export default BYOG
