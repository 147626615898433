import React, { useState, useEffect } from 'react'

import './app.scss'

import { connect, useDispatch } from 'react-redux'
import { isBrowser, isEdge, isMobile } from 'react-device-detect'
import {
    isChromeTooOld,
    piepie,
    MARIO_API_URL,
    isMobileEnabled,
    isChromewithH264,
    getGeoInfo,
    isEnvDev,
    isEnvStaging,
} from './utils'
import { sessionAvailable } from './starfox/starfox'
import store, { actions, types } from './store'
import analytics from './analytics'
import * as Axios from 'axios'

import Router from './Router'

import { SignedInState } from './state/app'
import { UnavailableReasonEnum } from './state/unavailable'

import i18next from 'i18next'
import { listenAuthChange, refreshFirebaseToken } from './firebase'
import { LocalStorage } from './state/storage'
import { logAxiosErrorResponse } from './utils/http'
import { setReason, setSignedIn } from './store/home/homeV2'

let _serverListReadyCalled = false

function App({ saveUser }) {
    const dispatch = useDispatch()
    const [signedInState, setSignedInState] = useState(
        SignedInState.NotSignedIn
    )
    const [isSignInStateKnown, setSignInStateKnown] = useState(false)
    const [unavailableReason, setUnavailableReason] = useState(
        UnavailableReasonEnum.Waiting
    )

    // PART 1 --- EFFECTS
    useEffect(() => {
        listenAuthChange((user) => {
            if (!user) {
                piepie.error(
                    'listenAuthChange callback should not be called with empty user'
                )
                return
            }
            setSignedInState(
                user.isAnonymous
                    ? SignedInState.SignedInAnonymously
                    : SignedInState.SignedIn
            )
            dispatch(
                setSignedIn(
                    user.isAnonymous
                        ? SignedInState.SignedInAnonymously
                        : SignedInState.SignedIn
                )
            )
            user.getIdToken(true).then((idToken) => {
                localStorage.setItem(LocalStorage.idToken, idToken)

                if (!user.isAnonymous) {
                    Axios.default
                        .get(`${MARIO_API_URL}/users/features`, {
                            headers: {
                                authorization: `Bearer ${idToken}`,
                            },
                        })
                        .then((result) => {
                            store.dispatch(
                                actions.user.setFeatures(result?.data?.features)
                            )
                        })
                        .catch((err) => {
                            // TODO: Cannot access the features for this user
                            logAxiosErrorResponse(err)
                        })
                }
            })
            analytics.identify(user.uid, {
                name: user.displayName,
                email: user.email,
                anonymous: user.isAnonymous,
            })
            if (!_serverListReadyCalled) {
                // avoid calling this again if already called
                _serverListReadyCalled = true
                const f = async () => {
                    const serverListReady = await sessionAvailable()
                    store.dispatch(
                        actions.home.setServerListReady(serverListReady)
                    )
                }
                f()
            }
            setSignInStateKnown(true)
            saveUser(user)
        })
    }, [saveUser, setSignedInState, dispatch])

    useEffect(() => {
        const milliseconds = 60 * 50 * 1000
        const interval = setInterval(() => {
            refreshFirebaseToken()
        }, milliseconds)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        piepie.log('Commit: ', process.env.REACT_APP_TAG)
        // setup analytics
        analytics.init(process.env.REACT_APP_ENV)
    }, [])

    useEffect(() => {
        const unavailable = isUnavailable(signedInState)
        setUnavailableReason(unavailable)
        dispatch(setReason(unavailable))
    }, [signedInState, setUnavailableReason, dispatch])

    useEffect(() => {
        const language =
            i18next.language ||
            window.localStorage.getItem(LocalStorage.i18nextLng)
        i18next.init({
            keySeparator: '^',
            nsSeparator: '|',
        })

        piepie.log('language : ', language)

        document.getElementsByTagName('html')[0].setAttribute('lang', language)
        getGeoInfo().then((payload) => {
            piepie.log(payload)
            if (payload?.data !== 'US' && !isEnvDev && !isEnvStaging) {
                const script = document.createElement('script')
                script.src =
                    '//cdn.cookie-script.com/s/6fe8f4030d00239e9e33c44cb95d981c.js'
                script.async = true
                script.charset = 'UTF-8'
                document.body.appendChild(script)
                return () => {
                    document.body.removeChild(script)
                }
            }
        })
    }, [])

    // PART 2 --- DISPLAY MODIFICATIONS DUE TO UNAVAILABLE

    // don't display while waiting or if not signed in, at least anonymously
    if (
        unavailableReason === UnavailableReasonEnum.Waiting ||
        signedInState === SignedInState.NotSignedIn
    ) {
        return <></>
    }

    // unsupported region, device or browser means redirect
    if (
        unavailableReason === UnavailableReasonEnum.UnsupportedDevice ||
        unavailableReason === UnavailableReasonEnum.MobileForbidden ||
        unavailableReason === UnavailableReasonEnum.UnsupportedBrowser
    ) {
        const redirectMessage = (() => {
            switch (unavailableReason) {
                case UnavailableReasonEnum.UnsupportedDevice:
                    return 'DeviceNotSupported'
                case UnavailableReasonEnum.UnsupportedBrowser:
                    return 'UnsupportedBrowser'
                case UnavailableReasonEnum.MobileForbidden:
                    return 'MobileForbidden'
                default:
                    return ''
            }
        })()

        window.location.href = `https://jam.gg?redirect=${redirectMessage}`

        return <></>
    }

    return (
        <Router
            signedIn={signedInState}
            reason={unavailableReason}
            isSignInStateKnown={isSignInStateKnown}
        />
    )
}

export default connect(null, (dispatch: types.Dispatch) => ({
    saveUser: (user) => dispatch(actions.user.setUser(user)),
}))(App)

function isUnavailable(isSignedIn: SignedInState): UnavailableReasonEnum {
    if (isMobile && !isMobileEnabled) {
        return UnavailableReasonEnum.MobileForbidden
    }

    if (!isBrowser && !isMobileEnabled) {
        return UnavailableReasonEnum.UnsupportedDevice
    }

    // restrict browser unless you are on mobile
    if (
        !isMobile &&
        !(
            isChromewithH264() ||
            isEdge ||
            navigator.userAgent.includes('HeadlessChrome')
        )
    ) {
        return UnavailableReasonEnum.UnsupportedBrowser
    }

    if (isChromeTooOld()) {
        return UnavailableReasonEnum.UnsupportedBrowserVersion
    }

    switch (isSignedIn) {
        case SignedInState.NotSignedIn:
            return UnavailableReasonEnum.NotSignedIn
        case SignedInState.SignedIn:
        case SignedInState.SignedInAnonymously:
            return UnavailableReasonEnum.None
        default:
            break
    }
}
