import { lazy } from 'react'

export const IconComponents = {
    arrowBack: lazy(() => import('./ArrowBack')),
    arrowDown: lazy(() => import('./ArrowDown')),
    arrowDropdown: lazy(() => import('./ArrowDropdown')),
    arrowLeft: lazy(() => import('./ArrowLeft')),
    arrowRight: lazy(() => import('./ArrowRight')),
    arrowUp: lazy(() => import('./ArrowUp')),
    arrows: lazy(() => import('./Arrows')),
    buttonA: lazy(() => import('./ButtonA')),
    buttonArrows: lazy(() => import('./ButtonArrows')),
    buttonB: lazy(() => import('./ButtonB')),
    buttonCircle: lazy(() => import('./ButtonCircle')),
    buttonDown: lazy(() => import('./ButtonDown')),
    buttonEnter: lazy(() => import('./ButtonEnter')),
    buttonJoystickLeft: lazy(() => import('./ButtonJoystickLeft')),
    buttonJoystickRight: lazy(() => import('./ButtonJoystickRight')),
    buttonL1: lazy(() => import('./ButtonL1')),
    buttonL2: lazy(() => import('./ButtonL2')),
    buttonLeft: lazy(() => import('./ButtonLeft')),
    buttonR1: lazy(() => import('./ButtonR1')),
    buttonR2: lazy(() => import('./ButtonR2')),
    buttonRight: lazy(() => import('./ButtonRight')),
    buttonSelect: lazy(() => import('./ButtonSelect')),
    buttonShift: lazy(() => import('./ButtonShift')),
    buttonSquare: lazy(() => import('./ButtonSquare')),
    buttonStart: lazy(() => import('./ButtonStart')),
    buttonTop: lazy(() => import('./ButtonTop')),
    buttonTriangle: lazy(() => import('./ButtonTriangle')),
    buttonX: lazy(() => import('./ButtonX')),
    buttonY: lazy(() => import('./ButtonY')),
    cached: lazy(() => import('./Cached')),
    cam: lazy(() => import('./Cam')),
    camOff: lazy(() => import('./CamOff')),
    chat: lazy(() => import('./Chat')),
    check: lazy(() => import('./Check')),
    chevronDown: lazy(() => import('./ChevronDown')),
    chevronUp: lazy(() => import('./ChevronUp')),
    close: lazy(() => import('./Close')),
    competitive: lazy(() => import('./Competitive')),
    competitiveMoodHover: lazy(() => import('./CompetitiveMoodHover')),
    competitiveMood: lazy(() => import('./CompetitiveMood')),
    delete: lazy(() => import('./Delete')),
    discovery: lazy(() => import('./Discovery')),
    discoveryMoodHover: lazy(() => import('./DiscoveryMoodHover')),
    discoveryMood: lazy(() => import('./DiscoveryMood')),
    done: lazy(() => import('./Done')),
    dragHandle: lazy(() => import('./DragHandle')),
    edit: lazy(() => import('./Edit')),
    emojis: lazy(() => import('./Emojis')),
    error: lazy(() => import('./Error')),
    events: lazy(() => import('./Events')),
    flag: lazy(() => import('./Flag')),
    fullscreen: lazy(() => import('./Fullscreen')),
    fullscreenOn: lazy(() => import('./FullscreenOn')),
    gamepadConnected: lazy(() => import('./GamepadConnected')),
    gamepadDisconnected: lazy(() => import('./GamepadDisconnected')),
    hermits: lazy(() => import('./Hermits')),
    hermitsMoodHover: lazy(() => import('./HermitsMoodHover')),
    hermitsMood: lazy(() => import('./HermitsMood')),
    home: lazy(() => import('./Home')),
    info: lazy(() => import('./Info')),
    jam: lazy(() => import('./Jam')),
    jamMultiColor: lazy(() => import('./JamMultiColor')),
    load: lazy(() => import('./Load')),
    logout: lazy(() => import('./Logout')),
    mask: lazy(() => import('./Mask')),
    menu: lazy(() => import('./Menu')),
    mic: lazy(() => import('./Mic')),
    micOff: lazy(() => import('./MicOff')),
    paste: lazy(() => import('./Paste')),
    pause: lazy(() => import('./Pause')),
    pausePie: lazy(() => import('./PausePie')),
    people: lazy(() => import('./People')),
    person: lazy(() => import('./Person')),
    personAdd: lazy(() => import('./PersonAdd')),
    piePieOutline: lazy(() => import('./PiePieOutline')),
    piecoin: lazy(() => import('./Piecoin')),
    piePie: lazy(() => import('./PiePie')),
    plainPause: lazy(() => import('./PlainPause')),
    plainPlay: lazy(() => import('./PlainPlay')),
    play: lazy(() => import('./Play')),
    quickSave: lazy(() => import('./QuickSave')),
    remove: lazy(() => import('./Remove')),
    replay: lazy(() => import('./Replay')),
    reset: lazy(() => import('./Reset')),
    resultBack: lazy(() => import('./ResultBack')),
    save: lazy(() => import('./Save')),
    search: lazy(() => import('./Search')),
    send: lazy(() => import('./Send')),
    settings: lazy(() => import('./Settings')),
    social: lazy(() => import('./Social')),
    socialMoodHover: lazy(() => import('./SocialMoodHover')),
    socialMood: lazy(() => import('./SocialMood')),
    success: lazy(() => import('./Success')),
    switchGame: lazy(() => import('./SwitchGame')),
    triangleDown: lazy(() => import('./TriangleDown')),
    twitch: lazy(() => import('./Twitch')),
    videoGame: lazy(() => import('./VideoGame')),
    volumeDown: lazy(() => import('./VolumeDown')),
    volumeOff: lazy(() => import('./VolumeOff')),
    volumeUp: lazy(() => import('./VolumeUp')),
    warning: lazy(() => import('./Warning')),
}
