import {
    SessionFromFirebase,
    UserInterfaceFromFirebase,
} from '../../state/starfox'

export interface Label {
    type: LabelType
    text: string
}

export enum LabelType {
    Language,
    Distance,
    Tag,
    Extra,
}

export enum RoomDistance {
    None = 'PublicRoomsDistanceUndefined',
    Close = 'PublicRoomsCloseToYou',
    Far = 'PublicRoomsFarFromYou',
    VeryFar = 'PublicRoomsVeryFarFromYou',
}

export interface FilteredRoomType {
    session: SessionFromFirebase
    players: UserInterfaceFromFirebase[]
}

export interface CachedRoomType {
    sessionId: string
    players: UserInterfaceFromFirebase[]
    lastUpdate: number
}

export enum JoinErrorType {
    None = '',
    Full = 'PublicRoomsJoinErrorFull',
    NotAvailable = 'PublicRoomsJoinErrorNotAvailable',
}
