import React, { useEffect, useRef, useState, FC } from 'react'
import { useSelector } from 'react-redux'
import { saveChatMessage } from '../../../firebase'
import { State } from '../../../store/types'
import { unsubscribeToChatMessage } from './unsubscribeToChatMessage'

import {
    Button,
    ChatDialog,
    ChatInput,
    Icon,
    MobileGameroomOverlay,
} from '../../../stories'
import style from './style.module.scss'
import { sleep, useDeviceOrientation } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { NicknameColorType } from '../../../state/starfox'
import { UsersDataType } from '../MobileGameRoom'

interface MobileChatOverlayProps {
    orientation?: string
    usersData: UsersDataType[]
    setShow?: (x: boolean) => void
}

export const MobileChatOverlay: FC<MobileChatOverlayProps> = ({
    setShow,
    usersData,
}) => {
    const [value, setValue] = useState('')
    const { t } = useTranslation()
    const scrollRef = useRef(null)
    const orientation = useDeviceOrientation()

    const [messageGroups, setMessageGroups] = useState([])

    const sessionID = useSelector((state: State) => state.session.sessionID)
    const userSessionID = useSelector(
        (state: State) => state.session.userSessionID
    )

    const playerExtraData = useSelector((state: State) => state.session.players)

    const playerHasGoldLetters = (uid) => {
        const player = usersData?.find((user) => user.uid === uid)?.[0]
        const playerUID = player?.UID
        return (
            playerExtraData[playerUID]?.NicknameColor === NicknameColorType.Gold
        )
    }

    useEffect(() => {
        if (usersData?.length > 0 && messageGroups?.length > 0) {
            const el = scrollRef.current as HTMLElement
            el.scrollTop = el.scrollHeight
        }
    }, [scrollRef, messageGroups, usersData])

    useEffect(() => {
        if (!sessionID) return

        return unsubscribeToChatMessage(
            sessionID,
            userSessionID,
            setMessageGroups
        )
    }, [sessionID, userSessionID])

    const handleChange = (e) => {
        e.preventDefault()
        setValue(e.target.value)
    }

    const sendMessage = async (e) => {
        e.preventDefault()
        saveChatMessage(value, sessionID, userSessionID)
        setValue('')
        await sleep(600)
    }

    return (
        <MobileGameroomOverlay
            transparency="halfBlueTransparency"
            size={
                orientation.includes('portrait')
                    ? 'portraitFullscreen'
                    : 'screen'
            }
        >
            <div className={style.chat}>
                <div className={style.chatPart} id="chat-part" ref={scrollRef}>
                    {usersData?.length > 0 &&
                        messageGroups?.map((msg) => {
                            const userData = usersData?.find(
                                (user) =>
                                    user.userSessionID === msg.UserSessionID
                            )

                            if (msg.fromself) {
                                return (
                                    <ChatDialog
                                        messages={msg.messages}
                                        color="primary"
                                        title={t('You')}
                                        goldLetters={playerHasGoldLetters(
                                            userData?.uid
                                        )}
                                    />
                                )
                            }

                            if (msg.fromself === false) {
                                const userData = usersData?.find(
                                    (user) =>
                                        user.userSessionID === msg.UserSessionID
                                )

                                return (
                                    <ChatDialog
                                        messages={msg.messages}
                                        color="secondary"
                                        title={userData?.nickname}
                                        avatarSrc={userData?.photoUrl}
                                        goldLetters={playerHasGoldLetters(
                                            userData?.uid
                                        )}
                                    />
                                )
                            }
                        })}
                </div>
                <div className={style.chatInputGroup}>
                    <Button variant="elliptical" onClick={() => setShow(false)}>
                        <Icon name="close" />
                    </Button>

                    <ChatInput
                        placeholderMsg="Say something cool!"
                        onChange={handleChange}
                        value={value}
                    />
                    <button
                        onClick={sendMessage}
                        className={style.submitButton}
                    >
                        <Icon name="send" />
                    </button>
                </div>
            </div>
        </MobileGameroomOverlay>
    )
}
