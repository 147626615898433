// To make decryption work, we need to use the same (tweaked) THREE
// everywhere
//import * as THREE from 'three'
let THREE = null

const _defaultSpec = {
    threeObject3D: null,

    nameChunk: '_faceSmoothOccluded',
    center: [0.0, 45.0, -50.0], // occluder ellipsoid center position in face follower ref
    scale: [140.0, 160.0, 160.0], // occluder ellipsoid width, height, depth
    dr: 100.0,

    isDebug: false,
}
let _spec = null
let _previousDebugOccluder = null

const insert_before = (str, search, insert) => {
    return str.replace(search, '\n' + insert + '\n' + search)
}

const replace_last = (str, search, replace) => {
    return str.replace(
        new RegExp(search + '([^' + search + ']*)$'),
        replace + '$1'
    )
}

const format_GLSLVec3 = (u) => {
    const prec = 4
    return (
        'vec3(' +
        u[0].toFixed(prec) +
        ',' +
        u[1].toFixed(prec) +
        ',' +
        u[2].toFixed(prec) +
        ')'
    )
}

const tweak_material = (
    modelToFaceFollowerMatrix,
    worldToFaceFollowerMatrix,
    shader
) => {
    let { fragmentShader, vertexShader, uniforms } = shader

    // add uniforms:
    Object.assign(uniforms, {
        modelToFaceFollowerMatrix: {
            value: modelToFaceFollowerMatrix,
        },
        worldToFaceFollowerMatrix: {
            value: worldToFaceFollowerMatrix,
        },
        faceFollowerToWorldMatrix: {
            value: _spec.threeObject3D.matrixWorld,
        },
    })

    // tweak vertex shader to send the position in face follower ref as a varying vec3 to the fragment shader:
    const GLSLDeclareVertexUniforms =
        'uniform mat4 modelToFaceFollowerMatrix;\n'
    const GLSLDeclareVaryings = 'varying vec3 vFaceFollowerPos;\n'
    vertexShader = insert_before(
        vertexShader,
        'void main() {',
        GLSLDeclareVertexUniforms + GLSLDeclareVaryings
    )

    // compute face follower position in the vertex shader and assign the varying:
    const GLSLComputeFaceFollowerPos =
        'vFaceFollowerPos = (modelToFaceFollowerMatrix * vec4(transformed, 1.0)).xyz;\n'
    vertexShader = insert_before(
        vertexShader,
        '#include <project_vertex>',
        GLSLComputeFaceFollowerPos
    )

    // tweak fragment shader to get vFaceFollowerPos and declare constants and uniforms:
    const GLSLDeclareFragmentConstants =
        'const vec3 OCCLUDER_CENTER = ' +
        format_GLSLVec3(_spec.center) +
        ';\n' +
        'const vec3 OCCLUDER_SCALE = ' +
        format_GLSLVec3(_spec.scale) +
        ';\n' +
        'const float OCCLUDER_DMAX = ' +
        _spec.dr.toFixed(4) +
        ';\n'
    const GLSLDeclareFragmentUniforms =
        'uniform mat4 worldToFaceFollowerMatrix, faceFollowerToWorldMatrix;\n'
    fragmentShader = insert_before(
        fragmentShader,
        'void main() {',
        GLSLDeclareFragmentConstants +
            GLSLDeclareFragmentUniforms +
            GLSLDeclareVaryings
    )

    // tweak fragment shader to compute distance to ellipsoid, i.e. distanceToOccluder:
    //   we do not compute the exact distance to the ellipsoid since it will be costly
    //   since the axis length (scale) are close we assume that the distance between rendered point P to the surface
    //   is the distance between P and the intersection (P, center) and the ellipsoid
    //
    // All coordinates are in the face follower ref. We note:
    //   P the currently rendered point (P = vFaceFollowerPos)
    //   Pw is P in world ref
    //   C the center of the ellipsoid (C = OCCLUDER_CENTER)
    //   Cw is C is world ref
    //   Uw so that Uw = (Pwx - Cwx, Pwy - Cwy, 0), in world ref
    //   U is Uw in face follower ref
    //   J is a point on the ellipsoid so that J = C + beta * U, beta scalar (J is on the contour)
    //   S the size of half axis (S = OCCLUDER_SCALE)

    const GLSLComputeDistanceToOccluder = [
        'vec4 Pw = faceFollowerToWorldMatrix * vec4(vFaceFollowerPos, 1.0);', // P in world ref
        'vec4 Cw = faceFollowerToWorldMatrix * vec4(OCCLUDER_CENTER, 1.0);', // C in world ref
        'vec4 Uw = (Pw - Cw) * vec4(1.0, 1.0, 0.0, 1.0);', // apply a mask to cancel Z (depth axis)
        'vec4 U = worldToFaceFollowerMatrix * Uw;',
        'vec3 UOverS = U.xyz / OCCLUDER_SCALE;', // component wise division
        'float beta = 1.0 / sqrt( dot(UOverS, UOverS) );', // beta can be beta or -beta
        'vec3 J0w = Cw.xyz + beta * Uw.xyz;', // first candidate for J in world ref
        'vec3 J1w = Cw.xyz - beta * Uw.xyz;', // second candidate for J in world ref
        'float d0 = distance(J0w.xy, Pw.xy);', // distance between J0 and P along world X,Y axis
        'float d1 = distance(J1w.xy, Pw.xy);', // distance between J1 and P along world X,Y axis
        'float distanceToOccluder = min(d0, d1);',
        'distanceToOccluder *= step(abs(beta), 1.0);', // set distance to 0 if inside
        'float isForwardOccluder = step(Cw.z, Pw.z);', // look if the point is not above the occluder
        'distanceToOccluder = mix(distanceToOccluder, OCCLUDER_DMAX, isForwardOccluder);', // set distance to DMAX if point is above the occluder
        '',
    ].join('\n')

    // tweak fragment shader to change alpha channel value:
    const GLSLTweakAlpha =
        'gl_FragColor.a *= smoothstep(0.0, OCCLUDER_DMAX, distanceToOccluder);\n'
    fragmentShader = replace_last(
        fragmentShader,
        '}',
        GLSLComputeDistanceToOccluder + GLSLTweakAlpha + '}'
    )

    shader.fragmentShader = fragmentShader
    shader.vertexShader = vertexShader
}

const update_modelToFaceFollowerMatrix = (
    threeModel,
    modelToFaceFollowerMatrix,
    worldToFaceFollowerMatrix
) => {
    // update worldToFaceFollowerMatrix:
    worldToFaceFollowerMatrix.copy(_spec.threeObject3D.matrixWorld).invert()

    // update modelToFaceFollowerMatrix:
    modelToFaceFollowerMatrix.copy(worldToFaceFollowerMatrix) // world -> face follower matrix
    modelToFaceFollowerMatrix.multiply(threeModel.matrixWorld)
}

const apply_faceSmoothOcclusion = (threeNode) => {
    console.log('Apply face smooth occlusion to', threeNode.name)
    threeNode.traverse((threeSubNode) => {
        if (threeSubNode.material) {
            console.log('Tweak material to add face smooth occlusion')

            const originalMaterial = threeSubNode.material
            const mat = threeSubNode.material.clone()
            threeSubNode.material = mat
            threeSubNode.userData.originalMaterialNoZSmooth = originalMaterial

            threeSubNode.material.transparent = true
            const modelToFaceFollowerMatrix = new THREE.Matrix4()
            const worldToFaceFollowerMatrix = new THREE.Matrix4()
            threeSubNode.onBeforeRender = update_modelToFaceFollowerMatrix.bind(
                null,
                threeSubNode,
                modelToFaceFollowerMatrix,
                worldToFaceFollowerMatrix
            )
            threeSubNode.material.onBeforeCompile = tweak_material.bind(
                null,
                modelToFaceFollowerMatrix,
                worldToFaceFollowerMatrix
            )
        }
    })
}

const init = (spec, three) => {
    _spec = Object.assign({}, _defaultSpec, spec)
    const { threeObject3D } = _spec

    THREE = three

    if (_previousDebugOccluder && _previousDebugOccluder.parent) {
        _previousDebugOccluder.parent.remove(_previousDebugOccluder)
        _previousDebugOccluder = null
    }

    if (!threeObject3D) {
        return
    }

    if (_spec.isDebug) {
        const geom = new THREE.IcosahedronGeometry(1.0, 3)
        const threeDebugMesh = new THREE.Mesh(
            geom,
            new THREE.MeshNormalMaterial()
        )
        threeDebugMesh.position.fromArray(_spec.center)
        threeDebugMesh.scale.fromArray(_spec.scale)
        _previousDebugOccluder = threeDebugMesh
        threeObject3D.add(threeDebugMesh)
    }

    threeObject3D.traverse((threeNode) => {
        if (!threeNode.name.includes(_spec.nameChunk)) {
            return
        }
        apply_faceSmoothOcclusion(threeNode)
    })
}

export default {
    init,
}
