import React, { FC, useCallback, useEffect, useState } from 'react'
import { PauseOverlay } from './PauseOverlay'
import { Layout } from './Layout'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

import { MobileGameroomOverlay } from '../../../stories'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/types'
import {
    FREE_SAVE_LIMIT,
    isPremium,
    PREMIUM_SAVE_LIMIT,
} from '../../../utils/subscription'
import { Savestates } from './Savestates'
import { loadGame } from '../../../network/socket'
import { formatDate, isByogMode, useDeviceOrientation } from '../../../utils'
import { getSession } from '../../../firebase'
import store, { actions } from '../../../store'
import { globalPubSub, PubSubEvent } from '../../../event/event'
import { SaveStatesType } from '../../../state/saveStates'
import { SelectingGameModal } from './SelectingGameModal'
import { SectionType } from '../MobileGameRoom'
import Reset from './Reset'

interface MobileGameroomOverlayProps {
    gameId: string
    section: SectionType
    setSection: (state) => void
    handleShowRoomSettings: () => void
    isDynamic: boolean
    setIsDynamic: (state) => void
    setShowOptions: (state) => void
}

const MobileGameroomOverlayComponent: FC<MobileGameroomOverlayProps> = ({
    gameId,
    section,
    setSection,
    isDynamic,
    setIsDynamic,
    handleShowRoomSettings,
    setShowOptions,
}) => {
    const savestates = useSelector((state: State) => state.session.saveStates)
    const [saveLimit, setSaveLimit] = useState(FREE_SAVE_LIMIT)
    const features = useSelector((state: State) => state.user.features)
    const [sliderLoading, setSliderLoading] = useState(false)
    const orientation = useDeviceOrientation()

    const dispatch = useDispatch()
    const userExtra = useSelector((state: State) => state.user.extra)
    const user = useSelector((state: State) => state.user.user)
    const [gameName, setGameName] = useState(gameId)

    useEffect(() => {
        if (isPremium(userExtra) || features.UNLIMITEDSAVESTATES) {
            setSaveLimit(PREMIUM_SAVE_LIMIT)
        }
    }, [userExtra, features.UNLIMITEDSAVESTATES])

    useEffect(() => {
        if (isByogMode(gameName)) {
            const fetchSession = async () => {
                const session = await getSession(
                    store.getState().session.sessionID
                )
                const lastGameIndex = session.Games.length - 1
                const byogGameName =
                    'BYOG/' + session.Games[lastGameIndex].GameID
                setGameName(byogGameName)
            }
            fetchSession()
        }
    }, [gameName])

    const refresh = useCallback(async () => {
        const storageRef = firebase.storage().ref()
        const savestatesRef = storageRef.child(
            `/user/${user.uid}/savestates/${gameName}`
        )
        const stt = await savestatesRef.listAll()
        const promises = []

        Object.values(stt.items).forEach((item) => {
            if (item.name.endsWith('.state')) {
                const name = item.name.split('.state')[0]
                if (
                    name.indexOf('auto') === -1 &&
                    name.indexOf('.crc') === -1
                ) {
                    const chunks = name.split('-')
                    const gmtDate = `${chunks[0]}/${formatDate(
                        chunks[1]
                    )}/${formatDate(chunks[2])}`
                    const gmtTime = `${formatDate(chunks[3])}:${formatDate(
                        chunks[4]
                    )}:${formatDate(chunks[5])}`
                    const gmt = new Date(`${gmtDate} ${gmtTime} GMT`)
                    const date = gmt.toLocaleDateString()
                    const time = gmt.toLocaleTimeString()
                    const screenshotsRef = storageRef.child(
                        `/user/${user.uid}/screenshots/${gameName}/${name}.png`
                    )

                    promises.push(
                        screenshotsRef.getDownloadURL().then((url) => {
                            return {
                                name: name,
                                date: date,
                                time: time,
                                gmt: gmt,
                                url: url,
                            }
                        })
                    )
                }
            }
        })

        Promise.all(promises).then((values) => {
            const saves: SaveStatesType[] = []
            values.forEach((v) => {
                saves[v.gmt.getTime()] = {
                    name: v.name,
                    date: v.date,
                    time: v.time,
                    gmt: v.gmt,
                    url: v.url,
                }
            })
            setSliderLoading(false)
            dispatch(actions.session.setSaveStates(saves))
        })
    }, [gameName, user.uid, dispatch])

    useEffect(() => {
        if (!user || !gameName) return
        setSliderLoading(true)
        refresh()

        const savedSub = globalPubSub.sub(
            PubSubEvent.GAME_SAVED,
            () => {
                setTimeout(refresh, 1000)
            },
            0
        )

        return () => {
            savedSub.unsub()
        }
    }, [user, gameName, refresh])

    const itemsList = Object.keys(savestates)
        .sort()
        .reverse()
        .slice(0, saveLimit)

    const children = (itemsList) => {
        switch (section) {
            case 'layout':
                return (
                    <Layout
                        setSection={setSection}
                        isDynamic={isDynamic}
                        setIsDynamic={setIsDynamic}
                    />
                )
            case 'load':
                return (
                    <Savestates
                        setSection={setSection}
                        itemsList={itemsList}
                        loadGame={loadGame}
                        gameName={gameName}
                        user={user}
                        refresh={refresh}
                        sliderLoading={sliderLoading}
                        setShowOptions={setShowOptions}
                    />
                )
            case 'pause':
                return (
                    <PauseOverlay
                        setSection={setSection}
                        handleShowRoomSettings={handleShowRoomSettings}
                    />
                )
            case 'switch':
                return (
                    <SelectingGameModal
                        section={section}
                        setSection={setSection}
                        setShowOptions={setShowOptions}
                    />
                )

            case 'reset':
                return (
                    <Reset
                        setSection={setSection}
                        setShowOptions={setShowOptions}
                    />
                )
            default:
                return
        }
    }

    const transparency = () => {
        if (section === 'pause') return 'transparent'
        if (section === 'load') return 'opac'
        if (section === 'reset') return 'opac'
        if (section === 'layout' && orientation.includes('portrait'))
            return 'opac'
        if (section === 'layout' && orientation.includes('landscape'))
            return 'halfBlackTransparency'
    }

    return (
        <MobileGameroomOverlay
            transparency={transparency()}
            size={
                orientation.includes('landscape') && section === 'layout'
                    ? 'fullscreen'
                    : 'screen'
            }
        >
            {children(itemsList)}
        </MobileGameroomOverlay>
    )
}

export default MobileGameroomOverlayComponent
