import { combineReducers } from 'redux'

import homeReducer from './home/reducer'
import homeSlice from './home/homeV2'
import userReducer from './user/reducer'
import sessionReducer from './session/reducer'
import conferenceReducer from './conference/reducer'
import maskReducer from './mask/reducer'
import modalReducer from './modal/reducer'
import authModalReducer from './auth/reducer'
import { State } from './types'
import inputSlice from './input/input'
import screenSlice from './screen/screen'
import rtcpSlice from './rtcp/rtcp'
import socketSlice from './socket/socket'
import alertSlice from './alert/alert'
import retroSlice from './retro/retro'
import gameroomModalSectionSlice from './gameroomModalSection/gameroomModalSection'
import authSlice from './auth/authV2'

const rootReducer = combineReducers<State>({
    home: homeReducer,
    homeV2: homeSlice.reducer,
    user: userReducer,
    session: sessionReducer,
    rtcp: rtcpSlice.reducer,
    socket: socketSlice.reducer,
    input: inputSlice.reducer,
    screen: screenSlice.reducer,
    conference: conferenceReducer,
    mask: maskReducer,
    alert: alertSlice.reducer,
    modal: modalReducer,
    authModal: authModalReducer,
    retro: retroSlice.reducer,
    gameroomModalSection: gameroomModalSectionSlice.reducer,
    auth: authSlice.reducer,
})

export default rootReducer
