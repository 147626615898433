import React, { FC } from 'react'
import SignUp from './Signup'
import SignUpMenu from './SignUpMenu'
import ResetPassword from './ResetPassword'
import Login from './Login'
import style from './style.module.scss'
import EnterAsGuest from './EnterAsGuest'
import cls from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { closeAuthModal } from '../../store/auth/actions'
import { State } from '../../store/types'
import ConfirmEmail from './ConfirmEmail'
import Banned from './Banned'
import { AuthModalType } from '../../store/auth/types'

const AuthModals: FC = () => {
    const dispatch = useDispatch()
    const { isOpen, onClose, modalType, closable, isTransparent } = useSelector(
        (state: State) => state.authModal
    )

    if (!isOpen) {
        return null
    }

    const onCloseClick = () => {
        if (onClose) {
            onClose()
        }
        dispatch(closeAuthModal())
    }

    const Modals = {
        [AuthModalType.Login]: <Login />,
        [AuthModalType.SignUp]: <SignUp />,
        [AuthModalType.SignUpMenu]: <SignUpMenu />,
        [AuthModalType.ResetPassword]: <ResetPassword />,
        [AuthModalType.EnterAsGuest]: <EnterAsGuest />,
        [AuthModalType.ConfirmEmail]: <ConfirmEmail />,
        [AuthModalType.Banned]: <Banned />,
    }

    if (modalType === AuthModalType.Banned) {
        return Modals[modalType]
    }

    return (
        <div
            className={cls(style.root, { [style.transparent]: isTransparent })}
        >
            {closable && (
                <button className={style.closeButton} onClick={onCloseClick}>
                    <CloseIcon />
                </button>
            )}

            {Modals[modalType]}
        </div>
    )
}

export default AuthModals
