import React from 'react'
import { useTranslation } from 'react-i18next'
import { IRedeemedItem } from './types'

interface IRedeemSuccess {
    redeemedItems: IRedeemedItem[]
}

const RedeemSuccess: React.FC<IRedeemSuccess> = ({ redeemedItems }) => {
    const { t } = useTranslation()
    return (
        <>
            <img
                className="redeemcode_modal_image"
                src="https://storage.googleapis.com/public-wagashi/random/Piepiemoji-Wink.svg"
                alt=""
            />
            <p className="redeemcode_modal_description">{`${t(
                'RedeemModalMessageSuccess'
            )} ${redeemedItems
                ?.reduce((acc, current) => {
                    acc.push(t(`${current.Type}_${current.ID}`))
                    return acc
                }, [])
                .join(', ')}`}</p>
        </>
    )
}

export default RedeemSuccess
