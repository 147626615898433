import React, { FC } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import style from '../styles.module.scss'
import { Button, Icon, Input } from '../../../stories'
import { useAlert } from '../useAlert'
import { FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { setCurrentView } from '../../../store/auth/authV2'
import { AuthModalType } from '../../../store/auth/types'

const Password: FC<{ formik: FormikProps<{ email: string }> }> = ({
    formik,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    useAlert()

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        isSubmitting,
    } = formik

    const { email } = values

    const handleBackClick = () => dispatch(setCurrentView(AuthModalType.Login))

    return (
        <div>
            <div className={style.titleWrapper}>
                <div className={style.flexAlign}>
                    <div
                        onClick={handleBackClick}
                        className={style.topBackArrow}
                    >
                        <Icon name="arrowBack" />
                    </div>
                    <h1 className={style.title}>{t('ResetPasswordTitle')}</h1>
                </div>
            </div>
            <div className={style.subtitle}>
                {t('EnterEmailAssociatedWithAccount')}
            </div>
            <form className={style.form} onSubmit={handleSubmit}>
                <div className={style.inputWrapper}>
                    <div>
                        <label>{t('EmailAddress')}</label>
                        <Input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            placeholder={t('EmailAddress')}
                        />
                        {touched.email && errors.email && (
                            <div className={style.errorMsg}>{errors.email}</div>
                        )}
                    </div>
                </div>
                <Button
                    className={cls(style.btn, style.submitButton)}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {t('RequestReset')}
                </Button>
            </form>
        </div>
    )
}

export default Password
