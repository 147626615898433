import { Dispatch } from 'react'

export enum ScreenType {
    NONE,
    LOCAL,
    CLOUD,
    UNITY,
}

export interface ScreenProps {
    gameScreenWidth?: number
    setFullscreen?: () => void
    leavingGame: () => void
    volume?: number
    updateVolume?: (state: number) => void
}

export interface ScreenMenuProps {
    setFullscreen: () => void
}

export interface InnerScreenProps {
    gameScreenWidth?: number
    setFullscreen?: () => void
    leavingGame: () => void
    initialControlOverlay: boolean
    setInitialControlOverlay: Dispatch<boolean>
    idleState: boolean
    shouldShowSavestates: () => boolean
    volume: number
    updateVolume: (volume: number) => void
    onCloseAlert: () => void
    handleActive: () => void
    handleIdle: () => void
    resume: () => void
    pause: () => void
}
