import { useEffect, RefObject } from 'react'

export const useClickOutside = (
    ref: RefObject<HTMLDivElement>,
    handler: () => void
): void => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [ref, handler])
}
