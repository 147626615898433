import { useEffect } from 'react'
import { AlertType } from '../../../state/alert'
import store from '../../../store'
import alertSlice from '../../../store/alert/alert'

/**
 * Detects if a user is offline and displays appropriate notification.
 */
export const useIsOnline = () => {
    useEffect(() => {
        const updateOnlineStatus = () => {
            store.dispatch(
                alertSlice.actions.push({
                    type: AlertType.Success,
                    message: 'InternetConnected',
                })
            )
        }

        const updateOfflineStatus = () => {
            store.dispatch(
                alertSlice.actions.push({
                    type: AlertType.Error,
                    message: 'InternetDisconnected',
                })
            )
        }

        window.addEventListener('online', updateOnlineStatus)
        window.addEventListener('offline', updateOfflineStatus)

        return () => {
            window.removeEventListener('online', updateOnlineStatus)
            window.removeEventListener('offline', updateOfflineStatus)
        }
    }, [])
}
