import React from 'react'

import { Form } from 'react-bootstrap'

export const InputText = ({ field, form: { touched, errors }, ...rest }) => (
    <>
        <Form.Control
            {...field}
            {...rest}
            isInvalid={touched[field.name] && errors[field.name]}
        />
        {errors[field.name] && (
            <Form.Control.Feedback type="invalid">
                {errors[field.name]}
            </Form.Control.Feedback>
        )}
    </>
)
