import styles from './styles.module.scss'
import React, { useEffect, useState } from 'react'
import { State } from '../../store/types'
import { useTranslation } from 'react-i18next'
import { Tabs, LimitedTextArea, Button, Alert, Tooltip } from '../../stories'
import { RoomAccess } from './constants'
import { ISessionSettings } from '.'
import { SessionMoodType } from '../../state/starfox'
import { LanguageSelect } from './LanguageSelect'
import { AlertType } from '../../state/alert'
import { getTransactionEmailServiceHostname } from '../../utils'
import * as Axios from 'axios'
import { logAxiosErrorResponse } from '../../utils/http'
import { useSelector } from 'react-redux'

interface RoomSettingsProps {
    onConfirm?: (settings: ISessionSettings) => void
    roomSettings: ISessionSettings
    onChange?: (settings: ISessionSettings) => void
    disabled?: boolean
    showCameraMessage?: boolean
    offensiveWords?: boolean
}

const RoomSettings: React.FC<RoomSettingsProps> = ({
    onConfirm,
    roomSettings,
    onChange,
    disabled = false,
    showCameraMessage = false,
    offensiveWords,
}) => {
    const { t } = useTranslation()
    const isBYOG = useSelector((state: State) => state.session.byog)
    const { RoomTitle, Language, Description, Access, NumberOfPlayers, Mood } =
        roomSettings

    const [settings, setSettings] = useState<ISessionSettings>({
        Mood: Mood || SessionMoodType.Discovery,
        Access,
        Language,
        RoomTitle,
        Description,
        NumberOfPlayers,
    })

    const handleChange = (fieldName, value) => {
        const newSettings = { ...settings, [fieldName]: value }
        setSettings(newSettings)
        if (onChange) onChange(newSettings)
    }

    const isPublic = settings.Access === RoomAccess.PUBLIC
    const [hasOffensiveWords, setHasOffensiveWords] = useState(false)

    useEffect(() => {
        setHasOffensiveWords(offensiveWords)
    }, [offensiveWords])

    const moodColor = {
        [SessionMoodType.Social]: 'primary',
        [SessionMoodType.Competitive]: 'red',
        [SessionMoodType.Discovery]: 'yellow',
        [SessionMoodType.Hermits]: 'blue',
    }

    return (
        <div className={styles.gameSettings}>
            {showCameraMessage && (
                <Alert
                    className={styles.cameraRoomMessage}
                    type={AlertType.Info}
                    message={
                        settings.Access === RoomAccess.PUBLIC
                            ? 'PublicRoomMessage'
                            : 'PrivateRoomMessage'
                    }
                    animate={false}
                />
            )}
            <Tabs
                className={styles.tabs}
                onChange={(a, b, c, currentData) => {
                    handleChange('Access', currentData)
                }}
                index={isPublic ? 0 : 1}
            >
                <Tabs.Tab
                    name={t(RoomAccess.PUBLIC)}
                    data={RoomAccess.PUBLIC}
                    className={styles.tab}
                >
                    {isBYOG && (
                        <div data-testid={'roomTitle'}>
                            <label>{t('RoomTitle')}</label>
                            <LimitedTextArea
                                rows={1}
                                limit={30}
                                value={settings.RoomTitle || ''}
                                setValue={(value) =>
                                    handleChange('RoomTitle', value)
                                }
                            />
                        </div>
                    )}
                    <div data-testid={'chatLanguage'}>
                        <label>{t('ChatLanguage')}</label>
                        <LanguageSelect
                            disabled={disabled}
                            language={settings.Language}
                            handleChange={handleChange}
                        />
                    </div>
                    <div data-testid={'roomMood'}>
                        <label>{t('RoomMood')}</label>
                        <div className={styles.moodWrapper}>
                            {Object.values(SessionMoodType).map((mood) => (
                                <Tooltip text={t(`${mood}Description`)}>
                                    <Button
                                        className={styles.moodButton}
                                        disabled={disabled}
                                        variant={
                                            settings.Mood === mood
                                                ? undefined
                                                : 'outlined'
                                        }
                                        color={moodColor[mood]}
                                        key={mood}
                                        onClick={() =>
                                            handleChange('Mood', mood)
                                        }
                                    >
                                        {t(mood)}
                                    </Button>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                    {hasOffensiveWords && (
                        <Alert
                            type={AlertType.Error}
                            message={t('NotNiceRoomSettings')}
                            animate={false}
                        />
                    )}
                    <div data-testid={'roomDescription'}>
                        <label>{t('RoomDescription')}</label>
                        <LimitedTextArea
                            rows={2}
                            limit={50}
                            value={settings.Description || ''}
                            setValue={(value) =>
                                handleChange('Description', value)
                            }
                        />
                    </div>
                </Tabs.Tab>
                <Tabs.Tab
                    name={t(RoomAccess.PRIVATE)}
                    data={RoomAccess.PRIVATE}
                    className={styles.tab}
                ></Tabs.Tab>
            </Tabs>
            {onConfirm && (
                <Button
                    className={styles.confirmButton}
                    variant="ellipticalLarge"
                    disabled={disabled}
                    onClick={async () => {
                        if (!disabled) {
                            try {
                                if (settings.RoomTitle.length)
                                    await Axios.default.post(
                                        `${getTransactionEmailServiceHostname()}/bodyguardRoomName`,
                                        {
                                            text: settings.RoomTitle,
                                        }
                                    )
                                if (settings.Description.length)
                                    await Axios.default.post(
                                        `${getTransactionEmailServiceHostname()}/bodyguardRoomDescription`,
                                        {
                                            text: settings.Description,
                                        }
                                    )
                                onConfirm(settings)
                            } catch (error) {
                                logAxiosErrorResponse(error)
                                setHasOffensiveWords(true)
                                return
                            }
                        }
                    }}
                >
                    {t('Apply')}
                </Button>
            )}
        </div>
    )
}

export default RoomSettings
