import React from 'react'
import { useFormik } from 'formik'
import Confirm from './Confirm'
import Password from './Password'
import { useAlert } from '../useAlert'
import { useValidation } from './validation'
import { handlePasswordReset } from '../../../store/auth/services'

const ResetPassword = () => {
    useAlert()
    const validationSchema = useValidation()

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
        },

        onSubmit: async (values, { setSubmitting, setStatus }) => {
            await handlePasswordReset(values, { setStatus })
            setSubmitting(false)
        },
    })
    const { status, handleSubmit } = formik

    return status?.success ? (
        <Confirm handleSubmit={handleSubmit} />
    ) : (
        <Password formik={formik} />
    )
}

export default ResetPassword
