import React, { FC, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line
import nipplejs from 'nipplejs'
import '../../../styles/joystick.scss'
import { helperJoystickFunctions } from './helper'
import style from './style.module.scss'
import { useDeviceOrientation } from '../../../utils'

type Position = {
    left: string
    top: string
}

interface ControlGroup {
    start: () => void
    end: () => void
    right: () => void
    left: () => void
    up: () => void
    down: () => void
    upRight: () => void
    upLeft: () => void
    downRight: () => void
    downLeft: () => void
}

interface JoystickProps {
    position?: Position
    controlGroup?: ControlGroup
    containerStyle?: any
    isDynamic?: boolean
    [rootHtmlAttributes: string]: any
}

const Joystick: FC<JoystickProps> = (props) => {
    const {
        controlGroup,
        containerStyle,
        isDynamic = false,
        position = { left: '50%', top: '50%' },
    } = props

    const [jsCurrentVal, setJsCurrentVal] = useState(null)

    const joystickContainer = useRef(null)

    const orientation = useDeviceOrientation()

    useEffect(() => {
        if (jsCurrentVal) {
            jsCurrentVal.destroy()
            setJsCurrentVal(null)
        }
        const asyncJS = async () => {
            function timeout(ms) {
                return new Promise((resolve) =>
                    setTimeout(() => {
                        if (!joystickContainer.current) {
                            resolve(undefined)
                            return
                        }

                        const js = nipplejs.create({
                            zone: joystickContainer.current,
                            mode: 'static',
                            position: position,
                            size: 150,
                            restJoystick: true,
                        })
                        resolve(js)
                    }, ms)
                )
            }

            let newInstance

            if (!isDynamic) {
                newInstance = await timeout(500).then((instance) => {
                    return instance
                })
                setJsCurrentVal(newInstance)
            } else {
                newInstance = nipplejs.create({
                    zone: joystickContainer.current,
                    mode: 'dynamic',
                    size: 150,
                    restJoystick: true,
                    catchDistance: 50,
                })
                setJsCurrentVal(newInstance)
            }

            return newInstance
        }

        asyncJS()

        return () => {
            if (jsCurrentVal !== null) {
                jsCurrentVal.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDynamic, orientation])

    useEffect(() => {
        helperJoystickFunctions(controlGroup, jsCurrentVal)
    }, [isDynamic, jsCurrentVal, controlGroup])

    return (
        <div
            style={{
                ...containerStyle,
            }}
            className={style.joystick}
            ref={joystickContainer}
        ></div>
    )
}

export default Joystick
