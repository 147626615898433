import { PubSubEvent, globalPubSub } from '../event/event'
import { piepie } from '../utils'
import { Pad } from './gamepads/pad'
import { Code } from './gamepads/types'
import variantsJson from './gamepads/variants.json'

const timers: Array<NodeJS.Timeout> = []
let currentId = 'default'
const sendStatePeriodMS = 8
const pads: Pad[] = []

// loop timer for checking joystick state
function checkJoystickState(port: number) {
    //TODO: This is done every frame, so we need to optimize this
    const gamepad = navigator.getGamepads()[port]

    // in order to handle multiple gamepads connected at the same time
    if (gamepad) {
        if (!pads[gamepad.index]) pads[gamepad.index] = new Pad()
        pads[gamepad.index].init(gamepad)
        pads[gamepad.index].joystickState(gamepad)
    }
}

export function registerGamepad(gamepad: Gamepad) {
    globalPubSub.pub(PubSubEvent.GAMEPAD_CONNECTED, gamepad)
}

// onGamepadConnected captures the last plugged joystick
const onGamepadConnected = (event: GamepadEvent) => {
    const gamepad = event.gamepad
    piepie.log(
        `[JOYSTICK] gamepad connected at index ${gamepad.index}: ${gamepad.id}. ${gamepad.buttons.length} buttons, ${gamepad.axes.length} axes.`
    )

    // looper, too intense?
    if (timers[gamepad.index] !== null) {
        clearInterval(timers[gamepad.index])
    }

    let currentVariant: string[]

    currentId = 'default'

    Object.entries(variantsJson.Codes as Code[]).every(([, code]) => {
        currentVariant = code.variants.filter((param) => param === gamepad.id)

        if (currentVariant.length > 0) {
            currentId = code.code
            // we stop the every() loop
            return false
        }
        // we continue the every() loop
        return true
    })

    if (!pads[gamepad.index]) pads[gamepad.index] = new Pad()
    pads[gamepad.index].setId(currentId)

    timers[gamepad.index] = setInterval(
        checkJoystickState,
        sendStatePeriodMS,
        gamepad.index
    ) // miliseconds per hit

    registerGamepad(gamepad)
}

export function init() {
    window.addEventListener('gamepadconnected', onGamepadConnected)
    window.addEventListener('gamepaddisconnected', (event: GamepadEvent) => {
        const gamepad = event.gamepad
        clearInterval(timers[gamepad.index])

        piepie.log(`[JOYSTICK] joystick disconnected: ${event}`)
        globalPubSub.pub(PubSubEvent.GAMEPAD_DISCONNECTED, gamepad)
    })

    piepie.log('[JOYSTICK] joystick has been initialized')
}
