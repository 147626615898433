import { isEnvProd } from '../../../utils'
import { Languages, ServerLocation } from './types'

const serverLocationStaging: ServerLocation = {
    Default: {
        address: '',
        text: 'DefaultLocation',
    },
    'starfox.api.piepackerstaging.com': {
        address: 'starfox.api.piepackerstaging.com',
        text: 'EuropeLocation',
    },
}

const serverLocationProd: ServerLocation = {
    Default: {
        address: '',
        text: 'DefaultLocation',
    },
    'starfox.portland.gke-jam.com': {
        address: 'starfox.portland.gke-jam.com',
        text: 'USWestLocation',
    },
    'starfox.richmond.gke-jam.com': {
        address: 'starfox.richmond.gke-jam.com',
        text: 'USEastLocation',
    },
    'starfox.iowa.gke-jam.com': {
        address: 'starfox.iowa.gke-jam.com',
        text: 'USCentralLocation',
    },
    'starfox.tokyo.gke-jam.com': {
        address: 'starfox.tokyo.gke-jam.com',
        text: 'AsiaLocation',
    },
    'starfox.australia.eks-piepacker.com': {
        address: 'starfox.australia.eks-piepacker.com',
        text: 'AustraliaLocation',
    },
    'starfox.singapore.gke-jam.com': {
        address: 'starfox.singapore.gke-jam.com',
        text: 'SingaporeLocation',
    },
    'starfox.saopaolo.gke-jam.com': {
        address: 'starfox.saopaolo.gke-jam.com',
        text: 'SouthAmericaEastLocation',
    },
    'starfox.paris.eks-piepacker.com': {
        address: 'starfox.paris.eks-piepacker.com',
        text: 'ParisLocation',
    },
    'starfox.belgium.gke-jam.com': {
        address: 'starfox.belgium.gke-jam.com',
        text: 'BelgiumLocation',
    },
    'starfox.stockholm.eks-piepacker.com': {
        address: 'starfox.stockholm.eks-piepacker.com',
        text: 'StockholmLocation',
    },
    'starfox.frankfurt.eks-piepacker.com': {
        address: 'starfox.frankfurt.eks-piepacker.com',
        text: 'FrankfurtLocation',
    },
    'starfox.milan.eks-piepacker.com': {
        address: 'starfox.milan.eks-piepacker.com',
        text: 'MilanLocation',
    },
    'starfox.bahrain.eks-piepacker.com': {
        address: 'starfox.bahrain.eks-piepacker.com',
        text: 'BahrainLocation',
    },
}

export const serverLocation = isEnvProd
    ? serverLocationProd
    : serverLocationStaging

export const languages: Languages = {
    en: {
        code: 'en',
        text: 'English',
    },
    fr: {
        code: 'fr',
        text: 'Français',
    },
    es: {
        code: 'es',
        text: 'Español',
    },
    de: {
        code: 'de',
        text: 'Deutsch',
    },
    pt: {
        code: 'pt',
        text: 'Português',
    },
}
