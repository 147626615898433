import React, { FC, useState } from 'react'
import cls from 'classnames'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import 'firebase/auth'
import style from './style.module.scss'
import { closeAuthModal, openAuthModal } from '../../store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import inlineStyle from './inlineStyles'
import { piepie } from '../../utils'
import { Button, Form, Alert } from 'react-bootstrap'
import { Field, Formik, Form as Formk } from 'formik'
import { InputText } from '../Form/fields'
import { State } from '../../store/types'

import {
    loginUserFacebook,
    logInUserGoogle,
    LogAndLink,
    LOGIN_METHOD,
} from '../../firebase/auth'
import { AuthModalType } from '../../store/auth/types'

const SignUpMenu: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [submitError, setSubmitError] = useState(null)
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const [privacyError, setPrivacyError] = useState(false)
    const authUser = useSelector((state: State) => state.user.user)

    const onSubmit = (method) => {
        if (!checkPrivacy) {
            setPrivacyError(true)
            return
        }

        if (method === LOGIN_METHOD.FACEBOOK) {
            loginUserFacebook(() => onSuccess('Facebook'), onError)
        } else if (method === LOGIN_METHOD.GOOGLE) {
            logInUserGoogle(() => onSuccess('Google'), onError)
        }
    }

    const onSuccess = (authenticationMethod) => {
        dispatch(closeAuthModal())
        window.location.reload()
    }

    const onError = (err) => {
        setSubmitError(err)
        piepie.log(err)
    }

    const linkWithPassword = () => {
        const password = document.getElementById(
            'password-input-link'
        ) as HTMLInputElement

        if (!password.value || password.value === '') {
            return
        }

        submitError.password = password.value
        LogAndLink(LOGIN_METHOD.PASSWORD, submitError, onSuccess, onError)
    }

    return (
        <>
            <div className={style.top}>
                {window.location.pathname !== '/' && !authUser.displayName && (
                    <Button
                        variant="link"
                        className={cls(style.buttonLink, style.topBackArrow)}
                        onClick={() =>
                            dispatch(
                                openAuthModal({
                                    modalType: AuthModalType.EnterAsGuest,
                                    closable: false,
                                })
                            )
                        }
                    >
                        <ArrowBackIcon />
                    </Button>
                )}
                <h1 className={style.heading}>{t('SignUpTitle')}</h1>
            </div>
            <Formik initialValues={{}} onSubmit={onSubmit}>
                <Formk className={style.form}>
                    {submitError && (
                        <Alert variant="danger">
                            {t(submitError.code)}
                            {submitError.method === LOGIN_METHOD.PASSWORD && (
                                <>
                                    <Field
                                        name="password"
                                        label={t('Password')}
                                        placeholder={t('Password')}
                                        type="password"
                                        id="password-input-link"
                                        component={InputText}
                                        style={inlineStyle.specialInput}
                                        autoComplete={'current-password'}
                                    />
                                    <Button
                                        className="btn-large"
                                        onClick={() => linkWithPassword()}
                                        block
                                        style={inlineStyle.button}
                                    >
                                        {t('SignInWithMail')}
                                    </Button>
                                </>
                            )}
                            {submitError.method === LOGIN_METHOD.GOOGLE && (
                                <Button
                                    onClick={() =>
                                        LogAndLink(
                                            LOGIN_METHOD.GOOGLE,
                                            submitError,
                                            onSuccess,
                                            onError
                                        )
                                    }
                                    block
                                    className={cls(
                                        'btn-large',
                                        style.gmailButton
                                    )}
                                    style={inlineStyle.specialButton}
                                >
                                    <img
                                        className={style.googleLogo}
                                        src="https://fassets.piepacker.com/random/google-logo.svg"
                                        alt="Google"
                                    />
                                    {t('SignInGoogle')}
                                </Button>
                            )}
                            {submitError.method === LOGIN_METHOD.FACEBOOK && (
                                <Button
                                    onClick={() =>
                                        LogAndLink(
                                            LOGIN_METHOD.FACEBOOK,
                                            submitError,
                                            onSuccess,
                                            onError
                                        )
                                    }
                                    block
                                    className={cls(
                                        'btn-large',
                                        style.facebookButton
                                    )}
                                    style={inlineStyle.specialButton}
                                >
                                    <img
                                        className={style.facebookLogo}
                                        src="https://fassets.piepacker.com/random/facebook-logo.svg"
                                        alt="Facebook"
                                    />
                                    {t('SignInFacebook')}
                                </Button>
                            )}
                        </Alert>
                    )}
                    <Form.Group>
                        <Button
                            onClick={() => onSubmit(LOGIN_METHOD.FACEBOOK)}
                            block
                            className={cls('btn-large', style.facebookButton)}
                            style={inlineStyle.button}
                        >
                            <img
                                className={style.facebookLogo}
                                src="https://fassets.piepacker.com/random/facebook-logo.svg"
                                alt="Facebook"
                            />
                            {t('SignUpFacebook')}
                        </Button>
                    </Form.Group>
                    <Form.Group>
                        <Button
                            onClick={() => onSubmit(LOGIN_METHOD.GOOGLE)}
                            block
                            className={cls('btn-large', style.gmailButton)}
                            style={inlineStyle.button}
                        >
                            <img
                                className={style.googleLogo}
                                src="https://fassets.piepacker.com/random/google-logo.svg"
                                alt="Google"
                            />
                            {t('SignUpGoogle')}
                        </Button>
                    </Form.Group>
                    <div className={style.checkbox}>
                        <input
                            data-test="GuestSubscriptionPrivacyCheckbox"
                            type="checkbox"
                            id="checkPrivacy"
                            checked={checkPrivacy}
                            onChange={() => {
                                if (!checkPrivacy) {
                                    setPrivacyError(false)
                                }

                                setCheckPrivacy(!checkPrivacy)
                            }}
                        />
                        <label htmlFor="checkPrivacy">
                            <Trans i18nKey="PrivacyAgreement">
                                I agree to Piepacker's
                                <a
                                    className={style.link}
                                    href="https://jam.gg/pages/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    privacy policy
                                </a>
                                and
                                <a
                                    className={style.link}
                                    href="https://jam.gg/pages/legal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    terms of use
                                </a>
                            </Trans>
                        </label>
                    </div>
                    {privacyError && (
                        <div
                            className={style.errorMessage}
                            data-test="GuestSubscriptionPrivacyError"
                        >
                            {t('PrivacyCheckboxError')}
                        </div>
                    )}
                    <div className={style.separationLineContainer}>
                        <hr className={style.separationLine} />
                    </div>
                    <Form.Group>
                        <Button
                            onClick={() =>
                                dispatch(
                                    openAuthModal({
                                        modalType: AuthModalType.SignUp,
                                    })
                                )
                            }
                            block
                            className="btn-large"
                            style={inlineStyle.button}
                        >
                            {t('SignUpWithMail')}
                        </Button>
                    </Form.Group>
                    <div className={style.smallText}>
                        {t('AlreadyHaveAccount')}{' '}
                        <Button
                            variant="link"
                            onClick={() =>
                                dispatch(
                                    openAuthModal({
                                        modalType: AuthModalType.Login,
                                    })
                                )
                            }
                            className={style.buttonLink}
                        >
                            {t('SignIn')}
                        </Button>
                    </div>
                </Formk>
            </Formik>
        </>
    )
}

export default SignUpMenu
