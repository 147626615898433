import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as selector from '../state/selectors'
import { useSelector } from 'react-redux'
import { setSubscribedToNews } from '../../../firebase'
import { piepie } from '../../../utils/piepie'

enum Logs {
    SubscribedSuccess = '[ACCOUNT] subscribed setting edited successfully',
}

interface ISubscription {
    refreshUser: () => void
}

const Subscription: React.FC<ISubscription> = ({ refreshUser }) => {
    const { t } = useTranslation()
    const subscribed = useSelector(selector.getSubscribedToNews)

    const handleCheck = async (sub: boolean) => {
        await setSubscribedToNews(sub)
        refreshUser()
        piepie.log(Logs.SubscribedSuccess)
    }

    return (
        <div className={styles.section}>
            <p className={styles.header}>{t('Subscription')}</p>
            <div className={styles.checkbox}>
                <input
                    name="subscribeToNews"
                    type="checkbox"
                    id="subscribeToNews"
                    checked={subscribed}
                    onChange={() => handleCheck(!subscribed)}
                />
                <label htmlFor="subscribeToNews">{t('SubscribeToNews')}</label>
            </div>
        </div>
    )
}

export default Subscription
