import { useEffect, useRef } from 'react'
import { PubSubEvent, globalPubSub } from '../../../event/event'
import { useDispatch } from 'react-redux'

/**
 * Initializes the PubSub events. (Subject of the future refactoring.)
 */
export const usePubSubEvents = ({
    videoCamHandler,
    call,
    sessionDataRef,
    setUsers,
    isLocalInitialized,
    isHealthy,
    setLobbyLoading,
}) => {
    const gameReadySub = useRef(null)
    const gameLocalInitializedSub = useRef(null)
    const gameRemoteInitializedSub = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        // GAME_READY after the startGame function is called, so we can display the video conference
        gameReadySub.current = globalPubSub.sub(
            PubSubEvent.GAME_READY,
            () => {
                videoCamHandler.current.refreshUsers(
                    sessionDataRef.current,
                    setUsers
                )
            },
            0
        )

        // The server sent 'init_local', so we know that it has been initialized
        gameLocalInitializedSub.current = globalPubSub.sub(
            PubSubEvent.GAME_LOCAL_INITIALIZED,
            () => {
                isLocalInitialized.current = true
                if (isHealthy.current) {
                    setLobbyLoading(false)
                }
            },
            0
        )

        // The server sent 'webrtc_ready', so we know that it has been remote initialized
        gameRemoteInitializedSub.current = globalPubSub.sub(
            PubSubEvent.GAME_REMOTE_INITIALIZED,
            () => {
                isLocalInitialized.current = true
                if (isHealthy.current) setLobbyLoading(false)
            },
            0
        )

        return function cleanup() {
            gameReadySub.current.unsub()
            gameLocalInitializedSub.current.unsub()
            gameRemoteInitializedSub.current.unsub()
        }
    }, [
        call,
        isHealthy,
        isLocalInitialized,
        sessionDataRef,
        setLobbyLoading,
        setUsers,
        videoCamHandler,
        dispatch,
    ])
}
