import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { piepie } from '../../../utils/piepie'
import { PubSubEvent, globalPubSub } from '../../../event/event'
import store, { actions, types } from '../../../store'
import { State } from '../../../store/types'

export interface NetworkProbeProps {
    rtcConnection: RTCPeerConnection
}

export enum NetworkQuality {
    Bad = 0,
    Medium,
    Good,
    Unknown,
}

/*
 * Allows to measure stats like ping and bitrate
 */
export const useNetworkProbe = () => {
    const rtcConnection = useSelector(
        (state: types.State) => state.rtcp.connection
    ) as RTCPeerConnection

    // utils
    const pingEnabled = useRef(true)
    const bitrateEnabled = useRef(true)
    const reportEnabled = useRef(true)
    const bytesPrev = useRef(0)
    const timestampPrev = useRef(0)
    const pingRecv = useRef(0)
    const inputChannel = useSelector((state: State) => state.rtcp.inputChannel)
    const inputReady = useSelector((state: State) => state.rtcp.inputReady)

    // intervals
    const pingInterval = 1000 // 1s
    const bitrateInterval = 1000 // 1s
    const reportInterval = 10000 // 10s

    // timeout ref
    const pingTimeoutRef = useRef(null)
    const bitrateTimeoutRef = useRef(null)
    const reportTimeoutRef = useRef(null)

    // report arrays
    const pings = useRef(0) // in ms
    const bitrates = useRef(0) // in Kb/s

    const onError = useCallback((err: any) => {
        piepie.log('[NETWORK PROBE HOOK] error when getting webrtc stats:', err)
    }, [])

    // we log the result and show a notification to the user if needed
    const displayReport = useCallback(() => {
        const ping = Math.round((pings.current / pingRecv.current) * 10) / 10
        const bitrate =
            Math.round(
                (bitrates.current / (reportInterval / bitrateInterval)) * 10
            ) / 10
        bitrates.current = 0
        pings.current = 0
        pingRecv.current = 0
        piepie.log(
            `[NETWORK PROBE HOOK] stats report { ping: ${ping}ms, bitrate: ${bitrate}Kb/s }`
        )
        if (ping < 60) {
            // good network connection
            store.dispatch(
                actions.session.setNetworkQuality(NetworkQuality.Good)
            )
        } else if (ping < 160) {
            // medium network connection
            store.dispatch(
                actions.session.setNetworkQuality(NetworkQuality.Medium)
            )
        } else {
            // bad network connection
            store.dispatch(
                actions.session.setNetworkQuality(NetworkQuality.Bad)
            )
        }
    }, [])

    // we display the results of the stats every `reportInterval` ms
    const report = useCallback(() => {
        reportTimeoutRef.current = setTimeout(() => {
            if (pings.current > 0 && bitrates.current > 0) {
                displayReport()
            }
            if (reportEnabled.current) {
                report()
            }
        }, reportInterval)
    }, [displayReport])

    const sendPing = useCallback(() => {
        if (inputChannel && inputChannel.readyState === 'open' && inputReady) {
            inputChannel.send('ping-' + Date.now())
        }
    }, [inputReady, inputChannel])

    // ping the server to check the network quality
    const ping = useCallback(() => {
        pingTimeoutRef.current = setTimeout(() => {
            sendPing()
            if (pingEnabled.current) {
                ping()
            }
        }, pingInterval)
    }, [sendPing])

    // checks the bitrate of the video stream
    const bitrate = useCallback(() => {
        pingTimeoutRef.current = setTimeout(async () => {
            if (bitrateEnabled.current && rtcConnection) {
                try {
                    const stats = await rtcConnection.getStats(null)

                    // we calculate the bitrate
                    stats.forEach((report) => {
                        const now = report.timestamp

                        let bitrate
                        if (
                            report.type === 'inbound-rtp' &&
                            report.mediaType === 'video'
                        ) {
                            const bytes = report.bytesReceived
                            if (timestampPrev.current) {
                                bitrate =
                                    (8 * (bytes - bytesPrev.current)) /
                                    (now - timestampPrev.current)
                                bitrate = Math.floor(bitrate)
                            }
                            bytesPrev.current = bytes
                            timestampPrev.current = now
                        }
                        // the bitrate in Kb/s
                        if (bitrate) {
                            bitrates.current += bitrate
                        }
                    })
                } catch (err) {
                    onError(err)
                }
                bitrate()
            }
        }, bitrateInterval)
    }, [rtcConnection, onError])

    // we deinit on unmount
    const deinit = useCallback(() => {
        if (pingTimeoutRef.current) {
            clearTimeout(pingTimeoutRef.current)
        }
        if (bitrateTimeoutRef.current) {
            clearTimeout(bitrateTimeoutRef.current)
        }
        if (reportTimeoutRef.current) {
            clearTimeout(reportTimeoutRef.current)
        }
        pingEnabled.current = false
        bitrateEnabled.current = false
        reportEnabled.current = false
        bitrates.current = 0
        pings.current = 0
        pingRecv.current = 0
    }, [])

    const init = useCallback(() => {
        pingEnabled.current = true
        bitrateEnabled.current = true
        reportEnabled.current = true
        ping()
        bitrate()
        report()
    }, [ping, bitrate, report])

    useEffect(() => {
        const pong = globalPubSub.sub(
            PubSubEvent.HEARTBEAT,
            (times: number[]) => {
                pings.current += times[0] - times[1]
                pingRecv.current++
            },
            0
        )
        init()

        return () => {
            pong.unsub()
            deinit()
        }
    }, [init, deinit])

    // used for unmount
    useEffect(() => {
        return () => {
            deinit()
        }
    }, [deinit])
}
