import * as React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../stories'
import styles from './styles.module.scss'
import { LocalStorage } from '../../state/storage'
import { useEffect } from 'react'
import { millisecondsToHours } from 'date-fns'

const TryDesktopBanner = () => {
    const { t } = useTranslation()
    const [isVisible, setIsVisible] = React.useState(false)

    useEffect(() => {
        const closedBannerTimestamp = localStorage.getItem(
            LocalStorage.closedAlphaMobileBanner
        )
        const msBetweenDates = Math.abs(
            Number(closedBannerTimestamp) - new Date().getTime()
        )
        const hoursBetweenDates = millisecondsToHours(msBetweenDates)
        const showBanner = hoursBetweenDates > 24
        setIsVisible(showBanner)
    }, [])

    const handleBannerClose = () => {
        setIsVisible(false)
        localStorage.setItem(
            LocalStorage.closedAlphaMobileBanner,
            new Date().getTime().toString()
        )
    }

    return (
        <div
            className={classNames(styles.alert, {
                [styles.hidden]: !isVisible,
            })}
        >
            <Icon className={styles.infoIcon} name="info" />
            <div>
                <p>{t('TryUsOnLaptop')}</p>
            </div>
            <button className={styles.close} onClick={handleBannerClose}>
                <Icon className={styles.closeIcon} name="close" />
            </button>
        </div>
    )
}
export default TryDesktopBanner
