import React, { FC, useState } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Button, Form, Alert } from 'react-bootstrap'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'
import style from './style.module.scss'
import inlineStyle from './inlineStyles'

import {
    loginUserFacebook,
    logInUserGoogle,
    LogAndLink,
    LOGIN_METHOD,
} from '../../firebase/auth'

import { InputText } from '../Form/fields'
import { deleteCookie, getCookie, piepie, useQuery } from '../../utils'
import { useDispatch } from 'react-redux'
import { closeAuthModal, openAuthModal } from '../../store/auth/actions'
import analytics from '../../analytics'
import { CookieStorage } from '../../state/storage'
import { AuthModalType } from '../../store/auth/types'

const Login: FC = () => {
    const urlParams = useQuery()
    const dispatch = useDispatch()
    const [submitError, setSubmitError] = useState(null)
    const { t } = useTranslation()

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email(t('InvalidEmail'))
            .required(t('Required')),
        password: Yup.string().required(t('NoPasswordProvided')),
    })

    const onSubmit = async (values: { email?: string; password?: string }) => {
        try {
            analytics.track('Login', {
                email: values.email,
            })
            await firebase
                .auth()
                .signInWithEmailAndPassword(
                    values.email.trim(),
                    values.password
                )
            onSuccess('email')
        } catch (err) {
            err.signedWithPassword = true
            err.email = values.email.trim()
            onError(err)
        }
    }

    const onSuccess = (authenticationMethod) => {
        dispatch(closeAuthModal())
        const intendedUrl = getCookie(CookieStorage.intendedUrl)

        if (intendedUrl) {
            // Delete the cookie so we don't redirect the user to the old game page anymore when logging in in the future.
            deleteCookie(CookieStorage.intendedUrl)
            window.location.href = intendedUrl
        } else {
            window.location.reload()
        }
    }

    const onError = (err) => {
        if (err.signedWithPassword) {
            firebase
                .auth()
                .fetchSignInMethodsForEmail(err.email)
                .then((methods) => {
                    if (methods[0] === 'google.com') {
                        err.code = 'auth/try-google-login'
                    }

                    if (methods[0] === 'facebook.com') {
                        err.code = 'auth/try-facebook-login'
                    }

                    setSubmitError(err)
                    piepie.log(err)
                })
        } else {
            setSubmitError(err)
            piepie.log(err)
        }
    }

    const linkWithPassword = () => {
        const password = document.getElementById(
            'password-input-link'
        ) as HTMLInputElement

        if (!password.value || password.value === '') {
            return
        }

        submitError.password = password.value
        LogAndLink(LOGIN_METHOD.PASSWORD, submitError, onSuccess, onError)
    }

    return (
        <>
            <div data-test="LoginPage" className={style.top}>
                <h1 className={style.heading}>{t('SignIn')}</h1>
            </div>

            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={{ email: '', password: '' }}
            >
                {({ isSubmitting }) => {
                    return (
                        <Formk className={style.form}>
                            {urlParams.has('emailConfirmed') && (
                                <Alert variant="success">
                                    {t('EmailConfirmed')}
                                </Alert>
                            )}
                            {submitError && (
                                <Alert
                                    data-test={submitError.code}
                                    variant="danger"
                                >
                                    {t(submitError.code)}
                                    {submitError.method ===
                                        LOGIN_METHOD.PASSWORD && (
                                        <>
                                            <Field
                                                name="password"
                                                label={t('Password')}
                                                placeholder={t('Password')}
                                                type="password"
                                                id="password-input-link"
                                                component={InputText}
                                                style={inlineStyle.specialInput}
                                            />
                                            <Button
                                                className="btn-large"
                                                onClick={() =>
                                                    linkWithPassword()
                                                }
                                                block
                                                style={inlineStyle.button}
                                            >
                                                {t('SignInWithMail')}
                                            </Button>
                                        </>
                                    )}
                                    {submitError.method ===
                                        LOGIN_METHOD.GOOGLE && (
                                        <Button
                                            onClick={() =>
                                                LogAndLink(
                                                    LOGIN_METHOD.GOOGLE,
                                                    submitError,
                                                    onSuccess,
                                                    onError
                                                )
                                            }
                                            block
                                            className={cls(
                                                'btn-large',
                                                style.gmailButton
                                            )}
                                            style={inlineStyle.specialButton}
                                        >
                                            <img
                                                className={style.googleLogo}
                                                src="https://fassets.piepacker.com/random/google-logo.svg"
                                                alt="Google"
                                            />
                                            {t('SignInGoogle')}
                                        </Button>
                                    )}
                                    {submitError.method ===
                                        LOGIN_METHOD.FACEBOOK && (
                                        <Button
                                            onClick={() =>
                                                LogAndLink(
                                                    LOGIN_METHOD.FACEBOOK,
                                                    submitError,
                                                    onSuccess,
                                                    onError
                                                )
                                            }
                                            block
                                            className={cls(
                                                'btn-large',
                                                style.facebookButton
                                            )}
                                            style={inlineStyle.specialButton}
                                        >
                                            <img
                                                className={style.facebookLogo}
                                                src="https://fassets.piepacker.com/random/facebook-logo.svg"
                                                alt="Facebook"
                                            />
                                            {t('SignInFacebook')}
                                        </Button>
                                    )}
                                </Alert>
                            )}
                            <Form.Group>
                                <Button
                                    onClick={() =>
                                        loginUserFacebook(
                                            () => onSuccess('Facebook'),
                                            onError
                                        )
                                    }
                                    block
                                    className={cls(
                                        'btn-large',
                                        style.facebookButton
                                    )}
                                    style={inlineStyle.button}
                                >
                                    <img
                                        className={style.facebookLogo}
                                        src="https://fassets.piepacker.com/random/facebook-logo.svg"
                                        alt="Facebook"
                                    />
                                    {t('SignInFacebook')}
                                </Button>
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    onClick={() =>
                                        logInUserGoogle(
                                            () => onSuccess('Google'),
                                            onError
                                        )
                                    }
                                    block
                                    className={cls(
                                        'btn-large',
                                        style.gmailButton
                                    )}
                                    style={inlineStyle.button}
                                >
                                    <img
                                        className={style.googleLogo}
                                        src="https://fassets.piepacker.com/random/google-logo.svg"
                                        alt="Google"
                                    />
                                    {t('SignInGoogle')}
                                </Button>
                            </Form.Group>
                            <div className={style.separationLineContainer}>
                                <hr className={style.separationLine} />
                                <div className={style.separationLineText}>
                                    {t('ContinueWith')}
                                </div>
                                <hr className={style.separationLine} />
                            </div>
                            <Form.Group>
                                <Field
                                    label={t('EmailAddress')}
                                    placeholder={t('EmailAddress')}
                                    name="email"
                                    id="email-input"
                                    component={InputText}
                                    style={inlineStyle.input}
                                    autoComplete={'email'}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Field
                                    name="password"
                                    label={t('Password')}
                                    placeholder={t('Password')}
                                    type="password"
                                    id="password-input"
                                    component={InputText}
                                    style={inlineStyle.input}
                                    autoComplete={'current-password'}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    variant="link"
                                    className={cls(
                                        style.alignRight,
                                        style.buttonLink
                                    )}
                                    onClick={() =>
                                        dispatch(
                                            openAuthModal({
                                                modalType:
                                                    AuthModalType.ResetPassword,
                                            })
                                        )
                                    }
                                >
                                    {t('PasswordForgot')}
                                </Button>
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    className="btn-large"
                                    type="submit"
                                    id="login-button"
                                    block
                                    disabled={isSubmitting}
                                    style={inlineStyle.button}
                                >
                                    {t('SignIn')}
                                </Button>
                            </Form.Group>
                            {!urlParams.has('emailConfirmed') && (
                                <div className={style.smallText}>
                                    {t('DontHaveAccount')}{' '}
                                    <Button
                                        variant="link"
                                        className={style.buttonLink}
                                        onClick={() =>
                                            dispatch(
                                                openAuthModal({
                                                    modalType:
                                                        AuthModalType.SignUpMenu,
                                                })
                                            )
                                        }
                                    >
                                        {t('AuthKickstarterSignUpButton')}
                                    </Button>
                                </div>
                            )}
                        </Formk>
                    )
                }}
            </Formik>
        </>
    )
}

export default Login
