import styles from './styles.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LocalStorage } from '../../../state/storage'
import { getFeatures } from '../state/selectors'
import { FirestoreDateRangeInterface } from '../../../state/starfox'

const ActiveFeatures: React.FC = () => {
    const { t } = useTranslation()
    const features = useSelector(getFeatures)
    const lang = localStorage.getItem(LocalStorage.i18nextLng)

    const expirationTimestamp = (validity: FirestoreDateRangeInterface[]) =>
        validity[validity.length - 1].EndDate

    const expirationDate = (validity: FirestoreDateRangeInterface[]) =>
        expirationTimestamp(validity)
            ? expirationTimestamp(validity).toDate().toLocaleDateString(lang, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
              })
            : t('ActiveFeaturesExpirationNever')

    const featureStatus = (validity: FirestoreDateRangeInterface[]) =>
        expirationTimestamp(validity) === undefined ||
        expirationTimestamp(validity).toDate() > new Date()
            ? t('ActiveFeaturesStatusActive')
            : t('ActiveFeaturesStatusExpired')

    return (
        <>
            {features && (
                <div className={styles.section}>
                    <p className={styles.header}>{t('ActiveFeaturesTitle')}</p>
                    <div style={{ overflowX: 'auto' }}>
                        <table className="activefeatures-table table">
                            <thead>
                                <tr>
                                    <th>{t('ActiveFeaturesHeaderFeatures')}</th>
                                    <th>
                                        {t('ActiveFeaturesHeaderDescription')}
                                    </th>
                                    <th>{t('ActiveFeaturesHeaderExpire')}</th>
                                    <th>{t('ActiveFeaturesHeaderStatus')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(features).map(
                                    ({ ID, Validity }) => {
                                        return (
                                            <tr>
                                                <td>{t(`FEATURE_${ID}`)}</td>
                                                <td>
                                                    {t(
                                                        `FEATUREDESCRIPTION_${ID}`
                                                    )}
                                                </td>
                                                <td>
                                                    {expirationDate(Validity)}
                                                </td>
                                                <td>
                                                    {featureStatus(Validity)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default ActiveFeatures
