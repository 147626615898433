import { piepie } from './piepie'

// makeCancelable makes a Promise cancelable
export function makeCancelable(promise) {
    let hasCanceled_ = false

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) =>
                hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            (error) =>
                hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        )
    })

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true
        },
    }
}

export function AxiosErrorMessage(err) {
    if (err.response) {
        // Request made and server responded
        piepie.log(err.response.data)
        piepie.log(err.response.status)
        piepie.log(err.response.headers)
        return err.response.data
    }
    if (err.request) {
        // The request was made but no response was received
        piepie.log(err.request)
    }
    // Something happened in setting up the request that triggered an Error
    piepie.log('Error', err.message)
    return err.message
}
