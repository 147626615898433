import React, { FC, useCallback, useEffect, useState } from 'react'
import GameLobbyLoading from '../GameLobby/GameLobbyLoading'
import Screen from '../Screen'

import MobileGameroomOverlay from './MobileGameroomOverlay'
import { piepie, useDeviceOrientation } from '../../utils'
import { showLoading } from '../../utils/loading'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { GAME_STATE } from '../../store/session/types'
import { useHistory } from 'react-router-dom'
import { ScreenType } from '../Screen/screen-props'
import style from './style.module.scss'
import { MobileChatOverlay } from './MobileChatOverlay/MobileChatOverlay'
import { getUserFromStarfox } from '../../firebase'
import { useTranslation } from 'react-i18next'

import { connectionInitialized } from '../../store/socket/utils'
import { SectionType, UsersDataType } from './MobileGameRoom'

import * as Socket from '../../network/socket'
import { CountdownAlert, Icon } from '../../stories'
import { User } from '../../state/faces'

interface GameScreenProps {
    section: SectionType
    setSection: (x) => void
    showChat: boolean
    setShowChat: (x) => void
    allUsers: { [index: string]: User }
    handleSetFullscreen?: () => void
    isDynamic: boolean
    setIsDynamic: (x) => void
    showOptions: boolean
    handleShowRoomSettings: () => void
    setShowOptions: (state) => void
}

const GameScreen: FC<GameScreenProps> = ({
    section,
    setSection,
    showChat,
    setShowChat,
    allUsers,
    isDynamic,
    setIsDynamic,
    showOptions,
    setShowOptions,
    handleShowRoomSettings,
}) => {
    const [usersData, setUsersData] = useState<UsersDataType[]>([])
    const orientation = useDeviceOrientation()
    const [prevOrientation, setPrevOrientation] = useState(orientation)

    const history = useHistory()
    const { t } = useTranslation()

    const game = useSelector((state: State) => state.session.game)
    const gameState = useSelector((state: State) => state.session.gameState)
    const screenType = useSelector((state: State) => state.screen.screenType)
    const isHost = useSelector((state: State) => state.session.isHost)
    const highlightStatus = useSelector(
        (state: State) => state.screen.highlightScreenStatus
    )

    const leavingGame = useCallback(() => {
        piepie.log('[GAME ROOM] leaving game')
        history.push('/')
    }, [history])

    useEffect(() => {
        const getUsersData = async () => {
            const users = []
            const allUsersValues = Object.values(allUsers)
            const allUsersKeys = Object.keys(allUsers)

            Promise.all(
                allUsersValues?.map(async (alluserObj: any, index) => {
                    const userUID = alluserObj.UID
                    const user = await getUserFromStarfox(userUID)
                    const userData: any = {
                        nickname: user.User.DisplayName || t('guest'),
                        photoUrl:
                            user.User.PhotoURL ||
                            'https://fassets.piepacker.com/backgrounds/original/user.png',
                        uid: user.User.UID,
                        userSessionID: allUsersKeys[index],
                    }
                    users.push({ ...userData })
                })
            )
            setUsersData(users)
        }

        getUsersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers])

    const gameLoading =
        gameState === GAME_STATE.LOADING ||
        (screenType === ScreenType.CLOUD && !connectionInitialized())

    const CountdownAlertContent = () => {
        return (
            <div className={style.countdownContent}>
                <Icon name="save" />
                {t('ResumeGame')}
            </div>
        )
    }
    const onApproveCountdownAlert = useCallback(() => {
        if (isHost) {
            Socket.loadGame('auto')
            piepie.log('[STREAMING SCREEN] load autosave')
        }
    }, [isHost])

    useEffect(() => {
        return () => {
            if (orientation && prevOrientation !== orientation) {
                setShowChat(false)
            }
        }
    }, [orientation, section, prevOrientation, setShowChat])

    useEffect(() => {
        setPrevOrientation(orientation)
    }, [orientation])

    return (
        <div
            className={style.gameContainer}
            style={
                orientation.includes('portrait')
                    ? {
                          height: window.innerWidth * (3 / 4),
                      }
                    : {}
            }
        >
            <div id="game" style={{ width: '100%', height: '100%' }}>
                {gameLoading ? (
                    <GameLobbyLoading
                        ESLMode={game.Competition}
                        gameId={game.id}
                    />
                ) : (
                    <>
                        <Screen leavingGame={leavingGame} />
                    </>
                )}

                {showOptions && (
                    <MobileGameroomOverlay
                        section={section}
                        setSection={setSection}
                        gameId={game.id}
                        isDynamic={isDynamic}
                        setIsDynamic={setIsDynamic}
                        handleShowRoomSettings={handleShowRoomSettings}
                        setShowOptions={setShowOptions}
                    />
                )}

                {showChat && (
                    <MobileChatOverlay
                        orientation={orientation}
                        setShow={setShowChat}
                        usersData={usersData}
                    />
                )}
            </div>

            {isHost && !showLoading(gameState, highlightStatus) && (
                <CountdownAlert
                    content={<CountdownAlertContent />}
                    autoClose
                    onApprove={onApproveCountdownAlert}
                    position="bottom"
                />
            )}
        </div>
    )
}

export default GameScreen
