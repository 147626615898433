import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RTCPEvent } from '../../state/network'

export interface RTCPState {
    events: RTCPEvent[]
    video: HTMLVideoElement
    connection: RTCPeerConnection
    inputChannel: RTCDataChannel
    mediaStream: MediaStream
    mediaStreamReady: boolean
    connected: boolean
    inputReady: boolean
}

export const initialState = {
    events: [],
    connection: null,
    video: null,
    inputChannel: null,
    mediaStream: null,
    mediaStreamReady: false,
    connected: false,
    inputReady: false,
}

const rtcpSlice = createSlice({
    name: 'rtcp',
    initialState: initialState,
    reducers: {
        clearRTCP: (state) => {
            return initialState
        },
        addEvent: (state, action: PayloadAction<RTCPEvent>) => {
            state.events = [...state.events, action.payload]
        },
        setVideo: (state, action: PayloadAction<HTMLVideoElement>) => {
            state.video = action.payload
        },
        setConnection: (state, action: PayloadAction<RTCPeerConnection>) => {
            state.connection = action.payload
        },
        setInputChannel: (state, action: PayloadAction<RTCDataChannel>) => {
            state.inputChannel = action.payload
        },
        setMediaStream: (state, action: PayloadAction<MediaStream>) => {
            state.mediaStream = action.payload
        },
        setMediaStreamReady: (state, action: PayloadAction<boolean>) => {
            state.mediaStreamReady = action.payload
        },
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload
        },
        setInputReady: (state, action: PayloadAction<boolean>) => {
            state.inputReady = action.payload
        },
    },
})

export type RTCPAction =
    | ReturnType<typeof rtcpSlice.actions.clearRTCP>
    | ReturnType<typeof rtcpSlice.actions.addEvent>
    | ReturnType<typeof rtcpSlice.actions.setVideo>
    | ReturnType<typeof rtcpSlice.actions.setConnection>
    | ReturnType<typeof rtcpSlice.actions.setInputChannel>
    | ReturnType<typeof rtcpSlice.actions.setMediaStream>
    | ReturnType<typeof rtcpSlice.actions.setMediaStreamReady>
    | ReturnType<typeof rtcpSlice.actions.setConnected>
    | ReturnType<typeof rtcpSlice.actions.setInputReady>

export default rtcpSlice
