import React, { FC, ReactNode } from 'react'
import style from './style.module.scss'
import { useCountdown } from './useCountdown'

interface CountdownCircleTimerProps {
    duration?: number
    startAt?: number
    stroke?: string
    size?: number
    strokeWidth?: number
    children?: string | ReactNode
    showTime?: boolean
    showTimeFontSize?: number
    onComplete?: () => void
    onClick?: () => void
    cleanUp?: boolean
    transparent?: boolean
    [rootHtmlAttributes: string]: any
}

const CountdownCircleTimer: FC<CountdownCircleTimerProps> = ({
    duration = 10,
    startAt = 0,
    stroke = 'rgb(255,255,255)',
    size = 180,
    strokeWidth = 12,
    showTime = false,
    showTimeFontSize = 2,
    onComplete,
    children,
    onClick,
    cleanUp,
    transparent = false,
    ...rootHtmlAttributes
}) => {
    const { path, pathLength, strokeDashoffset, elapsedTime } = useCountdown({
        duration,
        startAt,
        size,
        strokeWidth,
        onComplete,
        cleanUp,
    })

    const time = elapsedTime?.toFixed(0)

    return (
        <div
            className={style.container}
            style={{ width: `${size}px`, height: `${size}px` }}
            onClick={onClick}
            {...rootHtmlAttributes}
        >
            <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
                <path
                    d={path}
                    fill="none"
                    stroke={transparent ? '' : 'rgba(255,255,255,0.5)'}
                    strokeWidth={strokeWidth}
                />
                <path
                    d={path}
                    fill="none"
                    stroke={stroke}
                    strokeLinecap={'round'}
                    strokeWidth={strokeWidth}
                    strokeDasharray={pathLength}
                    strokeDashoffset={strokeDashoffset}
                />
            </svg>
            {children && <div className={style.children}>{children}</div>}
            {showTime && (
                <div
                    className={style.children}
                    style={{ fontSize: `${showTimeFontSize}rem` }}
                >
                    {time}
                </div>
            )}
        </div>
    )
}

export default CountdownCircleTimer
