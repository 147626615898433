import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSession, setSessionSettings } from '../../../firebase'
import { GameroomModalSectionType } from '../../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../../store/gameroomModalSection/gameroomModalSection'
import { setSession } from '../../../store/session/actions'
import { State } from '../../../store/types'
import { GameroomModal } from '../../../stories'
import { sleep } from '../../../utils'
import { usePausePlay } from '../../../utils/GameRoomHooks'
import { isGame } from '../../../utils/loading'
import { RoomSettings } from '../../GameSettings'
import style from './style.module.scss'
import { setMannequinMode } from '../../../store/mask/actions'

export const GameSettingsModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [show, setShow] = useState<boolean>(false)

    const { handleResumeGame } = usePausePlay()
    const session = useSelector((state: State) => state.session.session)
    const isHost = useSelector((state: State) => state.session.isHost)
    const sessionID = useSelector((state: State) => state.session.sessionID)
    const gameState = useSelector((state: State) => state.session.gameState)
    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const handleClose = useCallback(() => {
        setShow(false)
        handleResumeGame()
        dispatch(
            gameroomModalSectionSlice.actions.setGameroomModal(
                GameroomModalSectionType.NONE
            )
        )
    }, [handleResumeGame, dispatch])

    useEffect(() => {
        if (
            isGame(gameState).paused &&
            gameroomModalSection === GameroomModalSectionType.SETTINGS
        ) {
            setShow(true)
        } else if (gameroomModalSection === GameroomModalSectionType.NONE) {
            handleClose()
        }
    }, [dispatch, gameState, gameroomModalSection, handleClose])

    const refreshSession = useCallback(async () => {
        await sleep(600)
        const session = await getSession(sessionID)
        dispatch(setSession(session))
    }, [sessionID, dispatch])

    const Header = <div className={style.header}>{t('RoomConfiguration')}</div>
    return (
        <GameroomModal
            show={show}
            header={Header}
            onClose={handleClose}
            size="verticalWrap"
        >
            <RoomSettings
                showCameraMessage={true}
                disabled={!isHost}
                roomSettings={session}
                onConfirm={(settings) => {
                    setSessionSettings(sessionID, settings)
                    dispatch(setMannequinMode(true))
                    refreshSession()
                    handleClose()
                }}
            />
        </GameroomModal>
    )
}
