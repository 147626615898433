import * as Axios from 'axios'
import { AxiosResponse } from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { authorizedConfig, db, getSession } from '../firebase'
import store, { actions } from '../store'
import { SessionActiveEnum } from '../store/session/types'

import {
    computeGeographicStarfoxHostName,
    isEnvProd,
    NewKey,
    OBJTYPE,
    piepie,
    sessionStarfoxURL,
    sleep,
} from '../utils'

import { SessionFromFirebase, UserSessionFromFirebase } from '../state/starfox'
import { LocalStorage, SessionStorage } from '../state/storage'
import { logAxiosErrorResponse } from '../utils/http'
import { GameLobbyErrorType } from '../state/alert'
import { setGameLobbyError, setGameName } from '../store/session/actions'
import rtcpSlice from '../store/rtcp/rtcp'

export const sessionAvailable = async (): Promise<boolean> => {
    // We check the closest geographic starfox
    const closest = await computeGeographicStarfoxHostName()
    if (closest) {
        return true
    } else {
        return false
    }
}

export const joinSession = async () => {
    const sessionID = store.getState().session.sessionID
    const userSessionID = store.getState().session.userSessionID
    if (!sessionID || !userSessionID) {
        store.dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
        piepie.log(
            'missing session or user session id when trying to join session'
        )
        return
    }
    const cfg = await authorizedConfig()
    try {
        await Axios.default.post(
            `${sessionStarfoxURL()}/api/session/join/${sessionID}`,
            store.getState().session.userSession,
            cfg
        )
        return true
    } catch (reason) {
        logAxiosErrorResponse(reason)
        switch (reason.response.data) {
            case 'too many participants':
                store.dispatch(
                    actions.session.setGameLobbyError(
                        GameLobbyErrorType.ROOM_FULL
                    )
                )
                return false
            case 'user already in session':
                // actually not an error worth reporting
                return true
            case 'user has been banned':
                store.dispatch(
                    actions.session.setGameLobbyError(
                        GameLobbyErrorType.USER_BANNED
                    )
                )
                return false
            default:
                store.dispatch(
                    actions.session.setGameLobbyError(
                        GameLobbyErrorType.ROOM_FAILURE
                    )
                )
                return false
        }
    }
}

export const clearSessionState = () => {
    window.sessionStorage.clear()
    localStorage.setItem(LocalStorage.sessionID, '')
    store.dispatch(actions.session.clearSession())
    store.dispatch(rtcpSlice.actions.clearRTCP())
}

export const getUserSessionID = (): {
    userSessionID: string
    isNew: boolean
} => {
    let userSessionID: string
    let dispatchReduxAction = false
    let isNew = false

    userSessionID = store.getState().session.userSessionID

    // If not available in redux store, then check on session storage
    if (!userSessionID) {
        // session storage is used here because it persists across page refreshes
        userSessionID = sessionStorage.getItem(SessionStorage.userSessionID)
        dispatchReduxAction = true
    }

    // If not available in redux store and session storage, then create a new userSessionID
    if (!userSessionID) {
        // if no user session, we'll create one. Player will join as new player
        userSessionID = NewKey(OBJTYPE.USR_SESSION)
        isNew = true
    }

    // Save the userSessionID in redux and sessionStorage if necessary
    if (dispatchReduxAction) {
        store.dispatch(actions.session.setUserSessionId(userSessionID))
    }
    if (isNew) {
        sessionStorage.setItem(SessionStorage.userSessionID, userSessionID)
    }

    return { userSessionID, isNew }
}

const newUserSession = (): UserSessionFromFirebase => {
    return {
        IPv4: '127.0.0.1', // TODO: this is wrong, please fix
        ID: store.getState()?.session?.userSessionID,
        UserAgent: navigator.userAgent,
        SessionID: store.getState()?.session?.sessionID,
        UserEmail: firebase.auth()?.currentUser?.email,
        UID: firebase.auth()?.currentUser?.uid,
    }
}

export class StarfoxHandler {
    // getSessionState checks that a session exists (i.e. a game is already running)
    async getSessionState(): Promise<SessionActiveEnum> {
        let sessionID: string
        getUserSessionID()
        sessionID = store.getState().session.sessionID
        // checkURLParams for session (user session won't exist)
        const urlParams = new URLSearchParams(window.location.search)
        if (!sessionID) {
            sessionID = urlParams.get('sessionID')
            if (!sessionID) {
                sessionID = localStorage.getItem(LocalStorage.sessionID)
                sessionID = await this.emptySessionIfExpired(sessionID)
            } else {
                this.setWindowURL()
            }
        }
        let sessionActive: SessionActiveEnum
        if (!sessionID) {
            // if no session id, the session is definitely not active
            sessionID = NewKey(OBJTYPE.SESSION)
            sessionActive = SessionActiveEnum.No
            const closest = await computeGeographicStarfoxHostName()
            store.dispatch(actions.session.setCluster(closest))
        } else {
            // if sessionID exists then it may be active (we'll check later) and you are "rejoining". Otherwise,
            try {
                const session = await getSession(sessionID)
                sessionActive = SessionActiveEnum.Maybe
                store.dispatch(actions.session.setCluster(session.Cluster))
            } catch (error) {
                // sessionID was found in params but does not exist in firestore. session is definitely not active.
                sessionActive = SessionActiveEnum.No
                const closest = await computeGeographicStarfoxHostName()
                store.dispatch(actions.session.setCluster(closest))
            }
        }
        store.dispatch(actions.session.setSessionId(sessionID))
        localStorage.setItem(LocalStorage.sessionID, sessionID)
        // TODO: Move into store subscription
        return sessionActive
    }

    async emptySessionIfExpired(sessionID: string): Promise<string> {
        if (!sessionID) {
            return ''
        }
        const session = await getSession(sessionID)
        if (!session) {
            return ''
        }
        const stopped =
            typeof session.Stopped === 'string'
                ? Date.parse(session.Stopped)
                : session.Stopped
        const expired = !!stopped && stopped > 0
        if (expired) {
            return ''
        }
        return sessionID
    }

    responseCode(reason): SessionActiveEnum {
        // If the error state is like: net::ERR_CONNECTION_RESET, net::ERR_CONNECTION_REFUSED... thrown by the browser on client side

        if (!reason.response) {
            piepie.error(reason)
            return SessionActiveEnum.CannotConnect
        }

        // If the error is a classic one
        switch (reason.response.status) {
            case 403:
                piepie.error('[STARFOX] 403: forbidden --', reason.response)
                return SessionActiveEnum.Forbidden
            case 404:
                piepie.error('[STARFOX] 404: not found')
                break
            case 408:
                piepie.error('[STARFOX] 408: request timeout')
                return SessionActiveEnum.CannotConnect
            case 409:
                piepie.error(
                    `[STARFOX] 409: session ${
                        store.getState().session.sessionID
                    } was already started`
                )
                return SessionActiveEnum.Maybe
            case 500:
                piepie.error('[STARFOX] 500: internal server error')
                break
            case 502:
                piepie.error('[STARFOX] 502: server temporarily unavailable')
                return SessionActiveEnum.Maybe
        }
        piepie.error('[STARFOX] response error:', reason.response)
        return SessionActiveEnum.Failed
    }

    async checkSessionState(): Promise<SessionActiveEnum> {
        const cfg = await authorizedConfig()
        let response: AxiosResponse<SessionFromFirebase>
        try {
            response = await Axios.default.get(
                `${sessionStarfoxURL()}/api/session/${
                    store.getState().session.sessionID
                }`,
                cfg
            )
        } catch (error) {
            logAxiosErrorResponse(error)
            return this.responseCode(error)
        }
        const session = response.data
        const started =
            typeof session.Started === 'string'
                ? Date.parse(session.Started)
                : session.Started
        const stopped =
            typeof session.Stopped === 'string'
                ? Date.parse(session.Stopped)
                : session.Stopped
        // if the cluster endpoint for the session does not match the current one, update current
        if (session.Cluster !== store.getState().session.cluster) {
            store.dispatch(actions.session.setCluster(session.Cluster))
            return SessionActiveEnum.Maybe
        }
        // if the session hasn't been started yet, wait
        if (!started) {
            return SessionActiveEnum.Maybe
        }
        if (!!stopped && stopped > 0) {
            piepie.log(`[STARFOX] session already expired: ${session.ID}`)
            store.dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
            return SessionActiveEnum.Failed
        }
        // if the session exists but is unhealthy, expire it
        if (!session.Healthy) {
            piepie.log(`[STARFOX] session expired or unhealthy: ${session.ID}`)
            store.dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
            return SessionActiveEnum.Failed
        }

        // check if userSessionID is part of the existing UserSessionIDs. If not, join the session.
        const alreadyInSession = session.UserSessions.includes(
            store.getState().session.userSessionID
        )
        // There is a type mismatch between write data (payload to starfox) and read data (read from Firebase)
        let userSession: UserSessionFromFirebase
        if (!alreadyInSession) {
            userSession = newUserSession()
        } else {
            // user session can be fetched from firestore
            try {
                const resp = await db
                    .collection('userSessions')
                    .doc(store.getState().session.userSessionID)
                    .get()
                userSession = resp.data() as UserSessionFromFirebase
            } catch (err) {
                piepie.error(`[STARFOX] could not user session ID: ${err}`)
                return SessionActiveEnum.Failed
            }
        }
        store.dispatch(actions.session.setSession(session))
        const cluster = session.Cluster.split('.').slice(1).join('.')
        window.sessionStorage.setItem(
            'connectionHost',
            `${session.Bandicoot}.${cluster}`
        )
        window.sessionStorage.setItem('sessionID', session.ID)
        store.dispatch(actions.session.setUserSession(userSession))
        window.sessionStorage.setItem('userSessionID', userSession.ID)
        window.sessionStorage.setItem('userID', userSession.UID)
        window.sessionStorage.setItem(
            'displayName',
            store.getState().user.user?.displayName
        )
        if (session.Games.length !== 0) {
            // set game name if available
            const lastGame = session.Games[session.Games.length - 1]
            store.dispatch(setGameName(lastGame.GameID))
        }
        // user is a host iff he/she is this session's originator
        const isHost = session.OriginatorUserSession === userSession.ID
        localStorage.setItem(LocalStorage.isHost, `${isHost}`)
        store.dispatch(actions.session.setIsHost(isHost))
        piepie.log(`[STARFOX] existing session is active`)
        return SessionActiveEnum.Yes
    }

    setWindowURL() {
        const baseUrl = [
            window.location.protocol,
            '//',
            window.location.host,
            window.location.pathname,
        ].join('')

        if (isEnvProd) {
            window.history.replaceState({}, '', baseUrl)
        } else {
            const sessionID = store.getState().session.sessionID
            if (sessionID) {
                const urlParams = new URLSearchParams(window.location.search)
                urlParams.set('sessionID', sessionID)
                window.history.replaceState(
                    {},
                    '',
                    baseUrl + '?' + urlParams.toString()
                )
            }
        }
    }

    getFromStorageOrRedux(key: string): string {
        let val = store.getState().session[key]
        if (!val) {
            val = localStorage.getItem(key)
        }
        return val
    }

    // startSession will start the process of running a session and return once the session is healthy
    async startSession(): Promise<SessionActiveEnum> {
        // TODO: use real IP, user agent, originator
        const userSession: UserSessionFromFirebase = newUserSession()
        let gameName = store.getState().session.gameName
        // This case only append when the user never went through the Home Page, so we get the gameName in the URL
        if (!gameName) {
            gameName = decodeURIComponent(window.location.href)
                .split('/game/')[1]
                // Without this line, it takes the game name with the query string, which causes an API error.
                .replace(window.location.search, '')

            store.dispatch(actions.session.setGameName(gameName))
        }
        const uid = firebase.auth().currentUser.uid
        const session: SessionFromFirebase = {
            Games: [
                {
                    GameID: gameName,
                    BYOG: this.getFromStorageOrRedux('byog') === 'true',
                    GameCore: this.getFromStorageOrRedux('gameCore'),
                    GameFile: this.getFromStorageOrRedux('gameFile'),
                },
            ],
            ID: store.getState().session.sessionID,
            Cluster: store.getState().session.cluster,
            Originator: uid,
            OriginatorUserSession: store.getState().session.userSessionID,
            UserSessions: [store.getState().session.userSessionID],
            Uids: [uid],
        }
        const cfg = await authorizedConfig()
        try {
            // this is a blocking call, the session will be activated successfully iff this call returns true
            await Axios.default.post(
                `${sessionStarfoxURL()}/api/session/start`,
                { session: session, userSession: userSession },
                cfg
            )
            store.dispatch(actions.session.setSession(session))
            store.dispatch(actions.session.setUserSession(userSession))
            return SessionActiveEnum.Yes
        } catch (error) {
            logAxiosErrorResponse(error)
            return this.responseCode(error)
        }
    }

    // run() ensures that no matter what situation the player was in before, a session container exists
    // in the backend for him/her to connect to
    async run(): Promise<SessionActiveEnum> {
        let state = await this.getSessionState()
        // save the state for further refresh of page
        // TODO: some of this could be persisted in firestore
        this.setWindowURL()

        // eslint-disable-next-line no-constant-condition
        while (true) {
            switch (state) {
                case SessionActiveEnum.Maybe:
                    state = await this.checkSessionState()
                    break
                case SessionActiveEnum.No:
                    state = await this.startSession()

                    // Stop the loop if the server reply with Forbidden
                    if (state === SessionActiveEnum.Forbidden) return state
                    // we need to call checkSessionState here because the conferencing
                    // initialization logic is made on the server side.
                    // TODO: by changing the return payload of /api/session/start endpoint
                    // to return the session's id instead of a simple OK, we could change this and
                    // save a network call.
                    state = await this.checkSessionState()
                    break
                default:
                    return state
            }
            await sleep(1000)
        }
    }
}
