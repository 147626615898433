import {
    OPEN_AUTH_MODAL,
    CLOSE_AUTH_MODAL,
    AuthModalState,
    AuthModalActionTypes,
    AuthModalType,
} from './types'

export const INITIAL_STATE: AuthModalState = {
    isOpen: false,
    onClose: null,
    modalType: AuthModalType.Login,
    closable: null,
    isTransparent: true,
}

export default function (
    state = INITIAL_STATE,
    action: AuthModalActionTypes
): AuthModalState {
    switch (action.type) {
        case OPEN_AUTH_MODAL:
            return {
                ...state,
                ...action.payload,
                isOpen: true,
            }
        case CLOSE_AUTH_MODAL:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}
