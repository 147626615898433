import * as React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { Icon } from '../../atoms/Icon'
import { Input } from '../..'

export interface SearchInputProps {
    /** current value of the input */
    value: string
    /** called when input value changes */
    onChange: (newValue) => void
    /** placeholder to be displayed when input is empty */
    placeholder?: string
    /** className to be applied to the component */
    className?: string
    /** type of Input component */
    type?: 'text' | 'number'
    /** handle on input click */
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
    /** clears input value*/
    onClose?: () => void
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
    (
        {
            placeholder,
            value,
            className,
            type = 'text',
            onClick,
            onChange,
            onClose,
        },
        ref
    ) => {
        const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target && onChange) {
                onChange(event.target.value)
            }
        }

        const clearable = value.length > 0

        return (
            <div className={classnames(styles.wrapper, className)}>
                <Icon
                    className={classnames(styles.icon, styles.iconSearch)}
                    name="search"
                />
                <Input
                    ref={ref}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    value={value}
                    type={type}
                    onClick={onClick}
                    className={classnames(styles.input, {
                        [styles.inputFill]: clearable,
                    })}
                />
                {clearable && onClose && (
                    <Icon
                        onClick={onClose}
                        className={classnames(styles.icon, styles.iconClose)}
                        name="close"
                    />
                )}
            </div>
        )
    }
)

export default SearchInput
