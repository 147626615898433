import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    SessionFromFirebase,
    SessionMoodType,
    UserInterfaceFromFirebase,
} from '../../state/starfox'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import cls from 'classnames'
import { RoomAccess, roomLanguages } from '../GameSettings'
import { JoinErrorType, Label, LabelType, RoomDistance } from './types'
import { getPings, piepie } from '../../utils'
import { GameModalType } from '../GameModal/types'
import store, { actions } from '../../store'
import { LocalStorage } from '../../state/storage'
import { coreToConsole, getPlayers, isFullOrEmpty } from './utils'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { getSession } from '../../firebase'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import PublicRoomsDescription from './PublicRoomsDescription'
import PublicRoomsAvatar from './PublicRoomsAvatar'
import PublicRoomsDetails from './PublicRoomsDetails'

interface IPublicRoomsItem {
    room: SessionFromFirebase
    players: UserInterfaceFromFirebase[]
    type: GameModalType
}

const PublicRoomsItem: FC<IPublicRoomsItem> = ({ room, players, type }) => {
    const MAX_NUMER_OF_PLAYERS = 8
    const { t } = useTranslation()
    const history = useHistory()
    const [isHovered, setIsHovered] = useState(false)
    const [backgroundImg /*setBackgroundImg*/] = useState(null)
    const [labels, setLabels] = useState<Label[]>([])
    const [currentPlayers, setCurrentPlayers] = useState<
        UserInterfaceFromFirebase[]
    >([])
    const [displayJoinError, setDisplayJoinError] = useState<JoinErrorType>(
        JoinErrorType.None
    )
    const closestCluster = useSelector(
        (state: State) => state.home.closestCluster
    )

    const getLanguageCode = (lang) => {
        for (const l of Object.keys(roomLanguages)) {
            if (l === lang) return l.toUpperCase()
        }
        return 'EN'
    }

    const getRoomAccess = useCallback(
        async (sessionID: string): Promise<boolean> => {
            const session = await getSession(sessionID)
            const stopped =
                session.Stopped as unknown as firebase.firestore.Timestamp
            if (typeof session.Stopped !== 'string' && stopped?.seconds > 0)
                return false
            return session?.Access === RoomAccess.PUBLIC
        },
        []
    )

    const updateLabels = useCallback(
        (
            language: string,
            closestCluster: string,
            mood: SessionMoodType | ''
        ) => {
            const l = [] as Label[]
            const pings = getPings()
            let distance = RoomDistance.None
            for (const [, ping] of Object.entries(pings)) {
                if (ping.cluster === closestCluster)
                    distance = RoomDistance.Close
                else if (ping.latency < 300) distance = RoomDistance.Close
                else if (ping.latency < 500) distance = RoomDistance.Far
                else if (ping.latency >= 500) distance = RoomDistance.VeryFar
            }
            if (
                distance !== RoomDistance.Close &&
                distance !== RoomDistance.None
            ) {
                l.push({
                    type: LabelType.Distance,
                    text: t(distance),
                })
            }
            const lang = getLanguageCode(language)
            if (lang) {
                l.push({
                    type: LabelType.Language,
                    text: lang,
                })
            }
            if (mood) {
                l.push({
                    type: LabelType.Tag,
                    text: t(`${mood}`),
                })
            }
            setLabels(l)
        },
        [t]
    )

    const onJoinHandler = useCallback(async () => {
        const maxNbPlayers = room.NumberOfPlayers || MAX_NUMER_OF_PLAYERS
        const p = await getPlayers(room.UserSessions, room.ID, maxNbPlayers)
        const isAvailable = await getRoomAccess(room.ID)
        if (p && !isFullOrEmpty(p, maxNbPlayers, true) && isAvailable) {
            store.dispatch(actions.session.clearSession())
            localStorage.setItem(LocalStorage.sessionID, '')
            if (!room.Games[0].BYOG) {
                console.log(room.Games[0])
                history.push(
                    `/game/${encodeURIComponent(
                        room.Games[0].GameID
                    )}?sessionID=${room.ID}`
                )
            } else {
                history.push(
                    `/game/${encodeURIComponent(
                        coreToConsole(room.Games[0].GameCore)
                    )}?sessionID=${room.ID}`
                )
            }
        } else if (isFullOrEmpty(p, maxNbPlayers, true)) {
            setDisplayJoinError(JoinErrorType.Full)
            setCurrentPlayers(p)
            piepie.log('[Open Rooms] err: cannot join this room: room is full')
        } else if (!isAvailable) {
            setDisplayJoinError(JoinErrorType.NotAvailable)
            piepie.log(
                '[Open Rooms] err: cannot join this room: room is private or stopped'
            )
        }
    }, [history, room, getRoomAccess])

    useEffect(() => {
        setDisplayJoinError(JoinErrorType.None)
        setCurrentPlayers([...players])
        updateLabels(room.Language, closestCluster, room.Mood)
        // Uncomment to add background image on cards
        // if (type === GameModalType.Mood && !room.IsCurrentBYOG) {
        //     setBackgroundImg(cardBgUrl(room.CurrentGame, 'landscape'))
        // }
    }, [room, players, type, closestCluster, updateLabels])

    return (
        <>
            <div
                className={cls({
                    [style.room]: !backgroundImg,
                    [style.roomWithBackground]: !!backgroundImg,
                })}
                onClick={onJoinHandler}
            >
                {!!backgroundImg && (
                    <picture className={style.roomPicture}>
                        <source
                            srcSet={`${backgroundImg}.webp?organizationId=942630971205`}
                            type="image/webp"
                        />
                        <source
                            srcSet={`${backgroundImg}.jpg?organizationId=942630971205`}
                            type="image/jpeg"
                        />
                        <img
                            src={`${backgroundImg}.jpg?organizationId=942630971205`}
                            alt={
                                room.CurrentGame?.split(' ').join('-') +
                                '-thumbnail'
                            }
                        />
                    </picture>
                )}
                <div
                    className={cls(style.roomHover, {
                        [style.isHovered]: isHovered,
                    })}
                ></div>
                <div
                    className={style.roomContent}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <PublicRoomsDetails
                        labels={labels}
                        isHovered={isHovered}
                        isDark={!!backgroundImg}
                    />
                    <PublicRoomsAvatar players={currentPlayers} />
                    <PublicRoomsDescription
                        title={room.RoomTitle}
                        type={type}
                        description={room.Description}
                        gameId={room.CurrentGame}
                    />
                </div>
                {displayJoinError !== JoinErrorType.None && (
                    <div className={style.error}>
                        <div className={style.errorIcon}>
                            <PriorityHighIcon />
                        </div>
                        <div className={style.errorMessage}>
                            {t(displayJoinError)}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PublicRoomsItem
