import styles from './styles.module.scss'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import GameGenre from './GameGenre'
import { piepie } from '../../../utils'
import EditProfile from '../../EditProfile'
import * as selector from '../state/selectors'
import PreferredGameModal from './PreferredGameModal'
import EditBirthday from '../../EditBirthday'

enum Logs {
    EditSuccess = '[ACCOUNT] profile nickname edited successfully',
    BirthdaySuccess = '[ACCOUNT] date of birth edited successfully',
    PreferredGamesSuccess = '[ACCOUNT] preferred games edited successfully',
}

interface IPersonalInformation {
    refreshUser: () => void
}

const PersonalInformation: React.FC<IPersonalInformation> = ({
    refreshUser,
}) => {
    const { t } = useTranslation()
    const [isEditModal, setEditModal] = useState<boolean>(false)
    const [isEditBirthday, setEditBirthday] = useState<boolean>(false)
    const [isPreferenceModal, setPreferenceModal] = useState<boolean>(false)

    const preferredGames = useSelector(selector.getPreferredGames)
    const dob = useSelector(selector.getBirthday)
    const userName = useSelector(selector.getUserName)
    const userEmail = useSelector(selector.getUserEmail)

    const handleEditSuccess = () => {
        refreshUser()
        setEditModal(false)
        piepie.log(Logs.EditSuccess)
    }

    const handleBirthdaySuccess = () => {
        refreshUser()
        setEditBirthday(false)
        piepie.log(Logs.BirthdaySuccess)
    }

    const handleProfileSuccess = () => {
        refreshUser()
        setPreferenceModal(false)
        piepie.log(Logs.PreferredGamesSuccess)
    }

    return (
        <div className={styles.section}>
            <Modal
                onHide={() => setEditModal(false)}
                show={isEditModal}
                centered
                enforceFocus={false}
            >
                <EditProfile
                    onSuccess={handleEditSuccess}
                    initialValues={{ email: userEmail, displayName: userName }}
                />
            </Modal>
            <Modal
                onHide={() => setEditBirthday(false)}
                show={isEditBirthday}
                centered
                enforceFocus={false}
            >
                <EditBirthday
                    initialValues={{
                        email: userEmail,
                        birthday: dob ? dob.toLocaleDateString() : '',
                    }}
                    onSuccess={handleBirthdaySuccess}
                    dob={dob}
                />
            </Modal>
            <Modal
                onHide={() => setPreferenceModal(false)}
                show={isPreferenceModal}
                centered
                enforceFocus={false}
            >
                <PreferredGameModal
                    onSuccess={handleProfileSuccess}
                    selectedGames={preferredGames}
                />
            </Modal>
            <p className={styles.header}>{t('PersonalInformation')}</p>
            <div className={styles.itemsContainer}>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('Nickname')}</p>
                        <p className={styles.itemValue}>{userName}</p>
                    </div>
                    <p
                        onClick={() => setEditModal(true)}
                        className={styles.action}
                    >
                        {t('UpdateNickname')}
                    </p>
                </div>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('DateOfBirth')}</p>
                        <p className={styles.itemValue}>
                            {dob?.toLocaleDateString()}
                        </p>
                    </div>
                    <p
                        onClick={() => setEditBirthday(true)}
                        className={styles.action}
                    >
                        {t('ChangeDOB')}
                    </p>
                </div>
                <GameGenre
                    preferredGames={preferredGames}
                    setPreferenceModal={setPreferenceModal}
                />
            </div>
        </div>
    )
}

export default PersonalInformation
