import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Title from './Title'
import Alerts from './Alerts'
import SignIn from './SignIn'
import { Email as SignUpWithEmail, Menu } from './SignUp'
import style from './styles.module.scss'
import VideoBackground from './VideoBackground'
import Guest from './Guest'
import Banned from './Banned'
import { Route } from '../../routes'
import ConfirmEmail from './ConfirmEmail'
import ResetPassword from './ResetPassword'
import { AuthModalType } from '../../store/auth/types'
import { selectCurrentView, setCurrentView } from '../../store/auth/authV2'
import { LocalStorage } from '../../state/storage'
import TryDesktopBanner from '../../components/TryDesktopBanner'
import { Icon } from '../../stories'

const Screens = {
    [AuthModalType.Login]: <SignIn />,
    [AuthModalType.Banned]: <Banned />,
    [AuthModalType.SignUpMenu]: <Menu />,
    [AuthModalType.EnterAsGuest]: <Guest />,
    [AuthModalType.SignUp]: <SignUpWithEmail />,
    [AuthModalType.ConfirmEmail]: <ConfirmEmail />,
    [AuthModalType.ResetPassword]: <ResetPassword />,
}

const Registration = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { search, pathname } = useLocation()
    const currentView = useSelector(selectCurrentView)
    const handleHomeClick = () => dispatch(setCurrentView(AuthModalType.Login))

    useEffect(() => {
        const urlParams = new URLSearchParams(search)
        const modalTypeFromUrl = urlParams.get('modalType')
        const availableModals = Object.values(AuthModalType)
        const sessionID = urlParams.get(LocalStorage.sessionID)

        if (
            modalTypeFromUrl &&
            availableModals.includes(modalTypeFromUrl as AuthModalType)
        ) {
            dispatch(setCurrentView(modalTypeFromUrl as AuthModalType))
            history.push('/')
        }

        if (pathname === Route.ConfirmSignUp) {
            dispatch(setCurrentView(AuthModalType.ConfirmEmail))
        }
        // If the user is a guest, we check if the sessionID is in the URL and we allow him/her to join the room
        // If the sessionID is not in the URL, we redirect the user to the Login/Signup form
        // Then once the user is logged in, it will redirect the user to the lobby to create a new session

        if (sessionID) {
            localStorage.setItem(LocalStorage.sessionID, sessionID)
            const baseUrl = [
                window.location.protocol,
                '//',
                window.location.host,
                window.location.pathname,
            ].join('')
            window.history.replaceState({}, '', baseUrl)

            dispatch(setCurrentView(AuthModalType.EnterAsGuest))
        }
    }, [dispatch, search, history, pathname])

    return (
        <div className={style.root}>
            <TryDesktopBanner />
            <div className={style.overlay} />
            <div className={style.container}>
                <aside className={style.aside}>
                    <div className={style.bodyWrapper}>
                        <div className={style.logo}>
                            <Link to="/" onClick={handleHomeClick}>
                                <Icon
                                    name={'jamMultiColor'}
                                    className={style.logoJam}
                                />
                            </Link>
                        </div>
                        <Title />
                        <Alerts />
                        {Screens[currentView]}
                    </div>
                </aside>
                <VideoBackground />
            </div>
        </div>
    )
}

export default Registration
