import * as Axios from 'axios'
import { AxiosErrorMessage } from './request'
import { transactionHostnameMap } from './env'
import { SessionState } from '../store/session/types'
import store from '../store'
import 'firebase/auth'
import { piepie } from './piepie'
import { getUserExtra } from '../firebase/users'
import Worker from '../worker'
import { setClosestCluster } from '../store/home/actions'
import { logAxiosErrorResponse } from './http'

const prodClusters = [
    // google
    'starfox.portland.gke-jam.com',
    'starfox.belgium.gke-jam.com',
    'starfox.tokyo.gke-jam.com',
    'starfox.iowa.gke-jam.com',
    'starfox.richmond.gke-jam.com',
    'starfox.singapore.gke-jam.com',
    'starfox.saopaolo.gke-jam.com',
    // aws
    'starfox.australia.eks-piepacker.com',
    'starfox.paris.eks-piepacker.com',
    'starfox.stockholm.eks-piepacker.com',
    'starfox.frankfurt.eks-piepacker.com',
    'starfox.milan.eks-piepacker.com',
    'starfox.bahrain.eks-piepacker.com',
]
const stagingClusters = ['starfox.api.piepackerstaging.com']
const defaultProdCluster = 'starfox.belgium.gke-jam.com'
const defaultStagingCluster = 'starfox.api.piepackerstaging.com'

// isValidCluster checks that a cluster URL is a valid starfox cluster
const isValidCluster = (cluster: string): boolean => {
    switch (process.env.REACT_APP_ENV) {
        case 'STAGING':
            return stagingClusters.includes(cluster)
        case 'PROD':
            return prodClusters.includes(cluster)
        default:
            return isValidHostname(cluster)
    }
}

export const isValidHostname = (hostname: string): boolean => {
    try {
        new URL(`https://${hostname}`)
    } catch (e) {
        console.error(e)
        return false
    }
    return true
}

// defaultStarfoxURL returns the default api URL. May not be the closest geographically.
export const defaultStarfoxURL = (protocolWs?: boolean): string => {
    const cluster = (() => {
        switch (process.env.REACT_APP_ENV) {
            case 'STAGING':
                return defaultStagingCluster
            case 'PROD':
                return defaultProdCluster
            default:
                return process.env.REACT_APP_HOSTNAME
        }
    })()
    const protocol = getProtocol(protocolWs)
    return `${protocol}://${cluster}`
}

// sessionStarfoxURL returns the api URL tied to the current session in redux if exists, otherwise an error
export const sessionStarfoxURL = (protocolWs?: boolean): string => {
    const session: SessionState = store.getState().session
    if (session && isValidCluster(session.cluster)) {
        const protocol = getProtocol(protocolWs)
        return `${protocol}://${session.cluster}`
    }
    throw new Error(`starfoxHostName not set or not valid: ${session?.cluster}`)
}

export const buildURL = (hostname: string, protocolWs?: boolean): string => {
    const protocol = getProtocol(protocolWs)
    return `${protocol}://${hostname}`
}

// starfoxHostName returns the hostname of the geographically closest valid starfox cluster
export const computeGeographicStarfoxHostName = async () => {
    let clusterName: string
    if (process.env.REACT_APP_ENV === 'DEV') {
        // Dev environments are unique and use the REACT_APP_HOSTNAME env var
        clusterName = specificHostName()
        sessionStorage.setItem('clusterName', clusterName)
        return clusterName
    } else {
        // Staging/Prod environments use the closest geographic
        switch (process.env.REACT_APP_ENV) {
            case 'STAGING':
                clusterName = await closestGeographic(
                    defaultStagingCluster,
                    stagingClusters
                )
                sessionStorage.setItem('clusterName', clusterName)
                return clusterName
            case 'PROD':
                clusterName = await closestGeographic(
                    defaultProdCluster,
                    prodClusters
                )
                sessionStorage.setItem('clusterName', clusterName)
                return clusterName
        }
        throw new Error('unknown environment')
    }
}

export interface PingCluster {
    cluster: string
    latency: number
}

// Create new instance
const instance = new Worker()
// TODO: improve this
let cachedClosestCluster: string
let cachedTimestamp: number
const cachedTimeoutMs = 300000
let pingClusters: PingCluster[] = []

export const updatePings = (cluster: string, latency: number) => {
    for (let i = 0; i < pingClusters.length; i++) {
        if (pingClusters[i].cluster === cluster) {
            pingClusters[i].latency = latency
            return
        }
    }
    pingClusters.push({
        cluster: cluster,
        latency: latency,
    })
}

export const getPings = (): PingCluster[] => {
    return pingClusters
}

const closestGeographic = async (
    defaultCluster: string,
    clusters: string[]
): Promise<string> => {
    const userExtra = await getUserExtra()
    if (
        userExtra.PreferredCluster &&
        isValidCluster(userExtra.PreferredCluster)
    ) {
        cachedClosestCluster = userExtra.PreferredCluster
        cachedTimestamp = Date.now()
        piepie.log(`closest cluster (preferred): ${cachedClosestCluster}`)
        return cachedClosestCluster
    }
    // update the timestamp, dump the cache if timeout reached.
    if (cachedTimestamp && Date.now() - cachedTimestamp > cachedTimeoutMs) {
        // empty cache
        cachedClosestCluster = undefined
    }
    if (cachedClosestCluster) {
        piepie.log(`[cached] closest cluster: ${cachedClosestCluster}`)
        return cachedClosestCluster
    }
    const protocolGeographic =
        process.env.REACT_APP_SECURE === 'true' ? 'https' : 'http'
    let minLatency = Number.MAX_SAFE_INTEGER
    let closestCluster = defaultCluster
    const promises = []
    for (const cluster of clusters) {
        promises.push(
            instance.getLatencyAndAvailability(protocolGeographic, cluster)
        )
    }
    const results = await Promise.allSettled(promises)
    for (const result of results) {
        const innerResult = await result
        if (innerResult.status !== 'rejected') {
            const la = innerResult.value
            piepie.log(
                `${la.cluster}: latency=${la.latency}, available=${la.availability}`
            )
            updatePings(la.cluster, la.latency)
            if (!la.availability) {
                // skip this cluster
                continue
            }
            if (la.latency < minLatency) {
                minLatency = la.latency
                closestCluster = la.cluster
            }
        }
    }
    cachedClosestCluster = closestCluster
    cachedTimestamp = Date.now()
    piepie.log(`closest cluster: ${closestCluster}`)
    store.dispatch(setClosestCluster(closestCluster))
    return closestCluster
}

const specificHostName = (/**protocolWs?: boolean*/) =>
    process.env.REACT_APP_HOSTNAME

const getProtocol = (protocolWs?: boolean): string => {
    let protocol = process.env.REACT_APP_SECURE === 'true' ? 'https' : 'http'
    if (protocolWs) {
        protocol = process.env.REACT_APP_SECURE === 'true' ? 'wss' : 'ws'
    }
    return protocol
}

export const getGeoInfo = async () => {
    try {
        const output = await Axios.default.get(`${defaultStarfoxURL()}/geogate`)
        return output
    } catch (err) {
        logAxiosErrorResponse(err)
        console.error(AxiosErrorMessage(err))
    }
}

export function getTransactionEmailServiceHostname() {
    const protocol = process.env.REACT_APP_SECURE === 'true' ? 'https' : 'http'
    return `${protocol}://${transactionHostnameMap[process.env.REACT_APP_ENV]}`
}
