import React, { useEffect, useState } from 'react'
import { Dropdown, Icon, Modal, SearchInput } from '../../../stories'
import { BYOG_TAG_NAME, useGamesList } from '../../../utils/useGamesList'
import { Game } from '../../../state/starfox'
import {
    useGameTags,
    useNumberOfPlayers,
    useSearchFilterSort,
    useSelectGame,
} from '../../GamesList/hooks'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'
import cls from 'classnames'
import ConfirmModal from '../../ConfirmModal'
import { ConfirmDialog } from '../../../state/confirm'
import GamesList from '../../GamesList'
import { usePausePlay } from '../../../utils/GameRoomHooks'

//TODO: This file will be refactored after the release

export const SelectingGameModal = ({ section, setSection, setShowOptions }) => {
    const { t } = useTranslation()
    const { handleResumeGame } = usePausePlay()

    /* Modal */
    const [show, setShow] = useState(false)

    const handleClose = () => {
        handleResumeGame()
        setShow(false)
        setSection('pause')
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (section === 'switch') {
            handleShow()
        }
    }, [section])

    /* Modal navbar sections */
    const [showSection, setShowSection] = useState('')

    /* Game select */
    const [selectedGame, setSelectedGame] = useState<string>('')
    const handleGameSelect = useSelectGame()

    /* Sorting gameList */

    const initialGamesList: ReadonlyArray<Game> =
        useGamesList().gamesList.filter(
            (game) => !game.Tags.includes(BYOG_TAG_NAME)
        )

    const gameTags = useGameTags(initialGamesList)
    const gameTagsData = [t('SwitchGameTypeOfGame'), ...gameTags].map(
        (tag, index) => {
            return { id: index, value: tag }
        }
    )

    const { state, gamesList, handlers, isListModified } =
        useSearchFilterSort(initialGamesList)

    const { handleSearchItemChange, handleTagChange, handleNOPChange } =
        handlers

    /* Tag dropdown */

    const [showTagDropdown, setShowTagDropdown] = useState(false)
    const [selectedTag, setSelectedTag] = useState(gameTagsData[0].value)
    const handleTagToggle = () => setShowTagDropdown((param) => !param)

    const filteredGameTagsData = gameTagsData?.filter(
        (item) => item.value !== selectedTag
    )

    const handleSelectTag = (tag) => {
        if (tag === gameTagsData[0].id) {
            handleTagChange('')
        } else {
            handleTagChange(gameTagsData[tag].value)
        }
        setSelectedTag(gameTagsData[tag].value)
    }

    /* numberOfPlayers dropdown */

    const userNumberOptions = useNumberOfPlayers()

    const numberOfPlayersData = userNumberOptions.map((option) => {
        return { id: option.value, value: option.name }
    })

    const [showPlayersDropdown, setShowPlayersDropdown] = useState(false)
    const handlePlayersToggle = () => setShowPlayersDropdown((param) => !param)
    const [selectedNumberOfPlayers, setSelectedNumberOfPlayers] = useState(
        numberOfPlayersData[0].value
    )
    const handleSelectNumberOfPlayers = (val) => {
        const selectedItem = numberOfPlayersData.find((i) => i.id === val)
        setSelectedNumberOfPlayers(selectedItem.value)
        handleNOPChange(selectedItem.id)
    }

    const filteredNOPData = numberOfPlayersData?.filter(
        (item) => item.value !== selectedNumberOfPlayers
    )

    if (initialGamesList.length === 0) return null

    return (
        <Modal
            show={show}
            onClose={handleClose}
            title={t('StartNewGame')}
            size="fullscreen"
        >
            <div className={style.selectingGameModal}>
                <div className={style.filters}>
                    <span
                        onClick={() => setShowSection('search')}
                        className={cls(
                            style.section,
                            showSection !== 'search' &&
                                showSection !== '' &&
                                style.disappear
                        )}
                    >
                        {showSection === 'search' && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowSection('')
                                }}
                            >
                                <Icon name="arrowBack" />
                            </span>
                        )}
                        <SearchInput
                            onChange={handleSearchItemChange}
                            value={state.search}
                            placeholder={t('SwitchGameSearch')}
                        />
                    </span>
                    <span
                        onClick={() => setShowSection('tags')}
                        className={cls(
                            style.section,
                            showSection !== 'tags' &&
                                showSection !== '' &&
                                style.disappear
                        )}
                    >
                        {showSection === 'tags' && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowSection('')
                                    setShowTagDropdown(false)
                                }}
                            >
                                <Icon name="arrowBack" />
                            </span>
                        )}
                        {filteredGameTagsData && (
                            <Dropdown
                                data={filteredGameTagsData}
                                show={showTagDropdown}
                                onToggle={handleTagToggle}
                                selectedItem={selectedTag}
                                onSelect={handleSelectTag}
                            />
                        )}
                    </span>
                    <span
                        onClick={() => setShowSection('players')}
                        className={cls(
                            style.section,
                            showSection !== 'players' &&
                                showSection !== '' &&
                                style.disappear
                        )}
                    >
                        {showSection === 'players' && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowSection('')
                                    setShowPlayersDropdown(false)
                                }}
                            >
                                <Icon name="arrowBack" />
                            </span>
                        )}
                        <Dropdown
                            data={filteredNOPData}
                            show={showPlayersDropdown}
                            onToggle={handlePlayersToggle}
                            selectedItem={selectedNumberOfPlayers}
                            onSelect={handleSelectNumberOfPlayers}
                        />
                    </span>
                </div>

                <div className={style.games}>
                    {isListModified ? (
                        <GamesList.Uncategorized
                            handleGameSelect={setSelectedGame}
                            initialGamesList={initialGamesList}
                            gamesList={gamesList}
                        />
                    ) : (
                        <GamesList.Categorized
                            handleGameSelect={setSelectedGame}
                            gamesList={gamesList}
                        />
                    )}
                </div>
            </div>

            <ConfirmModal
                confirmDialog={ConfirmDialog.SwitchGame}
                open={selectedGame.length > 0}
                onConfirm={() => {
                    handleGameSelect(selectedGame)
                    setShowOptions(false)
                    setSection('pause')
                }}
                onClose={() => setSelectedGame('')}
                gameTitle={
                    selectedGame.length < 0
                        ? ''
                        : initialGamesList.find((g) => g.id === selectedGame)
                              ?.DisplayName
                }
            />
        </Modal>
    )
}
