import React, { useEffect, forwardRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import cls from 'classnames'
import style from './style.module.scss'
import { Icon } from '../Icon'
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll'

//NOTE: In the app, the modal will be rendered in the #modal-root div which is embeded in the app layout
//NOTE: In the storybook, the modal will be rendered in the #modal-root div which is embeded in storybooks html body element

interface ModalProps {
    size?: 'sm' | 'md' | 'lg' | 'fullscreen' | 'fullwidth' | 'fullheight'
    title?: string | JSX.Element
    show?: boolean
    onClose: () => void
    children?: string | JSX.Element
    footer?: string | JSX.Element
    closeOnEscapeKeyDown?: boolean
    closeOnClickOutside?: boolean
    position?: 'top' | 'center' | 'bottom'
    backdrop?: boolean
    className?: string
    contentClassName?: string
    bodyClassName?: string
    container?: Element
    [rootHtmlAttributes: string]: any
}
const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    const {
        size = 'sm',
        show = false,
        onClose,
        title,
        children = '',
        footer,
        closeOnEscapeKeyDown = true,
        closeOnClickOutside = true,
        position = 'center',
        backdrop = true,
        className,
        contentClassName,
        bodyClassName,
        container,
        ...rootHtmlAttributes
    } = props
    const {
        modal,
        modalContent,
        modalEnter,
        modalEnterActive,
        modalEnterDone,
        modalExit,
        modalExitActive,
        modalHeader,
        modalBody,
        modalFooter,
        modalTitle,
    } = style

    useLockBodyScroll()

    const closeEscapeKeyDown = useCallback(
        (e) => {
            if ((e.charCode || e.keyCode) === 27) {
                onClose()
            }
        },
        [onClose]
    )

    useEffect(() => {
        const closeEscapeKeyDown = (e) => {
            if ((e.charCode || e.keyCode) === 27) {
                onClose()
            }
        }

        if (window && closeOnEscapeKeyDown) {
            document.body.addEventListener('keydown', closeEscapeKeyDown)
        }
        return () => {
            if (closeOnEscapeKeyDown) {
                document.body.removeEventListener('keydown', closeEscapeKeyDown)
            }
        }
    }, [closeOnEscapeKeyDown, onClose, closeEscapeKeyDown])

    return createPortal(
        <CSSTransition
            in={show}
            unmountOnExit
            timeout={{ exit: 300 }}
            classNames={{
                enter: modalEnter,
                enterActive: modalEnterActive,
                enterDone: modalEnterDone,
                exit: modalExit,
                exitActive: modalExitActive,
            }}
        >
            <div
                ref={ref}
                onClick={closeOnClickOutside && onClose}
                className={cls(
                    className,
                    modal,
                    style[position],
                    backdrop && style.backdrop
                )}
            >
                <div
                    className={cls(modalContent, style[size], contentClassName)}
                    onClick={(e) => closeOnClickOutside && e.stopPropagation()}
                    {...rootHtmlAttributes}
                >
                    <div className={modalHeader}>
                        {title ? (
                            typeof title === 'string' ? (
                                <div className={modalTitle}>{title}</div>
                            ) : (
                                title
                            )
                        ) : null}
                        {onClose && (
                            <Icon
                                name="close"
                                onClick={onClose}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                    </div>

                    <div className={cls(modalBody, bodyClassName)}>
                        {children}
                    </div>

                    {footer ? (
                        <div className={modalFooter}>{footer}</div>
                    ) : null}
                </div>
            </div>
        </CSSTransition>,
        container || document.getElementById('modal-root')
    )
})

export default Modal
