import React, { FC, useCallback, useState, useEffect } from 'react'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'
import { SessionFromFirebase, SessionMoodType } from '../../state/starfox'
import { FilteredRoomType } from './types'
import {
    getLastTimeFiltered,
    getPlayers,
    isFullOrEmpty,
    MAX_NUMER_OF_PLAYERS,
    setLastTimeFiltered,
} from './utils'
import { RoomAccess } from '../GameSettings/constants'
import { GameModalType } from '../GameModal/types'
import { byog_config } from '../../pages/BYOG/config'
import { LanguageFilter } from './LanguageFilter'
import { isMobile } from 'react-device-detect'

interface IRoomFilters {
    rooms: SessionFromFirebase[]
    gameId: string
    type: GameModalType
    mood: SessionMoodType
    setFilteredRooms: React.Dispatch<React.SetStateAction<FilteredRoomType[]>>
}

const RoomFilters: FC<IRoomFilters> = ({
    rooms,
    gameId,
    type,
    mood,
    setFilteredRooms,
}) => {
    const { t } = useTranslation()
    const [language, setLanguage] = useState<string>(null)

    const filterHandler = useCallback(
        async (
            sessions: SessionFromFirebase[],
            language: string,
            mood: SessionMoodType,
            gameId: string,
            type: GameModalType
        ) => {
            let currentCore = ''
            let currentConsole = ''
            if (type === GameModalType.BYOG) {
                for (const [code, console] of Object.entries(
                    byog_config.session
                )) {
                    if (console === gameId) {
                        currentConsole = code
                        break
                    }
                }
            }
            let filteredRooms: FilteredRoomType[] = []
            let fullRooms: FilteredRoomType[] = []
            for (const [, session] of Object.entries(sessions)) {
                if (session.Access !== RoomAccess.PUBLIC) continue
                if (type === GameModalType.BYOG) {
                    if (!session.IsCurrentBYOG) continue
                    currentCore =
                        session.Games[session.Games.length - 1].GameCore
                    if (byog_config.cores[currentConsole] !== currentCore)
                        continue
                }
                if (type === GameModalType.Mood && mood !== session.Mood) {
                    continue
                }
                let time = Date.now()
                // we do not keep rooms that are too old (3 days)
                const started = new Date(session.Started).getTime()
                if (time - started > 1000 * 60 * 60 * 24 * 3) continue
                const players = await getPlayers(
                    session.UserSessions,
                    session.ID,
                    session.NumberOfPlayers || MAX_NUMER_OF_PLAYERS
                )
                // we cancel the current filtering if another one has been triggered
                // because of the requests + caching system, another filtering action can cross the first one, with a really bad timing
                // that's why we do this check just after the request, and we do it again at the end for more safety
                if (getLastTimeFiltered() > time) return
                time = Date.now()
                // if the room is empty, we ignore it
                if (isFullOrEmpty(players)) continue
                let lang = false
                if (!language || session.Language === language) lang = true
                if (lang) {
                    // if the room is full, we'll keep it for the end
                    if (isFullOrEmpty(players, session.NumberOfPlayers, true)) {
                        fullRooms.push({
                            session: session,
                            players: players,
                        })
                        continue
                    }
                    filteredRooms.push({
                        session: session,
                        players: players,
                    })
                }
                // we cancel the current filtering if another one has been triggered
                if (getLastTimeFiltered() > time) return
                setLastTimeFiltered(Date.now())
                // we update the filteredRooms state one by one while in the loop to prevent the user to wait too long before seeing rooms
                setFilteredRooms([...filteredRooms])
            }
            // we also need to update the filtered rooms after the loop in case setFilteredRooms() was never reached
            // which can happen if a room was passed to 'private' in the meantime
            // we add the full rooms at the end
            setFilteredRooms([...filteredRooms, ...fullRooms])
        },
        [setFilteredRooms]
    )

    useEffect(() => {
        if (!rooms || rooms.length === 0) return
        setLastTimeFiltered(Date.now())
        filterHandler(rooms, language, mood, gameId, type)
    }, [rooms, language, mood, gameId, type, filterHandler])

    return (
        <div className={style.filters}>
            <div className={cls(style.filter, style.modalLanguages)}>
                <LanguageFilter
                    language={language}
                    placeholder={
                        isMobile
                            ? t('PublicRoomsFiltersSearchLanguageMobile')
                            : t('PublicRoomsFiltersSearchLanguage')
                    }
                    handleChange={setLanguage}
                />
            </div>
        </div>
    )
}

export default RoomFilters
