import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { PreferredGameType } from '../../../state/starfox'

interface IGameGenre {
    preferredGames: PreferredGameType[]
    setPreferenceModal: (val: boolean) => void
}

const GameGenre: React.FC<IGameGenre> = ({
    preferredGames,
    setPreferenceModal,
}) => {
    const { t } = useTranslation()

    return (
        <div className="flex-spacebetween">
            <div>
                <p className={styles.microFont}>{t('PreferredGame')}</p>
                <div className={styles.preferredGameContainer}>
                    {preferredGames &&
                        preferredGames.length > 0 &&
                        preferredGames.map((game) => (
                            <div key={game} className={styles.preferredGame}>
                                {t(`PreferredGame${game}`)}
                            </div>
                        ))}
                </div>
            </div>
            <p
                onClick={() => setPreferenceModal(true)}
                className={styles.action}
            >
                {t('UpdatePreference')}
            </p>
        </div>
    )
}

export default GameGenre
