import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from '../types'
import { AuthModalType } from './types'

export interface AuthState {
    currentView: AuthModalType
    submitError: string
    submitSuccess: string
}

export const initialState: AuthState = {
    submitError: '',
    submitSuccess: '',
    currentView: AuthModalType.Login,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCurrentView: (state, action: PayloadAction<AuthModalType>) => {
            state.currentView = action.payload
        },
        setSubmitError: (state, action: PayloadAction<string>) => {
            state.submitError = action.payload
        },
        setSubmitSucess: (state, action: PayloadAction<string>) => {
            state.submitSuccess = action.payload
        },
    },
})

// actions
export const { setCurrentView, setSubmitError, setSubmitSucess } =
    authSlice.actions

// selectors
export const selectCurrentView = (state: State) => state.auth.currentView
export const selectSubmitError = (state: State) => state.auth.submitError
export const selectSubmitSuccess = (state: State) => state.auth.submitSuccess
export const selectShowLanguageSelect = (state: State) =>
    [
        AuthModalType.Login,
        AuthModalType.SignUp,
        AuthModalType.SignUpMenu,
        AuthModalType.EnterAsGuest,
    ].includes(state.auth.currentView)

export default authSlice
