import { SessionActiveEnum } from '../../store/session/types'
import { useCallback, useEffect } from 'react'
import { piepie } from '../../utils'
import * as Socket from '../socket'
import store, { actions } from '../../store'

export const useSocket = (
    e: SessionActiveEnum,
    setEnterClickable: (b: boolean) => void
) => {
    const fn = useCallback(
        async (e: SessionActiveEnum) => {
            if (e !== SessionActiveEnum.Yes) {
                // session is not yet active or failed
                return
            }
            piepie.log('[GAME] init socket')
            await Socket.init()
            // this will ensure the button is clickable
            setEnterClickable(true)
            store.dispatch(
                actions.session.setSessionActive(SessionActiveEnum.YesWS)
            )
        },
        [setEnterClickable]
    )
    useEffect(() => {
        fn(e)
        return () => {
            // TODO: cleanup ws state here. Useful to avoid page refresh when
            // changing app in the future. Should close websocket connection
        }
    }, [e, fn])
}
