import {
    OPEN_AUTH_MODAL,
    CLOSE_AUTH_MODAL,
    AuthModalActionTypes,
    AuthModalState,
} from './types'

export function openAuthModal(modal?: AuthModalState): AuthModalActionTypes {
    return {
        type: OPEN_AUTH_MODAL,
        payload: modal,
    }
}

export function closeAuthModal(): AuthModalActionTypes {
    return {
        type: CLOSE_AUTH_MODAL,
    }
}
