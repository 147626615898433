import React, { ReactNode, FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'
import { Icon } from '../../atoms/Icon'

interface GameroomModalProps {
    show: boolean
    size?: 'sm' | 'md' | 'lg' | 'verticalWrap'
    header: string | ReactNode
    headerPosition?: 'center' | 'start'
    onClose: () => void
    children: string | ReactNode
}

const GameroomModal: FC<GameroomModalProps> = ({
    show = false,
    size,
    header = '',
    headerPosition = 'start',
    onClose,
    children = '',
}) => {
    return (
        <>
            {show && (
                <div className={style.gameroomModal}>
                    <div
                        className={cls(style.modalContent, size && style[size])}
                    >
                        <div
                            className={cls(
                                style.header,
                                headerPosition && style[headerPosition]
                            )}
                        >
                            {typeof header === 'string' ? (
                                <p>{header}</p>
                            ) : (
                                header
                            )}
                            <Icon name="close" onClick={() => onClose()} />
                        </div>
                        <div className={style.body}>{children}</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default GameroomModal
