import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { GameroomModalSectionType } from '../../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../../store/gameroomModalSection/gameroomModalSection'
import { State } from '../../../store/types'
import { GameroomModal } from '../../../stories'
import { usePausePlay } from '../../../utils/GameRoomHooks'
import { isGame } from '../../../utils/loading'
import { isPremium } from '../../../utils/subscription'
import style from './style.module.scss'

export const GameSaveStatesModal = ({ children }) => {
    const { handleResumeGame } = usePausePlay()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [show, setShow] = useState<boolean>(false)
    const gameState = useSelector((state: State) => state.session.gameState)
    const user = useSelector((state: State) => state.user.extra)
    const disableSavestates = useSelector(
        (state: State) => state.session.disableSavestates
    ) as boolean

    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const handleClose = useCallback(() => {
        setShow(false)
        handleResumeGame()
        dispatch(
            gameroomModalSectionSlice.actions.setGameroomModal(
                GameroomModalSectionType.NONE
            )
        )
    }, [handleResumeGame, dispatch])

    const handleShow = () => setShow(true)

    useEffect(() => {
        if (
            isGame(gameState).paused &&
            gameroomModalSection === GameroomModalSectionType.LOAD &&
            !disableSavestates
        ) {
            handleShow()
        } else if (gameroomModalSection === GameroomModalSectionType.NONE) {
            handleClose()
        }
    }, [gameState, gameroomModalSection, disableSavestates, handleClose])

    const Header = <div className={style.header}>{t('LoadSavedGame')}</div>

    return (
        <>
            {isPremium(user) ? (
                <GameroomModal
                    show={show}
                    header={Header}
                    onClose={handleClose}
                    size="lg"
                >
                    {children}
                </GameroomModal>
            ) : (
                <GameroomModal
                    show={show}
                    header={Header}
                    onClose={handleClose}
                >
                    {children}
                </GameroomModal>
            )}
        </>
    )
}
