import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setCategoryDisplayed } from '../../../store/home/homeV2'
import { Icon } from '../../../stories'
import styles from './styles.module.scss'

interface TitleProps {
    title: string
    category?: string
    isHovered?: boolean
    displaySeeMore?: boolean
}

const Title: FC<TitleProps> = ({
    title,
    category = null,
    isHovered = false,
    displaySeeMore = false,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const shouldDisplaySeeMore =
        ![
            'BYOG',
            'Featured',
            'Mobile-Featured',
            'RecentlyPlayed',
            'Moods',
        ].includes(category) &&
        !!category &&
        isHovered &&
        displaySeeMore
    return (
        <div className={styles.title}>
            <div className={styles.categoryTitle}>{t(title)}</div>
            {shouldDisplaySeeMore && (
                <div
                    className={styles.categorySeeMore}
                    onClick={() => dispatch(setCategoryDisplayed(category))}
                >
                    <span>{t('SeeMore')}</span>
                    <div className={styles.iconWrapper}>
                        <Icon name="arrows" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Title
