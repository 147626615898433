import React, { useState, useEffect, useRef, useCallback } from 'react'

import cx from 'classnames'
import IdleTimer from 'react-idle-timer'
import { loadGame, sendFps } from '../../network/socket'
import * as Socket from '../../network/socket'

import store, { actions } from '../../store'
import RecordHandler from '../../handlers/RecordHandler'
import * as Input from '../../input/input'
import ConfirmModal from '../ConfirmModal'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

import { visibleIf, piepie, isByogMode } from '../../utils'

import { ConfirmDialog } from '../../state/confirm'
import { SessionType } from '../../state/screen'
import { Video, VideoLoader } from './screen-components'
import Savestates from '../Savestates'
import HighlightScreenMain from '../HighlightScreen'
import { InnerScreenProps } from './screen-props'
import ScreenMenu from './ScreenMenu'
import { clearSessionState } from '../../starfox/starfox'
import Confirmation, { ConfirmationType } from './Confirmation'
import { useNetworkProbe } from '../../pages/Game/hooks'
import { State } from '../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { getSession } from '../../firebase'
import { AlertStack } from '../../stories'
import { isGame, showLoading } from '../../utils/loading'
import useKeyboardPause from '../../utils/useKeyboardPause'
import rtcpSlice from '../../store/rtcp/rtcp'
import alertSlice from '../../store/alert/alert'
import screenSlice from '../../store/screen/screen'
import GameListScreenOverlay, {
    GuestScreenOverlay,
} from './GameListScreenOverlay'
import {
    GamePauseModal,
    GameSaveStatesModal,
    GameSettingsModal,
} from './GameScreenModals'
import { GameroomModalSectionType } from '../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../store/gameroomModalSection/gameroomModalSection'
import { isMobile } from 'react-device-detect'
import { GAME_STATE } from '../../store/session/types'

function StreamingScreen(props: InnerScreenProps) {
    const {
        gameScreenWidth,
        volume,
        leavingGame,
        updateVolume,
        setInitialControlOverlay,
    } = props
    const dispatch = useDispatch()
    const alertList = useSelector((state: State) => state.alert.list)
    const video = useRef(null)
    const canvas = useRef(null)
    const game = useSelector((state: State) => state.session.game)
    const user = useSelector((state: State) => state.user.user)
    const gameState = useSelector((state: State) => state.session.gameState)
    const startGame = useSelector((state: State) => state.screen.startGame)
    const websocketConnReady = useSelector(
        (state: State) => state.socket.connectionReady
    )
    const mediaStreamReady = useSelector(
        (state: State) => state.rtcp.mediaStreamReady
    )
    const sessionType = useSelector((state: State) => state.screen.sessionType)
    const highlightStatus = useSelector(
        (state: State) => state.screen.highlightScreenStatus
    )
    const initialControlOverlayTimer = useRef(null)
    const isHost = useSelector((state: State) => state.session.isHost)
    const isFirstFetch = useRef(true)
    const frameCounter = useRef(0)
    const lowPerf = useSelector((state: State) => state.user.extra?.LowPerf)

    const openReload = useSelector(
        (state: State) => state.screen.openReload
    ) as boolean
    const disableSavestates = useSelector(
        (state: State) => state.session.disableSavestates
    ) as boolean
    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const [muted, setMuted] = useState<boolean>(true)
    const [recordHandler, setRecordHandler] = useState<RecordHandler>(null)
    const [loadingOver, setLoadingOver] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )
    const [confirmNotif, setConfirmNotif] = useState<ConfirmationType>(
        ConfirmationType.None
    )

    useEffect(() => {
        if (openReload) {
            setConfirmNotif(ConfirmationType.Reload)
        } else {
            setConfirmNotif(ConfirmationType.None)
        }
    }, [openReload])

    // TODO: use savehandler
    // if an automatic savestate already exists, then we ask the user if he wants to reload it
    const checkAutoSaveExists = useCallback(async () => {
        // we check there are no other player in the room, it can happen when the host refresh the page
        const playerIndices = store.getState().session.playerIndices
        if (Object.keys(playerIndices).length > 1) {
            return
        }
        const storageRef = firebase.storage().ref()
        let gameName = game.id
        if (isByogMode(gameName)) {
            const session = await getSession(store.getState().session.sessionID)
            const lastGameIndex = session.Games.length - 1
            gameName = 'BYOG/' + session.Games[lastGameIndex].GameID
        }
        const savestatesRef = storageRef.child(
            `user/${user.uid}/savestates/${gameName}`
        )
        const stt = await savestatesRef.listAll()
        for (const [, itemRef] of Object.entries(stt.items)) {
            const name = itemRef.name.replace('.state', '')
            if (name === 'auto') {
                piepie.log('[STREAMING SCREEN] autosave exists')
                setConfirmNotif(ConfirmationType.Reload)
                return
            }
        }
        piepie.log('[STREAMING SCREEN] autosave does not exist')
    }, [game.id, user.uid])

    const onConfirmationValidate = useCallback(() => {
        switch (confirmNotif) {
            case ConfirmationType.Reload:
                if (isHost) {
                    Socket.loadGame('auto')
                    piepie.log('[STREAMING SCREEN] load autosave')
                    dispatch(
                        gameroomModalSectionSlice.actions.setGameroomModal(
                            GameroomModalSectionType.NONE
                        )
                    )
                }
                break
            default:
                // No action
                break
        }
        setConfirmNotif(ConfirmationType.None)
        store.dispatch(screenSlice.actions.setOpenReload(false))
    }, [confirmNotif, isHost, dispatch])

    const onConfirmationClose = useCallback(() => {
        setConfirmNotif(ConfirmationType.None)
        store.dispatch(screenSlice.actions.setOpenReload(false))
    }, [])

    const fps = useCallback(() => {
        const ips = frameCounter.current / 60
        frameCounter.current = 0

        piepie.log('[STREAMING SCREEN] fps:', ips)
        sendFps(ips.toString())

        setTimeout(fps, 60000)
    }, [])

    useNetworkProbe()

    useEffect(() => {
        piepie.log('[STREAMING SCREEN] ---\nEntering Cloud Mode\n---')
    }, [])

    // TODO: refactor this function. This file should not refer to rtcp/socket. Should move to webrtc
    // handler.
    // piepie.log('[STREAMING SCREEN] connection ready: ', connectionReady)
    const rtcpConnected = useSelector((state: State) => state.rtcp.connected)
    const inputReady = useSelector((state: State) => state.rtcp.inputReady)
    useEffect(() => {
        if (!websocketConnReady || !startGame || !rtcpConnected || !inputReady)
            return
        piepie.log('[STREAMING SCREEN] webrtc connection ready')
        piepie.log('[STREAMING SCREEN] starting the game')
        // Start playing
        // note we are not using gameState from the useSelector, because we do not want a change
        // in the game state to re-run this useEffect
        if (!isGame(store.getState().session.gameState).inSelectionScreen) {
            store.dispatch(actions.session.setGameStateReady())
        }
        setLoadingOver(true)
        setMuted(false)
        Input.unsetLocalInput()

        // Start the game
        Socket.startGame()

        // autosave
        if (isHost && sessionType === SessionType.LOCAL && !disableSavestates) {
            store.dispatch(screenSlice.actions.setOpenReload(false))
            checkAutoSaveExists()
        }

        if (!lowPerf) {
            const ctx = canvas.current.getContext('2d')
            const updateCanvas = () => {
                if (!video.current) return
                if (video.current.videoWidth !== canvas.current.width)
                    canvas.current.width = video.current.videoWidth
                if (video.current.videoHeight !== canvas.current.height)
                    canvas.current.height = video.current.videoHeight
                ctx.drawImage(
                    video.current,
                    0,
                    0,
                    canvas.current.width,
                    canvas.current.height
                )
                frameCounter.current++
                requestAnimationFrame(updateCanvas)
            }
            updateCanvas()
            fps()
        }

        Socket.sendQueryNumberCd()

        // once game has started producing video, it's the right time to check if the
        // game was paused or not before
    }, [
        websocketConnReady,
        rtcpConnected,
        inputReady,
        startGame,
        isHost,
        sessionType,
        lowPerf,
        disableSavestates,
        checkAutoSaveExists,
        fps,
    ])

    // we exit fullscreen if we open the modal
    useEffect(() => {
        if (confirmDialog !== ConfirmDialog.None) {
            store.dispatch(screenSlice.actions.setTheater(false))
            store.dispatch(screenSlice.actions.setFullscreen(false))
        }
    }, [confirmDialog])

    const onConfirmModalValidate = useCallback(() => {
        switch (confirmDialog) {
            case ConfirmDialog.Quit:
                if (isHost) {
                    Socket.quitGame()
                    piepie.log('[STREAMING SCREEN] Host closed the room')
                }
                clearSessionState()
                leavingGame()
                break
            default:
                // No action
                break
        }
        setConfirmDialog(ConfirmDialog.None)
    }, [confirmDialog, leavingGame, isHost])

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
    }, [])

    useEffect(() => {
        if (mediaStreamReady) {
            console.log(
                `RTCP: mediaStreamReady ${mediaStreamReady} video ${video.current}`
            )
            store.dispatch(rtcpSlice.actions.setVideo(video.current))
        }
    }, [mediaStreamReady])

    useEffect(() => {
        if (isFirstFetch.current) {
            if (video.current) video.current.focus()

            setRecordHandler(
                new RecordHandler(video.current.srcObject, game.id)
            )
            isFirstFetch.current = false
        }
        return () => {
            if (recordHandler) recordHandler.deinit()
        }
    }, [recordHandler, game.id])

    useEffect(() => {
        // this hook is used for any code which has to be run at the start of the game.
        // it is triggered by a parent.
        if (loadingOver) {
            // This is used to ensure that when the mouse does not hover over the screen initially,
            // we still show settings
            initialControlOverlayTimer.current = setTimeout(() => {
                setInitialControlOverlay(false)
            }, 4000)
        }

        return () => {
            clearTimeout(initialControlOverlayTimer.current)
        }
    }, [loadingOver, setInitialControlOverlay])

    useEffect(() => {
        // this effect is used for volume control
        if (isNaN(volume)) {
            video.current.volume = 0
            updateVolume && updateVolume(0)
        } else {
            video.current.volume = volume / 100
        }
    }, [volume, updateVolume])

    useKeyboardPause()

    const videoTag = useCallback(() => {
        if (lowPerf) {
            return (
                <>
                    {!showLoading(gameState, highlightStatus) && (
                        <div data-test="GameLoaded"></div>
                    )}
                    <Video
                        muted={muted}
                        playing={isGame(gameState).ready}
                        className={'lowPerf'}
                        ref={video}
                        tabIndex={1}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Video
                        muted={muted}
                        playing={isGame(gameState).ready}
                        className={'highPerf'}
                        ref={video}
                    />
                    {/* tabIndex is needed for the focus and input listeners */}
                    {!showLoading(gameState, highlightStatus) && (
                        <div data-test="GameLoaded"></div>
                    )}
                    <canvas
                        data-test="CanvasGame"
                        id="game-canvas"
                        className={cx(
                            game.id === 'Pong' && 'game-canvas-with-border'
                        )}
                        tabIndex={1}
                        ref={canvas}
                    />
                </>
            )
        }
    }, [muted, gameState, lowPerf, highlightStatus, game.id])

    const resetGame = () => {
        Socket.resetGame()
        dispatch(
            gameroomModalSectionSlice.actions.setGameroomModal(
                GameroomModalSectionType.NONE
            )
        )
    }

    const renderModals = () => {
        switch (gameroomModalSection) {
            case GameroomModalSectionType.PAUSE:
                return <GamePauseModal resetGame={resetGame} />
            case GameroomModalSectionType.LOAD:
                return (
                    <GameSaveStatesModal>
                        <Savestates
                            user={user}
                            gameName={game.id}
                            gameScreenWidth={gameScreenWidth}
                            idleState={props.idleState}
                            callback={loadGame}
                        />
                    </GameSaveStatesModal>
                )

            case GameroomModalSectionType.SWITCH:
                return <GameListScreenOverlay />

            case GameroomModalSectionType.SETTINGS:
                return <GameSettingsModal />
            default:
                break
        }
    }

    // Top section with game screen

    return (
        <div>
            {videoTag()}
            {/* tabIndex is needed for the focus and input listeners */}
            <HighlightScreenMain status={highlightStatus} />
            <div
                id="loading-screen"
                style={visibleIf(showLoading(gameState, highlightStatus))}
            >
                <VideoLoader loading={isGame(gameState).loading} />
            </div>
            {isGame(gameState).idle && (
                <div
                    id="overlay"
                    className={cx({
                        'initial-control-overlay': props.initialControlOverlay,
                        idle: props.idleState,
                    })}
                />
            )}

            {isHost && renderModals()}

            {!isHost && gameState === GAME_STATE.SELECTING && (
                <GuestScreenOverlay />
            )}
            <AlertStack
                className="alertStack-position"
                alertList={alertList}
                onAlertDelete={(id) => {
                    dispatch(alertSlice.actions.delete(id))
                }}
            />

            {!showLoading(gameState, highlightStatus) &&
                !isGame(gameState).inSelectionScreen &&
                !isMobile && <ScreenMenu setFullscreen={props.setFullscreen} />}
            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalValidate}
                onClose={onConfirmModalClose}
            />
            {!showLoading(gameState, highlightStatus) && (
                <Confirmation
                    type={confirmNotif}
                    onValidate={onConfirmationValidate}
                    onClose={onConfirmationClose}
                />
            )}
            <IdleTimer
                timeout={1000 * 4} // 4 seconds
                onActive={props.handleActive}
                onIdle={props.handleIdle}
                onAction={props.handleActive}
            />
        </div>
    )
}

export default StreamingScreen
