import React, { FC } from 'react'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'
import { Label, LabelType } from './types'

interface IPublicRoomsDetails {
    labels: Label[]
    isHovered: boolean
    isDark: boolean
}

const PublicRoomsDetails: FC<IPublicRoomsDetails> = ({
    labels,
    isHovered,
    isDark,
}) => {
    const { t } = useTranslation()
    return (
        <div className={style.roomDetails}>
            <div className={style.labels}>
                {labels &&
                    labels.map((label, index) => (
                        <div
                            key={index}
                            className={cls(style.label, {
                                [style.tag]:
                                    label.type === LabelType.Tag && !isDark,
                                [style.darkTag]:
                                    label.type === LabelType.Tag && isDark,
                                [style.language]:
                                    label.type === LabelType.Language &&
                                    !isDark,
                                [style.darkLanguage]:
                                    label.type === LabelType.Language && isDark,
                                [style.distance]:
                                    label.type === LabelType.Distance,
                                [style.extra]: label.type === LabelType.Extra,
                                [style.hide]: isHovered,
                            })}
                        >
                            {label.text}
                        </div>
                    ))}
                <div
                    className={cls(style.join, {
                        [style.show]: isHovered,
                    })}
                >
                    {t('PublicRoomJoin')}
                </div>
            </div>
        </div>
    )
}

export default PublicRoomsDetails
