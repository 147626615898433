import React, { useState, useCallback } from 'react'
import ESLDetails from './ESLDetails'
import ESLLeaderboard from './ESLLeaderBoard'
import ESLTabs from './ESLTabs'
import ESLDropdown from './ESLDropdown'
import { GamesForLeaderboard, EVENT_TAB } from '../../utils'

function Events() {
    const [selectedESLTab, setSelectedESLTab] = useState(
        EVENT_TAB.CLICK_AND_PLAY
    )
    const [selectedDropdownItem, setSelectedDropdownItem] = useState(
        GamesForLeaderboard.MicroMagesLeaderboardRush
    )
    const [dropdownItems, setDropdownItems] = useState([
        GamesForLeaderboard.XenoCrisis,
        GamesForLeaderboard.MicroMages,
        GamesForLeaderboard.ArseneBomber,
        GamesForLeaderboard.ArseneBomberGamescom,
        GamesForLeaderboard.ArseneBomberLeaderboardRush,
    ])

    const selectDropdownElement = useCallback(
        (item) => {
            let tempArray = dropdownItems
            tempArray = tempArray.filter((x) => x !== item)
            tempArray.push(selectedDropdownItem)

            setSelectedDropdownItem(item)
            setDropdownItems(tempArray)
            setSelectedESLTab(item.tabs[0])
        },
        [
            dropdownItems,
            selectedDropdownItem,
            setSelectedDropdownItem,
            setDropdownItems,
        ]
    )

    const ESLDetailsProps = {
        selectedDropdownItem,
    }

    const ESLLeaderboardProps = {
        selectedESLTab,
        selectedDropdownItem,
    }

    const ESLDropdownProps = {
        selectedDropdownItem,
        dropdownItems,
        selectDropdownElement,
    }

    const ESLTabsProps = {
        selectedDropdownItem,
        setSelectedESLTab,
    }

    return (
        <>
            <ESLDetails {...ESLDetailsProps} />
            <ESLLeaderboard {...ESLLeaderboardProps} />

            <div className="ESLBottomPanel">
                <ESLDropdown {...ESLDropdownProps} />
                <ESLTabs {...ESLTabsProps} />
            </div>
        </>
    )
}

export default Events
