import React, { FC } from 'react'
import styles from './style.module.scss'
import cls from 'classnames'

export interface PieProgressProps {
    bgColor?: 'primary' | 'positive' | 'negative' | 'warning' | 'inactive'
    completed: number
    text?: boolean
    display?: boolean
    message?: string
    className?: string
}

const PieProgress: FC<PieProgressProps> = ({
    bgColor = 'primary',
    completed,
    text = false,
    display = true,
    message = '',
    className = '',
}) => {
    if (!display) return null
    return (
        <div
            className={cls(
                styles.container,
                styles[`containerFiller-${bgColor}`],
                className
            )}
        >
            <div
                className={cls(styles.filler, styles[`filler-${bgColor}`])}
                style={{ width: `${completed}%` }}
            ></div>
            {text && (
                <div className={styles[`textColor-${bgColor}`]}>
                    <span className={styles.message}>{message}</span>
                    <span className={styles.label}>{`${Math.round(
                        completed
                    )}%`}</span>
                </div>
            )}
        </div>
    )
}

PieProgress.defaultProps = {
    bgColor: 'primary',
}

export default PieProgress
