import React, { useRef, useState, useEffect, ReactNode, FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface TooltipProps {
    position?: 'top' | 'bottom' | 'left' | 'right'
    text?: string
    children?: string | ReactNode
    contentClassName?: string
    disabled?: boolean
    [rootHtmlAttributes: string]: any
}

const Tooltip: FC<TooltipProps> = ({
    position = 'top',
    text = '',
    children,
    contentClassName,
    disabled = false,
    ...rootHtmlAttributes
}) => {
    const [show, setShow] = useState(false)
    const childrenRef = useRef(null)
    const [childrenSize, setChildrenSize] = useState({ width: 0, height: 0 })

    useEffect(() => {
        if (childrenRef.current) {
            setChildrenSize({
                width: childrenRef.current.clientWidth,
                height: childrenRef.current.clientHeight,
            })
        }
    }, [])

    const calculateDistance = () => {
        switch (position) {
            case 'top':
                return { bottom: `${childrenSize.height + 5}px` }
            case 'bottom':
                return { top: `${childrenSize.height + 5}px` }
            case 'left':
                return { right: `${childrenSize.width + 5}px` }
            case 'right':
                return { left: `${childrenSize.width + 5}px` }

            default:
                break
        }
    }
    return (
        <div
            className={cls(style.container)}
            onMouseOver={() => setShow(disabled ? false : true)}
            onMouseLeave={() => setShow(false)}
            {...rootHtmlAttributes}
        >
            <div
                className={cls(
                    style.tooltipWrapper,
                    style[position],
                    show && style.show
                )}
                style={calculateDistance()}
            >
                <div className={cls(style[position])} />
                <p className={style.tooltip}>
                    {text.split('\n').map((line, index) => (
                        <span key={index}>{line}</span>
                    ))}
                </p>
            </div>
            <div
                ref={childrenRef}
                className={cls(style.content, contentClassName)}
            >
                {children}
            </div>
        </div>
    )
}

export default Tooltip
