import { useCallback } from 'react'

export const useLayout = (
    gameRatio: number,
    setGameScreenWidth: (state: number) => void,
    setGameScreenHeight: (state: number) => void,
    setCamScreenWidth: (state: number) => void
) => {
    const layout = useCallback(() => {
        const MIN_CAM_WIDTH = 230 // Before: 180
        const MAX_CAM_WIDTH = 284 // Before: 276
        const GAME_SIDEBAR_WIDTH = 64
        const GAME_CAM_MARGIN = 24
        const GAME_RIGHT_MARGIN = 24
        const CAM_LEFT_MARGIN = 24
        const WINDOW_WIDTH = window.innerWidth
        const WINDOW_HEIGHT = window.innerHeight

        const availableGameWidth =
            WINDOW_WIDTH -
            MIN_CAM_WIDTH -
            GAME_SIDEBAR_WIDTH -
            GAME_CAM_MARGIN -
            GAME_RIGHT_MARGIN -
            CAM_LEFT_MARGIN
        let gameWidth = WINDOW_HEIGHT * gameRatio
        let cameraWidth = MIN_CAM_WIDTH

        if (availableGameWidth > gameWidth) {
            cameraWidth =
                WINDOW_WIDTH -
                gameWidth -
                GAME_SIDEBAR_WIDTH -
                GAME_CAM_MARGIN -
                GAME_RIGHT_MARGIN -
                CAM_LEFT_MARGIN
            if (cameraWidth > MAX_CAM_WIDTH) cameraWidth = 276
        } else {
            gameWidth = availableGameWidth
        }

        setGameScreenWidth(gameWidth)
        setGameScreenHeight(gameWidth / gameRatio)
        setCamScreenWidth(cameraWidth)
    }, [gameRatio, setGameScreenWidth, setGameScreenHeight, setCamScreenWidth])

    return { layout }
}
