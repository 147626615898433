import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'

export interface IReadMore {
    text: string
    limit: number
    className: string
}

const ReadMore: FC<IReadMore> = ({ text, limit, className }) => {
    const { t } = useTranslation()
    const [displayMore, setDisplayMore] = useState(false)

    const displayText = (d: string, l: number, disableLimit: boolean) => {
        if (!d || !text) {
            return <></>
        }
        let readmore = d.length > l
        if (disableLimit) {
            l = text.length
            readmore = false
        }
        const finalText = `${text.slice(0, l)}${readmore ? '... ' : ''}`
        return (
            <>
                {finalText}
                {readmore && (
                    <span
                        className={style.readmore}
                        onClick={() => setDisplayMore(true)}
                    >
                        {t('ReadMore')}
                    </span>
                )}
            </>
        )
    }

    return <p className={className}>{displayText(text, limit, displayMore)}</p>
}

export default ReadMore
