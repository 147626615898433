import React, { FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface ChatMesssageProps {
    message: string
    color?: 'primary' | 'secondary'
}
const ChatMessage: FC<ChatMesssageProps> = ({
    message = '',
    color = 'primary',
}) => {
    return (
        <div className={cls(style.container, style[color])}>
            <p className={style.text}>{message}</p>
        </div>
    )
}

export default ChatMessage
