import cls from 'classnames'
import React, { FC, ReactNode } from 'react'
import style from './style.module.scss'

type ButtonType =
    | 'contained'
    | 'outlined'
    | 'gamecontrol'
    | 'mini'
    | 'square'
    | 'elliptical'
    | 'ellipticalWithIcon'
    | 'round'
    | 'roundWithButton'
    | 'autosave'
    | 'ellipticalLarge'
    | 'ellipticalOutlinedLarge'
    | 'clear'

// TODO: Button types will change soon in design system
interface ButtonProps {
    className?: string
    children?: string | ReactNode
    variant?: ButtonType
    controlSize?: 'sm' | 'md' | 'lg' //only applicaple in variant gamecontrol
    controlColor?: 'regular' | 'green' | 'yellow' | 'blue' | 'red' //only applicaple in variant gamecontrol
    size?: 'small' | 'medium' | 'large'
    color?:
        | 'primary'
        | 'secondary'
        | 'light'
        | 'yellow'
        | 'blue'
        | 'red'
        | string // FIXME: maybe it is not a good idea to keep the color here
    disabled?: boolean
    onClick?: (e) => void
    [rootHtmlAttributes: string]: any
}

const Button: FC<ButtonProps> = ({
    className,
    children,
    variant = 'contained',
    size = 'medium',
    color = 'primary',
    controlSize = 'sm',
    controlColor = 'regular',
    disabled,
    onClick,
    ...rootHtmlAttributes
}) => {
    return (
        <button
            {...rootHtmlAttributes}
            className={cls(
                className,
                style.button,
                style[color],
                style[variant],
                style[size],
                variant === 'gamecontrol' && style[controlSize],
                variant === 'gamecontrol' && style[controlColor],
                {
                    [style.disabled]: disabled,
                },
                className
            )}
            onClick={disabled ? undefined : onClick}
        >
            {children}
        </button>
    )
}

export default Button
