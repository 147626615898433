import { useRef, useState } from 'react'
import { piepie } from '../../utils'

const games = [
    'Metal Slug X',
    'Arsene Bomber',
    'Micro Mages',
    'Earthworm Jim 2 (USA)',
    'Worms World Party (Europe) (En,Fr,De,Es,It,Nl,Sv,Da)',
    'Xeno Crisis',
    'Glover (USA)',
]

const getRandomMediaId = (excludeMediaId?: string): string => {
    const randomId = games[Math.floor(Math.random() * games.length)]
    if (!excludeMediaId) {
        return randomId
    }

    if (excludeMediaId === randomId) {
        return getRandomMediaId(excludeMediaId)
    } else {
        return randomId
    }
}

const initialRandomId = getRandomMediaId()

const useVideoPlayback = () => {
    const videoRef = useRef(null)
    const [mediaId, setMediaId] = useState<string>(initialRandomId)

    const playNextVideo = () => {
        const randomId = getRandomMediaId(mediaId)
        setMediaId(randomId)
        const playPromise = videoRef.current.play()
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    piepie.log('video played auto')
                })
                .catch(() => {
                    console.log('video playback prevented')
                })
        }
    }

    return { videoRef, mediaId, playNextVideo }
}

export default useVideoPlayback
