import React, { FC } from 'react'
import MobileGameControlsPortrait from './MobileGameControlsPortrait'
import MobileGameControlsLandscape from './MobileGameControlsLandscape'
import { useDeviceOrientation } from '../../../utils'
interface MobileGameControlsProps {
    children?: React.ReactNode
    section: string
    setShowChat?: (x) => void
    isDynamic: boolean
    handleOptions: (state) => void
    showOptions: boolean
}

export const MobileGameControls: FC<MobileGameControlsProps> = ({
    children,
    section,
    setShowChat,
    isDynamic,
    handleOptions,
    showOptions,
}) => {
    const orientation = useDeviceOrientation()

    const handleShowChat = () => {
        setShowChat((show) => !show)
    }

    return (
        <>
            {orientation.includes('portrait') && (
                <MobileGameControlsPortrait
                    handleShowChat={handleShowChat}
                    isDynamic={isDynamic}
                    section={section}
                    handleOptions={handleOptions}
                    showOptions={showOptions}
                />
            )}

            {orientation.includes('landscape') && (
                <MobileGameControlsLandscape
                    section={section}
                    children={children}
                    handleShowChat={handleShowChat}
                    isDynamic={isDynamic}
                    handleOptions={handleOptions}
                    showOptions={showOptions}
                />
            )}
        </>
    )
}
