import { AlertType } from '../state/alert'
import store, { actions } from '../store'
import { MaskMetadata } from '../store/mask/types'
import { db } from './'
import { KickstarterBackerType, MaskType } from '../state/starfox'
import alertSlice from '../store/alert/alert'
import { isEnvDev, isEnvStaging } from '../utils'

const getCollectionsNameBasedOnEnv = () => {
    if ((isEnvDev || isEnvStaging) && process.env.REACT_APP_FORCE_ALL_MASKS) {
        return 'masks-luke' // All the masks are uploaded here for testing
    }

    if (isEnvDev) {
        return `masks-${process.env.REACT_APP_DEVNAME}`
    }
    return 'masks'
}

export function getMasks() {
    console.log('getMasks')

    const KickstarterBacker =
        (isEnvDev || isEnvStaging) && process.env.REACT_APP_FORCE_ALL_MASKS
            ? ('admin' as MaskType)
            : store.getState().user.extra?.KickstarterBacker
    const subscribedToNews = store.getState().user.extra?.SubscribedToNews
    const personalCollection = store.getState().user.extra?.Masks
    const UserRole = store.getState().user.extra?.Role
    const Limited: MaskType[] = ['All'] as MaskType[]
    if (KickstarterBacker) Limited.push(KickstarterBacker)
    if (subscribedToNews) Limited.push(KickstarterBackerType.KSSubscription)
    if (UserRole) Limited.push(UserRole)
    console.log('personalCollection', personalCollection)
    try {
        db.collection(getCollectionsNameBasedOnEnv())
            .where('Limited', 'array-contains-any', Limited)
            .get()
            .then((snapshot) => {
                const maskStore: { [maskId: string]: MaskMetadata } = {}
                snapshot.forEach((doc) => {
                    const docData = doc.data() as MaskMetadata
                    maskStore[doc.id as string] = docData
                })
                store.dispatch(actions.mask.setMaskStore(maskStore))
            })

        db.collection(getCollectionsNameBasedOnEnv())
            .where('maskId', 'in', personalCollection) // FIXME: This will work only if personalCollection.length <= 10
            .get()
            .then((snapshot) => {
                const maskStore: { [maskId: string]: MaskMetadata } = {}
                snapshot.forEach((doc) => {
                    const docData = doc.data() as MaskMetadata
                    maskStore[doc.id as string] = docData
                })
                store.dispatch(actions.mask.addMaskStore(maskStore))
            })
    } catch (error) {
        alertSlice.actions.push({
            type: AlertType.Error,
            message: error.message,
            autoClose: false,
        })
    }
}
