import cls from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../../../../stories'
import { IMenuItem } from './constants'

const MenuItem: React.FC<IMenuItem> = ({ link, iconName, title, children }) => {
    const { pathname } = useLocation()
    return (
        <li>
            <Link
                to={link}
                className={cls(styles.menuItem, {
                    [styles.selected]: pathname === link,
                })}
            >
                <div className={styles.menuItemIcon} role="img">
                    <Icon name={iconName} />
                </div>
                <div className={cls(styles.menuItemContent)}>
                    {title || children}
                </div>
            </Link>
        </li>
    )
}

export default MenuItem
