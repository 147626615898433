import React, { Dispatch, SetStateAction, FC, useEffect, useState } from 'react'
import { UserSessionFromFirebase } from '../../state/starfox'
import { AlertType } from '../../state/alert'
import GameLobbyInfo from './GameLobbyInfo'
import GameLobbyVideo from './GameLobbyVideo'
import { useSelector, useDispatch } from 'react-redux'
import { State } from '../../store/types'
import { AlertStack } from '../../stories'
import alertSlice from '../../store/alert/alert'
import { isMobile } from 'react-device-detect'
import { RoomAccess } from '../GameSettings'
export interface GameLobbyProps {
    lobbyLoading: boolean
    enterClickable: boolean
    session: UserSessionFromFirebase[]
    setDisplayGameLobby: Dispatch<SetStateAction<boolean>>
    setSettingsOpen: Dispatch<SetStateAction<boolean>>
}

const GameLobby: FC<GameLobbyProps> = ({
    lobbyLoading,
    enterClickable,
    session,
    setDisplayGameLobby,
    setSettingsOpen,
}) => {
    const { isCameraGranted, isMicrophoneGranted, isDeviceBindingComplete } =
        useSelector((state: State) => state.conference)
    const alertList = useSelector((state: State) => state.alert.list)
    const { audioInputDevices = [], videoInputDevices = [] } = useSelector(
        (state: State) => state.conference.devices
    )
    const dispatch = useDispatch()
    const [roomAccess, setRoomAccess] = useState(RoomAccess.PUBLIC)

    useEffect(() => {
        // Check if permissions are set only after init
        if (!isDeviceBindingComplete) {
            return
        }

        // Check if video and audio permissions are granted.
        if (isCameraGranted === false && isMicrophoneGranted === false) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'CameraAndMicrophoneNotAvailable',
                    autoClose: false,
                })
            )
            return
        }
        if (isCameraGranted === false) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'CameraNotAvailable',
                    autoClose: false,
                })
            )
            return
        }
        if (isMicrophoneGranted === false) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'MicrophoneNotAvailable',
                    autoClose: false,
                })
            )
            return
        }

        // Check if devices are an empty array.
        if (audioInputDevices.length === 0 && videoInputDevices.length === 0) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'CameraAndMicrophoneNotAvailable',
                    autoClose: false,
                })
            )
            return
        }
        if (audioInputDevices.length === 0) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'MicrophoneNotAvailable',
                    autoClose: false,
                })
            )
            return
        }
        if (videoInputDevices.length === 0) {
            dispatch(
                alertSlice.actions.push({
                    type: AlertType.Warning,
                    message: 'CameraNotAvailable',
                    autoClose: false,
                })
            )
            return
        }
    }, [
        isCameraGranted,
        isMicrophoneGranted,
        audioInputDevices,
        videoInputDevices,
        isDeviceBindingComplete,
        dispatch,
    ])

    return (
        <>
            {/* <PadNavigation type={PadNavigationType.LOBBY} /> */}
            <div className={'game-lobby'} data-test="GameLobby">
                <div className="room-info">
                    <AlertStack
                        className="alertStack-lobby-position"
                        alertList={alertList}
                        onAlertDelete={(id: string) =>
                            dispatch(alertSlice.actions.delete(id))
                        }
                    />
                    <GameLobbyInfo
                        lobbyLoading={lobbyLoading}
                        enterClickable={enterClickable}
                        session={session}
                        setDisplayGameLobby={setDisplayGameLobby}
                        setSettingsOpen={setSettingsOpen}
                        onSettingsChange={(settings) => {
                            setRoomAccess(settings.Access)
                        }}
                        showAvatar={roomAccess === RoomAccess.PUBLIC}
                    />
                </div>
                {/* TODO: This code should be refactored */}
                {!isMobile && (
                    <div className="video-local">
                        <GameLobbyVideo
                            setSettingsOpen={setSettingsOpen}
                            showAvatar={roomAccess === RoomAccess.PUBLIC}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default GameLobby
