import cls from 'classnames'
import React, { FC } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import style from '../styles.module.scss'
import { useValidation } from './validation'
import { AuthModalType } from '../../../store/auth/types'
import { Button, Input, TextLink } from '../../../stories'
import { setCurrentView } from '../../../store/auth/authV2'
import { handleEmailSignIn } from '../../../store/auth/services'

const LoginWithEmail: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const validationSchema = useValidation()

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: '',
        },

        onSubmit: async (values, { setSubmitting }) => {
            await handleEmailSignIn(values)
            setSubmitting(false)
        },
    })

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        isSubmitting,
    } = formik
    const { email, password } = values

    const handleResetPassword = () =>
        dispatch(setCurrentView(AuthModalType.ResetPassword))

    return (
        <form className={style.form} onSubmit={handleSubmit}>
            <div className={style.inputWrapper}>
                <div>
                    <label>{t('EmailAddress')}</label>
                    <Input
                        name="email"
                        value={email}
                        autoComplete={'email'}
                        onChange={handleChange}
                        placeholder={t('EmailAddress')}
                    />
                    {touched.email && errors.email && (
                        <div className={style.errorMsg}>{errors.email}</div>
                    )}
                </div>
                <div>
                    <label>{t('Password')}</label>
                    <Input
                        name="password"
                        type="password"
                        value={password}
                        onChange={handleChange}
                        placeholder={t('Password')}
                        autoComplete={'current-password'}
                    />
                    {touched.password && errors.password && (
                        <div className={style.errorMsg}>{errors.password}</div>
                    )}
                    <TextLink
                        className={style.textLink}
                        onClick={handleResetPassword}
                    >
                        {t('ForgotPassword')}
                    </TextLink>
                </div>
            </div>
            <Button
                className={cls(style.btn, style.submitButton)}
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {t('SignIn')}
            </Button>
        </form>
    )
}

export default LoginWithEmail
