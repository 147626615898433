import * as React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'

interface OwnProps {
    /**
     * Custom classname on input
     */
    className?: string
}

export type InputProps = OwnProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    React.DOMAttributes<HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className = '', ...rest }, ref) => (
        <input
            ref={ref}
            className={classnames(styles.input, className)}
            {...rest}
        />
    )
)

export default Input
