import React, { FC } from 'react'
import cls from 'classnames'
import style from './styles.module.scss'
import { Button } from '../../stories'
import { IAuthenticateSignIn } from './types'

const logoUrl = 'https://assets.piepacker.com/random/google-logo.svg'

const AuthWithGoogle: FC<IAuthenticateSignIn> = ({ onClick, label }) => (
    <Button onClick={onClick} className={cls(style.btn, style.gmailButton)}>
        <img src={logoUrl} alt="Google" />
        {label}
    </Button>
)

export default AuthWithGoogle
