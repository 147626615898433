import styles from './styles.module.scss'
import cls from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UpdateRegion from './UpdateRegion'
import UpdateLanguage from './UpdateLanguage'
import { LocalStorage } from '../../../state/storage'
import { languages, serverLocation } from './constants'
import { useSelector } from 'react-redux'
import * as selector from '../state/selectors'

interface IRegionLanguage {
    refreshUser: () => void
}

const RegionLanguage: React.FC<IRegionLanguage> = ({ refreshUser }) => {
    const { t } = useTranslation()
    const [isEditRegion, setEditRegion] = useState(false)
    const [isEditLanguage, setEditLanguage] = useState(false)
    const langCode = localStorage.getItem(LocalStorage.i18nextLng)
    const selectedRegion = useSelector(selector.getServer)

    // const countryCode = langCode === 'en' ? 'gb' : langCode
    // const countryFlag = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/${countryCode}.svg`

    return (
        <div className={styles.section}>
            <UpdateLanguage
                lang={langCode}
                isModalOpen={isEditLanguage}
                setIsModalOpen={setEditLanguage}
                refreshUser={refreshUser}
            />
            <UpdateRegion
                isModalOpen={isEditRegion}
                selectedRegion={selectedRegion}
                setIsModalOpen={setEditRegion}
                refreshUser={refreshUser}
            />

            <p className={styles.header}>{t('RegionAndLanguage')}</p>
            <div className={styles.itemsContainer}>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>
                            {t('ServerLocation')}
                        </p>
                        <p className={styles.itemValue}>
                            {t(
                                selectedRegion && serverLocation[selectedRegion]
                                    ? serverLocation[selectedRegion].text
                                    : serverLocation.Default.text
                            )}
                        </p>
                    </div>
                    <p
                        onClick={() => setEditRegion(true)}
                        className={styles.action}
                    >
                        {t('UpdateServerLocation')}
                    </p>
                </div>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>
                            {t('PreferredLanguage')}
                        </p>
                        <div className={cls('flex-start', styles.itemValue)}>
                            <span>{languages[langCode].text}</span>
                        </div>
                    </div>
                    <p
                        onClick={() => setEditLanguage(true)}
                        className={styles.action}
                    >
                        {t('UpdateLanguage')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RegionLanguage
