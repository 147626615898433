import style from './style.module.scss'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useConferenceSettings } from '../../conference/hooks'
import { State } from '../../store/types'
import { Button, Icon, Dropdown } from '../../stories'

interface SettingsModalMicrophoneProps {
    showOptions?: boolean
}

const SettingsModalMicrophone: FC<SettingsModalMicrophoneProps> = ({
    showOptions = true,
}) => {
    const { devices, isMicrophoneAvailable, isMicrophoneGranted } = useSelector(
        (state: State) => state.conference
    )
    const microphones = (devices.audioInputDevices || []) as MediaDeviceInfo[]
    const { isMicrophoneActive, toggleIsMicrophoneActive, onMicrophoneChange } =
        useConferenceSettings()

    return (
        <div className={style.mediaOptionContainer}>
            <Button
                variant="round"
                color="secondary"
                data-test="SettingsMicrophoneLabel"
                onClick={() => {
                    if (isMicrophoneAvailable) toggleIsMicrophoneActive()
                }}
            >
                {isMicrophoneActive && isMicrophoneAvailable ? (
                    <Icon name="mic" data-test="SettingsMicrophoneIconOn" />
                ) : (
                    <Icon name="micOff" data-test="SettingsMicrophoneIconOff" />
                )}
            </Button>
            {showOptions && (
                <Dropdown
                    className={style.mediaOptions}
                    data-test="SettingsMicrophoneControl"
                    selectedItem={devices.currentAudioInputDevice?.label || ''}
                    disabled={
                        !isMicrophoneGranted ||
                        !devices.audioInputDevices ||
                        !devices.audioInputDevices.length
                    }
                    onSelect={(id) => {
                        const selectedDevice = microphones.find(
                            (microphone) => microphone.deviceId === id
                        )
                        onMicrophoneChange(selectedDevice)
                    }}
                    data={microphones.map((d: MediaDeviceInfo) => ({
                        id: d.deviceId,
                        value: d.label,
                    }))}
                    position="relative"
                />
            )}
        </div>
    )
}

export default SettingsModalMicrophone
