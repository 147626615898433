import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { Formik, Field, Form as Formk } from 'formik'
import * as Axios from 'axios'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { InputText } from '../components/Form/fields'

import {
    getTransactionEmailServiceHostname,
    AxiosErrorMessage,
    piepie,
} from '../utils'
import { logAxiosErrorResponse } from '../utils/http'

export default function InviteBetaPage() {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const { t } = useTranslation()
    const email = () => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('email')) {
            return atob(urlParams.get('email'))
        }
        return ''
    }

    return (
        <div
            className="container single-form-container"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
            <img
                alt="Piepies"
                src="https://fassets.piepacker.com/random/Piepie_Invite_friends.png"
                style={{
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginBottom: '25px',
                    display: 'block',
                    maxWidth: '300px',
                }}
            />
            <Modal.Title className="single-form-title">
                {t('InviteBetaTitle')}
            </Modal.Title>
            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}
            <Formik
                initialValues={{ email: email(), password: '', confirm: '' }}
                validationSchema={Yup.object({
                    email: Yup.string().required('Required'),
                    password: Yup.string()
                        .required(t('NoPasswordProvided'))
                        .min(6, t('PasswordTooShort')),
                    confirm: Yup.string().required('Required'),
                })}
                onSubmit={async (
                    { email, password, confirm },
                    { setStatus }
                ) => {
                    try {
                        if (password !== confirm) {
                            throw new Error(
                                'password does not match confirmation'
                            )
                        }
                        const payload = {
                            email: email,
                            password: password,
                        }
                        piepie.log(payload)
                        await Axios.default.post(
                            `${getTransactionEmailServiceHostname()}/offwhitelist`,
                            payload
                        )
                        setStatus({ success: true })
                    } catch (err) {
                        logAxiosErrorResponse(err)
                        setSubmitError({
                            error: true,
                            message: t(AxiosErrorMessage(err)),
                        })
                    }
                }}
            >
                {({ isSubmitting, status }) => (
                    <Formk>
                        {status && status.success && (
                            <Alert variant="success">
                                {t('InviteBetaSuccessGoBack')}{' '}
                                <strong>
                                    <Link to="/">{t('HomePage')}</Link>
                                </strong>
                            </Alert>
                        )}

                        <Form.Group>
                            <Field
                                label={t('EmailAddress')}
                                placeholder={t('EmailAddress')}
                                name="email"
                                type="text"
                                id="email"
                                component={InputText}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Field
                                label={t('NewPassword')}
                                placeholder={t('NewPassword')}
                                name="password"
                                type="password"
                                id="password"
                                component={InputText}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Field
                                label={t('ConfirmPassword')}
                                placeholder={t('ConfirmPassword')}
                                name="confirm"
                                type="password"
                                id="confirm"
                                component={InputText}
                            />
                        </Form.Group>

                        <Button
                            className="btn-large"
                            disabled={isSubmitting}
                            type="submit"
                            block
                        >
                            {t('JoinTheBeta')}
                        </Button>
                    </Formk>
                )}
            </Formik>
        </div>
    )
}
