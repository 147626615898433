import * as React from 'react'
import style from './style.module.scss'
import Box from '@material-ui/core/Box'
import { Game, SessionMoodType } from '../../state/starfox'
import Media from '../../pages/Home/Media'
import ModalDetails from './ModalDetails'
import { Icon } from '../../stories'
import PublicRoomsList from '../PublicRoomsList'
import { GameModalType } from './types'
import { useOpenRooms } from './hooks'

interface IGameModal {
    game: Game
    mood: SessionMoodType
    type: GameModalType
    close: () => void
}

const GameModal: React.FC<IGameModal> = ({ game, mood, type, close }) => {
    const { sessions, onRefreshHandle } = useOpenRooms({ game, type })

    return (
        <Box className={style.gameModal}>
            <div className={style.close} onClick={close}>
                <div className={style.closeIcon}>
                    <Icon name="close" />
                </div>
            </div>
            <Box className={style.gameInfo}>
                <Media
                    id={game ? game.id : mood}
                    mood={mood}
                    isBYOG={
                        game?.Categories && game?.Categories.includes('BYOG')
                    }
                    className={style.gameVideo}
                />
                {type !== GameModalType.Mood && (
                    <ModalDetails
                        id={game ? game.id : mood}
                        mood={mood}
                        type={type}
                        competition={game?.Competition}
                        numberOfRooms={sessions ? sessions.length : 0}
                    />
                )}
            </Box>
            <Box className={style.publicRooms}>
                <PublicRoomsList
                    rooms={sessions}
                    game={game}
                    mood={mood}
                    type={type}
                    refresh={onRefreshHandle}
                />
            </Box>
        </Box>
    )
}

export default GameModal
