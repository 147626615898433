import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { db } from './'

export async function saveChatMessage(
    chatMessage: string,
    sessionID: string,
    userSessionID: string
) {
    db.collection('chatMessages').add({
        Text: chatMessage,
        SessionID: sessionID,
        UserSessionID: userSessionID,
        Created: firebase.firestore.FieldValue.serverTimestamp(),
    })
}

export function subscribeToChatMessages(sessionID: string) {
    return db
        .collection('chatMessages')
        .where('SessionID', '==', sessionID)
        .orderBy('Created')
}
