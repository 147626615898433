import gradientMapURL from '../assets/gradientMaps/threeTone.jpg'

// To make decryption work, we need to use the same (tweaked) THREE
// everywhere
//import * as THREE from 'three'
let THREE = null
let _gradientMap = null

let _colorWhite = null,
    _colorBlack = null
const set_three = (three) => {
    THREE = three
    _colorWhite = new THREE.Color(0xffffff)
    _colorBlack = new THREE.Color(0x000000)
}

// create a toon material with outline effect from a random material
// see https://threejs.org/examples/?q=toon#webgl_materials_variations_toon
const create_toonMaterial = (node) => {
    const mat = node.material
    if (mat.isMeshToonMaterial) return mat

    return new THREE.MeshToonMaterial({
        name: mat.name,
        opacity: mat.opacity || 1.0,
        alphaMap: mat.alphaMap || null,
        aoMap: mat.aoMap || null,
        aoMapIntensity: mat.aoMapIntensity || 1.0,
        bumpMap: mat.bumpMap || null,
        bumpScale: mat.bumpScale || 1.0,
        color: mat.color || _colorWhite,
        displacementMap: mat.displacementMap || null,
        displacementScale: mat.displacementScale || 1.0,
        displacementBias: mat.displacementBias || 0.0,
        emissive: mat.emissive || _colorBlack,
        emissiveMap: mat.emissiveMap || null,
        emissiveIntensity: mat.emissiveIntensity || 1.0,
        gradientMap: mat.gradientMap || _gradientMap,
        lightMap: mat.lightMap || null,
        lightMapIntensity: mat.lightMapIntensity || 1.0,
        map: mat.map || null,
        normalMap: mat.normalMap || null,
        normalMapType: mat.normalMapType || THREE.TangentSpaceNormalMap,
        normalScale: mat.normalScale || new THREE.Vector2(1.0, 1.0),
        skinning: mat.skinning,
    })
}

const create_basicMaterial = (node) => {
    const mat = node.material
    if (mat.isMeshBasicMaterial) return mat

    return new THREE.MeshBasicMaterial({
        transparent: mat.transparent || false,
        name: mat.name,
        opacity: mat.opacity || 1.0,
        alphaMap: mat.alphaMap || null,
        aoMap: mat.aoMap || null,
        aoMapIntensity: mat.aoMapIntensity || 1.0,
        color: mat.color || _colorWhite,
        envMap: mat.envMap || null,
        lightMap: mat.lightMap || null,
        lightMapIntensity: mat.lightMapIntensity || 1.0,
        map: mat.map || null,
        side: mat.side,
        depthWrite: true, // force depthWrite to true mat.depthWrite,
        depthTest: mat.depthTest,
        blending: mat.blending,
        blendSrc: mat.blendSrc,
        blendDst: mat.blendDst,
        reflectivity: mat.reflectivity || 1,
        specularMap: mat.specularMap || null,
        //skinning: mat.skinning,
    })
}

const create_gradientMap = () => {
    const gradientMap = new THREE.TextureLoader().load(gradientMapURL)
    gradientMap.minFilter = THREE.NearestFilter
    gradientMap.magFilter = THREE.NearestFilter
    return gradientMap
}

const replace_materials = (model, createMaterialFunc) => {
    model.traverse(function (node) {
        if (
            (node.isMesh || node.isSkinnedMesh) &&
            node.name !== 'occluder' &&
            node.material
        ) {
            node.material = createMaterialFunc(node)
        }
    })
}

const init = (model, maskSettingsShading = {}) => {
    switch (maskSettingsShading.type) {
        case 'toon':
            _gradientMap = _gradientMap || create_gradientMap()
            replace_materials(model, create_toonMaterial)
            break
        case 'none':
            replace_materials(model, create_basicMaterial)
            break
        default:
            break
    }
}

export default {
    init,
    set_three,
}
