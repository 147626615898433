import { db } from './'
import { UserSessionFromFirebase } from '../state/starfox'

export async function getUserSession(
    userSessionID: string
): Promise<UserSessionFromFirebase> {
    const resp = await db.collection('userSessions').doc(userSessionID).get()
    return resp.data() as UserSessionFromFirebase
}

export async function updateConferenceId(
    userSessionID: string,
    conferenceID: string
) {
    await db
        .collection('userSessions')
        .doc(userSessionID)
        .update({ ConferenceID: conferenceID })
}

export function subscribeToUserSession(userSessionID: string) {
    return db.collection('userSessions').doc(userSessionID)
}
