import {
    MaskState,
    SET_ACTIVE_MASK_ID,
    RESET_STATE,
    MaskActionTypes,
    SET_MASK_STORE,
    ADD_MASK_STORE,
    SET_MANNEQUIN_MODE,
} from './types'

export const INITIAL_STATE: MaskState = {
    activeMaskId: null,
    metadataStore: {},
    mannequinMode: false,
}

export default function (
    state = INITIAL_STATE,
    action: MaskActionTypes
): MaskState {
    switch (action.type) {
        case RESET_STATE:
            return {
                ...INITIAL_STATE,
            }
        case SET_ACTIVE_MASK_ID:
            return {
                ...state,
                activeMaskId: action.payload,
            }
        case SET_MASK_STORE:
            return {
                ...state,
                metadataStore: action.payload,
            }
        case ADD_MASK_STORE:
            return {
                ...state,
                metadataStore: {
                    ...state.metadataStore,
                    ...action.payload,
                },
            }
        case SET_MANNEQUIN_MODE:
            return {
                ...state,
                mannequinMode: action.payload,
            }
        default:
            return state
    }
}
