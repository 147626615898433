import styles from './style.module.scss'

import React, {
    ReactNode,
    Children,
    ReactElement,
    useState,
    useRef,
    FC,
    MouseEvent,
} from 'react'
import { rand } from '../../../utils'
import cls from 'classnames'

interface TabsProps {
    children: ReactNode
    index?: number
    className?: string
    /**
     * onChange callback
     * @param e React Mouse Event
     * @param currentIndex contains current tab index
     * @param prevIndex contains previous tab index
     * @param currentData contains current tab data
     */
    onChange?: (
        arg: MouseEvent<HTMLButtonElement>,
        currentIndex: number,
        prevIndex?: number,
        currentData?: any
    ) => void
}

interface TabProps {
    children?: ReactNode
    name: string
    data?: any
    className?: string
}

const Tabs: FC<TabsProps> & {
    Tab: FC<TabProps>
} = ({ children, index = 0, onChange = undefined, className }) => {
    const [currentIndex, setCurrentIndex] = useState(index)
    const tabRandomId = useRef(rand())

    const currentTab: any = Children.toArray(children)[currentIndex]

    return (
        <div className={cls(styles.tabs, className)}>
            <ul role="tablist" className={styles.tabList}>
                {Children.toArray(children)
                    .filter((reactElement) => reactElement)
                    .map(
                        (
                            reactElement: ReactElement<TabProps>,
                            index: number
                        ) => {
                            return (
                                <li
                                    className={styles.tabElement}
                                    role="tab"
                                    aria-selected={index === currentIndex}
                                    aria-controls={`tab-${reactElement.props.name}-${tabRandomId.current}-tab`}
                                    id={`tab-${reactElement.props.name}-${tabRandomId.current}-nav`}
                                    key={`tab-${reactElement.props.name}`}
                                >
                                    <button
                                        className={styles.tabButton}
                                        onClick={(e) => {
                                            if (onChange) {
                                                onChange(
                                                    e,
                                                    index,
                                                    currentIndex,
                                                    reactElement.props.data
                                                )
                                            }
                                            setCurrentIndex(index)
                                        }}
                                    >
                                        {reactElement.props.name}
                                    </button>
                                </li>
                            )
                        }
                    )}
            </ul>
            <div
                tabIndex={0}
                role="tabpanel"
                aria-labelledby={`tab-${currentTab.props.name}-${tabRandomId.current}-nav`}
                id={`tab-${currentTab.props.name}-${tabRandomId.current}-tab`}
                className={styles.wrapper}
            >
                {currentTab}
            </div>
        </div>
    )
}

function Tab({ children, className }: TabProps) {
    return <div className={className}>{children}</div>
}

Tabs.Tab = Tab
export default Tabs
