import { ZboingZboingPhysics } from '../mask/contrib/threeZboingZboing/ZboingZboingPhysics.js'

// // physics should be applied to this skinnedMesh:
const skinnedMeshNameShouldContain = 'Physic'

// 'boneNamePattern', // if specified, physics is only applied to bones which contain this pattern:
const bonesNamesShouldContain = '_Physic'

const get_bonesSettings = (preset = {}) => {
    const bonesSettings = {
        BN_base: null, // this bone should not move

        DEFAULT: {
            // applied to all other bones:
            damper: preset.damper !== undefined ? preset.damper : 5,
            spring: preset.spring !== undefined ? preset.spring : 40,
        },
    }

    // switch (preset) {
    //     case 'verylow':
    //         bonesSettings.DEFAULT = {
    //             damper: 5,
    //             spring: 100,
    //             //   damper: 5,
    //             //   spring: 8,
    //         }
    //         break;
    //     case 'low':
    //         bonesSettings.DEFAULT = {
    //             damper: 5,
    //             spring: 70,
    //             //   damper: 30,
    //             //   spring: 50,
    //         }
    //         break;
    //     case 'strong':
    //         bonesSettings.DEFAULT = {
    //             damper: 10,
    //             spring: 40,
    //             //   damper: 30,
    //             //   spring: 200,
    //         }
    //         break;
    //     case 'verystrong':
    //         bonesSettings.DEFAULT = {
    //             damper: 30,
    //             spring: 40,
    //             // damper: 30,
    //             // spring: 400,
    //         }
    //         break;
    //     default:
    //         break;
    // }

    return bonesSettings
}

const init = (spec) => {
    const { threeObject3D, globals, maskSettings } = spec

    // for debug: disable physics feature:
    //return

    // destroy previous physics
    if (globals.physics) {
        globals.physics.forEach((ph) => {
            ph.destroy()
        })
        globals.physics = null
    }

    if (!maskSettings || !maskSettings.physics) {
        return
    }

    // look for meshes where physics applies:
    const physicsSkinnedMeshes = []
    threeObject3D.traverse((node) => {
        if (
            node.isSkinnedMesh &&
            node.name.indexOf(skinnedMeshNameShouldContain) !== -1
        ) {
            physicsSkinnedMeshes.push(node)
        }
    })
    if (physicsSkinnedMeshes.length === 0) {
        return
    }

    const bonesSettings = get_bonesSettings(maskSettings.physics)
    const gravity = maskSettings.physics.gravity || 0.0

    // init instances of ZboingZboingPhysics:

    globals.physics = physicsSkinnedMeshes.map((physicsSkinnedMesh) => {
        return new ZboingZboingPhysics(
            globals.threeScene,
            physicsSkinnedMesh,
            bonesSettings,
            {
                gravity,
                simuStepsCount: 3, // default: 3. fewer -> faster
                internalStrengthFactor: gravity === 0 ? 0.1 : 0, // default: 0.5 0 -> no internal strengths, i.e. between bones
                bonesNamesShouldContain,
                isDebug: false,
            }
        )
    })
}

export default {
    init,
}
