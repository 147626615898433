import { useState, useEffect } from 'react'
import * as Axios from 'axios'
import { piepie, isEnvStaging, isEnvDev } from '../../utils'
import { useTranslation } from 'react-i18next'
import store from '../../store'
import { logAxiosErrorResponse } from '../../utils/http'

export const useInvitationLink = ({ user, gameName, gameId }): string => {
    const [invitationLink, setInvitationLink] = useState('')
    const { t } = useTranslation()

    useEffect(() => {
        const link =
            isEnvStaging || isEnvDev
                ? window.location.href // The sessionID is already available in the URL in staging and dev envs
                : `${window.location.href}?sessionID=${
                      store.getState().session.sessionID
                  }`

        Axios.default
            .post(
                isEnvStaging
                    ? 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC8fs58MUgPdF85hgNlgyBhBHdLRcp9DiU'
                    : 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBjmEduD8DJBcaM-qyqldfCFO62Oo1tJfE',
                {
                    dynamicLinkInfo: {
                        domainUriPrefix: isEnvStaging
                            ? 'https://piepackerstaging.page.link'
                            : 'https://piepie.page.link',
                        link,
                        socialMetaTagInfo: {
                            socialTitle:
                                user['displayName'] !== null
                                    ? `${t('PiepackerPlay')} ${gameName} ${t(
                                          'With'
                                      )} ${user['displayName']}!`
                                    : `${t('PiepackerPlay')} ${gameName}!`,
                            socialDescription: t('PiepackerDescription'),
                            socialImageLink: `https://fassets.piepacker.com/social/${encodeURIComponent(
                                gameId
                            )}.png?organizationId=942630971205`,
                        },
                    },
                    suffix: {
                        option: 'SHORT',
                    },
                }
            )
            .then(function (response) {
                piepie.log(
                    '[INVITE LINK] Dynamic link generated: ' +
                        response.data['shortLink']
                )
                setInvitationLink(response.data['shortLink'])
            })
            .catch(function (error) {
                logAxiosErrorResponse(error)
                piepie.log(
                    `[INVITE LINK] Creating dynamic link error: ${error}, using regular link`
                )
                setInvitationLink(link)
            })
    }, [user, gameName, gameId, t])

    return invitationLink
}
