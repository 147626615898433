import { useCallback, useEffect, useRef, useState } from 'react'
import { PubSubEvent, globalPubSub } from '../../event/event'
import { piepie } from '../../utils'
import * as Socket from '../../network/socket'

export interface TeamMember {
    DisplayName: string
    MemberType?: string
    UID: string
}

export interface Team {
    GameId: string
    GameMode: number
    Name: string
    Score: number
    Ranking: number
    Members: TeamMember[]
}

interface gameAchievementsEventPayload {
    isNewHighScore: boolean
    team: Team
}

interface useGameAchievementsProps {
    setShowGameEndModal: (show: boolean) => void
    setNewHighScore: (higher: boolean) => void
    currentUserID: string
}

interface useGameAchievementsPayload {
    team: Team
    resetAchievements: () => void
}

const useGameAchievements = ({
    setShowGameEndModal,
    currentUserID,
    setNewHighScore,
}: useGameAchievementsProps): useGameAchievementsPayload => {
    const gameModeAndScoreSub = useRef(null)
    const [team, setTeam] = useState(null)
    const [isNewHighScore, setIsNewHighScore] = useState(null)

    const resetAchievements = () => {
        piepie.log('[ESL] resetAchievements')
        setTeam(null)
        setNewHighScore(false)
    }

    const updateScore = useCallback(async (team: Team) => {
        piepie.log('[ESL] updateScore')
        try {
            piepie.log('[ESL] updateScore, team: ', team)
            const response = await Socket.saveScore(team)
            piepie.log('[ESL] updateScore response', response)
        } catch (error) {
            piepie.log('[ESL] updateScore error', error)
        }
    }, [])

    useEffect(() => {
        if (!currentUserID || !team) {
            return
        }

        const gameEnded = async () => {
            piepie.log('[ESL] gameEnded')
            // from the backend side, we always receive a team object, with every information required for scoring
            // But, we populate the name of a team only for teams that already exist
            if (team.Name) {
                // team exists
                piepie.log('[ESL] gameEnded, team exists: ', team)
                if (isNewHighScore) {
                    piepie.log(
                        '[ESL] gameEnded, current score is higher than previous one'
                    )
                    setNewHighScore(true)
                }
                await updateScore(team)
                piepie.log('[ESL] gameEnded, opening game end modal')
                setShowGameEndModal(true)
            } else {
                // team does not exist, we'll have to pick a name
                // Display a new high score for a new team.
                setNewHighScore(true)
                piepie.log('[ESL] gameEnded, opening game end modal')
                setShowGameEndModal(true)
            }
        }

        gameEnded()
    }, [
        currentUserID,
        team,
        setNewHighScore,
        setShowGameEndModal,
        isNewHighScore,
        updateScore,
    ])

    useEffect(() => {
        gameModeAndScoreSub.current = globalPubSub.sub(
            PubSubEvent.GAME_ACHIEVEMENTS,
            (data: gameAchievementsEventPayload) => {
                piepie.log(
                    '[ESL] GAME_ACHIEVEMENTS WebSocketEvent, received data: ',
                    data
                )
                setIsNewHighScore(data.isNewHighScore)
                setTeam(data.team)
            },
            0
        )

        return () => {
            gameModeAndScoreSub.current.unsub()
        }
    }, [setShowGameEndModal])

    return { team, resetAchievements }
}

export default useGameAchievements
