import styles from './byog.module.scss'
import classNames from 'classnames'
import React, { FC, useCallback } from 'react'
import { ICard } from './types'
import { cardBgUrl, cardLogoUrl, transformNameToText } from './constants'

const BYOG: FC<Partial<ICard>> = ({
    game: { id, System },
    className,
    handleOpenGameModal,
}) => {
    const byogItem = classNames(
        styles.card,
        {
            [styles.NES]: System === 'NES',
            [styles.SNES]: System === 'SNES',
            [styles.Pico]: System === 'PICO-8',
            [styles.Lutro]: System === 'Lutro',
            [styles.Genesis]: System === 'Genesis',
            [styles.GameBoy]: System === 'Game Boy',
            [styles.Nintendo]: System === 'Nintendo 64',
            [styles.PlayStation]: System === 'PlayStation',
            [styles.MasterSystem]: System === 'Master System',
            [styles.GameBoyAdvance]: System === 'GameBoy Advance',
        },
        className
    )

    const handleCardClick = useCallback(() => {
        handleOpenGameModal()
        analytics.track('Click on BYOG', { System: System })
    }, [System, handleOpenGameModal])

    return (
        <div
            className={byogItem}
            onClick={handleCardClick}
            onMouseEnter={() =>
                analytics.track('Mouse BYOG', { System: System })
            }
            onTouchStart={() =>
                analytics.track('Touch BYOG', { System: System })
            }
        >
            <div className={styles.cardBackground}></div>
            <div className={styles.innerCard}>
                <div className={classNames(styles.cardRest)}>
                    <div className={styles.title}>
                        {transformNameToText[System]}
                    </div>
                    <img
                        className={styles.logo}
                        src={cardBgUrl(System, 'landscape')}
                        alt={System}
                    />
                </div>
                <div className={classNames(styles.cardHover)}>
                    <img
                        className={styles.logo}
                        src={`${cardLogoUrl(id)}.png`}
                        alt={System}
                    />
                    <div className={styles.title}>
                        {transformNameToText[System]}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BYOG
