import React, { FC } from 'react'
import cls from 'classnames'
import style from './style.module.scss'

type Spacing =
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'

export interface SpacingProps {
    children?: React.ReactNode
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
    inline?: boolean
    [rootHtmlAttributes: string]: any
}

const Spacing: FC<SpacingProps> = ({
    children,
    top,
    right,
    bottom,
    left,
    inline,
    ...rootHtmlAttributes
}) => {
    const classNames = cls(
        { [style.inline]: inline },
        top ? style[`top${top}`] : '',
        right ? style[`right${right}`] : '',
        bottom ? style[`bottom${bottom}`] : '',
        left ? style[`left${left}`] : ''
    )
    return (
        <div {...rootHtmlAttributes} className={classNames}>
            {children}
        </div>
    )
}

export default Spacing
