import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GameModalType } from '../../components/GameModal/types'
import { LocalStorage } from '../../state/storage'
import { piepie, updateUrlWithParam } from '../../utils'
import {
    setGameModalInstance,
    setIsGameModalOpened,
} from '../../store/home/actions'
import { setGameName } from '../../store/session/actions'
import { Game, SessionMoodType } from '../../state/starfox'
import { openAuthModal } from '../../store/auth/actions'
import { SignedInState } from '../../state/app'
import { sessionAvailable } from '../../starfox/starfox'
import { UnavailableReasonEnum } from '../../state/unavailable'
import { selectReason, selectSignedIn } from '../../store/home/homeV2'

export const useSelect = ({ game }: { game: Game }) => {
    const history = useHistory()
    const reason = useSelector(selectReason)
    const signedIn = useSelector(selectSignedIn)
    const dispatch = useDispatch()
    const handleClick = useCallback(async () => {
        if (!game) return

        if (signedIn !== SignedInState.SignedIn) {
            piepie.log('[HOME] Cannot start the game: user not signed in')
            dispatch(
                openAuthModal({
                    closable: true,
                    isTransparent: false,
                })
            )
            return
        }

        // enough compute capacity
        const available = await sessionAvailable()
        if (!available) {
            piepie.log(
                '[HOME] Cannot start the game: not enough compute capacity'
            )
            return
        }

        if (reason !== UnavailableReasonEnum.None) {
            piepie.log(`[HOME] Cannot start the the game: ${reason}`)
            return
        }

        if (game.Categories?.includes('BYOG')) {
            switch (game.id) {
                case 'Nintendo Entertainment System':
                    history.push('/byog?nes')
                    return
                case 'Super Nintendo Entertainment System':
                    history.push('/byog?snes')
                    return
                case 'Sega Genesis':
                    history.push('/byog?genesis')
                    return
                case 'Nintendo GameBoy Advance':
                    history.push('/byog?gba')
                    return
                case 'Nintendo 64':
                    history.push('/byog?n64')
                    return
                case 'Nintendo Game Boy':
                    history.push('/byog?gb')
                    return
                case 'Sega Master System':
                    history.push('/byog?sms')
                    return
                case 'PICO-8':
                    history.push('/byog?pico8')
                    return
                case 'Sony PlayStation':
                    history.push('/byog?psx')
                    return
                case 'Lutro':
                    history.push('/byog?lutro')
                    return
                default:
                    break
            }
        }

        setGameName(game.id)
        localStorage.setItem(LocalStorage.sessionID, '')
        history.push(`/game/${game.id}`)
    }, [history, game, dispatch, reason, signedIn])

    return handleClick
}

export const useOpenGameModal = ({
    isBYOG,
    key,
    gameId,
    mood,
    setGameModalType,
}: {
    isBYOG: boolean
    key: string
    gameId: string
    mood: SessionMoodType
    setGameModalType: (value: React.SetStateAction<GameModalType>) => void
}) => {
    const dispatch = useDispatch()
    const handleOpenGameModal = useCallback(
        (tempKey = key) => {
            setGameModalType(GameModalType.Regular)
            let id = gameId
            if (isBYOG) setGameModalType(GameModalType.BYOG)
            if (mood) {
                setGameModalType(GameModalType.Mood)
                id = mood
            }
            updateUrlWithParam('details', id)
            dispatch(setIsGameModalOpened(true))
            dispatch(setGameModalInstance(tempKey))
        },
        [dispatch, key, isBYOG, mood, setGameModalType, gameId]
    )

    return handleOpenGameModal
}

export const useCloseGameModal = () => {
    const dispatch = useDispatch()
    const handleCloseGameModal = useCallback(() => {
        updateUrlWithParam('details', '')
        dispatch(setIsGameModalOpened(false))
        dispatch(setGameModalInstance(''))
    }, [dispatch])

    return handleCloseGameModal
}
