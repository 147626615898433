import * as Axios from 'axios'
import { SessionFromFirebase } from '../state/starfox'
import { isEnvProd } from '../utils/env'
import { logAxiosErrorResponse } from '../utils/http'

export const DONKEY_SERVICE_URL = isEnvProd
    ? 'https://donkey.belgium.gke-jam.com'
    : 'https://donkey.piepackerstaging.com'

export const getLeaderboard = async (
    gameName,
    playerCount,
    numValues = 100
): Promise<any> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/leaderboard`,
            {
                gameName: gameName,
                playerCount: playerCount,
                numValues: numValues,
            }
        )

        return result.data
    } catch (error) {
        logAxiosErrorResponse(error)
        return {}
    }
}

export const getMyTeams = async (gameName, playerCount, uid): Promise<any> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/myTeams`,
            {
                gameName: gameName,
                playerCount: playerCount,
                uid: uid,
            }
        )

        return result.data
    } catch (error) {
        logAxiosErrorResponse(error)
        return {}
    }
}

export const getOpenRooms = async (
    gameName = ''
): Promise<SessionFromFirebase[]> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/openRoomsList`,
            {
                gameName: gameName,
            }
        )
        return !result.data?.length
            ? []
            : result.data.filter((s: SessionFromFirebase) => {
                  // we do not keep rooms that are too old (3 days)
                  const started = new Date(s.Started).getTime()
                  if (Date.now() - started > 1000 * 60 * 60 * 24 * 3)
                      return false
                  return true
              })
    } catch (error) {
        logAxiosErrorResponse(error)
        return []
    }
}

export const getOpenRoom = async (gameName): Promise<SessionFromFirebase> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/openRoom`,
            {
                gameName: gameName,
            }
        )
        return result.data
    } catch (error) {
        logAxiosErrorResponse(error)
        return {} as SessionFromFirebase
    }
}

export const getOpenRoomsCount = async (): Promise<number> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/openRoomsCount`
        )
        return result.data
    } catch (error) {
        logAxiosErrorResponse(error)
        return 0
    }
}

export const getOpenRoomsCountByGame = async (): Promise<any> => {
    try {
        const result = await Axios.default.post(
            `${DONKEY_SERVICE_URL}/openRoomsCountByGame`
        )
        return result.data
    } catch (error) {
        logAxiosErrorResponse(error)
        return {}
    }
}
