import React from 'react'
import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../stories'
import { AlertType } from '../../state/alert'
import { useQuery } from '../../utils'
import { selectSubmitError, selectSubmitSuccess } from '../../store/auth/authV2'
import { useSelector } from 'react-redux'

const Alerts = () => {
    const { t } = useTranslation()
    const urlParams = useQuery()
    const submitError = useSelector(selectSubmitError)
    const submitSucess = useSelector(selectSubmitSuccess)
    return (
        <div>
            {urlParams.has('emailConfirmed') && (
                <Alert
                    className={style.alert}
                    message={t('EmailConfirmed')}
                    animate={false}
                    type={AlertType.Success}
                />
            )}
            {submitError && (
                <Alert
                    className={style.alert}
                    message={t(submitError)}
                    animate={false}
                    type={AlertType.Error}
                />
            )}
            {submitSucess && (
                <Alert
                    className={style.alert}
                    message={submitSucess}
                    animate={false}
                    type={AlertType.Success}
                />
            )}
        </div>
    )
}

export default Alerts
