import { BadgerAutobones } from '../mask/contrib/threeBadgerAutobones/BadgerAutobones.js'

const init = (WEBARROCKSFACE, spec) => {
    const { threeObject3D, globals, maskSettings } = spec
    globals.autobones = null
    /*if (!maskSettings || !maskSettings.autobones){
    return
  }*/

    // look for autobone mesh:
    let autobonesSkinnedMesh = null
    threeObject3D.traverse((node) => {
        if (
            node.isSkinnedMesh &&
            //maskSettings &&
            //maskSettings.autobones &&
            node.name.indexOf('_Autobones') !== -1
        ) {
            autobonesSkinnedMesh = node
        }
    })

    if (!autobonesSkinnedMesh) {
        return
    }

    console.log('AUTOBONES Found')

    /*const moveFactor =
        maskSettings && maskSettings.autobones && maskSettings.autobones.factor
            ? maskSettings.autobones.factor
            : 1*/
    let moveFactorsPerAutobone = null
    if (maskSettings.autobones) {
        moveFactorsPerAutobone = {
            RBrow: maskSettings.autobones.moveFactorEyes,
            LBrow: maskSettings.autobones.moveFactorEyes,

            //NOSE:
            RNostril: maskSettings.autobones.moveFactorNose,
            LNostril: maskSettings.autobones.moveFactorNose,

            // MOUTH:
            UpperLipCenter: maskSettings.autobones.moveFactorMouth,
            LUpperLip: maskSettings.autobones.moveFactorMouth,
            LLipCorner: maskSettings.autobones.moveFactorMouth,
            LLowerLip: maskSettings.autobones.moveFactorMouth,
            LowerLipCenter: maskSettings.autobones.moveFactorMouth,
            RLowerLip: maskSettings.autobones.moveFactorMouth,
            RLipCorner: maskSettings.autobones.moveFactorMouth,
            RUpperLip: maskSettings.autobones.moveFactorMouth,
        }
    }

    globals.autobones = new BadgerAutobones(
        autobonesSkinnedMesh,
        {
            // map autobones (by bones names) to WebAR;face landmarks:
            // EYES:
            RBrow: 'rightEyeBrowCenter',
            LBrow: 'leftEyeBrowCenter',

            //NOSE:
            RNostril: 'noseRight',
            LNostril: 'noseLeft',

            // MOUTH:
            UpperLipCenter: 'upperLipTop',
            LUpperLip: 'upperLipTopLeft',
            LLipCorner: 'mouthLeft',
            LLowerLip: 'lowerLipBotLeft',
            LowerLipCenter: 'lowerLipBot',
            RLowerLip: 'lowerLipBotRight',
            RLipCorner: 'mouthRight',
            RUpperLip: 'upperLipTopRight',
        },
        {
            webARRocksLandmarks: WEBARROCKSFACE.get_LMLabels(),
            moveFactor: 1,
            moveFactorsPerAutobone,
        }
    )
}

export default {
    init,
}
