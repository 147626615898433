import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    MeetingSessionStatus,
    MeetingSessionStatusCode,
    AsyncScheduler,
} from 'amazon-chime-sdk-js'
import {
    setIsLoading,
    setChimeMeetingId,
    setError,
    addParticipant,
    removeParticipant,
    editParticipantMuted,
    editParticipantVolume,
    setIsVideoFeedEnabled,
} from '../../store/conference/actions'
import { AlertType, GameLobbyErrorType } from '../../state/alert'
import { useChime } from '.'
import { piepie } from '../../utils'

import { isChimeMeetingAvailable } from '../../firebase'
import { ChimeMessageTopics } from '../ChimeSdkWrapper'
import { State } from '../../store/types'
import { LocalStorage } from '../../state/storage'
import { setGameLobbyError } from '../../store/session/actions'
import alertSlice from '../../store/alert/alert'
import { setMannequinMode } from '../../store/mask/actions'

interface useCreateOrJoinConferenceProps {
    userSessionID: string
    sessionID: string
    audioElement: HTMLAudioElement
    onConfigurationOver?: (initialized: boolean) => void
    restricted?: boolean
}

/**
 * Creates or joins to the existing conference on a page load and
 * subscribes to the attendee real-time presence events.
 */
const useCreateOrJoinConference = ({
    userSessionID,
    sessionID,
    audioElement,
    onConfigurationOver,
    restricted,
}: useCreateOrJoinConferenceProps): void => {
    const dispatch = useDispatch()
    const chime = useChime()
    const participantsCount = useRef(0)
    const { isMicrophoneActive, participants } = useSelector(
        (state: State) => state.conference
    )

    useEffect(() => {
        if (
            !userSessionID ||
            !sessionID ||
            sessionID === 'null' ||
            restricted === undefined
        ) {
            return
        }

        let correcltyConfigured = false

        ;(async () => {
            dispatch(setIsLoading(true))
            new AsyncScheduler().start(async (): Promise<void> => {
                try {
                    const isMeetingAvailable = await isChimeMeetingAvailable(
                        sessionID
                    )
                    piepie.log(
                        'CHIME: isMeetingAvailable: ',
                        isMeetingAvailable,
                        sessionID,
                        userSessionID
                    )
                    const successful = await chime.createRoom(
                        sessionID,
                        userSessionID,
                        !isMeetingAvailable
                    )
                    if (!successful) {
                        dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
                        return
                    }
                    // We'll compare this time with the time when the user clicks on an enter room button to find out if the Chime session has expired.
                    // It's a quick fix/workaround. We need to address this issue when refactoring the Chime execution sequence.
                    // https://app.zenhub.com/workspaces/wagashi-5e57fae1d9593e0aa7ab07ad/issues/piepacker/wagashi/4544
                    localStorage.setItem(
                        LocalStorage.chimeSessionCreatedTime,
                        `${new Date().getTime()}`
                    )
                    const chimeMeetingId = chime.configuration.meetingId
                    dispatch(setChimeMeetingId(chimeMeetingId))
                    piepie.log(
                        'CHIME: chime.createRoom successful, chimeMeetingId: ',
                        chimeMeetingId
                    )

                    // Configure the devices
                    chime.configureDevices(audioElement, restricted)
                    correcltyConfigured = true
                } catch (error) {
                    dispatch(setError(error.message))
                    piepie.error(
                        'CHIME: useCreateOrJoinConference Error: ',
                        error.message
                    )
                    correcltyConfigured = false
                    return
                } finally {
                    dispatch(setIsLoading(false))
                    if (onConfigurationOver)
                        onConfigurationOver(correcltyConfigured)
                }
            })
        })()
    }, [
        restricted,
        chime,
        dispatch,
        userSessionID,
        sessionID,
        audioElement,
        onConfigurationOver,
    ])

    useEffect(() => {
        if (!chime.audioVideo) {
            return
        }

        // add the observer (listener) to monitor for call end
        const observer = {
            audioVideoDidStop: (sessionStatus: MeetingSessionStatus): void => {
                if (
                    sessionStatus.statusCode() ===
                    MeetingSessionStatusCode.AudioCallEnded
                ) {
                    chime.leaveRoom(true)
                    piepie.log('CHIME: leaving the room')
                }
            },
        }
        chime.audioVideo.addObserver(observer)

        return () => {
            chime.audioVideo.removeObserver(observer)
        }
    }, [chime])

    useEffect(() => {
        const attendeePresenceSet = new Set()
        const callback = (
            presentAttendeeId: any,
            present: any,
            externalUserId: string
        ) => {
            piepie.log(
                `CHIME: Attendee ID: ${presentAttendeeId} Present: ${present}`
            )
            if (present) {
                attendeePresenceSet.add(presentAttendeeId)
                dispatch(
                    addParticipant({
                        userId: externalUserId,
                        isLocal: userSessionID === externalUserId,
                    })
                )
                piepie.log(
                    'CHIME: Adding participant, externalUserId: ',
                    externalUserId
                )
                chime.audioVideo.realtimeSubscribeToVolumeIndicator(
                    presentAttendeeId,
                    (_, volume) => {
                        dispatch(editParticipantVolume(externalUserId, volume))
                    }
                )
            } else {
                attendeePresenceSet.delete(presentAttendeeId)
                chime.audioVideo.realtimeUnsubscribeFromVolumeIndicator(
                    presentAttendeeId,
                    (attendeeId) => {
                        console.log(attendeeId, 'volume unsubscribed')
                    }
                )
                dispatch(removeParticipant(externalUserId))
                piepie.log(
                    'CHIME: Removing removing participant, externalUserId:',
                    externalUserId
                )
            }
        }
        if (chime.audioVideo) {
            chime.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback)
        }
    }, [chime.audioVideo, dispatch, userSessionID])

    useEffect(() => {
        // When a new user joins the conference, we need to inform all participants about their muted state.
        const participantsLength = Object.values(participants).length
        if (participantsLength <= participantsCount.current) {
            return
        }

        piepie.log('MUTE: Participants updated. Sending a message: ', {
            externalUserId: chime.attendee.ExternalUserId,
            isMuted: !isMicrophoneActive,
        })
        participantsCount.current = participantsLength
        chime.sendMessage(ChimeMessageTopics.MUTE, {
            externalUserId: chime.attendee.ExternalUserId,
            isMuted: !isMicrophoneActive,
        })
    }, [participants, chime, isMicrophoneActive])

    useEffect(() => {
        if (!chime.audioVideo) {
            return
        }

        // Mute messages listener.
        chime.audioVideo.realtimeSubscribeToReceiveDataMessage(
            ChimeMessageTopics.MUTE,
            (data) => {
                // const { senderAttendeeId, senderExternalUserId } = data
                const attendeeToMute = JSON.parse(
                    new TextDecoder().decode(data.data)
                )

                piepie.log(
                    'MUTE: Received a message, attendeeToMute: ',
                    attendeeToMute
                )

                // The current user is the one that was requested to mute.
                if (
                    attendeeToMute.externalUserId ===
                    chime.attendee.ExternalUserId
                ) {
                    piepie.log('MUTE: Muting attendee: ', attendeeToMute)
                    // Via passing false as a second parameter to the "toggleAudioInputDevice" function,
                    // we are not broadcasting the mute event anymore.
                    chime.toggleAudioInputDevice(false, false)
                    dispatch(
                        alertSlice.actions.push({
                            type: AlertType.Info,
                            message: 'Muted',
                        })
                    )
                } else {
                    // Muting another participant.
                    piepie.log('MUTE: Updating participants isMuted field: ', {
                        externalUserId: attendeeToMute.externalUserId,
                        isMuted: attendeeToMute.isMuted,
                    })
                    dispatch(
                        editParticipantMuted({
                            externalUserId: attendeeToMute.externalUserId,
                            isMuted: attendeeToMute.isMuted,
                        })
                    )
                }
            }
        )

        // Toggle participants video feed listener.
        chime.audioVideo.realtimeSubscribeToReceiveDataMessage(
            ChimeMessageTopics.VIDEO_FEED,
            (data) => {
                const isVideoFeedEnabled = JSON.parse(
                    new TextDecoder().decode(data.data)
                )

                piepie.log(
                    'VIDEO_FEED: Received a message, showVideoFeed: ',
                    isVideoFeedEnabled
                )
                dispatch(setIsVideoFeedEnabled(isVideoFeedEnabled))
                if (isVideoFeedEnabled) {
                    // This means that we are switching from public to private room
                    dispatch(setMannequinMode(true))
                }
            }
        )

        return () => {
            chime.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
                ChimeMessageTopics.MUTE
            )
            chime.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
                ChimeMessageTopics.VIDEO_FEED
            )
        }
    }, [chime.audioVideo, chime, dispatch])
}

export default useCreateOrJoinConference
