import { Formik, Field, Form as Formk } from 'formik'
import * as React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { InputText } from './Form/fields'
import WarningIcon from '@material-ui/icons/Warning'
import { logoutUser } from '../firebase'
import * as Yup from 'yup'

import { ConfirmModalsProps, ConfirmDialog } from '../state/confirm'
import analytics from '../analytics'
import store from '../store'

export default function ConfirmModal(props: ConfirmModalsProps) {
    const {
        onClose,
        onValidate = () => {
            /** do nothing */
        },
        onConfirm = () => {
            /** do nothing */
        },
        open,
        confirmDialog,
        username = '',
        container,
    } = props
    return (
        // @ts-ignore Type definitions for Modal are incomplete
        <Modal
            onHide={onClose}
            show={open}
            centered
            animation={false}
            container={container}
            enforceFocus={false}
        >
            {confirmDialog === ConfirmDialog.Reset && (
                <ResetDialog onReset={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.Quit && (
                <QuitDialog onQuit={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.Error && (
                <ErrorDialog onRefresh={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.CloseAccount && (
                <CloseAccountDialog onValidate={onConfirm} />
            )}
            {confirmDialog === ConfirmDialog.BYOGWrongType && (
                <BYOGTypeError onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.BYOGTooLarge && (
                <BYOGTooLargeError onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.BYOGUploadFailed && (
                <BYOGUploadError onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.SaveDelete && (
                <SaveDelete onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.Override && (
                <Override onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.OverridePremium && (
                <OverridePremium onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.Ban && (
                <BanDialog
                    onValidate={onValidate}
                    onConfirm={onConfirm}
                    username={username}
                />
            )}
            {confirmDialog === ConfirmDialog.Mute && (
                <MuteDialog onValidate={onValidate} />
            )}
            {confirmDialog === ConfirmDialog.SwitchGame && (
                <SwitchDialog
                    gameTitle={props.gameTitle}
                    onConfirm={onConfirm}
                />
            )}
        </Modal>
    )
}

function ResetDialog(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('ResetConfirm')}</Modal.Title>
            </Modal.Header>
            <p>{t('ResetGame')}</p>
            <Button
                data-test="ModalResetConfirmButton"
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    analytics.track('ResetGame', {
                        game: store.getState().session.gameName,
                    })
                    props.onReset()
                }}
            >
                {t('Reset')}
            </Button>
        </>
    )
}

function QuitDialog(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('QuitConfirm')}</Modal.Title>
            </Modal.Header>
            <p>{t('QuitGame')}</p>
            <Button
                block
                variant="danger"
                className="btn-large"
                onClick={(event) => {
                    event.preventDefault()
                    analytics.track('QuitGame', {
                        game: store.getState().session.gameName,
                    })
                    props.onQuit()
                }}
                autoFocus
            >
                {t('Quit')}
            </Button>
        </>
    )
}

function ErrorDialog(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('ErrorConfirm')}</Modal.Title>
            </Modal.Header>
            <p>{t('ErrorAdvice')}</p>
            <Button
                block
                variant="danger"
                className="btn-large"
                onClick={(event) => {
                    event.preventDefault()
                    analytics.track('RefreshGame', {
                        game: store.getState().session.gameName,
                    })
                    props.onRefresh()
                }}
                autoFocus
            >
                {t('Refresh')}
            </Button>
        </>
    )
}

function CloseAccountDialog(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <WarningIcon className="danger-icon" /> {t('CloseAccount')}
                </Modal.Title>
            </Modal.Header>
            <div style={{ fontSize: 19, textAlign: 'center' }}>
                {t('CloseAccountWarning')}
            </div>
            <div className="logout-close" onClick={() => logoutUser(null)}>
                {t('CloseLogout')}
            </div>
            <p>{t('CloseAccountConfirm')}</p>
            <Formik
                initialValues={{}}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Invalid email address')
                        .required(t('Required')),
                    password: Yup.string()
                        .required(t('NoPasswordProvided'))
                        .min(6, t('PasswordTooShort')),
                })}
                onSubmit={(values: { email?: string; password?: string }) => {
                    analytics.track('CloseAccount', {
                        game: store.getState().session.gameName,
                    })
                    props.onValidate(values)
                }}
            >
                <Formk autoComplete="off">
                    <Form.Group>
                        <Field
                            label={t('EmailAddress')}
                            placeholder={t('EmailAddress')}
                            name="email"
                            autocomplete="off" // to turn off autocomplete
                            id="email-input"
                            component={InputText}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Field
                            name="password"
                            label={t('Password')}
                            placeholder={t('Password')}
                            autocomplete="new-password" // to turn off autocomplete, do not work on every browsers
                            type="password"
                            id="password-input"
                            component={InputText}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button
                            className="btn-large danger"
                            type="submit"
                            id="login-button"
                            block
                        >
                            {t('CloseAccountText')}
                        </Button>
                    </Form.Group>
                </Formk>
            </Formik>
        </>
    )
}

function BYOGTypeError(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('TypeFailed')}</Modal.Title>
            </Modal.Header>
            <p>{t('TypeFailedErrorMessage')}</p>
            <Button
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    props.onValidate()
                }}
            >
                {t('OK')}
            </Button>
        </>
    )
}

function BYOGTooLargeError(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('TooLarge')}</Modal.Title>
            </Modal.Header>
            <p>{t('TooLargeErrorMessage')}</p>
            <Button
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    props.onValidate()
                }}
            >
                {t('OK')}
            </Button>
        </>
    )
}

function BYOGUploadError(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('UploadFailed')}</Modal.Title>
            </Modal.Header>
            <p>{t('UploadFailedErrorMessage')}</p>
            <Button
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    props.onValidate()
                }}
            >
                {t('OK')}
            </Button>
        </>
    )
}

function SaveDelete(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('SaveDeleteTitle')}</Modal.Title>
            </Modal.Header>
            <p>{t('SaveDeleteMessage')}</p>
            <Button
                data-test="ModalDeleteSavestateConfirmButton"
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    analytics.track('DeleteSave', {
                        game: store.getState().session.gameName,
                    })
                    props.onValidate()
                }}
            >
                {t('OK')}
            </Button>
        </>
    )
}

function Override(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('OverrideTitle')}</Modal.Title>
            </Modal.Header>
            <p>{t('OverrideMessage')}</p>
            <div className="container-double">
                <Button
                    data-test="ModalDeleteSavestateConfirmButton"
                    className="btn-large"
                    block
                    onClick={(event) => {
                        event.preventDefault()
                        props.onValidate()
                    }}
                >
                    {t('Override')}
                </Button>
            </div>
        </>
    )
}

function OverridePremium(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('OverridePremiumTitle')}</Modal.Title>
            </Modal.Header>
            <p>{t('OverridePremiumMessage')}</p>
            <Button
                data-test="ModalDeleteSavestateConfirmButton"
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    props.onValidate()
                }}
            >
                {t('Resume')}
            </Button>
        </>
    )
}

function BanDialog(props) {
    const { t } = useTranslation()
    const [checked, setChecked] = React.useState(false)

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('BanDialogTitle')}</Modal.Title>
            </Modal.Header>
            <p>
                {t('BanDialogText', {
                    name: props.username,
                })}
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 0,
                    margin: 0,
                    marginLeft: '3px',
                }}
            >
                <input
                    name="checkBan"
                    type="checkbox"
                    id="checkBan"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    style={{
                        transform: 'scale(1.3)',
                    }}
                />
                <label
                    htmlFor="checkBan"
                    style={{
                        paddingLeft: '12px',
                        marginTop: '8px',
                        fontSize: '13.5px',
                    }}
                >
                    {t('BanCheckboxText')}
                </label>
            </div>
            <br />
            <Button
                data-test="ModalBanDialogConfirmButton"
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    if (checked) props.onValidate()
                    if (!checked) props.onConfirm()
                }}
            >
                {t('OK')}
            </Button>
        </>
    )
}

function MuteDialog(props) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('MutedConfirmTitle')}</Modal.Title>
            </Modal.Header>
            <Button
                className="btn-large"
                block
                onClick={(event) => {
                    event.preventDefault()
                    props.onValidate()
                }}
            >
                {t('Yes')}
            </Button>
        </>
    )
}

function SwitchDialog({ onConfirm, gameTitle }) {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('SwitchConfirm')}</Modal.Title>
            </Modal.Header>
            <p>{t('SwitchGame')}</p>
            <Button
                data-test="SwitchGameConfirmButton"
                onClick={(event) => {
                    event.preventDefault()
                    analytics.track('SwitchGame', {
                        game: store.getState().session.gameName,
                    })
                    onConfirm()
                }}
                autoFocus
            >
                {t('SwitchGamePlay', { gameTitle })}
            </Button>
        </>
    )
}
