// fullscreen mode
export const patternFS = [
    'p',
    'i',
    'e',
    'p',
    'a',
    'c',
    'k',
    'e',
    'r',
    'ArrowLeft',
    'ArrowRight',
    'f',
    's',
]

// fps display on local mode
export const patternFPS = [
    'p',
    'i',
    'e',
    'p',
    'a',
    'c',
    'k',
    'e',
    'r',
    'ArrowLeft',
    'ArrowRight',
    'f',
    'p',
    's',
]

// display the settings menu
export const patternSettings = [
    'p',
    'i',
    'e',
    'ArrowLeft',
    'ArrowRight',
    's',
    'e',
    't',
]

export const patternCleanVideoFeed = [
    'p',
    'i',
    'e',
    'ArrowLeft',
    'ArrowRight',
    'v',
    'i',
    'd',
    'e',
    'o',
]
