export interface RTCPEvent {
    Name: RTCPEventName
    Data: string
}

export enum RTCPEventName {
    ICE_CANDIDATE = 'iceCandidate',
    MEDIA_STREAM_INITIALIZED = 'mediaStreamInitialized',
    MEDIA_STREAM_SDP_AVAILABLE = 'mediaStreamSdpAvailable',
}
