import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GameroomModalSectionType } from '../../state/gameroomModalSection'

export interface gameroomModalSectionState {
    gameroomModalSection: GameroomModalSectionType
}

const initialState = {
    gameroomModalSection: GameroomModalSectionType.NONE,
}

const gameroomModalSectionSlice = createSlice({
    name: 'gameroomModalSection',
    initialState: initialState,
    reducers: {
        setGameroomModal: (
            state,
            action: PayloadAction<GameroomModalSectionType>
        ) => {
            state.gameroomModalSection = action.payload
        },
    },
})

export type gameroomModalSectionAction = ReturnType<
    typeof gameroomModalSectionSlice.actions.setGameroomModal
>

export default gameroomModalSectionSlice
