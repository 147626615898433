import { useEffect, useState, useCallback } from 'react'
import { getOpenRooms } from '../../../donkey/donkey'
import { Game, SessionFromFirebase } from '../../../state/starfox'
import { GameModalType } from '../types'

interface useGameAchievementsProps {
    game: Game
    type: GameModalType
}

interface useGameAchievementsPayload {
    sessions: SessionFromFirebase[]
    onRefreshHandle: () => void
}

export const useOpenRooms = ({
    game,
    type,
}: useGameAchievementsProps): useGameAchievementsPayload => {
    const [sessions, setSessions] = useState<SessionFromFirebase[]>([])

    const onRefreshHandle = useCallback(async () => {
        let s: SessionFromFirebase[] = []
        if (type === GameModalType.BYOG || type === GameModalType.Mood) {
            s = await getOpenRooms()
        } else if (game) {
            s = await getOpenRooms(game.id)
        }
        setSessions(s)
    }, [game, type])

    useEffect(() => {
        onRefreshHandle()
        return () => {
            setSessions([])
        }
    }, [onRefreshHandle])

    return { sessions, onRefreshHandle }
}
