import React, { FC } from 'react'
import cls from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { setActiveMaskId, setMannequinMode } from '../../store/mask/actions'
import { State } from '../../store/types'
import style from './style.module.scss'
import analytics from '../../analytics'
import { ToggleButton, Tooltip } from '../../stories'
import { useTranslation } from 'react-i18next'

const MaskSelector: FC = () => {
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const { activeMaskId, metadataStore } = useSelector(
        (state: State) => state.mask
    )
    const mannequinMode = useSelector(
        (state: State) => state.mask.mannequinMode
    )
    const isVideoFeedEnabled = useSelector(
        (state: State) => state.conference.isVideoFeedEnabled
    )

    return (
        <div className={style.root}>
            <div className={style.mannequinModeWrapper}>
                <Tooltip
                    position="bottom"
                    text={t('AvatarPublicRoomTooltip')}
                    contentClassName={style.tooltipContent}
                    disabled={isVideoFeedEnabled}
                >
                    <div className={style.mannequinModeSwitchContainer}>
                        <span>{t('AvatarMode')}</span>
                        <ToggleButton
                            className={style.mannequinModeSwitch}
                            disabled={!isVideoFeedEnabled}
                            value={mannequinMode || !isVideoFeedEnabled}
                            setValue={() => {
                                dispatch(setMannequinMode(!mannequinMode))
                            }}
                        />
                    </div>
                </Tooltip>
            </div>

            <div className={style.maskList}>
                <button
                    className={cls(
                        style.button,
                        !activeMaskId ? style.buttonActive : null
                    )}
                    onClick={() => {
                        dispatch(setActiveMaskId(null))
                        analytics.track('Remove Mask')
                    }}
                >
                    <NotInterestedIcon className={style.icon} />
                </button>
                {Object.entries(metadataStore).map(([maskId, metadata]) => (
                    <button
                        data-test={'FaceMask-' + maskId}
                        key={maskId}
                        className={cls(
                            style.button,
                            activeMaskId === maskId ? style.buttonActive : null
                        )}
                        onClick={() => {
                            analytics.track('SetMask', {
                                MaskId: maskId,
                            })
                            dispatch(setActiveMaskId(maskId))
                        }}
                    >
                        <img
                            className={style.buttonImage}
                            src={metadata.placeholder}
                            alt={metadata.name || ''}
                        />
                    </button>
                ))}
            </div>
        </div>
    )
}

export default MaskSelector
