import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as selector from '../state/selectors'
import { Switch } from '../../../components/ui'
import { setNicknameColor } from '../../../firebase'
import { NicknameColorType } from '../../../state/starfox'

const GoldenName: React.FC = () => {
    const { t } = useTranslation()
    const colorList = useSelector(selector.getColorList)
    const nicknameColor = useSelector(selector.getNicknameColor)

    const hasColor = colorList.length > 0
    const isGold = nicknameColor === NicknameColorType.Gold
    const hasGoldColor = colorList.includes(NicknameColorType.Gold)

    const handleChange = () => {
        const color = isGold ? NicknameColorType.None : NicknameColorType.Gold
        setNicknameColor(color)
    }

    return (
        <div className="flex-spacebetween">
            <div>
                <p className={styles.microFont}>{t('NameInGold')}</p>
                {hasGoldColor ? (
                    <p className="font-20">{t('ShowNameInGold')}</p>
                ) : (
                    <p className="font-20 font-disabled">{t('NotAvailable')}</p>
                )}
            </div>
            <Switch
                checked={isGold}
                disabled={!hasColor}
                onChange={handleChange}
                label={t('ShowOnProfileColor')}
            />
        </div>
    )
}

export default GoldenName
