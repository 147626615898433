import {
    SET_ACTIVE_MASK_ID,
    RESET_STATE,
    SET_MASK_STORE,
    ADD_MASK_STORE,
    SetActiveMaskIdAction,
    MaskActionTypes,
    MaskMetadata,
    SetMannequinMode,
    SET_MANNEQUIN_MODE,
} from './types'

export function setActiveMaskId(
    id: SetActiveMaskIdAction['payload']
): MaskActionTypes {
    return {
        type: SET_ACTIVE_MASK_ID,
        payload: id,
    }
}

export function resetState(): MaskActionTypes {
    return {
        type: RESET_STATE,
    }
}

export function setMaskStore(maskMetadataStore: {
    [maskId: string]: MaskMetadata
}): MaskActionTypes {
    return {
        type: SET_MASK_STORE,
        payload: maskMetadataStore,
    }
}

export function addMaskStore(maskMetadataStore: {
    [maskId: string]: MaskMetadata
}): MaskActionTypes {
    return {
        type: ADD_MASK_STORE,
        payload: maskMetadataStore,
    }
}

export function setMannequinMode(
    active: SetMannequinMode['payload']
): MaskActionTypes {
    return {
        type: SET_MANNEQUIN_MODE,
        payload: active,
    }
}
