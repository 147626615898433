import React, { FC } from 'react'
import cls from 'classnames'
import style from './styles.module.scss'
import { Button } from '../../stories'
import { IAuthenticateSignIn } from './types'

const logoUrl = 'https://assets.piepacker.com/random/facebook-logo.svg'

const AuthWithFacebook: FC<IAuthenticateSignIn> = ({ label, onClick }) => (
    <Button onClick={onClick} className={cls(style.btn, style.facebookButton)}>
        <img src={logoUrl} alt="Facebook" />
        {label}
    </Button>
)

export default AuthWithFacebook
