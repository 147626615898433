import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Social from './Social'

interface IConnections {
    refreshUser: () => void
}

const Connections: React.FC<IConnections> = ({ refreshUser }) => {
    const { t } = useTranslation()

    return (
        <>
            <p className={styles.pageTitle}>{t('Connections')}</p>
            <Social refreshUser={refreshUser} />
        </>
    )
}

export default Connections
