import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { validateNickname } from '../../../utils'

Yup.addMethod(Yup.string, 'nickname', validateNickname)

export const useValidation = () => {
    const { t } = useTranslation()

    return Yup.object({
        displayName: Yup.string()
            .trim()
            .required('Required')
            .min(3, t('NicknameLengthError'))
            .max(12, t('NicknameLengthError'))
            .nickname(t('DisplayNameErrorMessage')),
    })
}
