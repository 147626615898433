import React, { FC } from 'react'
import { Game, SessionMoodType } from '../../state/starfox'
import cls from 'classnames'
import {
    gameVideoBgImage,
    getVideoMedia,
    moodCoverImg,
    moodCoverImgFallback,
} from './Card/constants'

export interface HomePageMediaProps {
    id: Game['id']
    mood: SessionMoodType
    isBYOG: boolean
    className?: string
}

const HomePageMedia: FC<HomePageMediaProps> = ({
    id,
    mood,
    isBYOG,
    className = cls('home-page-media'),
}) => {
    const videoUrl = getVideoMedia({
        gameId: id,
        kind: 'featured',
        isBYOG,
        isGameModal: true,
    })
    return (
        <>
            {mood ? (
                <div className={className}>
                    <div
                        key={id}
                        className="image"
                        style={{
                            backgroundImage: `url(${moodCoverImg(
                                id
                            )}), url(${moodCoverImgFallback(id)})`,
                        }}
                    ></div>
                </div>
            ) : (
                <div className={className}>
                    <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        key={id}
                        className="video"
                        style={{
                            backgroundImage: gameVideoBgImage(id),
                        }}
                        src={videoUrl}
                    ></video>
                </div>
            )}
        </>
    )
}

export default HomePageMedia
