import { createContext, useContext } from 'react'
import ChimeSdkWrapper from '../ChimeSdkWrapper'

// ChimeContext is a global var not put in redux, because it is mutable
export const ChimeContext = createContext(new ChimeSdkWrapper())

/**
 * Exposes Chime SDK Wrapper class methods and properties.
 */
const useChime = (): ChimeSdkWrapper => {
    const chime = useContext(ChimeContext)

    return chime
}

export default useChime
