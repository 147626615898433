import styles from './styles.module.scss'
import React, { FC } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { gameCardMaximumTags } from './constants'
import { ICard } from './types'
import { FilterTag } from '../../../stories'
import {
    setSelectedTags,
    selectSelectedTags,
    setNumberOfPlayers,
} from '../../../store/home/homeV2'
import {
    getNumberOfPlayersTagName,
    numberOfPlayersAsTags,
} from '../Commons/constants'

const CardFooter: FC<ICard> = ({ game, category }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedTags = useSelector(selectSelectedTags)

    const handleNumberOfPlayersClick = () => {
        const removeCurrentlySelectedPlayers = selectedTags.filter(
            (tag) => !Object.keys(numberOfPlayersAsTags).includes(tag)
        )
        dispatch(setNumberOfPlayers(game.Players))
        dispatch(
            setSelectedTags([
                getNumberOfPlayersTagName(game.Players),
                ...removeCurrentlySelectedPlayers,
            ])
        )
    }

    return (
        <div className={classnames(styles.cardFooter)}>
            <div className={styles.cardTagsContainer}>
                <div className={styles.cardTags}>
                    {game.Tags?.slice(0, gameCardMaximumTags(category)).map(
                        (tag) => (
                            <FilterTag
                                size="small"
                                theme="secondary"
                                className={styles.cardTag}
                                key={tag}
                                onClick={() =>
                                    dispatch(
                                        setSelectedTags([...selectedTags, tag])
                                    )
                                }
                            >
                                #{t(tag)?.toLowerCase()}
                            </FilterTag>
                        )
                    )}
                </div>
            </div>
            <FilterTag
                className={styles.playerTag}
                size="small"
                theme="secondary"
                onClick={handleNumberOfPlayersClick}
            >
                {t('PlayerCountSmall', {
                    count: game.Players,
                })}
            </FilterTag>
        </div>
    )
}

export default CardFooter
