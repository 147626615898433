import { useTranslation } from 'react-i18next'

const NUMBER_OF_PLAYERS_UP_TO = [2, 3, 4]
export default function useNumberOfPlayers() {
    const { t } = useTranslation()
    const noValueOption = { value: 0, name: t('FindGameNumberOfPlayersClear') }
    return [
        noValueOption,
        ...NUMBER_OF_PLAYERS_UP_TO.map((number) => ({
            value: number,
            name: t('FindGameNumberOfPlayers', { count: number }),
        })),
    ]
}
