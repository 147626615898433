import { KEY } from './keys'
import {
    asteroidsMapping,
    boblMapping,
    fleaMapping,
    genesisPad3Mapping,
    microMagesMapping,
    missileMapping,
    psychoPinballMapping,
    superBatPuncherMapping,
    superBreakoutMapping,
} from './mapping'
import { piepie } from '../utils'
import { LocalPlayer } from '../handlers/InputHandler'
import store, { actions } from '../store'

let localInput = null
let gameId = ''
let gameCore = ''
let gameSharedPad = ''
let localPlayers: LocalPlayer[] = []
let alienHackPrevY = 0
let alienHackPrevSel = 0
const sendStatePeriodMS = 8
export const MAX_PLAYER = 8

// Game controller state
export const keyState: Array<Map<number, boolean>> = []
const analogRkeyState: Array<Array<number>> = []
const analogLkeyState: Array<Array<number>> = []
const analogAccuLkeyState: Array<Array<number>> = []

const newPlayerState = (): Map<number, boolean> => {
    const st = new Map<number, boolean>()
    st.set(KEY.B, false)
    st.set(KEY.Y, false)
    st.set(KEY.SELECT, false)
    st.set(KEY.START, false)
    st.set(KEY.UP, false)
    st.set(KEY.DOWN, false)
    st.set(KEY.LEFT, false)
    st.set(KEY.RIGHT, false)
    st.set(KEY.A, false)
    st.set(KEY.X, false)
    st.set(KEY.L1, false)
    st.set(KEY.R1, false)
    st.set(KEY.L2, false)
    st.set(KEY.R2, false)
    st.set(KEY.L3, false)
    st.set(KEY.R3, false)
    return st
}

export const setKeyState = (port: number, k: number, state: boolean) => {
    if (port >= MAX_PLAYER) {
        return
    }

    // All Genesis game + BYOG
    if (gameCore === 'blastem_libretro' || gameId === 'Sega Genesis') {
        // But Xeno
        if (gameId !== 'Xeno Crisis') {
            k =
                genesisPad3Mapping.get(k) === undefined
                    ? k
                    : genesisPad3Mapping.get(k)
        }
    }

    switch (gameId) {
        case 'Micro Mages':
            k =
                microMagesMapping.get(k) === undefined
                    ? k
                    : microMagesMapping.get(k)
            break
        case 'Micro Mages Leaderboard Rush':
            k =
                microMagesMapping.get(k) === undefined
                    ? k
                    : microMagesMapping.get(k)
            break
        case 'Flea':
            k = fleaMapping.get(k) === undefined ? k : fleaMapping.get(k)
            break
        case 'Super Bat Puncher (Demo)':
            k =
                superBatPuncherMapping.get(k) === undefined
                    ? k
                    : superBatPuncherMapping.get(k)
            break
        case 'Bobl':
            k = boblMapping.get(k) === undefined ? k : boblMapping.get(k)
            break
        case 'Psycho Pinball (Europe) (En,Fr,De,Es,It)':
            k =
                psychoPinballMapping.get(k) === undefined
                    ? k
                    : psychoPinballMapping.get(k)
            break
        case 'Missile Command':
            k = missileMapping.get(k) === undefined ? k : missileMapping.get(k)
            break
        case 'Asteroids':
            k =
                asteroidsMapping.get(k) === undefined
                    ? k
                    : asteroidsMapping.get(k)
            break
        case 'Super Breakout':
            k =
                superBreakoutMapping.get(k) === undefined
                    ? k
                    : superBreakoutMapping.get(k)
            break
        default:
            break
    }

    keyState[port].set(k, state)
}

export const resetKeyState = () => {
    for (let p = 0; p < MAX_PLAYER; p++) {
        keyState[p] = newPlayerState()
        analogLkeyState[p] = [0x0000, 0x0000]
        analogRkeyState[p] = [0x0000, 0x0000]
        analogAccuLkeyState[p] = [0x0000, 0x0000]
    }
}

resetKeyState()

export const setAnalogLKeyState = (port: number, state: number[]) => {
    analogLkeyState[port] = state
}

export const setAnalogRKeyState = (port: number, state: number[]) => {
    analogRkeyState[port] = state
}

const dpad2analog = (port: number, state: Array<number>, factor: number) => {
    const LEFT = -32768
    const RIGHT = 32767
    const UP = -32768
    const DOWN = 32767
    if (keyState[port].get(KEY.LEFT)) {
        state[0] = LEFT * factor
    } else if (keyState[port].get(KEY.RIGHT)) {
        state[0] = RIGHT * factor
    } else if (keyState[port].get(KEY.UP)) {
        state[1] = UP * factor
    } else if (keyState[port].get(KEY.DOWN)) {
        state[1] = DOWN * factor
    }
}

const convertAccuLAnalog = (
    port: number,
    state: Array<number>,
    delta: number
) => {
    const accumulator = analogAccuLkeyState[port]
    const deadzone = 0x2800
    for (let axe = 0; axe < 2; axe++) {
        // Accumulate position
        if (state[axe] < -deadzone) {
            accumulator[axe] -= delta
        }
        if (state[axe] > deadzone) {
            accumulator[axe] += delta
        }
        // Clamp
        accumulator[axe] = Math.min(Math.max(accumulator[axe], -32768), 32767)
        // Use accumulator as analog pos
        state[axe] = accumulator[axe]
    }
}

const getAnalogLState = (port: number): Array<number> => {
    const state = Array.from(analogLkeyState[port])
    switch (gameId) {
        case 'Missile Command':
            // Reduce sensibility if analog is used
            const FACTOR_ANALOG = 0.4
            state[0] *= FACTOR_ANALOG
            state[1] *= FACTOR_ANALOG
            // Digital to analog with lower sensibility
            dpad2analog(port, state, 0.3)
            break
        case 'Asteroids':
            // Digital to analog
            dpad2analog(port, state, 1.0)
            break
        case 'Super Breakout':
            // Digital to analog
            dpad2analog(port, state, 1.0)
            // Analog is the absolute position of the pad
            // but we want something sticky
            convertAccuLAnalog(port, state, 400)
            break
        case 'Pong':
            // Digital to analog
            dpad2analog(port, state, 1.0)
            // Analog is the absolute position of the pad
            // but we want something sticky
            convertAccuLAnalog(port, state, 600)
            // And finally map left/right into top/bottom
            state[0] = -state[1]
            // Clamp (needed due to above substraction)
            state[0] = Math.min(Math.max(state[0], -32768), 32767)
            break
        default:
            break
    }
    return state
}

const getDigitalState = (port: number): Map<number, boolean> => {
    if (
        gameId === 'Nintendo 64' ||
        gameId === 'Sony PlayStation' ||
        gameCore === 'swanstation_libretro' ||
        gameId === 'Little Big Adventure 2'
    ) {
        return keyState[port]
    }

    const newKeyState = new Map(keyState[port])
    const deadzone = 0x2800

    if (analogLkeyState[port][0] < -deadzone) {
        newKeyState.set(KEY.LEFT, true)
    }
    if (analogLkeyState[port][0] > deadzone) {
        newKeyState.set(KEY.RIGHT, true)
    }
    if (analogLkeyState[port][1] < -deadzone) {
        newKeyState.set(KEY.UP, true)
    }
    if (analogLkeyState[port][1] > deadzone) {
        newKeyState.set(KEY.DOWN, true)
    }

    // Special case to shoot with Xeno Crisis
    // It is funnier this way and it is a huge help
    // to test pad
    if (gameId === 'Xeno Crisis') {
        if (analogRkeyState[port][0] < -deadzone) {
            newKeyState.set(KEY.Y, true)
        }
        if (analogRkeyState[port][0] > deadzone) {
            newKeyState.set(KEY.A, true)
        }
        if (analogRkeyState[port][1] < -deadzone) {
            newKeyState.set(KEY.X, true)
        }
        if (analogRkeyState[port][1] > deadzone) {
            newKeyState.set(KEY.B, true)
        }
    }

    if (gameId === 'Alien Breed Tower Assault') {
        // Pressing a combo of Y + select (CTRL-ESC) will trigger an annoying reset of the machine
        // This code will disable SELECT/Y when Y/SELECT is pressed for few frames
        const disabled_frame = 8 // maybe not the minimum
        if (newKeyState.get(KEY.Y)) {
            alienHackPrevY = disabled_frame
        }
        if (newKeyState.get(KEY.SELECT)) {
            alienHackPrevSel = disabled_frame
        }
        if (alienHackPrevSel > 0) {
            newKeyState.set(KEY.Y, false)
            alienHackPrevSel--
        }
        if (alienHackPrevY > 0) {
            newKeyState.set(KEY.SELECT, false)
            alienHackPrevY--
        }
    }

    return newKeyState
}

export const sendKeyState = (
    inputChannel: RTCDataChannel,
    inputReady: boolean
) => {
    if (localInput !== null) {
        for (let i = 0; i < localPlayers.length; i++) {
            const indice = localPlayers[i].index
            const digitals = getDigitalState(indice)
            // Arkanoid expects an object not a map (well it used [] instead of get/set
            // method)
            //
            // Ideally we want to fix arkanoid. Actually we might want to use a basic
            // array instead of a complex map. But current change is an hot fix
            //
            // Greg: so I didn't find a way to iterate on the map that doesn't trigger
            // a warning or a type error. So luckily for me, keys are incremental indices.
            for (let key = 0; key < 16; key++) {
                localInput.input_user_state[indice][key] = digitals.get(key)
            }
            localInput.input_analogL_user_state[indice] =
                getAnalogLState(indice)
            localInput.input_analogR_user_state[indice] =
                analogRkeyState[indice]
        }
        if (gameSharedPad === 'All' || gameSharedPad === 'AllButStartSelect') {
            // An array of map would be nice, but I don't know how to write it, so copy/past rulez
            const digitals0 = getDigitalState(0)
            const digitals1 = getDigitalState(1)
            const digitals2 = getDigitalState(2)
            const digitals3 = getDigitalState(3)
            for (let key = 0; key < 16; key++) {
                if (
                    gameSharedPad === 'AllButStartSelect' &&
                    (key === KEY.START || key === KEY.SELECT)
                ) {
                    continue
                }
                localInput.input_user_state[0][key] =
                    digitals0.get(key) ||
                    digitals1.get(key) ||
                    digitals2.get(key) ||
                    digitals3.get(key)
            }
            // Merge analog value
            const mergedAnalogState: Array<number> = [0, 0]
            for (let i = 0; i < MAX_PLAYER; i++) {
                const analog = getAnalogLState(i)
                mergedAnalogState[0] += analog[0]
                mergedAnalogState[1] += analog[1]
            }
            for (let i = 0; i < 2; i++) {
                mergedAnalogState[i] = Math.min(
                    Math.max(mergedAnalogState[i], -0x8000),
                    0x7fff
                )
            }
            localInput.input_analogL_user_state[0] = mergedAnalogState
        }
    } else {
        const data = new Uint8Array(localPlayers.length * 11)
        let idx = 0
        // we only send the inputs of localPlayers
        for (let i = 0; i < localPlayers.length; i++) {
            if (i >= MAX_PLAYER || !keyState[localPlayers[i].index]) break
            // Header info of current player
            const indice = localPlayers[i].index
            const digitals = getDigitalState(indice)
            data[idx] = indice
            // Add a flag (0x80) if the user is using a gamepad
            data[idx] |= localPlayers[i].device === -1 ? 0 : 0x80
            idx++
            // Digital pad
            let digital = 0
            digitals.forEach((value, key) => {
                digital |= value ? 1 << key : 0
            })
            data[idx + 0] = digital & 0xff
            data[idx + 1] = (digital >> 8) & 0xff
            idx += 2
            // Left Analog pad
            const analogL = getAnalogLState(indice)
            data[idx + 0] = (analogL[0] >> 0) & 0xff
            data[idx + 1] = (analogL[0] >> 8) & 0xff
            data[idx + 2] = (analogL[1] >> 0) & 0xff
            data[idx + 3] = (analogL[1] >> 8) & 0xff
            idx += 4
            // Right Analog pad
            data[idx + 0] = analogRkeyState[indice][0] & 0xff
            data[idx + 1] = (analogRkeyState[indice][0] >> 8) & 0xff
            data[idx + 2] = analogRkeyState[indice][1] & 0xff
            data[idx + 3] = (analogRkeyState[indice][1] >> 8) & 0xff
            idx += 4
        }

        if (inputChannel && inputChannel.readyState === 'open' && inputReady) {
            inputChannel.send(data)
        }
    }
}

// getControllerIndex returns the player index of an associated controller
export const getDeviceIndex = (id: number) => {
    for (const [, player] of Object.entries(localPlayers)) {
        if (player.device === id) {
            return player.index
        }
    }
    return -1
}

// isControllerActive returns true if there is only 1 local player
// or if one of the local players is playing with this controller
export const isControllerActive = (id: number): boolean => {
    return localPlayers.length <= 1 || getDeviceIndex(id) >= 0
}

// if there is only one local player, then we assign him the last used device
export const setLastUsedDevice = (id: number) => {
    if (localPlayers.length === 1 && localPlayers[0].device !== id) {
        localPlayers[0].device = id
        store.dispatch(actions.session.setLocalPlayers(localPlayers.slice()))
    }
}

export const setLocalInput = (input: any) => {
    localInput = input
}

export const unsetLocalInput = () => {
    localInput = null
}

export const setGameInfo = (id: string, core: string, shared: string) => {
    gameId = id
    gameCore = core
    gameSharedPad = shared
}

export const setLocalPlayers = (lp: LocalPlayer[]) => {
    localPlayers = lp
}

let timeLastSentTick
let tickI = 0
let tickMin
let tickMax
let tickAvg
let instantCount = 0
let expectedCount = 0
let highCount = 0
let veryHighCount = 0
let ultraHighCount = 0

const ticker = () => {
    const t = performance.now()
    if (timeLastSentTick === undefined) {
        timeLastSentTick = t
    }
    const delta = t - timeLastSentTick
    timeLastSentTick = t
    if (tickMin === undefined || delta < tickMin) {
        tickMin = delta
    }
    if (tickMax === undefined || delta > tickMax) {
        tickMax = delta
    }
    if (tickAvg === undefined) {
        tickAvg = delta
    }
    tickAvg = (tickAvg * tickI + delta) / (tickI + 1)
    if (delta < 2) {
        instantCount++
    } else if (delta < 12) {
        expectedCount++
    } else if (delta < 32) {
        highCount++
    } else if (delta < 128) {
        // 128 ms is bad but few of them remain acceptable
        veryHighCount++
    } else {
        // major freeze
        ultraHighCount++
    }
    if (tickI % 10000 === 0) {
        piepie.log(
            `[INPUT] tick delay (ms): delta=${delta} avg=${tickAvg} min=${tickMin} max=${tickMax} [i=${instantCount},e=${expectedCount},h=${highCount},vh=${veryHighCount},uh=${ultraHighCount}]`
        )
        // Reset counter
        tickI = 0
        instantCount = 0
        expectedCount = 0
        highCount = 0
        veryHighCount = 0
        ultraHighCount = 0
        tickMin = undefined
        tickAvg = undefined
        tickMax = undefined
    }
    tickI++
}

interface intervals {
    key: NodeJS.Timeout
    tick: NodeJS.Timeout
}

export function init(
    inputChannel: RTCDataChannel,
    inputReady: boolean
): intervals {
    // Push input state every N ms
    piepie.log('[INPUT] init')
    return {
        key: setInterval(
            sendKeyState,
            sendStatePeriodMS,
            inputChannel,
            inputReady
        ),
        tick: setInterval(ticker, sendStatePeriodMS),
    }
}
