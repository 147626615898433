import store from '../store'
import { AlertType } from '../state/alert'
import {
    PubSubEvent,
    globalPubSub,
    SubscriptionInterface,
} from '../event/event'
import { piepie } from '../utils'
import alertSlice from '../store/alert/alert'
import screenSlice from '../store/screen/screen'

export default class ScreenHandler {
    savedSub: SubscriptionInterface
    saveFailedSub: SubscriptionInterface
    loadedSub: SubscriptionInterface
    pausedSub: SubscriptionInterface
    resumedSub: SubscriptionInterface
    resetSub: SubscriptionInterface
    gamepadConnectedSub: SubscriptionInterface
    gamepadDisconnectedSub: SubscriptionInterface
    errorSub: SubscriptionInterface
    webGLContextLostSub: SubscriptionInterface

    constructor() {
        piepie.log('[SCREEN HANDLER] init')
        this.savedSub = globalPubSub.sub(
            PubSubEvent.GAME_SAVED,
            () => {
                piepie.log('[SCREEN HANDLER] game saved')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GameSaved',
                        variant: 'success',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Success,
                        message: 'GameSaved',
                    })
                )
            },
            0
        )
        this.saveFailedSub = globalPubSub.sub(
            PubSubEvent.GAME_SAVE_FAILED,
            () => {
                piepie.log('[SCREEN HANDLER] game save failed')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'SaveFailure',
                        variant: 'danger',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Error,
                        message: 'SaveFailure',
                        autoClose: false,
                    })
                )
            },
            0
        )
        this.loadedSub = globalPubSub.sub(
            PubSubEvent.GAME_LOADED,
            () => {
                piepie.log('[SCREEN HANDLER] game loaded')

                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GameLoaded',
                        variant: 'success',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'check',
                        message: 'GameLoaded',
                    })
                )
            },
            0
        )
        this.pausedSub = globalPubSub.sub(
            PubSubEvent.GAME_PAUSED,
            () => {
                piepie.log('[SCREEN HANDLER] game paused')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GamePaused',
                        variant: 'success',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'pause',
                        message: 'GamePaused',
                    })
                )
            },
            0
        )
        this.resumedSub = globalPubSub.sub(
            PubSubEvent.GAME_RESUMED,
            () => {
                piepie.log('[SCREEN HANDLER] game resumed')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GameResumed',
                        variant: 'success',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'play',
                        message: 'GameResumed',
                    })
                )
            },
            0
        )
        this.resetSub = globalPubSub.sub(
            PubSubEvent.GAME_RESET,
            () => {
                piepie.log('[SCREEN HANDLER] game reset')

                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GameReset',
                        variant: 'success',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'reset',
                        message: 'GameReset',
                    })
                )
            },
            0
        )
        this.gamepadConnectedSub = globalPubSub.sub(
            PubSubEvent.GAMEPAD_CONNECTED,
            () => {
                piepie.log('[SCREEN HANDLER] gamepad connected')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GamePadConnected',
                        variant: 'info',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'gamepadConnected',
                        message: 'GamePadConnected',
                    })
                )
            },
            0
        )
        this.gamepadDisconnectedSub = globalPubSub.sub(
            PubSubEvent.GAMEPAD_DISCONNECTED,
            () => {
                piepie.log('[SCREEN HANDLER] gamepad disconnected')
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message: 'GamePadDisconnected',
                        variant: 'info',
                    })
                )
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Info,
                        icon: 'gamepadDisconnected',
                        message: 'GamePadDisconnected',
                    })
                )
            },
            0
        )
        this.errorSub = globalPubSub.sub(
            PubSubEvent.NOTIFY_ERROR,
            (message: string) => {
                store.dispatch(
                    alertSlice.actions.setNotification({
                        message,
                        variant: 'success',
                    })
                )
            },
            0
        )
        this.webGLContextLostSub = globalPubSub.sub(
            PubSubEvent.WEBGL_CONTEXT_LOST,
            (/**message: string*/) => {
                piepie.log('[SCREEN HANDLER] webgl context lost')
                store.dispatch(screenSlice.actions.setWebGLContextLost(true))
            },
            0
        )
    }

    deinit = () => {
        piepie.log('[SCREEN HANDLER] deinit')
        this.gamepadConnectedSub.unsub()
        this.gamepadDisconnectedSub.unsub()
        this.loadedSub.unsub()
        this.pausedSub.unsub()
        this.resumedSub.unsub()
        this.resetSub.unsub()
        this.savedSub.unsub()
        this.saveFailedSub.unsub()
        this.errorSub.unsub()
    }
}
