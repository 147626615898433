export enum SignedInState {
    NotSignedIn,
    SignedIn,
    SignedInAnonymously,
}

export enum NavbarTheme {
    Transparent,
    Dark,
    None,
}

export interface AppLayoutPropsInterface {
    signedIn: SignedInState
    theme?: NavbarTheme
    children: any
    hideAlerts?: boolean
    displaySearchBar?: boolean
    displayEventTab?: boolean
    hideNavbar?: boolean
    backButton?: boolean
}
