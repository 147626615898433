import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import Unavailable from '../pages/Unavailable'

import { UnavailableReasonEnum } from '../state/unavailable'
import { ModalState } from '../store/modal/types'

export enum ModalType {
    None,
    Wait,
    Unavailable,
    Global,
}

export interface ModalPropsWithTypeInterface {
    modalType: ModalType
    open: boolean
    onClose?: (value?: string) => void
    extra?: any
}

export interface ModalProps {
    open: boolean
    onClose?: (value?: string) => void
    extra?: any
}

export function Modals(props: ModalPropsWithTypeInterface) {
    const { modalType, onClose, open, extra } = props
    switch (modalType) {
        case ModalType.Wait:
            return (
                <WaitModal
                    onClose={(e) => {
                        if (onClose) onClose(e)
                    }}
                    open={open}
                    extra={extra}
                />
            )
        case ModalType.Unavailable:
            return (
                <UnavailableModal
                    onClose={(e) => {
                        if (onClose) onClose(e)
                    }}
                    open={open}
                    extra={extra}
                />
            )
        default:
            return <></>
    }
}

function WaitModal(props: ModalProps) {
    const { t } = useTranslation()
    const { onClose, open } = props
    return (
        // @ts-ignore Type definitions for Modal are incomplete
        <Modal
            onHide={() => {
                if (onClose) onClose()
            }}
            show={open}
            centered
            enforceFocus={false}
        >
            <Modal.Title>{t('GameRoomsFull')}</Modal.Title>
            {t('FreeDaRoom')}
        </Modal>
    )
}

function UnavailableModal(props: ModalProps) {
    const { onClose, open, extra } = props
    const reason: UnavailableReasonEnum = extra
    return (
        // @ts-ignore Type definitions for Modal are incomplete
        <Modal
            onHide={() => {
                if (onClose) onClose()
            }}
            show={open}
            centered
            enforceFocus={false}
        >
            <Unavailable reason={reason} />
        </Modal>
    )
}

export const GlobalModal: React.FC<ModalState> = ({
    onClose,
    isOpen,
    message,
}) => {
    const { t } = useTranslation()

    if (!isOpen) {
        return null
    }

    return (
        <Modal
            onHide={() => {
                if (onClose) {
                    onClose()
                }
            }}
            show={isOpen}
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'center' }}>
                    {t(message)}
                </Modal.Title>
            </Modal.Header>
        </Modal>
    )
}
