import styles from './styles.module.scss'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Aside from './Nav/Aside'
import Header from './Nav/Header'
import GameList from './GameList'
import { useGamesList } from '../../utils/useGamesList'
import useSearchFilterSort from './useSearchFilterSort'
import { sortArrayItemsByOccurence } from '../../utils'
import {
    selectIsListModified,
    selectSelectedTags,
} from '../../store/home/homeV2'
import TryDesktopBanner from '../../components/TryDesktopBanner'

const Layout: FC = () => {
    const isListModified = useSelector(selectIsListModified)
    const selectedTags = useSelector(selectSelectedTags)
    const { gamesList: initialGamesList, isLoading } = useGamesList()
    const { gamesList } = useSearchFilterSort(initialGamesList)
    const gameTags = useMemo(
        () => sortArrayItemsByOccurence(gamesList.flatMap((g) => g.Tags)),
        [gamesList]
    )
    const filteredGametags = gameTags.length === 0 ? selectedTags : gameTags

    return (
        <>
            <TryDesktopBanner />
            <Header gameTags={filteredGametags} />
            <Aside />
            <main className={styles.layout}>
                {isListModified ? (
                    <GameList.UnCategorized gamesList={gamesList} />
                ) : (
                    !isLoading && <GameList.Categorized gamesList={gamesList} />
                )}
            </main>
        </>
    )
}

export default Layout
