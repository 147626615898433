import React, { FC, useEffect, useState } from 'react'
import style from './style.module.scss'
import cls from 'classnames'
import theme from '../../../styles/theme.scss'

interface RangeSliderProps {
    size?: 'sm' | 'md'
    value: number
    setValue: (e) => void
    minText?: string
    maxText?: string
    color?: string
    [rootHtmlAttributes: string]: any
}

const RangeSlider: FC<RangeSliderProps> = ({
    size = 'sm',
    value,
    setValue,
    minText,
    maxText,
    color = theme['color-white'],
    ...rootHtmlAttributes
}) => {
    const [initialBackground, setInitialBackground] = useState('')
    const handleChange = (e) => {
        const target = e.target
        const min = target.min
        const max = target.max
        const val = target.value
        const bgValue = ((val - min) * 100) / (max - min)

        target.style.background = `linear-gradient(to right, ${color} 0%, ${color} ${bgValue}%, rgba(255, 255, 255, 0.5) ${bgValue}%, rgba(255, 255, 255, 0.5) 100%)`
        setValue(e.target.value)
    }

    useEffect(() => {
        if (value || value === 0) {
            const min = 0
            const max = 100
            const val = value
            const bgValue = ((val - min) * 100) / (max - min)
            const background = `linear-gradient(to right, ${color} 0%, ${color} ${bgValue}%, rgba(255, 255, 255, 0.5) ${bgValue}%, rgba(255, 255, 255, 0.5) 100%)`

            setInitialBackground(background)
        }
    }, [value, color])

    return (
        <div {...rootHtmlAttributes}>
            <div className={style.text}>
                {minText && <p>{minText}</p>}
                {maxText && <p>{maxText}</p>}
            </div>

            <input
                type="range"
                min="1"
                max="100"
                value={value}
                onChange={handleChange}
                className={cls(style.rangeSlider, style[size])}
                style={{ background: initialBackground }}
            />
        </div>
    )
}

export default RangeSlider
