import React, { useCallback, useEffect, useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'

import * as Socket from '../../network/socket'
import ConfirmModal from '../ConfirmModal'
import { piepie, useKonHandler } from '../../utils'
import { patternFPS } from '../../utils/konami'
import { ConfirmDialog } from '../../state/confirm'
import Savestates from '../Savestates'
import { State } from '../../store/types'
import HighlightScreenMain from '../HighlightScreen'

import WASMVideo, { frames, setFrames } from './video'
import WASMAudio from './audio'
import { WASM_CORES_VERSION_MAP } from './WasmcoresVersion'
import { WASM_CORES_OPTIONS_MAP } from './WasmcoresOptions'
import { InnerScreenProps } from './screen-props'
import { clearSessionState } from '../../starfox/starfox'
import * as Input from '../../input/input'

import 'firebase/storage'
import ScreenMenu from './ScreenMenu'
import RecordHandler from '../../handlers/RecordHandler'
import SaveHandler from '../../handlers/SaveHandler'
import Confirmation, { ConfirmationType } from './Confirmation'
import { PieProgress } from '../ui'
import { AlertStack } from '../../stories'
import store, { actions } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AlertType } from '../../state/alert'
import { LocalStorage } from '../../state/storage'
import { isGame, showLoading } from '../../utils/loading'

import GameListScreenOverlay from './GameListScreenOverlay'
import alertSlice from '../../store/alert/alert'
import screenSlice from '../../store/screen/screen'
import { usePausePlay } from '../../utils/GameRoomHooks'
import useKeyboardPause from '../../utils/useKeyboardPause'
import retroSlice from '../../store/retro/retro'
import { GamePauseModal, GameSaveStatesModal } from './GameScreenModals'
import { GameroomModalSectionType } from '../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../store/gameroomModalSection/gameroomModalSection'
import { isMobile } from 'react-device-detect'
import { GameSettingsModal } from './GameScreenModals/GameSettingsModal'

declare global {
    interface Window {
        libretro: any
        Module: any
    }
}

function LocalScreen(props: InnerScreenProps) {
    const {
        gameScreenWidth,
        volume,
        leavingGame,
        resume,
        updateVolume,
        setInitialControlOverlay,
    } = props
    const dispatch = useDispatch()
    const alertList = useSelector((state: State) => state.alert.list)
    const progressInterval = useRef(null)
    const user = useSelector((state: State) => state.user.user)
    const game = useSelector((state: State) => state.session.game)
    const gameName = useSelector((state: State) => state.session.gameName)
    const isHost = useSelector((state: State) => state.session.isHost)
    const startGame = useSelector((state: State) => state.screen.startGame)
    const canvas = useRef(null)
    const canvasContainer = useRef(null)
    const componentIsMounted = useRef(true)
    const startAutoSave = useRef(false)
    const romDownloadProgress = useRef(0)
    const current = useRef(0)
    const initialControlOverlayTimer = useRef(null)
    const gameState = useSelector((state: State) => state.session.gameState)
    const retroIsPaused = useSelector((state: State) => state.retro.paused)

    const highlightStatus = useSelector(
        (state: State) => state.screen.highlightScreenStatus
    )
    const webGLContextLost = useSelector(
        (state: State) => state.screen.webGLContextLost
    ) as boolean
    const disableSavestates = useSelector(
        (state: State) => state.session.disableSavestates
    ) as boolean

    const isSavedState = useSelector(
        (state: State) => state.session.isSavedState
    )

    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const [retro, setRetro] = useState(null)

    const { t } = useTranslation()

    const [hasLoaded, setHasLoaded] = useState(false)
    const recordHandler = useRef<RecordHandler>(null)
    const saveHandler = useRef<SaveHandler>(null)
    const [loadingComplete, setLoadingComplete] = useState(0)
    const [loadingOver, setLoadingOver] = useState(false)
    const [ips, setIPS] = useState(0)
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )
    const [confirmNotif, setConfirmNotif] = useState<ConfirmationType>(
        ConfirmationType.None
    )

    const fps = useCallback(() => {
        setIPS(frames)
        setFrames(0)
        piepie.log('[LOCAL SCREEN] fps:', frames)
        setTimeout(fps, 1000)
    }, [])

    const { konHandler } = useKonHandler({
        pattern: patternFPS,
        current,
        callback: fps,
        isLocal: true,
    })

    useEffect(() => {
        componentIsMounted.current = true
        return () => {
            componentIsMounted.current = false
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', konHandler, false)
        return () => {
            document.removeEventListener('keydown', konHandler, false)
        }
    }, [konHandler])

    const { handleResumeGame } = usePausePlay()

    useKeyboardPause()

    useEffect(() => {
        piepie.log('[LOCAL SCREEN] ---\nEntering Local Mode\n---')

        window.Module = {
            locateFile: (path, prefix) => {
                if (path.includes('.data'))
                    prefix = 'https://assets.piepacker.com/wasmroms/'
                return prefix + path
            },
        }

        let wfactor = 1
        let hfactor = 1
        if (game.SplitScreen) {
            if (game.SplitScreen.startsWith('h2')) {
                wfactor = 2
            } else if (game.SplitScreen.startsWith('v2')) {
                hfactor = 2
            } else if (game.SplitScreen.startsWith('hv22')) {
                wfactor = 2
                hfactor = 2
            }
        }

        let overscanLeft = 0
        if (game.OverscanLeft) {
            overscanLeft = game.OverscanLeft
        }
        let overscanRight = 0
        if (game.OverscanRight) {
            overscanRight = game.OverscanRight
        }
        let overscanTop = 0
        if (game.OverscanTop) {
            overscanTop = game.OverscanTop
        }
        let overscanBottom = 0
        if (game.OverscanBottom) {
            overscanBottom = game.OverscanBottom
        }

        let blendFrame = 0
        if (game.BlendFrame) {
            blendFrame = game.BlendFrame
            if (blendFrame > 1) {
                piepie.error(
                    `[LOCAL SCREEN] game.BlendFrame is limited to 1 for NFS`
                )
            }
        }

        window.Module.video = new WASMVideo(
            canvasContainer.current,
            (canvasRef) => {
                canvas.current = canvasRef
                saveHandler.current?.setCanvas(canvasRef)
                recordHandler.current?.setStream(canvasRef.captureStream())
            },
            wfactor,
            hfactor,
            overscanLeft,
            overscanRight,
            overscanTop,
            overscanBottom,
            blendFrame
        )
        window.Module.audio = new WASMAudio(game.SndMono)
    }, [
        game.SplitScreen,
        game.SndMono,
        game.OverscanLeft,
        game.OverscanRight,
        game.OverscanTop,
        game.OverscanBottom,
        game.BlendFrame,
    ])

    // hook for the webGL context lost handling
    useEffect(() => {
        if (webGLContextLost) {
            setConfirmDialog(ConfirmDialog.Error)
        }
    }, [webGLContextLost])

    const handleResume = useCallback(() => {
        resume()
    }, [resume])

    const handleStartAutosave = useCallback(() => {
        startAutoSave.current = true
    }, [])

    useEffect(() => {
        recordHandler.current = new RecordHandler(
            canvas.current.captureStream(), // FIXME: Maybe this captureStream is using some resources. Should we capture the stream only when we start the recording?
            gameName
        )
        saveHandler.current = new SaveHandler(
            gameName,
            user.uid,
            canvas.current,
            setConfirmNotif,
            handleResume,
            handleStartAutosave
        )

        return () => {
            if (recordHandler.current) recordHandler.current.deinit()
            if (saveHandler.current) saveHandler.current.deinit()
        }
    }, [gameName, user.uid, handleStartAutosave, handleResume])

    useEffect(() => {
        // this hook is used for any code which has to be run at the start of the game.
        // it is triggered by a parent.
        if (loadingOver) {
            // This is used to ensure that when the mouse does not hover over the screen initially,
            // we still show settings
            initialControlOverlayTimer.current = setTimeout(() => {
                setInitialControlOverlay(false)
            }, 4000)

            if (progressInterval.current) {
                clearInterval(progressInterval.current)
            }
        }

        return () => {
            clearTimeout(initialControlOverlayTimer.current)
        }
    }, [loadingOver, setInitialControlOverlay])

    // we exit fullscreen if we open the modal
    useEffect(() => {
        if (confirmDialog !== ConfirmDialog.None) {
            store.dispatch(screenSlice.actions.setTheater(false))
            store.dispatch(screenSlice.actions.setFullscreen(false))
        }
    }, [confirmDialog])

    const onConfirmationValidate = useCallback(() => {
        switch (confirmNotif) {
            case ConfirmationType.Reload:
                if (isHost) {
                    piepie.log('[LOCAL SCREEN] loading autosave')
                    saveHandler.current.loadSaveState('auto')
                    dispatch(
                        gameroomModalSectionSlice.actions.setGameroomModal(
                            GameroomModalSectionType.NONE
                        )
                    )
                    handleResumeGame()
                }
                break
            default:
                // No action
                break
        }
    }, [confirmNotif, isHost, saveHandler, dispatch, handleResumeGame])

    const onConfirmationCancel = useCallback(() => {
        piepie.log('[LOCAL SCREEN] do not load autosave')
        startAutoSave.current = true
    }, [])

    const onConfirmationClose = useCallback(() => {
        setConfirmNotif(ConfirmationType.None)
        store.dispatch(screenSlice.actions.setOpenReload(false))
    }, [])

    const onConfirmModalValidate = useCallback(() => {
        switch (confirmDialog) {
            case ConfirmDialog.Quit:
                if (isHost) {
                    Socket.quitGame()
                    piepie.log('[LOCAL SCREEN] Host closed the room')
                }
                clearSessionState()
                leavingGame()
                break
            case ConfirmDialog.Error:
                window.location.reload()
                break
            default:
                // No action
                break
        }
        setConfirmDialog(ConfirmDialog.None)
    }, [confirmDialog, leavingGame, isHost])

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
    }, [])

    const gameDownloadProgress = useCallback(() => {
        progressInterval.current = setInterval(() => {
            const data_path = `https://assets.piepacker.com/wasmroms/${gameName}.data`
            // In the WASM.js file, we can access to the progression of the game and core downloads with Module.dataFileDownloads
            if (
                window.Module.dataFileDownloads &&
                window.Module.dataFileDownloads[data_path] &&
                window.Module.GetWasmDownloaded
            ) {
                const total =
                    window.Module.GetWasmSize() +
                    window.Module.dataFileDownloads[data_path].total
                const current =
                    window.Module.GetWasmDownloaded() +
                    window.Module.dataFileDownloads[data_path].loaded

                romDownloadProgress.current = (current / total) * 100
                piepie.log(
                    `[LOCAL SCREEN] Game download completed at: ${romDownloadProgress.current}%`
                )
                setLoadingComplete(
                    romDownloadProgress.current >= 100
                        ? 99
                        : romDownloadProgress.current
                )
            }

            if (romDownloadProgress.current === 100) {
                clearInterval(progressInterval.current)
            }
        }, 50)
    }, [gameName])

    useEffect(() => {
        if (
            romDownloadProgress.current === 100 ||
            !showLoading(gameState, highlightStatus)
        ) {
            if (progressInterval) {
                clearInterval(progressInterval.current)
            }
        }
    }, [gameState, highlightStatus])

    // run the game
    useEffect(() => {
        if (!retro) return

        piepie.log(`[LOCAL SCREEN] ROM: ${game.ROM}`)

        const emuOptions = WASM_CORES_OPTIONS_MAP.get(game.Core)
        if (emuOptions) {
            emuOptions.forEach((value, key) => retro.setOptions(key, value))
        }

        if (game.Options) {
            Object.entries(game.Options).forEach(([key, value]) =>
                retro.setOptions(key, value)
            )
        }

        retro.loadGame(game.ROM)
        Input.setLocalInput(retro)
        if (game.Core !== 'mesens_libretro') {
            // Default setting is joypad except for mesens which will
            // internally set multitap or joypad
            for (let p = 0; p < Input.MAX_PLAYER; p++) {
                retro.setControllerPortDevice(p, 1)
            }
        }
        // Then check firedb options
        if (game.ControllerInfo) {
            game.ControllerInfo.forEach((controller, port) => {
                if (port < retro.env_controller_info.length) {
                    const controller_id =
                        retro.env_controller_info[port].get(controller)
                    if (controller_id) {
                        piepie.log(
                            `[LOCAL SCREEN] Set controller ${controller}:${controller_id} on port ${port}`
                        )
                        retro.setControllerPortDevice(port, controller_id)
                    }
                }
            })
        }

        saveHandler.current.setRetro(retro)
        let sramInterval = null
        let autosaveInterval = null
        let canSaveState = false // used to prevent sending bad early savestates
        let shouldRun = true
        store.dispatch(screenSlice.actions.setOpenReload(false))
        saveHandler.current.loadSRAM().then(() => {
            if (!shouldRun) return
            piepie.log('[LOCAL SCREEN] starting the game')
            // Start playing
            // note we are not using gameState from the useSelector, because we do not want a change
            // in the game state to re-run this useEffect
            if (!isGame(store.getState().session.gameState).inSelectionScreen) {
                store.dispatch(actions.session.setGameStateReady())
            }
            setLoadingOver(true)
            retro.skip_frame(game.SkipFrames > 0 ? game.SkipFrames : 1)
            if (disableSavestates) {
                return
            }
            // autosave
            let autoSaveCheckCount = 0
            // We retry to look for an existing autosave, in case a previous game was still uploading the last one.
            const checkAutosave = () => {
                saveHandler.current.checkAutoSaveExists((exists: boolean) => {
                    if (!exists) {
                        startAutoSave.current = true
                        if (autoSaveCheckCount < 10) {
                            setTimeout(checkAutosave, 3000)
                        }
                        autoSaveCheckCount++
                    } else {
                        store.dispatch(screenSlice.actions.setOpenReload(true))
                    }
                })
            }
            checkAutosave()
            autosaveInterval = setInterval(() => {
                if (startAutoSave.current) {
                    saveHandler.current.sendAutoSaveState()
                }
                // We do an autosave every 30 seconds because some connections (especially bad ADSL connections) need time to upload large savestates.
                // A future improvement would be to make the upload frequency depend on the user's connection and the size of the savestates.
            }, 30000) // 30s
            sramInterval = setInterval(saveHandler.current.sendSRAM, 10000)
            canSaveState = true
            try {
                retro.loop(-1)
            } catch (e) {
                piepie.log('[LOCAL SCREEN] retro.loop exception:', e)
            }
        })

        return () => {
            shouldRun = false
            // we send an autosave on unmount for the transition
            // except if we're in the first frame, early savestates are often unusable
            if (canSaveState) saveHandler.current.sendAutoSaveState()
            clearInterval(sramInterval)
            clearInterval(autosaveInterval)
            // Stop the core
            retro.unloadGame()
            piepie.log('[LOCAL SCREEN] quitting local mode')
        }
    }, [retro, game, disableSavestates, saveHandler])

    useEffect(() => {
        if (!startGame || hasLoaded) return
        /* after switching from streaming to local, full game information might
        not be ready yet, however gameName is updated with an id received within
        `core_ready` message */
        if (gameName !== game.id) return
        setHasLoaded(true)

        const romScript = document.createElement('script')
        // this downloads the js script only, not the rom
        // rom is downloaded by await window.libretro(window.Module)

        romScript.src = `https://assets.piepacker.com/wasmroms/${game.id}.js`
        romScript.async = true
        romScript.onload = () => {
            if (!componentIsMounted.current) return

            const coreScript = document.createElement('script')
            let version = WASM_CORES_VERSION_MAP.get(`${game.Core}`)
            if (version === undefined) {
                piepie.warn(
                    `[LOCAL SCREEN] no valid version found for ${game.Core}`
                )
                version = 'latest'
            }
            coreScript.src = `https://assets.piepacker.com/wasmcores/${version}/${game.Core}.js`
            coreScript.async = true
            coreScript.onload = async () => {
                if (!componentIsMounted.current) return

                window.Module.audio.volume = localStorage.getItem(
                    LocalStorage.volume
                )
                    ? parseInt(localStorage.getItem(LocalStorage.volume)) / 100
                    : 30 / 100

                setRetro(await window.libretro(window.Module))
            }
            document.head.appendChild(coreScript)
        }

        gameDownloadProgress()
        document.head.appendChild(romScript)
    }, [
        startGame,
        hasLoaded,
        game,
        updateVolume,
        gameDownloadProgress,
        setRetro,
        gameName,
    ])

    useEffect(() => {
        // this effect is used for volume control
        if (isNaN(volume) && !isMobile) {
            updateVolume(0)
            if (window.Module && window.Module.audio)
                window.Module.audio.volume = 0
        } else {
            if (window.Module && window.Module.audio) {
                window.Module.audio.volume = volume / 100
            }
        }
    }, [volume, updateVolume])

    const handleSendSaveState = useCallback(() => {
        saveHandler.current.sendSaveState()
        dispatch(actions.session.setIsSavedState(false))
    }, [saveHandler, dispatch])

    useEffect(() => {
        if (isSavedState) {
            handleSendSaveState()
        }
    }, [isSavedState, handleSendSaveState])

    const handleLoadSaveState = useCallback(
        async (name: string) => {
            await saveHandler.current.loadSaveState(name)
            dispatch(retroSlice.actions.setPaused(false))
            store.dispatch(
                alertSlice.actions.push({
                    type: AlertType.Info,
                    icon: 'check',
                    message: 'GameLoaded',
                })
            )
        },
        [saveHandler, dispatch]
    )

    useEffect(() => {
        if (retroIsPaused) {
            retro?.setPaused(true)
        } else if (retroIsPaused === false) {
            retro?.setPaused(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retroIsPaused])

    const resetGame = () => {
        retro.reset()
        const skipFrames = game.SkipFrames > 0 ? game.SkipFrames : 1
        piepie.log('[LOCAL SCREEN] skip frame', skipFrames)
        retro.skip_frame(skipFrames)

        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Info,
                icon: 'reset',
                message: 'GameReset',
            })
        )
        if (isGame(gameState).paused) {
            handleResumeGame()
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
        }
    }

    const renderModals = () => {
        switch (gameroomModalSection) {
            case GameroomModalSectionType.PAUSE:
                return <GamePauseModal resetGame={resetGame} />
            case GameroomModalSectionType.LOAD:
                return (
                    <GameSaveStatesModal>
                        <Savestates
                            user={user}
                            gameName={game.id}
                            gameScreenWidth={gameScreenWidth}
                            idleState={props.idleState}
                            callback={handleLoadSaveState}
                        />
                    </GameSaveStatesModal>
                )

            case GameroomModalSectionType.SWITCH:
                return <GameListScreenOverlay />

            case GameroomModalSectionType.SETTINGS:
                return <GameSettingsModal />

            default:
                break
        }
    }

    // Top section with game screen
    return (
        <div>
            {/* tabIndex is needed for the focus and input listeners */}
            {!showLoading(gameState, highlightStatus) && loadingOver && (
                <div data-test="GameLoaded"></div>
            )}
            <div id="game-canvas-container" ref={canvasContainer}></div>
            {ips > 0 && <span id="game-ips">{ips}</span>}
            <HighlightScreenMain status={highlightStatus} />
            {(showLoading(gameState, highlightStatus) || !loadingOver) && (
                <div className="localGameLoading">
                    <div className="gameLoadingContainer">
                        <img
                            src={`${process.env.PUBLIC_URL}/piepie_loading.gif`}
                            alt=""
                            className="piepieLoading"
                        />
                        <PieProgress
                            bgColor={'primary'}
                            completed={loadingComplete}
                            display={true}
                            text={true}
                            className="localProgressBar"
                            message={t('PreparingGame')}
                        />
                    </div>
                </div>
            )}
            {isGame(gameState).idle && (
                <div
                    id="overlay"
                    className={`${
                        props.initialControlOverlay
                            ? 'initial-control-overlay'
                            : ''
                    } ${props.idleState ? 'idle' : ''}`}
                />
            )}

            {isHost && renderModals()}

            <AlertStack
                className="alertStack-position"
                alertList={alertList}
                onAlertDelete={(id: string) =>
                    dispatch(alertSlice.actions.delete(id))
                }
            />

            {!showLoading(gameState, highlightStatus) &&
                !isGame(gameState).inSelectionScreen &&
                !isMobile && <ScreenMenu setFullscreen={props.setFullscreen} />}
            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalValidate}
                onClose={onConfirmModalClose}
            />
            <Confirmation
                type={confirmNotif}
                onValidate={onConfirmationValidate}
                onClose={onConfirmationClose}
                onCancel={onConfirmationCancel}
            />
            <IdleTimer
                timeout={1000 * 4} // 4 seconds
                onActive={props.handleActive}
                onIdle={props.handleIdle}
                onAction={props.handleActive}
            />
        </div>
    )
}

export default LocalScreen
