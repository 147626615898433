import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Badges from './Badges'
import GoldenName from './GoldenName'
import FeaturedBadge from './FeaturedBadge'

const Appearance: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.section}>
            <p className={styles.header}>{t('Appearance')}</p>
            <div className={styles.itemsContainer}>
                <Badges />
                <FeaturedBadge />
                <GoldenName />
            </div>
        </div>
    )
}

export default Appearance
