import React, { useRef, useCallback } from 'react'
import * as Socket from '../../network/socket'
import { useChime } from '../../conference/hooks'
import { ChimeMessageTopics } from '../../conference/ChimeSdkWrapper'
import { useSelector, useDispatch } from 'react-redux'
import { ConfirmDialog } from '../../state/confirm'
import ConfirmModal from '../ConfirmModal'
import { State } from '../../store/types'
import { piepie } from '../../utils'
import { editParticipantMuted } from '../../store/conference/actions'
import { confirmModalReset } from '../../store/modal/actions'
import { useTranslation } from 'react-i18next'

const ConfirmModals = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const chime = useChime()
    const isHost = useSelector(
        (state: State) => state.session.isHost
    ) as boolean
    const containerRef = useRef()
    const confirmModal = useSelector((state: State) => state.modal.confirmModal)

    const onModalValidate = useCallback(() => {
        switch (confirmModal.type) {
            case ConfirmDialog.Ban:
                if (isHost) {
                    piepie.log(
                        '[BAN] ban request sent for player:',
                        confirmModal.UID
                    )
                    Socket.ban(
                        JSON.stringify({
                            UserID: confirmModal.UID,
                            UserSessionID: confirmModal.UserSessionId,
                        })
                    )
                }
                break
            case ConfirmDialog.Mute:
                if (confirmModal.id) {
                    piepie.log('MUTE: Muting participant: ', {
                        externalUserId: confirmModal.id,
                        isMuted: true,
                    })
                    chime.sendMessage(ChimeMessageTopics.MUTE, {
                        externalUserId: confirmModal.id,
                        isMuted: true,
                    })
                    dispatch(
                        editParticipantMuted({
                            externalUserId: confirmModal.id,
                            isMuted: true,
                        })
                    )
                }
                break
            default:
                // No action
                break
        }
        dispatch(confirmModalReset())
    }, [confirmModal, isHost, chime, dispatch])

    const onModalConfirm = useCallback(() => {
        switch (confirmModal.type) {
            case ConfirmDialog.Ban:
                if (isHost) {
                    piepie.log(
                        '[KICKOUT] kickout request sent for player:',
                        confirmModal.UserSessionId,
                        confirmModal
                    )
                    Socket.kickout(confirmModal.UserSessionId)
                }
                break
            default:
                // No action
                break
        }
        dispatch(confirmModalReset())
    }, [confirmModal, isHost, dispatch])

    const onConfirmModalClose = useCallback(() => {
        dispatch(confirmModalReset())
    }, [dispatch])

    return (
        <ConfirmModal
            container={containerRef.current}
            confirmDialog={confirmModal.type}
            open={confirmModal.type !== ConfirmDialog.None}
            onValidate={onModalValidate}
            onConfirm={onModalConfirm}
            onClose={onConfirmModalClose}
            username={confirmModal.nickname || t('ThisPlayer')}
        />
    )
}

export default ConfirmModals
