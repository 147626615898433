import styles from './styles.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TierCard } from '../../../components/ui'
import { getKickstarterBacker } from '../state/selectors'

const PlanDetails: React.FC = () => {
    const { t } = useTranslation()
    const kickstarterBacker = useSelector(getKickstarterBacker)

    return (
        <div className={styles.section}>
            <p className={styles.header}>{t('PlanDetails')}</p>
            <div className="tierWrapper">
                <TierCard
                    selected={!kickstarterBacker}
                    title={t('FreeTierTitle')}
                    descriptionList={t('FreeTierDescription', {
                        returnObjects: true,
                    })}
                />
                <TierCard
                    titleColor="green"
                    selected={!!kickstarterBacker}
                    title={t('KickstarterTierTitle')}
                    descriptionList={t('KickstarterTierDescription', {
                        returnObjects: true,
                    })}
                />
            </div>
        </div>
    )
}

export default PlanDetails
