import React, { FC, FormEvent, useState } from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import * as Socket from '../../network/socket'
import { piepie, ordinalSuffixOf, CurrentEventName } from '../../utils'
import { db } from '../../firebase'
import style from './style.module.scss'

import { useTranslation } from 'react-i18next'
import { Team } from './useGameAchievements'
import analytics from '../../analytics'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'

interface GameEndModalProps {
    show: boolean
    onHide: () => void
    team: Team
    newHighScore: boolean
    container: HTMLElement
}

const GameEndModal: FC<GameEndModalProps> = ({
    show = false,
    onHide,
    team,
    newHighScore,
    container,
}) => {
    const [newTeamName, setNewTeamName] = useState(null)
    const [teamNameError, setTeamNameError] = useState(null)
    const isHost = useSelector((state: State) => state.session.isHost)

    const { t } = useTranslation()

    const createTeam = async (e: FormEvent) => {
        e.preventDefault()
        setTeamNameError(null)

        if (newTeamName.length < 3 || newTeamName.length > 36) {
            setTeamNameError(t('ESLTeamNameLengthError'))
            return
        }

        const regex = /^[a-zA-Z0-9 ]*$/
        const isValid = regex.test(newTeamName)
        if (!isValid) {
            setTeamNameError(t('ESLTeamNameValidError'))
            return
        }

        const teamId = `${CurrentEventName}-${newTeamName}-${team?.GameMode}`
        const snapshot = await db.collection('teams').doc(teamId).get()
        if (snapshot.exists) {
            piepie.log('[ESL] createTeam, team already exists')
            setTeamNameError(t('ESLTeamNameInUse'))
            return
        }
        analytics.track('CreateTeam', {
            newTeamName: newTeamName,
        })

        try {
            const newTeam = {
                ...team,
                Name: newTeamName,
            }
            piepie.log('[ESL] createTeam, team: ', newTeam)
            const response = await Socket.saveScore(newTeam)
            piepie.log('[ESL] createTeam response', response)
        } catch (error) {
            piepie.log('[ESL] createTeam, failed: ', error)
        } finally {
            onHide()
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            container={container}
            centered
            backdrop="static"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title bsPrefix={style.title}>
                    {t('ESLGameOver')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix={style.body}>
                <div className={style.imageContainer}>
                    <img
                        className={style.image}
                        src={`https://fassets.piepacker.com/esl-assets/${
                            newHighScore ? 'newhighscore' : 'tryagain'
                        }.png`}
                        alt=""
                    />
                    <span className={style.endresult}>
                        {newHighScore ? t('ESLNewHighScore') : t('ESLTryAgain')}
                    </span>
                </div>

                <table className={style.table}>
                    <tr>
                        <th>{t('ESLScore')}</th>
                        <th>{t('ESLRank')}</th>
                    </tr>
                    <tr>
                        <td>{team?.Score}</td>
                        <td>
                            {t(ordinalSuffixOf(team?.Ranking), {
                                rank: team?.Ranking,
                            })}
                        </td>
                    </tr>
                </table>
            </Modal.Body>

            {team?.Name && (
                <div>
                    <div>{t('ESLTeamName')}</div>
                    <h3 className={style.teamName}>{team?.Name}</h3>

                    <Button block onClick={onHide}>
                        {t('ESLKeepPlaying')}
                    </Button>
                </div>
            )}

            {!isHost && !team?.Name && (
                <Button block type="submit" onClick={onHide}>
                    {t('ESLKeepPlaying')}
                </Button>
            )}

            {isHost && !team?.Name && (
                <form onSubmit={createTeam}>
                    <Form.Group>
                        <Form.Label>{t('ESLTeamName')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('ESLEnterTeamName')}
                            onChange={(e) => setNewTeamName(e.target.value)}
                        />
                    </Form.Group>

                    {teamNameError && (
                        <Alert variant="danger">{teamNameError}</Alert>
                    )}

                    <Button
                        block
                        type="submit"
                        disabled={!newTeamName}
                        onClick={createTeam}
                    >
                        {newHighScore ? t('ESLSaveScore') : t('ESLKeepPlaying')}
                    </Button>
                </form>
            )}
        </Modal>
    )
}

export default GameEndModal
