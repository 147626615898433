import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'
import { InputText } from '../components/Form/fields'
import { getTransactionEmailServiceHostname, piepie } from '../utils'
import * as Axios from 'axios'
import { logAxiosErrorResponse } from '../utils/http'

interface IEditProfile {
    onSuccess: () => void
    initialValues: Record<string, string>
}

const EditProfile: React.FC<IEditProfile> = ({ onSuccess, initialValues }) => {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const { t } = useTranslation()

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('EditProfile')}</Modal.Title>
            </Modal.Header>

            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}

            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    displayName: Yup.string().required('Required'),
                })}
                onSubmit={async (values: any) => {
                    try {
                        const user = firebase.auth().currentUser
                        try {
                            await Axios.default.post(
                                `${getTransactionEmailServiceHostname()}/bodyguard`,
                                {
                                    email: user.email,
                                    uid: user.uid,
                                    text: values.displayName,
                                }
                            )
                        } catch (error) {
                            logAxiosErrorResponse(error)
                            setSubmitError({
                                error: true,
                                message: error.response.data,
                            })
                            return
                        }
                        await user.updateProfile({
                            displayName: values.displayName,
                        })

                        onSuccess()
                    } catch (err) {
                        piepie.error(
                            '[ACCOUNT] profile edititon failed:',
                            err.message
                        )
                        setSubmitError({ error: true, message: err.message })
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Formk>
                        <Form.Group>
                            <label>{t('Nickname')}</label>
                            <Field
                                placeholder="Nickname"
                                name="displayName"
                                component={InputText}
                                maxLength="16"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                className="btn-large"
                                disabled={isSubmitting}
                                type="submit"
                                block
                            >
                                {t('Save')}
                            </Button>
                        </Form.Group>
                    </Formk>
                )}
            </Formik>
        </>
    )
}

export default EditProfile
