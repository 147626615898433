import React, {
    FunctionComponent,
    useState,
    useCallback,
    useEffect,
    useRef,
} from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import style from './style.module.scss'
import cls from 'classnames'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'

interface PieSliderProps {
    itemsList: any
    sliderItem?: (
        data: any,
        index?: number,
        selectedIndex?: number,
        availableForAction?: boolean
    ) => void
    containerHeight: string
    containerPadding: string
    windowWidthReference?: number
    arrowsMarginTop?: number
    selected?: boolean
    sliderItemWidth?: number
    sliderMarginRight?: number
    transition?: string
    keyboardNavigation?: boolean
    disableWhenModalOpened?: boolean
    onSelect?: (item: any) => void
    setCurrentMousePressedTime?: (date: Date) => void
}

const PieSlider: FunctionComponent<PieSliderProps> = ({
    children,
    itemsList,
    sliderItem,
    containerHeight,
    containerPadding,
    windowWidthReference = window.innerWidth,
    arrowsMarginTop = 0,
    selected = true,
    sliderItemWidth = 0,
    sliderMarginRight = 0,
    keyboardNavigation = true,
    disableWhenModalOpened = false,
    transition = '1.2s',
    onSelect = () => {
        return
    },
    setCurrentMousePressedTime = () => {
        return
    },
}) => {
    const [numberOfItemToSlide, setNumberOfItemToSlide] = useState<number>(0)
    const [itemIndex, setItemIndex] = useState<number>(null)
    const slider = useRef(null)
    const isGameModalOpened = useSelector(
        (state: State) => state.home.isGameModalOpened
    )

    const itemPerSlide = Math.floor(windowWidthReference / sliderItemWidth) || 1
    const slideMarginError =
        windowWidthReference -
        itemPerSlide * sliderItemWidth -
        sliderMarginRight
    const reachedEndOfSlider =
        itemsList.length <= itemPerSlide ||
        numberOfItemToSlide === itemsList.length - itemPerSlide
    const leftShift =
        numberOfItemToSlide * -sliderItemWidth +
        (numberOfItemToSlide > 0 &&
        numberOfItemToSlide === itemsList.length - itemPerSlide
            ? slideMarginError
            : 0)

    const handleHorizontalSwiperScroll = useCallback(
        (sign: number) => {
            let refIndex = numberOfItemToSlide + sign * itemPerSlide

            if (refIndex > itemsList.length - itemPerSlide) {
                refIndex = itemsList.length - itemPerSlide
            }

            if (refIndex < 0) {
                refIndex = 0
            }

            onSelect(itemsList[refIndex])

            setNumberOfItemToSlide(refIndex)
        },
        [itemPerSlide, numberOfItemToSlide, itemsList, onSelect]
    )

    const handleArrowNavigation = useCallback(
        (sign: number) => {
            if (
                !keyboardNavigation ||
                (disableWhenModalOpened && isGameModalOpened)
            )
                return
            let refIndex = itemIndex

            if (refIndex === null) {
                refIndex = 0
            } else if (
                refIndex + sign >= 0 &&
                refIndex + sign <= itemsList.length - 1
            ) {
                refIndex += sign
            }

            if (refIndex - numberOfItemToSlide + 1 > itemPerSlide) {
                handleHorizontalSwiperScroll(1)
            } else if (refIndex - numberOfItemToSlide < 0) {
                handleHorizontalSwiperScroll(-1)
            }

            onSelect(itemsList[refIndex])

            setItemIndex(refIndex)
        },
        [
            keyboardNavigation,
            disableWhenModalOpened,
            isGameModalOpened,
            itemsList,
            itemIndex,
            numberOfItemToSlide,
            itemPerSlide,
            onSelect,
            handleHorizontalSwiperScroll,
        ]
    )

    useEffect(() => {
        if (!selected) {
            return
        }

        const keyDownHandler = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowRight':
                    handleArrowNavigation(1)
                    break
                case 'ArrowLeft':
                    handleArrowNavigation(-1)
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', keyDownHandler)

        return () => {
            window.removeEventListener('keydown', keyDownHandler)
        }
    }, [selected, handleArrowNavigation])

    useEffect(() => {
        let posX1 = 0
        let posX2 = 0
        let posInitial = 0
        const threshold = 50
        const sliderToDrag = slider.current as HTMLElement

        if (!selected || !sliderToDrag) {
            return
        }

        sliderToDrag.addEventListener('pointerdown', dragStart)

        function dragStart(e) {
            e.preventDefault()
            setCurrentMousePressedTime(new Date())
            posInitial = leftShift
            posX1 = e.touches ? e.touches[0].clientX : e.clientX
            sliderToDrag.addEventListener('pointermove', dragAction)
            // we need to detect event on the whole document because otherwise it won't detect the mouseup if the user drags outside the slider div
            document.addEventListener('pointerup', dragEnd)
        }

        function dragAction(e) {
            if (e.touches) {
                posX2 = posX1 - e.touches[0].clientX
                posX1 = e.touches[0].clientX
            } else {
                posX2 = posX1 - e.clientX
                posX1 = e.clientX
            }
            sliderToDrag.style.left =
                sliderToDrag.offsetLeft - posX2 * 150 + 'px'
        }

        function dragEnd() {
            if (
                numberOfItemToSlide < itemsList.length - itemPerSlide &&
                sliderToDrag.offsetLeft - posInitial < -threshold
            ) {
                handleHorizontalSwiperScroll(1)
            } else if (
                numberOfItemToSlide > 0 &&
                sliderToDrag.offsetLeft - posInitial > threshold
            ) {
                handleHorizontalSwiperScroll(-1)
            } else {
                sliderToDrag.style.left = posInitial + 'px'
            }

            sliderToDrag.removeEventListener('pointermove', dragAction)
            sliderToDrag.removeEventListener('pointerup', dragEnd)
        }

        return () => {
            sliderToDrag.removeEventListener('pointerdown', dragStart)
        }
    }, [
        selected,
        leftShift,
        numberOfItemToSlide,
        itemPerSlide,
        itemsList,
        handleHorizontalSwiperScroll,
        setCurrentMousePressedTime,
    ])

    useEffect(() => {
        if (selected) {
            onSelect(
                itemsList[itemIndex !== null ? itemIndex : numberOfItemToSlide]
            )
        }
    }, [selected, itemsList, itemIndex, numberOfItemToSlide, onSelect])

    useEffect(() => {
        if (
            numberOfItemToSlide > 0 &&
            numberOfItemToSlide > itemsList.length - itemPerSlide
        ) {
            setNumberOfItemToSlide(itemsList.length - itemPerSlide)
        }
    }, [itemsList, itemPerSlide, numberOfItemToSlide])

    return (
        <div
            data-test="Slider"
            data-test-selected={selected && 'SliderSelected'}
            className={style.listItem}
            style={{ height: containerHeight }}
        >
            <div
                ref={slider}
                className={style.listItemContainer}
                style={{
                    left: leftShift,
                    padding: containerPadding,
                    transition: `left ${transition}`,
                }}
            >
                {!React.isValidElement(children) && sliderItem
                    ? itemsList.map((item: any, index: number) => {
                          return sliderItem(item, index, itemIndex, selected)
                      })
                    : children}
            </div>
            <div
                data-test="SliderNextArrow"
                onClick={() => handleHorizontalSwiperScroll(1)}
                className={cls(
                    style.buttonNext,
                    reachedEndOfSlider && style.invisible
                )}
                style={{ marginTop: arrowsMarginTop }}
            >
                <ArrowForwardIosIcon />
            </div>
            <div
                data-test="SliderPreviousArrow"
                onClick={() => handleHorizontalSwiperScroll(-1)}
                className={cls(
                    style.buttonPrev,
                    numberOfItemToSlide === 0 && style.invisible
                )}
                style={{ marginTop: arrowsMarginTop }}
            >
                <ArrowBackIosIcon />
            </div>
        </div>
    )
}

export default PieSlider
