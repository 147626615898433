export enum LocalStorage {
    // Firebase authorization ID token.
    idToken = 'idToken',
    // Game session ID.
    sessionID = 'sessionID',
    // i18next currently chosen language.
    i18nextLng = 'i18nextLng',
    // A hack to detect Chime session expiration time.
    chimeSessionCreatedTime = 'chimeSessionCreatedTime',
    // Chosen audio input device.
    AudioInputDevice = 'AudioInputDevice',
    // Chosen audio output device.
    AudioOutputDevice = 'AudioOutputDevice',
    // Chosen video input device.
    VideoInputDevice = 'VideoInputDevice',
    // Game audio control value.
    volume = 'volume',
    // Boolean for detecting maintenance mode.
    maintenanceMode = 'maintenanceMode',
    // Boolean for detecting a host.
    isHost = 'isHost',
    // Timestamp when alpha mobile banner warning is closed
    closedAlphaMobileBanner = 'closedAlphaMobileBanner',
}

export enum CookieStorage {
    // Used for redirecting guest users to the intended game URL after authentication.
    intendedUrl = 'intendedUrl',
    // Used for Event competition Popup
    HomepageCompetitionModalDisabled = 'HomepageCompetitionModalDisabled',
}

export enum SessionStorage {
    // User session ID.
    userSessionID = 'userSessionID',
}
