import style from './style.module.scss'

import React, { useRef, useState, useCallback } from 'react'
import { saveChatMessage } from '../../../firebase'
import { useTranslation } from 'react-i18next'

import {
    sleep,
    isEnvDev,
    isEnvStaging,
    getTransactionEmailServiceHostname,
} from '../../../utils'
import * as Socket from '../../../network/socket'
import * as Axios from 'axios'

interface FacesChatProps {
    sessionID: string
    userSessionID: string
}

export default function FacesChatInput({
    sessionID,
    userSessionID,
}: FacesChatProps) {
    const { t } = useTranslation()
    const [chatMessage, setChatMessage] = useState('')
    const sending = useRef(false)

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLElement>) => {
        if (!chatMessage) {
            return
        }

        if (event.key === 'Enter') {
            // Shortcut to test the cheat feature until the extension is done
            // Send cheat-related message that start with '!' to the websocket
            if ((isEnvDev || isEnvStaging) && chatMessage[0] === '!') {
                const cmd = chatMessage.substring(1)
                if (cmd === 'list') {
                    Socket.send('do_cloud_transition')
                    Socket.send('list_effects')
                } else {
                    const data = {
                        id: cmd,
                        index: 1, // use 1 for basic testing
                    }
                    Socket.send('attempt_effect', JSON.stringify(data))
                }
            }
            sendMessage()
        }
    }

    // we send the message
    const sendMessage = useCallback(async () => {
        if (chatMessage && !sending.current) {
            sending.current = true
            try {
                await Axios.default.post(
                    `${getTransactionEmailServiceHostname()}/bodyguardChat`,
                    {
                        text: chatMessage,
                    }
                )
                saveChatMessage(chatMessage, sessionID, userSessionID)
            } catch (error) {
                saveChatMessage(
                    chatMessage.replace(/./gm, '*'),
                    sessionID,
                    userSessionID
                )
            }
            setChatMessage('')
            // we wait 600ms before being able to send another message, it prevents spamming
            await sleep(600)
            sending.current = false
        }
    }, [chatMessage, sessionID, userSessionID])

    return (
        <div className={style.facesChatInput}>
            <input
                className={style.chatInput}
                onChange={(e) => {
                    setChatMessage(e.target.value)
                }}
                value={chatMessage}
                type="text"
                autoComplete="off"
                onKeyDown={handleKeyDown}
                placeholder={t('WriteMessage')}
            />
        </div>
    )
}
