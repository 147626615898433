import style from '../styles.module.scss'
import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Google from '../AuthGoogle'
import Facebook from '../AuthFacebook'
import { useAlert } from '../useAlert'
import { Button } from '../../../stories'
import PrivacyCheck from '../PrivacyCheck'
import { usePrivacyCheck } from '../usePrivacyCheck'
import AlternativeSignIn from '../AlternativeSignIn'
import { AuthModalType } from '../../../store/auth/types'
import { setCurrentView } from '../../../store/auth/authV2'
import {
    handleFacebookSignUp,
    handleGoogleSignUp,
} from '../../../store/auth/services'

const SignUpMenu = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    useAlert()
    const { privacyError, setPrivacyError, checkPrivacy, setCheckPrivacy } =
        usePrivacyCheck()

    const handleValidation = () => {
        if (!checkPrivacy) {
            setPrivacyError(true)
            return false
        }
        return true
    }

    const handleEmailSignUp = () =>
        dispatch(setCurrentView(AuthModalType.SignUp))

    return (
        <div>
            <div className={style.signOptions}>
                <Facebook
                    label={t('SignUpFacebook')}
                    onClick={() => {
                        handleValidation() && handleFacebookSignUp()
                    }}
                />
                <Google
                    label={t('SignUpGoogle')}
                    onClick={() => {
                        handleValidation() && handleGoogleSignUp()
                    }}
                />
                <Button
                    className={cls(style.btn, style.submitButton)}
                    onClick={handleEmailSignUp}
                >
                    {t('SignUpWithMail')}
                </Button>
            </div>
            <PrivacyCheck
                privacyError={privacyError}
                hasAgreedToTerms={checkPrivacy}
                setPrivacyError={setPrivacyError}
                setHasAgreedToTerms={setCheckPrivacy}
            />
            <div className={style.altFooterWrapper}>
                <AlternativeSignIn />
            </div>
        </div>
    )
}

export default SignUpMenu
