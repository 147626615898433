import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import {
    BadgeInterface,
    UserExtraFromFirebase,
    NicknameColorType,
    FeaturesInterface,
} from '../../state/starfox'

export interface UserState {
    user: firebase.User | null
    extra: UserExtraFromFirebase | null
    features: FeaturesInterface
}

// Group all action types under a single prefix for easier reading
export enum UserActionType {
    setUser = 'USER:SET_USER',
    setUserExtra = 'USER:SET_USEREXTRA',
    setCurrentBadge = 'USER:SET_CURRENTBADGE',
    setNicknameColor = 'USER:SET_NICKNAMECOLOR',
    setFeatures = 'USER:SET_FEATURES',
}

// Actions to be dispatched
export interface SetUserAction {
    type: UserActionType.setUser
    payload: firebase.User
}

export interface SetUserExtraAction {
    type: UserActionType.setUserExtra
    payload: UserExtraFromFirebase
}

export interface SetCurrentBadgeAction {
    type: UserActionType.setCurrentBadge
    payload: BadgeInterface
}

export interface SetNicknameColorAction {
    type: UserActionType.setNicknameColor
    payload: NicknameColorType
}

export interface SetFeaturesAction {
    type: UserActionType.setFeatures
    payload: FeaturesInterface
}

export type UserAction =
    | SetUserAction
    | SetUserExtraAction
    | SetCurrentBadgeAction
    | SetNicknameColorAction
    | SetFeaturesAction
