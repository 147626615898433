import {
    OPEN_MODAL,
    CLOSE_MODAL,
    CONFIRM_MODAL_BAN,
    CONFIRM_MODAL_MUTE,
    ModalActionTypes,
    OpenModalProps,
    CONFIRM_MODAL_RESET,
} from './types'

export function openModal(modal: OpenModalProps): ModalActionTypes {
    return {
        type: OPEN_MODAL,
        payload: modal,
    }
}

export function closeModal(): ModalActionTypes {
    return {
        type: CLOSE_MODAL,
    }
}

export function confirmModalBan(payload): ModalActionTypes {
    return {
        type: CONFIRM_MODAL_BAN,
        payload,
    }
}

export function confirmModalMute(payload): ModalActionTypes {
    return {
        type: CONFIRM_MODAL_MUTE,
        payload,
    }
}

export function confirmModalReset(): ModalActionTypes {
    return {
        type: CONFIRM_MODAL_RESET,
    }
}
