import React, { useState, useEffect, FC } from 'react'
import { Icon } from '../Icon'
import { RangeSlider } from '../RangeSlider'

import style from './style.module.scss'

interface VolumeSliderProps {
    withToggle?: boolean
    volume: number
    setVolume: (volume: number) => void
    [rootHtmlAttributes: string]: any
}

const VolumeSlider: FC<VolumeSliderProps> = ({
    withToggle = false,
    volume,
    setVolume,
}) => {
    const [showVolume, setShowVolume] = useState<boolean>(false)
    const [muted, setMuted] = useState<boolean>(false)
    const [storedVolume, setStoredVolume] = useState<number>(0)

    useEffect(() => {
        if (muted) {
            setVolume(0)
            volume > 1 && setStoredVolume(volume)
        } else if (
            !muted &&
            storedVolume > 1 &&
            (volume === 0 || volume === 1)
        ) {
            setVolume(storedVolume ? storedVolume : 30)
        }
    }, [muted, setStoredVolume, setVolume, volume, storedVolume])

    useEffect(() => {
        if (volume > 1) {
            setMuted(false)
        }
    }, [volume])

    return (
        <div className={style.volume} onMouseLeave={() => setShowVolume(false)}>
            <button
                className={style.volumeButton}
                type="button"
                onClick={() => setMuted((state) => !state)}
                onMouseEnter={() => setShowVolume(true)}
            >
                {muted && volume === 0 ? (
                    <Icon name="volumeOff" width="1.5rem" height="1.5rem" />
                ) : volume > 50 ? (
                    <Icon name="volumeUp" width="1.5rem" height="1.5rem" />
                ) : (
                    <Icon name="volumeDown" width="1.5rem" height="1.5rem" />
                )}
            </button>
            {withToggle ? (
                showVolume && (
                    <div className={style.volumeSlider}>
                        <RangeSlider
                            color="#fff"
                            size="md"
                            value={volume}
                            storedValue={storedVolume}
                            setValue={setVolume}
                            style={{ width: '100%' }}
                        />
                    </div>
                )
            ) : (
                <div className={style.volumeSlider}>
                    <RangeSlider
                        color="#fff"
                        size="md"
                        storedValue={storedVolume}
                        value={volume}
                        setValue={setVolume}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
        </div>
    )
}

export default VolumeSlider
