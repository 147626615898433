import * as types from './types'

export function clearSession(): types.ClearSessionAction {
    return {
        type: types.SessionActionType.clearSession,
    }
}

export function setGameName(
    payload: types.SetGameNameAction['payload']
): types.SetGameNameAction {
    return {
        type: types.SessionActionType.setGameName,
        payload,
    }
}

export function setOriginalFileName(
    payload: types.SetOriginalFileNameAction['payload']
): types.SetOriginalFileNameAction {
    return {
        type: types.SessionActionType.SetOriginalFileName,
        payload,
    }
}

export function setCluster(
    payload: types.SetClusterAction['payload']
): types.SetClusterAction {
    return {
        type: types.SessionActionType.setCluster,
        payload,
    }
}

export function setSessionId(
    payload: types.SetSessionIdAction['payload']
): types.SetSessionIdAction {
    return {
        type: types.SessionActionType.setSessionId,
        payload,
    }
}

export function setUserSessionId(
    payload: types.SetUserSessionIdAction['payload']
): types.SetUserSessionIdAction {
    return {
        type: types.SessionActionType.setUserSessionId,
        payload,
    }
}

export function setConferenceUrl(
    payload: types.SetConferenceUrlAction['payload']
): types.SetConferenceUrlAction {
    return {
        type: types.SessionActionType.setConferenceUrl,
        payload,
    }
}

export function setSession(
    payload: types.SetSessionAction['payload']
): types.SetSessionAction {
    return {
        type: types.SessionActionType.setSession,
        payload,
    }
}

export function setUserSession(
    payload: types.SetUserSessionAction['payload']
): types.SetUserSessionAction {
    return {
        type: types.SessionActionType.setUserSession,
        payload,
    }
}

export function setIsHost(
    payload: types.SetIsHostAction['payload']
): types.SetIsHostAction {
    return {
        type: types.SessionActionType.setIsHost,
        payload,
    }
}

export function setPlayerIndices(
    payload: types.SetPlayerIndicesAction['payload']
): types.SetPlayerIndicesAction {
    return {
        type: types.SessionActionType.setPlayerIndices,
        payload,
    }
}

export function setDevices(
    payload: types.SetDevices['payload']
): types.SetDevices {
    return {
        type: types.SessionActionType.SetDevices,
        payload,
    }
}

export function setLocalPlayers(
    payload: types.SetLocalPlayers['payload']
): types.SetLocalPlayers {
    return {
        type: types.SessionActionType.SetLocalPlayers,
        payload,
    }
}

export function setNetworkQuality(
    payload: types.SetNetworkQuality['payload']
): types.SetNetworkQuality {
    return {
        type: types.SessionActionType.SetNetworkQuality,
        payload,
    }
}

export function setBYOG(payload: types.SetBYOG['payload']): types.SetBYOG {
    return {
        type: types.SessionActionType.SetBYOG,
        payload,
    }
}

export function setGameCore(
    payload: types.SetGameCore['payload']
): types.SetGameCore {
    return {
        type: types.SessionActionType.SetGameCore,
        payload,
    }
}

export function setGameFile(
    payload: types.SetGameFile['payload']
): types.SetGameFile {
    return {
        type: types.SessionActionType.SetGameFile,
        payload,
    }
}

export function setPlayer(
    uid: types.SetPlayer['uid'],
    payload: types.SetPlayer['payload']
): types.SetPlayer {
    return {
        type: types.SessionActionType.setPlayer,
        uid,
        payload,
    }
}

export function removePlayer(uid: types.SetPlayer['uid']): types.RemovePlayer {
    return {
        type: types.SessionActionType.removePlayer,
        uid,
    }
}

export function setIsESL(payload: types.SetIsESL['payload']): types.SetIsESL {
    return {
        type: types.SessionActionType.setIsESL,
        payload,
    }
}

export function setGame(
    payload: types.SetGameAction['payload']
): types.SetGameAction {
    return {
        type: types.SessionActionType.setGame,
        payload,
    }
}

export function setDisableSavestates(
    payload: types.SetDisableSavestates['payload']
): types.SetDisableSavestates {
    return {
        type: types.SessionActionType.setDisableSavestates,
        payload,
    }
}

export function setGameStateSelecting(): types.SetGameStateSelecting {
    return {
        type: types.SessionActionType.setGameStateSelecting,
    }
}
export function setGameStatePaused(): types.setGameStatePaused {
    return {
        type: types.SessionActionType.setGameStatePaused,
    }
}
export function setGameStateLoading(): types.SetGameStateLoading {
    return {
        type: types.SessionActionType.setGameStateLoading,
    }
}
export function setGameStateReady(): types.SetGameStateReady {
    return {
        type: types.SessionActionType.setGameStateReady,
    }
}

export function setSessionActive(
    payload: types.SetSessionActiveAction['payload']
): types.SetSessionActiveAction {
    return {
        type: types.SessionActionType.setSessionActive,
        payload,
    }
}
export function setSaveStates(
    payload: types.SetSaveStatesAction['payload']
): types.SetSaveStatesAction {
    return {
        type: types.SessionActionType.setSaveStates,
        payload,
    }
}

export function setIsSavedState(
    payload: types.SetIsSavedStateAction['payload']
): types.SetIsSavedStateAction {
    return {
        type: types.SessionActionType.setIsSavedState,
        payload,
    }
}
export function setGameLobbyError(
    payload: types.SetLobbyError['payload']
): types.SetLobbyError {
    return {
        type: types.SessionActionType.setLobbyError,
        payload,
    }
}
