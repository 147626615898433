// GameStatus indicates the state of the player, which can only be
// in a single state at once.
//
// Below are described valid transitions:
//  * left element is the prior state
//  * middle element is the action
//  * right element is the next state
//  * initial state is `NotPlaying`
//
// NotPlaying -> Loading
// Loading -> connection successful -> Playing
// Loading -> cannot connect -> Error
// Playing -> quit -> NotPlaying
// Playing -> error during play -> Error
// Error -> dismiss error -> NotPlaying
// NOTE: keep as reference to previous state machine
// enum GameStatus {
//     NotPlaying,
//     Loading,
//     Playing,
//     Paused,
//     Error,
// }

// HighlightScreenStatus indicates what state the highligthing mode of the screen
// is on.
export enum HighlightScreenStatus {
    NotHighlighting,
    Disconnected,
    Failed,
}

// SessionType indicates if the session is solo or mutliplayers
export enum SessionType {
    LOCAL,
    STREAM,
}
