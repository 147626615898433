import style from './style.module.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { User } from '../../../state/faces'
import store from '../../../store'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import cls from 'classnames'
import { State } from '../../../store/types'
import { NicknameColorType } from '../../../state/starfox'
import Badges, { BadgeSize } from '../../Badges'

interface FacesChatProps {
    messageGroups: any
    allUsers: { [index: string]: User }
}

export default function FacesChat({ messageGroups, allUsers }: FacesChatProps) {
    const { t } = useTranslation()
    const [nicknameMap, setNicknameMap] = useState({})
    const messagesScroll = useRef(null)
    const playerExtraData = useSelector((state: State) => state.session.players)

    useEffect(() => {
        setNicknameMap(getMapToNicknames(allUsers))
    }, [allUsers, setNicknameMap])

    useEffect(() => {
        if (messagesScroll.current && messageGroups.length > 0) {
            const el = messagesScroll.current as HTMLElement
            el.scrollTop = el.scrollHeight
        }
    }, [messageGroups, messagesScroll])

    const getChatAuthorNickname = (authorSessionId: string): string => {
        let nickname = nicknameMap[authorSessionId]
        if (!nickname) {
            if (authorSessionId === store.getState().session.userSessionID) {
                nickname = t('You')
            } else {
                nickname = t('Guest')
            }
        }

        return nickname
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    )

    return (
        <div className={style.facesChat} ref={messagesScroll}>
            <div className={style.messages}>
                {messageGroups.map((group, i) => {
                    const UID = allUsers[group.UserSessionID]?.UID
                    const hasGoldLetters =
                        playerExtraData[UID]?.NicknameColor ===
                        NicknameColorType.Gold

                    return (
                        <div
                            key={i}
                            className={style.group}
                            data-style-fromself={group.fromself}
                        >
                            <div
                                className={cls(
                                    style.author,
                                    hasGoldLetters && 'goldLetters'
                                )}
                            >
                                {playerExtraData[UID]?.CurrentBadge && (
                                    <Badges
                                        size={BadgeSize.ExtraSmall}
                                        badgeList={[
                                            playerExtraData[UID]?.CurrentBadge,
                                        ]}
                                    />
                                )}
                                {getChatAuthorNickname(group.UserSessionID)}
                            </div>
                            <div className={style.messageWrapper}>
                                {group.messages.map((message, j) => (
                                    <div className={style.message} key={j}>
                                        <Linkify
                                            componentDecorator={
                                                componentDecorator
                                            }
                                        >
                                            {message.Text}
                                        </Linkify>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function getMapToNicknames(users: { [index: string]: User }): {
    [index: string]: string
} {
    const nicknameMap: { [index: string]: string } = {}
    for (const user of Object.values(users)) {
        nicknameMap[user.UserSessionID] = user.nickname
    }
    return nicknameMap
}
