import { isEnvDev, isEnvStaging } from './env'
import { datadogLogs } from '@datadog/browser-logs'
import store from '../store'

declare global {
    interface Window {
        logs: any
    }
}

datadogLogs.init({
    clientToken: 'pub123ccff0c0ce52e44c096b021a7aeb83',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'nfs',
})

function sendLogs(...args: any) {
    try {
        const sessionID = store.getState().session?.sessionID
        const gameName = store.getState().session?.gameName
        const userSessionID = store.getState().session?.userSessionID
        const uid = store.getState().user?.user?.uid

        const logPayload: any = {
            piepie: {
                sessId: sessionID,
                gameName: gameName,
                gamePageStatus: '', // TODO: hydrate store from app to update that field
                userSessionId: userSessionID,
                uid: uid,
                env: process.env.REACT_APP_ENV,
                host: process.env.REACT_APP_HOSTNAME,
                // fullPayload: args, // DO NOT activate it since it causes huge games crashes
            },
        }
        // TODO: move tracking to dedicated block when we'll be able to check user consent
        logPayload.user = uid
        // disable nextstate and prevstate from datadog tracking
        if (args.length > 0) {
            const header = args[0]
            if (header === 'prev state' || header === 'next state') {
                // do not log to data dog
                return
            }
        }
        // limit datadog logs to 200 chars
        let msg = args.join(' | ')
        if (msg.length > 200) {
            msg = msg.substring(0, 200) + '...'
        }
        datadogLogs.logger.log(msg, logPayload, 'info')
    } catch (e) {
        if (isEnvDev || isEnvStaging) {
            console.error('[Datadog] error:', e)
        }
    }
}

export const piepie = {
    log: function (...args) {
        if (isEnvDev || isEnvStaging) {
            console.log(...args)
        }
        sendLogs(...args)
    },
    logProd: function (...args) {
        console.log(...args)
        sendLogs(...args)
    },
    warn: function (...args) {
        if (isEnvDev || isEnvStaging) {
            console.warn(...args)
        }
        sendLogs(...args)
    },
    error: function (...args) {
        if (isEnvDev || isEnvStaging) {
            console.error(...args)
        }
        sendLogs(...args)
    },
    info: function (...args) {
        if (isEnvDev || isEnvStaging) {
            console.info(...args)
        }
        sendLogs(...args)
    },
}
