import style from './style.module.scss'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import cls from 'classnames'
import {
    subscribeToChatMessages,
    getUserFromStarfox,
    getUserSession,
} from '../../firebase'

import { User, Message, Player } from '../../state/faces'
import store from '../../store'
import { State } from '../../store/types'
import {
    isWebGLEnabled,
    useBreakpoint,
    Breakpoint,
    useDeviceOrientation,
} from '../../utils'
import FacesChat from './FacesChat'
import FacesChatInput from './FacesChatInput'
import FacesTabs, { FacesContent, FacesTab, FacesVideoMode } from './FacesTabs'
// import { useVisibleIndices, visibleIndicesType } from './useVisibleIndices'
import { useInvitationLink } from './useInvitationLink'
// import { MAX_REMOTE_VIDEOS } from '../../conference/hooks'
import FacesVideo from './FacesVideo'
import FacesMultiplayer from './FacesMultiplayer'
import MaskSelector from '../MaskSelector'
import { NicknameColorType } from '../../state/starfox'
import { Modal } from '../../stories'
// import { showLoading } from '../../utils/loading'
import SettingsModalCamera from '../SettingsModal/SettingsModalCamera'
import SettingsModalMicrophone from '../SettingsModal/SettingsModalMicrophone'

export interface FacesProps {
    users: { [index: string]: User }
    allUsers: { [index: string]: User }
    onSettingsDialogOpen: () => void
    width?: number
    mobile?: boolean
}

enum Tab {
    VIDEO = 0,
    CHAT = 1,
    MASK = 2,
    MULTIPLAYER = 3,
}

enum ModalType {
    NONE = 0,
    PARTICIPANTS = 1,
    PLAYER = 2,
}

function Faces({
    users,
    allUsers,
    onSettingsDialogOpen,
    width,
    mobile = false,
}: FacesProps) {
    const { t } = useTranslation()
    const gameName = useSelector((state: State) => state.session.gameName)
    const gameId = useSelector((state: State) => state.session.game.id)
    const user = useSelector((state: State) => state.user.user)
    const sessionID = useSelector((state: State) => state.session.sessionID)
    const userSessionID = useSelector(
        (state: State) => state.session.userSessionID
    )
    const breakpoint = useBreakpoint()
    const [tab, setTab] = useState(Tab.VIDEO)
    // const [isRoomFull, setIsRoomFull] = useState(true)
    const [messageGroups, setMessageGroups] = useState([])
    const [unread, setUnread] = useState(false)
    const [lastMessage, setLastMessage] = useState<Message>(null)
    const [lastMessageRead, setLastMessageRead] = useState<Message>(null)
    const [isWebGLSupported, setIsWebGLSupported] = useState<boolean>(true)
    const [modalType, setModalType] = useState<ModalType>(ModalType.NONE)
    const orientation = useDeviceOrientation()

    const modalTitle = {
        [ModalType.NONE]: '',
        [ModalType.PARTICIPANTS]: t('PlayerListModalTitle'),
        [ModalType.PLAYER]: t('PlayerModalTitle'),
    }

    // const visibleIndices = useVisibleIndices({
    //     users,
    //     maxVideos: MAX_REMOTE_VIDEOS,
    // })
    // const numFaces = getNumFaces(visibleIndices)
    const invitationLink = useInvitationLink({ user, gameName, gameId })

    const isHost = useSelector((state: State) => state.session.isHost)
    const isESL = useSelector((state: State) => state.session.isESL)
    const ESLGuestPlayer = useSelector(
        (state: State) => state.user.extra?.ESLGuestPlayer
    )
    const [messageID, setMessageID] = useState(-1)

    useEffect(() => {
        if (messageID === -1) {
            setMessageID(Math.floor(Math.random() * Math.floor(3)) + 1)
        }
    }, [messageID])

    useEffect(() => {
        if (!sessionID) return

        // We display the last message of each user in a bubble on top of the webcam
        const displayLastMessage = (lastMsg: any) => {
            if (!lastMsg) return
            setLastMessage(lastMsg)
        }

        const unsubscribe = subscribeToChatMessages(sessionID).onSnapshot(
            (snapshot) => {
                const messages = []
                let lastUserSessionID = ''
                const messageGroups = []
                let group = { UserSessionID: '', messages: [], fromself: false }
                snapshot.forEach((doc) => {
                    const msg = doc.data() as Message
                    msg.fromself = msg.UserSessionID === userSessionID
                    messages.push(msg)
                    if (lastUserSessionID !== msg.UserSessionID) {
                        if (lastUserSessionID !== '') {
                            messageGroups.push(group)
                        }
                        group = {
                            UserSessionID: msg.UserSessionID,
                            messages: [],
                            fromself: msg.UserSessionID === userSessionID,
                        }
                        lastUserSessionID = msg.UserSessionID
                    }
                    group.messages.push(msg)
                })

                if (group.messages.length > 0) messageGroups.push(group)

                setMessageGroups(messageGroups)
                displayLastMessage(messages[messages.length - 1])

                if (tab === Tab.CHAT)
                    setLastMessageRead(messages[messages.length - 1])
            }
        )

        return unsubscribe
    }, [sessionID, userSessionID, users, tab])

    useEffect(() => {
        const isChatEmpty = messageGroups.length === 0

        const isLastMessageFromSelf =
            messageGroups.length > 0 &&
            messageGroups[messageGroups.length - 1].fromself

        const isLastMessageRead =
            lastMessage &&
            lastMessageRead &&
            !(lastMessage.Created > lastMessageRead.Created)

        if (
            tab !== Tab.CHAT &&
            !isLastMessageFromSelf &&
            !isChatEmpty &&
            !isLastMessageRead
        ) {
            setUnread(true)
        }
    }, [tab, messageGroups, lastMessage, lastMessageRead])

    const renderWebGLDisabledNote = () => (
        <div className={style.webglNote}>
            <Trans i18nKey="WebGLDisabled">
                Your browser settings prevent the use of your camera. Check out
                how to fix it
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://piepacker.zendesk.com/hc/en-gb/articles/4403660491921-What-is-the-WebGL-error-I-m-getting-Why-are-masks-not-loading-"
                >
                    here
                </a>
                .
            </Trans>
        </div>
    )

    useEffect(() => {
        const webGLEnabled = isWebGLEnabled()
        setIsWebGLSupported(webGLEnabled)
    }, [])

    const modes = {
        0: FacesVideoMode.Video,
        1: FacesVideoMode.Chat,
        2: FacesVideoMode.Mask,
        3: FacesVideoMode.Multiplayer,
    }
    const videoMode = modes[tab]

    const conferenceViewModes = {
        0: 'default',
        1: 'solo',
        2: 'solo',
        3: 'hidden',
    }
    const conferenceViewMode = conferenceViewModes[tab]

    const devices = useSelector((state: State) => state.session.devices)
    const localPlayers = useSelector(
        (state: State) => state.session.localPlayers
    )
    const playersIndex = useSelector(
        (state: State) => state.session.playerIndices
    )
    const playerExtraData = useSelector((state: State) => state.session.players)
    const [storedPlayerInformation, setStoredPlayerInformation] = useState({})
    const [players, setPlayers] = useState<Player[]>([])
    const [playersCount, setPlayersCount] = useState(0)
    const [localHost, setLocalHost] = useState(-1)
    const [focusedPlayerId, setFocusedPlayerId] = useState(undefined)

    const buildPlayer = useCallback((index: number): Player => {
        return {
            index: index,
            usid: null,
            uid: null,
            local: false,
            device: null,
            photoUrl: null,
            username: null,
            localhost: false,
            hasGoldLetters: false,
        }
    }, [])

    // TODO: move this to a unified data-structure with allUsers
    const populatePlayers = useCallback(async () => {
        const p = [
            buildPlayer(0),
            buildPlayer(1),
            buildPlayer(2),
            buildPlayer(3),
            buildPlayer(4),
            buildPlayer(5),
            buildPlayer(6),
            buildPlayer(7),
        ]

        const localUSID = store.getState().session.userSessionID
        for (const [usid, indices] of Object.entries(playersIndex)) {
            for (let i = 0; i < indices.length; i++) {
                if (!p[indices[i]]) continue
                p[indices[i]].usid = usid
                p[indices[i]].local = usid === localUSID

                // we set the device and the localhost
                if (usid === localUSID) {
                    let deviceId = 0
                    for (let j = 0; j < localPlayers.length; j++) {
                        if (localPlayers[j].index === p[indices[i]].index) {
                            deviceId = localPlayers[j].device
                            p[indices[i]].localhost = localPlayers[j].localhost
                            if (localPlayers[j].localhost)
                                setLocalHost(p[indices[i]].index)
                            break
                        }
                    }
                    for (let j = 0; j < devices.length; j++) {
                        if (devices[j].id === deviceId) {
                            p[indices[i]].device = devices[j]
                            break
                        }
                    }
                }

                if (storedPlayerInformation[usid]) {
                    p[indices[i]].photoUrl =
                        storedPlayerInformation[usid].photoUrl
                    p[indices[i]].username =
                        storedPlayerInformation[usid].username
                    p[indices[i]].hasGoldLetters =
                        storedPlayerInformation[usid].hasGoldLetters
                } else {
                    const userSession = await getUserSession(usid)
                    const user = await getUserFromStarfox(userSession.UID)
                    p[indices[i]].photoUrl = user.User.PhotoURL
                    p[indices[i]].username = user.User.DisplayName
                    p[indices[i]].hasGoldLetters =
                        playerExtraData[userSession.UID]?.NicknameColor ===
                        NicknameColorType.Gold
                    p[indices[i]].uid = userSession.UID

                    storedPlayerInformation[usid] = p[indices[i]]
                    setStoredPlayerInformation(storedPlayerInformation)
                }

                if (!p[indices[i]].localhost && p[indices[i]].local) {
                    p[indices[i]].photoUrl =
                        'https://fassets.piepacker.com/random/LocalPlayerCameraPlaceholder.png'
                }
            }
        }

        for (let i = p.length - 1; i > 0; i--) {
            if (!p[i].username) {
                p.pop()
            } else {
                break
            }
        }
        let count = 0
        for (const player of p) {
            if (player.username) count++
        }
        setPlayersCount(count)
        setPlayers(p)
    }, [
        devices,
        localPlayers,
        playersIndex,
        buildPlayer,
        playerExtraData,
        storedPlayerInformation,
        setStoredPlayerInformation,
    ])

    useEffect(() => {
        if (devices && localPlayers && playersIndex) {
            populatePlayers()
        }
    }, [devices, localPlayers, playersIndex, populatePlayers])

    if (!mobile) {
        return (
            <div className={style.faces}>
                <FacesTabs
                    className={style.tabs}
                    index={tab}
                    onChange={(_, { currentData }) => {
                        setTab(currentData)
                        setFocusedPlayerId(undefined)
                        if (currentData === Tab.CHAT) {
                            setUnread(false)
                            setLastMessageRead(lastMessage)
                        }
                    }}
                >
                    <FacesTab name="video" data={Tab.VIDEO} />
                    <FacesTab
                        name="chat"
                        notification={unread}
                        data={Tab.CHAT}
                        hidden={breakpoint < Breakpoint.md}
                    />
                    <FacesTab name="masks" data={Tab.MASK} />
                    <FacesTab
                        name="multiplayer"
                        scroll={true}
                        data={Tab.MULTIPLAYER}
                        hidden={
                            ((!isHost || !isESL) && isESL) ||
                            breakpoint < Breakpoint.md
                        }
                    />

                    <FacesContent className={cls(style.tabs, style[videoMode])}>
                        {!isWebGLSupported ? (
                            tab === Tab.VIDEO && renderWebGLDisabledNote()
                        ) : (
                            <FacesVideo
                                players={players}
                                width={width}
                                viewMode={conferenceViewMode}
                                onSettingsDialogOpen={onSettingsDialogOpen}
                                allUsers={allUsers}
                                invitationLink={invitationLink}
                                onListViewRequest={(userId) => {
                                    setTab(Tab.MULTIPLAYER)
                                    setFocusedPlayerId(userId)
                                }}
                            />
                        )}
                        {tab === Tab.CHAT && (
                            <FacesChat
                                messageGroups={messageGroups}
                                allUsers={allUsers}
                            />
                        )}
                        {tab === Tab.CHAT &&
                            (gameName === 'Arsene Bomber ESL' &&
                            ESLGuestPlayer ? null : (
                                <FacesChatInput
                                    sessionID={sessionID}
                                    userSessionID={userSessionID}
                                />
                            ))}
                        {tab === Tab.MASK &&
                            (!isWebGLSupported ? (
                                renderWebGLDisabledNote()
                            ) : (
                                <MaskSelector />
                            ))}
                        {tab === Tab.MULTIPLAYER && (
                            <FacesMultiplayer
                                players={players}
                                playersCount={playersCount}
                                localHost={localHost}
                                devices={devices}
                                localPlayers={localPlayers}
                                focusedPlayerId={focusedPlayerId}
                            />
                        )}
                    </FacesContent>
                </FacesTabs>
            </div>
        )
    }

    // return mobile:

    return (
        <div className={style.mobileFaces}>
            <FacesVideo
                players={players}
                width={width}
                viewMode={'mobile'}
                onSettingsDialogOpen={onSettingsDialogOpen}
                allUsers={allUsers}
                invitationLink={invitationLink}
                onListViewRequest={(userId) => {
                    setModalType(ModalType.PARTICIPANTS)
                    setFocusedPlayerId(userId)
                }}
                onPlayerSettingsOpen={() => {
                    setModalType(ModalType.PLAYER)
                }}
                mobile={true}
            />
            <Modal
                size={
                    orientation.includes('landscape')
                        ? 'fullheight'
                        : 'fullwidth'
                }
                show={modalType !== ModalType.NONE}
                onClose={() => setModalType(ModalType.NONE)}
                title={modalTitle[modalType]}
                position="bottom"
                backdrop={false}
                bodyClassName={style.mobileModalBody}
                className={style.mobileModal}
                style={
                    orientation.includes('landscape')
                        ? {
                              width: '63vw',
                          }
                        : {
                              // FIXME: We need something more robust here... for now this does the work...
                              height: `calc( 100% - 112px - ${
                                  (window.innerWidth * 3) / 4
                              }px )`, // full height - video conference space - game space
                          }
                }
            >
                {modalType === ModalType.PARTICIPANTS && (
                    <div className={style.mobileModalMultiplayer}>
                        <FacesMultiplayer
                            players={players}
                            playersCount={playersCount}
                            localHost={localHost}
                            devices={devices}
                            localPlayers={localPlayers}
                            focusedPlayerId={focusedPlayerId}
                        />
                    </div>
                )}

                {modalType === ModalType.PLAYER && (
                    <>
                        <div className={style.mobilePlayerSettings}>
                            <SettingsModalCamera showOptions={false} />
                            <SettingsModalMicrophone showOptions={false} />
                        </div>
                        <div className={style.mobileModalMasks}>
                            <MaskSelector />
                        </div>
                    </>
                )}
            </Modal>
        </div>
    )
}

export default Faces
