import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setSubmitError, setSubmitSucess } from '../../store/auth/authV2'

export const useAlert = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            dispatch(setSubmitError(''))
            dispatch(setSubmitSucess(''))
        }
    }, [dispatch])
}
