import { db } from './'
import { SessionFromFirebase } from '../state/starfox'

export async function getSession(
    sessionID: string
): Promise<SessionFromFirebase> {
    const resp = await db.collection('sessions').doc(sessionID).get()
    return resp.data() as SessionFromFirebase
}

export function subscribeToSession(sessionID: string) {
    return db.collection('sessions').doc(sessionID)
}

export function setSessionSettings(sessionID: string, settings): void {
    const sessionSettings = { ...settings }
    db.collection('sessions')
        .doc(sessionID)
        .set(
            {
                ...sessionSettings,
            },
            {
                merge: true,
            }
        )
}
