import React from 'react'
import style from './style.module.scss'
import { Icon } from '../../stories'
import { useDispatch } from 'react-redux'
import gameroomModalSectionSlice from '../../store/gameroomModalSection/gameroomModalSection'
import { GameroomModalSectionType } from '../../state/gameroomModalSection'

export default function Savestate({ save, index, load, remove }) {
    const dispatch = useDispatch()

    // const onClickWrapper = useCallback(
    //     (onClickCallback) => {
    //         if (
    //             currentMousePressedTime &&
    //             new Date().getTime() - currentMousePressedTime.getTime() > 150
    //         ) {
    //             return
    //         }

    //         onClickCallback()
    //     },
    //     [currentMousePressedTime]
    // )

    return (
        <div data-test="GameSavestate" className={style.state}>
            <img
                data-test={'GameSavestateLoad' + index}
                className={style.thumbnail}
                src={save.url}
                alt={save.name}
                onClick={(event) => {
                    event.preventDefault()
                    //onClickWrapper(() => load(save.name))
                    dispatch(
                        gameroomModalSectionSlice.actions.setGameroomModal(
                            GameroomModalSectionType.NONE
                        )
                    )
                    load(save.name)
                }}
            />
            <div className={style.label}>
                <div>{save.date}</div>
                <div>{save.time}</div>
            </div>
            <div
                data-test={'GameSavestateDelete' + index}
                className={style.delete}
                onClick={() => {
                    remove(save.name)
                    // onClickWrapper(() => remove(save.name))
                }}
            >
                <Icon name="delete" width="1.5rem" height="1.5rem" />
            </div>
        </div>
    )
}
