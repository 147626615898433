import { byog_config } from '../pages/BYOG/config'
import { Game, SessionFromFirebase } from '../state/starfox'
import { SessionState } from '../store/session/types'
import i18next from 'i18next'
import { LocalStorage } from '../state/storage'
import { coreToConsole } from '../components/PublicRoomsList/utils'

export function arrayNotEmpty(array: any[]): boolean {
    return Array.isArray(array) && array.length > 0
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

// WARNING: This is not used
export function shuffle(array) {
    let currentIndex = array.length,
        temporaryValue,
        randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }

    return array
}

export function visibleIf(b: boolean) {
    return { display: b ? 'block' : 'none' }
}

export function rand(min = 0, max = 999999): number {
    return min + Math.floor(Math.random() * (max - min + 1))
}

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand('copy')
        return successful
    } catch (err) {
        return false
    } finally {
        document.body.removeChild(textArea)
    }
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text)
    }
    const result = navigator.clipboard.writeText(text).then(
        function () {
            return true
        },
        function () {
            return false
        }
    )

    return result
}

export function isByogMode(gameName: string) {
    switch (gameName) {
        case byog_config.session.snes:
            return true
        case byog_config.session.nes:
            return true
        case byog_config.session.genesis:
            return true
        case byog_config.session.gba:
            return true
        case byog_config.session.n64:
            return true
        case byog_config.session.gb:
            return true
        case byog_config.session.sms:
            return true
        case byog_config.session.pico8:
            return true
        case byog_config.session.psx:
            return true
        case byog_config.session.lutro:
            return true
        default:
            return false
    }
}

export const formatDate = (date) => {
    if (parseInt(date) <= 9) {
        return `0${parseInt(date)}`
    }
    return date
}

export const isWebGLEnabled = () => {
    try {
        const canvas = document.createElement('canvas')

        const hasRenderingContext = window.WebGLRenderingContext ? true : false
        let hasWebGLContext = canvas.getContext('webgl') ? true : false

        if (!hasWebGLContext)
            hasWebGLContext = canvas.getContext('experimental-webgl')
                ? true
                : false

        return hasRenderingContext && hasWebGLContext
    } catch (e) {
        return false
    }
}

/**
 * decomposes combined graphemes into the combination of simple ones
 * (è ends up expressed as e +  ̀) using Unicode property Diacritic
 * removes diacritics from string
 */
export const normalizeRemoveDiacritic = (str: string): string => {
    if (!str || typeof str !== 'string') return ''
    return str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

interface CreateCoreGamePayload {
    gameName: SessionState['gameName']
    byog?: SessionState['byog']
    gameCore?: SessionState['gameCore']
    gameFile?: SessionState['gameFile']
}

export type CreateCoreGameString = `${CreateCoreGamePayload['gameName']}:${
    | CreateCoreGamePayload['byog']}:${CreateCoreGamePayload['gameCore']}:${CreateCoreGamePayload['gameFile']}`
type MakeCreateCorePayloadString = (
    gameObject: CreateCoreGamePayload
) => CreateCoreGameString

export const makeCreateCorePayloadString: MakeCreateCorePayloadString = ({
    gameName,
    byog = false,
    gameCore = '',
    gameFile = '',
}) => {
    return `${gameName}:${byog}:${gameCore}:${gameFile}`
}

export enum GameState {
    GAME_PAUSED = 'GAME_PAUSED',
    GAME_SELECT = 'GAME_SELECT',
    GAME_RUNNING = 'GAME_RUNNING',
}

type GameStateType = keyof typeof GameState

type CoreReadyDataString = `${Game['id']}:${GameStateType}`

/*
    TODO: update react-scripts to support modern typescript-parser. currently it
    does shows criptic error message
*/
// type ParseCoreReadyPayloadString = (
//     arg0:
// ) => [Game['id'], GameStateType]

export const parseCoreReadyPayloadString = (
    coreReadyDataString: CoreReadyDataString
) => {
    const [gameId, gameState] = coreReadyDataString.split(':')
    return [gameId, gameState]
}

export const removeDuplicates = (array: Array<any>) =>
    Array.from(new Set(array))

// Reference: https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1
        while (k--) {
            arr.push(undefined)
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr // for testing
}

export const sortArrayItemsByOccurence = (array: string[]) => {
    const occuenrence = {}
    array.forEach((value) => (occuenrence[value] = 0))
    const uniques = array.filter((value) => ++occuenrence[value] === 1)
    return uniques.sort((a, b) => occuenrence[b] - occuenrence[a])
}

export const getLanguage = () => {
    const currentLanguage =
        i18next.language ||
        window.localStorage.getItem(LocalStorage.i18nextLng) ||
        ''

    let lang = currentLanguage.includes('fr') ? 'FR' : 'EN'
    lang = currentLanguage.includes('es') ? 'ES' : lang
    lang = currentLanguage.includes('de') ? 'DE' : lang
    lang = currentLanguage.includes('pt') ? 'PT' : lang

    return lang
}

// Comapare two js objects
export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
        return false
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false
        }
    }
    return true
}

export let lastOpenRoomsCached: SessionFromFirebase[] = []

export const setLastOpenRoomsCached = (s: SessionFromFirebase[]) => {
    lastOpenRoomsCached = s
}

export const getNumberOfOpenRoomsByGame = (
    openRooms: SessionFromFirebase[],
    game: Game
): number => {
    let count = 0
    for (const room of openRooms) {
        if (room.CurrentGame === game.id) count++
        else if (
            room.IsCurrentBYOG &&
            game.Categories?.includes('BYOG') &&
            coreToConsole(room.Games[room.Games.length - 1].GameCore) ===
                game.id
        )
            count++
    }
    return count
}
