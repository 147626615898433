import styles from './styles.module.scss'
import cls from 'classnames'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { PreferredGameType } from '../../../state/starfox'
import { setPreferredGames } from '../../../firebase/users'
import Tag from '../../../stories/atoms/Tag'

interface IPreferredGameModal {
    selectedGames: PreferredGameType[]
    onSuccess: () => void
}

const PreferredGameModal: React.FC<IPreferredGameModal> = ({
    onSuccess,
    selectedGames,
}) => {
    const { t } = useTranslation()
    const [games, setGames] = useState<PreferredGameType[]>(
        selectedGames ? selectedGames : []
    )

    const includesGame = (game: PreferredGameType) => games.includes(game)

    const handleSelect = (game: PreferredGameType) => {
        if (includesGame(game)) {
            const updateGames = games.filter((item) => item !== game)
            setGames(updateGames)
        } else {
            setGames([...games, game])
        }
    }

    const handleSubmit = async (games: PreferredGameType[]) => {
        await setPreferredGames(games)
        onSuccess()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('PreferredGame')}</Modal.Title>
            </Modal.Header>
            <p>{t('ChoosePreferredGame')}</p>

            <div
                className={cls(
                    styles.preferredGameModalContainer,
                    styles.preferredGameContainer
                )}
            >
                {Object.values(PreferredGameType).map((game) => (
                    <Tag
                        key={game}
                        title={t(`PreferredGame${game}`)}
                        onClick={() => handleSelect(game)}
                        isSelected={includesGame(game)}
                    />
                ))}
            </div>

            <Button
                block
                type="submit"
                className="btn-large"
                onClick={() => handleSubmit(games)}
            >
                {t('Save')}
            </Button>
        </>
    )
}

export default PreferredGameModal
