import React, { FC } from 'react'
import { UserInterfaceFromFirebase } from '../../state/starfox'
import style from './style.module.scss'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

interface IPublicRoomsAvatar {
    players: UserInterfaceFromFirebase[]
}

const PublicRoomsAvatar: FC<IPublicRoomsAvatar> = ({ players }) => {
    const defaultPhotoUrl = '/piepie_white.svg'
    const { t } = useTranslation()

    const avatar = (p, index = -1, nickname = true) => {
        if (!p) return
        const isEmpty = Object.keys(p).length === 0
        if (!isEmpty) {
            let imgError = false
            const photoUrl = p?.User.PhotoURL || defaultPhotoUrl
            return (
                <div key={index} className={style.avatar}>
                    <img
                        alt=""
                        src={photoUrl}
                        onError={({ currentTarget }) => {
                            if (imgError) return
                            imgError = true
                            currentTarget.src = defaultPhotoUrl
                        }}
                        className={cls(style.picture, {
                            [style.empty]:
                                !p || photoUrl === defaultPhotoUrl || imgError,
                            [style.outlined]: index === -1,
                        })}
                    />
                    {nickname && (
                        <div className={style.nickname}>
                            {p?.User.DisplayName || t('Guest')}
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div key={index} className={cls(style.avatar, style.empty)}></div>
        )
    }

    return (
        <div className={style.avatars}>
            {/* First player */}
            <div className={style.firstPlayer}>{avatar(players[0])}</div>
            {/* 2nd+ players */}
            <div className={style.otherPlayers}>
                {players
                    .slice(1, players.length)
                    .map((p, index) => avatar(p, index, false))}
            </div>
        </div>
    )
}

export default PublicRoomsAvatar
