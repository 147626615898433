import styles from './styles.module.scss'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { piepie } from '../../../utils'
import * as selector from '../state/selectors'
import EditPassword from '../../EditPassword'

enum Logs {
    EditSuccess = '[ACCOUNT] password edited successfully',
}

const DEFAULT_PASSWORD = '******************'

const EmailAuthentication: React.FC = () => {
    const { t } = useTranslation()
    const [isEdit, setEdit] = useState(false)
    const userEmail = useSelector(selector.getUserEmail)

    const handleSuccess = () => {
        piepie.log(Logs.EditSuccess)
        setEdit(false)
    }

    return (
        <div className={styles.section}>
            <Modal
                onHide={() => setEdit(false)}
                show={isEdit}
                centered
                enforceFocus={false}
            >
                <EditPassword onSuccess={handleSuccess} />
            </Modal>
            <p className={styles.header}>{t('EmailAndPassword')}</p>
            <div className={styles.itemsContainer}>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('Email')}</p>
                        <p className={styles.itemValue}>{userEmail}</p>
                    </div>
                </div>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('Password')}</p>
                        <p className={styles.itemValue}>{DEFAULT_PASSWORD}</p>
                    </div>
                    <p onClick={() => setEdit(true)} className={styles.action}>
                        {t('UpdatePassword')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EmailAuthentication
