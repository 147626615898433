import React, { FC, ReactNode, useState, useEffect, useRef } from 'react'
import style from './style.module.scss'
import cls from 'classnames'
import { Icon, CountdownCircleTimer } from '../..'

interface FloatingOverlayProps {
    width?: number
    height?: number
    className?: string
    top?: number
    left?: number
    limitOffset?: number
    autoCloseTimeout?: number
    autoClose?: boolean
    onClose: () => void
    children: ReactNode
    [rootHtmlAttributes: string]: any
}

const FloatingOverlay: FC<FloatingOverlayProps> = ({
    width,
    height,
    className,
    children,
    top,
    left,
    borderOffset = 50,
    autoCloseTimeout = 10,
    autoClose = true,
    onClose,
    ...rootHtmlAttributes
}) => {
    const [grabOffset, setGrabOffset] = useState(null)
    const [topOffset, setTopOffset] = useState(top)
    const [leftOffset, setLeftOffset] = useState(left)
    const ref = useRef(undefined)

    useEffect(() => {
        const stopDrag = () => {
            setGrabOffset(null)
        }

        document.addEventListener('mouseup', stopDrag)
        document.addEventListener('mouseleave', stopDrag)
        return () => {
            document.removeEventListener('mouseup', stopDrag)
            document.removeEventListener('mouseleave', stopDrag)
        }
    }, [])

    useEffect(() => {
        const onMouseMove = (e: MouseEvent) => {
            if (grabOffset) {
                let newTopOffset = e.clientY + grabOffset.y
                let newLeftOffset = e.clientX + grabOffset.x

                if (newTopOffset < 0) newTopOffset = 0
                else if (window.innerHeight - borderOffset - newTopOffset < 0)
                    newTopOffset = window.innerHeight - borderOffset

                if (newLeftOffset < 0) newLeftOffset = 0
                else if (window.innerWidth - borderOffset - newLeftOffset < 0)
                    newLeftOffset = window.innerWidth - borderOffset

                setTopOffset(newTopOffset)
                setLeftOffset(newLeftOffset)
            }
        }

        document.addEventListener('mousemove', onMouseMove)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
        }
    }, [grabOffset, borderOffset])

    return (
        <div
            {...rootHtmlAttributes}
            className={cls(style.container, className)}
            style={{
                width,
                height,
                top: topOffset,
                left: leftOffset,
            }}
            ref={ref}
        >
            <div className={style.header}>
                <button
                    className={style.dragButton}
                    onMouseDown={(e) => {
                        const { offsetLeft, offsetTop } = ref.current
                        const newGrabOffset = {
                            x: offsetLeft - e.clientX,
                            y: offsetTop - e.clientY,
                        }
                        setGrabOffset(newGrabOffset)
                    }}
                >
                    <Icon name="dragHandle" />
                </button>

                {autoClose ? (
                    <CountdownCircleTimer
                        size={32}
                        strokeWidth={3}
                        duration={autoCloseTimeout}
                        onComplete={onClose}
                        onClick={onClose}
                        transparent
                    >
                        <Icon name="close" />
                    </CountdownCircleTimer>
                ) : (
                    <button className={style.closeButton} onClick={onClose}>
                        <Icon name="close" />
                    </button>
                )}
            </div>
            <div className={style.body}>{children}</div>
        </div>
    )
}

export default FloatingOverlay
