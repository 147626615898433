import React, { FC } from 'react'
import style from './style.module.scss'
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Banned: FC = () => {
    const { t } = useTranslation()

    return (
        <Modal
            dialogClassName={style.BanModalDialog}
            contentClassName={style.BanModalContent}
            show={true}
            centered
            enforceFocus={false}
        >
            <Modal.Title className={style.BanTitle}>
                {t('BanRedirectDialogTitle')}
            </Modal.Title>
            <Modal.Body className={style.BanBody}>
                {t('BanRedirectDialogText')}
            </Modal.Body>
            <Button className={style.BanHomeRedirect} href="/">
                {t('BanRedirectDialogCTA')}
            </Button>
        </Modal>
    )
}

export default Banned
