import React, { useCallback } from 'react'
import style from './style.module.scss'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export default function NotFound() {
    const { t } = useTranslation()
    const history = useHistory()

    const handleGoHomeClick = useCallback(() => {
        history.push('/')
    }, [history])

    return (
        <div className={style.container}>
            <img
                className={style.piepiebg}
                src="https://fassets.piepacker.com/random/404-PiePie.svg"
                alt="404"
            />
            <div className={style.title}>{t('404-Title')}</div>
            <div className={style.subtitle}>{t('404-SubTitle')}</div>
            <Button className="btn-w-100 btn-small" onClick={handleGoHomeClick}>
                {t('GoHome')}
            </Button>
        </div>
    )
}
