import { useEffect, useState } from 'react'

export const useDeviceOrientation = () => {
    const getOrientation = () => {
        let orientationValue =
            (window.screen.orientation || {}).type ||
            // @ts-ignore
            window.screen.mozOrientation ||
            // @ts-ignore
            window.screen.msOrientation

        if (!orientationValue) {
            switch (window.orientation) {
                case 0:
                    orientationValue = 'portrait-primary'
                    break
                case 180:
                    orientationValue = 'portrait-secondary'
                    break
                case 90:
                    orientationValue = 'landscape-primary'
                    break
                case -90:
                    orientationValue = 'landscape-secondary'
                    break
                default:
                    orientationValue = ''
            }
        }
        return orientationValue
    }

    const [orientation, setOrientation] = useState(getOrientation())

    useEffect(() => {
        const updateOrientation = () => {
            setOrientation(getOrientation())
        }

        window.addEventListener('orientationchange', updateOrientation)
        return () => {
            window.removeEventListener('orientationchange', updateOrientation)
        }
    }, [])

    return orientation
}
