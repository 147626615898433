import {
    CameraResolution,
    Participant,
    Participants,
} from '../../state/conference'
import {
    SET_MEETING_ID,
    SET_CHIME_MEETING_ID,
    SET_REGION,
    SET_NAME,
    SET_ERROR,
    SET_IS_LOADING,
    SET_SND_BANDWIDTH,
    SET_RCV_BANDWIDTH,
    SET_IS_CAMERA_ACTIVE,
    SET_IS_MICROPHONE_ACTIVE,
    SET_IS_SPEAKER_ACTIVE,
    RESET_STATE,
    ADD_PARTICIPANT,
    REMOVE_PARTICIPANT,
    EDIT_PARTICIPANT,
    UPDATE_CAMERA_RESOLUTION,
    SET_IS_CAMERA_AVAILABLE,
    SET_IS_MICROPHONE_AVAILABLE,
    ConferenceActionTypes,
    SET_IS_DEVICE_BINDING_COMPLETE,
    SET_IS_MICROPHONE_GRANTED,
    SET_IS_CAMERA_GRANTED,
    SET_VIRTUAL_CAMERA_STREAM,
    UPDATE_AUDIO_INPUT_DEVICES,
    UPDATE_AUDIO_OUTPUT_DEVICES,
    UPDATE_VIDEO_INPUT_DEVICES,
    SET_CURRENT_AUDIO_INPUT_DEVICE,
    SET_CURRENT_AUDIO_OUTPUT_DEVICE,
    SET_CURRENT_VIDEO_INPUT_DEVICE,
    SET_IS_SPEAKER_GRANTED,
    SET_IS_SPEAKER_AVAILABLE,
    EDIT_PARTICIPANT_MUTED,
    EDIT_PARTICIPANT_VOLUME,
    SET_IS_VIDEO_FEED_ENABLED,
} from './types'

export function setMeetingId(meetingId: string): ConferenceActionTypes {
    return {
        type: SET_MEETING_ID,
        payload: meetingId,
    }
}

export function setChimeMeetingId(
    chimeMeetingId: string
): ConferenceActionTypes {
    return {
        type: SET_CHIME_MEETING_ID,
        payload: chimeMeetingId,
    }
}

export function setRegion(region: string): ConferenceActionTypes {
    return {
        type: SET_REGION,
        payload: region,
    }
}

export function setName(name: string): ConferenceActionTypes {
    return {
        type: SET_NAME,
        payload: name,
    }
}

export function setError(error: string): ConferenceActionTypes {
    return {
        type: SET_ERROR,
        payload: error,
    }
}

export function setIsLoading(isLoading: boolean): ConferenceActionTypes {
    return {
        type: SET_IS_LOADING,
        payload: isLoading,
    }
}

export function setSndBandwidth(
    availableSendBandwidth: number
): ConferenceActionTypes {
    return {
        type: SET_SND_BANDWIDTH,
        payload: availableSendBandwidth,
    }
}

export function setRcvBandwidth(
    availableSendBandwidth: number
): ConferenceActionTypes {
    return {
        type: SET_RCV_BANDWIDTH,
        payload: availableSendBandwidth,
    }
}

export function setIsCameraActive(
    isCameraActive: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_CAMERA_ACTIVE,
        payload: isCameraActive,
    }
}

export function setIsMicrophoneActive(
    isMicrophoneActive: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_MICROPHONE_ACTIVE,
        payload: isMicrophoneActive,
    }
}

export function setIsSpeakerActive(
    isSpeakerActive: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_SPEAKER_ACTIVE,
        payload: isSpeakerActive,
    }
}

export function setIsDeviceBindingComplete(
    setIsDeviceBindingComplete: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_DEVICE_BINDING_COMPLETE,
        payload: setIsDeviceBindingComplete,
    }
}

export function addParticipant(
    participant: Participant
): ConferenceActionTypes {
    return {
        type: ADD_PARTICIPANT,
        payload: participant,
    }
}

export function removeParticipant(id: string): ConferenceActionTypes {
    return {
        type: REMOVE_PARTICIPANT,
        payload: id,
    }
}

export function editParticipant(
    participant: Participants<Participant>
): ConferenceActionTypes {
    return {
        type: EDIT_PARTICIPANT,
        payload: participant,
    }
}

export function editParticipantMuted(payload: {
    externalUserId: string
    isMuted: boolean
}): ConferenceActionTypes {
    return {
        type: EDIT_PARTICIPANT_MUTED,
        payload: payload,
    }
}

export function editParticipantVolume(
    userId: string,
    volume: number
): ConferenceActionTypes {
    return {
        type: EDIT_PARTICIPANT_VOLUME,
        payload: {
            userId,
            volume,
        },
    }
}

export function updateAudioInputDevices(
    audioInputDevices: MediaDeviceInfo[]
): ConferenceActionTypes {
    return {
        type: UPDATE_AUDIO_INPUT_DEVICES,
        payload: audioInputDevices,
    }
}

export function updateAudioOutputDevices(
    audioOutputDevices: MediaDeviceInfo[]
): ConferenceActionTypes {
    return {
        type: UPDATE_AUDIO_OUTPUT_DEVICES,
        payload: audioOutputDevices,
    }
}

export function updateVideoInputDevices(
    audioOutputDevices: MediaDeviceInfo[]
): ConferenceActionTypes {
    return {
        type: UPDATE_VIDEO_INPUT_DEVICES,
        payload: audioOutputDevices,
    }
}

export function updateCameraResolution(
    resolution: CameraResolution
): ConferenceActionTypes {
    return {
        type: UPDATE_CAMERA_RESOLUTION,
        payload: resolution,
    }
}

export function setIsCameraGranted(isGranted: boolean): ConferenceActionTypes {
    return {
        type: SET_IS_CAMERA_GRANTED,
        payload: isGranted,
    }
}

export function setIsMicrophoneGranted(
    isGranted: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_MICROPHONE_GRANTED,
        payload: isGranted,
    }
}

export function setIsCameraAvailable(
    isAvailable: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_CAMERA_AVAILABLE,
        payload: isAvailable,
    }
}

export function setIsMicrophoneAvailable(
    isAvailable: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_MICROPHONE_AVAILABLE,
        payload: isAvailable,
    }
}

export function setVirualCameraStream(
    stream: MediaStream
): ConferenceActionTypes {
    return {
        type: SET_VIRTUAL_CAMERA_STREAM,
        payload: stream,
    }
}

export function setCurrentAudioInputDevice(
    device: MediaDeviceInfo
): ConferenceActionTypes {
    return {
        type: SET_CURRENT_AUDIO_INPUT_DEVICE,
        payload: device,
    }
}

export function setCurrentAudioOutputDevice(
    device: MediaDeviceInfo
): ConferenceActionTypes {
    return {
        type: SET_CURRENT_AUDIO_OUTPUT_DEVICE,
        payload: device,
    }
}

export function setCurrentVideoInputDevice(
    device: MediaDeviceInfo
): ConferenceActionTypes {
    return {
        type: SET_CURRENT_VIDEO_INPUT_DEVICE,
        payload: device,
    }
}

export function setIsSpeakerGranted(isGranted: boolean): ConferenceActionTypes {
    return {
        type: SET_IS_SPEAKER_GRANTED,
        payload: isGranted,
    }
}

export function setIsSpeakerAvailable(
    isAvailable: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_SPEAKER_AVAILABLE,
        payload: isAvailable,
    }
}

export function setIsVideoFeedEnabled(
    isEnabled: boolean
): ConferenceActionTypes {
    return {
        type: SET_IS_VIDEO_FEED_ENABLED,
        payload: isEnabled,
    }
}

export function resetState(): ConferenceActionTypes {
    return {
        type: RESET_STATE,
    }
}
