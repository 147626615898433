import { KEYBOARD_MAP } from '../input/keyboard'
import * as Input from '../input/input'

const setInputState = (key, state = true) => {
    const id = KEYBOARD_MAP.get(key)
    if (id !== undefined && Input.isControllerActive(-1)) {
        const idx = Input.getDeviceIndex(-1)
        Input.setKeyState(idx >= 0 ? idx : 0, id, state)
        Input.setLastUsedDevice(-1)
    }
}

const joystickControlValues = {
    start: () => {
        setInputState('ArrowRight', false)
        setInputState('ArrowLeft', false)
        setInputState('ArrowUp', false)
        setInputState('ArrowDown', false)
    },
    end: () => {
        setInputState('ArrowRight', false)
        setInputState('ArrowLeft', false)
        setInputState('ArrowUp', false)
        setInputState('ArrowDown', false)
    },
    right: () => {
        setInputState('ArrowRight')
    },
    left: () => {
        setInputState('ArrowLeft')
    },
    up: () => {
        setInputState('ArrowUp')
    },
    down: () => {
        setInputState('ArrowDown')
    },
    upRight: () => {
        setInputState('ArrowRight')
        setInputState('ArrowUp')
    },
    downRight: () => {
        setInputState('ArrowRight')
        setInputState('ArrowDown')
    },
    upLeft: () => {
        setInputState('ArrowLeft')
        setInputState('ArrowUp')
    },
    downLeft: () => {
        setInputState('ArrowLeft')
        setInputState('ArrowDown')
    },
}

const keyControlValues = {
    L1: {
        touchStart: () => {
            setInputState('KeyQ')
        },
        touchEnd: () => {
            setInputState('KeyQ', false)
        },
    },
    R1: {
        touchStart: () => {
            setInputState('KeyE')
        },
        touchEnd: () => {
            setInputState('KeyE', false)
        },
    },
    L2: {
        touchStart: () => {
            setInputState('Digit1')
        },
        touchEnd: () => {
            setInputState('Digit1', false)
        },
    },
    R2: {
        touchStart: () => {
            setInputState('Digit3')
        },
        touchEnd: () => {
            setInputState('Digit3', false)
        },
    },
    X: {
        touchStart: () => {
            setInputState('KeyA')
        },
        touchEnd: () => {
            setInputState('KeyA', false)
        },
    },
    A: {
        touchStart: () => {
            setInputState('KeyS')
        },
        touchEnd: () => {
            setInputState('KeyS', false)
        },
    },
    Y: {
        touchStart: () => {
            setInputState('KeyW')
        },
        touchEnd: () => {
            setInputState('KeyW', false)
        },
    },
    B: {
        touchStart: () => {
            setInputState('KeyD')
        },
        touchEnd: () => {
            setInputState('KeyD', false)
        },
    },
}

const getButtons = (controls, keyGroup) => {
    const keys = []

    const sortedKeys = (keys) => {
        const orderedKeys = []
        const filteredKeys = keys.filter((k) => keyGroup.indexOf(k) > -1)

        keyGroup.forEach((a) => {
            filteredKeys.forEach((k) => {
                if (a === k) orderedKeys.push(a)
            })
        })

        return orderedKeys
    }

    if (controls) {
        Object.entries(controls).forEach((pair: any) => {
            if (pair[1]?.EN) {
                keys.push(pair[0])
            }
        })

        return (
            keys?.length > 0 &&
            sortedKeys(keys).reduce(
                (prevKeys, key) => ({
                    ...prevKeys,
                    [key]: keyControlValues[key],
                }),
                {}
            )
        )
    }
}

const handleSelectControl = (state = true) => {
    setInputState('ShiftRight', state)
}

const handleStartControl = (state = true) => {
    setInputState('Enter', state)
}

export {
    joystickControlValues,
    keyControlValues,
    handleSelectControl,
    handleStartControl,
    getButtons,
}
