import style from './style.module.scss'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useConferenceSettings } from '../../conference/hooks'
import { State } from '../../store/types'
import { Button, Icon, Dropdown } from '../../stories'

interface SettingsModalSpeakerProps {
    showOptions?: boolean
}

const SettingsModalSpeaker: FC<SettingsModalSpeakerProps> = ({
    showOptions = true,
}) => {
    const { devices } = useSelector((state: State) => state.conference)
    const speakers = (devices.audioOutputDevices || []) as MediaDeviceInfo[]
    const { isSpeakerActive, toggleIsSpeakerActive, onSpeakerChange } =
        useConferenceSettings()

    return (
        <div className={style.mediaOptionContainer}>
            <Button
                variant="round"
                color="secondary"
                data-test="SettingsSpeakerLabel"
                onClick={toggleIsSpeakerActive}
            >
                {isSpeakerActive ? (
                    <Icon name="volumeUp" />
                ) : (
                    <Icon name="volumeOff" data-test="SettingsSpeakerIconOff" />
                )}
            </Button>
            {showOptions && (
                <Dropdown
                    className={style.mediaOptions}
                    data-test="SettingsSpeakerControl"
                    selectedItem={devices.currentAudioOutputDevice?.label || ''}
                    disabled={
                        !devices.audioOutputDevices ||
                        !devices.audioOutputDevices.length
                    }
                    onSelect={(id) => {
                        const selectedDevice = speakers.find(
                            (speaker) => speaker.deviceId === id
                        )
                        onSpeakerChange(selectedDevice)
                    }}
                    data={speakers.map((d: MediaDeviceInfo) => ({
                        id: d.deviceId,
                        value: d.label,
                    }))}
                    position="relative"
                />
            )}
        </div>
    )
}

export default SettingsModalSpeaker
