import style from '../styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Google from '../AuthGoogle'
import Password from './Password'
import Facebook from '../AuthFacebook'
import { useAlert } from '../useAlert'
import { useQuery } from '../../../utils'
import {
    handleFacebookLogin,
    handleGoogleLogin,
} from '../../../store/auth/services'
import AlternativeSignUp from '../AlternativeSignUp'

const SignIn = () => {
    const { t } = useTranslation()
    const urlParams = useQuery()
    useAlert()

    return (
        <div>
            <div className={style.signOptions}>
                <Facebook
                    label={t('SignInFacebook')}
                    onClick={handleFacebookLogin}
                />
                <Google label={t('SignInGoogle')} onClick={handleGoogleLogin} />
            </div>
            <div className={style.separationLineContainer}>
                <div className={style.separationLineText}>
                    {t('ContinueWith')}
                </div>
            </div>
            <Password />
            {!urlParams.has('emailConfirmed') && (
                <div className={style.altFooterWrapper}>
                    <AlternativeSignUp />
                </div>
            )}
        </div>
    )
}

export default SignIn
