import React, { FC, ReactNode, useState } from 'react'
import cls from 'classnames'
import style from './style.module.scss'
import { Icon } from '../Icon'

interface Item {
    value: string
    icon?: string
    id: string | number
}
interface DropdownProps {
    data?: Item[]
    show?: boolean
    onSelect?: (param) => void
    selectedItem?: string | null
    fillColorIcon?: string
    selectedItemIcon?: string
    children?: ReactNode
    disabled?: boolean
    className?: string
    onToggle?: () => void
    position?: 'relative' | 'absolute'
    /** max height of the dropdown list */
    dropdownMaxHeight?: number
    [rootHtmlAttributes: string]: any
}

const Dropdown: FC<DropdownProps> & { Body: FC } = ({
    data = [],
    show = false,
    selectedItem = 'select',
    selectedItemIcon = '',
    fillColorIcon = '#9ca1a5',
    onSelect,
    disabled,
    dropdownMaxHeight,
    children,
    className,
    onToggle,
    position = 'absolute',
    ...rootHtmlAttributes
}) => {
    const [isVisible, setIsvisible] = useState(false)

    const handleToggle = () => setIsvisible((prev) => !prev)

    const { container, containerShow, selectedListItem } = style
    return (
        <div
            className={cls(
                container,
                (onToggle ? show : isVisible) && containerShow,
                className
            )}
            {...rootHtmlAttributes}
        >
            <button
                className={selectedListItem}
                onClick={onToggle ? onToggle : handleToggle}
                disabled={disabled}
            >
                <p>
                    {selectedItemIcon && (
                        <Icon fill={fillColorIcon} name={selectedItemIcon} />
                    )}
                    {selectedItem}
                </p>
                <Icon name="arrowDown" />
            </button>

            {(onToggle ? show : isVisible) &&
                (data?.length > 0 ? (
                    <div
                        className={cls(style.listContainer, style[position])}
                        style={{ height: dropdownMaxHeight }}
                    >
                        {data?.map((listItem) => {
                            return (
                                <button
                                    key={listItem.id}
                                    className="dropdown-item"
                                    onClick={() => {
                                        onSelect(listItem.id)
                                        onToggle ? onToggle() : handleToggle()
                                    }}
                                >
                                    {listItem.icon && (
                                        <Icon
                                            fill={fillColorIcon}
                                            name={listItem.icon}
                                        />
                                    )}
                                    {listItem.value}
                                </button>
                            )
                        })}
                    </div>
                ) : (
                    children
                ))}
        </div>
    )
}

const Body = (props: { children?: ReactNode }) => (
    <div className={style.listContainer}>{props.children}</div>
)
Dropdown.Body = Body

export default Dropdown
