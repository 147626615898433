import React, { forwardRef, useEffect, useState, FC } from 'react'
import cx from 'classnames'
import './Item.scss'
import { Game } from '../../../state/starfox'
import { Card } from '../../../stories'

// Note: remove BYOG Item if it is not necessary

const consoleNameToText = {
    NES: 'NES',
    'Game Boy': 'Game boy',
    'GameBoy Advance': 'Game Boy Advance',
    Genesis: 'Genesis',
    'Master System': 'Master System',
    SNES: 'Super NES',
}

interface GameItemI {
    game: Game
    handleGameSelect: (gameId: string) => void
}

const GameItem: FC<GameItemI> = ({ game, handleGameSelect }) => {
    const [gameUrl, setGameUrl] = useState('')
    const [fallbackUrl, setFallbackUrl] = useState('')

    useEffect(() => {
        if (game?.id) {
            setGameUrl(
                'https://fassets.piepacker.com/thumbnails/' +
                    encodeURIComponent(game.id) +
                    `.webp?organizationId=942630971205`
            )
            setFallbackUrl(
                'https://fassets.piepacker.com/thumbnails/' +
                    encodeURIComponent(game.id) +
                    `.jpg?organizationId=942630971205`
            )
        }
    }, [game, fallbackUrl])

    return (
        <div
            onClick={() => handleGameSelect(game.id)}
            className="item"
            data-test="SwitchGameCategoriesItem"
        >
            <Card
                size="medium"
                alt="SwitchGameCategoriesItem"
                src={gameUrl}
                fallbackSrc={fallbackUrl}
            >
                <Card.Header>{game.id}</Card.Header>
            </Card>
        </div>
    )
}

const BYOGItem = forwardRef(
    (props: { game: Game }, ref: React.MutableRefObject<any>) => {
        const { game } = props
        const baseURLBYOG =
            'https://fassets.piepacker.com/thumbnails/' +
            encodeURIComponent(game.System)
        return (
            <div
                ref={ref}
                className={cx('byogItem', 'byogItemContainer', {
                    NES: game.System === 'NES',
                    GameBoy: game.System === 'Game Boy',
                    GameBoyAdvance: game.System === 'GameBoy Advance',
                    Genesis: game.System === 'Genesis',
                    MasterSystem: game.System === 'Master System',
                    SNES: game.System === 'SNES',
                })}
            >
                <div className="byogItemText">
                    {consoleNameToText[game.System]}
                </div>
                <img
                    className="byogItemImage"
                    src={baseURLBYOG + '.png'}
                    alt={game.System}
                />
            </div>
        )
    }
)

GameItem.displayName = 'SwitchGameItem'
BYOGItem.displayName = 'SwitchGameBYOGItem'

export default { GameItem, BYOGItem }
