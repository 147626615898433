// This stuff is a dirty antipattern
// But it is so handy yeah!
// Maybe we should use ForwardRef and useImperativeHandle hook to get rid of this

const MaskGlobals = {
    threeRenderer: null,
    threeCamera: null,
    threeScene: null,

    // objects:
    threeObject3D: null,

    // features:
    physics: null,
    autobones: null,
    animation: null,
    torso: null,

    // animation:
    threeClock: null,
    threeAnimationMixer: null,

    // postprocessing:
    threeEffectComposer: null,
    threeOutlineEffect: null,
}

export default MaskGlobals
