import { useMemo, useReducer } from 'react'
import { Game } from '../../../state/starfox'
import { normalizeRemoveDiacritic, removeDuplicates } from '../../../utils'
import {
    TNumberOfPlayers,
    TSearchFilterSortState,
    TSortBy,
    TUseSearchFilterSortAction,
} from '../types'

const initialState: TSearchFilterSortState = {
    search: '',
    typeOfGame: '',
    numberOfPlayers: 0,
    sortBy: { kind: '', order: '' },
}

const sortGamesBy =
    ({ kind, order }: TSortBy) =>
    (gameA: Game, gameB: Game): number => {
        switch (kind) {
            case 'Alphabetically': {
                const { DisplayName: nameA } = gameA
                const { DisplayName: nameB } = gameB
                if (order === 'asc') {
                    return nameA.localeCompare(nameB)
                }
                return nameB.localeCompare(nameA)
            }
            case 'ReleaseYear': {
                const { ReleaseYear: yearA } = gameA
                const { ReleaseYear: yearB } = gameB
                if (order === 'asc') {
                    return yearA - yearB
                }
                return yearB - yearA
            }
        }
    }

function reducer(
    state: TSearchFilterSortState,
    action: TUseSearchFilterSortAction
): TSearchFilterSortState {
    switch (action.type) {
        case 'updateSearch':
            return { ...state, search: action.payload.search }
        case 'updateTypeOfGame':
            return { ...state, typeOfGame: action.payload.typeOfGame }
        case 'updateNumberOfPlayers':
            return {
                ...state,
                numberOfPlayers: action.payload.numberOfPlayers,
            }
        case 'updateSortBy':
            return { ...state, sortBy: action.payload.sortBy }
    }
}

//TODO: remove unnecessary dispatch events when the native dropdowns are gone on the desktop

const useSearchFilterSort = (gamesList: ReadonlyArray<Game>) => {
    const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState)
    const { search, typeOfGame, numberOfPlayers, sortBy } = state
    const noPrepping =
        search === initialState.search &&
        typeOfGame === initialState.typeOfGame &&
        numberOfPlayers === initialState.numberOfPlayers &&
        sortBy.kind === initialState.sortBy.kind

    const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: 'updateSearch',
            payload: { search: evt.target.value },
        })

    const handleSearchItemChange = (item) =>
        dispatch({
            type: 'updateSearch',
            payload: { search: item },
        })

    const handleTypeOfGameChange = (
        evt: React.ChangeEvent<HTMLSelectElement>
    ) => {
        dispatch({
            type: 'updateTypeOfGame',
            payload: { typeOfGame: evt.target.value },
        })
    }

    const handleTagChange = (tag) => {
        dispatch({
            type: 'updateTypeOfGame',
            payload: { typeOfGame: tag },
        })
    }

    const handleNumberOfPlayersChange = (
        evt: React.ChangeEvent<HTMLSelectElement>
    ) => {
        // TODO: consider more elegant solution
        const nextValue = Number(evt.target.value) as TNumberOfPlayers
        dispatch({
            type: 'updateNumberOfPlayers',
            payload: { numberOfPlayers: nextValue },
        })
    }

    const handleNOPChange = (nop) => {
        dispatch({
            type: 'updateNumberOfPlayers',
            payload: { numberOfPlayers: nop },
        })
    }
    const handleSortByChange = ({ kind, order }: TSortBy) => {
        dispatch({
            type: 'updateSortBy',
            payload: { sortBy: { kind, order } },
        })
    }
    const handlers = {
        handleSearchChange,
        handleTypeOfGameChange,
        handleNumberOfPlayersChange,
        handleSortByChange,
        handleTagChange,
        handleNOPChange,
        handleSearchItemChange,
    }
    /* TODO: get all requirements for type/number/sorting */
    const gamesPrepped = useMemo(() => {
        if (noPrepping) return gamesList
        let preppedGamesList = [...gamesList]
        if (typeOfGame !== initialState.typeOfGame) {
            preppedGamesList = preppedGamesList.filter((g) =>
                g.Tags.includes(typeOfGame)
            )
        }
        if (numberOfPlayers !== initialState.numberOfPlayers) {
            preppedGamesList = preppedGamesList.filter(
                (g) => g.Players >= numberOfPlayers
            )
        }
        const normalizedSearchString =
            normalizeRemoveDiacritic(search).toLocaleLowerCase()
        if (search !== initialState.search) {
            /*
                search in order of priority (see SearchPage for reference) going from display name
                down to publisher. tag is excluded, since in this implementation it has its own
                dropdown to filter.
                aggregated results then flattened in order of priority
            */
            preppedGamesList = preppedGamesList.reduce(
                (acc, game, index, arr) => {
                    const { DisplayName, System, ReleaseYear, Publisher } = game
                    if (
                        normalizeRemoveDiacritic(DisplayName)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[0].push(game)
                    }
                    if (
                        normalizeRemoveDiacritic(System)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[1].push(game)
                    } else if (
                        String(ReleaseYear).includes(normalizedSearchString)
                    ) {
                        acc[2].push(game)
                    } else if (
                        normalizeRemoveDiacritic(Publisher)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[3].push(game)
                    }
                    /* fold list on last element removing games that could
                    possibly meet more than one criteria */
                    if (index === arr.length - 1) {
                        return removeDuplicates(acc.flat())
                    }

                    return acc
                },
                [[], [], [], []]
            )
        }
        if (sortBy.kind !== initialState.sortBy.kind) {
            const { kind, order } = sortBy
            preppedGamesList.sort(sortGamesBy({ kind, order }))
        }
        return preppedGamesList
    }, [search, typeOfGame, numberOfPlayers, sortBy, gamesList, noPrepping])

    return {
        state,
        gamesList: gamesPrepped,
        isListModified: !noPrepping,
        handlers: handlers as typeof handlers,
    }
}

export default useSearchFilterSort
