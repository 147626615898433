import React, { FC, useMemo } from 'react'

import { Game } from '../../state/starfox'

import GameListSection from './components/GameListSection'

import { Grid } from '@material-ui/core'
import {
    useGamesCategories,
    useRecentlyPlayedGames,
} from '../../utils/useGamesList'
import SearchResultItem from './components/SearchResultItem'

import './GameList.scss'
import GameNotFound from './components/NotFound'

type TGamesList = FC<{
    gamesList: ReadonlyArray<Game>
    initialGamesList?: ReadonlyArray<Game>
    handleGameSelect: (gameId: string) => void
}>

const GamesList: TGamesList = ({ gamesList, handleGameSelect }) => {
    const recentlyPlayedGames = useRecentlyPlayedGames(gamesList)
    const gameCategories: string[] = useGamesCategories(recentlyPlayedGames)
    const grouped: { [key: string]: Game[] } = useMemo(
        () =>
            gameCategories.reduce(
                (acc, cat) => ({
                    ...acc,
                    [cat]: gamesList.filter((game) =>
                        game.Categories?.includes(cat)
                    ),
                }),
                {}
            ),
        [gameCategories, gamesList]
    )
    return (
        <Grid container>
            {Object.entries(grouped).map(
                ([category, games]) =>
                    games.length > 0 && (
                        <Grid item xs={12}>
                            <GameListSection
                                key={category}
                                handleGameSelect={handleGameSelect}
                                category={category}
                                gamesInCategory={games}
                            />
                        </Grid>
                    )
            )}
        </Grid>
    )
}

const GridView: TGamesList = ({
    gamesList,
    handleGameSelect /* initialGamesList */,
}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {gamesList.length < 1 ? (
                    <GameNotFound />
                ) : (
                    <div className="search-result-list">
                        {gamesList.map((game) => (
                            <SearchResultItem
                                key={game.id}
                                game={game}
                                handleGameSelect={handleGameSelect}
                            />
                        ))}
                    </div>
                )}
            </Grid>
        </Grid>
    )
}

export default {
    Categorized: GamesList,
    Uncategorized: GridView,
}
