import cls from 'classnames'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import styles from './styles.module.scss'
import { getUserAvatar } from '../../../UserSettings/state/selectors'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../../../../stories'
import { setResetFilters } from '../../../../store/home/homeV2'

const homeRoute = '/'
const settingsRoute = '/settings/UserProfile'

const Mobile: FC = () => {
    const avatar = useSelector(getUserAvatar)
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    return (
        <footer className={styles.footer}>
            <ul className={styles.footerMenu}>
                <li>
                    <Link
                        to="/"
                        className={cls(styles.footerMenuItem, {
                            [styles.selected]: pathname === homeRoute,
                        })}
                        onClick={() => dispatch(setResetFilters())}
                    >
                        <Icon name={'home'} />
                    </Link>
                </li>
                <li>
                    <Link
                        to="/settings/UserProfile"
                        className={cls(styles.footerMenuItem, {
                            [styles.selected]: pathname === settingsRoute,
                        })}
                    >
                        {avatar ? (
                            <Avatar
                                src={avatar}
                                classes={{ root: styles.menuItemAvatar }}
                            />
                        ) : (
                            <Icon name={'person'} />
                        )}
                    </Link>
                </li>
            </ul>
        </footer>
    )
}

export default Mobile
