import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmailAuthentication from './EmailAuthentication'

const PrivacySafety: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <p className={styles.pageTitle}>{t('PrivacySafety')}</p>
            <EmailAuthentication />
        </>
    )
}

export default PrivacySafety
