import * as Axios from 'axios'

import { authorizedConfig } from '../firebase'
import { sessionStarfoxURL } from '../utils'
import { logAxiosErrorResponse } from '../utils/http'

export interface WatchRTCConfiguration {
    rtcApiKey: string
    rtcRoomId: string
    rtcPeerId: string
    rtcTags: string[]
}

export interface PiepackerRTCConfiguration extends RTCConfiguration {
    watchrtc?: WatchRTCConfiguration
}

export async function FetchStunTurn(): Promise<PiepackerRTCConfiguration> {
    try {
        const cfg = await authorizedConfig()
        const resp = await Axios.default.post(
            `${sessionStarfoxURL()}/twilio/nts`,
            undefined,
            cfg
        )
        const rtcConfig: PiepackerRTCConfiguration = {
            iceServers: resp.data['ice_servers'],
        }
        return rtcConfig
    } catch (error) {
        logAxiosErrorResponse(error)
        return {}
    }
}
