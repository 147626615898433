import React, { useEffect, useRef } from 'react'

import { EffectComposer } from '@react-three/postprocessing'
import PostprocessingOutline from './PostprocessingOutline'

const Postprocessing = (props) => {
    const effectComposerRef = useRef()
    props.globals.threeEffectComposer = null

    useEffect(() => {
        props.globals.threeEffectComposer = effectComposerRef.current
    })

    if (!props.maskPostprocessing.outline) {
        return null
    }

    return (
        <EffectComposer ref={effectComposerRef}>
            {props.maskPostprocessing.outline && (
                <PostprocessingOutline
                    globals={props.globals}
                    resolution={props.resolution}
                    outlineThickness={
                        props.maskPostprocessing.outline.thickness
                    }
                />
            )}
        </EffectComposer>
    )
}

export default Postprocessing
