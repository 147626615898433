import style from './style.module.scss'
import React, { FC } from 'react'
import { VideoFeed, VideoPreview } from '../Video'

interface SettingsModalVideoPreview {
    preview: boolean
}

const SettingsModalVideoPreview: FC<SettingsModalVideoPreview> = ({
    preview,
}) => {
    return (
        <div className={style.videoPreview}>
            {preview ? (
                <VideoPreview className="video" />
            ) : (
                <VideoFeed className="video" />
            )}
        </div>
    )
}

export default SettingsModalVideoPreview
