import { useEffect, useRef } from 'react'
import { PubSubEvent, globalPubSub } from '../../../event/event'
import * as Socket from '../../../network/socket'
import { useIsTabVisible } from '../../../utils'

/**
 * Gathers the information about the call participant tab visibility.
 * Based on this information, we blur the participant videos when they switch the Browser's tab.
 */
export const useTabVisibility = (
    userSessionID,
    usersTabInfo,
    setUsersTabInfo
) => {
    const tabVisibilityChangedSub = useRef(null)
    const isTabVisible = useIsTabVisible()

    useEffect(() => {
        if (!userSessionID) return
        Socket.tabVisibilityChanged(isTabVisible, userSessionID)
    }, [isTabVisible, userSessionID])

    useEffect(() => {
        tabVisibilityChangedSub.current = globalPubSub.sub(
            PubSubEvent.TAB_VISIBILITY_CHANGED,
            (data) => {
                const { userId, isVisible } = data
                const index = usersTabInfo.findIndex((u) => u.userId === userId)
                if (index > -1) {
                    const newArray = [...usersTabInfo]
                    newArray[index] = { ...newArray[index], isVisible }
                    setUsersTabInfo(newArray)
                } else {
                    setUsersTabInfo([...usersTabInfo, { userId, isVisible }])
                }
            },
            0
        )

        return () => {
            tabVisibilityChangedSub.current.unsub()
        }
    }, [usersTabInfo, setUsersTabInfo])
}
