import { UserExtraFromFirebase, UserRoleType } from '../state/starfox'

export const FREE_SAVE_LIMIT = 1
export const PREMIUM_SAVE_LIMIT = 100

export const roleValue = (role: UserRoleType): number => {
    switch (role) {
        case UserRoleType.Guest:
            return 1
        case UserRoleType.Regular:
            return 2
        case UserRoleType.Plus:
            return 3
        case UserRoleType.Piepacker:
            return 4
        default:
            return 0
    }
}

export const isPremium = (user: UserExtraFromFirebase): boolean => {
    if (roleValue(user?.Role) >= roleValue(UserRoleType.Plus)) return true
    return false
}
