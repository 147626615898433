import React, { FC, ReactNode, useState } from 'react'
import { Button } from '../Button'
import { CountdownCircleTimer } from '../CountdownCircleTimer'
import { Icon } from '../Icon'
import style from './style.module.scss'
import cls from 'classnames'

interface CountdownAlertProps {
    content: string | ReactNode
    duration?: number
    autoClose?: boolean
    onApprove?: () => void
    onClose?: () => void
    onCompleted?: () => void
    position?: 'top' | 'bottom'
}

const CountdownAlert: FC<CountdownAlertProps> = ({
    content = '',
    duration = 10,
    autoClose = false,
    onApprove,
    onClose,
    onCompleted,
    position = 'top',
}) => {
    const [show, setShow] = useState<boolean>(true)
    const [cleanUp, setCleanUp] = useState<boolean>(false)

    if (!show) {
        return null
    }

    const handleOnComplete = () => {
        onCompleted && onCompleted()
        autoClose && setShow(false)
        return false
    }

    const handleOnApprove = () => {
        onApprove && onApprove()
        setCleanUp(true)
        setShow(false)
    }

    const handleOnClose = () => {
        onClose && onClose()
        setCleanUp(true)
        setShow(false)
    }

    return (
        <>
            <div className={cls(style.container, style[position])}>
                <div className={style.content}>{content}</div>
                <div className={style.buttons}>
                    <Button
                        variant="roundWithButton"
                        onClick={() => handleOnApprove()}
                    >
                        <Icon name="done" />
                    </Button>

                    <CountdownCircleTimer
                        size={32}
                        strokeWidth={4}
                        duration={duration}
                        onComplete={handleOnComplete}
                        onClick={handleOnClose}
                        cleanUp={cleanUp}
                    >
                        <Icon name="close" />
                    </CountdownCircleTimer>
                </div>
            </div>
        </>
    )
}

export default CountdownAlert
