import { AxiosError } from 'axios'
import { piepie } from './piepie'

export function axiosErrorResponse(error: AxiosError): string {
    // ugh... type guards are ugly.
    if (!error) {
        return `should be axios error is not axios error: ${error}`
    }
    const isAxiosError =
        (error as AxiosError).response || (error as AxiosError).request
    if (!isAxiosError) {
        return `should be axios error is not axios error: ${error}`
    }
    let errorMsg = 'axios error:'
    if (error.response) {
        const r = error.response
        errorMsg = `${errorMsg} status=${r.status}, statusText='${r.statusText}', data='${r.data}'`
    }
    if (error.request) {
        const req = error.request as XMLHttpRequest
        errorMsg = `${errorMsg}, url=${req.responseURL}`
    }
    return errorMsg
}

export function logAxiosErrorResponse(error: AxiosError) {
    piepie.error(axiosErrorResponse(error))
}
