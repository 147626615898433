import { useElapsedTime } from '../../../utils'

interface Props {
    duration: number
    size: number
    strokeWidth: number
    startAt: number
    onComplete?: () => void
    cleanUp?: boolean
}

export const getPathProps = (size: number, strokeWidth: number) => {
    const halfSize = size / 2
    const halfStrokeWith = strokeWidth / 2
    const arcRadius = halfSize - halfStrokeWith
    const arcDiameter = 2 * arcRadius
    const rotationIndicator = '1,0'

    const pathLength = 2 * Math.PI * arcRadius
    const path = `m ${halfSize},${halfStrokeWith} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,${arcDiameter} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,-${arcDiameter}`

    return { path, pathLength }
}

const linearEase = (
    time: number,
    start: number,
    goal: number,
    duration: number
) => {
    if (duration === 0) {
        return start
    }

    const currentTime = time / duration
    return start + goal * currentTime
}

export const useCountdown = (props: Props) => {
    const { duration, size, strokeWidth, startAt, onComplete, cleanUp } = props
    const { elapsedTime } = useElapsedTime({
        duration,
        startAt,
        onComplete,
        cleanUp,
    })

    const maxStrokeWidth = Math.max(strokeWidth, 0)
    const { path, pathLength } = getPathProps(size, maxStrokeWidth)

    return {
        path,
        pathLength,
        size,
        strokeDashoffset: linearEase(elapsedTime, 0, pathLength, duration),
        strokeWidth,
        elapsedTime,
    }
}
