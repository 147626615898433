import { isEnvDev, isEnvProd, isEnvStaging, isPreview } from '../../utils'

export const byog_config = {
    cores: {
        genesis: 'blastem_libretro',
        snes: 'mesens_libretro',
        gba: 'mgba_libretro',
        nes: 'mesen_libretro',
        n64: 'mupen64plus_next_libretro',
        gb: 'sameboy_libretro',
        sms: 'gearsystem_libretro',
        pico8: 'retro8_libretro',
        psx: 'swanstation_libretro',
        lutro: 'lutro_libretro',
    },
    acceptableTypes: {
        genesis: ['.zip', '.7z', '.rar', '.tar.zst', '.md', '.bin'],
        snes: ['.zip', '.7z', '.rar', '.tar.zst', '.smc', '.sfc'],
        nes: ['.zip', '.7z', '.rar', '.tar.zst', '.nes'],
        gba: ['.zip', '.7z', '.rar', '.tar.zst', '.gba'],
        n64: ['.zip', '.7z', '.rar', '.tar.zst', '.n64', '.v64', '.z64'],
        gb: ['.zip', '.7z', '.rar', '.tar.zst', '.gb', '.gbc'],
        sms: ['.zip', '.7z', '.rar', '.tar.zst', '.sms'],
        pico8: ['.p8', '.png'],
        psx: [
            '.zip',
            '.7z',
            '.rar',
            '.tar.zst',
            '.bin',
            '.iso',
            '.img',
            '.pbp',
        ],
        lutro: ['.lutro', '.lua'],
    },
    session: {
        genesis: 'Sega Genesis',
        snes: 'Super Nintendo Entertainment System',
        nes: 'Nintendo Entertainment System',
        gba: 'Nintendo GameBoy Advance',
        n64: 'Nintendo 64',
        gb: 'Nintendo Game Boy',
        sms: 'Sega Master System',
        pico8: 'PICO-8',
        psx: 'Sony PlayStation',
        lutro: 'Lutro',
    },
    piereader: {
        genesis: 'M'.charCodeAt(0),
        snes: 'N'.charCodeAt(0),
        nes: 'N'.charCodeAt(0),
        gba: 'N'.charCodeAt(0),
        n64: 'N'.charCodeAt(0),
        gb: 'N'.charCodeAt(0),
        sms: 'N'.charCodeAt(0),
        pico8: 'N'.charCodeAt(0),
        psx: 'N'.charCodeAt(0),
        lutro: 'N'.charCodeAt(0),
    },
    maxsize: {
        genesis: 20 * 1024 * 1024, // The heaviest game on Genesis is between 5MB and 8MB, so 10MB is to be broad
        snes: 14 * 1024 * 1024, // The heaviest game on SNES is around 6MB, so 14MB is to be broad
        nes: 4 * 1024 * 1024, // The heaviest game on NES is around 1MB, so 4MB is to be broad
        gba: 45 * 1024 * 1024, // The heaviest game on GBA is around 32MB, so 45MB is to be broad
        n64: 64 * 1024 * 1024,
        gb: 4 * 1024 * 1024,
        sms: 4 * 1024 * 1024,
        pico8: 1 * 1024 * 1024,
        psx: 4 * 800 * 1024 * 1024, // Biggest games are 4 cdrom !
        lutro: 200 * 1024 * 1024,
    },
    whitelisted: {
        genesis: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        snes: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        nes: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        gba: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        gb: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        n64: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        sms: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        pico8: isEnvDev || isPreview || isEnvStaging,
        psx: isEnvDev || isPreview || isEnvProd || isEnvStaging,
        lutro: isEnvDev || isPreview || isEnvStaging,
    },
}
