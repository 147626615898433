import React, {
    Dispatch,
    SetStateAction,
    useRef,
    useEffect,
    useState,
    useCallback,
    FC,
} from 'react'
import style from './style.module.scss'
import cls from 'classnames'
import { AsyncScheduler } from 'amazon-chime-sdk-js'
import { useDispatch, useSelector } from 'react-redux'
import GameLogo from '../../components/GameLogo'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@material-ui/core'
import {
    getSession,
    getUserFromStarfox,
    setSessionSettings,
} from '../../firebase'
import { useHistory } from 'react-router-dom'
import { useChime, useConferenceSettings } from '../../conference/hooks'
import { setIsLoading } from '../../store/conference/actions'
import {
    clearSession,
    setGameLobbyError,
    setSession,
} from '../../store/session/actions'
import { GameLobbyErrorType, AlertType } from '../../state/alert'
import { Alert, Button } from '../../stories'
import { clearSessionState, joinSession } from '../../starfox/starfox'

import {
    UserInterfaceFromFirebase,
    UserSessionFromFirebase,
    NicknameColorType,
    SessionMoodType,
} from '../../state/starfox'
import GameLobbyLoading from './GameLobbyLoading'
import { piepie, getTransactionEmailServiceHostname } from '../../utils'
import { State } from '../../store/types'
import { LocalStorage } from '../../state/storage'
import store from '../../store'
import { RoomSettings, RoomAccess, ISessionSettings } from '../GameSettings'
import screenSlice from '../../store/screen/screen'
import * as Axios from 'axios'
import { logAxiosErrorResponse } from '../../utils/http'
import GameLobbyVideo from './GameLobbyVideo'
import { isMobile } from 'react-device-detect'
import { setMannequinMode } from '../../store/mask/actions'
import { getGameName } from '../../pages/BYOG/utils'

export interface GameLobbyInfoProps {
    lobbyLoading: boolean
    enterClickable: boolean
    session: UserSessionFromFirebase[]
    setDisplayGameLobby: Dispatch<SetStateAction<boolean>>
    setSettingsOpen: Dispatch<SetStateAction<boolean>>
    onSettingsChange: (settings) => void
    showAvatar: boolean
}

const GameLobbyInfo: FC<GameLobbyInfoProps> = ({
    lobbyLoading,
    enterClickable,
    session,
    setDisplayGameLobby,
    setSettingsOpen,
    onSettingsChange,
    showAvatar,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const chime = useChime()
    const dispatch = useDispatch()
    const enterClick = useRef(false)
    const playerExtraData = useSelector((state: State) => state.session.players)
    const sessionID = useSelector((state: State) => state.session.sessionID)
    const isHost = useSelector((state: State) => state.session.isHost)
    const lobbyError = useSelector((state: State) => state.session.lobbyError)
    const game = useSelector((state: State) => state.session.game)
    const originalFileName = useSelector(
        (state: State) => state.session.originalFileName
    )
    const [users, setUsers] = useState<UserInterfaceFromFirebase[]>([])
    const [hasOffensiveWords, setHasOffensiveWords] = useState(false)
    const gameSession = useSelector((state: State) => state.session.session)
    const userSession = useSelector((state: State) => state.session.userSession)
    const { RoomTitle, Language, Description, Access, Mood } = gameSession
    const [settings, setSettings] = useState<ISessionSettings>({
        Mood: Mood || SessionMoodType.Discovery,
        NumberOfPlayers: 8,
        Language: Language || 'en',
        RoomTitle: RoomTitle || getGameName(originalFileName) || '',
        Description: Description || '',
        Access: Access || RoomAccess.PUBLIC,
    })
    const { isCameraActive, toggleIsCameraActive } = useConferenceSettings()

    const refreshSession = useCallback(async () => {
        const session = await getSession(sessionID)
        dispatch(setSession(session))
    }, [dispatch, sessionID])

    const refreshSettings = useCallback(
        async (sessionID: string) => {
            const session = await getSession(sessionID)
            if (!session) return
            dispatch(setSession(session))
            setSettings({
                Mood: session.Mood || SessionMoodType.Discovery || '',
                NumberOfPlayers: session.NumberOfPlayers,
                Language: session.Language || Language || 'en',
                RoomTitle:
                    session.RoomTitle || getGameName(originalFileName) || '',
                Description: session.Description || Description || '',
                Access: session.Access || Access || RoomAccess.PUBLIC,
            })
        },
        [dispatch, Access, Description, Language, originalFileName]
    )

    // populateUsers populates the current users in the game room thanks to the userSessionsIDs
    // it allows us to display the nicknames and the profile pictures
    const populateUsers = useCallback(async () => {
        const users: UserInterfaceFromFirebase[] = []
        for (const [, usession] of Object.entries(session)) {
            const user = await getUserFromStarfox(usession.UID)
            if (!user) continue
            users.push(user)
            const localUser = JSON.parse(JSON.stringify(user))
            localUser.User.PhotoURL =
                'https://fassets.piepacker.com/random/LocalPlayerCameraPlaceholder.png'
            // we add the other local players
            for (let i = 0; i < usession.LocalPlayers; i++) {
                users.push(localUser)
            }
        }
        setUsers(users)
    }, [session, setUsers])

    useEffect(() => {
        if (session) {
            populateUsers()
        }
        if (sessionID) {
            refreshSettings(sessionID)
        }
    }, [session, sessionID, refreshSettings, populateUsers])

    // handleEnterRoomClick will allow the user to enter the game room (or not) after clicking on 'Enter room' button
    const handleEnterRoomClick = useCallback(async () => {
        dispatch(setIsLoading(true))
        analytics.track('Enter Room Pressed')

        try {
            if (settings.RoomTitle.length)
                await Axios.default.post(
                    `${getTransactionEmailServiceHostname()}/bodyguardRoomName`,
                    {
                        text: settings.RoomTitle,
                    }
                )
            if (settings.Description.length)
                await Axios.default.post(
                    `${getTransactionEmailServiceHostname()}/bodyguardRoomDescription`,
                    {
                        text: settings.Description,
                    }
                )
            setHasOffensiveWords(false)
        } catch (error) {
            logAxiosErrorResponse(error)
            setHasOffensiveWords(true)
            dispatch(setIsLoading(false))
            return
        }

        if (isMobile && isCameraActive) toggleIsCameraActive()

        new AsyncScheduler().start(async () => {
            const alreadyJoined = gameSession.UserSessions.includes(
                store.getState().session.userSessionID
            )
            const joined = alreadyJoined ? true : await joinSession()
            if (!joined) {
                return
            }
            try {
                const chimeSessionCreatedTime = localStorage.getItem(
                    LocalStorage.chimeSessionCreatedTime
                )

                if (lobbyError) return

                if (chimeSessionCreatedTime) {
                    const timeout = 1000 * 60 * 4 // 4 min
                    const expired =
                        new Date().getTime() - +chimeSessionCreatedTime <
                        timeout
                            ? false
                            : true
                    if (expired) {
                        dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
                        return
                    }
                }
                chime.addObservers()
                await chime.joinRoom()
                chime.audioVideo.startLocalVideoTile()
                piepie.log(
                    'CHIME: chime.joinRoom and startLocalVideoTile successful'
                )
                if (isHost) {
                    setSessionSettings(sessionID, settings)
                }
                await refreshSession()
                piepie.log('GAME SESSION SETTINGS: added session preference')
            } catch (error) {
                piepie.error('CHIME: chime.joinRoom error', error)
            } finally {
                dispatch(setIsLoading(false))
                analytics.track('Room Created', {
                    Settings: settings,
                    Session_ID: sessionID,
                })
                return
            }
        })

        if (enterClickable && chime.audioVideo && !enterClick.current) {
            enterClick.current = true
            setDisplayGameLobby(false)
            store.dispatch(screenSlice.actions.setStartGame(true))
        }
    }, [
        isCameraActive,
        toggleIsCameraActive,
        chime,
        enterClickable,
        gameSession.UserSessions,
        lobbyError,
        setDisplayGameLobby,
        dispatch,
        settings,
        sessionID,
        refreshSession,
        isHost,
    ])

    const getUserDisplayName = (user) => {
        if (
            !user ||
            (user && !user.User) ||
            (user.User && !user.User.DisplayName)
        ) {
            return t('Guest')
        }

        if (user.User.DisplayName.length > 9) {
            return `${user.User.DisplayName.substring(0, 9)}...`
        }

        return user.User.DisplayName
    }

    const handleSettingChange = (settings) => {
        if (settings.Access === RoomAccess.PUBLIC) {
            dispatch(setMannequinMode(true))
        } else {
            dispatch(setMannequinMode(false))
        }
        setSettings(settings)
    }

    // handleGoHomeClick will redirect to the home page after clicking on the 'Go Home' button
    const handleGoHomeClick = useCallback(() => {
        history.push('/')
    }, [history])

    const reCreateSession = () => {
        if (isMobile && isCameraActive) toggleIsCameraActive()
        dispatch(clearSession())
        clearSessionState()
        // Remove the old session ID from the URL, so the app can generate a new one.
        const urlWithoutQueryString = window.location.href.split('?')[0]
        window.location.href = urlWithoutQueryString
    }

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (
                e.key === 'Enter' &&
                lobbyError !== GameLobbyErrorType.TIMEOUT
            ) {
                handleEnterRoomClick()
            }
        }

        window.addEventListener('keydown', keyDownHandler)

        return () => {
            window.removeEventListener('keydown', keyDownHandler)
        }
    }, [lobbyError, handleEnterRoomClick])

    useEffect(() => {
        onSettingsChange(settings)
    }, [settings, onSettingsChange])

    const isLoading =
        // Enable loading if the button is not clickable or chime didn't load yet
        (!enterClickable || !chime.audioVideo) &&
        // Disable isLoading if we have an error message
        (lobbyError === undefined || lobbyError === null)

    enum GamesThatRequireInstructions {
        WORMS = 'Worms World Party',
        SOCCER = "Sensible Soccer '92/93",
    }

    let saveFilePSXMessage = null

    // Save limitation is limited to non HLE game (BYOG is always HLE)
    if (
        game.System.toLowerCase() === 'playstation' &&
        game.id !== 'Sony PlayStation'
    ) {
        if (
            !(
                game.Options &&
                game.Options['duckstation_HLE___Enable'] === 'true'
            )
        ) {
            saveFilePSXMessage = {
                link: 'SaveFilePSXLink',
                message: 'SaveFilePSXMessage',
            }
        }
    }

    if (game.id === 'Sony PlayStation') {
        saveFilePSXMessage = {
            link: '',
            message: 'MultiCDMessage',
        }
    }

    if (game.id === 'Nintendo GameBoy Advance') {
        saveFilePSXMessage = {
            link: 'SaveFilePSXLink',
            message: 'SaveFileBYOGGBAMessage',
        }
    }

    if (game.id === 'Nintendo 64') {
        saveFilePSXMessage = {
            link: '',
            message: 'WarningN64',
        }
    }

    if (game.id === 'Little Big Adventure 2') {
        saveFilePSXMessage = {
            link: '',
            message: 'WarningLBA2',
        }
    }

    let specialMessage = null

    switch (game.DisplayName) {
        case GamesThatRequireInstructions.WORMS:
            specialMessage = {
                img: 'https://storage.googleapis.com/public-wagashi/random/WormsMask.png',
                link: 'WormsPopupLink',
                message: 'WormsPopupNote',
            }

            break
        case GamesThatRequireInstructions.SOCCER:
            specialMessage = {
                img: false,
                link: 'SensibleSoccerPopupLink',
                message: 'SensibleSoccerPopupNote',
            }

            break
        default:
            break
    }
    return (
        <div className="page-details">
            {game.Competition && (
                <div className="logo-esl-wrapper">
                    <span className="logo-esl-specialevent">
                        {t('SpecialEventLobby')}
                    </span>
                    <div className="logo-esl-ppswb">
                        <span>{t('PiepackerAndLobby')}</span>
                    </div>
                </div>
            )}
            <div data-test="LobbyPageDetails" className="lobby-page-details">
                {(!game.Competition || (!lobbyLoading && !isLoading)) && (
                    <div className="game-logo">
                        <GameLogo logoKind="game-page-logo" gameID={game.id} />
                    </div>
                )}
                {!lobbyLoading && !isLoading && !game.Competition && (
                    <>
                        {!lobbyError && (
                            <Alert
                                className={style.publicRoomLobbyMessage}
                                type={AlertType.Info}
                                message={
                                    settings.Access === RoomAccess.PUBLIC
                                        ? 'PublicRoomMessage'
                                        : 'PrivateRoomMessage'
                                }
                                animate={false}
                            />
                        )}
                        {isMobile && (
                            <div className={style.videoPreviewMobile}>
                                <GameLobbyVideo
                                    setSettingsOpen={setSettingsOpen}
                                    showAvatar={showAvatar}
                                />
                            </div>
                        )}
                        {isHost && !lobbyError && (
                            <RoomSettings
                                roomSettings={settings}
                                onChange={handleSettingChange}
                                offensiveWords={hasOffensiveWords}
                            />
                        )}
                    </>
                )}
                {lobbyLoading || isLoading ? (
                    <GameLobbyLoading
                        ESLMode={game.Competition}
                        gameId={game.id}
                    />
                ) : (
                    <>
                        {
                            // We look at each user session (and not each user) and display it's corresponding user if not self.
                            gameSession.UserSessions &&
                                gameSession.UserSessions.length !== 0 && (
                                    <div className="profile-pictures">
                                        {gameSession.UserSessions.map(
                                            (
                                                userSessionID: string,
                                                index: number
                                            ) => {
                                                // get user from uid from game session
                                                const uid =
                                                    gameSession.Uids[index]
                                                const filtered = users.filter(
                                                    (u) => u.ID === uid
                                                )
                                                if (filtered.length === 0) {
                                                    return null
                                                }
                                                const user = filtered[0]
                                                if (
                                                    userSessionID ===
                                                    userSession.ID
                                                ) {
                                                    return null
                                                }
                                                const avatar = user
                                                    ? user?.User?.PhotoURL
                                                    : 'https://fassets.piepacker.com/backgrounds/original/user.png'

                                                return (
                                                    <div
                                                        className="profile"
                                                        // we cannot use user.ID as an unique key here because anonymous users will all have the same ID
                                                        key={index}
                                                    >
                                                        <Avatar src={avatar} />
                                                        <span
                                                            className={cls(
                                                                'profile-name',
                                                                playerExtraData[
                                                                    user.ID
                                                                ]
                                                                    ?.NicknameColor ===
                                                                    NicknameColorType.Gold &&
                                                                    'goldLetters'
                                                            )}
                                                        >
                                                            {getUserDisplayName(
                                                                user
                                                            )}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                )
                        }
                        {lobbyError && (
                            <div className="lobby-error">
                                <Alert
                                    type={AlertType.Warning}
                                    message={lobbyError}
                                    animate={false}
                                />
                            </div>
                        )}
                        {!lobbyError && saveFilePSXMessage && (
                            <div className="lobby-error">
                                <Alert
                                    type={AlertType.Info}
                                    message={saveFilePSXMessage.message}
                                    seeMoreLink={saveFilePSXMessage.link}
                                    animate={false}
                                />
                            </div>
                        )}
                        {!lobbyError && specialMessage && (
                            <div className="lobby-error">
                                <Alert
                                    type={AlertType.Info}
                                    message={specialMessage.message}
                                    seeMoreLink={specialMessage.link}
                                    animate={false}
                                />
                            </div>
                        )}
                        {!lobbyError && isMobile && (
                            <div className="lobby-error">
                                <Alert
                                    type={AlertType.Warning}
                                    message="LobbyMobileCameraOffWarning"
                                    animate={false}
                                />
                            </div>
                        )}

                        <div className="lobby-btn">
                            {lobbyError !== GameLobbyErrorType.TIMEOUT &&
                                lobbyError !== GameLobbyErrorType.USER_BANNED &&
                                lobbyError !== GameLobbyErrorType.ROOM_FULL &&
                                lobbyError !==
                                    GameLobbyErrorType.ROOM_FAILURE &&
                                lobbyError !==
                                    GameLobbyErrorType.CREATE_ROOM_FORBIDDEN &&
                                !isLoading && (
                                    <Button
                                        className="btn-w-100 btn-small enter-room"
                                        onClick={handleEnterRoomClick}
                                        variant="ellipticalLarge"
                                        id="enterroombtn"
                                        data-js-enter-room
                                        data-test="EnterRoom"
                                    >
                                        {t('EnterRoom')}
                                    </Button>
                                )}
                            {lobbyError === GameLobbyErrorType.TIMEOUT && (
                                <Button
                                    variant="ellipticalLarge"
                                    className="btn-w-100 btn-small enter-room"
                                    onClick={reCreateSession}
                                    data-test="ReCreateSession"
                                >
                                    {t('CreateNewRoom')}
                                </Button>
                            )}
                            {lobbyError && (
                                <Button
                                    className="btn-w-100 btn-small go-home"
                                    variant="ellipticalOutlinedLarge"
                                    onClick={handleGoHomeClick}
                                    data-js-go-home
                                >
                                    {t('GoHome')}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default GameLobbyInfo
