import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import analytics from '../analytics'
import store from '../store'

export interface InviteModalsProps {
    open: boolean
    shareLink: string
    onClose: () => void
}

export default function InviteModal(props: InviteModalsProps) {
    const { open, shareLink, onClose } = props
    const { t } = useTranslation()
    const [isCopiedHintVisible, setCopiedHintVisibility] = useState(false)
    return (
        // @ts-ignore Type definitions for Modal are incomplete
        <Modal onHide={onClose} show={open} centered enforceFocus={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('InviteFriends')}</Modal.Title>
            </Modal.Header>

            <Form.Group>
                <p className="secondary">{t('InviteFriendsHint')}</p>
                <Form.Control
                    className="clickable-control"
                    onClick={async (event) => {
                        event.target.select()
                        document.execCommand('copy')
                        setCopiedHintVisibility(true)
                        setTimeout(() => {
                            setCopiedHintVisibility(false)
                        }, 1500)
                    }}
                    value={shareLink}
                />
                <span
                    className={'hint' + (isCopiedHintVisible ? ' visible' : '')}
                >
                    {t('Copied')}
                </span>
            </Form.Group>

            <Button
                className="btn-large"
                block
                onClick={async (event) => {
                    event.preventDefault()
                    analytics.track('InviteFriend', {
                        game: store.getState().session.gameName,
                    })
                    try {
                        // The clipboard is not always available
                        // we should consider adding a detection
                        // with fallback for browsers w/o capability
                        await (navigator as any).clipboard.writeText(shareLink)
                    } finally {
                        onClose()
                    }
                }}
            >
                {t('CopyLink')}
            </Button>
        </Modal>
    )
}
