import style from './style.module.scss'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useConferenceSettings } from '../../conference/hooks'
import { State } from '../../store/types'
import { Button, Icon, Dropdown } from '../../stories'

interface SettingsModalCameraProps {
    showOptions?: boolean
}

const SettingsModalCamera: FC<SettingsModalCameraProps> = ({
    showOptions = true,
}) => {
    const { devices, isCameraAvailable, isCameraGranted } = useSelector(
        (state: State) => state.conference
    )
    const cameras = (devices.videoInputDevices || []) as MediaDeviceInfo[]
    const { isCameraActive, toggleIsCameraActive, onCameraChange } =
        useConferenceSettings()

    return (
        <div className={style.mediaOptionContainer}>
            <Button
                variant="round"
                color="secondary"
                data-test="SettingsCameraLabel"
                onClick={() => {
                    if (isCameraAvailable) toggleIsCameraActive()
                }}
            >
                {isCameraActive && isCameraAvailable ? (
                    <Icon name="cam" data-test="SettingsCameraIconOn" />
                ) : (
                    <Icon name="camOff" data-test="SettingsCameraIconOff" />
                )}
            </Button>
            {showOptions && (
                <Dropdown
                    className={style.mediaOptions}
                    data-test="SettingsCameraControl"
                    selectedItem={devices.currentVideoInputDevice?.label || ''}
                    disabled={
                        !isCameraGranted ||
                        !devices.videoInputDevices ||
                        !devices.videoInputDevices.length
                    }
                    onSelect={(id) => {
                        const selectedDevice = cameras.find(
                            (speaker) => speaker.deviceId === id
                        )
                        onCameraChange(selectedDevice)
                    }}
                    data={cameras.map((d: MediaDeviceInfo) => ({
                        id: d.deviceId,
                        value: d.label,
                    }))}
                    position="relative"
                />
            )}
        </div>
    )
}

export default SettingsModalCamera
