import { useEffect, useState } from 'react'
import { getGame } from '../../../firebase'
import { Game as GameType } from '../../../state/starfox'
import { getGeoInfo, isEnvProd, isLocalhost, piepie } from '../../../utils'
import * as Socket from '../../../network/socket'
import { clearSessionState } from '../../../starfox/starfox'
import store, { actions } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/types'
import { useParams } from 'react-router'
import { byog_config } from '../../BYOG/config'

/**
 * Fetches the specific Game information from the Firestore.
 */
export const useGetGameInfo = () => {
    const dispatch = useDispatch()
    const { extra: userExtra } = useSelector((state: State) => state.user)
    const { gameName, byog, isHost } = useSelector(
        (state: State) => state.session
    )
    const { gameId: paramsGameId } = useParams<{ gameId: string }>()
    // BYOG processes console level ids. If BYOG, always use game id from query parameters.
    // NOTE: The game name is usually extracted from the session information. However, the game name is
    // actually added to the session after a small amount of time (through the host's websocket connection).
    // If a guest joins **before** that time, the game name may not have appeared in the session's info.
    // in such a case, we fall back to the game name from the query parameters.
    // NOTE: decodeURIComponent is needed here, decodeURI will not decode special characters such as `&`
    const gameId =
        byog || !gameName ? decodeURIComponent(paramsGameId) : gameName
    const [isGameInfoLoaded, setIsGameInfoLoaded] = useState<boolean>(false)
    useEffect(() => {
        const getGameFromFirestore = async () => {
            for (const [, console] of Object.entries(byog_config.session)) {
                if (console === paramsGameId) {
                    store.dispatch(actions.session.setBYOG(true))
                    break
                }
            }
            if (!gameId) return
            const doc = await getGame(gameId)

            if (doc.exists) {
                const currentGame = { id: doc.id, ...doc.data() } as GameType
                if (!currentGame.Available) {
                    piepie.error('[GAME INFO HOOK] error game not available')
                    window.location.href = '/'
                    return
                }
                const country = await getGeoInfo()
                if (
                    isHost &&
                    currentGame.ExcludedCountries?.includes(country.data)
                ) {
                    window.location.href = '/'
                    return
                }
                // Disabling use of whitelisted or event condition, because userExtra is null.
                // TODO: fix asap
                const whiteListedOrInEvent =
                    userExtra?.Events?.includes(currentGame.Event) ||
                    currentGame.Whitelist?.includes(window.location.host)
                if (isEnvProd && !isLocalhost && !whiteListedOrInEvent) {
                    piepie.log(
                        '[GAME INFO HOOK] game not whitelisted but allowing anyways'
                    )
                    // piepie.error('[GAME INFO HOOK] error game not whitelisted')
                    // window.location.href = '/'
                    // return
                }

                dispatch(actions.session.setGame(currentGame))
                dispatch(actions.session.setIsESL(!!currentGame.Competition))
                dispatch(
                    actions.session.setDisableSavestates(
                        !!currentGame.DisableSavestates
                    )
                )
                if (!byog) {
                    dispatch(actions.session.setGameName(doc.id))
                }
                setIsGameInfoLoaded(true)
            } else {
                piepie.log('[GAME INFO HOOK] Game cannot be found in firebase!')
                if (isHost) {
                    Socket.quitGame()
                    piepie.log('[GAME INFO HOOK] Host closed the room')
                }
                clearSessionState()
                window.location.href = '/'
                return
            }
        }

        getGameFromFirestore()
    }, [isHost, gameId, dispatch, userExtra, byog, paramsGameId])
    return isGameInfoLoaded
}
