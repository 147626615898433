import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    AlertInterface,
    AlertStateInterface,
    EmptyAlert,
} from '../../state/alert'
import { v1 as uuid } from 'uuid'

export interface AlertState {
    list: AlertInterface[]
    // alert
    notification: AlertStateInterface
}

export const initialState = {
    list: [],
    notification: EmptyAlert,
}

const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        push: (state, action: PayloadAction<AlertInterface>) => {
            state.list.push({
                id: uuid(),
                ...action.payload,
            })
        },
        pop: (state) => {
            state.list.pop()
        },
        delete: (state, action: PayloadAction<string>) => {
            state.list = state.list.filter(
                (alert) => alert.id !== action.payload
            )
        },
        clearNotification: (state) => {
            state.notification = EmptyAlert
        },
        setNotification: (
            state,
            action: PayloadAction<AlertStateInterface>
        ) => {
            state.notification = action.payload
        },
    },
})

export default alertSlice

export type AlertActionTypes =
    | ReturnType<typeof alertSlice.actions.clear>
    | ReturnType<typeof alertSlice.actions.delete>
    | ReturnType<typeof alertSlice.actions.push>
    | ReturnType<typeof alertSlice.actions.pop>
    | ReturnType<typeof alertSlice.actions.clearNotification>
    | ReturnType<typeof alertSlice.actions.setNotification>
