import React, { FC, useCallback, useState, useRef, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import FacesPlayer from './FacesPlayer'
import cls from 'classnames'
import style from './style.module.scss'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useSelector } from 'react-redux'
import { PubSubEvent, globalPubSub } from '../../../event/event'
import { State } from '../../../store/types'
import { Icon } from '../../../stories'

import * as Socket from '../../../network/socket'
import analytics from '../../../analytics'
import { isMobile } from 'react-device-detect'

//NOTE: SET NEW PROPS TYPES AND TOUCH EVENTS

interface FacesMultiplayerProps {
    players: any
    playersCount: number
    localHost: number
    devices: any
    localPlayers: any
    focusedPlayerId?: string
}

const FacesMultiplayer: FC<FacesMultiplayerProps> = ({
    players,
    playersCount,
    localHost,
    devices,
    localPlayers,
    focusedPlayerId,
}) => {
    const { t } = useTranslation()
    const isHost = useSelector((state: State) => state.session.isHost)
    const [dragOver, setDragOver] = useState(false)
    const [draggedElementIndex, setDraggedElementIndex] = useState(null)
    const dragCounter = useRef(0)
    const [expandedPlayerId, setExpandedPlayerId] = useState(focusedPlayerId)

    useEffect(() => {
        setExpandedPlayerId(focusedPlayerId)
    }, [focusedPlayerId])

    const onDragStart = useCallback(
        (e, index: number, device = 'desktop') => {
            if (!isHost) return
            if (device === ' desktop') {
                e.dataTransfer.effectAllowed = 'move'
            }
            setDraggedElementIndex(index)
        },
        [isHost]
    )

    const onDragEnd = useCallback(() => {
        if (!isHost) return
        setDraggedElementIndex(null)
    }, [isHost])

    const onDragOver = useCallback(
        (e) => {
            if (!isHost) return
            e.preventDefault()
        },
        [isHost]
    )

    const onRemoveDragEnter = useCallback(
        (e) => {
            if (!isHost) return
            e.preventDefault()
            dragCounter.current += 1
            setDragOver(true)
        },
        [isHost]
    )

    const onRemoveDragLeave = useCallback(
        (e) => {
            if (!isHost) return
            e.preventDefault()
            dragCounter.current -= 1
            if (dragCounter.current > 0) return
            setDragOver(false)
        },
        [isHost]
    )

    const switchPlayerLogic = useCallback(
        (playerIndex1, playerIndex2) => {
            const switchIndices = []
            const tempValue = players[playerIndex1]
            switchIndices.push(playerIndex1)
            switchIndices.push(playerIndex2)

            players[playerIndex1] = players[playerIndex2]
            players[playerIndex1].index = playerIndex1
            players[playerIndex2] = tempValue
            players[playerIndex2].index = playerIndex2
            globalPubSub.pub(PubSubEvent.REQ_SWITCH_INDICES, switchIndices)

            if (playerIndex2 === players.length - 1 && !tempValue.username) {
                players.pop()
            }
        },
        [players]
    )

    const switchPlayer = useCallback(
        (e) => {
            e.preventDefault()
            const element = e.target as HTMLElement
            const dropAreaIndex = parseInt(element.dataset.dropIndex)
            switchPlayerLogic(dropAreaIndex, draggedElementIndex)
        },
        [draggedElementIndex, switchPlayerLogic]
    )

    const removeLocalPlayer = useCallback((index) => {
        analytics.track('RemoveLocalPlayer', {})
        Socket.removeLocalPlayer(index)
    }, [])

    const addLocalPlayerHandler = useCallback(() => {
        analytics.track('AddLocalPlayer', {})
        Socket.addLocalPlayer()
    }, [])

    const isLocal = useCallback(
        (index: number) => {
            for (const player of players) {
                if (player.index === index && player.local) return true
            }
            return false
        },
        [players]
    )

    return (
        <div className={style.container}>
            {playersCount > 0 && (
                <>
                    {isMobile && isHost && (
                        <div className={style.mobileSwitchHint}>
                            <Icon
                                className={style.mobileSwitchHintIcon}
                                name="info"
                            />
                            <p>{t('MobilePlayerIndicesHint')}</p>
                        </div>
                    )}
                    <div
                        className={cls(
                            style.multiplayerContainer,
                            isMobile &&
                                draggedElementIndex !== null &&
                                style.highlighted
                        )}
                        data-test="MultiplayerPlayerContainer"
                    >
                        {players.map((p, index) => {
                            return (
                                <div
                                    className={cls(
                                        style.playerWrapper,
                                        isMobile &&
                                            index === draggedElementIndex &&
                                            style.highlighted
                                    )}
                                >
                                    <FacesPlayer
                                        key={index}
                                        userId={p.uid}
                                        userSessionId={p.usid}
                                        index={p.index}
                                        username={p.username}
                                        photoUrl={p.photoUrl}
                                        controller={
                                            p.device
                                                ? p.device.name
                                                : t('NoDevice')
                                        }
                                        devices={devices}
                                        local={p.local}
                                        localPlayers={localPlayers}
                                        localhost={p.localhost}
                                        draggedElementIndex={
                                            draggedElementIndex
                                        }
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        onDrop={switchPlayer}
                                        onDragOver={onDragOver}
                                        removeLocalPlayer={removeLocalPlayer}
                                        hasGoldLetters={p.hasGoldLetters}
                                        focus={expandedPlayerId === p.usid}
                                        onSettingsOpenChange={() =>
                                            setExpandedPlayerId(undefined)
                                        }
                                        onSwitchPlayerSelected={(
                                            playerIndex
                                        ) => {
                                            setExpandedPlayerId(undefined)

                                            if (
                                                playerIndex ===
                                                draggedElementIndex
                                            ) {
                                                // double click on the same player
                                                setDraggedElementIndex(null)
                                                return
                                            }

                                            if (draggedElementIndex === null) {
                                                // ready to start the switch
                                                setDraggedElementIndex(
                                                    playerIndex
                                                )
                                                return
                                            }

                                            // switch the players
                                            switchPlayerLogic(
                                                playerIndex,
                                                draggedElementIndex
                                            )
                                            setDraggedElementIndex(null)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div className={style.bottom}>
                        {draggedElementIndex !== null &&
                        draggedElementIndex !== localHost &&
                        !isMobile &&
                        isLocal(draggedElementIndex) ? (
                            <div
                                data-test="MultiplayerRemoveLocalPlayer"
                                className={cls(style.removeLocalPlayer, {
                                    [style.active]: dragOver,
                                })}
                                onDragEnd={onDragEnd}
                                onDragOver={onDragOver}
                                onDragEnter={onRemoveDragEnter}
                                onDragLeave={onRemoveDragLeave}
                                onDrop={() =>
                                    removeLocalPlayer(draggedElementIndex)
                                }
                            >
                                <DeleteOutlineIcon />
                            </div>
                        ) : (
                            playersCount < 8 && (
                                <Button
                                    data-test="MultiplayerAddLocalPlayer"
                                    variant="outline-primary"
                                    className={style.addLocalPlayer}
                                    onClick={addLocalPlayerHandler}
                                >
                                    {t('AddLocalPlayer')}
                                </Button>
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default FacesMultiplayer
