import React, { useEffect, useState } from 'react'
import { Grid, Input, NativeSelect } from '@material-ui/core'
import GamesList from '../GamesList'
import './GamesListScreen.scss'
import style from './style.module.scss'
import { useGamesList, BYOG_TAG_NAME } from '../../utils/useGamesList'
import { Game } from '../../state/starfox'

import { TSortBy } from '../GamesList/types'
import {
    useSearchFilterSort,
    useNumberOfPlayers,
    useGameTags,
    useSortByOptions,
    useSelectGame,
} from '../GamesList/hooks'
import ConfirmModal from '../ConfirmModal'
import { ConfirmDialog } from '../../state/confirm'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../store/types'
import screenSlice from '../../store/screen/screen'

export default function GamesListScreen() {
    /* v1 for switch game hides byog */
    const initialGamesList: ReadonlyArray<Game> =
        useGamesList().gamesList.filter(
            (game) => !game.Tags.includes(BYOG_TAG_NAME)
        )
    const gameTags = useGameTags(initialGamesList)
    const { state, handlers, gamesList, isListModified } =
        useSearchFilterSort(initialGamesList)

    const {
        handleSearchChange,
        handleTypeOfGameChange,
        handleNumberOfPlayersChange,
        handleSortByChange,
    } = handlers
    const { kind, order } = state.sortBy

    const userNumberOptions = useNumberOfPlayers()
    const userSortByOptions = useSortByOptions()
    const [selectedGame, setSelectedGame] = useState<string>('')
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleGameSelect = useSelectGame()
    const theater = useSelector((state: State) => state.screen.theater)
    const fullscreen = useSelector(
        (state: State) => state.screen.fullscreen
    ) as boolean

    useEffect(() => {
        if (selectedGame && theater) {
            dispatch(screenSlice.actions.setTheater(false))
        }
        // eslint-disable-next-line
    }, [theater, selectedGame])

    useEffect(() => {
        if (selectedGame && fullscreen) {
            dispatch(screenSlice.actions.setFullscreen(false))
        }
        // eslint-disable-next-line
    }, [fullscreen, selectedGame])

    if (initialGamesList.length === 0) return null

    return (
        <>
            <Grid justifyContent="space-between" container spacing={4}>
                <Grid item xs={4}>
                    <Input
                        inputProps={{
                            'data-test': 'SwitchGameSearchBar',
                        }}
                        value={state.search}
                        onChange={handleSearchChange}
                        fullWidth
                        disableUnderline
                        className="input-field"
                        placeholder={t('SwitchGameSearch')}
                    />
                </Grid>
                <Grid item xs>
                    <NativeSelect
                        fullWidth
                        className={cls(
                            style.nativeDropDown,
                            style.nativeFitContent
                        )}
                        disableUnderline
                        // placeholder= {t('SwitchGameTypeOfGame')}
                        id="type-of-game-select"
                        value={null}
                        onChange={handleTypeOfGameChange}
                    >
                        <>
                            <option value="">
                                {t('SwitchGameTypeOfGame')}
                            </option>
                            {gameTags.map((gameTag) => (
                                <option key={gameTag} value={gameTag}>
                                    {gameTag}
                                </option>
                            ))}
                        </>
                    </NativeSelect>
                </Grid>
                <Grid item xs>
                    <NativeSelect
                        fullWidth
                        className={cls(
                            style.nativeDropDown,
                            style.nativeFitContent
                        )}
                        disableUnderline
                        // placeholder={t('FindGameNumberOfPlayersClear')}
                        id="type-of-game-select"
                        value={state.numberOfPlayers}
                        onChange={handleNumberOfPlayersChange}
                    >
                        {userNumberOptions.map(({ value, name }) => (
                            <option key={value} value={value}>
                                {name}
                            </option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs />
                <Grid item xs={2}>
                    <NativeSelect
                        fullWidth
                        className={cls(style.nativeDropDown)}
                        disableUnderline
                        // placeholder={t("SwitchGameSortBy")}
                        id="type-of-game-select"
                        value={[kind, order].join(',')}
                        onChange={(evt) => {
                            const [kind, order] = evt.target.value.split(',')
                            // TODO: make it more approachable (how to type this kind of situations?)
                            handleSortByChange({ kind, order } as TSortBy)
                        }}
                    >
                        <option value="">{t('SwitchGameSortBy')}</option>
                        {userSortByOptions.map(({ kind, name, order }) => (
                            <option
                                key={`${kind}${order}`}
                                value={[kind, order]}
                            >
                                {name}
                            </option>
                        ))}
                    </NativeSelect>
                </Grid>
            </Grid>
            <div className={'game-list-inner'}>
                {isListModified ? (
                    <GamesList.Uncategorized
                        handleGameSelect={setSelectedGame}
                        initialGamesList={initialGamesList}
                        gamesList={gamesList}
                    />
                ) : (
                    <GamesList.Categorized
                        handleGameSelect={setSelectedGame}
                        gamesList={gamesList}
                    />
                )}
            </div>
            <ConfirmModal
                confirmDialog={ConfirmDialog.SwitchGame}
                open={selectedGame.length > 0}
                onConfirm={() => {
                    handleGameSelect(selectedGame)
                }}
                onClose={() => setSelectedGame('')}
                gameTitle={
                    selectedGame.length < 0
                        ? ''
                        : initialGamesList.find((g) => g.id === selectedGame)
                              ?.DisplayName
                }
            />
        </>
    )
}
