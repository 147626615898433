interface CameraResolution {
    width: number
    height: number
}

export const getCameraResolution =
    async (): Promise<CameraResolution | null> => {
        console.log(
            'WARNING in getCameraResolution - request video access without any constraints. We should not do that since video constraints may not work for video requested after.'
        )

        const video = await navigator.mediaDevices.getUserMedia({
            video: true,
        })
        const { width, height } = video.getTracks()[0].getSettings()
        return width ? { width, height } : null
    }
