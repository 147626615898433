import { createContext } from 'react'

export interface InviteModalContexteInterface {
    inviteModalOpen: boolean
    setInviteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    openInviteModal: () => Promise<void>
}

export const InviteModalContext =
    createContext<InviteModalContexteInterface | null>(null)
