import React from 'react'
import styles from './styles.module.scss'
import { Paper, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import classNames from 'classnames'

const Dropdown = (props) => (
    <div className={styles.dropdown}>
        <Autocomplete
            {...props}
            disablePortal
            disableClearable
            getOptionSelected={(option, value) => option === value}
            className={classNames(props.className, styles.autoCompleteInput)}
            ListboxProps={{
                style: {
                    maxHeight: '240px',
                },
            }}
            PaperComponent={({ children }) => (
                <Paper className={styles.autoCompleteDropdown}>
                    {children}
                </Paper>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        placeholder: props.placeholder,
                        disableUnderline: true,
                        style: {
                            color: 'white',
                            paddingRight: 8,
                            paddingLeft: 8,
                        },
                    }}
                />
            )}
        />
    </div>
)

export default Dropdown
