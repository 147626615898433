// Angles in NippleJS
//         90
//         |
// 180 -        - 0
//         |
//        270

export enum JoystickAngleRange {
    Right = 22.5,
    UpRight = 67.5,
    Up = 112.5,
    UpLeft = 157.5,
    Left = 202.5,
    DownLeft = 247.5,
    Down = 292.5,
    DownRight = 337.5,
}

export const helperJoystickFunctions = async (controlGroup, joystick) => {
    if (controlGroup && joystick) {
        joystick.on('start', function (e) {
            controlGroup.start()
        })

        joystick.on('move', function (e, data) {
            controlGroup.start()

            const angle = data.angle?.degree

            if (angle === undefined) return

            if (angle < JoystickAngleRange.Right) {
                controlGroup.right()
            } else if (angle < JoystickAngleRange.UpRight) {
                controlGroup.upRight()
            } else if (angle < JoystickAngleRange.Up) {
                controlGroup.up()
            } else if (angle < JoystickAngleRange.UpLeft) {
                controlGroup.upLeft()
            } else if (angle < JoystickAngleRange.Left) {
                controlGroup.left()
            } else if (angle < JoystickAngleRange.DownLeft) {
                controlGroup.downLeft()
            } else if (angle < JoystickAngleRange.Down) {
                controlGroup.down()
            } else if (angle < JoystickAngleRange.DownRight) {
                controlGroup.downRight()
            } else {
                controlGroup.right()
            }
        })

        // joystick.on('dir:up', function () {
        //     controlGroup.start()
        //     controlGroup.up()
        // })

        // joystick.on('dir:down', function () {
        //     controlGroup.start()
        //     controlGroup.down()
        // })

        // joystick.on('dir:right', function () {
        //     controlGroup.start()
        //     controlGroup.right()
        // })

        // joystick.on('dir:left', function () {
        //     controlGroup.start()
        //     controlGroup.left()
        // })

        joystick.on('end', function () {
            controlGroup.end()
        })
    }
}
