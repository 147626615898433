import { KEY } from '../keys'
import * as Input from '../input'
import gamepadJson from './gamepads.json'
import { GamePads, GamePad } from './types'

/****** GamePad positions *******
Like the SNES Gamepad

KEY.B: cluster right: down
KEY.A: cluster right: right
KEY.Y: cluster right: left
KEY.X: cluster right: up
KEY.L1: L1
KEY.R1: R1
KEY.L2: L2
KEY.R2: R2
KEY.SELECT: SELECT
KEY.START: START
KEY.L3: L3
KEY.R3: R3
KEY.UP: cluster left: up
KEY.DOWN: cluster left: down
KEY.LEFT: cluster left: left
KEY.RIGHT: cluster left: right
********************************/

export class Pad {
    mapping: Map<number, number>
    gamepad: Gamepad
    state: Map<string, number>
    currentGamepad: GamePad
    currentGamepadName: string
    currentId: string

    constructor() {
        this.mapping = new Map<number, number>()
        this.gamepad = null
        this.state = new Map<string, number>()
        this.currentGamepad = null
        this.currentGamepadName = ''
        this.currentId = 'default'
    }

    setId(id: string) {
        this.currentId = id
    }

    init(gamepad: Gamepad) {
        if (gamepad.id === this.currentGamepadName) {
            // No need to reinit if this is the Gamepad we are currently using
            return
        } else {
            this.currentGamepadName = gamepad.id
            this.state.clear()
            this.mapping.clear()
        }

        const gamePads: GamePads = gamepadJson
        gamePads.forEach(() => {
            const pads = (gamepadJson as GamePads).filter(
                (param) => param.code === this.currentId
            )

            // Default: XBOX controller
            // If the gamepad id does not exists in our JSON
            if (pads.length === 0) {
                this.currentGamepad = (gamepadJson as GamePads).filter(
                    (param) => param.code === 'default'
                )[0]
            } else {
                this.currentGamepad = pads[0]
            }

            Object.entries(this.currentGamepad.mapping).forEach(
                ([key, value]) => {
                    this.mapping.set(parseInt(key), KEY[value])
                }
            )
        })

        this.gamepad = gamepad
    }

    joystickStateAnalog(gamepad: Gamepad, idx: number) {
        // Default ID of axes
        let analog_left_h = 0
        let analog_left_v = 1
        let analog_right_h = 2
        let analog_right_v = 3
        // ID from JSON
        if (
            this.currentGamepad.analog &&
            this.currentGamepad.analog.length === 4
        ) {
            // Got value from json
            analog_left_h = this.currentGamepad.analog[0]
            analog_left_v = this.currentGamepad.analog[1]
            analog_right_h = this.currentGamepad.analog[2]
            analog_right_v = this.currentGamepad.analog[3]
        }

        // Current axes value
        let left_h = this.state.get('analog_left_h')
        let left_v = this.state.get('analog_left_v')
        let right_h = this.state.get('analog_right_h')
        let right_v = this.state.get('analog_right_v')

        // Query the controller state
        const len = gamepad.axes.length
        if (analog_left_h < len && analog_left_v < len) {
            left_h = gamepad.axes[analog_left_h]
            left_v = gamepad.axes[analog_left_v]
        }
        if (analog_right_h < len && analog_right_v < len) {
            right_h = gamepad.axes[analog_right_h]
            right_v = gamepad.axes[analog_right_v]
        }

        // Apply a deadzone
        const deadzone = 0.15 // 10% is barely enough on Flo's Xbox pad
        if (-deadzone <= left_h && left_h <= deadzone) {
            left_h = 0
        }
        if (-deadzone <= left_v && left_v <= deadzone) {
            left_v = 0
        }
        if (-deadzone <= right_h && right_h <= deadzone) {
            right_h = 0
        }
        if (-deadzone <= right_v && right_v <= deadzone) {
            right_v = 0
        }

        // Set the new value
        if (
            this.state.get('analog_left_h') !== left_h ||
            this.state.get('analog_left_v') !== left_v
        ) {
            this.state.set('analog_left_h', left_h)
            this.state.set('analog_left_v', left_v)
            Input.setAnalogLKeyState(idx, [
                left_h * (32 * 1024 - 1),
                left_v * (32 * 1024 - 1),
            ])
            Input.setLastUsedDevice(gamepad.index)
        }
        if (
            this.state.get('analog_right_h') !== right_h ||
            this.state.get('analog_right_v') !== right_v
        ) {
            this.state.set('analog_right_h', right_h)
            this.state.set('analog_right_v', right_v)
            Input.setAnalogRKeyState(idx, [
                right_h * (32 * 1024 - 1),
                right_v * (32 * 1024 - 1),
            ])
            Input.setLastUsedDevice(gamepad.index)
        }
    }

    joystickState(gamepad: Gamepad) {
        if (this.mapping.size > 0) {
            if (!Input.isControllerActive(gamepad.index)) {
                return
            }

            let idx = Input.getDeviceIndex(gamepad.index)
            idx = idx >= 0 ? idx : 0 // not sure why this is needed

            // Query the left and right analog value
            this.joystickStateAnalog(gamepad, idx)

            this.mapping.forEach((name: number, id: number) => {
                const button = gamepad.buttons[id]
                let pressed = Boolean(button) && button.pressed

                if (this.currentGamepad.axes) {
                    Object.entries(this.currentGamepad.axes).forEach(
                        ([, value]) => {
                            switch (value.condition) {
                                case 'greater':
                                    if (
                                        name === KEY[value.key] &&
                                        gamepad.axes[value.axis] > value.val
                                    )
                                        pressed = true
                                    break
                                case 'lower':
                                    if (
                                        name === KEY[value.key] &&
                                        gamepad.axes[value.axis] < value.val
                                    )
                                        pressed = true
                                    break
                                case 'equal':
                                    if (
                                        name === KEY[value.key] &&
                                        gamepad.axes[value.axis] === value.val
                                    )
                                        pressed = true
                                    break
                                case 'around':
                                    if (
                                        name === KEY[value.key] &&
                                        gamepad.axes[value.axis] >=
                                            value.val - 0.1 &&
                                        gamepad.axes[value.axis] <=
                                            value.val + 0.1
                                    )
                                        pressed = true
                                    break
                            }
                        }
                    )
                }
                const pressed_number = pressed ? 1 : 0
                if (
                    typeof name !== 'undefined' &&
                    this.state.get(name.toString()) !== pressed_number
                ) {
                    this.state.set(name.toString(), pressed_number)
                    Input.setKeyState(idx, name, pressed)
                    Input.setLastUsedDevice(gamepad.index)
                }
            })
        }
    }
}
