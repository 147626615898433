import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store, { actions } from '../../store'
import { formatDate, isByogMode } from '../../utils'
import firebase from 'firebase/compat/app'
import { getSession } from '../../firebase'
import { SaveStatesType } from '../../state/saveStates'
import { State } from '../../store/types'

export const useRefreshSaveStates = (
    setSliderLoading: (state: boolean) => void,
    userUID: string
) => {
    const dispatch = useDispatch()
    const game = useSelector((state: State) => state.session.game)

    const refresh = useCallback(async () => {
        const storageRef = firebase.storage().ref()

        let gameName = game?.id
        if (isByogMode(gameName)) {
            const session = await getSession(store.getState().session.sessionID)
            const lastGameIndex = session.Games.length - 1
            gameName = 'BYOG/' + session.Games[lastGameIndex].GameID
        }

        const savestatesRef = storageRef.child(
            `/user/${userUID}/savestates/${gameName}`
        )

        const stt = await savestatesRef.listAll()
        const promises = []

        Object.values(stt.items).forEach((item) => {
            if (item.name.endsWith('.state')) {
                const name = item.name.split('.state')[0]
                if (
                    name.indexOf('auto') === -1 &&
                    name.indexOf('.crc') === -1
                ) {
                    const chunks = name.split('-')
                    const gmtDate = `${chunks[0]}/${formatDate(
                        chunks[1]
                    )}/${formatDate(chunks[2])}`
                    const gmtTime = `${formatDate(chunks[3])}:${formatDate(
                        chunks[4]
                    )}:${formatDate(chunks[5])}`
                    const gmt = new Date(`${gmtDate} ${gmtTime} GMT`)

                    const date = gmt.toLocaleDateString()
                    const time = gmt.toLocaleTimeString()
                    const screenshotsRef = storageRef.child(
                        `/user/${userUID}/screenshots/${gameName}/${name}.png`
                    )

                    promises.push(
                        screenshotsRef.getDownloadURL().then((url) => {
                            return {
                                name: name,
                                date: date,
                                time: time,
                                gmt: gmt,
                                url: url,
                            }
                        })
                    )
                }
            }
        })

        Promise.all(promises).then((values) => {
            // it will highly help the sorting when we'll display the savestate
            // it is better to sort by a number (date in ms) than by a string
            const saves: SaveStatesType[] = []
            values.forEach((v) => {
                saves[v.gmt.getTime()] = {
                    name: v.name,
                    date: v.date,
                    time: v.time,
                    gmt: v.gmt,
                    url: v.url,
                }
            })
            setSliderLoading(false)
            dispatch(actions.session.setSaveStates(saves))
        })
    }, [game, userUID, dispatch, setSliderLoading])

    return { refresh }
}
