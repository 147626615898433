import React, { useEffect, useState } from 'react'
import { Card } from '../../../stories'
import './SearchResultItem.scss'

function SearchResultItem({ game, handleGameSelect }) {
    const [gameUrl, setGameUrl] = useState('')
    const [fallbackUrl, setFallbackUrl] = useState('')

    useEffect(() => {
        if (game?.id) {
            setGameUrl(
                'https://fassets.piepacker.com/thumbnails/' +
                    encodeURIComponent(game.id) +
                    `.webp?organizationId=942630971205`
            )
            setFallbackUrl(
                'https://fassets.piepacker.com/thumbnails/' +
                    encodeURIComponent(game.id) +
                    `.jpg?organizationId=942630971205`
            )
        }
    }, [game, fallbackUrl])

    return (
        <div
            onClick={() => handleGameSelect(game.id)}
            data-test="SwitchGameSearchResultItem"
            className="item"
        >
            <Card
                size="medium"
                alt="SwitchGameSearchResultItem"
                src={gameUrl}
                fallbackSrc={fallbackUrl}
            >
                <Card.Header>{game.id}</Card.Header>
            </Card>
        </div>
        // </Grid>
    )
}

export default SearchResultItem
