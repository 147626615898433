import React, { useCallback, useEffect } from 'react'

declare global {
    function createUnityInstance(
        canvasHtmlElement: HTMLCanvasElement,
        parameters: any,
        onProgress?: (progression: number) => void
    ): Promise<any>
}

export const assetsUrl = (partner: boolean) => {
    if (partner && process.env.REACT_APP_ENV !== 'PROD') {
        return 'https://assets.partners.piepacker.com/'
    }
    return 'https://assets.piepacker.com/'
}

function UnityScreen({ gameId, partner }) {
    var OnScriptLoaded = useCallback(() => {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            // Mobile device style: fill the whole browser client area with the game canvas:
            var meta = document.createElement('meta')
            meta.name = 'viewport'
            meta.content =
                'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes'
            document.getElementsByTagName('head')[0].appendChild(meta)
        }

        //no-error
        createUnityInstance(document.querySelector('#unity-canvas'), {
            dataUrl:
                assetsUrl(partner) + 'Unity/' + gameId + '/WebGLBuild.data',
            frameworkUrl:
                assetsUrl(partner) +
                'Unity/' +
                gameId +
                '/WebGLBuild.framework.js',
            codeUrl:
                assetsUrl(partner) + 'Unity/' + gameId + '/WebGLBuild.wasm',
            streamingAssetsUrl: 'StreamingAssets',
            companyName: 'DefaultCompany',
            productName: gameId,
            productVersion: '1.0',
            // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
            // devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
        })
    }, [gameId, partner])

    useEffect(() => {
        const script = document.createElement('script')
        script.src =
            assetsUrl(partner) + 'Unity/' + gameId + '/WebGLBuild.loader.js'
        script.async = true
        script.onload = () => OnScriptLoaded()

        document.body.appendChild(script)
    }, [OnScriptLoaded, gameId, partner])

    return (
        <canvas
            id="unity-canvas"
            style={{ width: '100%', height: '100%', background: '#231F20' }}
        ></canvas>
    )
}

export default UnityScreen
