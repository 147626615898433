import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RetroState {
    paused: boolean
}

export const initialState = {
    paused: false,
}

const retroSlice = createSlice({
    name: 'retro',
    initialState: initialState,
    reducers: {
        setPaused: (state, action: PayloadAction<boolean>) => {
            state.paused = action.payload
        },
    },
})

export type RetroAction = ReturnType<typeof retroSlice.actions.setPaused>

export default retroSlice
