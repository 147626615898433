import React from 'react'
import { Dropdown } from 'react-bootstrap'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'

export default function ESLDropdown({
    selectedDropdownItem,
    dropdownItems,
    selectDropdownElement,
}) {
    const { t } = useTranslation()

    return (
        <div className="ESLDropdown">
            <span className="ESLDropdownLabel">{t('ChoseEvent')}</span>
            <Dropdown alignRight>
                <Dropdown.Toggle>
                    {selectedDropdownItem.name}
                    <KeyboardArrowDown
                        style={{
                            position: 'absolute',
                            right: '18px',
                            top: '14px',
                            fontSize: '24px',
                        }}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdownItems.map((item) => {
                        return (
                            <Dropdown.Item
                                onClick={() => selectDropdownElement(item)}
                                key={item}
                            >
                                {item.name}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
