import React from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import 'firebase/auth'

import App from './App'
import store from './store'
import * as serviceWorker from './service-worker'
import './i18n'
import * as Joystick from './input/joystick'
import axios from 'axios'
import { LocalStorage } from './state/storage'

axios.defaults.headers.common['Accept-Language'] =
    window.localStorage.getItem(LocalStorage.i18nextLng) || 'en'
// TODO: Refactoring --> We can setup the base URL here so the calls in the other pages are cleaner

const init = async () => {
    Joystick.init()

    render(
        <>
            <Provider store={store}>
                <App />
            </Provider>
        </>,
        document.getElementById('root')
    )

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    if (window.location.href.includes('beta.piepacker.com')) {
        serviceWorker.register()
        window.location.replace(
            'http://play.jam.gg/'
        )
    } else {
        serviceWorker.unregister()
    }
}

init()
