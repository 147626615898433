import React from 'react'

export { default as Tags } from './Tags'

export default function Tag(
    props: React.HTMLProps<HTMLSpanElement>
): React.ReactComponentElement<'span', React.HTMLAttributes<HTMLSpanElement>> {
    const className = [props.className, 'tag'].join(' ')
    return <span {...props} className={className}></span>
}
