import { useCallback, useEffect } from 'react'
import { piepie } from '../../utils'
import { FetchStunTurn } from '../stun-turn'
import store from '../../store'
import { globalPubSub, PubSubEvent } from '../../event/event'
import * as Socket from '../socket'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { RTCPEventName } from '../../state/network'
import { AlertType } from '../../state/alert'
import { useTranslation } from 'react-i18next'
import rtcpSlice from '../../store/rtcp/rtcp'
import alertSlice from '../../store/alert/alert'

export const useRtcp = () => {
    // Redux state
    const mediaStreamReady = useSelector(
        (state: State) => state.rtcp.mediaStreamReady
    )
    const mediaStream = useSelector((state: State) => state.rtcp.mediaStream)
    const connection = useSelector((state: State) => state.rtcp.connection)
    const video = useSelector((state: State) => state.rtcp.video)
    const events = useSelector((state: State) => state.rtcp.events)
    const { t } = useTranslation()

    // Callbacks
    const onIceCandidate = useCallback((event: { candidate: any }) => {
        if (event.candidate) {
            const c = new RTCIceCandidate(event.candidate)
            Socket.send('icecandidate', JSON.stringify(c))
            return
        }
    }, [])
    const onIceStateChange = useCallback((event: Event): void => {
        // @ts-ignore
        switch (event.target.iceGatheringState) {
            case 'gathering':
                piepie.log('[RTCP] ice gathering')
                break
            case 'complete':
                piepie.log('[RTCP] ice gathering completed')
        }
    }, [])
    const onIceConnectionStateChange = useCallback(
        (connection: RTCPeerConnection) => async () => {
            piepie.log(
                `[RTCP] <- iceConnectionState: ${connection.iceConnectionState}`
            )
            switch (connection.iceConnectionState) {
                case 'connected': {
                    piepie.log('[RTCP] connected...')
                    store.dispatch(rtcpSlice.actions.setConnected(true))
                    break
                }
                case 'disconnected': {
                    piepie.log('[RTCP] disconnected...')
                    store.dispatch(rtcpSlice.actions.setConnected(false))
                    break
                }
                case 'failed': {
                    piepie.error('[RTCP] connection failed, retry...')
                    store.dispatch(rtcpSlice.actions.setConnected(false))

                    // TODO Need to find the syntax to use await
                    //
                    const offer = await connection.createOffer({
                        offerToReceiveVideo: true,
                        offerToReceiveAudio: true,
                        iceRestart: true,
                    })
                    await connection
                        .setLocalDescription(offer)
                        .catch(piepie.error)

                    piepie.log('[RTCP] step 4.1: send initwebrtc')
                    Socket.send(
                        'initwebrtc',
                        btoa(JSON.stringify(connection.localDescription))
                    )
                    break
                }
            }
        },
        []
    )

    // Start RTCP Action
    const start = useCallback(async () => {
        piepie.log(`[RTCP] collecting ice servers`)
        const rtcConfig = await FetchStunTurn()
        piepie.log(`[RTCP] got servers: ${JSON.stringify(rtcConfig)}`)

        piepie.log(`[RTCP] calling rtcp start`)

        const connection = new RTCPeerConnection(rtcConfig)
        connection.onconnectionstatechange = () => {
            // Log connection state to help debug
            piepie.log(
                `[RTCP] <- ConnectionState: ${connection.iceConnectionState}`
            )
        }

        const mediaStream = new MediaStream()
        const mediaStreamReady = false

        const inputReady = false
        const inputChannel = connection.createDataChannel('a', {
            ordered: true,
            negotiated: true,
            id: 0,
        })
        inputChannel.onopen = () => {
            piepie.log('[RTCP] the input channel has opened')
        }
        inputChannel.onclose = (ev: Event) => {
            piepie.log(`[RTCP] input channel has closed: ${ev.target}`)
            store.dispatch(rtcpSlice.actions.setInputReady(false))
        }
        inputChannel.onmessage = (ev: MessageEvent) => {
            const recvTime = Date.now()
            const msg = String.fromCharCode.apply(
                null,
                new Int8Array(ev.data)
            ) as string
            if (msg.indexOf('ping') >= 0) {
                const sendTime = parseInt(msg.split('-')[1])
                globalPubSub.pub(PubSubEvent.HEARTBEAT, [recvTime, sendTime])
            }
            if (msg.indexOf('latency') >= 0) {
                inputChannel.send(msg)
            }
        }
        inputChannel.onerror = (ev: RTCErrorEvent) => {
            piepie.log(
                `[RTCP] input channel has closed: ${ev.error.errorDetail} (${ev.error.code})`
            )
            store.dispatch(rtcpSlice.actions.setInputReady(false))
        }

        connection.addTransceiver('video', { direction: 'sendrecv' })
        connection.addTransceiver('audio', { direction: 'sendrecv' })
        const [videoReceiver, audioReceiver] = connection.getReceivers()
        piepie.log(
            '[RTCP] videoReceiver, audioReceiver',
            videoReceiver,
            audioReceiver
        )
        // @ts-ignore
        videoReceiver.playoutDelayHint = 0.0
        // @ts-ignore
        audioReceiver.playoutDelayHint = 0.0

        connection.oniceconnectionstatechange =
            onIceConnectionStateChange(connection)
        connection.onicegatheringstatechange = onIceStateChange
        connection.onicecandidate = onIceCandidate
        connection.ontrack = (event) => {
            mediaStream.addTrack(event.track)
        }

        const offer = await connection.createOffer({
            offerToReceiveVideo: true,
            offerToReceiveAudio: true,
        })
        await connection.setLocalDescription(offer).catch(piepie.error)

        piepie.log('[RTCP] step 4.1: send initwebrtc')
        Socket.send(
            'initwebrtc',
            btoa(JSON.stringify(connection.localDescription))
        )
        store.dispatch(rtcpSlice.actions.setConnection(connection))
        store.dispatch(rtcpSlice.actions.setMediaStream(mediaStream))
        store.dispatch(rtcpSlice.actions.setMediaStreamReady(mediaStreamReady))
        store.dispatch(rtcpSlice.actions.setInputChannel(inputChannel))
        store.dispatch(rtcpSlice.actions.setInputReady(inputReady))
    }, [onIceCandidate, onIceStateChange, onIceConnectionStateChange])
    useEffect(() => {
        const n = events.length
        if (
            n === 0 ||
            events[n - 1].Name !== RTCPEventName.MEDIA_STREAM_INITIALIZED
        ) {
            return
        }
        start()
    }, [start, events])

    // SetMediaStream RTCP Action
    const setMediaStream = useCallback(
        async (video: HTMLVideoElement) => {
            if (!video) {
                piepie.log(`[RTCP] video not ready`)
                return
            }
            if (!mediaStreamReady) {
                piepie.log(`[RTCP] media stream not ready`)
                return
            }
            piepie.log(`[RTCP] media stream set`)
            video.srcObject = mediaStream
            if (video.play) await video.play()
            piepie.log(`[RTCP] video is playing`)
        },
        [mediaStreamReady, mediaStream]
    )
    useEffect(() => {
        setMediaStream(video)
    }, [setMediaStream, video])

    // SetRemoteDescription RTCP Action
    const setRemoteDescription = useCallback(
        (data: string) => {
            if (!connection) {
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Error,
                        message: t('GameDisconnected'),
                        autoClose: false,
                    })
                )
                return
            }
            connection
                .setRemoteDescription(
                    new RTCSessionDescription(JSON.parse(atob(data)))
                )
                // set media object stream
                .then(() => {
                    piepie.log(
                        `[RTCP] remote description and media.srcObject set, mediastream active: ${mediaStream}`
                    )
                    store.dispatch(rtcpSlice.actions.setMediaStreamReady(true))
                })
        },
        [connection, mediaStream, t]
    )
    useEffect(() => {
        const n = events.length
        if (
            n === 0 ||
            events[n - 1].Name !== RTCPEventName.MEDIA_STREAM_SDP_AVAILABLE
        ) {
            return
        }
        setRemoteDescription(events[n - 1].Data)
    }, [setRemoteDescription, events])
    const addCandidate = useCallback(
        (candidate: string) => {
            if (!connection) {
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Error,
                        message: t('GameDisconnected'),
                        autoClose: false,
                    })
                )
                return
            }
            const iceCandidateInit: RTCIceCandidateInit = JSON.parse(candidate)
            connection.addIceCandidate(iceCandidateInit)
        },
        [connection, t]
    )
    useEffect(() => {
        const n = events.length
        if (n === 0 || events[n - 1].Name !== RTCPEventName.ICE_CANDIDATE) {
            return
        }
        addCandidate(events[n - 1].Data)
    }, [addCandidate, events])
}
