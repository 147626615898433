export const GAME_CATEGORIES = [
    'Featured',
    'Mobile-Featured',
    'Trending',
    'BYOG',
    'RecentlyPlayed',
    'Moods',
    'RecentlyAdded',
    'Multiplayer',
    'Platformer',
    'OpenRoom',
    'Shooter',
    'Sports',
    'Fighting',
    'Puzzle',
    'Racing',
    'Indie',
    'AllGames',
] as const

export type TGameCategories = typeof GAME_CATEGORIES[number]
