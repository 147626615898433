import React, { useState } from 'react'
import { roomLanguages, languagesData } from '../GameSettings'
import { Autocomplete } from '@material-ui/lab'
import { Paper, TextField } from '@material-ui/core'
import style from './style.module.scss'
import { Icon } from '../../stories'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

interface ILanguageFilter {
    language: string
    placeholder?: string
    handleChange: (value: string) => void
}

export const LanguageFilter: React.FC<ILanguageFilter> = ({
    handleChange,
    language,
    placeholder = '',
}) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const getText = (text: string) => {
        return isMobile
            ? `${text.toUpperCase()}`
            : `${text.toUpperCase()} (${t(`Lang${text.toUpperCase()}`)})`
    }

    return (
        <Autocomplete
            disableClearable
            disablePortal
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={languagesData(placeholder)}
            defaultValue={{ value: placeholder, id: null }}
            value={roomLanguages[language] || null}
            getOptionLabel={(option) => {
                if (option.id) return getText(option.id)
                for (const [id, value] of Object.entries(roomLanguages)) {
                    if (value === option) return getText(id)
                }
                return placeholder
            }}
            getOptionSelected={(option, value) => option.value === value}
            className={style.autoCompleteInput}
            onChange={(_, { id }) => handleChange(id)}
            PaperComponent={({ children }) => (
                <Paper className={style.autoCompleteDropdown}>{children}</Paper>
            )}
            forcePopupIcon={false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        classes: {
                            disabled: style.autoCompleteInputDisabled,
                        },
                        endAdornment: (
                            <Icon
                                name="arrowDropdown"
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    transform: open
                                        ? 'rotate(180deg)'
                                        : 'rotate(0)',
                                }}
                                onClick={() => setOpen(!open)}
                            />
                        ),
                    }}
                />
            )}
        />
    )
}
