import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NavigationPrompt from 'react-router-navigation-prompt'
import { updateConferenceId } from '../../firebase'
import { clearSessionState, getUserSessionID } from '../../starfox/starfox'
import * as Socket from '../../network/socket'
import { State } from '../../store/types'
import VideoCamHandler from '../../handlers/VideoCamHandler'
import ConfirmModal from '../../components/ConfirmModal'
import SettingsModal from '../../components/SettingsModal'
import { ConfirmDialog } from '../../state/confirm'
import { UserSessionFromFirebase } from '../../state/starfox'
import GameLobby from '../../components/GameLobby'
import { GameLobbyErrorType } from '../../state/alert'
import { piepie, sleep, UserTabInfo } from '../../utils'
import GameRoom from '../../components/GameRoom/GameRoom'
import { User } from '../../state/faces'
import { useCreateOrJoinConference } from '../../conference/hooks'
import { init as watchRTCInit } from '@testrtc/watchrtc-sdk'
import { BackButton } from '../../stories'
import { isMobile } from 'react-device-detect'

import {
    useGetGameInfo,
    useIsOnline,
    usePubSubEvents,
    useTabVisibility,
    useUserManager,
} from './hooks'
import { SessionActiveEnum } from '../../store/session/types'
import { useStarfox } from '../../starfox/hooks'
import { useSocket } from '../../network/hooks'
import { useRtcp } from '../../network/hooks/useRtcp'
import { setGameLobbyError } from '../../store/session/actions'

function GamePage() {
    const game = useSelector((state: State) => state.session.game)
    const videoCamHandler = useRef(new VideoCamHandler())
    const call = useRef(null)
    const sessionDataRef = useRef<UserSessionFromFirebase[]>([])
    const isHealthy = useRef(false)
    const isLocalInitialized = useRef(false)
    const allUsersRef = useRef<{ [index: string]: User }>({})
    const [usersTabInfo, setUsersTabInfo] = useState<UserTabInfo[]>([])
    const isSpeakerActive = useSelector(
        (state: State) => state.conference.isSpeakerActive
    )
    const sessionID = useSelector((state: State) => state.session.sessionID)
    const userSessionID = useSelector(
        (state: State) => state.session.userSessionID
    )
    const isHost = useSelector((state: State) => state.session.isHost)
    const sessionActive = useSelector(
        (state: State) => state.session.sessionActive
    )

    const isGameInfoLoaded = useGetGameInfo()
    useTabVisibility(userSessionID, usersTabInfo, setUsersTabInfo)

    // sessionData contains all the UserSession infos about the participants currently in the room
    // it is updated everytime someone join or leave the room
    const [sessionData, setSessionData] = useState<UserSessionFromFirebase[]>(
        []
    )

    const [settingsOpen, setSettingsOpen] = useState(false)
    const [users, setUsers] = useState({})
    const [conferenceID] = useState('')
    const [displayGameLobby, setDisplayGameLobby] = useState(true)
    const [lobbyLoading, setLobbyLoading] = useState(true)
    const [enterClickable, setEnterClickable] = useState(false)
    const lobbyError = useSelector((state: State) => state.session.lobbyError)
    const [allUsers, setAllUsers] = useState<{ [index: string]: User }>({})
    const [chimeConfigurationOver, setChimeConfigurationOver] = useState(false)
    const ESLGuestPlayer = useSelector((state: State) =>
        state.user.extra ? state.user.extra.ESLGuestPlayer || false : undefined
    )
    const dispatch = useDispatch()

    useIsOnline()
    usePubSubEvents({
        videoCamHandler,
        call,
        sessionDataRef,
        setUsers,
        isLocalInitialized,
        isHealthy,
        setLobbyLoading,
    })
    useStarfox()
    useSocket(sessionActive, setEnterClickable)
    useRtcp()
    useUserManager(
        sessionActive,
        lobbyError,
        sessionID,
        userSessionID,
        setLobbyLoading,
        setAllUsers,
        setSessionData,
        setEnterClickable,
        setUsers,
        allUsersRef,
        sessionDataRef,
        videoCamHandler
    )
    useEffect(() => {
        // Initialiaze state
        getUserSessionID()
    }, [])

    useEffect(() => {
        if (!sessionID || !userSessionID) {
            return
        }

        // Log the sessionID for debugging purposes.
        piepie.logProd('sessionID', sessionID)

        // init watchrtc
        piepie.log('[GAME] watchRTC init')
        watchRTCInit({
            rtcApiKey: 'staging:62abd72b-b105-4621-84f2-3b3f9960469a',
            rtcRoomId: sessionID,
            rtcPeerId: userSessionID,
        })
    }, [sessionID, userSessionID])

    // CHIME:
    const audioElementRef = useRef(null)
    const onConfigurationOver = useCallback(() => {
        setChimeConfigurationOver(true)
    }, [setChimeConfigurationOver])
    useCreateOrJoinConference({
        sessionID,
        userSessionID,
        audioElement: audioElementRef.current,
        onConfigurationOver,
        restricted: game
            ? game.id === 'Arsene Bomber ESL' && ESLGuestPlayer
            : undefined,
    })

    // we wait 2 minutes before throwing an error
    const waitHealthy = useCallback(async () => {
        await sleep(2000 * 60)
        if (sessionActive === SessionActiveEnum.Yes) {
            return
        }
        dispatch(setGameLobbyError(GameLobbyErrorType.TIMEOUT))
    }, [sessionActive, dispatch])
    useEffect(() => {
        waitHealthy()
    }, [waitHealthy])

    useEffect(() => {
        const subscribe = async () => {
            piepie.log('[GAME] Your usersessionID is ', userSessionID)

            // Update our own ConferenceID in the user session
            await updateConferenceId(userSessionID, conferenceID)
        }

        if (conferenceID && userSessionID) subscribe()
    }, [conferenceID, userSessionID, sessionID])

    const shouldPrompt = useCallback((curr, next): boolean => {
        // Don't show a prompt when clicking on an external link. E.g. Contact us link on a feedback tool.
        if (!next) {
            return false
        }

        if (next.hash && next.hash === '#logout') {
            return false
        }

        return true
    }, [])

    const onValidate = useCallback(
        (onConfirm) => {
            if (isHost) {
                Socket.quitGame()
                piepie.log('[GAME] host closed the room')
            }
            clearSessionState()
            onConfirm()
        },
        [isHost]
    )

    return (
        <>
            {!isMobile && <BackButton />}
            <audio
                ref={audioElementRef}
                style={{ display: 'none' }}
                muted={!isSpeakerActive}
            />

            {isGameInfoLoaded && (
                <>
                    {displayGameLobby ? (
                        <GameLobby
                            lobbyLoading={
                                lobbyLoading && !chimeConfigurationOver
                            }
                            enterClickable={enterClickable}
                            session={sessionData}
                            setDisplayGameLobby={setDisplayGameLobby}
                            setSettingsOpen={setSettingsOpen}
                        />
                    ) : (
                        <GameRoom
                            users={users}
                            allUsers={allUsers}
                            setSettingsOpen={setSettingsOpen}
                        />
                    )}

                    <NavigationPrompt when={shouldPrompt}>
                        {({ onConfirm, onCancel }) => (
                            <ConfirmModal
                                onClose={onCancel}
                                onValidate={() => {
                                    onValidate(onConfirm)
                                    // TODO: This is a hack, we'll have to remove this after Sega demo
                                    window.location.reload()
                                }}
                                open={true}
                                confirmDialog={ConfirmDialog.Quit}
                            />
                        )}
                    </NavigationPrompt>
                    <SettingsModal
                        preview={displayGameLobby}
                        open={settingsOpen}
                        onClose={() => setSettingsOpen(false)}
                    />
                </>
            )}
        </>
    )
}

export default GamePage
