import styles from './styles.module.scss'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { authorizedConfig } from '../../../firebase'
import { defaultStarfoxURL } from '../../../utils'
import * as selector from '../state/selectors'
import { useSelector } from 'react-redux'
import firebase from 'firebase/compat/app'
import * as Axios from 'axios'

interface ISocial {
    refreshUser: () => void
}

const Social: React.FC<ISocial> = ({ refreshUser }) => {
    const { t } = useTranslation()
    const twitchLinked = useSelector(selector.getTwitchLinked)

    const connect = useCallback(async () => {
        const cfg = await authorizedConfig()
        const user = firebase.auth().currentUser
        const res = await Axios.default.get(
            `${defaultStarfoxURL()}/api/twitch/getauthorizationurl/${user.uid}`,
            cfg
        )
        const url = res.data
        window.open(url, '_self').focus()
    }, [])

    const disconnect = useCallback(async () => {
        const cfg = await authorizedConfig()
        await Axios.default.get(`${defaultStarfoxURL()}/api/twitch/unlink`, cfg)
        refreshUser()
    }, [refreshUser])

    return (
        <div className={styles.section}>
            <p className={styles.header}>{t('Social')}</p>
            <div className={styles.itemsContainer}>
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('Twitch')}</p>
                        {twitchLinked && (
                            <p className={styles.itemValue}>Connected</p>
                        )}
                        {!twitchLinked && (
                            <p className={styles.itemValue}>Not connected</p>
                        )}
                    </div>
                    {twitchLinked && (
                        <p className={styles.action} onClick={disconnect}>
                            {t('Disconnect')}
                        </p>
                    )}
                    {!twitchLinked && (
                        <p className={styles.action} onClick={connect}>
                            {t('Connect')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Social
