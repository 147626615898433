import React from 'react'
import style from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../../stories'

const UpdateCheck = ({ checkUpdates, setCheckUpdates }) => {
    const { t } = useTranslation()
    const handleUpdateClick = () => setCheckUpdates(!checkUpdates)
    return (
        <div className={style.checkbox}>
            <Checkbox
                label={
                    <div className={style.checkboxLabel}>
                        {t('GetLatestUpdates')}
                    </div>
                }
                value={checkUpdates}
                onChange={handleUpdateClick}
            />
        </div>
    )
}

export default UpdateCheck
