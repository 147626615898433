const mesen_options = new Map<string, string>()
mesen_options.set('mesen_overscan_down', '8px')
mesen_options.set('mesen_overscan_left', '8px')
mesen_options.set('mesen_overscan_right', '8px')
mesen_options.set('mesen_overscan_up', '8px')
mesen_options.set('mesen_controllerturbospeed', 'Disabled')

const mesens_options = new Map<string, string>()
mesens_options.set('mesen-s_overscan_vertical', '8px')
mesens_options.set('mesen-s_hle_coprocessor', 'enabled')
mesens_options.set('mesen-s_ramstate', 'All 0s')

export const WASM_CORES_OPTIONS_MAP = new Map<string, Map<string, string>>()
WASM_CORES_OPTIONS_MAP.set('mesens_libretro', mesens_options)
WASM_CORES_OPTIONS_MAP.set('mesen_libretro', mesen_options)
WASM_CORES_OPTIONS_MAP.set('lutro_libretro', null)
WASM_CORES_OPTIONS_MAP.set('mgba_libretro', null)
WASM_CORES_OPTIONS_MAP.set('blastem_libretro', null)
WASM_CORES_OPTIONS_MAP.set('mametiny_libretro', null)
WASM_CORES_OPTIONS_MAP.set('mamearcade_libretro', null)
