import React, { useEffect, useState, useRef } from 'react'
import FaceMask from '../FaceMask'
import { useSelector, useDispatch } from 'react-redux'
import { useConferenceSettings } from '../../../conference/hooks'
import { piepie } from '../../../utils'
import { AlertType } from '../../../state/alert'
import { globalPubSub, PubSubEvent } from '../../../event/event'
import { setActiveMaskId } from '../../../store/mask/actions'
import alertSlice from '../../../store/alert/alert'

const FaceMaskWrapper = (props) => {
    const dispatch = useDispatch()
    const { activeMaskId, metadataStore, mannequinMode } = useSelector(
        (state) => state.mask
    )
    const { isCameraActive } = useSelector((state) => state.conference)
    const { currentVideoInputDevice } = useSelector(
        (state) => state.conference.devices
    )
    const { onCameraChange, onCameraStreamChange, onCameraStreamStop } =
        useConferenceSettings()

    const {
        userSessionID,
        onMaskLoadingStart,
        onMaskLoadingEnd,
        showVideoFeed,
        playerIndex,
    } = props

    const [gameMaskState, setGameMaskState] = useState({})

    const faceMaskRef = useRef(null)

    useEffect(() => {
        globalPubSub.sub(PubSubEvent.GAME_HOTPOTATO, (data) =>
            setGameMaskState(data)
        )
    }, [])

    useEffect(() => {
        if (isCameraActive) {
            if (gameMaskState.matchIsRunning === 1) {
                dispatch(setActiveMaskId('arsenebomber_bomb')) // FIXME: this is triggered too many times

                if (
                    gameMaskState.bombIsExploded === 0 &&
                    gameMaskState.bombCarrier !== -1
                ) {
                    // Execute this only during a new round. Do not reset the animation at the end of the round (in order to show the animation)
                    faceMaskRef.current.dispatch_externalTrigger({
                        type: 'mask-off',
                    })

                    faceMaskRef.current.dispatch_externalTrigger({
                        type: 'animations-reset',
                    })
                }

                if (gameMaskState.bombIsExploded === 1) {
                    faceMaskRef.current.dispatch_externalTrigger({
                        type: 'animation-start',
                        name: 'HotPotato',
                    })
                } else if (gameMaskState.bombCarrier === playerIndex + 1) {
                    if (gameMaskState.bombIsExploded === 0) {
                        faceMaskRef.current.dispatch_externalTrigger({
                            type: 'animations-reset',
                        })
                    }

                    faceMaskRef.current.dispatch_externalTrigger({
                        type: 'mask-on',
                    })
                } else if (gameMaskState.bombCarrier !== -1) {
                    if (gameMaskState.bombIsExploded === 0) {
                        faceMaskRef.current.dispatch_externalTrigger({
                            type: 'animations-reset',
                        })
                    }

                    faceMaskRef.current.dispatch_externalTrigger({
                        type: 'mask-off',
                    })
                }
            }
        } else {
            dispatch(setActiveMaskId(null))
            faceMaskRef.current.dispatch_externalTrigger({
                type: 'mask-on',
            })
        }
    }, [
        gameMaskState,
        playerIndex,
        userSessionID,
        isCameraActive,
        dispatch,
        faceMaskRef,
    ])

    return (
        <FaceMask
            {...props}
            ref={faceMaskRef}
            showVideoFeed={showVideoFeed && !mannequinMode}
            isMannequin={mannequinMode || !showVideoFeed}
            activeMaskId={activeMaskId}
            maskSettings={metadataStore[activeMaskId] || {}}
            isMaskVisible={activeMaskId !== null}
            currentVideoInputDevice={currentVideoInputDevice}
            isCameraActive={isCameraActive}
            onCameraStreamChange={onCameraStreamChange}
            onCameraChange={onCameraChange}
            onCameraStreamStop={onCameraStreamStop}
            onMaskLoadingStart={onMaskLoadingStart}
            onMaskLoadingEnd={onMaskLoadingEnd}
            logger={piepie.log}
            errorLogger={piepie.error}
            videoPreset="auto"
            onError={() => {
                dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Error,
                        message: 'IncompatibleBrowserForMasks',
                        autoClose: false,
                    })
                )
            }}
        />
    )
}

export default FaceMaskWrapper
