import {
    CameraResolution,
    Participant,
    Participants,
} from '../../state/conference'

export const SET_MEETING_ID = 'SET_MEETING_ID'
export const SET_CHIME_MEETING_ID = 'SET_CHIME_MEETING_ID'
export const SET_REGION = 'SET_REGION'
export const SET_NAME = 'SET_NAME'
export const SET_ERROR = 'SET_ERROR'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_SND_BANDWIDTH = 'SET_SND_BANDWIDTH'
export const SET_RCV_BANDWIDTH = 'SET_RCV_BANDWIDTH'
export const SET_IS_CAMERA_ACTIVE = 'SET_IS_CAMERA_ACTIVE'
export const SET_IS_MICROPHONE_ACTIVE = 'SET_IS_MICROPHONE_ACTIVE'
export const SET_IS_SPEAKER_ACTIVE = 'SET_IS_SPEAKER_ACTIVE'
export const RESET_STATE = 'RESET_STATE'
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT'
export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT'
export const EDIT_PARTICIPANT_MUTED = 'EDIT_PARTICIPANT_MUTED'
export const EDIT_PARTICIPANT_VOLUME = 'EDIT_PARTICIPANT_VOLUME'
export const UPDATE_CAMERA_RESOLUTION = 'UPDATE_CAMERA_RESOLUTION'
export const SET_IS_CAMERA_AVAILABLE = 'SET_IS_CAMERA_AVAILABLE'
export const SET_IS_MICROPHONE_AVAILABLE = 'SET_IS_MICROPHONE_AVAILABLE'
export const SET_IS_SPEAKER_AVAILABLE = 'SET_IS_SPEAKER_AVAILABLE'
export const SET_IS_DEVICE_BINDING_COMPLETE = 'SET_IS_DEVICE_BINDING_COMPLETE'
export const SET_IS_CAMERA_GRANTED = 'SET_IS_CAMERA_GRANTED'
export const SET_IS_MICROPHONE_GRANTED = 'SET_IS_MICROPHONE_GRANTED'
export const SET_IS_SPEAKER_GRANTED = 'SET_IS_SPEAKER_GRANTED'
export const SET_VIRTUAL_CAMERA_STREAM = 'SET_VIRTUAL_CAMERA_STREAM'
export const UPDATE_AUDIO_INPUT_DEVICES = 'UPDATE_AUDIO_INPUT_DEVICES'
export const UPDATE_AUDIO_OUTPUT_DEVICES = 'UPDATE_AUDIO_OUTPUT_DEVICES'
export const UPDATE_VIDEO_INPUT_DEVICES = 'UPDATE_VIDEO_INPUT_DEVICES'
export const SET_CURRENT_AUDIO_INPUT_DEVICE = 'SET_CURRENT_AUDIO_INPUT_DEVICE'
export const SET_CURRENT_VIDEO_INPUT_DEVICE = 'SET_CURRENT_VIDEO_INPUT_DEVICE'
export const SET_CURRENT_AUDIO_OUTPUT_DEVICE = 'SET_CURRENT_AUDIO_OUTPUT_DEVICE'
export const SET_IS_VIDEO_FEED_ENABLED = 'SET_IS_VIDEO_FEED_ENABLED'

interface SetMeetingIdAction {
    type: typeof SET_MEETING_ID
    payload: string
}

interface SetChimeMeetingIdAction {
    type: typeof SET_CHIME_MEETING_ID
    payload: string
}

interface SetRegionAction {
    type: typeof SET_REGION
    payload: string
}

interface SetNameAction {
    type: typeof SET_NAME
    payload: string
}

interface SetErrorAction {
    type: typeof SET_ERROR
    payload: string
}

interface setIsLoadingAction {
    type: typeof SET_IS_LOADING
    payload: boolean
}

interface setSndBandwidthAction {
    type: typeof SET_SND_BANDWIDTH
    payload: number
}

interface setRcvBandwidthAction {
    type: typeof SET_RCV_BANDWIDTH
    payload: number
}

interface setIsCameraActiveAction {
    type: typeof SET_IS_CAMERA_ACTIVE
    payload: boolean
}

interface setIsMicrophoneActiveAction {
    type: typeof SET_IS_MICROPHONE_ACTIVE
    payload: boolean
}

interface setIsSpeakerActiveAction {
    type: typeof SET_IS_SPEAKER_ACTIVE
    payload: boolean
}

interface setIsDeviceBindingComplete {
    type: typeof SET_IS_DEVICE_BINDING_COMPLETE
    payload: boolean
}

interface AddParticipantAction {
    type: typeof ADD_PARTICIPANT
    payload: Participant
}

interface RemoveParticipantAction {
    type: typeof REMOVE_PARTICIPANT
    payload: string
}

interface EditParticipantAction {
    type: typeof EDIT_PARTICIPANT
    payload: Participants<Participant>
}

interface EditParticipantMutedAction {
    type: typeof EDIT_PARTICIPANT_MUTED
    payload: {
        externalUserId: string
        isMuted: boolean
    }
}

interface EditParticipantVolume {
    type: typeof EDIT_PARTICIPANT_VOLUME
    payload: {
        userId: string
        volume: number
    }
}

interface resetStateAction {
    type: typeof RESET_STATE
}

interface UpdateVideoInputDevices {
    type: typeof UPDATE_VIDEO_INPUT_DEVICES
    payload: MediaDeviceInfo[]
}

interface UpdateAudioInputDevices {
    type: typeof UPDATE_AUDIO_INPUT_DEVICES
    payload: MediaDeviceInfo[]
}

interface UpdateAudioOutputDevices {
    type: typeof UPDATE_AUDIO_OUTPUT_DEVICES
    payload: MediaDeviceInfo[]
}

interface UpdateCameraResolutionAction {
    type: typeof UPDATE_CAMERA_RESOLUTION
    payload: CameraResolution
}

interface SetIsCameraAvailable {
    type: typeof SET_IS_CAMERA_AVAILABLE
    payload: boolean
}

interface SetIsMicrophoneAvailable {
    type: typeof SET_IS_MICROPHONE_AVAILABLE
    payload: boolean
}

interface SetIsSpeakerAvailable {
    type: typeof SET_IS_SPEAKER_AVAILABLE
    payload: boolean
}

interface SetIsCameraGranted {
    type: typeof SET_IS_CAMERA_GRANTED
    payload: boolean
}

interface SetIsMicrophoneGranted {
    type: typeof SET_IS_MICROPHONE_GRANTED
    payload: boolean
}

interface SetIsSpeakerGranted {
    type: typeof SET_IS_SPEAKER_GRANTED
    payload: boolean
}

interface SetVirtualCameraStream {
    type: typeof SET_VIRTUAL_CAMERA_STREAM
    payload: MediaStream | null
}

interface SetCurrentAudioInputDevice {
    type: typeof SET_CURRENT_AUDIO_INPUT_DEVICE
    payload: MediaDeviceInfo | null
}

interface SetCurrentAudioOutputDevice {
    type: typeof SET_CURRENT_AUDIO_OUTPUT_DEVICE
    payload: MediaDeviceInfo | null
}

interface SetCurrentVideoInputDevice {
    type: typeof SET_CURRENT_VIDEO_INPUT_DEVICE
    payload: MediaDeviceInfo | null
}

interface SetIsVideoFeedEnabled {
    type: typeof SET_IS_VIDEO_FEED_ENABLED
    payload: boolean
}

export type ConferenceActionTypes =
    | SetMeetingIdAction
    | SetChimeMeetingIdAction
    | SetRegionAction
    | SetNameAction
    | SetErrorAction
    | setIsLoadingAction
    | setSndBandwidthAction
    | setRcvBandwidthAction
    | setIsCameraActiveAction
    | setIsMicrophoneActiveAction
    | setIsSpeakerActiveAction
    | AddParticipantAction
    | RemoveParticipantAction
    | EditParticipantAction
    | EditParticipantMutedAction
    | EditParticipantVolume
    | resetStateAction
    | UpdateCameraResolutionAction
    | SetIsCameraAvailable
    | SetIsMicrophoneAvailable
    | setIsDeviceBindingComplete
    | SetIsCameraGranted
    | SetIsMicrophoneGranted
    | SetVirtualCameraStream
    | UpdateVideoInputDevices
    | UpdateAudioInputDevices
    | UpdateAudioOutputDevices
    | SetCurrentAudioInputDevice
    | SetCurrentAudioOutputDevice
    | SetCurrentVideoInputDevice
    | SetIsSpeakerGranted
    | SetIsSpeakerAvailable
    | SetIsVideoFeedEnabled
