import { useCallback } from 'react'
import { piepie } from './piepie'

interface useKonHandlerParams {
    pattern: string[]
    current
    callback?: (param?) => void
    isLocal?: boolean
    isScreen?: boolean
}

export const useKonHandler = ({
    pattern,
    current,
    isLocal,
    callback,
    isScreen,
}: useKonHandlerParams) => {
    const konHandler = useCallback(
        (konami) => {
            if (
                pattern.indexOf(konami.key) < 0 ||
                (konami.key !== pattern[current.current] &&
                    konami.key !== pattern[current.current - 1])
            ) {
                current.current = 0
                return
            } else if (
                pattern[current.current - 1] &&
                konami.key === pattern[current.current - 1]
            ) {
                return
            }
            current.current++
            if (pattern.length === current.current) {
                current.current = 0
                if (isLocal) {
                    piepie.log('[LOCAL SCREEN] konami fps')
                    callback()
                } else if (isScreen) {
                    document.body.classList.add('debugCleanVideoFeed')
                } else {
                    callback((showFS) => !showFS)
                }
            }
        },
        [pattern, current, isLocal, callback, isScreen]
    )

    return { konHandler }
}
