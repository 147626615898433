import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar } from 'react-bootstrap'
import styles from './style.module.scss'
import store from '../../store'
import { actions } from '../../store/home'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'

const Maintenance: FunctionComponent = () => {
    const maintenanceEnd = useSelector(
        (state: State) => state.home.maintenanceEnd
    )
    const isFetched = useRef(false)
    const { t } = useTranslation()

    // we just need to render the component to update the store
    useEffect(() => {
        if (!isFetched.current) {
            isFetched.current = true
            store.dispatch(actions.setMaintenance(true))
        }
    }, [])

    return (
        <Navbar
            sticky="top"
            expand={false}
            className={`${styles['ks-banner-container']} ${styles['bg-piepurple']}`}
        >
            <Navbar.Brand className={`p-0 ${styles['centered']}`}>
                <span
                    className={styles['ks-banner-comingsoon']}
                    style={{ color: '#fff' }}
                >
                    {t('Maintenance')} {maintenanceEnd}
                </span>
            </Navbar.Brand>
        </Navbar>
    )
}

export default Maintenance
