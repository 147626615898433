import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const useValidation = () => {
    const { t } = useTranslation()

    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .email(t('InvalidEmail'))
            .required(t('Required')),
        password: Yup.string().required(t('NoPasswordProvided')),
    })
}
