import React, { FC, useCallback } from 'react'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { GameModalType } from '../GameModal/types'
import store, { actions } from '../../store'
import { LocalStorage } from '../../state/storage'
import { useDispatch } from 'react-redux'
import { byog_config } from '../../pages/BYOG/config'

interface INoPublicRooms {
    gameId: string
    type: GameModalType
}

const NoPublicRooms: FC<INoPublicRooms> = ({ gameId, type }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const setGameName = useCallback(
        (name: string) => {
            dispatch({ type: 'SET_GAME_NAME', payload: name })
        },
        [dispatch]
    )

    const onStartHandle = useCallback(() => {
        store.dispatch(actions.session.clearSession())
        localStorage.setItem(LocalStorage.sessionID, '')
        if (type === GameModalType.Regular) {
            setGameName(gameId)
            history.push(`/game/${encodeURIComponent(gameId)}`)
        } else if (type === GameModalType.BYOG) {
            for (const [path, console] of Object.entries(byog_config.session)) {
                if (console === gameId) {
                    history.push(`/byog?${path}`)
                    break
                }
            }
        }
    }, [gameId, history, type, setGameName])

    return (
        <div className={style.noRoom}>
            <div className={style.noRoomImage}>
                <img src={'/piepie_norooms.svg'} alt={''}></img>
            </div>
            <div className={style.noRoomTitle}>{t('NoPublicRoomsTitle')}</div>
            {type !== GameModalType.Mood && (
                <div className={style.noRoomStart} onClick={onStartHandle}>
                    {t('NoPublicRoomsStart')}
                </div>
            )}
        </div>
    )
}

export default NoPublicRooms
