import React, { FC } from 'react'
import cls from 'classnames'
import { useDispatch } from 'react-redux'
import { Button } from '../../stories'
import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { AuthModalType } from '../../store/auth/types'
import { setCurrentView } from '../../store/auth/authV2'

const Banned: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleHomeClick = () => dispatch(setCurrentView(AuthModalType.Login))
    return (
        <div>
            <div className={style.titleWrapper}>
                <div className={style.subtitle}>{t('AuthBannedText')}</div>
            </div>
            <Button
                className={cls(style.btn, style.submitButton)}
                onClick={handleHomeClick}
            >
                {t('GoBackHome')}
            </Button>
        </div>
    )
}

export default Banned
