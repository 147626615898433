import React, { FC, useCallback, useMemo, useState } from 'react'
import { Grid } from './Grid'
import GameItem from './GameItem'
import NoResult from './NoResult'
import { GAME_CATEGORIES } from './types'
import CategoryTitle from './Commons/CategoryTitle'
import {
    Game,
    getSortedSessionMoods,
    SessionMoodType,
} from '../../state/starfox'
import styles from './styles.module.scss'
import { Icon } from '../../stories'
import {
    useRecentlyPlayedGames,
    useTrendingGames,
    getAllGamesCategory,
    getRecentlyAddedGamesCategory,
} from '../../utils/useGamesList'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectCategoryDisplayed,
    setResetFilters,
} from '../../store/home/homeV2'
import { useWindowSize } from '@react-hook/window-size'

type TGamesList = FC<{
    gamesList: ReadonlyArray<Game>
}>

const GameListByCategory: TGamesList = ({ gamesList }) => {
    const [windowWidth] = useWindowSize()
    const [categoryHovered, setCategoryHovered] = useState(null)
    const recentlyPlayedGames = useRecentlyPlayedGames(gamesList)
    const trendingGames = useTrendingGames(gamesList)
    const allGames = getAllGamesCategory(gamesList)
    const recentlyAddedGames = getRecentlyAddedGamesCategory(gamesList)
    const maxNbOfGames = 16
    const smallWindowSize = 768

    const grouped: { [key: string]: Game[] } = useMemo(
        () =>
            GAME_CATEGORIES.reduce(
                (acc, cat) => ({
                    ...acc,
                    [cat]: gamesList.filter((game) => {
                        if (!isMobile && cat === 'Mobile-Featured') return false
                        if (isMobile && cat === 'Featured') return false
                        return game.Categories?.includes(cat)
                    }),
                }),
                {}
            ),
        [gamesList]
    )

    const getSlicedGames = useCallback(
        (games: Game[] | SessionMoodType[], category: string) => {
            if (category === 'Moods' || category === 'BYOG') return games
            return games.slice(0, maxNbOfGames)
        },
        []
    )

    const categoriesArray = Object.entries({
        ...grouped,
        Moods: getSortedSessionMoods(),
        RecentlyPlayed: recentlyPlayedGames,
        RecentlyAdded: recentlyAddedGames,
        Trending: trendingGames,
        AllGames: allGames,
    })

    return (
        <>
            {categoriesArray.map(
                ([category, games]) =>
                    games.length > 0 && (
                        <div
                            key={category}
                            className={styles.category}
                            onMouseEnter={() => setCategoryHovered(category)}
                            onMouseLeave={() => setCategoryHovered(null)}
                        >
                            <Grid.Row
                                title={
                                    category !== 'Featured' &&
                                    category !== 'Mobile-Featured' && (
                                        <CategoryTitle
                                            title={`${category}Category`}
                                            category={category}
                                            isHovered={
                                                category === categoryHovered ||
                                                windowWidth < smallWindowSize
                                            }
                                            displaySeeMore={
                                                games.length > maxNbOfGames ||
                                                windowWidth < smallWindowSize
                                            }
                                        />
                                    )
                                }
                            >
                                {getSlicedGames(games, category).map(
                                    (game, index) => (
                                        <Grid.Item
                                            key={index}
                                            category={category}
                                        >
                                            <GameItem
                                                category={category}
                                                mood={
                                                    category === 'Moods'
                                                        ? game
                                                        : null
                                                }
                                                game={
                                                    category === 'Moods'
                                                        ? null
                                                        : game
                                                }
                                            />
                                        </Grid.Item>
                                    )
                                )}
                            </Grid.Row>
                        </div>
                    )
            )}
        </>
    )
}

const GameListByGrid: TGamesList = ({ gamesList }) => {
    const category = useSelector(selectCategoryDisplayed)
    const dispatch = useDispatch()
    return (
        <div style={{ height: '100%' }}>
            {gamesList.length <= 0 ? (
                <NoResult />
            ) : (
                <>
                    <div className={styles.resultTitle}>
                        <div
                            className={styles.resultArrow}
                            onClick={() => dispatch(setResetFilters())}
                        >
                            <Icon name="resultBack" />
                        </div>
                        <CategoryTitle
                            title={
                                category
                                    ? `${category}Category`
                                    : 'FilterResults'
                            }
                        />
                    </div>
                    <Grid.List>
                        {gamesList.map((game, index) => (
                            <GameItem
                                game={game}
                                mood={null}
                                category={'List'}
                                key={game.id || index}
                            />
                        ))}
                    </Grid.List>
                </>
            )}
        </div>
    )
}

export default {
    UnCategorized: GameListByGrid,
    Categorized: GameListByCategory,
}
