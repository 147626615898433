import React from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { UnavailableReasonEnum } from '../state/unavailable'

export default function Unavailable(props) {
    const reason = ((reason: UnavailableReasonEnum) => {
        switch (reason) {
            case UnavailableReasonEnum.NotSignedIn:
                return <RequireSignIn />
            case UnavailableReasonEnum.Waiting:
                // TODO: add a loader?
                return <></>
            case UnavailableReasonEnum.UnsupportedBrowser:
                return <RequireSupportedBrowser />
            case UnavailableReasonEnum.InsufficientConnection:
                return <RequireFasterConnection />
            case UnavailableReasonEnum.UnsupportedDevice:
                return <RequireDesktopBrowser />
            case UnavailableReasonEnum.UnsupportedBrowserVersion:
                return <RequireRecentBrowser />
            default:
                return <></>
        }
    })(props.reason)
    return reason
}

function RequireSignIn() {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Title>{t('PiepackerComingSoonTitle')}</Modal.Title>
            <p>{t('SignUpSignInTopRight')}</p>
        </>
    )
}

function RequireSupportedBrowser() {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Title>{t('FuckZilla')}</Modal.Title>
            <p>
                {t('Download')}{' '}
                <a className="link" href="https://www.google.com/chrome/">
                    Google Chrome
                </a>{' '}
                {t('Or')}{' '}
                <a className="link" href="https://www.microsoft.com/en-us/edge">
                    Microsoft Edge
                </a>
            </p>
        </>
    )
}

function RequireFasterConnection() {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Title>{t('InsufficientConnectionTitle')}</Modal.Title>
            <p>{t('FourthGenConnection')}</p>
        </>
    )
}

function RequireDesktopBrowser() {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Title>{t('DesktopOnly')}</Modal.Title>
        </>
    )
}

function RequireRecentBrowser() {
    const { t } = useTranslation()
    return (
        <>
            <Modal.Title>{t('OldBrowserTitle')}</Modal.Title>
            <p>{t('OldBrowserDescription')}</p>
        </>
    )
}
