// @ts-nocheck
/* eslint-disable */
import './style.scss'

import React, { Component, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'

// import THREE and some modules:
import { Euler } from 'three'

// import neural network models:
import NN from './mask/contrib/WebARRocksFace/neuralNets/NN_AUTOBONES_21.json'
import NNMobile from './mask/contrib/WebARRocksFace/neuralNets/NN_AUTOBONES_LIGHT_1.json'
//import NNMobile from './mask/contrib/WebARRocksFace/neuralNets/NN_AUTOBONES_10_STATIC.json'

// import main script:
import WEBARROCKSFACE from './mask/contrib/WebARRocksFace/dist/WebARRocksFace.module.js'

// import THREE Helper
// This helper is not minified, feel free to customize it (and submit pull requests bro):
import threeHelper from './mask/contrib/WebARRocksFace/helpers/WebARRocksFaceThreeHelper.js'

// import expressions detector:
import expressionsDetector from './mask/misc/PiepackerExpressionsDetector'

import TWEEN from '@tweenjs/tween.js'
import classNames from 'classnames'

// Secondary components using THREE FIBER:
import ThreeGrabber from './threeSubComponents/ThreeGrabber'
import Loading3DPlaceHolder from './threeSubComponents/Loading3DPlaceHolder'
import Lighting from './threeSubComponents/Lighting'
import Postprocessing from './threeSubComponents/Postprocessing.jsx'
import PostprocessingOutline from './threeSubComponents/PostprocessingOutline'

// Video WebGL effects:
import VideoWebGLLUT from './videoWebGL/VideoWebGLLUT'
import VideoBackgroundRemover from './VideoBackgroundRemover'

// Main mask component:
import ModelContainer from './ModelContainer'

// static background if mannequin mode:
//import mannequinBg from './assets/mannequin/background.jpg'
const mannequinBg = null // null for transparent background

// background if removeBackgroud is enabled
import removeBackgroundBgImage from './assets/backgroundImages/bibliotheque.webp'

// Get config info (in case of WebGL context lost)
import GetConfigInfos from './mask/contrib/GetConfigInfos.js'

// Globals
import MaskGlobals from './MaskGlobals'

// video presets:
import VideoPresets from './VideoPresets'

// size a three renderer:
const size_threeRenderer = (threeRenderer, resolution) => {
    threeRenderer.setSize(resolution.width, resolution.height, false)
    const canvasStyle = threeRenderer.domElement.style
    canvasStyle.removeProperty('width')
    canvasStyle.removeProperty('height')
}

const download = (blob, filename) => {
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = filename
    link.click()
    URL.revokeObjectURL(url)
}

const log_configInfos = (issue) => {
    console.log('An issue occured: ' + issue + '. CONFIGURATION INFORMATIONS:')
    const configInfos = GetConfigInfos.get_all().join('\n')
    console.log(configInfos)
}

const should_renderMask = (props) => {
    return props.isRemoveBackground || props.isMannequin || props.isMaskVisible
}

const forge_videoSettings = (props) => {
    const { videoPreset, isCameraActive, currentVideoInputDevice, logger } =
        props
    if (!isCameraActive || !currentVideoInputDevice) {
        logger('FCFaceMask - do not forge videoSettings')
        return null
    }
    const videoSettings = currentVideoInputDevice.deviceId
        ? { deviceId: currentVideoInputDevice.deviceId }
        : {}

    Object.assign(videoSettings, VideoPresets.get(videoPreset))
    logger('FCFaceMask asked videoSettings: ', videoSettings)
    return videoSettings
}

const toggle_highPerformanceTracking = (isHighPerformanceTrackingRequired) => {
    // Wether we should ask the max tracking performance
    // But it can hurt other part of the application
    // Like the game emulator => don't use in NFS!

    WEBARROCKSFACE.set_scanSettings({
        enableAsyncReadPixels: !isHighPerformanceTrackingRequired,
    })
}

class FaceMask extends Component {
    constructor(props) {
        super(props)

        // initialize state:
        this.state = {
            isFirstMaskLoaded: false, // to fix a weird issue
            isCameraForcedPose: false,
            isLoading: true,
        }

        this.isMaskHiddenByExternalTrigger = false

        // refs. We cannot use useRef() since it is a class component:
        this.parentRef = React.createRef()
        this.layerCanvasesRef = React.createRef()
        this.canvasFaceRef = React.createRef()
        this.canvasCompositeRef = React.createRef()

        // recording and mannequin mode
        this.mediaRecorder = null
        this.mannequinBgImage = new Image()

        this.dispatch_externalTrigger = this.dispatch_externalTrigger.bind(this)

        // compositing:
        this.compositeCtx = null
        this.update_compositeCanvas = this.update_compositeCanvas.bind(this)
        this.captureStream = this.captureStream.bind(this)
        this.closeStream = this.closeStream.bind(this)

        // face detection and face fade out effect:
        this.isFaceDetected = false
        this.isFaceTrackingAlwaysEnabled = false
        this.onFaceDetected = this.onFaceDetected.bind(this)
        this.faceDetectedTweenAlpha = { value: 1 }

        // rendering:
        this.animate = this.animate.bind(this)
        this.apply_forcedPose = this.apply_forcedPose.bind(this)

        // workarounds and dirty fixes:
        this.onResize = this.onResize.bind(this)
        this.timerResize = null
        this.force_animateLoop = this.force_animateLoop.bind(this)

        // temporary three objects (to avoid allocating)
        this.tmpEuler = new Euler()

        this.displayResolution = null // quick and dirty fix

        this.get_resolution = this.get_resolution.bind(this)
    }

    onFaceDetected(isFaceDetected) {
        const shouldRender = should_renderMask(this.props)
        this.isFaceDetected = isFaceDetected && shouldRender
        TWEEN.removeAll()

        if (this.isFaceDetected) {
            this.props.logger('WEBAR: Face detected')
            MaskGlobals.threeObject3D.visible = true
            this.faceDetectedTweenAlpha.value = 1

            if (MaskGlobals.physics) {
                // avoid big zboingzboing when mask appears
                MaskGlobals.physics.forEach((ph) => {
                    ph.needsReset = true
                })
            }
        } else if (shouldRender && !this.props.isPersistent) {
            this.props.logger('WEBAR: Face lost')
            // eslint-disable-next-line
            const tweenMaskFadeOut = new TWEEN.Tween(
                this.faceDetectedTweenAlpha
            )
                // .wait(100, true) // TODO: Add a wait of 1 second before making the mask disappear -- this technique is not working
                .to({ value: 0 }, 600)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    // good optimization: we should not render the mask when not visible:
                    MaskGlobals.threeObject3D.visible = false
                })
                .start()
        }

        if (this.props.onFaceDetectedChange) {
            if (this.isFaceDetected) this.props.onFaceDetectedChange(true)
            else if (shouldRender) this.props.onFaceDetectedChange(false)
            else this.props.onFaceDetectedChange(true)
        }
    }

    dispatch_externalTrigger(event) {
        switch (event.type) {
            case 'animation-start':
            case 'animation-stop':
            case 'animations-reset':
                if (MaskGlobals.animation) {
                    MaskGlobals.animation.dispatch_externalTrigger(event)
                }
                break

            case 'mask-on':
                this.isMaskHiddenByExternalTrigger = false
                break

            case 'mask-off':
                this.isMaskHiddenByExternalTrigger = true
                break

            default:
                throw new Error(
                    'unknow external trigger ' + JSON.stringify(event)
                )
        }
    }

    update_compositeCanvas(threeCanvasAlpha) {
        const cv = this.canvasCompositeRef.current
        const ctx = this.compositeCtx
        // draw the video or static background:
        ctx.globalAlpha = 1
        if (this.props.isMannequin) {
            if (mannequinBg && this.mannequinBgImage.complete) {
                ctx.drawImage(this.mannequinBgImage, 0, 0, cv.width, cv.height)
            } else {
                // transparent background
                ctx.clearRect(0, 0, cv.width, cv.height)
            }
        } else if (!this.props.showVideoFeed) {
            ctx.clearRect(0, 0, cv.width, cv.height)
        } else {
            ctx.drawImage(this.canvasFaceRef.current, 0, 0)
            if (this.props.isRemoveBackground) {
                ctx.globalCompositeOperation = 'destination-in'
                ctx.drawImage(VideoBackgroundRemover.get_domElement(), 0, 0)
                ctx.globalCompositeOperation = 'source-over'
            }
        }

        // draw the 3D:
        if (threeCanvasAlpha > 0 && !!MaskGlobals.threeRenderer) {
            ctx.globalAlpha = threeCanvasAlpha
            ctx.drawImage(MaskGlobals.threeRenderer.domElement, 0, 0)
        }
    }

    start_recording() {
        const stream = this.canvasCompositeRef.current.captureStream(30)
        const isVideoRecordedTransparent = this.props.isMannequin
        // see available mimeTypes here: https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
        const mimeType = isVideoRecordedTransparent
            ? 'video/webm; codecs=vp9'
            : 'video/webm; codecs=h264'
        const mediaRecorder = new MediaRecorder(stream, { mimeType })
        const recordedChunks = []
        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size <= 0) {
                return
            }
            recordedChunks.push(event.data)
            const blob = new Blob(recordedChunks, {
                type: 'video/webm',
            })

            const faceExpressionsTrack = MaskGlobals.animation
                ? MaskGlobals.animation.stop_recording()
                : {}
            const faceExpressionsJson = JSON.stringify({
                // save track as track property instead of root object to make it possible to add metadata for later
                track: faceExpressionsTrack,
            })

            download(blob, 'piepackerMasksRecord.webm')
            download(
                new Blob([faceExpressionsJson], { type: 'text/plain' }),
                'piepackerMasksRecord.json'
            )
            this.mediaRecorder = null
        }
        mediaRecorder.start()
        if (MaskGlobals.animation) {
            MaskGlobals.animation.start_recording()
        }
        this.mediaRecorder = mediaRecorder
    }

    stop_recording() {
        this.mediaRecorder.stop()
    }

    captureStream = () => {
        const stream = this.canvasCompositeRef.current.captureStream()
        this.props.onCameraStreamChange(stream)
    }

    closeStream = () => {
        this.props.onCameraStreamStop()
    }

    apply_forcedPose() {
        if (this.props.forceMaskPose && MaskGlobals.threeObject3D) {
            const pos = this.props.forceMaskPose.position
            const rot = this.props.forceMaskPose.rotation
            this.tmpEuler.set(rot[0], rot[1], rot[2], 'XYZ')
            const mat = MaskGlobals.threeObject3D.parent.matrix
            mat.makeRotationFromEuler(this.tmpEuler).setPosition(
                pos[0],
                pos[1],
                pos[2]
            )
        }
    }

    update_physics() {
        if (MaskGlobals.physics) {
            MaskGlobals.physics.forEach((ph) => {
                ph.update()
            })
        }
    }

    update_torso() {
        if (MaskGlobals.torso) {
            MaskGlobals.torso.update()
        }
    }

    update_mask(detectStates, landmarksStabilized) {
        // update autobones:
        if (MaskGlobals.autobones && !this.props.forceMaskPose) {
            MaskGlobals.autobones.update_fromWebARRocks(
                MaskGlobals.threeCamera,
                landmarksStabilized
            )
        }

        // update expressions triggers:
        if (!this.props.forceMaskPose) {
            expressionsDetector.update(WEBARROCKSFACE, detectStates)
        }
    }

    update_postProcessing() {
        // update postprocessing if required:
        if (
            MaskGlobals.threeOutlineEffect &&
            MaskGlobals.threeOutlineEffect.selection.size === 0 &&
            MaskGlobals.threeObject3D
        ) {
            const selection = MaskGlobals.threeOutlineEffect.selection
            selection.layer = 0 // set layer 0 (default layer) as selected layer
            selection.currentLayer = 0
            selection.set([MaskGlobals.threeObject3D])
        }
    }

    update_maskAnimations() {
        // update predefined animations:
        if (MaskGlobals.threeAnimationMixer) {
            MaskGlobals.threeAnimationMixer.update(
                MaskGlobals.threeClock.getDelta()
            )
        }
    }

    force_animateLoop() {
        this.animate({ isDetected: true }, undefined)
        window.requestAnimationFrame(this.force_animateLoop)
    }

    animate(detectStates, landmarksStabilized) {
        // force display of the mask if pose is forced:
        if (this.props.forceMaskPose) {
            detectStates.isDetected = true
        }

        // face fade out effect:
        if (landmarksStabilized !== undefined) {
            if (detectStates.isDetected && !this.isFaceDetected) {
                this.onFaceDetected(true)
            } else if (!detectStates.isDetected && this.isFaceDetected) {
                this.onFaceDetected(false)
            }
        }

        // update TWEEN:
        TWEEN.update()

        if (
            ((landmarksStabilized === undefined ||
                this.isMaskHiddenByExternalTrigger) &&
                !this.props.isPersistent &&
                !this.props.forceMaskPose) ||
            !MaskGlobals.threeObject3D
        ) {
            this.update_compositeCanvas(0)
            return
        }

        // make sure mask is visible:
        if (detectStates.isDetected && should_renderMask(this.props)) {
            MaskGlobals.threeObject3D.visible = true
        }

        this.apply_forcedPose()
        if (this.isFaceDetected || this.props.forceMaskPose) {
            this.update_physics()
            this.update_torso()
        }
        if (this.isFaceDetected) {
            this.update_mask(detectStates, landmarksStabilized)
        }
        if (this.isFaceDetected || this.props.forceMaskPose) {
            this.update_maskAnimations()
            this.update_postProcessing()
        }

        // force rendering of the scene if the tab has lost focus:
        if (!WEBARROCKSFACE.is_winFocus()) {
            MaskGlobals.threeRenderer.render(
                MaskGlobals.threeScene,
                MaskGlobals.threeCamera
            )
        }

        // background removal rendering:
        if (this.props.isRemoveBackground) {
            VideoBackgroundRemover.update(
                MaskGlobals.threeScene,
                MaskGlobals.threeCamera
            )
        }

        // update composite canvas:
        this.update_compositeCanvas(this.faceDetectedTweenAlpha.value)
    }

    get_resolution(resolutionProp) {
        const resolution = {
            width: 0,
            height: 0,
        }
        let componentSize = {
            width: 0,
            height: 0,
        }
        if (
            this.parentRef.current &&
            (resolutionProp.width === 0 || resolutionProp.height === 0)
        ) {
            componentSize = this.parentRef.current.getBoundingClientRect()
        }
        const dpr = window.devicePixelRatio || 1
        resolution.width =
            resolutionProp.width || componentSize.width * dpr || 368
        resolution.height =
            resolutionProp.height || componentSize.height * dpr || 207
        console.log(
            'INFO in FaceMask - computed display resolution = ',
            resolution
        )

        return resolution
    }

    update_resolution(resolutionProp) {
        const resolution = this.get_resolution(resolutionProp)
        this.displayResolution = resolution

        if (MaskGlobals.threeRenderer) {
            size_threeRenderer(MaskGlobals.threeRenderer, resolution)
        }
        if (MaskGlobals.threeEffectComposer) {
            MaskGlobals.threeEffectComposer.setSize(
                resolution.width,
                resolution.height,
                false
            )
        }

        if (this.canvasFaceRef.current) {
            this.canvasFaceRef.current.width = resolution.width
            this.canvasFaceRef.current.height = resolution.height
        }

        if (threeHelper) {
            threeHelper.resize()
        }

        VideoBackgroundRemover.resize(resolution)
    }

    is_mask() {
        // avoid undefined:
        return (this.props.maskSettings && this.props.maskSettings.model) ||
            this.props.isRemoveBackground ||
            this.props.isMannequin // display mannequin even if there is no mask
            ? true
            : false
    }

    is_trackingEnabled() {
        if (this.isFaceTrackingAlwaysEnabled) {
            return true
        }
        const isMask = this.is_mask()
        const isVideo = this.props.isCameraActive
        return isMask && isVideo && !this.props.forceMaskPose
    }

    get_webarScanSettings() {
        return {
            threshold: 0.8,
            nScaleLevels: this.props.isMobile ? 2 : 3,
            overlapFactors: [2, 2, 4],

            // these parameters are only for WebAR.rocks.face >= 1.4.6:
            isCleanGLStateAtEachIteration: false,
            enableAsyncReadPixels: true, // used only for WebAR.rocks.face >= 1.8.1
            readPixelsAsyncDelay: 3, // used only for WebAR.rocks.face >= 1.8.1. Min (default) value = 1. Increasing delay increases framerate
            // since we wait less with GL.clientWaitSync
            animateProcessOrder: 'DSAR', // D = Detect face, S = Sync to read GPU face detection result, A = Analyze, R = Render. Default = SADR
        }
    }

    get_webarNN() {
        return this.props.isMobile ? NNMobile : NN
    }

    onResize() {
        // avoid a bug on IOS 15.4 (video freeze if orientation changes)
        // set a timeout to be sure that resizing is finished, and to not resize too often
        if (this.timerResize) {
            clearTimeout(this.timerResize)
        }
        this.timerResize = setTimeout(() => {
            if (threeHelper) {
                threeHelper.resize()
                const videoElement = threeHelper.get_videoElement()
                if (videoElement) {
                    videoElement.play()
                }
            }
        }, 100)
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize)

        this.update_resolution(this.props.resolution)

        VideoBackgroundRemover.init({
            resolution: this.get_resolution(this.props.resolution),
            blurBorderWidth: 0.025, // relative to resolution width
        })

        // create a 2D context for the composite canvas:
        this.compositeCtx = this.canvasCompositeRef.current.getContext('2d')
        console.log('INFO: WebAR.rocks.face version =', WEBARROCKSFACE.VERSION)

        // init WEBARROCKSFACE through the helper:
        const { isCameraActive } = this.props

        const videoSettings = forge_videoSettings(this.props)

        threeHelper.init(WEBARROCKSFACE, {
            NN: this.get_webarNN(),
            //animateDelay: 0.001,
            isVisibilityAuto: false,
            isTrackingEnabled: this.is_trackingEnabled(),
            isKeepRunningOnWinFocusLost: false,
            GPUBenchmarkerCallback: (freqOverMaxFreq) => {
                if (freqOverMaxFreq < 0.5) {
                    log_configInfos(
                        'MAJOR GPU PERFORMANCE DROP DETECTED. Freq over max freq = ' +
                            freqOverMaxFreq.toFixed(2)
                    )
                }
            },
            rxOffset: -15 * (Math.PI / 180), // Rotation to reposition the mask tilted down
            translationYZ: [5.0, 0.0], // translate a bit upper 10 -> too upper (gap issue)
            scale: 0.97, // reduce a bit the scale
            scanSettings: this.get_webarScanSettings(),
            stabilizerSettings: {
                // fix https://github.com/piepacker/wagashi/issues/5030
                dampingRatio: 0.8,
            },
            videoSettings, // If set to something else than null, value is not respected.
            canvas: this.canvasFaceRef.current,
            maxFacesDetected: 1,
            callbackReady: (err, spec) => {
                if (err === 'GLCONTEXT_LOST') {
                    // it is very important to get configs where WebGL Context fails
                    log_configInfos('WebGL Context was lost')

                    if (this.props.onContextLost) {
                        WEBARROCKSFACE.toggle_videoStream(false).then(
                            this.props.onContextLost
                        )
                    } else {
                        throw new Error(
                            'GLCONTEXT_LOST - Unhandled (Set FaceMask component onContextLost property to handle it)'
                        )
                    }
                    return
                }
                if (err) throw new Error(err)
                this.setState({ isLoading: false })
                threeHelper.resize()

                this.props.logger(
                    'WEBAR: threeHelper has been initialized successfully'
                )
                // uncomment for debug, then launch simulateContextLost() in the JS console
                //window.simulateContextLost  = spec.GL.getExtension('WEBGL_lose_context').loseContext
                if (isCameraActive) {
                    this.captureStream()
                }
                if (spec.video) {
                    this.props.logger(
                        'FCFaceMask real video resolution: ',
                        spec.video.videoWidth,
                        'x',
                        spec.video.videoHeight
                    )
                }
                // in the studio only, run display loop even if video is null
                if (
                    spec.video === null &&
                    this.props.forceMaskPose &&
                    this.props.isMannequin
                ) {
                    this.setState({ isCameraForcedPose: true })
                    this.force_animateLoop()
                }
                if (this.props.callbackReady) {
                    this.props.callbackReady()
                }
            },
            callbackTrack: this.animate,
            callbackRenderVideo: VideoWebGLLUT.update,
        })

        let interval = null
        // user changed tab, draw image one last time
        this.visibilityListener = () => {
            if (document.hidden) {
                this.canvasCompositeRef.current.getContext('2d').filter =
                    'blur(4px)'
                // let count = 0
                interval = setInterval(() => {
                    this.update_compositeCanvas(
                        this.faceDetectedTweenAlpha.value
                    )
                    // this.props.logger("drawing when hidden", count++)
                }, 1000)
            } else {
                this.canvasCompositeRef.current.getContext('2d').filter = 'none'
                this.update_compositeCanvas(this.faceDetectedTweenAlpha.value)
                if (interval) {
                    // this.props.logger('done drawing when hidden')
                    clearInterval(interval)
                }
            }
        }

        document.addEventListener('visibilitychange', this.visibilityListener)
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextVideoInput = nextProps.currentVideoInputDevice
        const currentVideoInput = this.props.currentVideoInputDevice
        const nextVideoInputId = nextVideoInput ? nextVideoInput.deviceId : null
        const currentVideoInputId = currentVideoInput
            ? currentVideoInput.deviceId
            : null
        const isCameraActive = this.props.isCameraActive
        let shouldUpdate = false

        if (this.props.debug)
            console.log('shouldComponentUpdate', nextProps, this.props)
        if (nextVideoInputId !== currentVideoInputId) {
            this.props.logger(
                'XOX WEBAR: Updating component due to currentVideoInputDevice.deviceId change'
            )
            shouldUpdate = true
        }
        if (nextProps.isCameraActive !== isCameraActive) {
            this.props.logger(
                'XOX WEBAR: Updating component due to camera activation change'
            )
            shouldUpdate = true
        }
        const willRenderMask = should_renderMask(nextProps)
        if (willRenderMask !== should_renderMask(this.props)) {
            const nDetectsPerLoop = willRenderMask ? 0 : 1
            console.log('nDetectsPerLoop =', nDetectsPerLoop)
            WEBARROCKSFACE.set_scanSettings({
                nDetectsPerLoop,
            })
            if (MaskGlobals.threeObject3D) {
                MaskGlobals.threeObject3D.visible = willRenderMask
            }
            shouldUpdate = true
        }

        if (nextProps.isPersistent) {
            MaskGlobals.threeObject3D.visible = true
        }

        window.th = threeHelper
        window.wa = WEBARROCKSFACE
        window.cv = this.canvasFaceRef

        shouldUpdate =
            shouldUpdate ||
            nextProps.className !== this.props.className ||
            nextProps.activeMaskId !== this.props.activeMaskId ||
            nextState.isLoading !== this.state.isLoading ||
            nextProps.videoElement !== this.props.videoElement ||
            nextProps.videoPreset !== this.props.videoPreset ||
            nextProps.resolution.height !== this.props.resolution.height ||
            nextProps.resolution.width !== this.props.resolution.width ||
            nextProps.isMannequin !== this.props.isMannequin

        if (this.props.debug) console.log('||| Final decision', shouldUpdate)

        return shouldUpdate
    }

    update_videoSettings(props) {
        toggle_highPerformanceTracking(props.videoPreset === 'high')
        return WEBARROCKSFACE.update_videoSettings(forge_videoSettings(props))
    }

    /**
     * Whether a stream is capture or cancelled, the action depends on the matrix below
     *
        |                                | device id changes  | device id stays the same |
        |--------------------------------|--------------------|--------------------------|
        | camera activity stays inactive | do nothing         | do nothing               |
        | camera activity stays active   | capture new stream | do nothing               |
        | camera active -> inactive      | cancel stream      | cancel stream            |
        | camera inactive -> active      | capture new stream | capture new stream       |
     */
    componentDidUpdate(prevProps) {
        const prevVideoInput = prevProps.currentVideoInputDevice
        const currentVideoInput = this.props.currentVideoInputDevice
        const prevVideoInputId = prevVideoInput ? prevVideoInput.deviceId : null
        const currentVideoInputId = currentVideoInput
            ? currentVideoInput.deviceId
            : null
        const isCameraActive = this.props.isCameraActive
        const prevCameraActive = prevProps.isCameraActive

        const resolution = this.get_resolution(this.props.resolution)
        const isSameResolution =
            this.displayResolution.width === resolution.width &&
            this.displayResolution.height === resolution.height

        if (
            !isSameResolution &&
            prevProps.videoPreset !== this.props.videoPreset
        ) {
            this.update_videoSettings(this.props).then(() => {
                this.update_resolution(this.props.resolution)
            })
        } else if (!isSameResolution) {
            this.update_resolution(this.props.resolution)
        } else if (prevProps.videoPreset !== this.props.videoPreset) {
            this.update_videoSettings(this.props)
        }

        const updateVideo = async () => {
            // update video settings, then capture the stream
            try {
                const videoElement = await WEBARROCKSFACE.update_videoSettings({
                    deviceId: currentVideoInputId,
                })
                threeHelper.set_videoElement(videoElement)
                this.props.logger('WEBAR: video settings updated successfully')
                this.props.logger(
                    'XOX WEBAR componentDidUpdate: video settings updated, currentVideoInput: ',
                    currentVideoInput
                )
                this.captureStream()
            } catch (error) {
                this.props.errorLogger(
                    'WEBAR: an error occurred while updating video settings, error: ',
                    error
                )
            }
        }
        const closeVideo = async () => {
            this.props.logger('WEBAR: video settings updated successfully')
            this.props.logger(
                'XOX WEBAR componentDidUpdate: video settings updated, currentVideoInput: ',
                currentVideoInput
            )
            this.closeStream()
            await WEBARROCKSFACE.update_videoSettings(null)
            threeHelper.set_videoElement(null)
            // need to re-access the context from the canvas
            // weird: why does calling on this.compositeCtx not work?
            this.canvasCompositeRef.current
                .getContext('2d')
                .clearRect(0, 0, 368, 207)
        }

        // see if we should update NN:
        if (prevProps.isMobile !== this.props.isMobile) {
            WEBARROCKSFACE.update({
                NN: this.get_webarNN(),
                scanSettings: this.get_webarScanSettings(),
            })
        }

        // update WebAR.rocks.face video once it is playing:
        if (this.props.videoElement) {
            const vidElt = this.props.videoElement
            this.props.logger(
                'WEBAR componentDidUpdate: update videoElement from external. Resolution =',
                vidElt.videoWidth,
                '*',
                vidElt.videoHeight
            )
            vidElt.addEventListener('play', () => {
                threeHelper.update_video(vidElt, () => {
                    console.log(
                        'Camera video has been successfully replaced by debug video'
                    )
                })
            })
            return
        }

        console.log('WEBAR: componentDidUpdate', prevProps, this.props)

        if (prevVideoInputId === currentVideoInputId) {
            // device id stays the same

            // camera activity stays the same => do nothing
            if (prevCameraActive === isCameraActive) return

            // camera active -> inactive => cancel stream
            if (prevCameraActive && !isCameraActive) {
                closeVideo()
                return
            }

            // camera inactive -> active => capture new stream
            updateVideo()
        } else {
            // device id changes

            if (prevCameraActive && isCameraActive) {
                // camera activity stays active => capture new stream
                updateVideo()
                return
            }
            if (!prevCameraActive && !isCameraActive) {
                // camera activity stays inactive  => do nothing
                return
            }
            if (prevCameraActive && !isCameraActive) {
                // camera active -> inactive  => cancel stream
                closeVideo()
                return
            }

            // camera inactive -> active => capture new stream
            updateVideo()
        }
    }

    componentWillUnmount() {
        if (this.timerResize) {
            this.clearTimeout(this.timerResize)
            this.timerResize = null
        }
        window.removeEventListener('resize', this.onResize)

        MaskGlobals.threeOutlineEffect = null
        MaskGlobals.threeCamera = null
        document.removeEventListener(
            'visibilitychange',
            this.visibilityListener
        )
        return WEBARROCKSFACE.destroy()
    }

    // this function is useless in NFS:
    download_autobones() {
        if (!MaskGlobals.autobones) {
            alert('Autobones are not used in this mask')
            return
        }
        MaskGlobals.autobones
            .download_currentDeformedModel()
            .then(function (gltf) {
                download(gltf.blob, gltf.filename)
            })
    }

    get_threeCanvas() {
        return MaskGlobals.threeRenderer.domElement
    }

    render() {
        // reset postprocessing effects:
        MaskGlobals.threeOutlineEffect = null
        if (MaskGlobals.threeRenderer) {
            // fix #4031
            MaskGlobals.threeRenderer.autoClear =
                this.props.maskSettings.postprocessing &&
                this.props.maskSettings.postprocessing.outline
                    ? false
                    : true
        }

        const displayPlaceholder =
            !this.props.isCameraActive || this.state.isLoading

        if (!this.isFaceTrackingAlwaysEnabled) {
            const isTrackingEnabled = this.is_trackingEnabled()
            console.log('Toggle face tracking ', isTrackingEnabled)
            WEBARROCKSFACE.toggle_tracking(isTrackingEnabled)
        }

        if (this.props.isMannequin && mannequinBg) {
            this.mannequinBgImage.src = mannequinBg
        }

        // handle video effects:
        if (this.props.maskSettings && !this.state.isLoading) {
            VideoWebGLLUT.init(
                threeHelper,
                this.props.maskSettings.videoPostprocessing
            )
        }

        const res = this.get_resolution(this.props.resolution)

        // generate canvases:
        console.log('Rerender FaceMask...')
        return (
            <div
                ref={this.parentRef}
                className={`FaceMask ${this.props.className}`}
            >
                {/* We hide the 2 canvas since we only display the compositing canvas */}
                <div
                    ref={this.layerCanvasesRef}
                    className="FaceMask-layerCanvases"
                    style={{
                        position:
                            'fixed' /* this is important since this element will be hidden using
                                 visibility property and not display property
                                 so it keeps its place in the DOM. */,
                        PointerEvent: 'none',
                    }}
                >
                    {/* Canvas managed by three fiber, for AR: */}
                    <Canvas
                        gl={{
                            preserveDrawingBuffer: true, // allow image capture
                        }}
                        style={{
                            width: res.width,
                            height: res.height,
                        }}
                        width={res.width}
                        height={res.height}
                        updateDefaultCamera={false}
                        onCreated={(threeFiber) => {
                            // should fix the race condition where everything is black:
                            size_threeRenderer(threeFiber.gl, res)
                            // DO NOT USE display = none because then THREE-Fiber will measure the canvas
                            // as null size, and will resize it crappily
                            this.layerCanvasesRef.current.style.visibility =
                                'hidden'
                        }}
                    >
                        <ThreeGrabber
                            isCameraForcedPose={this.state.isCameraForcedPose}
                            globals={MaskGlobals}
                            threeHelper={threeHelper}
                            resolution={res}
                        />

                        {this.is_mask() && (
                            <Suspense
                                fallback={
                                    <Loading3DPlaceHolder
                                        onMaskLoadingStart={
                                            this.props.onMaskLoadingStart
                                        }
                                    />
                                }
                            >
                                <ModelContainer
                                    threeHelper={threeHelper}
                                    globals={MaskGlobals}
                                    decryptionKey={this.props.decryptionKey}
                                    expressionsDetector={expressionsDetector}
                                    isFaceExpressionsDetection={
                                        this.props.isFaceExpressionsDetection
                                    }
                                    WEBARROCKSFACE={WEBARROCKSFACE}
                                    GLTFModel={
                                        this.props.maskSettings &&
                                        this.props.maskSettings.model
                                    }
                                    faceIndex={0}
                                    isFirstMaskLoaded={
                                        this.state.isFirstMaskLoaded
                                    }
                                    isMannequin={this.props.isMannequin}
                                    isRemoveBackground={
                                        this.props.isRemoveBackground
                                    }
                                    isCameraActive={this.props.isCameraActive}
                                    maskSettings={this.props.maskSettings}
                                    logger={this.props.logger}
                                    showOccluders={this.props.showOccluders}
                                    resolution={res}
                                    onMaskLoadingEnd={() => {
                                        if (!this.state.isFirstMaskLoaded) {
                                            this.setState({
                                                isFirstMaskLoaded: true,
                                            })
                                        }
                                        if (this.props.onMaskLoadingEnd)
                                            this.props.onMaskLoadingEnd()
                                    }}
                                />

                                {this.props.maskSettings && (
                                    <Lighting
                                        globals={MaskGlobals}
                                        maskShading={
                                            this.props.maskSettings.shading || {
                                                type: 'regular',
                                            }
                                        }
                                    />
                                )}
                            </Suspense>
                        )}
                        {
                            /* Postprocessing if necessary: */
                            this.props.maskSettings.postprocessing && (
                                <Postprocessing
                                    globals={MaskGlobals}
                                    resolution={res}
                                    maskPostprocessing={
                                        this.props.maskSettings.postprocessing
                                    }
                                />
                            )
                        }
                    </Canvas>

                    {/* Canvas managed by WebAR.rocks, just displaying the video (and used for WebGL computations) */}
                    <canvas
                        data-debug="CleanVideoFeed"
                        ref={this.canvasFaceRef}
                        width={res.width}
                        height={res.height}
                        style={{
                            visibility: 'hidden',
                        }}
                    />
                </div>

                {displayPlaceholder && <div className="FaceMask-placeholder" />}

                {/* Compositing canvas */}
                <canvas
                    data-test="CanvasFace"
                    className={classNames(
                        'FaceMask-mergeCanvas',
                        !this.props.isCameraActive && 'disabled'
                    )}
                    ref={this.canvasCompositeRef}
                    width={res.width}
                    height={res.height}
                />

                {/* If background removal is enabled, display an image in the background
                  Since a uniform background looks very bad (it underlines the border artifacts) */}
                {this.props.isRemoveBackground && (
                    <div
                        style={{
                            backgroundImage: `url(${removeBackgroundBgImage})`,
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                        }}
                    />
                )}
            </div>
        )
    }
}

FaceMask.defaultProps = {
    ref: null,
    resolution: {
        width: 0, // 0 -> auto
        height: 0, // 0 -> auto
    },
    isMobile: false,
    activeMaskId: 'undef',
    isMannequin: false,
    isPersistent: false,
    maskSettings: null,
    showOccluders: false,
    isMaskVisible: true,
    isFaceExpressionsDetection: true,
    currentVideoInputDevice: 'auto',
    isCameraActive: true,
    onCameraStreamChange: null,
    onCameraChange: null,
    onCameraStreamStop: null,
    onContextLost: null,
    logger: null,
    errorLogger: null,
    onFaceDetectedChange: null,
    showVideoInputFileButton: true,
    videoElement: null,
    videoPreset: 'low',
    decryptionKey: null,
    forceMaskPose: null,
    isRemoveBackground: false,
    showVideoFeed: false,
}

export default FaceMask
