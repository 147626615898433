import React, { FC, useEffect, useRef, useState } from 'react'
import Card from './Card/Card'
import BYOG from './Card/BYOG'
import Mood from './Card/Mood'
import { Game, SessionMoodType } from '../../state/starfox'
import { GameModalType } from '../../components/GameModal/types'
import { formatDistance } from 'date-fns'
import { TGameCategories } from './types'
import { CardKind } from './Card/types'
import { v1 as uuid } from 'uuid'
import Modal from '@material-ui/core/Modal'
import GameModal from '../../components/GameModal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { useCloseGameModal, useOpenGameModal, useSelect } from './useGameSelect'
import { selectOpenRooms } from '../../store/home/homeV2'
import { getNumberOfOpenRoomsByGame } from '../../utils/helpers'

const gameKind: Partial<Record<TGameCategories | 'List', CardKind['kind']>> = {
    RecentlyPlayed: 'recent',
    Featured: 'featured',
    List: 'recent',
    'Mobile-Featured': 'featured',
}
interface IGameItem {
    game: Game & { Stopped?: { seconds: number } }
    mood: SessionMoodType
    category: string
}

const GameItem: FC<IGameItem> = ({ category, game, mood }) => {
    const roomsOpened = useSelector(selectOpenRooms)
    const isFetched = useRef(false)
    const [key, setKey] = useState('')
    const tempKey = useRef('')
    const [gameModalType, setGameModalType] = useState<GameModalType>(
        GameModalType.Regular
    )
    const gameModalInstance = useSelector(
        (state: State) => state.home.gameModalInstance
    )
    const parsedDate =
        game?.Stopped &&
        game.Stopped?.seconds > 0 &&
        formatDistance(new Date(), game.Stopped?.seconds * 1000, {
            includeSeconds: true,
        })

    const handleClick = useSelect({ game })
    const handleOpenGameModal = useOpenGameModal({
        isBYOG: category === 'BYOG' || game?.Categories?.includes('BYOG'),
        key: key,
        gameId: game?.id,
        mood: mood,
        setGameModalType: setGameModalType,
    })
    const handleCloseGameModal = useCloseGameModal()

    useEffect(() => {
        // used to read the url and open the game modal if needed
        if (!isFetched.current) {
            tempKey.current = uuid()
            setKey(tempKey.current)
            isFetched.current = true
            const url = new URL(window.location.href)
            const details = url.searchParams.get('details')
            if (
                details &&
                (details === game?.id || details === mood) &&
                !game?.Competition
            ) {
                handleOpenGameModal(tempKey.current)
            }
        }
    }, [mood, game, category, handleOpenGameModal])

    return (
        <>
            {category === 'BYOG' ? (
                <BYOG game={game} handleOpenGameModal={handleOpenGameModal} />
            ) : category === 'Moods' ? (
                <Mood mood={mood} handleOpenGameModal={handleOpenGameModal} />
            ) : (
                <Card
                    game={game}
                    date={parsedDate}
                    category={category}
                    title={game.DisplayName}
                    kind={gameKind[category] ?? 'generic'}
                    availablePublicRooms={
                        roomsOpened
                            ? getNumberOfOpenRoomsByGame(roomsOpened, game)
                            : 0
                    }
                    introText={
                        (category === 'Featured' ||
                            category === 'Mobile-Featured') &&
                        game?.ShortDescription?.EN
                    }
                    handleClick={handleClick}
                    handleOpenGameModal={handleOpenGameModal}
                    gradientColor={game?.GradientColor}
                />
            )}
            <Modal
                open={key === gameModalInstance}
                onClose={handleCloseGameModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
                disableEnforceFocus
            >
                <Fade
                    in={key === gameModalInstance}
                    appear={key === gameModalInstance}
                    timeout={300}
                >
                    <div>
                        <GameModal
                            game={game}
                            mood={mood}
                            type={gameModalType}
                            close={handleCloseGameModal}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default GameItem
