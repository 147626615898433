import style from './style.module.scss'
import React, { FC } from 'react'
import cls from 'classnames'
import { Icon } from '../../'
import ControllerConversionMap from './ControllerConversionMap.json'
import IconConversionMap from './IconConversionMap.json'

interface HintIconProps {
    className?: string
    controllerType?: ControllerType
    buttonName: string | HintButton
    label: string
}

export enum ControllerType {
    KeyboardQWERTY = 'keyboard-qwerty',
    KeyboardAZERTY = 'keyboard-azerty',
    Playstation = 'playstation',
    Xbox = 'xbox',
    Nintendo = 'nintendo',
}

enum HintButton {
    A = 'A',
    B = 'B',
    X = 'X',
    Y = 'Y',
    L1 = 'L1',
    R1 = 'R1',
    L2 = 'L2',
    R2 = 'R2',
    JOYSTICKLEFT = 'JOYSTICKLEFT',
    JOYSTICKRIGHT = 'JOYSTICKRIGHT',
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    SELECT = 'SELECT',
    START = 'START',
}

const KeyboardSpecialKeys = ['SELECT', 'START', 'JOYSTICKRIGHT', 'JOYSTICKLEFT']

const HintIcon: FC<HintIconProps> = ({
    className,
    controllerType = ControllerType.KeyboardQWERTY,
    buttonName,
    label,
}) => {
    return (
        <li className={cls(className, style.hintIcon)}>
            {(controllerType !== ControllerType.KeyboardQWERTY &&
                controllerType !== ControllerType.KeyboardAZERTY) ||
            KeyboardSpecialKeys.includes(buttonName) ? (
                <Icon
                    className={style.icon}
                    name={
                        IconConversionMap[
                            ControllerConversionMap[controllerType][buttonName]
                        ]
                    }
                />
            ) : (
                <span className={style.keyboardButton}>
                    {ControllerConversionMap[controllerType][buttonName]}
                </span>
            )}
            <span className={style.label}>{label}</span>
        </li>
    )
}

export default HintIcon
