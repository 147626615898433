import React, { FC } from 'react'
import { Button, Icon } from '../..'

import { VolumeSlider } from '../../atoms/VolumeSlider'
import style from './style.module.scss'
import cls from 'classnames'
import { Tooltip } from '../../atoms/Tooltip'
import { GameroomModalSectionType } from '../../../state/gameroomModalSection'

type TooltipTexts = {
    play: string
    pause: string
    displaySaveStates: string
    switchGame: string
    quickSave: string
    settings: string
    showHints: string
    theaterMode: string
}

interface GameroomControlsProps {
    isHost?: boolean
    isPaused: boolean
    handlePlayPause: () => void
    handleQuickSave: () => void
    disableSavestates: boolean
    handleSwitchGame: () => void
    handleTwitch?: () => void
    handleSettings?: () => void
    handleShowHints: () => void
    handleSetTheater: () => void
    handleDisplaySaveStates?: () => void
    volume: number
    setVolume: (volume: number) => void
    isTheater?: boolean
    tooltipTexts: TooltipTexts
    gameroomModalSection?: GameroomModalSectionType
}

const GameroomControls: FC<GameroomControlsProps> = ({
    isHost,
    isPaused,
    handlePlayPause,
    handleQuickSave,
    disableSavestates,
    handleSwitchGame,
    // handleTwitch,
    handleSettings,
    handleShowHints,
    handleSetTheater,
    handleDisplaySaveStates,
    volume,
    setVolume,
    isTheater = false,
    tooltipTexts,
    gameroomModalSection,
}) => {
    return (
        <>
            <div className={style.gameroomControlsContainer}>
                <div className={style.maingroup}>
                    <Tooltip
                        position="right"
                        text={isPaused ? tooltipTexts.play : tooltipTexts.pause}
                    >
                        <Button
                            variant="square"
                            onClick={(event) => {
                                event.preventDefault()
                                // Fire onPausePlay function only when an event is initiated by a mouse click, not on an enter click.
                                if (event.detail !== 0) {
                                    handlePlayPause()
                                }
                            }}
                            data-test="GamePauseOrPlay"
                            className={cls(
                                isPaused &&
                                    gameroomModalSection ===
                                        GameroomModalSectionType.PAUSE &&
                                    style.pausedGame
                            )}
                            disabled={!isHost}
                        >
                            {isPaused ? (
                                <Icon name="plainPlay" />
                            ) : (
                                <Icon name="plainPause" />
                            )}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        position="right"
                        text={tooltipTexts.displaySaveStates}
                    >
                        <Button
                            variant="square"
                            onClick={handleDisplaySaveStates}
                            disabled={!isHost}
                            className={cls(
                                gameroomModalSection ===
                                    GameroomModalSectionType.LOAD &&
                                    style.pausedGame
                            )}
                        >
                            <Icon name="load" />
                        </Button>
                    </Tooltip>
                    <Tooltip position="right" text={tooltipTexts.switchGame}>
                        <Button
                            variant="square"
                            onClick={handleSwitchGame}
                            disabled={!isHost}
                            className={cls(
                                gameroomModalSection ===
                                    GameroomModalSectionType.SWITCH &&
                                    style.pausedGame
                            )}
                        >
                            <Icon name="switchGame" />
                        </Button>
                    </Tooltip>
                    {/* _ */}
                    {/* <Button variant="square" onClick={handleTwitch}>
                        <Icon name="twitch" />
                    </Button> */}

                    <Tooltip position="right" text={tooltipTexts.settings}>
                        <Button
                            variant="square"
                            onClick={handleSettings}
                            disabled={!isHost}
                            className={cls(
                                gameroomModalSection ===
                                    GameroomModalSectionType.SETTINGS &&
                                    style.pausedGame
                            )}
                        >
                            <Icon name="settings" />
                        </Button>
                    </Tooltip>
                </div>
                <div className={style.subgroup}>
                    {!disableSavestates && (
                        <Tooltip position="right" text={tooltipTexts.quickSave}>
                            <button
                                className={style.subgroupButton}
                                onClick={handleQuickSave}
                                data-test="GameSave"
                                disabled={!isHost}
                            >
                                <Icon name="quickSave" />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip position="right" text={tooltipTexts.showHints}>
                        <button
                            className={cls(style.subgroupButton, style.hints)}
                            type="button"
                            onClick={handleShowHints}
                        >
                            <Icon name="videoGame" />
                        </button>
                    </Tooltip>

                    <div className={style.volumePlaceholder}>
                        <button className={style.volumeWrapper}>
                            <VolumeSlider
                                volume={volume}
                                setVolume={setVolume}
                                withToggle
                            />
                        </button>
                    </div>

                    <Tooltip position="right" text={tooltipTexts.theaterMode}>
                        <button
                            className={style.subgroupButton}
                            type="button"
                            onClick={handleSetTheater}
                        >
                            {isTheater ? (
                                <Icon name="fullscreenOn" />
                            ) : (
                                <Icon name="fullscreen" />
                            )}
                        </button>
                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default GameroomControls
