import React, { FC, useRef, useState, useCallback, useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { Avatar, Tooltip } from '@material-ui/core'
import { ReactComponent as DragIndicatorIcon } from '../assets/drag-indicator.svg'
import { ReactComponent as GamePadIcon } from '../assets/gamepad.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg'
import style from './style.module.scss'
import { useClickOutside } from '../../../utils'
import { Device, LocalPlayer } from '../../../handlers/InputHandler'
import store, { actions } from '../../../store'
import { globalPubSub, PubSubEvent } from '../../../event/event'
import { useSelector, useDispatch } from 'react-redux'
import { State } from '../../../store/types'
import { Icon, Button } from '../../../stories'
import { Participant } from '../../../state/conference'
import { confirmModalBan, confirmModalMute } from '../../../store/modal/actions'
import { isMobile } from 'react-device-detect'

interface FacesPlayerProps {
    index: number
    photoUrl: string
    username: string
    draggedElementIndex: number
    controller: string
    devices: Device[]
    local: boolean
    localPlayers: LocalPlayer[]
    localhost: boolean
    hasGoldLetters?: boolean
    userId: string
    userSessionId: string
    focus?: boolean
    onDragStart: (e, index: number, device?: string) => void
    onDragEnd: () => void
    onDrop: (e) => void
    onDragOver: (e) => void
    removeLocalPlayer: (index?: number) => void
    onSwitchPlayerSelected: (index: number) => void
    onSettingsOpenChange: (isOpen: boolean) => void
}

const FacesPlayer: FC<FacesPlayerProps> = ({
    index,
    photoUrl,
    username,
    draggedElementIndex,
    controller,
    devices,
    local,
    localPlayers,
    localhost,
    hasGoldLetters = false,
    userSessionId,
    userId,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDrop,
    removeLocalPlayer,
    focus,
    onSwitchPlayerSelected,
    onSettingsOpenChange,
}) => {
    const dispatch = useDispatch()
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const isHost = useSelector((state: State) => state.session.isHost)
    const [isControllersMenuOpen, setIsControllersMenuOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const settingsMenuRef = useRef(null)
    const controllersMenuRef = useRef(null)
    const { t } = useTranslation()
    const rootRef = useRef(undefined)
    const participant: Participant = useSelector(
        (state: State) => state.conference.participants[userSessionId]
    )
    useClickOutside(controllersMenuRef, () => setIsControllersMenuOpen(false))

    useEffect(() => {
        if (rootRef.current && focus) {
            rootRef.current.scrollIntoView()
            setIsSettingsOpen(true)
        }
    }, [focus, rootRef])

    const switchPadHandler = useCallback(
        (padId: number) => {
            const lp = localPlayers
            for (let i = 0; i < lp.length; i++) {
                if (lp[i].device === padId) {
                    lp[i].device = null
                }
                if (lp[i].index === index) {
                    lp[i].device = padId
                }
            }
            store.dispatch(actions.session.setLocalPlayers(lp.slice()))
            globalPubSub.pub(PubSubEvent.LOCAL_PLAYERS_CHANGED, '')
        },
        [localPlayers, index]
    )

    return (
        <div
            className={cls(
                style.player,
                isMobile && style.backdrop,
                isMobile && index === draggedElementIndex && style.highlighted
            )}
            ref={rootRef}
        >
            {isMobile ? (
                <button
                    className={style.mobilePlayerIndex}
                    disabled={!isHost}
                    onClick={() => onSwitchPlayerSelected(index)}
                >
                    <i className={style.playerHeading}>{`P${index + 1}`}</i>
                    <Icon name="triangleDown" />
                </button>
            ) : (
                <i className={style.playerHeading}>{`P${index + 1}`}</i>
            )}

            {draggedElementIndex !== null &&
            draggedElementIndex !== index &&
            !isMobile ? (
                <div
                    data-test={'MultiplayerDropZone' + (index + 1)}
                    className={cls(
                        style.playerContainerSwitch,
                        isHovered && style.playerContainerSwitchHovered
                    )}
                    onDragOver={(e) => {
                        setIsHovered(true)
                        onDragOver(e)
                    }}
                    onDragLeave={() => setIsHovered(false)}
                    onDrop={onDrop}
                    data-drop-index={index}
                >
                    {t('SwitchLocalPlayer', { index: index + 1 })}
                </div>
            ) : username ? (
                // if the player is not the host, we display a tooltip on hover
                <Tooltip
                    arrow
                    title={isHost ? '' : t('CannotSwitchIndex')}
                    placement="bottom"
                >
                    <div className={style.playerAccordeon}>
                        <div
                            data-test={'MultiplayerPlayerCard' + (index + 1)}
                            className={style.playerContainer}
                            draggable={!isMobile}
                            onDragEnd={onDragEnd}
                            onDragStart={(e) => {
                                setIsSettingsOpen(false)
                                onSettingsOpenChange(false)
                                onDragStart(e, index)
                            }}
                        >
                            <div className={style.playerLeft}>
                                {!isMobile && <DragIndicatorIcon />}

                                <div className={style.playerInfo}>
                                    <div className={style.avatarContainer}>
                                        <Avatar
                                            className={style.avatarImg}
                                            src={photoUrl}
                                        />
                                    </div>

                                    <div
                                        className={style.playerUsernameDevices}
                                    >
                                        <div
                                            className={cls(
                                                style.username,
                                                hasGoldLetters && 'goldLetters'
                                            )}
                                        >
                                            {username}
                                        </div>
                                        {/* We allow to see the gamepad only for local players */}
                                        {local && (
                                            <button
                                                data-test={
                                                    'MultiplayerControllerMenu' +
                                                    (index + 1)
                                                }
                                                onClick={() =>
                                                    setIsControllersMenuOpen(
                                                        !isControllersMenuOpen
                                                    )
                                                }
                                                className={style.devices}
                                                ref={controllersMenuRef}
                                            >
                                                <GamePadIcon
                                                    className={
                                                        style.gamepadIcon
                                                    }
                                                />
                                                <div
                                                    data-test={
                                                        'MultiplayerPlayerController' +
                                                        (index + 1)
                                                    }
                                                    className={style.device}
                                                >
                                                    {controller === 'Keyboard'
                                                        ? t('Keyboard')
                                                        : controller}
                                                </div>
                                                <div
                                                    className={cls(
                                                        style.menu,
                                                        style.controllerMenu,
                                                        {
                                                            [style.menuOpen]:
                                                                isControllersMenuOpen,
                                                        }
                                                    )}
                                                >
                                                    <ul>
                                                        {devices.map(
                                                            (
                                                                device: Device
                                                            ) => (
                                                                <li
                                                                    value={
                                                                        device.id
                                                                    }
                                                                    key={
                                                                        device.id
                                                                    }
                                                                >
                                                                    <button
                                                                        data-test={
                                                                            'MultiplayerSwitchController' +
                                                                            (index +
                                                                                1)
                                                                        }
                                                                        onClick={() => {
                                                                            switchPadHandler(
                                                                                device.id
                                                                            )
                                                                        }}
                                                                    >
                                                                        {device.name ===
                                                                        'Keyboard'
                                                                            ? t(
                                                                                  'Keyboard'
                                                                              )
                                                                            : device.name}
                                                                    </button>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <ArrowDownIcon
                                                    className={
                                                        style.arrowDownIcon
                                                    }
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {local && !localhost && (
                                <div
                                    className={style.settings}
                                    ref={settingsMenuRef}
                                >
                                    <Button
                                        variant="clear"
                                        data-test={
                                            'MultiplayerPlayerMenu' +
                                            (index + 1)
                                        }
                                        onClick={() => removeLocalPlayer(index)}
                                    >
                                        <Icon name="delete" />
                                    </Button>
                                </div>
                            )}

                            {!local && !localhost && (
                                <Button
                                    variant="clear"
                                    data-test={
                                        'MultiplayerPlayerMenu' + (index + 1)
                                    }
                                    onClick={() => {
                                        onSettingsOpenChange(!isSettingsOpen)
                                        setIsSettingsOpen(!isSettingsOpen)
                                    }}
                                >
                                    {isSettingsOpen ? (
                                        <Icon name="chevronUp" />
                                    ) : (
                                        <Icon name="chevronDown" />
                                    )}
                                </Button>
                            )}
                        </div>
                        {isSettingsOpen && !localhost && !local && (
                            <div className={style.playerAccordeonOptions}>
                                <button
                                    className={style.playerAccordeonAction}
                                    disabled={participant?.isMuted}
                                    onClick={() =>
                                        dispatch(
                                            confirmModalMute({
                                                id: userSessionId,
                                            })
                                        )
                                    }
                                >
                                    <Icon
                                        width={24}
                                        height={24}
                                        name={
                                            participant?.isMuted
                                                ? 'micOff'
                                                : 'mic'
                                        }
                                    />
                                    <span
                                        className={
                                            style.playerAccordeonActionLabel
                                        }
                                    >
                                        {participant?.isMuted
                                            ? 'player is muted'
                                            : 'mute player'}
                                    </span>
                                </button>
                                {isHost && (
                                    <button
                                        className={style.playerAccordeonAction}
                                        onClick={() =>
                                            dispatch(
                                                confirmModalBan({
                                                    UID: userId,
                                                    UserSessionId:
                                                        userSessionId,
                                                    nickname: username,
                                                })
                                            )
                                        }
                                    >
                                        <Icon
                                            width={24}
                                            height={24}
                                            name="remove"
                                        />
                                        <span
                                            className={
                                                style.playerAccordeonActionLabel
                                            }
                                        >
                                            ban player
                                        </span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </Tooltip>
            ) : (
                <div
                    data-test={'MultiplayerPlayerPlaceholder' + (index + 1)}
                    className={style.playerContainerSwitch}
                >
                    {t('EmptyPlayerPlaceholder')}
                </div>
            )}
        </div>
    )
}

export default FacesPlayer
