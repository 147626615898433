import React from 'react'

const DebugCube = (props) => {
    const s = props.size || 1
    const position = props.position || [0, 0, 0]

    if (props.onMaskLoadingStart) props.onMaskLoadingStart()

    return (
        <mesh name="debugCube" position={position}>
            <boxBufferGeometry args={[s, s, s]} />
            <meshNormalMaterial />
        </mesh>
    )
}

export default DebugCube
