import React, { useState, useEffect, useRef, useCallback } from 'react'
import { globalPubSub, PubSubEvent } from '../../event/event'
import style from './style.module.scss'

import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import { getSession } from '../../firebase'
import store, { actions } from '../../store'
import Savestate from './Savestate'
import ConfirmModal from '../ConfirmModal'
import { ConfirmDialog } from '../../state/confirm'
import { isByogMode } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../store/types'
import { useTranslation } from 'react-i18next'
import {
    FREE_SAVE_LIMIT,
    isPremium,
    PREMIUM_SAVE_LIMIT,
} from '../../utils/subscription'
import cls from 'classnames'
import screenSlice from '../../store/screen/screen'
import { useRefreshSaveStates, useSaveStates } from '../../utils/GameRoomHooks'
import { Button, Icon } from '../../stories'
import { ScreenType } from '../Screen/screen-props'
import { saveGame } from '../../network/socket'

export interface SavestatesProps {
    user: firebase.User
    gameName: string
    gameScreenWidth?: number
    idleState: boolean
    callback: any
}

export default function Savestates(props: SavestatesProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const pendingRemove = useRef(null)

    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )
    const [confirmOverrideDialog, setConfirmOverrideDialog] =
        useState<ConfirmDialog>(ConfirmDialog.None)
    const [showOverrideDialog, setShowOverrideDialog] = useState(false)
    const [saveLimit, setSaveLimit] = useState(FREE_SAVE_LIMIT)
    const [sliderLoading, setSliderLoading] = useState(false)
    // const [currentMousePressedTime, setCurrentMousePressedTime] =
    //     useState<Date>(null)

    const savestates = useSelector((state: State) => state.session.saveStates)
    const user = useSelector((state: State) => state.user.extra)
    const features = useSelector((state: State) => state.user.features)
    const screenType = useSelector((state: State) => state.screen.screenType)

    useEffect(() => {
        if (isPremium(user) || features.UNLIMITEDSAVESTATES) {
            setSaveLimit(PREMIUM_SAVE_LIMIT)
        }
    }, [user, features.UNLIMITEDSAVESTATES])

    const { refresh } = useRefreshSaveStates(setSliderLoading, props.user.uid)

    const removeHandler = useCallback((savestate: string) => {
        pendingRemove.current = savestate
        store.dispatch(screenSlice.actions.setTheater(false))
        store.dispatch(screenSlice.actions.setFullscreen(false))
        setConfirmDialog(ConfirmDialog.SaveDelete)
    }, [])

    const remove = useCallback(async () => {
        if (!pendingRemove.current) return
        const storageRef = firebase.storage().ref()
        let gameName = props.gameName
        if (isByogMode(props.gameName)) {
            const session = await getSession(store.getState().session.sessionID)
            const lastGameIndex = session.Games.length - 1
            gameName = 'BYOG/' + session.Games[lastGameIndex].GameID
        }
        const savestatesRef = storageRef.child(
            `/user/${props.user.uid}/savestates/${gameName}/${pendingRemove.current}.state`
        )
        const screenshotsRef = storageRef.child(
            `/user/${props.user.uid}/screenshots/${gameName}/${pendingRemove.current}.png`
        )
        await savestatesRef.delete()
        await screenshotsRef.delete()
        refresh()
    }, [props.user.uid, props.gameName, refresh])

    const onConfirmModalValidate = useCallback(() => {
        switch (confirmDialog) {
            case ConfirmDialog.SaveDelete:
                remove()
                break
            default:
                // No action
                break
        }
        setConfirmDialog(ConfirmDialog.None)
    }, [confirmDialog, remove])

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
    }, [])

    useEffect(() => {
        if (!props.user || !props.gameName) return
        setSliderLoading(true)
        refresh()

        // if the player saved, we refresh the list of savestates in the slider
        // we have no real way to detect when a new file appeared in firebase storage
        const savedSub = globalPubSub.sub(
            PubSubEvent.GAME_SAVED,
            () => {
                setTimeout(refresh, 1000)
            },
            0
        )

        return () => {
            savedSub.unsub()
        }
    }, [props.user, props.gameName, refresh])

    const itemsList = Object.keys(savestates)
        .sort()
        .reverse()
        .slice(0, saveLimit)

    const newItemsList = [t('Save'), ...itemsList]

    const handleLocalSendSaveState = () => {
        dispatch(actions.session.setIsSavedState(true))
    }
    const handleSave =
        screenType === ScreenType.CLOUD ? saveGame : handleLocalSendSaveState

    const { handleSaveStates, removeLast } = useSaveStates(
        handleSave,
        setConfirmOverrideDialog,
        setShowOverrideDialog
    )

    return (
        <>
            <div className={cls(style.savestates, props.idleState && 'idle')}>
                {sliderLoading ? (
                    <div className={style.loading}>
                        <svg
                            className={style.loadingSpinner}
                            height="100"
                            width="100"
                            viewBox="0 0 100 100"
                        >
                            <circle
                                className="loader-svg animate"
                                cx="50"
                                cy="50"
                                r="45"
                            ></circle>
                        </svg>
                    </div>
                ) : (
                    <div
                        className={cls(
                            isPremium(user)
                                ? style.savestatesItemListPremium
                                : style.savestatesItemList
                        )}
                    >
                        {newItemsList.map((item: any, index: number) => {
                            if (index === 0) {
                                return (
                                    <div className={style.saveItem}>
                                        {showOverrideDialog ? (
                                            <div className={style.override}>
                                                <p>
                                                    {confirmOverrideDialog ===
                                                    ConfirmDialog.Override
                                                        ? t('OverrideMessage')
                                                        : t(
                                                              'OverridePremiumMessage'
                                                          )}
                                                </p>
                                                {confirmOverrideDialog ===
                                                    ConfirmDialog.Override && (
                                                    <Button
                                                        variant="ellipticalLarge"
                                                        onClick={() => {
                                                            handleSave()
                                                            removeLast()
                                                        }}
                                                    >
                                                        <Icon
                                                            name="save"
                                                            width="1.5rem"
                                                            height="1.5rem"
                                                        />
                                                        {t('Override')}
                                                    </Button>
                                                )}
                                            </div>
                                        ) : (
                                            <Button
                                                variant="ellipticalLarge"
                                                onClick={handleSaveStates}
                                            >
                                                <Icon
                                                    name="save"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                />
                                                {item}
                                            </Button>
                                        )}
                                    </div>
                                )
                            }
                            return (
                                <Savestate
                                    index={index}
                                    key={savestates[item].name}
                                    save={savestates[item]}
                                    load={props.callback}
                                    remove={removeHandler}
                                />
                            )
                        })}
                    </div>
                )}
            </div>

            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalValidate}
                onClose={onConfirmModalClose}
            />
        </>
    )
}
