import { PieNavigator } from '../utils/piereader-type'
import { piepie } from '../utils'

export default class PiereaderHandler {
    // constructor() {}

    // requests the pireader and start the transfer if we found it
    request = async (): Promise<boolean> => {
        return new Promise((resolve) => {
            const filters = [{ vendorId: 0x2341 }, { vendorId: 0x03eb }]
            const promise = (
                navigator as unknown as PieNavigator
            ).usb.requestDevice({ filters: filters })
            promise
                .then((device) => {
                    piepie.log(
                        '[PieReader Request] USB detected => Serial number:',
                        device.serialNumber
                    )
                    resolve(true)
                })
                .catch((e) => {
                    piepie.error('[PieReader] error on pairing:', e)
                    resolve(false)
                })

            //const romScript = document.createElement('script')
            //romScript.src = `https://assets.piepacker.com/piereader/transfer.js`
            //romScript.async = true
            //romScript.onload = async () => {
            //    await window.Module.transferPiereader(console)
            //    setRomLoaded(true)
            //}
            //document.head.appendChild(romScript)
        })
    }

    transfer = async (emulator: number): Promise<File> => {
        return new Promise(async (resolve) => {
            const devices = await (
                navigator as unknown as PieNavigator
            ).usb.getDevices()
            let device = devices[0]
            devices.forEach((d) => {
                // Filter to keep leonardo
                if (d.vendorId === 0x2341 && d.productId === 0x8036) {
                    device = d
                }
            })

            piepie.log(
                '[PieReader Transfer] USB transfer => Serial number: ' +
                    device.serialNumber
            )

            let endpointOut = 4
            let endpointIn = 3
            let interfaceCfg0 = 0
            let interfaceCfg1 = 1

            const leonardo = 1
            if (leonardo) {
                endpointOut = 4
                endpointIn = 5
                interfaceCfg0 = 2
                interfaceCfg1 = 2
            }

            // Open
            await device.open()
            piepie.log('USB device opened')
            await device.selectConfiguration(1)
            piepie.log('USB configurated selected')
            let interfaceNumber0 = 0
            let interfaceNumber1 = 0
            if (leonardo) {
                device.configuration.interfaces.forEach((interface_) => {
                    interface_.alternates.forEach((alternate) => {
                        if (alternate.interfaceClass === 0xff) {
                            interfaceNumber0 = interface_.interfaceNumber
                            interfaceNumber1 = interface_.interfaceNumber
                            alternate.endpoints.forEach((ep) => {
                                if (ep.direction === 'out') {
                                    endpointOut = ep.endpointNumber
                                }
                                if (ep.direction === 'in') {
                                    endpointIn = ep.endpointNumber
                                }
                            })
                        }
                    })
                })

                await device.claimInterface(interfaceNumber0)
                await device.selectAlternateInterface(interfaceNumber0, 0)
            } else {
                interfaceNumber0 =
                    device.configuration.interfaces[interfaceCfg0]
                        .interfaceNumber
                interfaceNumber1 =
                    device.configuration.interfaces[interfaceCfg1]
                        .interfaceNumber
                await device.claimInterface(interfaceNumber0)
                await device.claimInterface(interfaceNumber1)
                await device.selectAlternateInterface(interfaceNumber1, 0)
            }
            piepie.log('USB interface claimed')

            // Send the D command
            const cmd = new Uint8Array(2)

            const trans_status1 = await device.controlTransferOut({
                requestType: 'class',
                recipient: 'interface',
                request: 0x22,
                value: 0x01,
                index: interfaceNumber0,
            })
            piepie.log(
                `USB device: sent reset cmd status: ${trans_status1.status}`
            )

            const t0 = performance.now()

            let stat
            let size = 0
            let waiting_byte = 0

            cmd[0] = 0
            cmd[1] = 0
            stat = await device.transferOut(endpointOut, cmd)
            piepie.log(
                'USB device: sent reset board cmd transfer status:' +
                    stat.status +
                    ' ' +
                    stat.bytesWritten
            )

            // Dump the rom
            cmd[0] = 'D'.charCodeAt(0)
            cmd[1] = emulator
            //cmd[1] = 'N'.charCodeAt(0)
            //cmd[1] = 'G'.charCodeAt(0)
            //cmd[1] = 'M'.charCodeAt(0)
            stat = await device.transferOut(endpointOut, cmd)
            piepie.log(
                'USB device: sent dump cmd transfer status:' +
                    stat.status +
                    ' ' +
                    stat.bytesWritten
            )

            // Query the size of the ROM
            cmd[0] = 'S'.charCodeAt(0)
            stat = await device.transferOut(endpointOut, cmd)
            piepie.log(
                'USB device: sent size cmd transfer status:' +
                    stat.status +
                    ' ' +
                    stat.bytesWritten
            )
            // Collect the response
            waiting_byte += 4
            let factor = 1
            while (waiting_byte > 0) {
                piepie.log(`USB wait rsp:${waiting_byte}`)
                const rsp = await device.transferIn(endpointIn, waiting_byte)
                waiting_byte -= rsp.data.byteLength
                for (let i = 0; i < rsp.data.byteLength; i++) {
                    const v = rsp.data.getUint8(i)
                    size += factor * v
                    piepie.log(`ROM Size:${size} v:${v} factor:${factor}`)
                    factor <<= 8
                }
            }

            const t1 = performance.now()

            let index = 0
            const romArray = new Uint8Array(size)
            while (index < size) {
                // Send the read command
                cmd[0] = 'R'.charCodeAt(0)
                stat = await device.transferOut(endpointOut, cmd)
                //piepie.log("USB device: sent read cmd transfer status:" + stat.status + " " + stat.bytesWritten)
                waiting_byte += 64
                while (waiting_byte > 0) {
                    if (index % 10240 === 0) {
                        piepie.log(`USB wait rsp:${waiting_byte} (${index})`)
                    }
                    const rsp = await device.transferIn(
                        endpointIn,
                        waiting_byte
                    )
                    waiting_byte -= rsp.data.byteLength
                    for (let i = 0; i < rsp.data.byteLength; i++) {
                        const v = rsp.data.getUint8(i)
                        if (index < size) {
                            romArray[index] = v
                            index++
                            if (index < 16) {
                                piepie.log(`got ${romArray[index]} ${v}`)
                            }
                        }
                    }
                }
            }

            const t2 = performance.now()
            const perf_dump = t1 - t0
            const perf_trans = t2 - t1
            piepie.log(
                `Performance. Dump & query size:${perf_dump}ms. Rom transfer: ${perf_trans}ms`
            )

            // Close
            await device.releaseInterface(interfaceNumber0)
            if (interfaceNumber0 !== interfaceNumber1) {
                await device.releaseInterface(interfaceNumber1)
            }
            await device.close()
            piepie.log('USB device closed')

            const romFile = new File([romArray], Date.now().toString())

            resolve(romFile)
        })
    }

    deinit = () => {
        // TODO
    }
}
