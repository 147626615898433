import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const useValidation = () => {
    const { t } = useTranslation()

    return Yup.object({
        email: Yup.string().email(t('InvalidEmail')).required(t('Required')),
    })
}
