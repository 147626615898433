import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { HighlightScreenStatus } from '../state/screen'

export interface HighlightScreen {
    status: HighlightScreenStatus
}

export default function HighlightScreen(
    props: HighlightScreen
): React.ReactElement {
    const history = useHistory()
    const gotoHome = useCallback(() => history.push('/'), [history])

    switch (props.status) {
        case HighlightScreenStatus.Failed:
            return (
                <ErrorScreen onClick={gotoHome} message={'GameServerFailure'} />
            )
        default:
            return <></>
    }
}

export interface ErrorScreenProps {
    onClick?: () => void
    disabled?: boolean
    message: string
}

export function ErrorScreen(props: ErrorScreenProps) {
    const { t } = useTranslation()
    return (
        <div className="overlayScreen">
            <div className="background" />
            <div
                className="shy"
                style={{ position: 'absolute', top: '0', width: '100%' }}
            ></div>
            <div className="absolute-center">
                <Button
                    className="btn-large"
                    color="primary"
                    onClick={props.onClick}
                    style={{ height: '100%' }}
                >
                    {t(props.message)}
                </Button>
            </div>
        </div>
    )
}
