import React from 'react'
import { useTranslation } from 'react-i18next'

export default function GameLobbyLoading({
    ESLMode = false,
    gameId,
}: {
    ESLMode?: boolean
    gameId?: string
}) {
    const { t } = useTranslation()

    if (ESLMode) {
        return (
            <div className="loading">
                <img
                    src={`https://fassets.piepacker.com/esl-assets/${gameId}-loading.jpg`}
                    className="loading-image"
                    alt=""
                    width="300px"
                />
                <span className="loading-esl">{t('LoadingDots')}</span>
            </div>
        )
    } else {
        return (
            <div className="loading">
                <img
                    src={`${process.env.PUBLIC_URL}/piepie_loading.gif`}
                    className="loading-image"
                    alt=""
                    width="300px"
                />
                {t('LoadingDots')}
            </div>
        )
    }
}
