import React from 'react'
import style from './style.module.scss'

//This is a controlled input, if you need an uncontrolled checkbox input, you can add it here

const Checkbox = (props) => {
    const { label, value, onChange, ...rootHtmlAttributes } = props
    const { container, checkmark } = style
    return (
        <label className={container}>
            {label}
            <input
                type="checkbox"
                value={value}
                onChange={onChange}
                {...rootHtmlAttributes}
            />
            <span className={checkmark}></span>
        </label>
    )
}

export default Checkbox
