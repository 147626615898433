import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, ToggleButton } from '../../../stories'
import { usePausePlay } from '../../../utils/GameRoomHooks'

import style from './style.module.scss'

interface LayoutProps {
    setSection: (x: string) => void
    isDynamic: boolean
    setIsDynamic: (state) => void
}

export const Layout: FC<LayoutProps> = ({
    setSection,
    isDynamic,
    setIsDynamic,
}) => {
    const { t } = useTranslation()
    const { handleResumeGame } = usePausePlay()

    const handleSection = () => {
        handleResumeGame()
        setSection('pause')
    }

    return (
        <div className={style.layoutContainer}>
            <div className={style.layoutHeader}>
                <Button variant="round" onClick={handleSection}>
                    <Icon name="arrowBack" />
                </Button>
                <p className={style.headerText}>{t('TouchscreenLayout')}</p>
            </div>

            <div>
                <div className={style.layoutToggle}>
                    <p>{t('DynamicJoystick')}</p>
                    <ToggleButton value={isDynamic} setValue={setIsDynamic} />
                </div>

                {isDynamic && (
                    <div className={style.layoutInfo}>
                        <Icon
                            name="info"
                            width="1rem"
                            height="1rem"
                            fill="rgba(255, 255, 255, 0.8)"
                        />
                        <p> {t('TouchscreenInfo')}</p>
                    </div>
                )}
            </div>
        </div>
    )
}
