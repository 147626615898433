import React, { useRef, useEffect } from 'react'

// import THREE stuff used in this class and also
// in features
import {
    Clock,
    Color,
    IcosahedronGeometry,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshNormalMaterial,
    MeshToonMaterial,
    NearestFilter,
    Object3D,
    ShaderChunk,
    ShaderMaterial,
    TangentSpaceNormalMap,
    TextureLoader,
    Vector2,
} from 'three'

import { useLoader } from '@react-three/fiber'

// import GLTF loader - originally in examples/jsm/loaders/
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

// import SkeletonUtils, useful to clone a THREE instance with a skeleton
import { clone as SkeletonUtilsClone } from 'three/examples/jsm/utils/SkeletonUtils'

// import occluders:
import GLTFFaceOccluderModel from './assets/occluders/faceOccluder.glb'
import GLTFTorsoOccluderModel from './assets/occluders/torsoOccluder.glb'

// for mannequin mode only
import GLTFMannequinFaceOccluderModel from './assets/mannequin/faceMannequin.glb'
import GLTFMannequinTorsoOccluderModel from './assets/mannequin/torsoMannequin.glb'

// for video background removal. We need larger occluders,
// especially for the head to include hair
import GLTFVideoBackgroundRemoveFaceOccluderModel from './assets/occluders/videoFaceOccluder.glb'
import GLTFVideoBackgroundRemoveTorsoOccluderModel from './assets/occluders/videoTorsoOccluder.glb'

// features:
import TorsoFeature from './features/TorsoFeature'
import AnimationFeature from './features/AnimationFeature'
import PhysicsFeature from './features/PhysicsFeature'
import AutobonesFeature from './features/AutobonesFeature'
import Animation2DFeature from './features/Animation2DFeature'
import OverrideMaterialsFeature from './features/OverrideMaterialsFeature'
import ZSmoothOccluderFeature from './features/ZSmoothOccluderFeature'
import MaterialVariantFeature from './features/MaterialVariantFeature'
import FaceSmoothOccluderFeature from './features/FaceSmoothOccluderFeature'

// import empty model (used in background removal if no mask is selected)
import GLTFModelEmpty from './assets/models3D/empty.glb'

// GLB Decryptor
import GLBDecryptor from './mask/contrib/GLBDecryptor.js'

const SETTINGS = {
    mannequinHexColor: 0x4a2281, // Mannequin Hex Color
}

// Since we tweak THREE for object decryption, we need to have
// the same THREE instance for this component and for most of its features
// so we group selective THREE import into a THREE object:
const THREE = {
    Clock,
    Color,
    IcosahedronGeometry,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshNormalMaterial,
    MeshToonMaterial,
    NearestFilter,
    Object3D,
    ShaderChunk,
    ShaderMaterial,
    TangentSpaceNormalMap,
    TextureLoader,
    Vector2,
}

// Applied for both mannequin torso and face meshes
const tweak_mannequinMesh = (mesh) => {
    const mannequinMaterial = new MeshBasicMaterial({
        color: SETTINGS.mannequinHexColor,
        toneMapped: false,
        fog: false,
        depthWrite: false,
        transparent: false,
    })

    mesh.traverse((threeNode) => {
        if (threeNode.material) {
            threeNode.material = mannequinMaterial
        }
    })

    mesh.renderOrder = -1e6
}

const set_layer = (threeObject, layerIndex) => {
    threeObject.traverse((threeNode) => {
        if (threeNode.layers) {
            threeNode.layers.set(layerIndex)
        }
    })
}

/* eslint-disable react-hooks/rules-of-hooks */
const load_gltf = (modelPath) => {
    // about DRACO loader with three fiber: https://www.tabnine.com/code/javascript/functions/react-three-fiber/useLoader
    return useLoader(GLTFLoader, modelPath, (loader) => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco/') // public path with draco loader
        loader.setDRACOLoader(dracoLoader)
    })
}
/* eslint-enable react-hooks/rules-of-hooks */

const load_occluderMesh = (modelPath) => {
    const gltfOccluder = load_gltf(modelPath)
    const occluder = gltfOccluder.scene.clone()
    occluder.traverse((threeNode) => {
        threeNode.userData.isOccluder = true
    })
    return occluder
}

const ModelContainer = (props) => {
    const {
        WEBARROCKSFACE,
        threeHelper,
        globals,
        maskSettings,
        expressionsDetector,
        isFaceExpressionsDetection,
    } = props

    //threeHelper.clean()
    const isForceOccluders = props.isMannequin || props.isRemoveBackground

    const torsoOccluderParent = new Object3D()
    torsoOccluderParent.matrixAutoUpdate = false

    // import main model:
    const gltf = load_gltf(props.GLTFModel || GLTFModelEmpty)
    //window.debugGLTF = gltf // for debugging - to inspect the GTLF model in the console
    const model = SkeletonUtilsClone(gltf.scene)
    props.logger('MASK: New mask loaded:', maskSettings.maskId)

    if (!globals.threeClock) {
        globals.threeClock = new Clock()
    }

    // tweak three to decrypt model on GPU
    if (props.decryptionKey) {
        GLBDecryptor.tweak_threeToDecrypt(THREE, props.decryptionKey)
    } else {
        GLBDecryptor.untweak_three(THREE)
    }
    OverrideMaterialsFeature.set_three(THREE)

    // import and create occluders:
    let faceOccludersContainer = null
    let torsoOccludersContainer = null

    // standard occluders:
    const faceOccluder = load_occluderMesh(GLTFFaceOccluderModel)
    const torsoOccluder = load_occluderMesh(GLTFTorsoOccluderModel)

    // mannequin occluders:
    const faceOccluderMannequin = load_occluderMesh(
        props.isMannequin ? GLTFMannequinFaceOccluderModel : GLTFModelEmpty
    )
    const torsoOccluderMannequin = load_occluderMesh(
        props.isMannequin ? GLTFMannequinTorsoOccluderModel : GLTFModelEmpty
    )

    // video background removal occluders:
    const faceOccluderVideoBackgroundRemoval = load_occluderMesh(
        props.isRemoveBackground
            ? GLTFVideoBackgroundRemoveFaceOccluderModel
            : GLTFModelEmpty
    )
    const torsoOccluderVideoBackgroundRemoval = load_occluderMesh(
        props.isRemoveBackground
            ? GLTFVideoBackgroundRemoveTorsoOccluderModel
            : GLTFModelEmpty
    )

    const createOccluder = (
        occluder,
        occluderMannequin,
        occluderVideoRemoval,
        isRender
    ) => {
        let occludersContainer = new Object3D()

        if (isRender) {
            const occluderMesh = threeHelper.create_occluderMesh(
                occluder,
                props.showOccluders
            )
            occludersContainer.add(occluderMesh)
            set_layer(occluderMesh, 1)
        }

        if (props.isMannequin) {
            tweak_mannequinMesh(occluderMannequin)
            //occluderMannequin.userData.isOccluder = true
            occludersContainer.add(occluderMannequin)
            set_layer(occluderMannequin, 1)
        }

        if (props.isRemoveBackground) {
            set_layer(occluderVideoRemoval, 2)
            occludersContainer.add(occluderVideoRemoval)
        }

        return occludersContainer
    }
    const maskSettingsFaceOccluder =
        maskSettings && maskSettings.mesh && maskSettings.mesh.faceOccluder
    const maskSettingsTorsoOccluder =
        maskSettings && maskSettings.mesh && maskSettings.mesh.torsoOccluder
    if (maskSettingsFaceOccluder || isForceOccluders) {
        const isRenderFaceOccluder = maskSettingsFaceOccluder // || props.isMannequin
        faceOccludersContainer = createOccluder(
            faceOccluder,
            faceOccluderMannequin,
            faceOccluderVideoBackgroundRemoval,
            isRenderFaceOccluder
        )
    }
    if (maskSettingsTorsoOccluder || isForceOccluders) {
        const isRenderTorsoOccluder = maskSettingsTorsoOccluder // || props.isMannequin
        torsoOccludersContainer = createOccluder(
            torsoOccluder,
            torsoOccluderMannequin,
            torsoOccluderVideoBackgroundRemoval,
            isRenderTorsoOccluder
        )
        torsoOccluderParent.add(torsoOccludersContainer)
    }

    const objRef = useRef()
    useEffect(() => {
        console.log('REBUILD MASK')

        const threeObject3DParent = objRef.current
        if (!threeObject3DParent) {
            console.log('No parent found')
            return
        }
        const threeObject3D = threeObject3DParent.children[0]

        if (
            globals.threeObject3D &&
            globals.threeObject3D.parent &&
            globals.threeObject3D !== threeObject3D
        ) {
            globals.threeObject3D.parent.remove(globals.threeObject3D)
        }

        // init features:
        OverrideMaterialsFeature.init(model, maskSettings.shading)
        ZSmoothOccluderFeature.init(
            threeObject3D,
            maskSettings && maskSettings.mesh
                ? maskSettings.mesh.zSmoothOccluder
                : null,
            THREE
        )
        TorsoFeature.init({
            threeObject3D,
            torsoOccluderParent,
            torsoOccluder,
            isForcedTorso: isForceOccluders,
        })
        globals.torso = TorsoFeature
        Animation2DFeature.init({ globals, threeObject3D }, THREE)
        PhysicsFeature.init({ globals, threeObject3D, maskSettings })
        AutobonesFeature.init(WEBARROCKSFACE, {
            globals,
            threeObject3D,
            maskSettings,
        })
        MaterialVariantFeature.init({
            gltf,
            threeObject3D,
        })
        AnimationFeature.init(WEBARROCKSFACE, {
            expressionsDetector,
            model,
            gltfAnimations: gltf.animations,
            globals,
            isFaceExpressionsDetection,
            enableSimultaneous: false, // enable or not simultaneous animations
        })
        globals.animation = AnimationFeature
        AnimationFeature.set_materialVariantFeature(MaterialVariantFeature)
        FaceSmoothOccluderFeature.init({ threeObject3D }, THREE)

        // append to face follower object:
        threeHelper.set_faceFollower(
            threeObject3DParent,
            threeObject3D,
            props.faceIndex
        )

        // set visibility. should be a boolean, not null:
        threeObject3D.visible = false
        globals.threeObject3D = threeObject3D

        if (props.onMaskLoadingEnd) props.onMaskLoadingEnd()

        //        return threeHelper.clean
        // eslint-disable-next-line
    }, [
        props.GLTFModel,
        maskSettings,
        props.isCameraActive,
        props.isFirstMaskLoaded,
        props.resolution,
        props.isMannequin,
    ]) // end useEffect

    return (
        <object3D ref={objRef}>
            <object3D visible={false}>
                <primitive object={model} />
                {faceOccludersContainer && (
                    <primitive
                        object={faceOccludersContainer}
                        name="faceOccluder"
                    />
                )}
            </object3D>
        </object3D>
    )
}

export default ModelContainer
