import React, { useCallback, useEffect, useRef, useState } from 'react'
import store, { actions } from '../../store'
import style from './style.module.scss'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import { sleep, updateUrlWithParam } from '../../utils'
import cls from 'classnames'

function PieSearch() {
    const searchRef = useRef<HTMLInputElement>(null)
    const [searchActive, setSearchActive] = useState(false)
    const [displayClose, setDisplayClose] = useState(false)
    const isFetched = useRef(false)
    const { t } = useTranslation()

    const toggle = useCallback(
        (cond: boolean) => {
            if (cond !== searchActive) {
                setSearchActive(!searchActive)
            }
        },
        [searchActive, setSearchActive]
    )

    const searchHandler = useCallback(() => {
        updateUrlWithParam('search', searchRef.current.value)
        store.dispatch(actions.home.setSearch(searchRef.current.value))
        if (searchRef.current.value) {
            setDisplayClose(true)
        } else {
            setDisplayClose(false)
        }
    }, [])

    const onOpenHandler = useCallback(async () => {
        toggle(true)
        // we wait for the animation to end
        await sleep(200)
        searchRef.current.focus()
    }, [toggle])

    const onCloseHandler = useCallback(() => {
        toggle(false)
        searchRef.current.value = ''
        updateUrlWithParam('search', searchRef.current.value)
        store.dispatch(actions.home.setSearch(''))
        setDisplayClose(false)
    }, [toggle])

    const onFocusOut = useCallback(() => {
        if (!searchRef.current.value) {
            onCloseHandler()
        }
    }, [onCloseHandler])

    useEffect(() => {
        if (!isFetched.current) {
            isFetched.current = true
            const url = new URL(window.location.href)
            const search = url.searchParams.get('search')
            if (search) {
                searchRef.current.value = search
                onOpenHandler()
                searchHandler()
            }
        }
    }, [searchHandler, onOpenHandler])

    return (
        <div
            className={cls(
                style.searchContainer,
                searchActive && style.searchContainerActive
            )}
            onClick={onOpenHandler}
            data-test="SearchIcon"
        >
            <SearchIcon />
            <input
                type="input"
                placeholder={t('Search')}
                className={style.searchBar}
                ref={searchRef}
                onChange={searchHandler}
                onKeyDown={searchHandler}
                onPaste={searchHandler}
                onInput={searchHandler}
                onBlur={onFocusOut}
                data-test="SearchInput"
            />
            {displayClose && (
                <span
                    className={style.searchBarIcon}
                    data-test="ClearHomeSearch"
                    onClick={onCloseHandler}
                >
                    <ClearIcon />
                </span>
            )}
        </div>
    )
}

export default PieSearch
