import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import store, { actions } from '../../store'

import { HighlightScreenStatus } from '../../state/screen'

import StreamingScreen from './StreamingScreen'
import LocalScreen from './LocalScreen'
import UnityScreen from './UnityScreen'
import { ScreenProps, ScreenType } from './screen-props'
import { globalPubSub } from '../../event/event'
import { piepie, useKonHandler } from '../../utils'
import * as Input from '../../input/input'
import InputHandler from '../../handlers/InputHandler'
import ScreenHandler from '../../handlers/ScreenHandler'
import { patternCleanVideoFeed } from '../../utils/konami'
import { AlertType } from '../../state/alert'
import { State } from '../../store/types'
import { isGame } from '../../utils/loading'
import alertSlice from '../../store/alert/alert'
import screenSlice from '../../store/screen/screen'

function Screen(props: ScreenProps) {
    const {
        gameScreenWidth,
        setFullscreen,
        leavingGame,
        volume,
        updateVolume,
    } = props
    const game = useSelector((state: State) => state.session.game)
    const type = useSelector((state: State) => state.screen.screenType)
    const [idleState, setIdleState] = useState<boolean>(false)
    const [initialControlOverlay, setInitialControlOverlay] =
        useState<boolean>(true)
    const gameState = useSelector((state: State) => state.session.gameState)

    const screen = useRef(null)
    const inputChannel = useSelector((state: State) => state.rtcp.inputChannel)
    const inputReady = useSelector((state: State) => state.rtcp.inputReady)
    useEffect(() => {
        if (game.System === 'Unity') {
            // For retro games, screen type is set in a response to a websocket message.
            // For unity games, no such response will appear. Instead, we set the screen type
            // directly in this file.
            store.dispatch(screenSlice.actions.setScreenType(ScreenType.UNITY))
        }
    }, [game])
    useEffect(() => {
        const intervals = Input.init(inputChannel, inputReady)
        return () => {
            // cleanup intervals
            clearInterval(intervals.key)
            clearInterval(intervals.tick)
        }
    }, [inputChannel, inputReady])
    const currentKonami = useRef(0)

    const { konHandler } = useKonHandler({
        pattern: patternCleanVideoFeed,
        current: currentKonami,
        isScreen: true,
    })

    useEffect(() => {
        document.addEventListener('keydown', konHandler, false)
        return () => {
            document.removeEventListener('keydown', konHandler, false)
        }
    }, [konHandler])

    const resume = useCallback(() => {
        piepie.log('[SCREEN] resume game')
        store.dispatch(actions.session.setGameStateReady())
    }, [])

    const pause = useCallback(() => {
        piepie.log('[SCREEN] pause game')
        store.dispatch(actions.session.setGameStatePaused())
    }, [])

    const inputHandler = useRef(null)
    const screenHandler = useRef(null)
    useEffect(() => {
        inputHandler.current?.deinit()
        screenHandler.current?.deinit()
        inputHandler.current = new InputHandler(globalPubSub)
        screenHandler.current = new ScreenHandler()
        return () => {
            inputHandler.current?.deinit()
            screenHandler.current?.deinit()
        }
    }, [game.id, resume, pause])

    useEffect(() => {
        Input.setGameInfo(game.id, game.Core, game.SharedPad)
    }, [game.id, game.Core, game.SharedPad])

    const handleActive = useCallback(() => {
        setIdleState(false)
    }, [])

    const handleIdle = useCallback(() => {
        piepie.log('[SCREEN] player is idle')
        setIdleState(true)
    }, [])

    const isHost = useSelector((state: State) => state.session.isHost)

    const shouldShowSavestates = () => isGame(gameState).paused && isHost

    const onCloseAlert = useCallback(
        () => store.dispatch(alertSlice.actions.clearNotification()),
        []
    )

    const rtcpConnected = useSelector((state: State) => state.rtcp.connected)
    useEffect(() => {
        if (rtcpConnected) {
            store.dispatch(
                screenSlice.actions.setHighlightScreenStatus(
                    HighlightScreenStatus.NotHighlighting
                )
            )
        }
    }, [rtcpConnected])

    const isGamepadConnected = () => {
        const gamepad = navigator.getGamepads()[0]
        return gamepad !== null
    }

    // Don't know if it's a good idea to be based on the Game Core
    // Also I'm not handling the controller, only using SHIFT key
    // If we change the key or we do a gamepad remap feature, it won't fit anymore
    useEffect(() => {
        if (game.InsertCoin) {
            store.dispatch(
                alertSlice.actions.push({
                    type: AlertType.Info,
                    icon: 'info',
                    message: isGamepadConnected()
                        ? 'InsertCoinGamepad'
                        : 'InsertCoin',
                    autoClose: false,
                })
            )
        }
    }, [game.InsertCoin])

    const innerScreenProps = {
        gameScreenWidth: gameScreenWidth,
        leavingGame: leavingGame,
        setFullscreen: setFullscreen,
        initialControlOverlay: initialControlOverlay,
        setInitialControlOverlay: setInitialControlOverlay,
        idleState: idleState,
        shouldShowSavestates: shouldShowSavestates,
        volume: volume,
        updateVolume: updateVolume,
        onCloseAlert: onCloseAlert,
        handleActive: handleActive,
        handleIdle: handleIdle,
        inputHandler: inputHandler.current,
        resume: resume,
        pause: pause,
    }

    // Top section with game screen
    return (
        <div ref={screen}>
            {type === ScreenType.UNITY && (
                <UnityScreen gameId={game.id} partner={game.Partner} />
            )}
            {type === ScreenType.CLOUD && (
                <StreamingScreen {...innerScreenProps} />
            )}
            {type === ScreenType.LOCAL && (
                <LocalScreen {...innerScreenProps} key={game.id} />
            )}
        </div>
    )
}

export default Screen
