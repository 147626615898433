import * as types from './types'

export function setUser(
    payload: types.SetUserAction['payload']
): types.SetUserAction {
    return { type: types.UserActionType.setUser, payload }
}

export function setUserExtra(
    payload: types.SetUserExtraAction['payload']
): types.SetUserExtraAction {
    return { type: types.UserActionType.setUserExtra, payload }
}

export function setCurrentBadge(
    payload: types.SetCurrentBadgeAction['payload']
): types.SetCurrentBadgeAction {
    return { type: types.UserActionType.setCurrentBadge, payload }
}

export function setNicknameColor(
    payload: types.SetNicknameColorAction['payload']
): types.SetNicknameColorAction {
    return { type: types.UserActionType.setNicknameColor, payload }
}

export function setFeatures(
    payload: types.SetFeaturesAction['payload']
): types.SetFeaturesAction {
    return { type: types.UserActionType.setFeatures, payload }
}
