import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18next
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: {
            'en-US': ['en'],
            'fr-FR': ['fr'],
            'es-ES': ['es'],
            'de-DE': ['de'],
            'pt-BR': ['pt'],
            default: ['en'],
        },
        supportedLngs: ['en', 'fr', 'es', 'de', 'pt'],
        // By setting debug to true, it also logs the information in the production environment.
        // Since we are limited to read only the first couple of logs from UserBack,
        // let's leave this space to more important logs.
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        },
    })

export default i18next
