// TODO: Convert these to enums
import { v1 as uuid } from 'uuid'
// events
export enum PubSubEvent {
    LATENCY_CHECK_REQUESTED = 'latencyCheckRequested',
    GAME_ROOM_AVAILABLE = 'gameRoomAvailable',
    // GAME_SAVED is sent when the game had been saved successfully (savestate)
    GAME_SAVED = 'gameSaved',
    // GAME_SAVE_FAILED is sent when the savestate creation has failed
    GAME_SAVE_FAILED = 'gameSaveFailed',
    GAME_LOADED = 'gameLoaded',
    GAME_PAUSED = 'gamePaused',
    GAME_RESUMED = 'gameResumed',
    GAME_CHECK_PAUSE = 'gameCheckPause',
    GAME_RESET = 'gameReset',
    // GAME_READY is sent when the startGame function has been called and the game has started
    GAME_READY = 'gameReady',
    // GAME_ROOM_FULL is sent when the game room is full
    GAME_ROOM_FULL = 'gameRoomFull',
    // GAME_ROOM_JOINED is sent when the game room has been joined successfully
    GAME_ROOM_JOINED = 'gameRoomJoined',
    // GAME_LOCAL_INITIALIZED is sent when we received a 'init_local' from the server
    GAME_LOCAL_INITIALIZED = 'gameLocalInitialized',
    // GAME_REMOTE_INITIALIZED is sent when we received a 'webrtc_ready' from the server
    GAME_REMOTE_INITIALIZED = 'gameRemoteInitialized',
    // BANNED is sent when we detect the user was banned
    BANNED = 'userBanned',
    GAMEPAD_CONNECTED = 'gamepadConnected',
    GAMEPAD_DISCONNECTED = 'gamepadDisconnected',
    MENU_HANDLER_ATTACHED = 'menuHandlerAttached',
    NOTIFY_ERROR = 'error',
    MENU_PRESSED = 'menuPressed',
    MENU_RELEASED = 'menuReleased',
    KEY_STATE_UPDATED = 'keyStateUpdated',
    // TAB_VISIBILITY_CHANGED is sent when a user leaves or enters the browser's tab
    TAB_VISIBILITY_CHANGED = 'tabVisibilityChanged',
    // HEARTBEAT is sent when we receive an heartbeat response
    HEARTBEAT = 'heartbeat',
    // NEW_PLAYER_INDICES is sent when we receive a player_idx_update packet
    NEW_PLAYER_INDICES = 'newPlayerIndices',
    // LOCAL_PLAYERS_CHANGED is sent when we remap the player <-> device in the settings modal
    LOCAL_PLAYERS_CHANGED = 'localPlayersChanged',
    // WEBGL_CONTEXT_LOST is sent when the webgl context of the game in local mode is lost
    WEBGL_CONTEXT_LOST = 'webGLContextLost',
    // REQ_SWITCH_INDICES is sent when we want to switch player indexes
    REQ_SWITCH_INDICES = 'reqSwitchIndices',
    // RCV_SWITCH_INDICES is sent when we received the confirmation of lemmings that the indexes have been switched
    RCV_SWITCH_INDICES = 'rcvSwitchIndices',
    // PIEREADER_PROGRESS is sent when the piereader transfer is updated
    PIEREADER_PROGRESS = 'piereaderProgress',
    // GAME_ACHIEVEMENTS is sent when the game ends (the game is over or player(s) beat the final boss).
    GAME_ACHIEVEMENTS = 'gameAchievements',
    // GAME_HOTPOTATO is sent when playing to Arsene Bomber in HotPotato Mode
    GAME_HOTPOTATO = 'gameHotPotato',
}

// Object to store subscribed topics
export interface SubscriptionInterface {
    unsub: () => void
}

export class PubSub {
    private topics: any

    constructor() {
        this.pub = this.pub.bind(this)
        this.sub = this.sub.bind(this)
        this.topics = {}
    }

    sub = (topic, listener, order) => {
        if (!this.topics[topic]) this.topics[topic] = []
        const id = uuid()
        // order handling stuff
        const value = { order: order || 0, listener: listener, id }
        this.topics[topic].push(value)
        this.topics[topic].sort((a, b) => a.order - b.order)

        return {
            unsub: () => {
                this.topics[topic] = this.topics[topic].filter(
                    ({ id: innerId }) => id !== innerId
                )
            },
        }
    }

    pub = (topic: string, data) => {
        if (!this.topics[topic] || this.topics[topic].length < 1) return

        this.topics[topic].forEach((listener) => {
            listener.listener(data || {})
        })
    }
}

// TODO: using a global pub sub mak
export const globalPubSub = new PubSub()
