import React, { useCallback, useEffect, useState } from 'react'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/types'
import { Button, GameroomModal, Icon } from '../../../stories'
import { usePausePlay } from '../../../utils/GameRoomHooks'
import { isGame } from '../../../utils/loading'
import gameroomModalSectionSlice from '../../../store/gameroomModalSection/gameroomModalSection'
import { GameroomModalSectionType } from '../../../state/gameroomModalSection'

export const GamePauseModal = ({ resetGame }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { handleResumeGame } = usePausePlay()
    const gameState = useSelector((state: State) => state.session.gameState)
    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const [showResetDialog, setShowResetDialog] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)

    const handleClose = useCallback(() => {
        handleResumeGame()
        setShow(false)
        setShowResetDialog(false)
        dispatch(
            gameroomModalSectionSlice.actions.setGameroomModal(
                GameroomModalSectionType.NONE
            )
        )
    }, [handleResumeGame, dispatch])

    const handleShow = () => setShow(true)
    const handleReset = () => {
        resetGame()
        setShow(false)
    }

    useEffect(() => {
        if (
            isGame(gameState).paused &&
            gameroomModalSection === GameroomModalSectionType.PAUSE
        ) {
            handleShow()
        } else if (gameroomModalSection === GameroomModalSectionType.NONE) {
            handleClose()
        }
    }, [gameState, gameroomModalSection, handleClose])

    const Header = (
        <div className={style.header}>
            {t('Pause')} <Icon name="pausePie" />
        </div>
    )

    return (
        <GameroomModal
            header={Header}
            show={show}
            onClose={handleClose}
            headerPosition="center"
        >
            {showResetDialog ? (
                <div>
                    <p className={style.resetDialogText}>
                        {t('ContinueResetGame')}
                    </p>
                    <div className={style.pauseDialogContent}>
                        <Button
                            variant="ellipticalLarge"
                            onClick={() => setShowResetDialog(false)}
                        >
                            {t('Cancel')}
                        </Button>

                        <Button variant="ellipticalLarge" onClick={handleReset}>
                            {t('Continue')}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className={style.pauseDialogContent}>
                    <Button variant="ellipticalLarge" onClick={handleClose}>
                        <Icon name="plainPlay" width="1.5rem" height="1.5rem" />
                        {t('Resume')}
                    </Button>

                    <Button
                        variant="ellipticalLarge"
                        onClick={() => setShowResetDialog(true)}
                    >
                        <Icon name="reset" width="1.5rem" height="1.5rem" />
                        {t('Reset')}
                    </Button>
                </div>
            )}
        </GameroomModal>
    )
}
