import React from 'react'
import cls from 'classnames'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import style from '../styles.module.scss'
import { Button, TextLink } from '../../../stories'
import { AuthModalType } from '../../../store/auth/types'
import { setCurrentView } from '../../../store/auth/authV2'

const Confirm = ({ handleSubmit }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleHomeClick = () => dispatch(setCurrentView(AuthModalType.Login))
    return (
        <div>
            <div className={style.titleWrapper}>
                <h1 className={style.title}>{t('CheckYourMail')}</h1>
            </div>
            <div className={style.titleWrapper}>
                <div className={style.subtitle}>{t('WeEmailYourPassword')}</div>
            </div>
            <Button
                type="submit"
                onClick={handleHomeClick}
                className={cls(style.btn, style.submitButton)}
            >
                {t('GoBackHome')}
            </Button>
            <div className={style.altFooterWrapper}>
                <div className={style.altFooter}>
                    <small>{t('NoPasswordLinkSent')}</small>
                    <TextLink onClick={handleSubmit}>
                        <b>{t('Resend')}</b>
                    </TextLink>
                </div>
            </div>
        </div>
    )
}

export default Confirm
