import React, { useCallback, useState } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import cls from 'classnames'

import { connect, useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { Button, Nav, Dropdown } from 'react-bootstrap'
import { Avatar } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import { logoutUser } from '../firebase'
import store, { types } from '../store'

import { clearSessionState } from '../starfox/starfox'
import * as Socket from '../network/socket'

import ConfirmModal from './ConfirmModal'

import { ConfirmDialog } from '../state/confirm'
import { piepie } from '../utils'
import { AlertType } from '../state/alert'
import { NicknameColorType } from '../state/starfox'
import { State } from '../store/types'
import { AuthModalType } from '../store/auth/types'
import alertSlice from '../store/alert/alert'
import { setCurrentView } from '../store/auth/authV2'

const AuthToolbar = ({ user, extra }) => {
    const isHost = useSelector((state: State) => state.session.isHost)
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    const onError = useCallback((error: string) => {
        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Error,
                message: error,
                autoClose: false,
            })
        )
    }, [])

    const onLogout = useCallback(async () => {
        if (location.pathname.includes('/game/')) {
            setConfirmModalOpen(true)
        } else {
            await logoutUser(onError)
            history.push('/#logout')
        }
    }, [onError, location, history])

    const onValidate = useCallback(() => {
        if (isHost) {
            Socket.quitGame()
            piepie.log('Host closed the room')
        }
        clearSessionState()
        logoutUser(onError)
        setConfirmModalOpen(false)
        history.push('/#logout')
    }, [history, isHost, onError])

    if (user && !user.isAnonymous) {
        return (
            <div className="user-space">
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        data-test="UserDropdown"
                        id="userdropdown"
                        variant="link"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar src={user.photoURL} />
                        <div
                            className={cls(
                                'avatar-username',
                                extra?.NicknameColor ===
                                    NicknameColorType.Gold && 'goldLetters'
                            )}
                        >
                            {user.displayName}
                        </div>
                        <KeyboardArrowDown
                            style={{
                                color: 'white',
                                height: '24px',
                                width: '40px',
                                paddingRight: '12px',
                            }}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ position: 'absolute' }}>
                        <Dropdown.Item
                            data-test="UserDropdownSettings"
                            as={Link}
                            to="/settings/UserProfile"
                        >
                            {t('MyAccount')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            as="a"
                            data-test="UserDropdownHelpCenter"
                            href={t('HelpCenterLink')}
                            rel="noopener"
                            target="_blank"
                        >
                            {t('HelpCenter')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            data-test="UserDropdownLogout"
                            onClick={onLogout}
                        >
                            {t('Logout')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <ConfirmModal
                    onClose={() => {
                        setConfirmModalOpen(false)
                    }}
                    onValidate={onValidate}
                    open={confirmModalOpen}
                    confirmDialog={ConfirmDialog.Quit}
                />
            </div>
        )
    } else {
        return (
            <>
                <Nav.Item className="nav-item-padded">
                    <Link to="/">
                        <Button
                            variant="link"
                            style={{
                                color: 'white',
                            }}
                            onClick={() => {
                                dispatch(setCurrentView(AuthModalType.Login))
                            }}
                        >
                            {t('SignIn')}
                        </Button>
                    </Link>
                </Nav.Item>
                <Nav.Item className="nav-item-padded">
                    <Link to="/">
                        <Button
                            color="primary"
                            onClick={() =>
                                dispatch(
                                    setCurrentView(AuthModalType.SignUpMenu)
                                )
                            }
                        >
                            {t('SignUp')}
                        </Button>
                    </Link>
                </Nav.Item>
            </>
        )
    }
}

export default connect(
    (state: types.State) => ({
        user: state.user.user,
        extra: state.user.extra,
    }),
    null
)(AuthToolbar)
