import { KickstarterBackerType } from '../../state/starfox'

export const SET_ACTIVE_MASK_ID = 'MASK:SET_ACTIVE_MASK_ID'
export const RESET_STATE = 'MASK:RESET_STATE'
export const SET_MASK_STORE = 'MASK:SET_MASK_STORE'
export const ADD_MASK_STORE = 'MASK:ADD_MASK_STORE'
export const SET_MANNEQUIN_MODE = 'MASK:SET_MANNEQUIN_MODE'

export type MetadataStore = { [maskId: string]: MaskMetadata }

export interface MaskState {
    activeMaskId: string | null
    mannequinMode: boolean
    metadataStore: MetadataStore
}

export interface MaskMetadata {
    available: boolean
    maskId: string
    mesh?: {
        torsoOccluder?: boolean
        faceOccluder?: boolean
        bodyOccluderSrc?: string
        faceOccluderSrc?: string
    }
    model: string
    name: string
    placeholder: string
    postprocessing?: {
        outline?: {
            thickness?: number
        }
    }
    shading?: {
        toon?: boolean
    }
    Limited?: KickstarterBackerType
}

export interface SetActiveMaskIdAction {
    type: typeof SET_ACTIVE_MASK_ID
    payload: MaskState['activeMaskId']
}

export interface resetStateAction {
    type: typeof RESET_STATE
}

export interface SetMaskStore {
    type: typeof SET_MASK_STORE
    payload: MaskState['metadataStore']
}

export interface AddMaskStore {
    type: typeof ADD_MASK_STORE
    payload: MaskState['metadataStore']
}

export interface SetMannequinMode {
    type: typeof SET_MANNEQUIN_MODE
    payload: MaskState['mannequinMode']
}

export type MaskActionTypes =
    | SetActiveMaskIdAction
    | resetStateAction
    | SetMaskStore
    | AddMaskStore
    | SetMannequinMode
