import React, { FC, useCallback } from 'react'
import { Game, SessionMoodType } from '../../state/starfox'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GameLogo from '../GameLogo'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import style from './style.module.scss'
import cls from 'classnames'
import { LocalStorage } from '../../state/storage'
import { GameModalType } from './types'
import { byog_config } from '../../pages/BYOG/config'
import store, { actions } from '../../store'
import { SignedInState } from '../../state/app'
import { openAuthModal } from '../../store/auth/actions'
import { sessionAvailable } from '../../starfox/starfox'
import { UnavailableReasonEnum } from '../../state/unavailable'
import { selectReason, selectSignedIn } from '../../store/home/homeV2'
import { piepie } from '../../utils/piepie'

export interface ModalDetailsProps {
    id: Game['id']
    mood: SessionMoodType
    type: GameModalType
    competition: Game['Competition']
    numberOfRooms: number
}

const ModalDetails: FC<ModalDetailsProps> = ({
    id,
    mood,
    type,
    competition,
    numberOfRooms,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const reason = useSelector(selectReason)
    const signedIn = useSelector(selectSignedIn)
    const setGameName = useCallback(
        (name: string) => {
            dispatch({ type: 'SET_GAME_NAME', payload: name })
        },
        [dispatch]
    )

    const onStartHandle = useCallback(async () => {
        // first, we check the user is signed in and that a session can be created
        if (signedIn !== SignedInState.SignedIn) {
            piepie.log('[MODAL] Cannot start the game: user not signed in')
            dispatch(
                openAuthModal({
                    closable: true,
                    isTransparent: false,
                })
            )
            return
        }

        // enough compute capacity
        const available = await sessionAvailable()
        if (!available) {
            piepie.log(
                '[MODAL] Cannot start the game: not enough compute capacity'
            )
            return
        }

        if (reason !== UnavailableReasonEnum.None) {
            piepie.log(`[MODAL] Cannot start the the game: ${reason}`)
            return
        }

        // if everything is ok, then let's start a new session
        store.dispatch(actions.session.clearSession())
        localStorage.setItem(LocalStorage.sessionID, '')
        if (type === GameModalType.Regular) {
            setGameName(id)
            history.push(`/game/${encodeURIComponent(id)}`)
        } else if (type === GameModalType.BYOG) {
            for (const [path, console] of Object.entries(byog_config.session)) {
                if (console === id) {
                    history.push(`/byog?${path}`)
                    break
                }
            }
        }
    }, [id, type, setGameName, history, dispatch, reason, signedIn])

    return (
        <div className={style.details}>
            <div className={style.gameLogo}>
                <GameLogo logoKind={style.gameModalLogo} gameID={id} />
            </div>
            <div className={style.buttons}>
                {type !== GameModalType.Mood && (
                    <Button
                        block
                        type="submit"
                        className={cls('btn-large', {
                            [style.createRoom]: numberOfRooms,
                        })}
                        data-test="GameModalStartNew"
                        onClick={onStartHandle}
                    >
                        {t('PublicRoomsCreate')}
                    </Button>
                )}
            </div>

            {/* TODO: what to do with competition games? */}
            {competition && (
                <div className="DetailsEventWrapper">
                    <div className="DetailsEventButtonTitle">
                        {t('ESLSpecialEventHomepageTitle')}
                    </div>
                    <Link to="/events" className="DetailsEventButton">
                        <Button variant="outline-primary">
                            {t('LeaderboardRush')}
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default ModalDetails
