import style from './style.module.scss'

import React, { FC, useState } from 'react'
import cls from 'classnames'

interface TextInputButtonProps {
    className?: string
    label?: string
    buttonLabel: string
    onClick?: (inputValue: string) => void
    inputType?: string
    placeholder?: string
    clearOnClick?: boolean
}

const TextInputButton: FC<TextInputButtonProps> = ({
    className,
    buttonLabel,
    inputType = 'text',
    placeholder,
    label,
    onClick,
    clearOnClick = true,
}) => {
    const [inputValue, setInputValue] = useState('')

    const onPreClick = () => {
        if (onClick) onClick(inputValue)
        if (clearOnClick) setInputValue('')
    }

    return (
        <div className={cls(style.root, className)}>
            {label && <span className={style.label}>{label}</span>}
            <div className={style.wrapper}>
                <input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onPreClick()
                        }
                    }}
                    className={style.input}
                    type={inputType}
                    placeholder={placeholder}
                />
                <button
                    disabled={!inputValue}
                    className={style.button}
                    onClick={onPreClick}
                >
                    {buttonLabel}
                </button>
            </div>
        </div>
    )
}

export default TextInputButton
