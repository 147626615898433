import styles from './UserSettings/styles.module.scss'
import React, { useEffect, useState } from 'react'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form as Formk } from 'formik'
import { piepie } from '../utils'
import { LocalStorage } from '../state/storage'
import { enGB, fr, de, pt, es } from 'date-fns/locale'
import { setBirthday } from '../firebase'

const localeMap = {
    fr,
    de,
    pt,
    es,
    en: enGB,
}

interface IEditBirthday {
    dob?: Date
    onSuccess: () => void
    initialValues: Record<string, string>
}

const EditBirthday: React.FC<IEditBirthday> = ({
    onSuccess,
    initialValues,
    dob,
}) => {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const { t } = useTranslation()
    const [localDob, setLocalDob] = useState<Date>(null)
    const langCode = localStorage.getItem(LocalStorage.i18nextLng)

    useEffect(() => {
        setLocalDob(dob)
    }, [dob])

    const handleDob = async (date: Date) => {
        setLocalDob(date)
    }

    const handleSubmit = async () => {
        try {
            await setBirthday(localDob)
            onSuccess()
        } catch (err) {
            piepie.error('[ACCOUNT] profile edititon failed:', err.message)
            setSubmitError({ error: true, message: err.message })
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('EditBirthday')}</Modal.Title>
            </Modal.Header>

            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}

            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Formk>
                        <Form.Group>
                            <div>{t('Birthday')}</div>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={localeMap[langCode]}
                            >
                                <KeyboardDatePicker
                                    autoOk
                                    name="birthday"
                                    value={localDob}
                                    openTo="year"
                                    format="dd/MM/yyyy"
                                    placeholder="DD/MM/YYYY"
                                    margin="normal"
                                    views={['year', 'month', 'date']}
                                    className={styles.keyboardPicker}
                                    KeyboardButtonProps={{
                                        color: 'primary',
                                    }}
                                    InputAdornmentProps={{
                                        style: {
                                            position: 'absolute',
                                            right: 0,
                                            color: '#fff',
                                        },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(date) => handleDob(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Group>
                        <Form.Group>
                            <Button
                                className="btn-large"
                                disabled={isSubmitting}
                                type="submit"
                                block
                            >
                                {t('Save')}
                            </Button>
                        </Form.Group>
                    </Formk>
                )}
            </Formik>
        </>
    )
}

export default EditBirthday
