import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import homeSlice, {
    initialState,
    selectAllFiltersVisibility,
    selectSelectedTags,
    selectOpenRoom,
    selectNumberOfPlayers,
    setCategoryDisplayed,
} from '../../../store/home/homeV2'
import { getNumberOfPlayersTagName } from '../Commons/constants'

export const useModalFilters = () => {
    const dispatch = useDispatch()
    const {
        setSelectedTags,
        setOpenRoom,
        setNumberOfPlayers,
        setAllFiltersVisibility,
    } = homeSlice.actions

    const numberOfPlayers = useSelector(selectNumberOfPlayers)
    const openRoom = useSelector(selectOpenRoom)
    const selectedTags = useSelector(selectSelectedTags)
    const isModalVisible = useSelector(selectAllFiltersVisibility)
    const filterSelectedTags = selectedTags.filter(
        (tag) => tag !== getNumberOfPlayersTagName(numberOfPlayers)
    )
    const [tempOpenRoom, setTempOpenRoom] = useState(openRoom)
    const [tempSelectedTags, setTempSelectedTags] = useState(filterSelectedTags)
    const [tempNumberOfPlayers, setTempNumberOfPlayers] =
        useState(numberOfPlayers)
    const isValidNumberOfPlayers =
        !!tempNumberOfPlayers ||
        selectedTags.includes(getNumberOfPlayersTagName(tempNumberOfPlayers))

    const handleReset = useCallback(() => {
        setTempOpenRoom(initialState.openRoom)
        setTempSelectedTags(initialState.selectedTags)
        setTempNumberOfPlayers(initialState.numberofPlayers)

        dispatch(setOpenRoom(initialState.openRoom))
        dispatch(setSelectedTags(initialState.selectedTags))
        dispatch(setNumberOfPlayers(initialState.numberofPlayers))
        dispatch(setCategoryDisplayed(initialState.categoryDisplayed))
    }, [dispatch, setOpenRoom, setSelectedTags, setNumberOfPlayers])

    const handleSave = useCallback(() => {
        dispatch(setOpenRoom(tempOpenRoom))
        const selectedTags = isValidNumberOfPlayers
            ? [
                  getNumberOfPlayersTagName(tempNumberOfPlayers),
                  ...tempSelectedTags,
              ]
            : tempSelectedTags
        dispatch(setSelectedTags(selectedTags))
        isValidNumberOfPlayers &&
            dispatch(setNumberOfPlayers(tempNumberOfPlayers))
        dispatch(setAllFiltersVisibility(false))
    }, [
        dispatch,
        setAllFiltersVisibility,
        setOpenRoom,
        setSelectedTags,
        tempNumberOfPlayers,
        tempOpenRoom,
        tempSelectedTags,
        setNumberOfPlayers,
        isValidNumberOfPlayers,
    ])

    const handleModalClose = () => dispatch(setAllFiltersVisibility(false))

    return {
        handleSave,
        handleReset,
        isModalVisible,
        handleModalClose,
        tempSelectedTags,
        setTempSelectedTags,
        setTempOpenRoom,
        tempOpenRoom,
        tempNumberOfPlayers,
        setTempNumberOfPlayers,
    }
}
