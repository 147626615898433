import React, { FC } from 'react'
import { Button } from '../..'
import style from './style.module.scss'
import cls from 'classnames'

type Key = { touchStart: () => void; touchEnd: () => void }

interface ControlGroup {
    X?: Key
    A?: Key
    Y?: Key
    B?: Key
}

interface ShoulderButtons {
    L1?: Key
    L2?: Key
    R1?: Key
    R2?: Key
}

interface ControlButtonsProps {
    controlGroup?: ControlGroup
    shoulderButtons?: ShoulderButtons
}

const ControlButtons: FC<ControlButtonsProps> = ({
    controlGroup,
    shoulderButtons,
}) => {
    const isControlGroupExist =
        controlGroup && Object.keys(controlGroup).length > 0
    const isShoulderButtonsExist =
        shoulderButtons && Object.keys(shoulderButtons).length > 0

    const ControlGroup = () => {
        const keys = Object.keys(controlGroup)

        if (keys.length === 4) {
            const firstHalf = keys.splice(0, 2)
            const secondHalf = keys.splice(-2)
            const controlSize = (group, index) => {
                if (index === 0 && group === 'first') return 'sm'
                if (index === 1 && group === 'first') return 'md'
                if (index === 0 && group === 'second') return 'md'
                if (index === 1 && group === 'second') return 'lg'
            }
            const controlColor = (group, index) => {
                if (index === 0 && group === 'first') return 'yellow'
                if (index === 1 && group === 'first') return 'red'
                if (index === 0 && group === 'second') return 'blue'
                if (index === 1 && group === 'second') return 'green'
            }
            return (
                <div
                    className={cls(
                        isShoulderButtonsExist
                            ? style.halfControlGroupContainer4El
                            : style.controlGroupContainer4El
                    )}
                >
                    <div className={style.firstHalf}>
                        {firstHalf.map((key, index) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize={controlSize('first', index)}
                                    controlColor={controlColor('first', index)}
                                    onTouchStart={controlGroup[key].touchStart}
                                    onTouchEnd={controlGroup[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                    <div className={style.secondHalf}>
                        {secondHalf.map((key, index) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize={controlSize('second', index)}
                                    controlColor={controlColor('second', index)}
                                    onTouchStart={controlGroup[key].touchStart}
                                    onTouchEnd={controlGroup[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            )
        } else if (keys.length === 3) {
            const firstKey = keys[0]
            const secondHalf = keys.splice(-2)
            return (
                <div
                    className={cls(
                        isShoulderButtonsExist
                            ? style.halfControlGroupContainer3El
                            : style.controlGroupContainer3El
                    )}
                >
                    <div className={style.firstHalf}>
                        <Button
                            variant="gamecontrol"
                            controlSize="md"
                            controlColor="red"
                            onTouchStart={controlGroup[firstKey].touchStart}
                            onTouchEnd={controlGroup[firstKey].touchEnd}
                        >
                            {firstKey}
                        </Button>
                    </div>
                    <div className={style.secondHalf}>
                        {secondHalf.map((key, index) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize={index === 0 ? 'md' : 'lg'}
                                    controlColor={
                                        index === 0 ? 'blue' : 'green'
                                    }
                                    onTouchStart={controlGroup[key].touchStart}
                                    onTouchEnd={controlGroup[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            )
        } else if (keys.length === 2) {
            return (
                <div
                    className={cls(
                        isShoulderButtonsExist
                            ? style.halfControlGroupContainer2El
                            : style.controlGroupContainer2El
                    )}
                >
                    <Button
                        variant="gamecontrol"
                        controlSize="md"
                        controlColor="red"
                        onTouchStart={controlGroup[keys[0]].touchStart}
                        onTouchEnd={controlGroup[keys[0]].touchEnd}
                    >
                        {keys[0]}
                    </Button>
                    <Button
                        variant="gamecontrol"
                        controlSize={isShoulderButtonsExist ? 'md' : 'lg'}
                        controlColor="green"
                        onTouchStart={controlGroup[keys[1]].touchStart}
                        onTouchEnd={controlGroup[keys[1]].touchEnd}
                    >
                        {keys[1]}
                    </Button>
                </div>
            )
        } else if (keys.length === 1) {
            return (
                <div
                    className={cls(
                        isShoulderButtonsExist &&
                            style.halfControlGroupContainer
                    )}
                >
                    <Button
                        variant="gamecontrol"
                        controlSize="lg"
                        controlColor="green"
                        onTouchStart={controlGroup[keys[0]].touchStart}
                        onTouchEnd={controlGroup[keys[0]].touchEnd}
                    >
                        {keys[0]}
                    </Button>
                </div>
            )
        }
    }

    const ShoulderButtons = () => {
        const keys = Object.keys(shoulderButtons)
        if (keys.length === 4) {
            const firstHalf = keys.splice(0, 2)
            const secondHalf = keys.splice(-2)
            return (
                <div className={style.shoulderButtons}>
                    <div className={style.firstHalf}>
                        {firstHalf.map((key) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize="sm"
                                    controlColor="regular"
                                    onTouchStart={
                                        shoulderButtons[key].touchStart
                                    }
                                    onTouchEnd={shoulderButtons[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                    <div className={style.secondHalf}>
                        {secondHalf.map((key) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize="sm"
                                    controlColor="regular"
                                    onTouchStart={
                                        shoulderButtons[key].touchStart
                                    }
                                    onTouchEnd={shoulderButtons[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            )
        } else if (keys.length === 2) {
            return (
                <div className={style.shoulderButtons}>
                    <div className={style.shoulderButtonGroup}>
                        {keys.map((key) => {
                            return (
                                <Button
                                    key={key}
                                    variant="gamecontrol"
                                    controlSize="sm"
                                    controlColor="regular"
                                    onTouchStart={
                                        shoulderButtons[key].touchStart
                                    }
                                    onTouchEnd={shoulderButtons[key].touchEnd}
                                >
                                    {key}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={style.container}>
            {isControlGroupExist && <ControlGroup />}
            {isShoulderButtonsExist && <ShoulderButtons />}
        </div>
    )
}

export default ControlButtons
