import React from 'react'
import style from './styles.module.scss'
import { useTranslation, Trans } from 'react-i18next'
import { Checkbox } from '../../stories'
import { piepackerUrl } from './constants'

const PrivacyCheck = ({
    privacyError,
    setPrivacyError,
    hasAgreedToTerms,
    setHasAgreedToTerms,
}) => {
    const { t } = useTranslation()

    const handleOnChange = () => {
        if (!hasAgreedToTerms) {
            setPrivacyError(false)
        }
        setHasAgreedToTerms(!hasAgreedToTerms)
    }

    return (
        <>
            <div className={style.checkbox}>
                <Checkbox
                    label={
                        <div className={style.checkboxLabel}>
                            <Trans i18nKey="PrivacyAgreement">
                                I agree to Piepacker's
                                <a
                                    target="_blank"
                                    className={style.link}
                                    rel="noopener noreferrer"
                                    href={`${piepackerUrl}/privacy`}
                                >
                                    privacy policy
                                </a>
                                and
                                <a
                                    target="_blank"
                                    className={style.link}
                                    rel="noopener noreferrer"
                                    href={`${piepackerUrl}/terms-of-service`}
                                >
                                    terms of use
                                </a>
                            </Trans>
                        </div>
                    }
                    value={hasAgreedToTerms}
                    onChange={handleOnChange}
                />
            </div>
            {privacyError && (
                <div className={style.errorMsg}>
                    {t('PrivacyCheckboxError')}
                </div>
            )}
        </>
    )
}

export default PrivacyCheck
