import { useTranslation } from 'react-i18next'
import { SORT_BY_OPTIONS, SORT_BY_ORDER, TSortBy } from '../types'

export default function useSortByOptions(): Array<TSortBy & { name: string }> {
    const { t } = useTranslation()
    return Object.values(SORT_BY_OPTIONS).flatMap((option) => [
        {
            name: t(`SortBy${option}Ascending`),
            kind: option,
            order: SORT_BY_ORDER.asc,
        },
        {
            name: t(`SortBy${option}Descending`),
            kind: option,
            order: SORT_BY_ORDER.desc,
        },
    ])
}
