import React from 'react'
import { FilterTag } from '../../../stories'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setNumberOfPlayers } from '../../../store/home/homeV2'
import { numberOfPlayersAsTags } from '../Commons/constants'

const Tags = ({ handleSelectedTags, selectedTags, tag }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const hasItem = selectedTags.includes(tag)
    const handleTagClick = () => {
        !hasItem && handleSelectedTags([...selectedTags, tag])
    }
    const handleClose = () => {
        const filterTag = selectedTags.filter((existingTag) => {
            return existingTag !== tag
        })
        if (numberOfPlayersAsTags[tag]) {
            dispatch(setNumberOfPlayers(0))
        }
        hasItem && handleSelectedTags(filterTag)
    }

    return (
        <FilterTag
            size="small"
            theme="secondary"
            isSelected={hasItem}
            onClick={handleTagClick}
            onClose={handleClose}
        >
            #{t(tag)?.toLowerCase()}
        </FilterTag>
    )
}

export default Tags
