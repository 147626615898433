export const KEY = {
    A: 8,
    B: 0,
    X: 9,
    Y: 1,
    START: 3,
    SELECT: 2,
    UP: 4,
    DOWN: 5,
    LEFT: 6,
    RIGHT: 7,
    L1: 10,
    L2: 12,
    L3: 14,
    R1: 11,
    R2: 13,
    R3: 15,
}
