import React from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { selectNumberOfPlayers } from '../../../store/home/homeV2'
import { getNumberOfPlayersTagName } from '../Commons/constants'
import Tags from './Tags'
import { removeDuplicates } from '../../../utils'

interface IQuickAccessProps {
    selectedTags: string[]
    gameTags: string[]
    handleSelectedTags: (value: string[]) => void
}

const QuickAccess: React.FC<IQuickAccessProps> = ({
    gameTags,
    selectedTags,
    handleSelectedTags,
}) => {
    const numberOfPlayers = useSelector(selectNumberOfPlayers)
    const numberOfPlayersTag = getNumberOfPlayersTagName(numberOfPlayers)
    const modifiedGameTags = !!numberOfPlayersTag
        ? removeDuplicates([numberOfPlayersTag, ...gameTags])
        : gameTags
    return (
        <div className={styles.quickAccess}>
            {modifiedGameTags.map((tag, index) => (
                <Tags
                    tag={tag}
                    key={tag || index}
                    selectedTags={selectedTags}
                    handleSelectedTags={handleSelectedTags}
                />
            ))}
        </div>
    )
}

export default QuickAccess
