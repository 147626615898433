const getVideoSize = (): string => {
    return window.matchMedia('(min-resolution: 2dppx)').matches
        ? 'large'
        : window.matchMedia('(min-resolution: 1.1dppx)').matches
        ? 'medium'
        : 'small'
}

const getImageSize = (): number => {
    return window.matchMedia('(min-resolution: 2dppx)').matches
        ? 2
        : window.matchMedia('(min-resolution: 1.74dppx)').matches
        ? 1.75
        : window.matchMedia('(min-resolution: 1.49dppx)').matches
        ? 1.5
        : window.matchMedia('(min-resolution: 1.24dppx)').matches
        ? 1.25
        : 1
}

export const cardBgUrl = (id: string, kind: string): string => {
    let size = getImageSize()
    let width = 308
    let mode = 'landscape'
    if (kind === 'featured') mode = 'featured'
    if (kind === 'recent') mode = 'portrait'

    switch (mode) {
        case 'featured':
            if (size === 2) width = 832
            else if (size === 1.75) width = 728
            else if (size === 1.5) width = 624
            else if (size === 1.25) width = 520
            else width = 416
            break
        case 'landscape':
            if (size === 2) width = 616
            else if (size === 1.75) width = 539
            else if (size === 1.5) width = 462
            else if (size === 1.25) width = 385
            else width = 308
            break
        default:
            //generic, portrait
            if (size === 2) width = 400
            else if (size === 1.75) width = 350
            else if (size === 1.5) width = 300
            else if (size === 1.25) width = 250
            else width = 200
            break
    }

    return `https://fassets.piepacker.com/thumbnails-preview/${encodeURIComponent(
        id
    )}_${mode}.png?width=${width}`
}

export const cardLogoUrl = (id: string) =>
    `https://fassets.piepacker.com/logos/${encodeURIComponent(id)}`

export const moodCoverImg = (id: string) =>
    `https://fassets.piepacker.com/backgrounds/cropped/${encodeURIComponent(
        id
    )}.png`

export const moodCoverImgFallback = (id: string) =>
    `https://fassets.piepacker.com/backgrounds/cropped/${encodeURIComponent(
        id
    )}.svg`

const byogVideoUrl = `https://fassets.piepacker.com/videos/cropped/BYOG.mp4?organizationId=942630971205`

const gameVideoUrl = (
    id: string,
    kind: string,
    isGameModal: boolean
): string => {
    if (isGameModal) {
        // Hardcoded medium/featured for the game modal, large provoked chrome pink screen bug with border radius
        return `https://fassets.piepacker.com/videos-preview/${encodeURIComponent(
            id
        )}_featured_medium.mp4`
    } else {
        let mode = 'landscape'
        if (kind === 'featured') mode = 'featured'
        if (kind === 'recent') mode = 'portrait'
        return `https://fassets.piepacker.com/videos-preview/${encodeURIComponent(
            id
        )}_${mode}_${getVideoSize()}.mp4`
    }
}

export const gameVideoBgImage = (id: string) =>
    `url('https://fassets.piepacker.com/backgrounds/cropped/${encodeURIComponent(
        id
    )}.jpg')`

export const getVideoMedia = ({
    isBYOG = false,
    isGameModal = false,
    kind,
    gameId,
}: {
    isBYOG?: boolean
    isGameModal?: boolean
    gameId: string
    kind: string
}) => (isBYOG ? byogVideoUrl : gameVideoUrl(gameId, kind, isGameModal))

export const transformNameToText = {
    NES: 'NES',
    'Game Boy': 'Game boy',
    'GameBoy Advance': 'GBA',
    Genesis: 'Genesis',
    'Master System': 'Master System',
    SNES: 'Super NES',
    PlayStation: 'PS1',
    'Nintendo 64': 'Nintendo 64',
    Lutro: 'Lutro',
    'PICO-8': 'Piko 8',
}

export const gameCardMaximumTags = (category: string) =>
    ({
        Featured: 3,
        RecentlyPlayed: 1,
        List: 1,
    }[category] ?? 2)
