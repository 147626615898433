import styles from './styles.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IRedeemModalData } from './types'
import RedeemModal from './RedeemModal'
import { actions } from '../../../store'
import { getUserExtra } from '../../../firebase'
import { getMasks } from '../../../firebase/masks'
import { TextInputButton } from '../../../components/ui'
import { getRedeemedItems, getUserFeatures } from '../state/utils'

const Redeem: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [modalData, setModalData] = useState<IRedeemModalData>(null)

    const handleSubmit = async (code) => {
        setIsLoading(true)
        try {
            const { status, data } = await getRedeemedItems(code)
            setModalData({ status, data })
            getUserExtra().then((userExtra) => {
                dispatch(actions.user.setUserExtra(userExtra))
            })
            getMasks()
            const isFeature = data.redeemedItems?.find(
                (item) => item.Type === 'FEATURE'
            )
            if (!!isFeature) {
                try {
                    const features = await getUserFeatures()
                    dispatch(actions.user.setFeatures(features))
                } catch (err) {
                    console.error('CANNOT GET FEATURES FOR THE USER ---', err)
                }
            }
        } catch (err) {
            setModalData({
                status: err.response.status,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.section}>
            <RedeemModal
                modalData={modalData}
                isLoading={isLoading}
                setModalData={setModalData}
            />
            <p className={styles.header}>{t('RedeemSectionAction')}</p>
            <div>
                <TextInputButton
                    className="redeemcode-wrapper"
                    label={t('RedeemSectionLabel')}
                    buttonLabel={t('Redeem')}
                    placeholder={t('RedeemSectionPlaceholder')}
                    onClick={(code) => handleSubmit(code)}
                />
            </div>
        </div>
    )
}

export default Redeem
