export enum Keys {
    A = 'A',
    B = 'B',
    X = 'X',
    Y = 'Y',
    START = 'START',
    SELECT = 'SELECT',
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    L1 = 'L1',
    L2 = 'L2',
    R1 = 'R1',
    R2 = 'R2',
}
