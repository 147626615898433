import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useChime } from '../../conference/hooks'
import { State } from '../../store/types'
import { piepie } from '../../utils'
import { Video } from './'

interface VideoPreviewProps {
    className?: string
    showAvatar?: boolean
}

const VideoPreview: FC<VideoPreviewProps> = ({ className, showAvatar }) => {
    const chime = useChime()
    const {
        isCameraActive,
        isCameraAvailable,
        devices,
        isDeviceBindingComplete,
    } = useSelector((state: State) => state.conference)
    const videoElementRef = useRef<HTMLVideoElement>()

    useEffect(() => {
        if (
            !videoElementRef.current ||
            !chime.audioVideo ||
            !isDeviceBindingComplete
        ) {
            return
        }
        if (isCameraActive) {
            piepie.log(
                'CHIME: Starting video preview, currentVideoInputDevice:',
                devices.currentVideoInputDevice,
                'using this HTML element:',
                videoElementRef.current
            )
            videoElementRef.current.addEventListener('canplaythrough', () => {
                const elt = videoElementRef.current
                piepie.log(
                    'CHIME: real video resolution in VideoPreview =',
                    elt.videoWidth,
                    'x',
                    elt.videoHeight
                )
            })

            chime.audioVideo.startVideoPreviewForVideoInput(
                videoElementRef.current
            )
        } else {
            piepie.log(
                'CHIME: Stoping video preview on element:',
                videoElementRef.current
            )
            chime.audioVideo.stopVideoPreviewForVideoInput(
                videoElementRef.current
            )
        }

        return
    }, [
        chime.audioVideo,
        videoElementRef,
        isCameraActive,
        devices.currentVideoInputDevice,
        isDeviceBindingComplete,
    ])

    useEffect(() => {
        const videoElementReference = videoElementRef.current
        return () => {
            if (chime.audioVideo && videoElementReference && !isCameraActive) {
                piepie.log(
                    'CHIME: UNMOUNT -- Stoping video preview on element:',
                    videoElementReference
                )
                chime.audioVideo.stopVideoPreviewForVideoInput(
                    videoElementReference
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Video
            ref={videoElementRef}
            className={className}
            showAvatar={showAvatar && isCameraActive && isCameraAvailable}
        />
    )
}

export default VideoPreview
