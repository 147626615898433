import React, { FC, ReactNode, useEffect, useState } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface MobileGameroomOverlayProps {
    children?: ReactNode
    transparency:
        | 'transparent'
        | 'halfBlackTransparency'
        | 'halfBlueTransparency'
        | 'opac'
    size: 'screen' | 'fullscreen' | 'portraitFullscreen'
    [rootHtmlAttributes: string]: any
}

const MobileGameroomOverlay: FC<MobileGameroomOverlayProps> & {
    Body?: FC
    Header?: FC
} = ({
    children,
    transparency = 'opac',
    size = 'screen',
    ...rootHtmlAttributes
}) => {
    const [height, setHeight] = useState('')

    useEffect(() => {
        const el = document.querySelector('#game')

        if (el) {
            //@ts-ignore
            setHeight(el.style.height)
        }
    }, [])

    return (
        <div
            id="mobile-gameroom-overlay"
            className={cls(style.overlay, style[transparency], style[size])}
            style={{ height: height }}
            {...rootHtmlAttributes}
        >
            {children}
        </div>
    )
}

export default MobileGameroomOverlay
