import { useEffect, useState } from 'react'

// from 1200px breakpoints increases by 216
const desktopBreakPoints = [1416, 1632, 1848, 2064, 2280, 2496, 2712]
const GRID_COL_WIDTH_DESKTOP = 92
const COL_GAP = 16
const ROW_OFFSET = 64
const UNIT_COLUMN_DESKTOP = 200
export const useMatchMedia = () => {
    const [col, setColWidth] = useState(0)
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const windowWidthRange = desktopBreakPoints.findIndex(
        (item) => innerWidth <= item
    )

    const actualWidth =
        desktopBreakPoints[windowWidthRange] -
        ROW_OFFSET -
        (UNIT_COLUMN_DESKTOP + COL_GAP)

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth)
        }

        const mediaWatcher = window.matchMedia(
            `(max-width: ${desktopBreakPoints[windowWidthRange]}px)`
        )

        const updateColumn = () => {
            setColWidth(() => (100 * GRID_COL_WIDTH_DESKTOP) / actualWidth)
        }

        updateColumn()

        if (mediaWatcher.addEventListener) {
            window.addEventListener('resize', handleResize)
            mediaWatcher.addEventListener('change', updateColumn)
            return () => {
                mediaWatcher.removeEventListener('change', updateColumn)
                window.removeEventListener('resize', handleResize)
            }
        } else {
            mediaWatcher.addListener(updateColumn)
            return () => {
                mediaWatcher.removeListener(updateColumn)
            }
        }
    }, [windowWidthRange, actualWidth, innerWidth])

    return col
}
