export const getGameName = (fileName: string): string => {
    // removes every [] and replaces them by ' '
    fileName = fileName.replace(/ *\[[^)]*\] */g, ' ')
    // removes every () and replaces them by ' '
    fileName = fileName.replace(/ *\([^)]*\) */g, ' ')
    // removes every _ and replaces them by ' '
    fileName = fileName.replace(/_/g, ' ')
    // removes every extensions (some file can have up to 3 extensions, e.g. `bin.ecm.zip`)
    fileName = fileName.replace(/\.[^/.]+$/, '')
    fileName = fileName.replace(/\.[^/.]+$/, '')
    fileName = fileName.replace(/\.[^/.]+$/, '')
    // removes every random text at the end of the file (identified by adding multiple spaces before by replacing () and [] by spaces)
    fileName = fileName.split('   ')[0]
    // trims and returns the name
    return fileName.trim()
}
