import React, { useCallback, FC } from 'react'
import { RoomSettings } from '../../GameSettings'
import { Modal } from '../../../stories'
import { useDeviceOrientation, sleep } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionSettings, getSession } from '../../../firebase'
import { setSession } from '../../../store/session/actions'
import { State } from '../../../store/types'
import { usePausePlay } from '../../../utils/GameRoomHooks'
import { setMannequinMode } from '../../../store/mask/actions'

interface GameSettingsModalProps {
    showRoomSettings: boolean
    setShowRoomSettings: (show: boolean) => void
}

export const MobileGameSettingsModal: FC<GameSettingsModalProps> = ({
    showRoomSettings,
    setShowRoomSettings,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const orientation = useDeviceOrientation()
    const sessionID = useSelector((state: State) => state.session.sessionID)
    const session = useSelector((state: State) => state.session.session)
    const isHost = useSelector((state: State) => state.session.isHost)

    const { handleResumeGame } = usePausePlay()

    const roomSettingsModalType = {
        position: 'bottom',
        backdrop: false,
        size: orientation.includes('landscape') ? 'fullheight' : 'fullwidth',
        style: orientation.includes('landscape')
            ? {
                  width: '63vw',
              }
            : {
                  // FIXME: We need something more robust here... for now this does the work...
                  height: 'calc( 100% - 112px )', // full height - video conference space
              },
    }

    const refreshSession = useCallback(async () => {
        await sleep(600)
        const session = await getSession(sessionID)
        dispatch(setSession(session))
    }, [sessionID, dispatch])

    return (
        <Modal
            show={showRoomSettings}
            onClose={() => {
                handleResumeGame()
                setShowRoomSettings(false)
            }}
            title={t('RoomConfiguration')}
            {...roomSettingsModalType}
        >
            <RoomSettings
                showCameraMessage={true}
                disabled={!isHost}
                roomSettings={session}
                onConfirm={(settings) => {
                    setSessionSettings(sessionID, settings)
                    dispatch(setMannequinMode(true))
                    refreshSession()
                    setShowRoomSettings(false)
                }}
            />
        </Modal>
    )
}
