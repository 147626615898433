import { useFrame, useThree } from '@react-three/fiber'

// fake component, display nothing
// just used to get the Camera and the renderer used by React-fiber:
const ThreeGrabber = (props) => {
    const threeFiber = useThree()
    props.globals.threeCamera = threeFiber.camera
    props.globals.threeScene = threeFiber.scene
    props.globals.threeRenderer = threeFiber.gl

    if (props.isCameraForcedPose) {
        const cam = threeFiber.camera
        cam.near = 1.0
        cam.far = 10000.0
        cam.fov = 40
        cam.position.set(0, 0, 0)
        cam.updateProjectionMatrix()
    }

    // enable layer1 (with occluders) for the camera:
    threeFiber.camera.layers.enable(1)
    useFrame(
        props.threeHelper.update_threeCamera.bind(
            null,
            props.resolution,
            threeFiber.camera
        )
    )
    return null
}

export default ThreeGrabber
