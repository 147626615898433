import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import Loader from './RedeemLoader'
import RedeemFail from './RedeemFail'
import { IRedeemModalData } from './types'
import RedeemSuccess from './RedeemSuccess'

export interface IRedeemModal {
    isLoading: boolean
    modalData: IRedeemModalData
    setModalData: (val: IRedeemModalData) => void
}

const RedeemModal: React.FC<IRedeemModal> = ({
    isLoading,
    modalData,
    setModalData,
}) => {
    const { t } = useTranslation()
    const isSuccess = modalData?.status === 200
    const handleModalClose = () => setModalData(null)

    const modalTitle =
        (isLoading && 'RedeemModalTitleLoading') ||
        (isSuccess && 'RedeemModalTitleSuccess') ||
        'RedeemModalTitleFail'

    return (
        <Modal
            centered
            backdrop="static"
            enforceFocus={false}
            show={isLoading || Boolean(modalData?.status)}
            onHide={handleModalClose}
            dialogClassName="redeemcode_modal"
            contentClassName="redeemcode_modal_content"
        >
            <Modal.Header>
                <Modal.Title>{t(modalTitle)}</Modal.Title>
            </Modal.Header>
            {isLoading ? (
                <Loader />
            ) : isSuccess ? (
                <RedeemSuccess redeemedItems={modalData.data?.redeemedItems} />
            ) : (
                <RedeemFail />
            )}
            {!isLoading && (
                <>
                    <Button
                        onClick={() => {
                            setModalData(null)
                        }}
                    >
                        {t('RedeemModalAction')}
                    </Button>

                    <span className="redeemcode_modal_footer">
                        <Trans i18nKey="RedeemModalSupport">
                            <span>Questions? Reach out to </span>
                            <a href="mailto:support@piepacker.com">
                                support@piepacker.com
                            </a>
                        </Trans>
                    </span>
                </>
            )}
        </Modal>
    )
}

export default RedeemModal
