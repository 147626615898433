import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useSlider } from './useSlider'
import { Icon } from '../../../stories'
import { SliderProps } from './types'

const GamesSlider: SliderProps = ({ style, children, className, title }) => {
    const {
        dots,
        page,
        prevDisable,
        nextDisable,
        handlePrev,
        handleNext,
        sliderRef,
        handleDotClick,
    } = useSlider()
    const isValidDots = dots > 1 && !Number.isNaN(dots) && isFinite(dots)
    const itemHeight =
        sliderRef.current?.firstElementChild?.getElementsByClassName(
            'sliderCardBody'
        )[0]?.clientHeight

    const arrowStyles = { height: itemHeight ? `${itemHeight}px` : '100%' }
    return (
        <div>
            {title && (
                <div className={styles.titleContainer}>
                    <div>{title && title}</div>
                    {isValidDots && (
                        <div className={styles.dots}>
                            {Array(dots)
                                .fill('')
                                .map((key, index) => (
                                    <div
                                        key={index}
                                        className={classNames(styles.dot, {
                                            [styles.selected]: page === index,
                                        })}
                                        onClick={() => {
                                            handleDotClick(index)
                                        }}
                                    ></div>
                                ))}
                        </div>
                    )}
                </div>
            )}
            <div className={styles.container}>
                <div
                    style={style}
                    ref={sliderRef}
                    className={classNames(styles.carousel, className)}
                >
                    {children}
                </div>
                <div
                    onClick={handlePrev}
                    className={classNames(styles.arrow, styles.arrowLeft, {
                        [styles.disabled]: prevDisable,
                    })}
                    style={arrowStyles}
                >
                    <div className={styles.arrowLeftIcon}>
                        <Icon name="arrowRight" />
                    </div>
                </div>
                <div
                    onClick={handleNext}
                    className={classNames(styles.arrow, styles.arrowRight, {
                        [styles.disabled]: nextDisable,
                    })}
                    style={arrowStyles}
                >
                    <Icon name="arrowRight" />
                </div>
            </div>
        </div>
    )
}

export default GamesSlider
