import { KEYBOARD_MAP } from '../input/keyboard'
import {
    PubSub,
    PubSubEvent,
    SubscriptionInterface,
    globalPubSub,
} from '../event/event'
import store, { actions } from '../store'
import * as Input from '../input/input'
import * as Joystick from '../input/joystick'
import * as Socket from '../network/socket'
import { PlayerIndex } from '../store/session/types'
import { piepie } from '../utils'

export type Device = {
    id: number
    name: string
    gamepad: Gamepad
}

export type LocalPlayer = {
    index: number
    device: number
    localhost: boolean
}

export default class InputHandler {
    devices: Device[]
    localPlayers: LocalPlayer[]
    gamepadConnectedSub: SubscriptionInterface
    gamepadDisconnectedSub: SubscriptionInterface
    localPlayersChangedSub: SubscriptionInterface
    reqSwitchIndicesSub: SubscriptionInterface
    rcvSwitchIndicesSub: SubscriptionInterface
    indicesSub: SubscriptionInterface
    userSessionID: string
    localhost: number

    constructor(pubsub: PubSub) {
        piepie.log('[INPUT HANDLER] init')
        this.userSessionID = store.getState().session.userSessionID
        // we init the devices list
        this.devices = [
            {
                id: -1,
                name: 'Keyboard',
                gamepad: null,
            },
        ]
        this.localhost = -1
        this.localPlayers = []
        Input.setLocalPlayers(this.localPlayers)
        store.dispatch(actions.session.setDevices(this.devices.slice()))
        this.updatePlayersIndices()

        this.gamepadConnectedSub = pubsub.sub(
            PubSubEvent.GAMEPAD_CONNECTED,
            this.add,
            0
        )

        this.gamepadDisconnectedSub = pubsub.sub(
            PubSubEvent.GAMEPAD_DISCONNECTED,
            this.remove,
            0
        )

        this.indicesSub = pubsub.sub(
            PubSubEvent.NEW_PLAYER_INDICES,
            this.updatePlayersIndices,
            0
        )

        this.localPlayersChangedSub = pubsub.sub(
            PubSubEvent.LOCAL_PLAYERS_CHANGED,
            () => {
                piepie.log('[INPUT HANDLER] local players changed')
                this.localPlayers = store.getState().session.localPlayers
                Input.setLocalPlayers(this.localPlayers)
            },
            0
        )

        this.reqSwitchIndicesSub = pubsub.sub(
            PubSubEvent.REQ_SWITCH_INDICES,
            (switchIndices) => {
                if (switchIndices.length !== 2) return
                const p = store.getState().session.playerIndices
                const request = {}
                for (const [usid, indices] of Object.entries(p)) {
                    for (let i = 0; i < indices.length; i++) {
                        if (
                            indices[i] === switchIndices[0] ||
                            indices[i] === switchIndices[1]
                        ) {
                            request[indices[i]] = usid
                        }
                    }
                }
                // if we switch with an empty player
                if (Object.entries(request).length === 1) {
                    if (typeof request[switchIndices[0]] === 'undefined') {
                        request[switchIndices[0]] = 'none'
                    } else {
                        request[switchIndices[1]] = 'none'
                    }
                }
                piepie.log(
                    '[INPUT HANDLER] req switch indices:',
                    JSON.stringify(request)
                )
                Socket.switchIndices(JSON.stringify(request))
            },
            0
        )

        this.rcvSwitchIndicesSub = pubsub.sub(
            PubSubEvent.RCV_SWITCH_INDICES,
            (switchIndices) => {
                this.onKeyReset()
                this.updateLocalhost(switchIndices)
                const playerIndices = store.getState().session.playerIndices
                const playerIndexes: PlayerIndex[] = []
                for (const [id, idx] of Object.entries(playerIndices)) {
                    for (let i = 0; i < idx.length; i++) {
                        if (idx[i] === switchIndices[0]) {
                            idx[i] = switchIndices[1]
                        } else if (idx[i] === switchIndices[1]) {
                            idx[i] = switchIndices[0]
                        }
                    }
                    playerIndexes.push({
                        UserSessionID: id,
                        PlayerIdx: idx,
                    })
                }
                piepie.log(
                    '[INPUT HANDLER] rcv switch indices:',
                    JSON.stringify(playerIndexes)
                )
                store.dispatch(actions.session.setPlayerIndices(playerIndexes))
                globalPubSub.pub(PubSubEvent.NEW_PLAYER_INDICES, '')
            },
            0
        )
        // keydown and keyup event listeners are triggered when the players
        // uses keyboard keys.
        document.addEventListener('keydown', this.onKeyDown, false)
        document.addEventListener('keyup', this.onKeyUp, false)
        document.addEventListener(
            'visibilitychange',
            () => {
                if (document.visibilityState !== 'visible') this.onKeyReset()
            },
            false
        )

        // Register gamepads that already connected
        const gamepads = navigator.getGamepads()
        for (let i = 0; i < gamepads.length; i++) {
            const gamepad = gamepads[i]
            if (gamepad) Joystick.registerGamepad(gamepad)
        }
    }

    updateLocalhost = (players: Array<number>) => {
        piepie.log('[INPUT HANDLER] update localhost:', JSON.stringify(players))
        let index = -1
        // players.length should be equal to 2
        for (let i = 0; i < players.length; i++) {
            if (players[i] === this.localhost) {
                index = i
                break
            }
        }
        if (index > -1) this.localhost = players[(index + 1) % 2]
        piepie.log('[INPUT HANDLER] new localhost:', this.localhost)
    }

    // adds a controller to the list
    add = (gamepad: Gamepad) => {
        // we add the gamepad if it doesn't exist
        // if we want the keyboard to be at the end of the devices list, we just have to replace `push` by `unshift` to put the pads first
        // the devices order in this list will impact the automatic assignment when a new local player is added
        piepie.log('[INPUT HANDLER] add gamepad:', gamepad.id, gamepad.index)
        this.devices.push({
            id: gamepad.index,
            name: gamepad.id,
            gamepad: gamepad,
        })
        store.dispatch(actions.session.setDevices(this.devices.slice()))
        this.addOrRemoveDevice(gamepad.index)
    }

    // removes a controller from the list
    remove = (gamepad: Gamepad) => {
        // if the gamepad already exists, then we want to remove it
        piepie.log('[INPUT HANDLER] remove gamepad:', gamepad.id, gamepad.index)
        for (let i = 0; i < this.devices.length; i++) {
            if (this.devices[i].id === gamepad.index) {
                this.devices.splice(i, 1)
                store.dispatch(actions.session.setDevices(this.devices.slice()))
                this.addOrRemoveDevice(gamepad.index, true)
                return
            }
        }
    }

    // maps device <--> player
    mapDevice = (id: number, idx: number) => {
        piepie.log('[INPUT HANDLER] map device:', id, idx)
        for (let i = 0; i < this.localPlayers.length; i++) {
            if (this.localPlayers[i].index === idx) {
                this.localPlayers[i].device = id
                return
            }
        }
        Input.setLocalPlayers(this.localPlayers)
    }

    // assigns a gamepad to the first player who needed a gamepad (or removes it)
    addOrRemoveDevice = (id: number, remove = false) => {
        const playerIdx = Input.getDeviceIndex(id)
        if (remove && playerIdx >= 0) {
            this.mapDevice(null, playerIdx)
        } else if (!remove && playerIdx === -1) {
            for (const [, player] of Object.entries(this.localPlayers)) {
                if (player.device === null) {
                    this.mapDevice(id, player.index)
                    store.dispatch(
                        actions.session.setLocalPlayers(
                            this.localPlayers.slice()
                        )
                    )
                    return
                }
            }
        }
    }

    // returns the id of the first available device found, null otherwise
    getAvailableDevice = (): number => {
        for (const [, device] of Object.entries(this.devices)) {
            if (Input.getDeviceIndex(device.id) === -1) {
                return device.id
            }
        }
        return null
    }

    // returns true if a player index is part of the local player indexes
    isIndexInLocalPlayers = (idx: number): boolean => {
        for (const [, player] of Object.entries(this.localPlayers)) {
            if (player.index === idx) return true
        }
        return false
    }

    // maps the local player index (removes or adds)
    mapIndex = (idx: number[]) => {
        const idxList = []
        const localCount = this.localPlayers.length
        for (let i = 0; i < idx.length; i++) {
            // add
            if (!this.isIndexInLocalPlayers(idx[i])) {
                this.localPlayers.push({
                    index: idx[i],
                    device: this.getAvailableDevice(),
                    localhost: this.localhost === idx[i],
                })
            } else {
                idxList.push(idx[i])
            }
        }
        if (idxList.length < localCount && idx.length !== localCount) {
            // remove
            for (let p = 0; p < localCount; p++) {
                if (
                    this.localPlayers[p] &&
                    !idxList.includes(this.localPlayers[p].index)
                ) {
                    this.localPlayers.splice(p, 1)
                }
            }
        }
        // we remove the players that are not in local
        const playerIndices = store.getState().session.playerIndices
        for (const [id, idx] of Object.entries(playerIndices)) {
            for (let p = 0; p < idx.length; p++) {
                for (let i = 0; i < this.localPlayers.length; i++) {
                    if (
                        idx[p] === this.localPlayers[i].index &&
                        this.userSessionID !== id
                    ) {
                        this.localPlayers.splice(i, 1)
                        break
                    }
                }
            }
        }
    }

    // updates the field localhost
    mapLocalhost = () => {
        let device: number
        let oldLocalHost = 0
        for (let i = 0; i < this.localPlayers.length; i++) {
            if (this.localPlayers[i].localhost) {
                this.localPlayers[i].localhost = false
                device = this.localPlayers[i].device
                oldLocalHost = i
                break
            }
        }
        for (let i = 0; i < this.localPlayers.length; i++) {
            if (this.localhost === this.localPlayers[i].index) {
                this.localPlayers[oldLocalHost].device =
                    this.localPlayers[i].device
                this.localPlayers[i].device = device
                this.localPlayers[i].localhost = true
                break
            }
        }
    }

    // updatePlayersIndices updates the mapping between all local players and their index
    updatePlayersIndices = () => {
        const playerIndices = store.getState().session.playerIndices
        for (const [id, idx] of Object.entries(playerIndices)) {
            if (id === this.userSessionID) {
                if (this.localhost === -1) {
                    this.localhost = idx[0]
                }
                this.mapIndex(idx)
                this.mapLocalhost()
                Input.setLocalPlayers(this.localPlayers)
                store.dispatch(
                    actions.session.setLocalPlayers(this.localPlayers)
                )
                piepie.log(
                    '[INPUT HANDLER] update players indices:',
                    idx,
                    this.localPlayers.length,
                    this.localhost
                )
                return
            }
        }
    }

    // we send the request to lemmings to add a new local player
    addLocalPlayer = () => {
        piepie.log('[INPUT HANDLER] add new local player')
        Socket.addLocalPlayer()
    }

    // we send the request to lemmings to remove a local player
    removeLocalPlayer = (id: number) => {
        piepie.log('[INPUT HANDLER] remove local player:', id)
        Socket.removeLocalPlayer(id)
    }

    // onKey will translate the key code from the KEYBOARD map
    // key is released if isPressed is false
    // It then sends the key state to the input module
    onKey = (ev: KeyboardEvent, isPressed: boolean) => {
        const id = KEYBOARD_MAP.get(ev.code)
        if (id !== undefined && Input.isControllerActive(-1)) {
            const idx = Input.getDeviceIndex(-1)
            Input.setKeyState(idx >= 0 ? idx : 0, id, isPressed)
            Input.setLastUsedDevice(-1)
        }
    }

    isUserBacksElement = (target) => {
        const userbackContainer = document.querySelector(
            '.userback-button-container'
        )
        if (userbackContainer) {
            return userbackContainer.contains(target)
        } else {
            return false
        }
    }

    isRoomDescription = (target) => {
        const roomDescription = document.querySelector('#roomDescription')
        return roomDescription?.contains(target)
    }

    onKeyDown = (ev) => {
        if (
            ev.target.tagName !== 'INPUT' &&
            // This is introduced to allow space and backspace use on the feedback tool in game room
            ev.target.tagName !== 'UBCOMMENT' &&
            !this.isUserBacksElement(ev.target) &&
            !this.isRoomDescription(ev.target)
        ) {
            // space and arrow keys
            if ([32, 37, 38, 39, 40].indexOf(ev.keyCode) > -1)
                ev.preventDefault()
            this.onKey(ev, true)
        }
    }

    onKeyUp = (ev) => {
        if (
            ev.target.tagName !== 'INPUT' &&
            // This is introduced to allow space and backspace use on the feedback tool in game room
            ev.target.tagName !== 'UBCOMMENT' &&
            !this.isUserBacksElement(ev.target)
        ) {
            // space and arrow keys
            if ([32, 37, 38, 39, 40].indexOf(ev.keyCode) > -1)
                ev.preventDefault()
            this.onKey(ev, false)
        }
    }

    onKeyReset = () => {
        Input.resetKeyState()
    }

    deinit = () => {
        piepie.log('[INPUT HANDLER] deinit')
        this.gamepadConnectedSub.unsub()
        this.gamepadDisconnectedSub.unsub()
        this.localPlayersChangedSub.unsub()
        this.reqSwitchIndicesSub.unsub()
        this.rcvSwitchIndicesSub.unsub()
        this.indicesSub.unsub()
        document.removeEventListener('keydown', this.onKeyDown, false)
        document.removeEventListener('keyup', this.onKeyUp, false)
    }
}
