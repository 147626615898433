import styles from './styles.module.scss'
import React, { useState, FC, useCallback } from 'react'
import classnames from 'classnames'
import { ICard, CardKind } from './types'
import CardFooter from './CardFooter'
import { convertHexToRGBA } from './utils'
import CardBody from './CardBody'

const Card: FC<ICard & CardKind> = ({
    game,
    className,
    category,
    availablePublicRooms,
    handleClick,
    handleOpenGameModal,
    gradientColor,
    ...props
}) => {
    const [loadVideo, setLoadVideo] = useState<boolean>(false)
    const gradient = gradientColor || '#000000'
    const bgGradient = `linear-gradient(180deg, ${convertHexToRGBA(
        gradient,
        0
    )} 55.82%, ${convertHexToRGBA(gradient, 0.7)} ${
        props.kind === 'recent' ? '81.27%' : '74.23%'
    }, ${convertHexToRGBA(gradient, 0.85)} 100%)`

    const handleCardClick = useCallback(() => {
        if (!game.Competition) handleOpenGameModal()
        else handleClick()
        analytics.track('Click Game Card', { gameName: game.id })
    }, [game.id, game.Competition, handleClick, handleOpenGameModal])

    return (
        <div
            className={styles.container}
            onMouseEnter={() => {
                setLoadVideo(true)
                analytics.track('Mouse Game Card', { gameName: game.id })
            }}
            onTouchStart={() =>
                analytics.track('Touch Game Card', { gameName: game.id })
            }
        >
            <div
                onClick={handleCardClick}
                className={classnames(styles.card, className, 'sliderCardBody')}
            >
                <div
                    className={styles.cardOverlay}
                    style={{ backgroundImage: bgGradient }}
                ></div>
                <div
                    className={classnames(styles.cardBackground, {
                        [styles.vertical]: props.kind === 'recent',
                        [styles.featured]: props.kind === 'featured',
                        [styles.list]: category === 'List',
                    })}
                ></div>
                <CardBody
                    game={game}
                    category={category}
                    loadVideo={loadVideo}
                    availablePublicRooms={availablePublicRooms}
                    {...props}
                />
            </div>
            <CardFooter game={game} category={category} />
        </div>
    )
}

export default Card
