import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Redeem from './Redeem'
import PlanDetails from './PlanDetails'
import ActiveFeatures from './ActiveFeatures'

const PlanPayments: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <p className={styles.pageTitle}>{t('PlanPayments')}</p>
            <PlanDetails />
            <div className={styles.ruler}></div>
            <ActiveFeatures />
            <div className={styles.ruler}></div>
            <Redeem />
        </>
    )
}

export default PlanPayments
