import { AnimationMixer, LoopOnce } from 'three'

const _animationsNameChunks = {
    openMouth: 'Open_Mouth',
    wink: 'Wink',
    LWink: 'LWink',
    RWink: 'RWink',
    eyebrowsUp: 'Eyebrows_up',
    eyebrowsDown: 'Eyebrows_down',
    smile: 'Smile',
}

const _playAnimationStates = {
    notSet: -1,
    notFound: 0,
    alreadyPlaying: 1,
    locked: 2,
    played: 3,
}
const _materialVariantsSets = new Set()

const create_animAction = () => {
    return {
        arr: [],
        played: null,
        curs: 0,
    }
}
const create_animationActions = () => {
    return {
        openMouth: create_animAction(),
        smile: create_animAction(),
        eyebrowsUp: create_animAction(),
        eyebrowsDown: create_animAction(),
        wink: create_animAction(),
        LWink: create_animAction(),
        RWink: create_animAction(),
    }
}
let _animationActions = create_animationActions()
let _gltfAnimations = []
let _materialVariantFeature = null
let _threeAnimationMixer = null
const _externalTriggeredAnimationActions = []

let _isLocked = false
let _isFaceExpressionsDetection = false
let _faceExpressionsTrack = null
let _recordFaceExpressionsTimestampStart = -1

const lock = () => {
    console.log('LOCK ANIMATION')
    _isLocked = true
}

const unlock = () => {
    console.log('UNLOCK ANIMATION')
    _isLocked = false
}

const isLocked = () => {
    return _isLocked
}

const playAnimation = (animId) => {
    const anims = _animationActions[animId]

    if (anims.arr.length === 0) {
        return {
            state: _playAnimationStates.notFound,
            anim: null,
        }
    }
    const anim = anims.arr[anims.curs]

    const { isExclusive, isInvertible } = anim
    if (isExclusive && isLocked()) {
        return {
            state: _playAnimationStates.locked,
            anim: null,
        }
    }
    if (anims.arr.length >= 2 && anims.played && anims.played.isRunning()) {
        return {
            state: _playAnimationStates.alreadyPlaying,
            anim: null,
        }
    }

    anims.curs = (anims.curs + 1) % anims.arr.length
    const previousAnim = anims.played
    anims.played = anim

    if (anims.arr.length === 1) {
        anim.stop()
    } else {
        if (previousAnim !== null) {
            previousAnim.stop()
        }
        anim.clampWhenFinished = true
        anim.reset()
    }
    if (isInvertible) {
        anim.clampWhenFinished = true
        anim.timeScale = 1
    }
    if (isExclusive && anims.arr.length === 1) {
        lock()
    }
    anim.play()
    return {
        state: _playAnimationStates.played,
        anim,
    }
}

const stopAnimation = (animId) => {
    const anims = _animationActions[animId]

    if (anims.arr.length === 0 || anims.played === null) {
        return
    }
    if (anims.arr.length >= 2) {
        return
    }
    const anim = anims.played
    anims.played = null
    anim.stop()

    const { isExclusive, isInvertible } = anim
    if (isExclusive && isLocked()) {
        unlock()
    }

    if (isInvertible) {
        // for eye open and close
        anim.clampWhenFinished = false
        anim.timeScale = -1
        anim.play()
    }
}

const clip_actionOnce = (animationMixer, animationClip) => {
    const threeAction =
        animationMixer.existingAction(animationClip) ||
        animationMixer.clipAction(animationClip)
    return threeAction
}

const get_timestamp = () => {
    return performance.now() / 1000.0 // in seconds
}

const init = (WEBARROCKSFACE, spec) => {
    const {
        globals,
        expressionsDetector,
        model,
        gltfAnimations,
        isFaceExpressionsDetection,
    } = spec

    _animationActions = create_animationActions()
    globals.threeAnimationMixer = null
    _threeAnimationMixer = null
    _materialVariantsSets.clear()
    _gltfAnimations = []
    _externalTriggeredAnimationActions.splice(0)
    _isFaceExpressionsDetection = isFaceExpressionsDetection
    if (!model) {
        return
    }

    // init animationMixer
    if (gltfAnimations.length) {
        globals.threeAnimationMixer = new AnimationMixer(model)
        _threeAnimationMixer = globals.threeAnimationMixer
        unlock()
        if (!spec.enableSimultaneous) {
            globals.threeAnimationMixer.addEventListener(
                'finished',
                (event) => {
                    const action = event.action
                    if (action.eventsFinished) {
                        action.eventsFinished.forEach((func) => {
                            func()
                        })
                        action.eventsFinished.splice(0)
                    }
                    if (action.isExclusive) {
                        unlock()
                    }
                }
            )
        }
    }

    const get_action = (animationClip, isInvertible, isForceExclusive) => {
        const threeAction = clip_actionOnce(
            globals.threeAnimationMixer,
            animationClip
        )
        threeAction.setLoop(LoopOnce)
        threeAction.isInvertible = isInvertible ? true : false
        threeAction.eventsFinished = []
        threeAction.isExclusive =
            !threeAction.isInvertible && !spec.enableSimultaneous
        threeAction.isExclusive = threeAction.isExclusive || isForceExclusive
        return threeAction
    }

    // sort gltfAnimations by _<indice> order
    // to avoid wrong animation order when multiple animations match an expression
    // 2021-12-07 bug with open/close visor of kknd2_series9_seeder mask
    const get_animationOrderIndice = (anim) => {
        const val = parseInt(anim.name.split('_').pop())
        return isNaN(val) ? -1 : val
    }
    gltfAnimations.sort((animA, animB) => {
        return get_animationOrderIndice(animA) - get_animationOrderIndice(animB)
    })

    gltfAnimations.forEach((animation, i) => {
        const name = animation.name
        const isForceExclusive = name.includes('_exclusive')
        if (name.includes('Loop')) {
            const actionLoop = clip_actionOnce(
                globals.threeAnimationMixer,
                animation
            )
            actionLoop.play()
        } else if (name.includes(_animationsNameChunks.openMouth)) {
            _animationActions.openMouth.arr.push(
                get_action(animation, false, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.wink)) {
            _animationActions.wink.arr.push(
                get_action(animation, false, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.LWink)) {
            _animationActions.LWink.arr.push(
                get_action(animation, true, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.RWink)) {
            _animationActions.RWink.arr.push(
                get_action(animation, true, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.eyebrowsUp)) {
            _animationActions.eyebrowsUp.arr.push(
                get_action(animation, false, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.eyebrowsDown)) {
            _animationActions.eyebrowsDown.arr.push(
                get_action(animation, false, isForceExclusive)
            )
        } else if (name.includes(_animationsNameChunks.smile)) {
            _animationActions.smile.arr.push(
                get_action(animation, false, isForceExclusive)
            )
        }
    })
    _gltfAnimations = gltfAnimations

    // disable frustum culling when animation
    // indeed, the bounding volumes used for frustum culling are not updated
    // during the animation and can be wrong to compute frustum culling
    if (_gltfAnimations.length) {
        disable_frustumCulling(model)
    }

    // set predefined animations:
    expressionsDetector.init(WEBARROCKSFACE, {
        onMouthOpen: dispatch_animation.bind(null, 'openMouth', true), // playAnimation.bind(null, 'openMouth'),
        onMouthClose: dispatch_animation.bind(null, 'openMouth', false), // null
        onSmileStart: dispatch_animation.bind(null, 'smile', true), // playAnimation.bind(null, 'smile'),
        onSmileEnd: dispatch_animation.bind(null, 'smile', false), //null,
        onEyeLeftClose: dispatch_animation.bind(null, 'LWink', true), //playAnimation.bind(null, 'LWink'),
        onEyeLeftOpen: dispatch_animation.bind(null, 'LWink', false), // stopAnimation.bind(null, 'LWink'),
        onEyeRightClose: dispatch_animation.bind(null, 'RWink', true), //playAnimation.bind(null, 'RWink'),
        onEyeRightOpen: dispatch_animation.bind(null, 'RWink', false), //stopAnimation.bind(null, 'RWink'),
        onWinkStart: dispatch_animation.bind(null, 'wink', true), //playAnimation.bind(null, 'wink'),
        onWinkEnd: dispatch_animation.bind(null, 'wink', false), // null
        onEyebrowsUpStart: dispatch_animation.bind(null, 'eyebrowsUp', true), // playAnimation.bind(null, 'eyebrowsUp'),
        onEyebrowsUpEnd: dispatch_animation.bind(null, 'eyebrowsUp', false), //null,
        onEyebrowsDownStart: dispatch_animation.bind(
            null,
            'eyebrowsDown',
            true
        ), //playAnimation.bind(null, 'eyebrowsDown'),
        onEyebrowsDownEnd: dispatch_animation.bind(null, 'eyebrowsDown', false), //null,
    })
}

const record_animation = (animationName, isToggled) => {
    if (_faceExpressionsTrack !== null) {
        _faceExpressionsTrack.push({
            name: animationName,
            v: isToggled,
            t: get_timestamp() - _recordFaceExpressionsTimestampStart,
        })
    }
}

const dispatch_animation = (animationName, isToggled) => {
    record_animation(animationName, isToggled)

    if (!_isFaceExpressionsDetection) return

    const wasLocked = isLocked()
    let playAnimationReturn = null

    //console.log('INFO in AnimationFeature: dispatch_animation()', animationName, isToggled)
    if (isToggled) {
        switch (animationName) {
            case 'openMouth':
            case 'smile':
            case 'LWink':
            case 'RWink':
            case 'wink':
            case 'eyebrowsUp':
            case 'eyebrowsDown':
                playAnimationReturn = playAnimation(animationName)
                break
            default:
                break
        }
    } else {
        switch (animationName) {
            case 'LWink':
            case 'RWink':
                stopAnimation(animationName)
                break
            default:
                break
        }
    }

    if (_materialVariantFeature !== null) {
        // see https://github.com/piepacker/wagashi/issues/7385

        const matName = _animationsNameChunks[animationName]
        let isMaterialReplaced = false

        const isForceInstantaneous =
            (playAnimationReturn === null ||
                playAnimationReturn.state === _playAnimationStates.notFound ||
                playAnimationReturn.state === _playAnimationStates.played) &&
            !_materialVariantsSets.has(matName)

        isMaterialReplaced = _materialVariantFeature.update(
            matName,
            isToggled,
            isForceInstantaneous,
            wasLocked
        )

        if (
            playAnimationReturn !== null &&
            playAnimationReturn.state === _playAnimationStates.played &&
            isMaterialReplaced
        ) {
            _materialVariantsSets.add(matName)
        }

        if (
            playAnimationReturn !== null &&
            playAnimationReturn.state === _playAnimationStates.played &&
            isMaterialReplaced
        ) {
            // add event to switch off material variant at the end of the animation
            playAnimationReturn.anim.eventsFinished.push(() => {
                _materialVariantFeature.update(matName, false, true)
                _materialVariantsSets.delete(matName)
            })
        }
    }
}

const extract_animation = (animationName) => {
    if (!_gltfAnimations) {
        return null
    }
    return _gltfAnimations.find((animationClip) => {
        return animationClip.name === animationName
    })
}

const disable_frustumCulling = (model) => {
    model.traverse((threeNode) => {
        if (threeNode.frustumCulled === true) {
            threeNode.frustumCulled = false
        }
    })
}

const dispatch_externalTrigger = (event) => {
    let animationName = null
    switch (event.type) {
        case 'animation-start':
            animationName = event.name
            record_animation(animationName, true)
            const animationClip = extract_animation(animationName)
            if (animationClip && _threeAnimationMixer) {
                const animationAction = clip_actionOnce(
                    _threeAnimationMixer,
                    animationClip
                )
                animationAction.setLoop(LoopOnce)
                if (
                    !_externalTriggeredAnimationActions.includes(
                        animationAction
                    )
                ) {
                    _externalTriggeredAnimationActions.push(animationAction)
                }
                animationAction.stop()
                animationAction.play()
            }
            break

        case 'animation-stop':
            animationName = event.name
            record_animation(animationName, false)
            _externalTriggeredAnimationActions.forEach((animationAction) => {
                if (
                    animationAction.name === animationName &&
                    animationAction.isRunning()
                ) {
                    animationAction.stop()
                }
            })
            break

        case 'animations-reset':
            _externalTriggeredAnimationActions.forEach((animationAction) => {
                if (animationAction.isRunning()) {
                    animationAction.stop()
                }
            })
            _externalTriggeredAnimationActions.splice(0)
            break
        default:
            break
    }
}

const set_materialVariantFeature = (mvf) => {
    _materialVariantFeature = mvf
}

const start_recording = () => {
    console.log('INFO in AnimationFeature: start recording face expressions...')
    _recordFaceExpressionsTimestampStart = get_timestamp()
    _faceExpressionsTrack = []
}

const stop_recording = () => {
    const r = _faceExpressionsTrack
    _faceExpressionsTrack = null
    return r
}

export default {
    init,
    set_materialVariantFeature,
    dispatch_externalTrigger,
    start_recording,
    stop_recording,
}
