import style from './style.module.scss'

import React, { FC } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

interface TierCardProps {
    className?: string
    selected?: boolean
    title?: string
    descriptionList?: string[]
    titleColor?: string
}

const TierCard: FC<TierCardProps> = ({
    className,
    selected = false,
    title,
    descriptionList = [],
    titleColor,
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={cls(
                style.TierCard,
                className,
                selected && style.selected
            )}
        >
            {selected && (
                <div className={style.currentPlan}>
                    <span className={style.currentPlanText}>
                        {t('TierCurrentPlan')}
                    </span>
                </div>
            )}
            {title && (
                <span
                    className={cls(
                        style.title,
                        titleColor && style[titleColor]
                    )}
                >
                    {title}
                </span>
            )}
            {descriptionList.length > 0 && (
                <ul className={style.descriptionList}>
                    {Object.values(descriptionList).map(
                        (descriptionText, i) => (
                            <li
                                key={i}
                                className={style.descriptionListElement}
                                dangerouslySetInnerHTML={{
                                    __html: descriptionText,
                                }}
                            ></li>
                        )
                    )}
                </ul>
            )}
        </div>
    )
}

export default TierCard
