import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { coreToConsole } from '../../components/PublicRoomsList/utils'
import { Game } from '../../state/starfox'
import {
    selectSearch,
    selectIsListModified,
    selectSelectedTags,
    selectOpenRoom,
    selectOpenRoomsGames,
    selectCategoryDisplayed,
    selectOpenRooms,
} from '../../store/home/homeV2'
import { normalizeRemoveDiacritic, removeDuplicates } from '../../utils'
import { numberOfPlayersAsTags } from './Commons/constants'

const useSearchFilterSort = (gamesList: ReadonlyArray<Game>) => {
    const search = useSelector(selectSearch)
    const selectedTags = useSelector(selectSelectedTags)
    const isListModified = useSelector(selectIsListModified)
    const openRoomsGames = useSelector(selectOpenRoomsGames)
    const openRooms = useSelector(selectOpenRooms)
    const isOpenRoom = useSelector(selectOpenRoom)
    const category = useSelector(selectCategoryDisplayed)

    const gamesPrepped = useMemo(() => {
        if (!isListModified) return gamesList

        // we display BYOG only for trending or open rooms
        // let preppedGamesList = [...gamesList].filter((game) =>
        //     isOpenRoom || category === 'Trending'
        //         ? true
        //         : !game.Categories?.includes('BYOG')
        // )
        let preppedGamesList = [...gamesList]

        if (category && category !== 'Trending') {
            if (category === 'AllGames') {
                preppedGamesList = [...preppedGamesList].sort((a, b) => {
                    const nameA = a.DisplayName.toLowerCase()
                    const nameB = b.DisplayName.toLowerCase()
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return null
                })
            }
            if (category === 'RecentlyAdded') {
                preppedGamesList = preppedGamesList.filter((game) => game.IsNew)
            } else {
                preppedGamesList = preppedGamesList.filter((game) =>
                    game.Categories?.includes(category)
                )
            }
        }
        const normalizedSearchString =
            normalizeRemoveDiacritic(search).toLocaleLowerCase()
        if (search) {
            // search in game list
            preppedGamesList = preppedGamesList.reduce(
                (acc, game, index, arr) => {
                    const {
                        Tags,
                        System,
                        Publisher,
                        DisplayName,
                        ReleaseYear,
                    } = game
                    if (
                        normalizeRemoveDiacritic(DisplayName)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[0].push(game)
                    }
                    if (
                        normalizeRemoveDiacritic(System)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[1].push(game)
                    }
                    if (String(ReleaseYear).includes(normalizedSearchString)) {
                        acc[2].push(game)
                    }
                    if (
                        normalizeRemoveDiacritic(Publisher)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        acc[3].push(game)
                    }
                    if (
                        Tags.map((tag) => tag.toLocaleLowerCase()).includes(
                            normalizedSearchString
                        )
                    ) {
                        acc[4].push(game)
                    }
                    /* fold list on last element removing games that could
                    possibly meet more than one criteria */
                    if (index === arr.length - 1) {
                        return removeDuplicates(acc.flat())
                    }

                    return acc
                },
                [[], [], [], [], []]
            )

            // search in open room titles for BYOG games
            for (const game of openRoomsGames) {
                if (!game.Categories?.includes('BYOG')) continue
                for (const room of openRooms) {
                    if (
                        room.IsCurrentBYOG &&
                        coreToConsole(
                            room.Games[room.Games.length - 1].GameCore
                        ) === game.id &&
                        normalizeRemoveDiacritic(room.RoomTitle)
                            .toLocaleLowerCase()
                            .includes(normalizedSearchString)
                    ) {
                        preppedGamesList.push(game)
                    }
                }
            }
            // remove duplicates
            preppedGamesList = removeDuplicates(preppedGamesList.flat())
        }

        if (selectedTags.length > 0) {
            preppedGamesList = preppedGamesList.filter((g) =>
                selectedTags.every((selectedTag) => {
                    if (numberOfPlayersAsTags[selectedTag]) {
                        return numberOfPlayersAsTags[selectedTag] === 1
                            ? g.Players === numberOfPlayersAsTags[selectedTag]
                            : g.Players >= numberOfPlayersAsTags[selectedTag]
                    }
                    return g.Tags.includes(selectedTag)
                })
            )
        }

        if (isOpenRoom || category === 'Trending') {
            preppedGamesList = preppedGamesList.filter((g) => {
                for (const game of openRoomsGames) {
                    if (g.id === game.id) return true
                }
                return false
            })
            preppedGamesList = openRoomsGames.length ? preppedGamesList : []
        }
        // when displaying the search page, we scroll top to avoid weird behavior
        window.scrollTo(0, 0)

        //Tracks when filters and search queries change, ignores one-letter search queries
        if (isListModified && search.length !== 1) {
            analytics.track('Updated Filters and Search', {
                tags: selectedTags,
                search: search,
                open_room_filter: isOpenRoom,
                categories: category,
            })
        }

        return preppedGamesList
    }, [
        search,
        gamesList,
        isOpenRoom,
        selectedTags,
        isListModified,
        openRoomsGames,
        openRooms,
        category,
    ])

    return {
        gamesList: gamesPrepped,
    }
}

export default useSearchFilterSort
