import { KEY } from './keys'

export const KEYBOARD_MAP = new Map<string, number>()
KEYBOARD_MAP.set('ArrowLeft', KEY.LEFT)
KEYBOARD_MAP.set('ArrowUp', KEY.UP)
KEYBOARD_MAP.set('ArrowRight', KEY.RIGHT)
KEYBOARD_MAP.set('ArrowDown', KEY.DOWN)
KEYBOARD_MAP.set('KeyS', KEY.B)
KEYBOARD_MAP.set('KeyD', KEY.A)
KEYBOARD_MAP.set('KeyA', KEY.Y)
KEYBOARD_MAP.set('KeyW', KEY.X)
KEYBOARD_MAP.set('KeyQ', KEY.L1)
KEYBOARD_MAP.set('Digit1', KEY.L2)
KEYBOARD_MAP.set('KeyE', KEY.R1)
KEYBOARD_MAP.set('Digit3', KEY.R2)
KEYBOARD_MAP.set('Enter', KEY.START)
KEYBOARD_MAP.set('ShiftRight', KEY.SELECT)
KEYBOARD_MAP.set('ShiftLeft', KEY.SELECT)
