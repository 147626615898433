import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { isGame } from '../../utils/loading'

export enum ConfirmationType {
    Reload, // ask if we want to reload or not
    None,
}

interface ConfirmationProps {
    type: ConfirmationType
    onValidate?: () => void
    onCancel?: () => void
    onClose?: () => void
}

export default function Confirmation(props: ConfirmationProps) {
    const {
        type,
        onValidate = () => {
            /** do nothing */
        },
        onCancel = () => {
            /** do nothing */
        },
        onClose = () => {
            /** do nothing */
        },
    } = props
    const { t } = useTranslation()
    const { gameState } = useSelector((state: State) => state.session)

    const handleYesClick = useCallback(() => {
        onValidate()
        onClose()
    }, [onValidate, onClose])

    const handleNoClick = useCallback(() => {
        onCancel()
        onClose()
    }, [onCancel, onClose])

    return (
        <>
            {type === ConfirmationType.Reload &&
                !isGame(gameState).inSelectionScreen &&
                !isMobile && (
                    <div className="screen-reload">
                        <div className="text">{t('ContinueGame')}</div>
                        <div className="buttons">
                            <Button variant="info" onClick={handleYesClick}>
                                {t('Yes')}
                            </Button>
                            &nbsp;
                            <Button variant="info" onClick={handleNoClick}>
                                {t('No')}
                            </Button>
                        </div>
                    </div>
                )}
        </>
    )
}
