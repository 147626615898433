import styles from './styles.module.scss'
import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as selector from '../state/selectors'
import { Switch } from '../../../components/ui'
import { setCurrentBadge } from '../../../firebase'

const Badges: React.FC = () => {
    const { t } = useTranslation()
    const badgeList = useSelector(selector.getBadgeList)
    const currentBadge = useSelector(selector.getCurrentBadge)
    const hasBadge = badgeList.length > 0

    const handleChange = () => {
        const badge = currentBadge ? null : badgeList[0]
        setCurrentBadge(badge)
    }

    return (
        <div className="flex-spacebetween">
            <div>
                <p className={styles.microFont}>{t('Badges')}</p>
                {hasBadge ? (
                    <div className={styles.badgeWrapper}>
                        {/* TODO: Use Badge fom Storybook */}
                        {badgeList &&
                            badgeList.map((badge) => (
                                <div
                                    key={badge.ID}
                                    className={cls(
                                        currentBadge &&
                                            currentBadge.ID === badge.ID &&
                                            styles.badgeBg
                                    )}
                                >
                                    <img
                                        alt={badge.Name}
                                        title={badge.Name}
                                        src={badge.ImageUrl}
                                        className={styles.badgeImg}
                                    />
                                </div>
                            ))}
                    </div>
                ) : (
                    <p className="font-20 font-disabled">{t('NotAvailable')}</p>
                )}
            </div>
            <Switch
                disabled={!hasBadge}
                onChange={handleChange}
                checked={!!currentBadge}
                label={t('ShowOnProfileBadges')}
            />
        </div>
    )
}

export default Badges
