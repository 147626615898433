import React, { useState } from 'react'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import { useTranslation } from 'react-i18next'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'

import { InputText } from '../components/Form/fields'
import { piepie } from '../utils'

export default function EditPassword({ onSuccess }) {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const { t } = useTranslation()

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{t('ChangePassword')}</Modal.Title>
            </Modal.Header>

            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}

            <Formik
                initialValues={{}}
                validationSchema={Yup.object({
                    password: Yup.string()
                        .required(t('NoPasswordProvided'))
                        .min(8, t('PasswordTooShort')),
                    confirmPassword: Yup.string().oneOf(
                        [Yup.ref('password'), null],
                        t('PasswordsDontMatch')
                    ),
                })}
                onSubmit={async (values: any) => {
                    try {
                        const user = firebase.auth().currentUser

                        await user.updatePassword(values.password)

                        onSuccess()
                    } catch (err) {
                        piepie.error(
                            '[ACCOUNT] password edition failed:',
                            err.message
                        )
                        setSubmitError({ error: true, message: err.message })
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Formk>
                        <Form.Group>
                            <Field
                                placeholder={t('NewPassword')}
                                name="password"
                                type="password"
                                component={InputText}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Field
                                placeholder={t('ConfirmNewPassword')}
                                name="confirmPassword"
                                type="password"
                                component={InputText}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                className="btn-large"
                                disabled={isSubmitting}
                                type="submit"
                                block
                            >
                                {t('ChangePassword')}
                            </Button>
                        </Form.Group>
                    </Formk>
                )}
            </Formik>
        </>
    )
}
