import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { switchGame } from '../../../network/socket'
import { GameroomModalSectionType } from '../../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../../store/gameroomModalSection/gameroomModalSection'
import { makeCreateCorePayloadString } from '../../../utils'

function useSelectGame() {
    const dispatch = useDispatch()

    const selectGame = useCallback(
        (nextGameId) => {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
            const paramString = makeCreateCorePayloadString({
                gameName: nextGameId,
            })
            switchGame(paramString)
        },
        [dispatch]
    )

    return selectGame
}

export default useSelectGame
