import React, { FC, ReactNode, useState } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface CardProps {
    children?: ReactNode
    size: 'medium'
    src: string
    alt: string
    fallbackSrc?: string
    [rootHtmlAttributes: string]: any
}

const Card: FC<CardProps> & {
    Body?: FC
    Header?: FC
} = ({
    children,
    size,
    src,
    fallbackSrc = '',
    rootHtmlAttributes,
    alt = '',
}) => {
    const [imgSrc, setImgSrc] = useState<string>(src)
    const onError = () => setImgSrc(fallbackSrc)

    return (
        <div className={cls(style.card, style[size])} {...rootHtmlAttributes}>
            <div className={style.background}></div>
            <img
                className={style.image}
                src={imgSrc ? imgSrc : fallbackSrc}
                onError={onError}
                alt={alt}
            />
            <div className={style.children}>{children}</div>
        </div>
    )
}

const Header = (props: { children?: ReactNode }) => (
    <div className={style.header}>{props.children}</div>
)
const Body = (props: { children?: ReactNode }) => (
    <div className={style.body}>{props.children}</div>
)

Card.Header = Header
Card.Body = Body

export default Card
