import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'

import { AppLayoutPropsInterface, NavbarTheme } from '../../state/app'

import AuthToolbar from '../AuthToolbar'
import InviteModal from '../InviteModal'
import { GlobalModal } from '../Modals'

import * as Axios from 'axios'
import { InviteModalContext, InviteModalContexteInterface } from './appContext'
import { piepie, isEnvStaging, checkMaintenance } from '../../utils'
import PieSearch from '../PieSearch'
import { AlertStack, BackButton, Icon } from '../../stories'
import AuthModals from '../Auth/AuthModals'
import Maintenance from '../Maintenance'
import { State } from '../../store/types'
import { LocalStorage } from '../../state/storage'
import cls from 'classnames'
import alertSlice from '../../store/alert/alert'
import { logAxiosErrorResponse } from '../../utils/http'
import { setCurrentView } from '../../store/auth/authV2'
import { AuthModalType } from '../../store/auth/types'

const AppLayout: FunctionComponent<AppLayoutPropsInterface> = ({
    children,
    theme = NavbarTheme.Transparent,
    hideAlerts = false,
    displaySearchBar = false,
    displayEventTab = true,
    hideNavbar = false,
    backButton = false,
}) => {
    const dispatch = useDispatch()
    const gameName = useSelector((state: State) => state.session.gameName)
    const user = useSelector((state: State) => state.user.user)
    const modal = useSelector((state: State) => state.modal)
    const isMaintenance = useSelector((state: State) => state.home.maintenance)
    const alertList = useSelector((state: State) => state.alert.list)
    const [inviteModalOpen, setInviteModalOpen] = useState(false)
    const [shareLink, setShareLink] = useState('')
    const { t } = useTranslation()
    const location = useLocation()

    useEffect(() => {
        checkMaintenance()
    }, [location])

    const openInviteModal = useCallback(async () => {
        try {
            await Axios.default
                .post(
                    isEnvStaging
                        ? 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC8fs58MUgPdF85hgNlgyBhBHdLRcp9DiU'
                        : 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBjmEduD8DJBcaM-qyqldfCFO62Oo1tJfE',
                    {
                        dynamicLinkInfo: {
                            domainUriPrefix: isEnvStaging
                                ? 'https://piepackerstaging.page.link'
                                : 'https://piepie.page.link',
                            link: window.location.href,
                            socialMetaTagInfo: {
                                socialTitle:
                                    user['displayName'] !== null
                                        ? t('PiepackerPlay') +
                                          ' ' +
                                          gameName +
                                          ' ' +
                                          t('With') +
                                          ' ' +
                                          user['displayName'] +
                                          ' !'
                                        : t('PiepackerPlay') +
                                          ' ' +
                                          gameName +
                                          ' !',
                                socialDescription: t('PiepackerDescription'),
                                socialImageLink:
                                    'https://fassets.piepacker.com/social/' +
                                    gameName +
                                    '.png?organizationId=942630971205',
                            },
                        },
                        suffix: {
                            option: 'SHORT',
                        },
                    }
                )
                .then(function (response) {
                    piepie.log(
                        'Dynamic link generated: ' + response.data['shortLink']
                    )
                    setShareLink(response.data['shortLink'])
                })
                .catch(function (error) {
                    logAxiosErrorResponse(error)
                })
        } finally {
            setInviteModalOpen(true)
        }
    }, [gameName, user, t])

    const value: InviteModalContexteInterface = {
        inviteModalOpen: inviteModalOpen,
        setInviteModalOpen: setInviteModalOpen,
        openInviteModal: openInviteModal,
    }

    if (
        isMaintenance &&
        localStorage.getItem(LocalStorage.maintenanceMode) !== 'false'
    ) {
        return <Maintenance />
    }

    return (
        <div className="app-layout">
            {backButton && <BackButton />}
            {!hideNavbar && (
                <div
                    className={cls(
                        'navbar-wrapper',
                        theme === NavbarTheme.None && style.hideNavBar
                    )}
                >
                    <Navbar
                        sticky="top"
                        className={cls(
                            theme === NavbarTheme.Dark
                                ? style.dark
                                : style.transparent
                        )}
                        expand={false}
                    >
                        <Navbar.Brand className="p-0">
                            <Link
                                to="/"
                                className={style.logo}
                                onClick={() => {
                                    dispatch(
                                        setCurrentView(AuthModalType.Login)
                                    )
                                }}
                            >
                                <Icon
                                    name={'jamMultiColor'}
                                    className={style.logoJam}
                                />
                            </Link>
                        </Navbar.Brand>
                        {displayEventTab && (
                            <Link
                                to="/events"
                                onClick={() => {
                                    analytics.track('EventLink', {})
                                }}
                                className="HeaderSpecialEvent"
                            >
                                <span className="HeaderEventDot"></span>
                                <span>{t('HeaderSpecialEvent')}</span>
                            </Link>
                        )}
                        {displaySearchBar && <PieSearch />}
                        <Nav style={{ flexDirection: 'row' }}>
                            <AuthToolbar />
                        </Nav>
                    </Navbar>
                </div>
            )}
            <InviteModalContext.Provider value={value}>
                <div className="children-wrapper">{children}</div>
            </InviteModalContext.Provider>

            <AuthModals />

            <GlobalModal
                isOpen={modal.isOpen}
                message={modal.message}
                onClose={modal.onClose}
            />
            <InviteModal
                onClose={() => setInviteModalOpen(false)}
                open={inviteModalOpen}
                shareLink={shareLink}
            />
            {!hideAlerts && (
                <AlertStack
                    className="app-layout__alerts"
                    alertList={alertList}
                    onAlertDelete={(id) =>
                        dispatch(alertSlice.actions.delete(id))
                    }
                />
            )}
            <div id="modal-root"></div>
        </div>
    )
}

export default AppLayout
