export const languages = [
    {
        code: 'en',
        text: 'EN',
    },
    {
        code: 'fr',
        text: 'FR',
    },
    {
        code: 'es',
        text: 'ES',
    },
    {
        code: 'de',
        text: 'DE',
    },
    {
        code: 'pt',
        text: 'PT',
    },
]

export const piepackerUrl = `https://jam.gg/pages`
