import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ReconnectingWebSocket from 'reconnecting-websocket'

export interface SocketState {
    curPacketId: string
    pingInterval: NodeJS.Timeout
    conn: ReconnectingWebSocket
    connectionReady: boolean
    retryCount: number
    number_cd: number
}

export const initialState = {
    curPacketId: '',
    pingInterval: null,
    conn: null,
    connectionReady: false,
    retryCount: 0,
    number_cd: -1,
}

const pingIntervalMs = 1000

const socketSlice = createSlice({
    name: 'Socket',
    initialState: initialState,
    reducers: {
        clear: (state) => {
            clearInterval(state.pingInterval)
            return initialState
        },
        // Following side effect recommendation from here: https://goshacmd.com/redux-side-effect-approaches/
        startPingLoop: (state, action: PayloadAction<() => void>) => {
            state.retryCount = 0
            state.pingInterval = setInterval(() => {
                action.payload()
            }, pingIntervalMs)
        },
        setNumberCd: (state, action: PayloadAction<number>) => {
            state.number_cd = action.payload
        },
        setDisconnected: (state) => {
            state.connectionReady = false
            clearInterval(state.pingInterval)
        },
        setCurPacketId: (state, action: PayloadAction<string>) => {
            state.curPacketId = action.payload
        },
        setConnectionReady: (state, action: PayloadAction<boolean>) => {
            state.connectionReady = action.payload
        },
        setConnection: (
            state,
            action: PayloadAction<ReconnectingWebSocket>
        ) => {
            state.conn = action.payload
        },
        incRetryCount: (state) => {
            state.retryCount++
        },
    },
})

export type SocketAction =
    | ReturnType<typeof socketSlice.actions.clear>
    | ReturnType<typeof socketSlice.actions.startPingLoop>
    | ReturnType<typeof socketSlice.actions.setDisconnected>
    | ReturnType<typeof socketSlice.actions.setCurPacketId>
    | ReturnType<typeof socketSlice.actions.setConnection>
    | ReturnType<typeof socketSlice.actions.setConnectionReady>
    | ReturnType<typeof socketSlice.actions.incRetryCount>

export default socketSlice
