import React from 'react'
import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const NoResult = () => {
    const { t } = useTranslation()

    return (
        <div className={style.noResult}>
            <img
                src="https://fassets.piepacker.com/random/Piepiemoji-Shocked.svg"
                alt="404"
            />
            <div className={style.noResultTitle}>{t('NoResultsFound')}</div>
            <div className={style.noResultSubTitle}>
                {t('SearchForAnotherGame')}
            </div>
        </div>
    )
}

export default NoResult
