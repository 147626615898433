import React from 'react'
import { ACESFilmicToneMapping, NoToneMapping, sRGBEncoding } from 'three'
import { Environment } from '@react-three/drei'

//environmentHDR
import HDRIDarkWarm from '../assets/HDRIs/decor_shop_1k.hdr'
import HDRILightWarm from '../assets/HDRIs/hotel_room_1k.hdr'
import HDRIDarkCold from '../assets/HDRIs/kiara_interior_1k.hdr'
import HDRILightCold from '../assets/HDRIs/abandoned_hall_01_1k.hdr'
import HDRINeutral from '../assets/HDRIs/art_studio_1k.hdr'

// SETTINGS
const REGULAR_AMBLIGHT_INTENSITY = 1
const REGULAR_DIRLIGHT_INTENSITY = 1
const REGULAR_DIRLIGHT_DIR = [458, 226, 324] // [1,0,0] -> light from left, [0,1,0] -> light from top, [0,0,1] -> light from front
//const REGULAR_DIRLIGHT_DIR = [458, 226, 324]

const TOON_AMBLIGHT_INTENSITY = 1
const TOON_DIRLIGHT_INTENSITY = 0.75
const TOON_DIRLIGHT_DIR = [458, 226, 324] // [1,0,0] -> light from left, [0,1,0] -> light from top, [0,0,1] -> light from front

const Lighting = (props) => {
    // set tonemapping:
    const threeRenderer = props.globals.threeRenderer

    if (threeRenderer) {
        if (props.maskShading.type === 'realistic') {
            threeRenderer.toneMapping = ACESFilmicToneMapping
        } else {
            threeRenderer.toneMapping = NoToneMapping
        }
        threeRenderer.outputEncoding = sRGBEncoding
        //threeRenderer.outputEncoding = THREE.LinearEncoding
    }

    // render light sources:
    switch (props.maskShading.type) {
        case 'realistic':
            return create_realisticLighting(props)
        case 'none':
            return null
        case 'regular':
            return create_regularLighting(props)
        case 'toon':
            return create_toonLighting(props)
        default:
    }
}

const create_realisticLighting = (props) => {
    const preset = props.maskShading.HDRIEnvMapPreset || 'neutral'
    const HDRIEnv = {
        neutral: HDRINeutral,
        darkWarm: HDRIDarkWarm,
        lightWarm: HDRILightWarm,
        darkCold: HDRIDarkCold,
        lightCold: HDRILightCold,
    }[preset]

    return <Environment files={HDRIEnv} />
}

const create_regularLighting = (props) => {
    return (
        <group>
            <hemisphereLight
                groundColor="#000000"
                skyColor="#8F8F8F"
                intensity={REGULAR_AMBLIGHT_INTENSITY}
            />
            <directionalLight
                intensity={REGULAR_DIRLIGHT_INTENSITY}
                position={REGULAR_DIRLIGHT_DIR}
                color="#FFFFFF"
            />
        </group>
    )
}

const create_toonLighting = (props) => {
    return (
        <group>
            <hemisphereLight
                groundColor="#000000"
                skyColor="#8F8F8F"
                intensity={TOON_AMBLIGHT_INTENSITY}
            />
            <directionalLight
                intensity={TOON_DIRLIGHT_INTENSITY}
                position={TOON_DIRLIGHT_DIR}
                color="#FFFFFF"
            />
        </group>
    )
}

export default Lighting
