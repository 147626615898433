import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './style.module.scss'
import { Icon } from '../Icon'

type theme = 'danger' | 'secondary' | 'primary' | 'transparent'

export interface TagProps {
    /** called when tag gets closed */
    onClose?: () => void
    /** called when tag gets clicked */
    onClick?: () => void
    /** className to apply to the component */
    className?: string
    /** color of the tag */
    theme?: theme
    /** size of the tag */
    size?: 'small' | 'normal'
    isSelected?: boolean
}

export const FilterTag: FC<TagProps> = ({
    className,
    children,
    onClick,
    onClose,
    isSelected,
    theme,
    size = 'normal',
}) => {
    const tagClasses = classnames(className, styles.tag, {
        [styles.danger]: theme === 'danger',
        [styles.primary]: theme === 'primary',
        [styles.secondary]: theme === 'secondary',
        [styles.transparent]: theme === 'transparent',
        [styles.normal]: size === 'normal',
        [styles.small]: size === 'small',
        [styles.selected]: isSelected,
    })

    const handleClick = () => {
        onClick()
        isSelected && onClose()
    }

    return (
        <div
            className={classnames(tagClasses, {
                [styles.clickable]: onClick,
            })}
            onClick={handleClick}
        >
            <div className={classnames(styles.content)}>{children}</div>
            {isSelected && (
                <div className={styles.close} onClick={onClose}>
                    <Icon name="close" />
                </div>
            )}
        </div>
    )
}

export default FilterTag
