// refs: https://threejs.org/examples/?q=variant#webgl_loader_gltf_variants

let _variantsByExpressionName = {}

const register_variant = (parser, node, mapping) => {
    const materialId = mapping['material']

    mapping['variants'].forEach(function (variantName) {
        // fetch variant material instance:
        parser.getDependency('material', materialId).then((material) => {
            if (typeof variantName !== 'string') {
                throw new Error(
                    'Material variant export plugin should be used with Blender 2.93 (do not work with 3.0.0)'
                )
            }

            const expressionName = variantName.replace('_instant', '')
            const isInstant = variantName.includes('_instant')
            if (!(expressionName in _variantsByExpressionName)) {
                _variantsByExpressionName[expressionName] = []
            }
            _variantsByExpressionName[expressionName].push({
                node,
                material,
                isInstant,
            })
        })
    }) // end loop on material variants
}

const init = (spec) => {
    const { threeObject3D, gltf } = spec
    _variantsByExpressionName = {}

    // look for object which have material variants in the mask:
    threeObject3D.traverse((node) => {
        if (!node.isMesh && !node.isSkinnedMesh) {
            return
        }
        if (
            !node.userData['gltfExtensions'] ||
            !node.userData['gltfExtensions']['KHR_materials_variants']
        ) {
            return
        }
        const variants =
            node.userData['gltfExtensions']['KHR_materials_variants']
        if (!variants['mappings'] && !variants['mappings'].length) {
            return
        }

        const mappings = variants['mappings']
        mappings.forEach(register_variant.bind(null, gltf.parser, node))

        // save current material:
        const originalMaterial = node.material
        node.userData.originalMaterial = originalMaterial
    })
}

const update = (expressionName, isToggled, isForce, wasLocked) => {
    // launched by AnimationFeature when an animation should be triggered
    if (!(expressionName in _variantsByExpressionName)) {
        return false
    }

    let isAnyReplaced = false
    _variantsByExpressionName[expressionName].forEach((variant) => {
        //console.log('INFO in MaterialVariantFeature: do change material. isToggled = ', isToggled)
        let isReplace = isForce || variant.isInstant
        if (wasLocked && !variant.isInstant) {
            isReplace = false
        }
        isAnyReplaced = isAnyReplaced || isReplace
        if (isReplace) {
            variant.node.material = isToggled
                ? variant.material
                : variant.node.userData.originalMaterial
        }
    })

    return isAnyReplaced
}

export default {
    init,
    update,
}
