import styles from './styles.module.scss'
import React, { useState } from 'react'
import cls from 'classnames'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import EditAvatar from '../../EditAvatar'
import { piepie } from '../../../utils'
import * as selector from '../state/selectors'
import { NicknameColorType } from '../../../state/starfox'
import { Icon } from '../../../stories'

enum Logs {
    EditSuccess = '[ACCOUNT] avatar edited successfully',
}

interface IUserAvatar {
    refreshUser: () => void
}

const UserAvatar: React.FC<IUserAvatar> = ({ refreshUser }) => {
    const [isEdit, setEdit] = useState(false)

    const userName = useSelector(selector.getUserName)
    const avatar = useSelector(selector.getUserAvatar)
    const badge = useSelector(selector.getCurrentBadge)
    const nicknameColor = useSelector(selector.getNicknameColor)

    const handleEditSuccess = () => {
        refreshUser()
        setEdit(false)
        piepie.log(Logs.EditSuccess)
    }

    const isGold = nicknameColor === NicknameColorType.Gold

    return (
        <div className={styles.avatarContainer}>
            <Modal
                onHide={() => setEdit(false)}
                show={isEdit}
                centered
                enforceFocus={false}
            >
                <EditAvatar userAvatar={avatar} onSuccess={handleEditSuccess} />
            </Modal>
            <div className={styles.avatarWrapper}>
                <Avatar src={avatar} id={styles.avatar} />
                <div className={styles.avatarOverlay}>
                    {/* TODO: replace with an Icon */}
                    <div
                        onClick={() => setEdit(true)}
                        className={styles.avatarOverlayIcon}
                    >
                        <Icon name="edit" />
                    </div>
                </div>
            </div>
            <div className="flex-start">
                <div
                    className={cls(styles.playerName, isGold && 'goldLetters')}
                >
                    {userName}
                </div>
                {badge && (
                    <div>
                        <img
                            alt="badge"
                            className="badge-img"
                            src={badge.ImageUrl}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserAvatar
