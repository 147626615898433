import React, { forwardRef, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Tooltip } from '@material-ui/core'
import { CloseSharp } from '@material-ui/icons'
import { visibleIf } from '../../utils'
import store from '../../store'
import screenSlice from '../../store/screen/screen'

export const Video = forwardRef(function Video(
    props: any,
    ref: RefObject<HTMLVideoElement>
) {
    // NOTE: New browser doesn't allow unmuted video player. So we muted here.
    // There is still audio because current audio flow is not from media but it is manually encoded (technical webRTC challenge). Later, when we can integrate audio to media, we can face the issue with mute again .
    //     https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    // playinfullscreen={props.fullScreen}
    // onLoadStart={() => this.volume = 0.5}//"this.volume=0.5"
    // const key = (ref.current?.srcObject as any)?.id || 'video'l
    return (
        <video
            id="game-screen"
            muted={props.muted}
            playsInline
            poster="./assets/piepacker.svg"
            autoPlay={props.playing}
            className={props.className}
            controls={false}
            ref={ref}
        />
    )
})

export const Quit = (props) => {
    const { t } = useTranslation()

    return (
        <Tooltip title={t('Quit')} aria-label="quit" placement="top">
            <Fab
                color="secondary"
                size="small"
                onClick={(event) => {
                    event.preventDefault()
                    props.onQuit()
                    store.dispatch(screenSlice.actions.setTheater(false))
                    store.dispatch(screenSlice.actions.setFullscreen(false))
                }}
            >
                <CloseSharp />
            </Fab>
        </Tooltip>
    )
}

export function VideoControl() {
    const { t } = useTranslation()
    return <div className="screen-control">{t('ScreenControl')}</div>
}

export function VideoLoader(props) {
    const { t } = useTranslation()
    return (
        <div
            id="loading-icon"
            className="pong-loader"
            style={visibleIf(props.loading)}
        >
            <div className="loading-text">{t('Loading')}</div>
        </div>
    )
}

export function QualityIndicator(props) {
    const { t } = useTranslation()
    return (
        <div id="quality" className={props.quality}>
            {props.latency}
            {t('ms')}
        </div>
    )
}
