import { MutableRefObject, useCallback, useEffect } from 'react'
import { SessionActiveEnum } from '../../../store/session/types'
import store from '../../../store'
import {
    SessionFromFirebase,
    UserSessionFromFirebase,
} from '../../../state/starfox'
import { GameLobbyErrorType } from '../../../state/alert'
import { piepie } from '../../../utils'
import {
    getUserFromStarfox,
    getUserSettings,
    subscribeToSession,
    subscribeToUserSession,
} from '../../../firebase'
import { setGameLobbyError, setPlayer } from '../../../store/session/actions'
import { User } from '../../../state/faces'
import VideoCamHandler from '../../../handlers/VideoCamHandler'
import { useDispatch } from 'react-redux'

export const useUserManager = (
    e: SessionActiveEnum,
    lobbyError: GameLobbyErrorType,
    sessionID: string,
    userSessionID: string,
    setLobbyLoading: (b: boolean) => void,
    setAllUsers: (e: { [index: string]: User }) => void,
    setSessionData: (u: UserSessionFromFirebase[]) => void,
    setEnterClickable: (b: boolean) => void,
    setUsers: (a: any) => void,
    allUsersRef: MutableRefObject<{ [index: string]: User }>,
    sessionDataRef: MutableRefObject<UserSessionFromFirebase[]>,
    videoCamHandler: MutableRefObject<VideoCamHandler>
) => {
    const dispatch = useDispatch()
    const clone = useCallback((obj: any) => {
        if (null === obj || 'object' != typeof obj) return obj
        const copy = obj.constructor()
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr])
        }
        return copy
    }, [])

    // this updates the all users array with the current player's details
    const updateAllUsers = useCallback(
        async (userSession: UserSessionFromFirebase) => {
            if (!userSession) {
                return
            }
            let inList = false
            for (const [, user] of Object.entries(allUsersRef.current)) {
                if (user.UserSessionID === userSession.ID) {
                    inList = true
                    break
                }
            }
            if (!inList) {
                const user = await getUserFromStarfox(userSession.UID)
                allUsersRef.current[userSession.ID] = {
                    UserSessionID: userSession.ID,
                    nickname: user?.User.DisplayName,
                    UID: userSession.UID,
                }
                getUserSettings(userSession.UID).then((user) => {
                    store.dispatch(setPlayer(userSession.UID, user))
                })
            }
            setAllUsers(clone(allUsersRef.current))
        },
        [clone, allUsersRef, setAllUsers]
    )
    // this checks room in lobby, and updates the chime users array with the current player's details
    const updateParticipants = useCallback(
        (userSession: UserSessionFromFirebase) => {
            if (!userSessionID) {
                return
            }
            const data: UserSessionFromFirebase[] = []
            let localPlayers = 0
            let inList = false
            for (const [, usession] of Object.entries(sessionDataRef.current)) {
                if (
                    usession.ID === userSession.ID &&
                    userSession.Left.length > 0 &&
                    userSession.Left[userSession.Left.length - 1] >=
                        userSession.Joined[userSession.Joined.length - 1]
                ) {
                    inList = true
                    continue
                } else if (usession.ID === userSession.ID) {
                    inList = true
                }
                data.push(
                    usession.ID === userSession.ID ? userSession : usession
                )
            }
            if (
                !inList &&
                userSession &&
                (userSession.Left.length === 0 ||
                    userSession.Left[userSession.Left.length - 1] <
                        userSession.Joined[userSession.Joined.length - 1])
            ) {
                data.push(userSession)
            }
            // counts the number of local players
            for (const [, usession] of Object.entries(data)) {
                localPlayers += usession.LocalPlayers
                    ? usession.LocalPlayers
                    : 0
            }
            const userSessionIDs = data.map((userSession) => userSession.ID)
            const lobbyIsFull =
                data.length + localPlayers >= 8 &&
                !userSessionIDs.includes(userSessionID)
            console.log(
                '[GAME] enter clickable: updateParticipants: ',
                !lobbyIsFull
            )
            sessionDataRef.current = data
            videoCamHandler.current.refreshUsers(
                sessionDataRef.current,
                setUsers
            )
            setSessionData(sessionDataRef.current)
            if (lobbyIsFull) {
                dispatch(setGameLobbyError(GameLobbyErrorType.ROOM_FULL))
            } else {
                if (lobbyError === GameLobbyErrorType.ROOM_FULL) {
                    dispatch(setGameLobbyError(undefined))
                }
            }
        },
        [
            userSessionID,
            lobbyError,
            dispatch,
            setSessionData,
            sessionDataRef,
            setUsers,
            videoCamHandler,
        ]
    )

    const reactToSession = useCallback(
        (
            e: SessionActiveEnum,
            userSessionIdsMap: Map<any, any>
        ): (() => void) => {
            if (e !== SessionActiveEnum.Yes) {
                // session is not in expected state
                return null
            }
            return subscribeToSession(sessionID).onSnapshot((doc) => {
                const session = doc.data() as SessionFromFirebase

                if (session && session.Failure) {
                    // isFailure.current = true
                    dispatch(setGameLobbyError(GameLobbyErrorType.ROOM_FAILURE))
                    // setSessionFail(true)
                    return
                }

                // we need to be sure the session is healthy before entering the room (it will cause refresh issues otherwise)
                if (!session) {
                    return
                }

                if (
                    session &&
                    session.Banned?.includes(store.getState().user.user.uid)
                ) {
                    dispatch(setGameLobbyError(GameLobbyErrorType.USER_BANNED))
                    return
                }

                setLobbyLoading(false)
                piepie.log('[GAME] createSnapshots; session=', session.ID)
                const { UserSessions = [] } = session
                for (const [, userSessionID] of Object.entries(UserSessions)) {
                    piepie.log('[GAME] snapshot; userSession=', userSessionID)

                    if (!userSessionIdsMap.has(userSessionID)) {
                        const unsubscribe = subscribeToUserSession(
                            userSessionID
                        ).onSnapshot((doc) => {
                            const userSession =
                                doc.data() as UserSessionFromFirebase
                            updateParticipants(userSession)
                            updateAllUsers(userSession)
                        })
                        userSessionIdsMap.set(userSessionID, unsubscribe)
                    }
                }
            })
        },
        [
            sessionID,
            dispatch,
            setLobbyLoading,
            updateAllUsers,
            updateParticipants,
        ]
    )
    useEffect(() => {
        const userSessionIdsMap: any = new Map()
        const unSubscribeSession = reactToSession(e, userSessionIdsMap)

        // we have to disable the unsubscribe logic because it is called everytime the useUserManager hook is called
        // this hook unmount everytime one of the parameters is updated, so this useEffect is cleaned up everytime
        // and since the useEffect is cleaned up as many times it's breaking a lot of features (the users won't be updated)
        // we need to think of another architecture
        return () => {
            if (!unSubscribeSession) {
                return
            }
            // unSubscribeSession()
            if (userSessionIdsMap.size > 0) {
                for (const [key] of userSessionIdsMap.entries()) {
                    const unsubscribe = userSessionIdsMap.get(key)
                    if (unsubscribe && typeof unsubscribe === 'function') {
                        unsubscribe()
                    }
                }
            }
        }
    }, [e, reactToSession])
}
