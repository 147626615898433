import { getUserFromStarfox } from '../../firebase/users'
import { getUserSession } from '../../firebase/userSessions'
import { byog_config } from '../../pages/BYOG/config'
import {
    SessionMoods,
    SessionMoodType,
    UserInterfaceFromFirebase,
    UserSessionFromFirebase,
} from '../../state/starfox'
import { CachedRoomType } from './types'

export const MAX_NUMER_OF_PLAYERS = 8

// check if a room is full or empty
export const isFullOrEmpty = (
    p: UserInterfaceFromFirebase[],
    maxNbPlayers = MAX_NUMER_OF_PLAYERS,
    checkFull = false
) => {
    if (!p || p.length === 0 || !p[0]) return true
    if (Object.keys(p[0]).length === 0) return true
    let nbPlayers = 0
    if (checkFull) {
        for (let i = 0; i < maxNbPlayers; i++) {
            if (Object.keys(p[i]).length !== 0) nbPlayers++
        }
    }
    return nbPlayers >= maxNbPlayers
}

// get all players from a UserSessions list
export const getPlayers = async (
    userSessions: string[],
    sessionId: string,
    maxNbPlayers: number
) => {
    // get the players from the cache
    const cachedPlayers = getFromCached(sessionId)
    if (cachedPlayers !== null) return cachedPlayers
    // if the cache returned null, we request the players in Firebase using the userSessionId
    const emptyPlayer = {} as UserInterfaceFromFirebase
    let uSessions = [] as UserSessionFromFirebase[]
    // we get the UserSessions
    for (const userSession of userSessions) {
        const uSession = await getUserSession(userSession)
        if (!uSession) continue
        if (
            uSession.ID === userSession &&
            uSession.Left.length > 0 &&
            uSession.Left[uSession.Left.length - 1] >=
                uSession.Joined[uSession.Joined.length - 1]
        ) {
            continue
        }
        uSessions.push(uSession)
    }
    let allPlayers = [] as UserInterfaceFromFirebase[]
    // we get the Users
    for (const uSession of uSessions) {
        const user = await getUserFromStarfox(uSession.UID)
        allPlayers.push(user)
        for (let i = 0; i < uSession.LocalPlayers; i++) {
            allPlayers.push(user)
        }
    }
    // if allPlayers is not full, then we add an empty player so it can be display on the public rooms card
    for (let i = allPlayers.length; i < maxNbPlayers; i++) {
        allPlayers.push(emptyPlayer)
    }
    // update the cache
    updateCachedRooms(sessionId, allPlayers)
    return allPlayers
}

// cache public rooms for the Game Modal
// it allows us to not request firebase too many times
// when users are filtering the public rooms list from the modal, it reduces a lot the search time
let cachedRooms: CachedRoomType[] = []
const validity = 1000 * 60 * 1 // 1 minute

export const getFromCached = (
    sessionId: string
): UserInterfaceFromFirebase[] => {
    for (const [, room] of Object.entries(cachedRooms)) {
        if (
            room.sessionId === sessionId &&
            Date.now() - room.lastUpdate < validity
        )
            return room.players
    }
    return null
}

export const updateCachedRooms = (
    sessionId: string,
    players: UserInterfaceFromFirebase[]
) => {
    for (let i = 0; i < cachedRooms.length; i++) {
        if (cachedRooms[i].sessionId === sessionId) {
            cachedRooms[i].players = players
            cachedRooms[i].lastUpdate = Date.now()
            return
        }
    }
    cachedRooms.push({
        sessionId: sessionId,
        players: players,
        lastUpdate: Date.now(),
    })
}

// with lastTimeFiltered we can cancel a current filtering action if another one has been triggered meanwhile
let lastTimeFiltered = 0

export const setLastTimeFiltered = (time: number) => {
    if (time < 0 || isNaN(time)) return
    lastTimeFiltered = time
}

export const getLastTimeFiltered = (): number => {
    return lastTimeFiltered
}

export const getMoodIcon = (label: '' | SessionMoodType): string => {
    for (const mood of SessionMoods) {
        if (mood.label === label) return mood.icon
    }
    return ''
}

export const coreToConsole = (core: string): string => {
    let currentConsole = ''
    for (const [code, console] of Object.entries(byog_config.cores)) {
        if (console === core) {
            currentConsole = code
            break
        }
    }
    return byog_config.session[currentConsole]
}
