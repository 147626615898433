export enum UnavailableReasonEnum {
    None,
    Waiting,
    NotSignedIn,
    InsufficientConnection,
    UnsupportedBrowser,
    UnsupportedDevice,
    UnsupportedBrowserVersion,
    MobileForbidden,
}
