import styles from './styles.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../../stories'
import { useSelector } from 'react-redux'
import * as selector from '../state/selectors'
import { piepie } from '../../../utils/piepie'
import { setKeyboard } from '../../../firebase'

enum Logs {
    KeyboardSuccess = '[ACCOUNT] keyboard setting edited successfully',
}

enum Keyboard {
    Default = 'Automatic',
    Qwerty = 'Qwerty',
    Azerty = 'Azerty',
}

interface IController {
    refreshUser: () => void
}

const Controller: React.FC<IController> = ({ refreshUser }) => {
    const { t } = useTranslation()
    const selectedKeyboard = useSelector(selector.getKeyboard)
    const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false)

    const handleSelect = async (id: Keyboard) => {
        await setKeyboard(id)
        setIsKeyboardVisible(false)
        refreshUser()
        piepie.log(Logs.KeyboardSuccess)
    }

    const keyboardData = Object.values(Keyboard)
        .filter((keyboard) => keyboard !== selectedKeyboard)
        .map((keyboard) => ({
            value: t(keyboard),
            id: keyboard,
        }))

    return (
        <div className={styles.section}>
            <p className={styles.header}>{t('Keyboard')}</p>
            <div className={styles.keyboardDropdown}>
                <label>{t('DefaultKeyboard')}</label>
                <Dropdown
                    data={keyboardData}
                    show={isKeyboardVisible}
                    onSelect={handleSelect}
                    selectedItem={t(
                        selectedKeyboard ? selectedKeyboard : Keyboard.Default
                    )}
                    onToggle={() => setIsKeyboardVisible(!isKeyboardVisible)}
                />
            </div>
        </div>
    )
}

export default Controller
