import * as types from './types'
import makeReducer from '../makeReducer'

export const initialState: types.UserState = {
    user: null,
    extra: null,
    features: {},
}

const handlers = {
    [types.UserActionType.setUser](
        state: types.UserState,
        action: types.SetUserAction
    ) {
        return {
            ...state,
            user: action.payload,
        }
    },
    [types.UserActionType.setUserExtra](
        state: types.UserState,
        action: types.SetUserExtraAction
    ) {
        return {
            ...state,
            extra: action.payload,
        }
    },
    [types.UserActionType.setCurrentBadge](
        state: types.UserState,
        action: types.SetCurrentBadgeAction
    ) {
        return {
            ...state,
            extra: {
                ...state.extra,
                CurrentBadge: action.payload,
            },
        }
    },
    [types.UserActionType.setNicknameColor](
        state: types.UserState,
        action: types.SetNicknameColorAction
    ) {
        return {
            ...state,
            extra: {
                ...state.extra,
                NicknameColor: action.payload,
            },
        }
    },
    [types.UserActionType.setFeatures](
        state: types.UserState,
        action: types.SetFeaturesAction
    ) {
        return {
            ...state,
            features: {
                ...state.features,
                ...action.payload,
            },
        }
    },
}

export default makeReducer<types.UserState, types.UserAction>(
    initialState,
    handlers
)
