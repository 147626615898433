import RecordRTC from 'recordrtc'

export default class RecordHandler {
    stream: MediaStream
    gameName: string
    recording: boolean
    paused: boolean
    recorder: RecordRTC.RecordRTCPromisesHandler

    constructor(stream: MediaStream, gameName: string) {
        this.stream = stream
        this.gameName = gameName
        this.recording = false
        this.paused = false
    }

    setStream = (stream: MediaStream) => {
        this.stream = stream
    }

    start = async (stream = null) => {
        if (this.recording) await this.stop()
        if (stream) this.stream = stream
        this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, {
            type: 'video',
            mimeType: 'video/webm',
        })
        this.recorder.startRecording()
        this.recording = true
    }

    pause = () => {
        if (this.recording && !this.paused) {
            this.recorder.pauseRecording()
            this.paused = true
        }
    }

    resume = () => {
        if (this.recording && this.paused) {
            this.recorder.resumeRecording()
            this.paused = false
        }
    }

    stop = async () => {
        await this.recorder?.stopRecording()
        this.recording = false
    }

    save = async () => {
        if (this.recording) await this.stop()
        const blob = await this.recorder.getBlob()
        RecordRTC.invokeSaveAsDialog(blob)
    }

    isRecording = (): boolean => {
        return this.recording
    }

    deinit = () => {
        this.stop()
    }
}
