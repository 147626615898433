import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UserAvatar from './Avatar'
import Appearance from './Appearance'
import PersonalInformation from './PersonalInformation'

interface IUserProfile {
    refreshUser: () => void
}

const UserProfile: React.FC<IUserProfile> = ({ refreshUser }) => {
    const { t } = useTranslation()

    return (
        <>
            <p className={styles.pageTitle}>{t('UserProfile')}</p>
            <UserAvatar refreshUser={refreshUser} />
            <PersonalInformation refreshUser={refreshUser} />
            <div className={styles.ruler}></div>
            <Appearance />
        </>
    )
}

export default UserProfile
