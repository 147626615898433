import React, { FC, useState, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { piepie, isEnvProd, EVENT_TAB } from '../../utils'
import cls from 'classnames'

import * as Axios from 'axios'
import { State } from '../../store/types'
import { useSelector } from 'react-redux'
import { logAxiosErrorResponse } from '../../utils/http'

// TODO: use DONKEY instead
const RANKING_SERVICE_URL = isEnvProd
    ? 'https://ranking.piepacker.com'
    : 'https://ranking.piepackerstaging.com'

const fakeTeam = {
    Name: '---',
    Ranking: 0,
    Score: '0',
}

const fakeLeaderBoard = [fakeTeam, fakeTeam, fakeTeam, fakeTeam, fakeTeam]

interface ESLLeaderBoardProps {
    selectedESLTab: any
    selectedDropdownItem: any
}

const ESLLeaderBoard: FC<ESLLeaderBoardProps> = ({
    selectedESLTab,
    selectedDropdownItem,
}) => {
    const { t } = useTranslation()
    const [leaderBoardMode, setLeaderBoardMode] = useState(0)
    const [rankingByPlayer, setRankingByPlayer] = useState([
        fakeLeaderBoard,
        fakeLeaderBoard,
        fakeLeaderBoard,
        fakeLeaderBoard,
    ])
    const [leaderBoardTeam, setLeaderBoardTeam] = useState(fakeLeaderBoard)
    const [myRankingByPlayer, setMyRankingByPlayer] = useState([
        [fakeTeam],
        [fakeTeam],
        [fakeTeam],
        [fakeTeam],
    ])
    const [myTeams, setMyTeams] = useState(fakeLeaderBoard)
    const currentUserID = useSelector((state: State) => state.user.user?.uid)
    const displayHowToTab = selectedESLTab === EVENT_TAB.CLICK_AND_PLAY
    const displayLeaderboardTab = selectedESLTab !== EVENT_TAB.CLICK_AND_PLAY

    const getLeaderBoard = useCallback(
        async (numberOfPlayers) => {
            piepie.log('[ESL] HomePage getLeaderBoard')

            try {
                // TODO: use DONKEY instead
                const result = await Axios.default.post(
                    `${RANKING_SERVICE_URL}/leaderboard`,
                    {
                        gameName: selectedDropdownItem.gameLink,
                        playerCount: numberOfPlayers,
                        numValues: 100,
                    }
                )

                const teams = result.data

                piepie.log(
                    '[ESL] HomePage getLeaderBoard, received teams data from the API: ',
                    teams
                )

                if (!teams || (teams && !Array.isArray(teams))) {
                    return []
                }

                return teams
            } catch (error) {
                // The current user doesn't belong to any {numberOfPlayers} member team.
                logAxiosErrorResponse(error)
                piepie.log('[ESL] HomePage getLeaderBoard, failed: ', error)
                return []
            }
        },
        [selectedDropdownItem]
    )

    const getTeam = useCallback(
        async (numberOfPlayers) => {
            piepie.log('[ESL] HomePage getTeam')

            if (!currentUserID) {
                piepie.log('[ESL] HomePage getTeam failed, no current user id')

                return []
            }

            try {
                // TODO: use DONKEY instead
                const result = await Axios.default.post(
                    `${RANKING_SERVICE_URL}/myTeams`,
                    {
                        gameName: selectedDropdownItem.gameLink,
                        playerCount: numberOfPlayers,
                        uid: currentUserID,
                    }
                )

                const teams = result.data

                piepie.log(
                    '[ESL] HomePage getTeam, received teams data from the API: ',
                    teams
                )

                if (!teams || (teams && !Array.isArray(teams))) {
                    return []
                }

                return teams
            } catch (error) {
                // The current user doesn't belong to any {numberOfPlayers} member team.
                logAxiosErrorResponse(error)
                piepie.log('[ESL] HomePage getTeam, failed: ', error)

                return []
            }
        },
        [currentUserID, selectedDropdownItem]
    )

    useEffect(() => {
        setLeaderBoardMode(selectedDropdownItem.defaultPlayerModeIndex || 0)
    }, [selectedDropdownItem])

    useEffect(() => {
        const fetchLeaderBoard = async () => {
            const teams = []

            for (
                let i = 0;
                i < selectedDropdownItem.numberOfPlayers.length;
                i++
            ) {
                let data = await getLeaderBoard(
                    selectedDropdownItem.numberOfPlayers[i]
                )
                data = data.length > 0 ? data : fakeLeaderBoard
                teams.push(data)
            }

            setRankingByPlayer(teams)
        }
        fetchLeaderBoard()
    }, [getLeaderBoard, selectedDropdownItem.numberOfPlayers])

    useEffect(() => {
        const fetchTeams = async () => {
            const teams = []

            for (
                let i = 0;
                i < selectedDropdownItem.numberOfPlayers.length;
                i++
            ) {
                let data = await getTeam(
                    selectedDropdownItem.numberOfPlayers[i]
                )
                data = data.length > 0 ? data : [fakeTeam]
                teams.push(data)
            }

            setMyRankingByPlayer(teams)
        }
        fetchTeams()
    }, [getTeam, selectedDropdownItem.numberOfPlayers])

    useEffect(() => {
        if (
            displayLeaderboardTab &&
            rankingByPlayer[leaderBoardMode]?.length > 0
        ) {
            setLeaderBoardTeam(rankingByPlayer[leaderBoardMode])
        }
    }, [leaderBoardMode, displayLeaderboardTab, rankingByPlayer])

    useEffect(() => {
        if (
            displayLeaderboardTab &&
            myRankingByPlayer[leaderBoardMode]?.length > 0
        ) {
            setMyTeams(myRankingByPlayer[leaderBoardMode])
        }
    }, [leaderBoardMode, displayLeaderboardTab, myRankingByPlayer])

    return (
        <div
            className={cls(
                'ESLLeaderboard',
                selectedDropdownItem.tabs.length === 0 && 'ESLLeaderboardFull'
            )}
            style={{
                backgroundImage: selectedDropdownItem.backgroundImg
                    ? "url('https://fassets.piepacker.com/esl-assets/" +
                      selectedDropdownItem.backgroundImg +
                      "')"
                    : 'none',
            }}
        >
            <div className="ESLLeaderboardContainer">
                {displayHowToTab && (
                    <>
                        <div className="ESLLeaderboardHowTo">
                            <div className="ESLLeaderboardHowToTitle">
                                <img
                                    className="ESLLeaderboardHowToImage"
                                    src="https://fassets.piepacker.com/esl-assets/Cup.svg"
                                    alt=""
                                    title=""
                                />
                                <span>{t('HeaderSpecialEvent')}</span>
                            </div>
                            <div className="ESLLeaderboardHowToSubTitle">
                                <Trans i18nKey="ESLLeaderboardHowToTitle">
                                    <div className="ESLLeaderboardHowToLeft">
                                        Piepacker will be present
                                    </div>
                                    <div className="ESLLeaderboardHowToRight">
                                        at the Gamescom Retro Area!
                                    </div>
                                </Trans>
                            </div>
                            <div className="ESLLeaderboardHowToText">
                                <Trans i18nKey="ESLLeaderboardHowToText">
                                    <div className="ESLLeaderboardHowToTextCenter">
                                        Piepacker launches leaderboard rush, a
                                        recurring event that allows you to
                                        compete against players from all over
                                        the world to win awesome rewards!
                                    </div>
                                </Trans>
                            </div>
                        </div>
                        <div className="ESLLeaderboardRules">
                            <div className="ESLLeaderboardRulesTitle">
                                <img
                                    src="https://fassets.piepacker.com/esl-assets/Instructions.svg"
                                    alt=""
                                    title=""
                                />
                                <span>{t('RulesAndRewards')}</span>
                            </div>
                            <div className="ESLLeaderboardRulesItem">
                                <div className="ESLLeaderboardRulesItemTitle">
                                    {t('Rules')}
                                </div>
                                <ul>
                                    <li>{t('Rules1')}</li>
                                    <li>{t('Rules2')}</li>
                                    <li>{t('Rules3')}</li>
                                    <li>{t('Rules4')}</li>
                                </ul>
                            </div>
                            <div className="ESLLeaderboardRulesItem">
                                <div className="ESLLeaderboardRulesItemTitle">
                                    {t('Rewards')}
                                </div>
                                <ul>
                                    <li>{t('Rewards1')}</li>
                                    <ul>
                                        <li>{t('Rewards2')}</li>
                                        <li>{t('Rewards3')}</li>
                                        <li>{t('Rewards4')}</li>
                                    </ul>
                                    <li>{t('Rewards5')}</li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}
                {displayLeaderboardTab && (
                    <>
                        <div className="ESLLeaderboardRanking">
                            <div className="ESLLeaderboardRankingTitle">
                                <div className="ESLLeaderboardRankingTitleContainer">
                                    <img
                                        src="https://fassets.piepacker.com/esl-assets/Cup.svg"
                                        alt=""
                                        title=""
                                    />
                                    <span className="ESLLeaderboardRankingTitleText">
                                        {t('Leaderboards')}
                                    </span>
                                </div>
                            </div>
                            <div className="ESLLeaderboardRankingMenu">
                                <div className="ESLLeaderboardRankingMenuWrapper">
                                    {selectedDropdownItem.numberOfPlayers.map(
                                        (item: number, index: number) => {
                                            return (
                                                <div
                                                    onClick={() =>
                                                        setLeaderBoardMode(
                                                            index
                                                        )
                                                    }
                                                    className="ESLLeaderboardRankingMenuItem"
                                                    key={
                                                        'leaderboardMenu-' +
                                                        index
                                                    }
                                                >
                                                    <span className="ESLLeaderboardRankingMenuItem__extended">
                                                        {item === 1
                                                            ? t('SinglePlayer')
                                                            : t('PlayerCount', {
                                                                  count: item,
                                                              })}
                                                    </span>
                                                    <span className="ESLLeaderboardRankingMenuItem__small">
                                                        {t('PlayerCountSmall', {
                                                            count: item,
                                                        })}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <div className="ESLLeaderboardRankingMenuBarContainer">
                                    <div
                                        className="ESLLeaderboardRankingMenuBarFiller"
                                        style={{
                                            width:
                                                100 /
                                                    selectedDropdownItem
                                                        .numberOfPlayers
                                                        .length +
                                                '%',
                                            marginLeft:
                                                (leaderBoardMode * 100) /
                                                    selectedDropdownItem
                                                        .numberOfPlayers
                                                        .length +
                                                '%',
                                        }}
                                    ></div>
                                </div>
                            </div>
                            <div className="ESLLeaderboardRankingTableContainer">
                                <div className="ESLLeaderboardRankingTableWrapper">
                                    <div className="ESLLeaderboardRankingTable">
                                        <div className="ESLLeaderboardRankingTableTitle">
                                            {t('TopScores')}
                                        </div>
                                        <div className="ESLLeaderboardRankingTableHeader">
                                            <span className="ESLLeaderboardRankingTableHeaderLeft">
                                                {t('Rank')}
                                            </span>
                                            <span>{t('Team')}</span>
                                            <span className="ESLLeaderboardRankingTableHeaderRight">
                                                {t('Score')}
                                            </span>
                                        </div>
                                        <div className="ESLLeaderboardRankingTableList">
                                            {leaderBoardTeam.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            className={cls(
                                                                'ESLLeaderboardRankingTableListItem',
                                                                index > 2 &&
                                                                    'ESLLeaderboardRankingTableListItem--extra'
                                                            )}
                                                            data-index={
                                                                index + 1
                                                            }
                                                            key={
                                                                'leaderboard-' +
                                                                index
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    'ESLLeaderboardRankingTableListLeft' +
                                                                    (item.Ranking <
                                                                    4
                                                                        ? ' ESLLeaderboardRankingTableListLeftPodium'
                                                                        : '')
                                                                }
                                                            >
                                                                {item.Ranking}
                                                            </span>
                                                            <span className="ESLLeaderboardRankingTableListCenter">
                                                                {item.Name}
                                                            </span>
                                                            <span className="ESLLeaderboardRankingTableListRight">
                                                                {item.Score}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="ESLLeaderboardRankingTable ESLLeaderboardRankingTable--personal">
                                        <div className="ESLLeaderboardRankingTableTitle">
                                            {t('YourScore')}
                                        </div>
                                        <div className="ESLLeaderboardRankingTableHeader">
                                            <span className="ESLLeaderboardRankingTableHeaderLeft">
                                                {t('Rank')}
                                            </span>
                                            <span>{t('Team')}</span>
                                            <span className="ESLLeaderboardRankingTableHeaderRight">
                                                {t('Score')}
                                            </span>
                                        </div>
                                        <div className="ESLLeaderboardRankingTableList">
                                            {myTeams.map((item, index) => {
                                                return (
                                                    <div
                                                        className={cls(
                                                            'ESLLeaderboardRankingTableListItem',
                                                            index > 2 &&
                                                                'ESLLeaderboardRankingTableListItem--extra'
                                                        )}
                                                        data-index={index + 1}
                                                        key={'myteam-' + index}
                                                    >
                                                        <span className="ESLLeaderboardRankingTableListLeft">
                                                            {item.Ranking}
                                                        </span>
                                                        <span className="ESLLeaderboardRankingTableListCenter">
                                                            {item.Name}
                                                        </span>
                                                        <span className="ESLLeaderboardRankingTableListRight">
                                                            {item.Score}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ESLLeaderBoard
