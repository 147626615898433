import * as Axios from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import {
    AudioVideoFacade,
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    DeviceChangeObserver,
    LogLevel,
    MeetingSession,
    MeetingSessionConfiguration,
    AudioVideoObserver,
    ClientMetricReport,
    VideoTileState,
    AsyncScheduler,
} from 'amazon-chime-sdk-js'
import { RegionType } from '../state/conference'
import store from '../store'
import {
    editParticipant,
    setSndBandwidth,
    setRcvBandwidth,
    updateAudioInputDevices,
    updateAudioOutputDevices,
    updateVideoInputDevices,
    setIsMicrophoneGranted,
    setIsCameraGranted,
    setIsMicrophoneActive,
    setIsSpeakerActive,
    setIsCameraActive,
    setCurrentAudioInputDevice,
    setCurrentAudioOutputDevice,
    setCurrentVideoInputDevice,
    setIsDeviceBindingComplete,
    setIsMicrophoneAvailable,
    setIsCameraAvailable,
    setVirualCameraStream,
    setIsSpeakerGranted,
    setIsSpeakerAvailable,
} from '../store/conference/actions'

import { defaultStarfoxURL, piepie } from '../utils'
import { authorizedConfig } from '../firebase'
import { LocalStorage } from '../state/storage'
import { logAxiosErrorResponse } from '../utils/http'

import VideoPresets from '../components/Faces/FaceMask/VideoPresets'

const BASE_URL: string = defaultStarfoxURL()

enum DeviceType {
    AudioInputDevice = 'AudioInputDevice',
    AudioOutputDevice = 'AudioOutputDevice',
    VideoInputDevice = 'VideoInputDevice',
}

export enum ChimeMessageTopics {
    MUTE = 'MUTE',
    VIDEO_FEED = 'VIDEO_FEED',
}

export default class ChimeSdkWrapper
    implements DeviceChangeObserver, AudioVideoObserver
{
    meetingSession: MeetingSession | null = null

    audioVideo: AudioVideoFacade | null = null

    title: string | null = null

    name: string | null = null

    region: string | null = null

    attendee: { ExternalUserId: string; AttendeeId: string } | null = null

    supportedChimeRegions: RegionType[] = [
        { label: 'United States (N. Virginia)', value: 'us-east-1' },
        { label: 'Japan (Tokyo)', value: 'ap-northeast-1' },
        { label: 'Singapore', value: 'ap-southeast-1' },
        { label: 'Australia (Sydney)', value: 'ap-southeast-2' },
        { label: 'Canada', value: 'ca-central-1' },
        { label: 'Germany (Frankfurt)', value: 'eu-central-1' },
        { label: 'Sweden (Stockholm)', value: 'eu-north-1' },
        { label: 'Ireland', value: 'eu-west-1' },
        { label: 'United Kingdom (London)', value: 'eu-west-2' },
        { label: 'France (Paris)', value: 'eu-west-3' },
        { label: 'Brazil (São Paulo)', value: 'sa-east-1' },
        { label: 'United States (Ohio)', value: 'us-east-2' },
        { label: 'United States (N. California)', value: 'us-west-1' },
        { label: 'United States (Oregon)', value: 'us-west-2' },
    ]

    // currentAudioInputDevice is the audio input selected once devices configured
    currentAudioInputDevice: MediaDeviceInfo | null = null

    // currentAudioOutputDevice is the audio output selected once devices configured
    currentAudioOutputDevice: MediaDeviceInfo | null = null

    // currentAudioOutputDevice is the video input selected once devices configured
    currentVideoInputDevice: MediaDeviceInfo | null = null

    // currentAudioOutputDevice is the list of audio inputs, available once the room is created or joined
    audioInputDevices: MediaDeviceInfo[] = []

    // currentAudioOutputDevice is the list of audio inputs, available once the room is created or joined
    audioOutputDevices: MediaDeviceInfo[] = []

    // currentAudioOutputDevice is the list of audio inputs, available once the room is created or joined
    videoInputDevices: MediaDeviceInfo[] = []

    configuration: MeetingSessionConfiguration | null = null

    audioElement: HTMLAudioElement | null = null

    virtualCameraStream: MediaStream | null = null

    initializeSdkWrapper = async () => {
        this.meetingSession = null
        this.audioVideo = null
        this.title = null
        this.name = null
        this.region = null
        this.currentAudioInputDevice = null
        this.currentAudioOutputDevice = null
        this.currentVideoInputDevice = null
        this.audioInputDevices = []
        this.audioOutputDevices = []
        this.videoInputDevices = []
        this.configuration = null
        this.audioElement = null
        this.virtualCameraStream = null
    }

    /*
     * ====================================================================
     * regions
     * ====================================================================
     */
    lookupClosestChimeRegion = async (): Promise<string> => {
        let region: string
        try {
            const response = await fetch(
                `https://nearest-media-region.l.chime.aws`,
                {
                    method: 'GET',
                }
            )
            const json = await response.json()
            if (json.error) {
                throw new Error(`CreateOrJoin.serverError: ${json.error}`)
            }
            region = json.region
        } catch (error) {
            this.logError(error)
        }
        return (
            this.supportedChimeRegions.find(({ value }) => value === region) ||
            this.supportedChimeRegions[0]
        ).value
    }

    // creates the room in the backend and broadcasts it for others to join
    // returns true if the creation/joining of the room was successful, false otherwise
    createRoom = async (
        sessionID: string | null,
        userSessionID: string | null,
        start = true
    ): Promise<boolean> => {
        if (!sessionID || !userSessionID) return

        const uid = firebase.auth().currentUser.uid
        const cfg = await authorizedConfig()
        try {
            const response = await Axios.default.post(
                start
                    ? `${BASE_URL}/api/chime/start`
                    : `${BASE_URL}/api/chime/join`,
                {
                    sessionID,
                    userSessionID,
                    UID: uid,
                },
                cfg
            )
            this.configuration = new MeetingSessionConfiguration(
                response.data.Meeting,
                response.data.Attendee
            )
            this.attendee = response.data.Attendee
            await this.initializeMeetingSession(this.configuration)
            return true
        } catch (err) {
            logAxiosErrorResponse(err)
            piepie.error(`CreateOrJoin.serverError: ${err}`)
            return false
        }
    }

    initializeMeetingSession = async (
        configuration: MeetingSessionConfiguration
    ): Promise<void> => {
        const logger = new ConsoleLogger('CHIME SDK:', LogLevel.ERROR)
        const deviceController = new DefaultDeviceController(logger)
        this.meetingSession = new DefaultMeetingSession(
            configuration,
            logger,
            deviceController
        )
        this.audioVideo = this.meetingSession.audioVideo
    }

    sendMessage = (topic: ChimeMessageTopics, data: any): void => {
        if (!this.audioVideo) {
            return
        }

        piepie.log('MUTE: sending message, data: ', data)
        new AsyncScheduler().start(() => {
            this.audioVideo.realtimeSendDataMessage(topic, data)
        })
    }

    addObservers = async () => {
        const observer = {
            videoTileDidUpdate: (tileState: VideoTileState): void => {
                piepie.log('CHIME: videoTileDidUpdate tileState', tileState)
                if (!tileState.boundAttendeeId || !tileState.tileId) {
                    return
                }

                try {
                    const updatedParticipant = {
                        userId: tileState.boundExternalUserId,
                        isLocal: tileState.localTile,
                        stream: tileState.boundVideoStream,
                        tileId: tileState.tileId,
                        isMuted:
                            store.getState().conference?.participants[
                                tileState?.boundExternalUserId
                            ]?.isMuted || false,
                    }
                    if (tileState.boundVideoStream) {
                        piepie.log(
                            'CHIME: videoTileDidUpdate: Updating participant, updatedParticipant: ',
                            updatedParticipant
                        )
                        store.dispatch(
                            editParticipant({
                                [tileState.boundExternalUserId]:
                                    updatedParticipant,
                            })
                        )
                    }
                } catch (error) {
                    piepie.error(
                        'CHIME: videoTileDidUpdate error:',
                        error.message
                    )
                }
            },

            videoTileWasRemoved: (tileId: number): void => {
                try {
                    // const index = releaseVideoIndex(tileId)
                    // videoElements[index].srcObject = null
                    this.audioVideo.unbindVideoElement(tileId)
                    piepie.log('CHIME: videoTileWasRemoved tileId', tileId)
                } catch (error) {
                    piepie.error(
                        'CHIME: videoTileWasRemoved error:',
                        error.message
                    )
                }
            },
        }

        this.audioVideo.addObserver(observer)
    }

    getLocalStorageDevice = (
        availableDevices: MediaDeviceInfo[],
        storageKey: DeviceType
    ): MediaDeviceInfo | null => {
        if (availableDevices.length < 1) {
            return null
        }
        let storageDevice = null
        try {
            storageDevice = JSON.parse(
                localStorage.getItem(LocalStorage[storageKey])
            )
        } catch (e) {
            return null
        }

        if (!storageDevice) {
            return null
        }

        // If not found this returns null
        const availableDevice = availableDevices.find(
            (d) => d.deviceId === storageDevice.deviceId
        )

        return availableDevice
    }

    setLocalStorageDevice = (
        device: MediaDeviceInfo,
        storageKey: DeviceType
    ): void => {
        localStorage.setItem(LocalStorage[storageKey], JSON.stringify(device))
    }

    removeLocalStorageDevice = (storageKey: DeviceType): void => {
        localStorage.removeItem(LocalStorage[storageKey])
    }

    // configureDevices configures the devices in their initial position (first elem) of list
    // of each device id
    configureDevices = async (
        element: HTMLAudioElement | null,
        restricted?: boolean
    ): Promise<void> => {
        // Preventing the execution of this function before chime init
        if (this.audioVideo === null) {
            this.logError(
                new Error(
                    'CHIME: Configure Devices -- Execution of the function before chime init'
                )
            )
            return
        }

        // Preventing the execution of this function after chime conference join
        if (this.didStart) {
            this.logError(
                new Error(
                    'CHIME: Configure Devices -- Execution of the function after joining the meeting'
                )
            )
            return
        }

        if (!element) {
            this.logError(
                new Error(
                    'CHIME: Configure Devices -- audio element does not exist'
                )
            )
            return
        }

        this.audioElement = element

        window.addEventListener(
            'unhandledrejection',
            (event: PromiseRejectionEvent) => {
                this.logError(event.reason)
            }
        )

        this.audioVideo.setDeviceLabelTrigger(
            async (): Promise<MediaStream> => {
                try {
                    const microphonePermission =
                        // TODO: wait for typescript to add (?) proper type
                        // https://github.com/microsoft/TypeScript/issues/33923
                        await navigator.permissions.query({
                            name: 'microphone' as PermissionName,
                        })
                    piepie.log(
                        'CHIME: Microphone permission state --',
                        microphonePermission.state
                    )
                } catch (e) {
                    piepie.error('CHIME: Microphone permission request denied')
                }

                try {
                    const cameraPermission = await navigator.permissions.query({
                        // TODO: wait for typescript to add (?) proper type
                        name: 'camera' as PermissionName,
                    })
                    piepie.log(
                        'CHIME: Camera permission state --',
                        cameraPermission.state
                    )
                } catch (e) {
                    piepie.error('CHIME: Camera permission request denied')
                }

                let mediaStream
                try {
                    piepie.log(
                        'WARNING in CHIME setDeviceLabelTrigger: request video access without any constraints. We should not do that since video constraints may not work for video requested after.'
                    )
                    mediaStream = await navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: true,
                    })
                    piepie.log(
                        'CHIME setDeviceLabelTrigger: getUserMedia for audio and video -- SUCCESS'
                    )
                } catch (err) {
                    piepie.error(
                        'CHIME setDeviceLabelTrigger: getUserMedia ERROR --',
                        err.name,
                        err
                    )
                }

                return mediaStream
            }
        )

        // PHASE 1: Getting the initial device list
        try {
            this.audioInputDevices =
                await this.audioVideo.listAudioInputDevices()
            store.dispatch(setIsMicrophoneGranted(true))
        } catch (err) {
            // Reference for error handling: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

            piepie.error('CHIME: Microphone granting [PHASE 1]:', err.name, err)

            switch (err.name) {
                case 'AbortError':
                case 'NotFoundError':
                case 'NotReadableError':
                case 'OverconstrainedError':
                case 'SecurityError':
                case 'TypeError':
                    store.dispatch(setIsMicrophoneGranted(true))
                    store.dispatch(setIsMicrophoneAvailable(false))
                    store.dispatch(setIsMicrophoneActive(false))
                    break
                case 'NotAllowedError':
                default:
                    store.dispatch(setIsMicrophoneGranted(false))
                    store.dispatch(setIsMicrophoneAvailable(false))
                    store.dispatch(setIsMicrophoneActive(false))
                    break
            }
        } finally {
            piepie.log(
                `CHIME: [PHASE 1] Audio Input Device List: ${JSON.stringify(
                    this.audioInputDevices
                )}`
            )
            this.audioInputDevices = this.audioInputDevices.filter(
                (audioInputDevice) => audioInputDevice.deviceId !== ''
            )
            store.dispatch(updateAudioInputDevices(this.audioInputDevices))
        }

        try {
            this.audioOutputDevices =
                await this.audioVideo.listAudioOutputDevices()
            store.dispatch(setIsSpeakerGranted(true))
        } catch (err) {
            // Reference for error handling: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

            piepie.error('CHIME: Speaker granting [PHASE 1]:', err.name, err)

            switch (err.name) {
                case 'AbortError':
                case 'NotFoundError':
                case 'NotReadableError':
                case 'OverconstrainedError':
                case 'SecurityError':
                case 'TypeError':
                    store.dispatch(setIsSpeakerGranted(true))
                    store.dispatch(setIsSpeakerAvailable(false))
                    store.dispatch(setIsSpeakerActive(false))
                    break
                case 'NotAllowedError':
                default:
                    store.dispatch(setIsSpeakerGranted(false))
                    store.dispatch(setIsSpeakerAvailable(false))
                    store.dispatch(setIsSpeakerActive(false))
                    break
            }
        } finally {
            piepie.log(
                `CHIME: [PHASE 1] Audio Output Device List: ${JSON.stringify(
                    this.audioOutputDevices
                )}`
            )
            this.audioOutputDevices = this.audioOutputDevices.filter(
                (audioOutputDevice) => audioOutputDevice.deviceId !== ''
            )
            store.dispatch(updateAudioOutputDevices(this.audioOutputDevices))
        }

        try {
            this.videoInputDevices =
                await this.audioVideo.listVideoInputDevices()
            store.dispatch(setIsCameraGranted(true))
        } catch (err) {
            piepie.error('CHIME: Camera granting [PHASE 1]:', err.name, err)

            // Reference for error handling: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
            switch (err.name) {
                case 'AbortError':
                case 'NotAllowedError':
                case 'NotReadableError':
                case 'OverconstrainedError':
                case 'SecurityError':
                case 'TypeError':
                    store.dispatch(setIsCameraGranted(true))
                    store.dispatch(setIsCameraAvailable(false))
                    store.dispatch(setIsCameraActive(false))
                    break
                case 'NotFoundError':
                default:
                    store.dispatch(setIsCameraGranted(false))
                    store.dispatch(setIsCameraAvailable(false))
                    store.dispatch(setIsCameraActive(false))
                    break
            }
        } finally {
            piepie.log(
                `CHIME: [PHASE 1] Video Input Device List: ${JSON.stringify(
                    this.videoInputDevices
                )}`
            )
            this.videoInputDevices = this.videoInputDevices.filter(
                (videoInputDevice) => videoInputDevice.deviceId !== ''
            )
            store.dispatch(updateVideoInputDevices(this.videoInputDevices))
        }

        // PHASE 2: Selecting default devices (system default)
        if (this.audioInputDevices.length > 0) {
            const storageAudioInputDevice = this.getLocalStorageDevice(
                this.audioInputDevices,
                DeviceType.AudioInputDevice
            )

            // Check if the default device is available or not
            if (storageAudioInputDevice && !restricted) {
                try {
                    await this.audioVideo.chooseAudioInputDevice(
                        storageAudioInputDevice.deviceId
                    )
                    this.currentAudioInputDevice = storageAudioInputDevice
                    store.dispatch(setIsMicrophoneAvailable(true))
                    store.dispatch(setIsMicrophoneActive(true))
                    piepie.log(
                        'CHIME: Default microphone binding [PHASE 2]: SUCCESS'
                    )
                } catch (error) {
                    piepie.error(
                        'CHIME: Default microphone binding [PHASE 2]: ERROR -',
                        error
                    )
                    this.removeLocalStorageDevice(DeviceType.AudioInputDevice)
                }
            }

            // If the default device is not available, check if other devices are accessible or not
            if (!this.currentAudioInputDevice && !restricted) {
                for (const audioInputDevice of this.audioInputDevices) {
                    try {
                        await this.audioVideo.chooseAudioInputDevice(
                            audioInputDevice.deviceId
                        )
                        this.currentAudioInputDevice = audioInputDevice
                        store.dispatch(setIsMicrophoneAvailable(true))
                        store.dispatch(setIsMicrophoneActive(true))
                        this.setLocalStorageDevice(
                            audioInputDevice,
                            DeviceType.AudioInputDevice
                        )
                        piepie.log(
                            'CHIME: Microphone binding [PHASE 2]: SUCCESS'
                        )
                        break
                    } catch (error) {
                        piepie.error(
                            'CHIME: Microphone binding [PHASE 2]: ERROR -',
                            error
                        )
                    }
                }
            }

            if (!this.currentAudioInputDevice) {
                // No device is available in the list

                // If you don't allow the permissions in chome settings, then you'll get a empty list of devices
                store.dispatch(setIsMicrophoneGranted(false))
                store.dispatch(setIsMicrophoneAvailable(false))
                store.dispatch(setIsMicrophoneActive(false))
            }

            store.dispatch(
                setCurrentAudioInputDevice(this.currentAudioInputDevice)
            )
        }

        if (this.audioOutputDevices.length > 0) {
            const storageAudioOutputDevice = this.getLocalStorageDevice(
                this.audioOutputDevices,
                DeviceType.AudioOutputDevice
            )

            // Check if the default device is available or not
            if (storageAudioOutputDevice) {
                try {
                    await this.audioVideo.chooseAudioOutputDevice(
                        storageAudioOutputDevice.deviceId
                    )
                    this.currentAudioOutputDevice = storageAudioOutputDevice
                    store.dispatch(setIsSpeakerActive(true))
                    store.dispatch(setIsSpeakerAvailable(true))
                    piepie.log(
                        'CHIME: Default speaker binding [PHASE 2]: SUCCESS'
                    )
                } catch (err) {
                    piepie.error(
                        'CHIME: Speaker binding [PHASE 2]: ERROR -',
                        err
                    )
                    this.removeLocalStorageDevice(DeviceType.AudioOutputDevice)
                }
            }

            // If the default device is not available, check if other devices are accessible or not
            if (!this.currentAudioOutputDevice) {
                for (const audioOutputDevice of this.audioOutputDevices) {
                    try {
                        await this.audioVideo.chooseAudioOutputDevice(
                            audioOutputDevice.deviceId
                        )
                        this.currentAudioOutputDevice = audioOutputDevice
                        store.dispatch(setIsSpeakerActive(true))
                        store.dispatch(setIsSpeakerAvailable(true))
                        this.setLocalStorageDevice(
                            audioOutputDevice,
                            DeviceType.AudioOutputDevice
                        )
                        piepie.log('CHIME: Speaker binding [PHASE 2]: SUCCESS')
                        break
                    } catch (err) {
                        piepie.error(
                            'CHIME: Speaker binding [PHASE 2]: ERROR -',
                            err
                        )
                    }
                }
            }

            if (!this.currentAudioOutputDevice) {
                // No device is available in the list

                // If you don't allow the permissions in chome settings, then you'll get a empty list of devices
                store.dispatch(setIsSpeakerGranted(false))

                store.dispatch(setIsSpeakerAvailable(false))
                store.dispatch(setIsSpeakerActive(false))
            }

            store.dispatch(
                setCurrentAudioOutputDevice(this.currentAudioOutputDevice)
            )
        }

        if (this.videoInputDevices.length > 0) {
            const storageVideoInputDevice = this.getLocalStorageDevice(
                this.videoInputDevices,
                DeviceType.VideoInputDevice
            )

            // wrapper around this.audioVideo.chooseVideoInputDevice
            // to specify video resolution
            // otherwise video resolution can be too huge and WEBAR with struggle to process it
            const chooseVideoInputDeviceWrapper = (deviceId) => {
                const videoSettings = VideoPresets.get('auto')
                piepie.log(
                    'CHIME: ask for a video resolution of ',
                    videoSettings.idealWidth,
                    'x',
                    videoSettings.idealHeight
                )
                this.audioVideo.chooseVideoInputQuality(
                    videoSettings.idealWidth,
                    videoSettings.idealHeight,
                    15,
                    Infinity
                )
                return this.audioVideo.chooseVideoInputDevice(deviceId)
            }

            // Check if the default device is available or not
            if (storageVideoInputDevice && !restricted) {
                try {
                    //this.audioVideo.chooseVideoInputQuality(176, 144, 15, Infinity);
                    await chooseVideoInputDeviceWrapper(
                        storageVideoInputDevice.deviceId
                    )
                    this.currentVideoInputDevice = storageVideoInputDevice
                    store.dispatch(setIsCameraAvailable(true))
                    store.dispatch(setIsCameraActive(true))
                    piepie.log(
                        'CHIME: Default camera binding [PHASE 2]: SUCCESS'
                    )
                } catch (err) {
                    piepie.error(
                        'CHIME: Default camera binding [PHASE 2]: ERROR -',
                        err
                    )
                }
            }

            // If the default device is not available, check if other devices are accessible or not
            if (!this.currentVideoInputDevice && !restricted) {
                for (const videoInputDevice of this.videoInputDevices) {
                    try {
                        await chooseVideoInputDeviceWrapper(
                            videoInputDevice.deviceId
                        )
                        this.currentVideoInputDevice = videoInputDevice
                        store.dispatch(setIsCameraAvailable(true))
                        store.dispatch(setIsCameraActive(true))
                        this.setLocalStorageDevice(
                            videoInputDevice,
                            DeviceType.VideoInputDevice
                        )
                        piepie.log('CHIME: Camera binding [PHASE 2]: SUCCESS')
                        break
                    } catch (err) {
                        piepie.error(
                            'CHIME: Camera binding [PHASE 2]: ERROR -',
                            err
                        )
                    }
                }
            }

            if (!this.currentVideoInputDevice) {
                // No device is available in the list

                // If you don't allow the permissions in chome settings, then you'll get a empty list of devices
                store.dispatch(setIsCameraGranted(false))

                store.dispatch(setIsCameraAvailable(false))
                store.dispatch(setIsCameraActive(false))
            }

            store.dispatch(
                setCurrentVideoInputDevice(this.currentVideoInputDevice)
            )
        }

        this.audioVideo.addDeviceChangeObserver(this)

        store.dispatch(setIsDeviceBindingComplete(true))
    }

    didStart = false

    // joinRoom starts the audio video channels and lets the user start talking and seeing others
    joinRoom = async () => {
        if (!this.didStart) {
            // Binding the audio only once
            if (this.audioElement) {
                await this.audioVideo?.bindAudioElement(this.audioElement)
            }
            this.audioVideo?.start()
            piepie.log('audio video started')
            this.didStart = true
        }
    }

    // leaveRoom indicates the user exits the conference. If end is true, the conference is terminated as well
    leaveRoom = async (end: boolean): Promise<void> => {
        try {
            this.audioVideo?.stop()
            this.didStart = false
        } catch (error) {
            this.logError(error)
        }

        try {
            if (end && this.title) {
                await fetch(
                    `${BASE_URL}end?title=${encodeURIComponent(this.title)}`,
                    {
                        method: 'POST',
                    }
                )
            }
        } catch (error) {
            this.logError(error)
        }

        this.initializeSdkWrapper()
    }

    /**
     * ====================================================================
     * Device
     * ====================================================================
     */

    toggleAudioInputDevice = async (
        active: boolean,
        notifyOtherParticipants = true
    ): Promise<void> => {
        try {
            await this.audioVideo?.chooseAudioInputDevice(
                active ? this.currentAudioInputDevice?.deviceId : null
            )
            store.dispatch(setIsMicrophoneActive(active))
            store.dispatch(setIsMicrophoneAvailable(true))

            // There is no need for broadcasting messages before a user joins the conference/game room,
            // as participants won't receive messages from a user who has not joined the conference room.
            // Moreover, we send a message on a room join event, anyway.
            if (!this.audioVideo?.hasStartedLocalVideoTile()) {
                return
            }

            // No need to broadcast a message if the current user is alone in the conference.
            const participants = store.getState().conference.participants
            const participantsLength = Object.values(participants).length
            if (
                participantsLength === 1 &&
                participants[this.attendee.ExternalUserId]
            ) {
                return
            }
            // When a participant receives a message to mute itself, we don't want to broadcast that event to others.
            if (notifyOtherParticipants) {
                this.sendMessage(ChimeMessageTopics.MUTE, {
                    externalUserId: this.attendee.ExternalUserId,
                    isMuted: !active,
                })
            }
        } catch (error) {
            this.logError(error)
            store.dispatch(setIsMicrophoneActive(false))
            store.dispatch(setIsMicrophoneAvailable(false))
        }
    }

    toggleAudioOutputDevice = async (active: boolean) => {
        try {
            await this.audioVideo?.chooseAudioOutputDevice(
                active ? this.currentAudioOutputDevice?.deviceId : null
            )
            store.dispatch(setIsSpeakerActive(active))
            store.dispatch(setIsSpeakerAvailable(true))
        } catch (error) {
            this.logError(error)
            store.dispatch(setIsSpeakerActive(false))
            store.dispatch(setIsSpeakerAvailable(false))
        }
    }

    toggleVideoInputDevice = async (active: boolean) => {
        try {
            const currentVideoInputDevice = this.virtualCameraStream
                ? this.virtualCameraStream
                : this.currentVideoInputDevice?.deviceId

            await this.audioVideo?.chooseVideoInputDevice(
                active ? currentVideoInputDevice : null
            )
            store.dispatch(setIsCameraActive(active))
            store.dispatch(setIsCameraAvailable(true))
        } catch (error) {
            this.logError(error)
            store.dispatch(setIsCameraActive(false))
            store.dispatch(setIsCameraAvailable(true))
            throw error
        }
    }

    chooseAudioInputDevice = async (device: MediaDeviceInfo) => {
        this.currentAudioInputDevice = device
        try {
            await this.audioVideo?.chooseAudioInputDevice(
                device ? device.deviceId : null
            )
            store.dispatch(setIsMicrophoneAvailable(true))
            store.dispatch(setIsMicrophoneActive(!!device))
        } catch (error) {
            this.logError(error)
            store.dispatch(setIsMicrophoneAvailable(false))
            store.dispatch(setIsMicrophoneActive(false))
            throw error
        } finally {
            store.dispatch(
                setCurrentAudioInputDevice(this.currentAudioInputDevice)
            )
            this.setLocalStorageDevice(
                this.currentAudioInputDevice,
                DeviceType.AudioInputDevice
            )
        }
    }

    chooseAudioOutputDevice = async (device: MediaDeviceInfo) => {
        this.currentAudioOutputDevice = device
        try {
            // Reference: https://github.com/aws/amazon-chime-sdk-js/blob/cf473e32770fd82bd1dda9a3e64081f16276cf8e/demos/browser/app/meetingV2/meetingV2.ts#L1816
            // @ts-ignore
            this.audioElement.setSinkId(device.deviceId)
            store.dispatch(setIsSpeakerActive(!!device))
            store.dispatch(setIsSpeakerAvailable(true))
        } catch (error) {
            this.logError(error)
            store.dispatch(setIsSpeakerAvailable(false))
            store.dispatch(setIsSpeakerActive(false))
        } finally {
            store.dispatch(
                setCurrentAudioOutputDevice(this.currentAudioOutputDevice)
            )
            this.setLocalStorageDevice(
                this.currentAudioOutputDevice,
                DeviceType.AudioOutputDevice
            )
        }
    }

    chooseVideoInputDevice = async (device: MediaDeviceInfo) => {
        this.currentVideoInputDevice = device
        try {
            // The virtual Camera stream (mask) shouldn't be removed if it is not null
            if (!this.virtualCameraStream) {
                await this.audioVideo?.chooseVideoInputDevice(
                    device ? device.deviceId : null
                )
            }
            store.dispatch(setIsCameraAvailable(true))
            store.dispatch(setIsCameraActive(!!device))

            piepie.log(
                'XOX device chosen',
                device,
                'active stream:',
                this.virtualCameraStream?.active
            )
        } catch (error) {
            console.error('XOX error choosing input device', error)
            store.dispatch(setIsCameraAvailable(false))
            store.dispatch(setIsCameraActive(false))
            throw error
        } finally {
            store.dispatch(
                setCurrentVideoInputDevice(this.currentVideoInputDevice)
            )
            this.setLocalStorageDevice(
                this.currentVideoInputDevice,
                DeviceType.VideoInputDevice
            )
        }
    }

    chooseVirtualCameraInputDevice = async (stream: MediaStream) => {
        this.virtualCameraStream = stream
        try {
            await this.audioVideo?.chooseVideoInputDevice(
                stream ? stream : null
            )
            store.dispatch(setIsCameraActive(!!stream))
            piepie.log('XOX new virtual camera stream', stream)
        } catch (error) {
            console.error(
                'XOX error selecting the virtual camera stream',
                error
            )
            store.dispatch(setIsCameraActive(false))
        } finally {
            store.dispatch(setVirualCameraStream(this.virtualCameraStream))
        }
    }

    /**
     * ====================================================================
     * Observer methods
     * ====================================================================
     */

    audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): void {
        this.audioInputDevices = freshAudioInputDeviceList
        store.dispatch(updateAudioInputDevices(this.audioInputDevices))

        if (this.currentAudioInputDevice) {
            const isAvailable = this.audioInputDevices.some(
                (audioInputDevice) =>
                    audioInputDevice.deviceId ===
                    this.currentAudioInputDevice.deviceId
            )

            if (!isAvailable) {
                const newDevice = freshAudioInputDeviceList
                    ? freshAudioInputDeviceList[0]
                    : null
                this.chooseAudioInputDevice(newDevice)
                this.setLocalStorageDevice(
                    newDevice,
                    DeviceType.AudioInputDevice
                )
            }
        }
    }

    audioOutputsChanged(freshAudioOutputDeviceList: MediaDeviceInfo[]): void {
        this.audioOutputDevices = freshAudioOutputDeviceList
        store.dispatch(updateAudioInputDevices(this.audioInputDevices))

        if (this.currentAudioOutputDevice) {
            const isAvailable = this.audioOutputDevices.some(
                (audioOutputDevice) =>
                    audioOutputDevice.deviceId ===
                    this.currentAudioOutputDevice.deviceId
            )

            if (!isAvailable) {
                const newDevice = freshAudioOutputDeviceList
                    ? freshAudioOutputDeviceList[0]
                    : null
                this.chooseAudioOutputDevice(newDevice)
                this.setLocalStorageDevice(
                    newDevice,
                    DeviceType.AudioOutputDevice
                )
            }
        }
    }

    videoInputsChanged(freshVideoInputDeviceList: MediaDeviceInfo[]): void {
        this.videoInputDevices = freshVideoInputDeviceList
        store.dispatch(updateAudioInputDevices(this.audioInputDevices))

        if (this.currentVideoInputDevice) {
            const isAvailable = this.videoInputDevices.some(
                (videoInputDevice) =>
                    videoInputDevice.deviceId ===
                    this.currentVideoInputDevice.deviceId
            )

            if (!isAvailable) {
                const newDevice = freshVideoInputDeviceList
                    ? freshVideoInputDeviceList[0]
                    : null
                this.chooseVideoInputDevice(newDevice)
                this.setLocalStorageDevice(
                    newDevice,
                    DeviceType.VideoInputDevice
                )
            }
        }
    }

    /**
     * ====================================================================
     * Utilities
     * ====================================================================
     */
    private logError = (error: Error) => {
        // eslint-disable-next-line
        piepie.error(error)
    }

    metricsDidReceive?(clientMetricReport: ClientMetricReport): void {
        const metricReport = clientMetricReport.getObservableMetrics()
        const availableSendBandwidth =
            metricReport.availableSendBandwidth / 1000 // in kbps
        const availableReceiveBandwidth =
            metricReport.availableReceiveBandwidth / 1000 // in kbps

        store.dispatch(setSndBandwidth(availableSendBandwidth))
        store.dispatch(setRcvBandwidth(availableReceiveBandwidth))
    }
}
