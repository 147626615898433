import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
    FC,
} from 'react'
import style from './style.module.scss'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useHistory } from 'react-router-dom'
import Faces from '../Faces'
import { User } from '../../state/faces'
import Screen from '../Screen'
import { piepie } from '../../utils'
import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import { getMasks } from '../../firebase/masks'
import store from '../../store'
import { GameEndModal, useGameAchievements } from '../ESLModals'
import GameLobbyLoading from '../GameLobby/GameLobbyLoading'
import { isMobile } from 'react-device-detect'
import { GAME_STATE } from '../../store/session/types'
import { ScreenType } from '../Screen/screen-props'
import MobileGameRoom from '../MobileGameRoom/MobileGameRoom'
import { connectionInitialized } from '../../store/socket/utils'
import screenSlice from '../../store/screen/screen'
import ConfirmModals from './ConfirmModals'
import GameRoomControls from './GameRoomControls'
import { useLayout, useVolume } from '../../utils/GameRoomHooks'
export interface GameRoomProps {
    users: any
    allUsers: { [index: string]: User }
    setSettingsOpen: Dispatch<SetStateAction<boolean>>
}

const GameRoom: FC<GameRoomProps> = ({ users, allUsers, setSettingsOpen }) => {
    // Game Room
    const history = useHistory()
    const theaterMode = useFullScreenHandle()
    const fullscreenMode = useFullScreenHandle()
    const game = useSelector((state: State) => state.session.game)
    const gameRatio = game.id === 'PICO-8' ? 1 : 4 / 3 // TODO: Specify aspect ratio from DB here
    const gameState = useSelector((state: State) => state.session.gameState)
    const screenType = useSelector((state: State) => state.screen.screenType)

    const gameLoading =
        gameState === GAME_STATE.LOADING ||
        (screenType === ScreenType.CLOUD && !connectionInitialized())

    const gameRoom = useRef(null)
    const gameScreen = useRef(null)
    const rightCol = useRef(null)
    const modalContainer = useRef()

    // const [showGameStartModal, setShowGameStartModal] = useState(true)
    const [showGameEndModal, setShowGameEndModal] = useState(false)
    const [newHighScore, setNewHighScore] = useState(false)
    const theater = useSelector(
        (state: State) => state.screen.theater
    ) as boolean
    const fullscreen = useSelector(
        (state: State) => state.screen.fullscreen
    ) as boolean

    // We need to access these properties singurarly otherwise the dependencies in the useEffect are not computed properly.
    // This means that the useEffect is executed at every re-render.
    // NEVER create variables inside the useSelector and then return them... NEVER!
    const KickstarterBacker = useSelector(
        (state: State) => state.user.extra?.KickstarterBacker
    )
    const subscribedToNews = useSelector(
        (state: State) => state.user.extra?.SubscribedToNews
    )
    const maskList = useSelector((state: State) => state.user.extra?.Masks)
    const currentUserID = useSelector((state: State) => state.user.user?.uid)
    const UserRole = useSelector((state: State) => state.user.extra?.Role)

    const leavingGame = useCallback(() => {
        piepie.log('[GAME ROOM] leaving game')
        history.push('/')
    }, [history])

    const [gameScreenWidth, setGameScreenWidth] = useState(0)
    const [gameScreenHeight, setGameScreenHeight] = useState(0)
    const [camScreenWidth, setCamScreenWidth] = useState(0)

    const { layout } = useLayout(
        gameRatio,
        setGameScreenWidth,
        setGameScreenHeight,
        setCamScreenWidth
    )

    const { volume, updateVolume } = useVolume()

    const { team, resetAchievements } = useGameAchievements({
        currentUserID,
        setShowGameEndModal,
        setNewHighScore,
    })

    useEffect(() => {
        if (isMobile) {
            return
        }
        if (gameLoading) {
            return
        }
        if (theaterMode.active && !theater) {
            theaterMode.exit()
        }
    }, [theater, theaterMode, gameLoading])

    useEffect(() => {
        if (isMobile) {
            return
        }
        if (gameLoading) {
            return
        }
        if (fullscreenMode.active && !fullscreen) {
            fullscreenMode.exit()
        }
    }, [fullscreen, fullscreenMode, gameLoading])

    useEffect(() => {
        getMasks()
        // keep the dependencies
        // eslint-disable-next-line
    }, [KickstarterBacker, subscribedToNews, UserRole, maskList])

    useEffect(() => {
        window.addEventListener('resize', layout)
        if (isMobile) {
            return
        }
        window.addEventListener('resize', layout) // TODO: Add debounce here
        layout()
        return () => {
            window.removeEventListener('resize', layout)
        }
    }, [layout])

    const theaterReportChange = useCallback(
        (state, h) => {
            console.log('theaterReportChange')
            if (h === theaterMode) {
                store.dispatch(screenSlice.actions.setTheater(state))
                layout()
            }
        },
        [theaterMode, layout]
    )

    const fullScreenReportChange = useCallback(
        (state, h) => {
            console.log('fullScreenReportChange')
            if (h === fullscreenMode) {
                store.dispatch(screenSlice.actions.setFullscreen(state))
                layout()
            }
        },
        [fullscreenMode, layout]
    )

    const handleSetTheater = useCallback(() => {
        if (theater) {
            theaterMode.exit()
            store.dispatch(screenSlice.actions.setTheater(false))
        } else {
            theaterMode.enter()
            store.dispatch(screenSlice.actions.setTheater(true))
        }
    }, [theater, theaterMode])

    const handleSetFullscreen = useCallback(() => {
        if (fullscreen) {
            fullscreenMode.exit()
            store.dispatch(screenSlice.actions.setFullscreen(false))
        } else {
            fullscreenMode.enter()
            store.dispatch(screenSlice.actions.setFullscreen(true))
        }
    }, [fullscreen, fullscreenMode])

    const onSettingsOpen = useCallback(() => {
        piepie.log('[GAME ROOM] open settings')
        setSettingsOpen(true)
        // we exit fullscreen if we open the settings modal
        store.dispatch(screenSlice.actions.setTheater(false))
        store.dispatch(screenSlice.actions.setFullscreen(false))
    }, [setSettingsOpen])

    return (
        <>
            {/* DESKTOP */}
            {!isMobile && (
                <FullScreen handle={theaterMode} onChange={theaterReportChange}>
                    <div className={style.gameroom} ref={gameRoom}>
                        {game.Competition && (
                            <div ref={modalContainer}>
                                {/* <GameStartModal
                                container={modalContainer.current}
                                show={showGameStartModal}
                                onHide={() => {
                                    setShowGameStartModal(false)
                                }}
                                gameId={game.id}
                            /> */}
                                <GameEndModal
                                    container={modalContainer.current}
                                    show={showGameEndModal}
                                    onHide={() => {
                                        setShowGameEndModal(false)
                                        // Reset achievements only when the modal finishes fade out to avoid displaying changes in the modal during the fade out.
                                        setTimeout(() => {
                                            resetAchievements()
                                        }, 300)
                                    }}
                                    team={team}
                                    newHighScore={newHighScore}
                                />
                            </div>
                        )}
                        <div className={style.gameWrapper}>
                            <GameRoomControls
                                handleSetTheater={handleSetTheater}
                                isTheater={theater}
                                volume={volume}
                                updateVolume={updateVolume}
                            />
                            <FullScreen
                                handle={fullscreenMode}
                                onChange={fullScreenReportChange}
                            >
                                <div
                                    className={style.game}
                                    id="game"
                                    ref={gameScreen}
                                    style={{
                                        width: gameScreenWidth,
                                        height: gameScreenHeight,
                                    }}
                                >
                                    {gameLoading ? (
                                        <GameLobbyLoading
                                            ESLMode={game.Competition}
                                            gameId={game.id}
                                        />
                                    ) : (
                                        <>
                                            <Screen
                                                gameScreenWidth={
                                                    gameScreenWidth
                                                }
                                                leavingGame={leavingGame}
                                                setFullscreen={
                                                    handleSetFullscreen
                                                }
                                                volume={volume}
                                                updateVolume={updateVolume}
                                            />
                                        </>
                                    )}
                                </div>
                            </FullScreen>
                        </div>

                        <div
                            className={style.camWrapper}
                            ref={rightCol}
                            style={{
                                width: camScreenWidth,
                            }}
                        >
                            <Faces
                                width={camScreenWidth}
                                users={users}
                                allUsers={allUsers}
                                onSettingsDialogOpen={onSettingsOpen}
                            />
                        </div>
                    </div>
                </FullScreen>
            )}

            {/* MOBILE */}

            {isMobile && (
                <>
                    <MobileGameRoom
                        users={users}
                        allUsers={allUsers}
                        onSettingsOpen={onSettingsOpen}
                        handleSetFullscreen={handleSetFullscreen}
                    />
                </>
            )}

            <ConfirmModals />
        </>
    )
}

export default GameRoom
