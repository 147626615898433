import React, { FC, useState } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button, Form, Alert } from 'react-bootstrap'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'
import * as Axios from 'axios'
import { InputText } from '../Form/fields'
import {
    getTransactionEmailServiceHostname,
    AxiosErrorMessage,
} from '../../utils'
import style from './style.module.scss'
import inlineStyle from './inlineStyles'

import { useDispatch } from 'react-redux'
import { openAuthModal } from '../../store/auth/actions'
import analytics from '../../analytics'
import { AuthModalType } from '../../store/auth/types'
import { logAxiosErrorResponse } from '../../utils/http'

const ResetPassword: FC = () => {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <>
            <div className={style.top}>
                <h1 className={style.heading}>{t('ResetPasswordTitle')}</h1>
            </div>

            <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('InvalidEmail'))
                        .required(t('Required')),
                })}
                onSubmit={async ({ email }, { setStatus }) => {
                    analytics.track('ResetPassword', {
                        email: email,
                    })
                    try {
                        await Axios.default.post(
                            `${getTransactionEmailServiceHostname()}/resetPassword`,
                            { email: email }
                        )
                        setStatus({ success: true })
                    } catch (err) {
                        logAxiosErrorResponse(err)
                        setSubmitError({
                            error: true,
                            message: AxiosErrorMessage(err),
                        })
                    }
                }}
            >
                {({ isSubmitting, status }) => (
                    <Formk className={style.form}>
                        {submitError.error && (
                            <Alert variant="danger">
                                {submitError.message}
                            </Alert>
                        )}

                        {status && status.success && (
                            <Alert variant="success">
                                {t('ResetPasswordEmailSent')}{' '}
                                <div
                                    className="link"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() =>
                                        dispatch(
                                            openAuthModal({
                                                modalType: AuthModalType.Login,
                                            })
                                        )
                                    }
                                >
                                    {t('SignIn')}
                                </div>
                            </Alert>
                        )}

                        <Form.Group>
                            <Field
                                label={t('EmailAddress')}
                                placeholder={t('EmailAddress')}
                                name="email"
                                id="email"
                                component={InputText}
                                style={inlineStyle.input}
                            />
                        </Form.Group>

                        <Button
                            className="btn-large"
                            disabled={isSubmitting}
                            style={inlineStyle.button}
                            type="submit"
                            block
                        >
                            {t('RequestReset')}
                        </Button>

                        <div
                            className={cls(
                                style.smallText,
                                style.alreadyAccount
                            )}
                        >
                            <Button
                                variant="link"
                                onClick={() =>
                                    dispatch(
                                        openAuthModal({
                                            modalType: AuthModalType.Login,
                                        })
                                    )
                                }
                                className={style.buttonLink}
                            >
                                {t('GoBack')}
                            </Button>
                        </div>
                    </Formk>
                )}
            </Formik>
        </>
    )
}

export default ResetPassword
