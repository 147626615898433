import styles from './styles.module.scss'
import React from 'react'
import { roomLanguages, languagesData } from './constants'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { Paper, TextField } from '@material-ui/core'
import { Icon } from '../../stories'
import { useTranslation } from 'react-i18next'

interface ILangSelect {
    language: string
    handleChange: (fieldName, value) => void
    disabled?: boolean
}
type TLanguageSelect = ILangSelect & Partial<AutocompleteRenderInputParams>
export const LanguageSelect: React.FC<TLanguageSelect> = ({
    handleChange,
    language,
    disabled,
    ...rest
}) => {
    const { t } = useTranslation()
    return (
        <Autocomplete
            {...rest}
            disableClearable
            disabled={disabled}
            disablePortal
            options={languagesData()}
            value={roomLanguages[language]}
            getOptionLabel={(option) => {
                if (option.id) return t(`Lang${option.id.toUpperCase()}`)
                for (const [id, value] of Object.entries(roomLanguages)) {
                    if (value === option) return t(`Lang${id.toUpperCase()}`)
                }
                return t(`LangEN`)
            }}
            getOptionSelected={(option, value) => option.value === value}
            className={styles.autoCompleteInput}
            onChange={(_, { id }) => handleChange('Language', id)}
            PaperComponent={({ children }) => (
                <Paper className={styles.autoCompleteDropdown}>
                    {children}
                </Paper>
            )}
            forcePopupIcon={false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        classes: {
                            disabled: styles.autoCompleteInputDisabled,
                        },
                        endAdornment: (
                            <Icon
                                name="arrowDown"
                                style={{ position: 'absolute', right: '10px' }}
                            />
                        ),
                    }}
                />
            )}
        />
    )
}
