import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { Formik, Field, Form as Formk } from 'formik'
import * as Axios from 'axios'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { InputText } from '../components/Form/fields'

import {
    getTransactionEmailServiceHostname,
    AxiosErrorMessage,
    piepie,
    useQuery,
} from '../utils'
import { logAxiosErrorResponse } from '../utils/http'

export default function ResetPasswordPage() {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })
    const [emailLink, setEmailLink] = useState('')
    const [OOBToken, setOOBToken] = useState('')
    const urlParams = useQuery()
    const { t } = useTranslation()

    useEffect(() => {
        if (urlParams.has('emailLink') && urlParams.has('OOBtoken')) {
            setEmailLink(urlParams.get('emailLink'))
            setOOBToken(urlParams.get('OOBtoken'))
        }
    }, [urlParams])
    return (
        <div className="container single-form-container">
            <Modal.Title className="single-form-title">
                {t('ResetPasswordTitle')}
            </Modal.Title>
            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}
            <Formik
                initialValues={{ password: '', confirm: '' }}
                validationSchema={Yup.object({
                    password: Yup.string()
                        .required(t('NoPasswordProvided'))
                        .min(8, t('PasswordTooShort')),
                    confirm: Yup.string().required('Required'),
                })}
                onSubmit={async ({ password, confirm }, { setStatus }) => {
                    try {
                        if (password !== confirm) {
                            throw new Error(t('PasswordsDontMatch'))
                        }
                        const payload = {
                            emailHash: emailLink,
                            password: password,
                            OOBToken: OOBToken,
                        }
                        piepie.log(payload)
                        await Axios.default.post(
                            `${getTransactionEmailServiceHostname()}/verifyReset`,
                            payload
                        )
                        setStatus({ success: true })
                    } catch (err) {
                        logAxiosErrorResponse(err)
                        setSubmitError({
                            error: true,
                            message: t(AxiosErrorMessage(err)),
                        })
                    }
                }}
            >
                {({ isSubmitting, status }) => (
                    <Formk>
                        {status && status.success && (
                            <Alert variant="success">
                                {t('PasswordResetGoBack')}{' '}
                                <strong>
                                    <Link to="/">{t('HomePage')}</Link>
                                </strong>
                            </Alert>
                        )}

                        <Form.Group>
                            <Field
                                label={t('NewPassword')}
                                placeholder={t('NewPassword')}
                                name="password"
                                type="password"
                                id="password"
                                component={InputText}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Field
                                label={t('ConfirmPasswordColon')}
                                placeholder={t('ConfirmPasswordColon')}
                                name="confirm"
                                type="password"
                                id="confirm"
                                component={InputText}
                            />
                        </Form.Group>

                        <Button
                            className="btn-large"
                            disabled={isSubmitting}
                            type="submit"
                            block
                        >
                            {t('ResetPassword')}
                        </Button>
                    </Formk>
                )}
            </Formik>
        </div>
    )
}
