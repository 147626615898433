import { KEY } from './keys'

export const genesisPad3Mapping = new Map<number, number>()
genesisPad3Mapping.set(KEY.Y, KEY.B)
genesisPad3Mapping.set(KEY.B, KEY.A)
genesisPad3Mapping.set(KEY.A, KEY.R1)
genesisPad3Mapping.set(KEY.R1, KEY.Y)

export const microMagesMapping = new Map<number, number>()
microMagesMapping.set(KEY.B, KEY.A)
microMagesMapping.set(KEY.A, KEY.X)
microMagesMapping.set(KEY.X, KEY.Y)
microMagesMapping.set(KEY.Y, KEY.B)

export const fleaMapping = new Map<number, number>()
fleaMapping.set(KEY.B, KEY.A)
fleaMapping.set(KEY.A, KEY.X)
fleaMapping.set(KEY.X, KEY.Y)
fleaMapping.set(KEY.Y, KEY.B)

export const superBatPuncherMapping = new Map<number, number>()
superBatPuncherMapping.set(KEY.B, KEY.A)
superBatPuncherMapping.set(KEY.A, KEY.X)
superBatPuncherMapping.set(KEY.X, KEY.Y)
superBatPuncherMapping.set(KEY.Y, KEY.B)

export const boblMapping = new Map<number, number>()
boblMapping.set(KEY.B, KEY.A)
boblMapping.set(KEY.A, KEY.X)
boblMapping.set(KEY.X, KEY.Y)
boblMapping.set(KEY.Y, KEY.B)

export const psychoPinballMapping = new Map<number, number>()
psychoPinballMapping.set(KEY.L1, KEY.LEFT)
psychoPinballMapping.set(KEY.A, KEY.R1)

export const missileMapping = new Map<number, number>()
missileMapping.set(KEY.X, KEY.Y)
missileMapping.set(KEY.Y, KEY.A)
missileMapping.set(KEY.A, KEY.X)

export const asteroidsMapping = new Map<number, number>()
asteroidsMapping.set(KEY.A, KEY.X)
asteroidsMapping.set(KEY.B, KEY.A)
asteroidsMapping.set(KEY.Y, KEY.B)

export const superBreakoutMapping = new Map<number, number>()
superBreakoutMapping.set(KEY.B, KEY.A)
