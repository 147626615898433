import React, { FC, useEffect, useRef } from 'react'
import style from './style.module.scss'
import cls from 'classnames'
import { Icon } from '../Icon'

interface InputProps {
    variant?: 'mobile' | 'dekstop'
    withEmoji?: boolean
    placeholderMsg?: string
    onChange: (e) => void
    value: string
    [rootHtmlAttributes: string]: any
}

const Input: FC<InputProps> = ({
    variant = 'mobile',
    withEmoji = false,
    placeholderMsg = '',
    onChange,
    value = '',
    ...rootHtmlAttributes
}) => {
    const textareaRef = useRef(null)
    useEffect(() => {
        let textareaElRef = null

        const textareaEl = textareaRef?.current

        const detectSpaceBar = (e) => {
            if (e.keyCode === 32) {
                e.stopPropagation()
            }
        }

        if (textareaEl) {
            textareaEl.oninput = function () {
                textareaEl.style.height = ''
                textareaEl.style.height =
                    Math.min(textareaEl.scrollHeight, 80) + 'px'
            }

            textareaEl.addEventListener('keydown', detectSpaceBar)
            textareaElRef = textareaEl
        }

        return () => {
            textareaElRef.removeEventListener('keydown', detectSpaceBar)
        }
    }, [])

    return (
        <div
            className={cls(style.chatInputContainer, style[variant])}
            {...rootHtmlAttributes}
        >
            <textarea
                id="chat-input"
                className={cls(
                    style.chatInput,
                    style[variant],
                    withEmoji && style.withEmoji
                )}
                rows={1}
                onChange={onChange}
                placeholder={placeholderMsg}
                value={value}
                ref={textareaRef}
            />

            {withEmoji && <Icon name="emojis" width="30px" height="30px" />}
        </div>
    )
}

export default Input
