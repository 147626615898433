import React, { forwardRef } from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { Icon } from '../../atoms/Icon'
import { Input } from '../..'

export interface SearchInputProps {
    /** current value of the input */
    value: string
    /** called when input value changes */
    onChange: (newValue: string) => void
    /** placeholder to be displayed when input is empty */
    placeholder?: string
    /** className to be applied to the component */
    className?: string
    /** type of Input component */
    type?: 'text' | 'number'
    /** handle on input click */
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
    /** clears input value*/
    onClose?: () => void

    isSearchExpanded: boolean
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
    (
        { value, className, onClick, onChange, onClose, isSearchExpanded },
        ref
    ) => {
        const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target && onChange) {
                onChange(event.target.value)
            }
        }

        const collapseStyle = { width: '44px', borderRadius: '50%' }
        const expandStyle = { width: '100%', borderRadius: '6px' }

        return (
            <div className={classnames(styles.wrapper, className)}>
                <Input
                    ref={ref}
                    onChange={handleOnChange}
                    value={value}
                    type={'text'}
                    onClick={onClick}
                    className={styles.input}
                    id="searchInput"
                    style={isSearchExpanded ? expandStyle : collapseStyle}
                />
                {isSearchExpanded && onClose ? (
                    <Icon
                        onClick={onClose}
                        className={classnames(styles.icon, styles.iconClose)}
                        name="close"
                    />
                ) : (
                    <label htmlFor="searchInput">
                        <Icon
                            className={classnames(
                                styles.icon,
                                styles.iconSearch
                            )}
                            name="search"
                        />
                    </label>
                )}
            </div>
        )
    }
)

export default SearchInput
