import { db } from './'
import {
    UserExtraFromFirebase,
    UserInterfaceFromFirebase,
    BadgeInterface,
    NicknameColorType,
    PreferredGameType,
} from '../state/starfox'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { NewKey, OBJTYPE, defaultStarfoxURL } from '../utils'

import * as Axios from 'axios'
import store, { actions } from '../store'
import { LocalStorage } from '../state/storage'
import { logAxiosErrorResponse } from '../utils/http'

export async function writeUserToFirestore(user: firebase.User) {
    const piepackerUser = {
        User: {
            DisplayName: user.displayName,
            Email: user.email,
            EmailVerified: user.emailVerified,
            Metadata: {
                CreationTime: user.metadata.creationTime,
                LastSignInTime: user.metadata.lastSignInTime,
            },
            ProviderID: 'firebase',
            UID: user.uid,
            PhotoURL: user.photoURL,
        },
        ID: NewKey(OBJTYPE.USR),
    }
    const obj = JSON.parse(
        JSON.stringify(piepackerUser, function (k, v) {
            if (v === undefined) {
                return null
            }
            return v
        })
    )
    await db.collection('users').doc(user.uid).set(obj, { merge: true })
}

export async function getUserFromStarfox(
    uid: string
): Promise<UserInterfaceFromFirebase> {
    let idToken = localStorage.getItem(LocalStorage.idToken)
    if (!idToken) idToken = await firebase.auth().currentUser.getIdToken()
    const cfg: Axios.AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${idToken}` },
    }

    try {
        const result = await Axios.default.get(
            `${defaultStarfoxURL()}/api/firebaseUser/${uid}`,
            cfg
        )
        return {
            ID: uid,
            User: {
                ...result.data,
            },
        }
    } catch (err) {
        logAxiosErrorResponse(err)
    }
}

export async function getUserExtra(
    UID?: string
): Promise<UserExtraFromFirebase> {
    const uid = UID || firebase.auth().currentUser.uid
    const resp = await db.collection('users_extra').doc(uid).get()
    const respUserSettings = await db
        .collection('users_settings')
        .doc(uid)
        .get()

    const respData = resp.data() || {}
    const respDataUserSettingsData = respUserSettings.data() || {}

    let CurrentBadge: BadgeInterface = undefined
    const BadgeList: BadgeInterface[] = []
    if (respData.BadgeList && respData.BadgeList.length > 0) {
        const BadgeListSnapshot = await db
            .collection('badges')
            .where('ID', 'in', respData.BadgeList)
            .get()

        BadgeListSnapshot.forEach((doc) => {
            const docData = doc.data() as BadgeInterface
            if (docData.ID === respDataUserSettingsData.CurrentBadge) {
                CurrentBadge = docData
            }
            BadgeList.push(docData)
        })
    }

    return {
        ...respData,
        ...respDataUserSettingsData,
        CurrentBadge,
        BadgeList,
        ColorList: respData.ColorList || [],
    } as UserExtraFromFirebase
}

export async function getUserSettings(
    UID?: string
): Promise<UserExtraFromFirebase> {
    const uid = UID || firebase.auth().currentUser.uid
    const resp = await db.collection('users_settings').doc(uid).get()

    const respData = resp.data()

    let CurrentBadge: BadgeInterface = undefined
    if (respData?.CurrentBadge) {
        const BadgeSnapshot = await db
            .collection('badges')
            .where('ID', '==', respData.CurrentBadge)
            .get()

        BadgeSnapshot.forEach((doc) => {
            const docData = doc.data() as BadgeInterface
            CurrentBadge = docData
        })
    }

    return {
        ...respData,
        CurrentBadge,
    } as UserExtraFromFirebase
}

export function setCurrentBadge(badge?: BadgeInterface): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                CurrentBadge: badge?.ID || '',
            },
            {
                merge: true,
            }
        )
        .then(() => {
            store.dispatch(actions.user.setCurrentBadge(badge))
        })
}

export function setNicknameColor(color: NicknameColorType): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                NicknameColor: color || '',
            },
            {
                merge: true,
            }
        )
        .then(() => {
            store.dispatch(actions.user.setNicknameColor(color))
        })
}

export function setPreferredGames(games: PreferredGameType[]): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                PreferredGames: games || null,
            },
            {
                merge: true,
            }
        )
}

export function setBirthday(dob: Date): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                Birthday: dob || null,
            },
            {
                merge: true,
            }
        )
}

export function setKeyboard(keyboard: string): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                Keyboard: keyboard || null,
            },
            {
                merge: true,
            }
        )
}

export function setSubscribedToNews(subscribed: boolean): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                SubscribedToNews: subscribed || false,
            },
            {
                merge: true,
            }
        )
}
export async function setSubscribedToNewsForNewUser(
    subscribed = false,
    email: string
): Promise<void> {
    let user: UserInterfaceFromFirebase
    const userSnapshot = await db
        .collection('users')
        .where('email', '==', email)
        .get()
    userSnapshot.forEach((doc) => {
        const docData = doc.data() as UserInterfaceFromFirebase
        user = docData
    })

    db.collection('users_settings').doc(user.User.UID).set(
        {
            SubscribedToNews: subscribed,
        },
        {
            merge: true,
        }
    )
}

export function setServer(server: string): void {
    const UID = firebase.auth().currentUser.uid
    db.collection('users_settings')
        .doc(UID)
        .set(
            {
                PreferredCluster: server || '',
            },
            {
                merge: true,
            }
        )
}
