// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'

// Add the Firebase products that you want to use
/* eslint-disable import/no-duplicates */
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
/* eslint-enable */

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = (() => {
    if (process.env.REACT_APP_ENV === 'PROD') {
        return {
            apiKey: 'AIzaSyBjmEduD8DJBcaM-qyqldfCFO62Oo1tJfE',
            authDomain: 'esoteric-fx-261813.firebaseapp.com',
            databaseURL: 'https://esoteric-fx-261813.firebaseio.com',
            projectId: 'esoteric-fx-261813',
            storageBucket: 'esoteric-fx-261813.appspot.com',
            messagingSenderId: '426566571368',
            appId: '1:426566571368:web:ccde3c5ceb9ae7112632dd',
            measurementId: 'G-0K802FN5N6',
        }
    } else {
        return {
            apiKey: 'AIzaSyC8fs58MUgPdF85hgNlgyBhBHdLRcp9DiU',
            authDomain: 'piepacker-staging.firebaseapp.com',
            databaseURL: 'https://piepacker-staging.firebaseio.com',
            projectId: 'piepacker-staging',
            storageBucket: 'piepacker-staging.appspot.com',
            messagingSenderId: '475644064543',
            appId: '1:475644064543:web:5bfc431095166a6c74b62a',
            measurementId: 'G-6RQ426VLZ6',
        }
    }
})()

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
if (firebase.analytics.isSupported()) firebase.analytics()

export const db = firebase.firestore()
