import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertType } from '../../../state/alert'
import { Alert } from '../../../stories'
import NotFoundImage from '../assets/NotFoundPiePieEmoji.png'
import './SearchResultItem.scss'

export default function GameNotFound() {
    const { t } = useTranslation()
    return (
        <div className="not-found">
            <img
                height="100"
                className="game-not-found-image"
                src={NotFoundImage}
                alt="Sad emoji indicating no results for given search."
            />
            <Alert
                autoClose={false}
                animate={false}
                className="game-not-found-alert-message"
                type={AlertType.Warning}
                message={t('SwitchGameNotFound')}
            />
        </div>
    )
}
