import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../store/types'

export const useLoudestParticipant = (delay = 300) => {
    const [loudestParticipant, setLoudestParticipant] = useState(undefined)
    const [allowExecution, setAllowExectuion] = useState(false)

    const participantsVolume = useSelector(
        (state: State) => state.conference.participantsVolume
    )

    const timer = useRef(undefined)

    useEffect(() => {
        if (!timer.current) {
            timer.current = setTimeout(() => {
                timer.current = undefined
                setAllowExectuion(true)
            }, delay)
        }
    }, [allowExecution, delay])

    // Executed when player volume changes (executed many times, so it should be fast)
    useEffect(() => {
        if (!allowExecution) return

        let tempLoudestParticipant = { volume: 0, userId: undefined }
        Object.entries(participantsVolume).forEach(([userId, volume]) => {
            if (volume > tempLoudestParticipant?.volume) {
                tempLoudestParticipant = { userId, volume }
            }
        })
        setLoudestParticipant(tempLoudestParticipant.userId)
        setAllowExectuion(false)
    }, [participantsVolume, allowExecution])

    return loudestParticipant
}
