import * as Axios from 'axios'
import firebase from 'firebase/compat/app'
import { setSubmitError, setSubmitSucess } from './authV2'
import { logAxiosErrorResponse } from '../../utils/http'
import {
    AxiosErrorMessage,
    deleteCookie,
    getCookie,
    getTransactionEmailServiceHostname,
    MARIO_API_URL,
    piepie,
    setCookie,
} from '../../utils'
import store from '..'
import i18next from 'i18next'
import { CookieStorage, LocalStorage } from '../../state/storage'
import {
    loginUserFacebook,
    logInUserGoogle,
    setSubscribedToNewsForNewUser,
} from '../../firebase'

export const handlePasswordReset = async ({ email }, { setStatus }) => {
    setStatus({ success: false })
    analytics.track('ResetPassword', {
        email: email,
    })
    try {
        await Axios.default.post(
            `${getTransactionEmailServiceHostname()}/resetPassword`,
            { email: email }
        )
        setStatus({ success: true })
    } catch (err) {
        logAxiosErrorResponse(err)
        store.dispatch(setSubmitError(AxiosErrorMessage(err)))
    }
}

export const onSignInSuccess = (authenticationMethod: string) => {
    try {
        window.dataLayer.push({
            event: 'signin',
            authenticationMethod: authenticationMethod,
        })
        analytics.track('Datalayer Trigger', {
            event: 'signin',
            authenticationMethod: authenticationMethod,
            status: 'success',
        })
    } catch (error) {
        analytics.track('Datalayer Trigger', {
            event: 'signin',
            authenticationMethod: authenticationMethod,
            status: 'failed',
        })
    }

    const intendedUrl = getCookie(CookieStorage.intendedUrl)

    if (intendedUrl) {
        // Delete the cookie so we don't redirect the user to the old game page anymore when logging in in the future.
        deleteCookie(CookieStorage.intendedUrl)
        window.location.href = intendedUrl
    } else {
        window.location.reload()
    }
}

export const onSignInError = (err) => {
    if (err.signedWithPassword) {
        firebase
            .auth()
            .fetchSignInMethodsForEmail(err.email)
            .then((methods) => {
                if (methods[0] === 'google.com') {
                    err.code = 'auth/try-google-login'
                }

                if (methods[0] === 'facebook.com') {
                    err.code = 'auth/try-facebook-login'
                }

                store.dispatch(setSubmitError(err?.code))
                piepie.log(err)
            })
    } else {
        store.dispatch(setSubmitError(err?.code))
        piepie.log(err)
    }
}

export const handleEmailSignIn = async (values: {
    email: string
    password: string
}) => {
    try {
        analytics.track('Login', {
            email: values.email,
        })
        await firebase
            .auth()
            .signInWithEmailAndPassword(values.email.trim(), values.password)
        onSignInSuccess('email')
    } catch (err) {
        err.signedWithPassword = true
        err.email = values.email.trim()
        onSignInError(err)
    }
}

export const handleFacebookLogin = () =>
    loginUserFacebook(() => onSignInSuccess('Facebook'), onSignInError)

export const handleGoogleLogin = () =>
    logInUserGoogle(() => onSignInSuccess('Google'), onSignInError)

export const onSignUpSuccess = (authenticationMethod) => {
    try {
        window.dataLayer.push({
            event: 'new_signup',
            authenticationMethod: authenticationMethod,
        })

        analytics.track('Datalayer Trigger', {
            event: 'new_signup',
            authenticationMethod: authenticationMethod,
            status: 'success',
        })
    } catch (error) {
        analytics.track('Datalayer Trigger', {
            event: 'new_signup',
            authenticationMethod: authenticationMethod,
            status: 'failed',
        })
    }
}

export const onSignUpError = (err) => {
    store.dispatch(setSubmitError(err?.code))
    piepie.log(err)
}

export const handleEmailSignUp = async ({
    values,
    location,
    subscribedtoUpdates,
}) =>
    // TODO: add connected-react-router package so we can use history object easily outside react component
    {
        const urlParams = new URLSearchParams(location?.search)
        const { email, displayName, password } = values

        const trimmedEmail = email.trim()
        const trimmedDisplayName = displayName.replace(/  +/g, ' ').trim()

        store.dispatch(setSubmitError(''))
        store.dispatch(setSubmitSucess(''))

        try {
            await Axios.default.post(
                `${getTransactionEmailServiceHostname()}/bodyguard`,
                {
                    email: email,
                    uid: 'signup',
                    text: trimmedDisplayName,
                }
            )
        } catch (error) {
            logAxiosErrorResponse(error)
            store.dispatch(setSubmitError(error.response.data))
            return
        }

        try {
            analytics.track('Signup', {
                email: trimmedEmail,
                displayName: trimmedDisplayName,
            })
            const result = await Axios.default.post(
                `${getTransactionEmailServiceHostname()}/signup`,
                {
                    email: trimmedEmail,
                    displayName: trimmedDisplayName,
                    password,
                    utm_source: urlParams.get('utm_source'),
                    utm_medium: urlParams.get('utm_medium'),
                    utm_campaign: urlParams.get('utm_campaign'),
                    utm_content: urlParams.get('utm_content'),
                    utm_term: urlParams.get('utm_term'),
                }
            )
            store.dispatch(setSubmitSucess(result.data))
            setSubscribedToNewsForNewUser(subscribedtoUpdates, email)

            onSignUpSuccess('email')

            // The user signed up on the "Enter as a guest page". Meaning someone invited them to play.
            // Hence they expect to be redirected to the specific game page after finishing the sign up process.
            if (location?.pathname.includes('/game/')) {
                // If the user confirms the email address after one hour, most likely,
                // the specific game session has ended, and they are not expecting to be redirected to that game page anymore.
                const oneHour = 3600 * 1000
                setCookie(
                    CookieStorage.intendedUrl,
                    window.location.href,
                    oneHour
                )
            }
        } catch (error) {
            logAxiosErrorResponse(error)
            store.dispatch(setSubmitError(error.response.data))
        }
    }

export const handleFacebookSignUp = () =>
    loginUserFacebook(() => onSignUpSuccess('Facebook'), onSignUpError)

export const handleGoogleSignUp = () =>
    logInUserGoogle(() => onSignUpSuccess('Google'), onSignUpError)

export const handleGuestSignIn = async ({ displayName }) => {
    store.dispatch(setSubmitError(''))

    const user = firebase.auth().currentUser
    const trimmedDisplayName = displayName.replace(/  +/g, ' ').trim()
    try {
        await Axios.default.post(
            `${getTransactionEmailServiceHostname()}/bodyguard`,
            {
                uid: user.uid,
                text: trimmedDisplayName,
            }
        )
    } catch (error) {
        logAxiosErrorResponse(error)
        store.dispatch(setSubmitError(error.response.data))
        return
    }
    analytics.track('EnterAsGuest', {
        displayName: trimmedDisplayName,
        uid: user.uid,
    })
    try {
        await Axios.default.post(`${MARIO_API_URL}/users/enter-as-guest`, {
            id: user.uid,
            displayName: trimmedDisplayName,
            language:
                i18next.language ||
                window.localStorage.getItem(LocalStorage.i18nextLng) ||
                'en',
        })
        await user.updateProfile({ displayName })
        window.location.reload()
    } catch (error) {
        logAxiosErrorResponse(error)
        store.dispatch(setSubmitError(error.response.data))
    }
}
