import style from './style.module.scss'

import React, { FC } from 'react'
import cls from 'classnames'

interface SpinnerProps {
    className?: string
}

const Spinner: FC<SpinnerProps> = ({ className }) => {
    return (
        <svg
            className={cls(style.spinner, className)}
            height="100"
            width="100"
            viewBox="0 0 100 100"
        >
            <circle className={style.circle} cx="50" cy="50" r="45"></circle>
        </svg>
    )
}

export default Spinner
