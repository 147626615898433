import React, { FC } from 'react'
import style from './style.module.scss'
import { GameModalType } from '../GameModal/types'

interface IPublicRoomsDescription {
    title: string
    description: string
    type: GameModalType
    gameId: string
}

const PublicRoomsDescription: FC<IPublicRoomsDescription> = ({
    title,
    description,
    type,
    gameId,
}) => {
    return (
        <>
            {type === GameModalType.Mood ? (
                title ? (
                    <div className={style.descriptionContainer}>
                        <div className={style.roomTitle}>{title}</div>
                        <div className={style.smallDescription}>
                            {description}
                        </div>
                    </div>
                ) : (
                    <div className={style.descriptionContainer}>
                        <div className={style.roomTitle}>{gameId}</div>
                        <div className={style.smallDescription}>
                            {description}
                        </div>
                    </div>
                )
            ) : title ? (
                <div className={style.descriptionContainer}>
                    <div className={style.roomTitle}>{title}</div>
                    <div className={style.smallDescription}>{description}</div>
                </div>
            ) : (
                <div className={style.roomDescription}>{description}</div>
            )}
        </>
    )
}

export default PublicRoomsDescription
