export const numberOfPlayersAsTags = {
    HomePageFilterSoloGames: 1,
    HomePageFilter2p: 2,
    HomePageFilter3p: 3,
    HomePageFilter4p: 4,
}

export const getNumberOfPlayersTagName = (value: string | number) =>
    Object.keys(numberOfPlayersAsTags).find(
        (key) => numberOfPlayersAsTags[key] === value
    )
