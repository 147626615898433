import { Box, Typography } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Game, SessionFromFirebase, SessionMoodType } from '../../state/starfox'
import PublicRoomsItem from './PublicRoomsItem'
import NoPublicRooms from './NoPublicRooms'
import RefreshIcon from '@material-ui/icons/Refresh'
import RoomFilters from './RoomFilters'
import PieSlider from '../PieSwiper/PieSlider'
import { GameModalType } from '../GameModal/types'
import GameDescription from './GameDescription'
import { FilteredRoomType } from './types'

interface IPublicRoomsList {
    game: Game
    mood: SessionMoodType
    rooms: SessionFromFirebase[]
    type: GameModalType
    refresh: () => void
}

const PublicRoomsList: FC<IPublicRoomsList> = ({
    game,
    mood,
    rooms,
    type,
    refresh,
}) => {
    const { t } = useTranslation()
    const isFetched = useRef(false)
    const [filteredRooms, setFilteredRooms] = useState<FilteredRoomType[]>([])
    const [sliderWidth, setSliderWidth] = useState(0)
    const [itemWidth, setItemWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            const sliderW = document.documentElement.clientWidth * 0.65
            const itemW =
                document.documentElement.clientWidth * 0.15 > 280
                    ? document.documentElement.clientWidth * 0.15
                    : 280
            setSliderWidth(sliderW)
            setItemWidth(itemW + 30)
        }
        window.addEventListener('resize', handleResize)

        if (!isFetched.current) {
            isFetched.current = true
            handleResize()
        }

        return () => {
            // remove the event listener
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={style.publicRooms}>
            <div className={style.publicRoomsHeader}>
                <div className={style.publicRoomsTitle}>
                    <Typography
                        variant="h6"
                        component="h2"
                        className={style.title}
                    >
                        {t('JoinLiveRooms')}
                    </Typography>
                    <div className={style.refresh} onClick={refresh}>
                        <RefreshIcon />
                    </div>
                </div>
                <RoomFilters
                    type={type}
                    gameId={game ? game.id : mood}
                    mood={mood}
                    rooms={rooms}
                    setFilteredRooms={setFilteredRooms}
                />
            </div>
            <div className={style.publicRoomsList}>
                {filteredRooms && filteredRooms.length > 0 && sliderWidth ? (
                    <PieSlider
                        itemsList={filteredRooms}
                        windowWidthReference={sliderWidth}
                        containerHeight={'27vh'}
                        containerPadding={'0'}
                        sliderItemWidth={itemWidth}
                        sliderMarginRight={12}
                        transition={'0.4s'}
                        keyboardNavigation={false}
                    >
                        {Object.entries(filteredRooms).map(([index, item]) => {
                            return (
                                <PublicRoomsItem
                                    key={index}
                                    room={item.session}
                                    players={item.players}
                                    type={type}
                                />
                            )
                        })}
                    </PieSlider>
                ) : (
                    <NoPublicRooms gameId={game ? game.id : mood} type={type} />
                )}
            </div>
            {type === GameModalType.Regular && (
                <Box className={style.gameDesc}>
                    <GameDescription game={game} />
                </Box>
            )}
        </div>
    )
}

export default PublicRoomsList
