import styles from './styles.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'
import { serverLocation } from './constants'
import { Dropdown } from '../../../stories'
import { setServer } from '../../../firebase'

interface IUpdateRegion {
    isModalOpen: boolean
    selectedRegion: string
    setIsModalOpen: (val: boolean) => void
    refreshUser: () => void
}

const UpdateRegion: React.FC<IUpdateRegion> = ({
    isModalOpen,
    selectedRegion,
    setIsModalOpen,
    refreshUser,
}) => {
    const { t } = useTranslation()
    const [currentRegion, setCurrentRegion] = useState('')
    const [isRegionVisible, setIsRegionVisible] = useState<boolean>(false)

    useEffect(() => {
        setCurrentRegion(selectedRegion)
    }, [selectedRegion])

    const handleRegionSelect = (id: string) => {
        setCurrentRegion(id)
        setIsRegionVisible(false)
    }

    const handleSuccess = () => {
        setServer(currentRegion)
        refreshUser()
        setIsModalOpen(false)
    }

    const regionData = Object.values(serverLocation)
        .filter(({ address }) => address !== currentRegion)
        .map(({ address, text }) => ({
            value: t(text),
            id: address,
        }))

    const handleModalHide = () => {
        setIsModalOpen(false)
        setIsRegionVisible(false)
    }

    return (
        <Modal
            onHide={handleModalHide}
            show={isModalOpen}
            centered
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('ServerLocation')}</Modal.Title>
            </Modal.Header>
            <div className={styles.modalInputWrapper}>
                <div>
                    <label>{t('Region')}</label>
                    <Dropdown
                        data={regionData}
                        show={isRegionVisible}
                        onSelect={handleRegionSelect}
                        selectedItem={t(
                            currentRegion && serverLocation[currentRegion]
                                ? serverLocation[currentRegion].text
                                : serverLocation.Default.text
                        )}
                        onToggle={() => setIsRegionVisible(!isRegionVisible)}
                    />
                </div>
                <Button
                    block
                    type="submit"
                    className="btn-large"
                    onClick={handleSuccess}
                >
                    {t('Save')}
                </Button>
            </div>
        </Modal>
    )
}

export default UpdateRegion
