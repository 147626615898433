import { Fab, Tooltip } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
//import { SoundControl } from './screen-components'
import FullscreenIcon from '@material-ui/icons/Fullscreen'

import { ScreenMenuProps } from './screen-props'
import { useTranslation } from 'react-i18next'
import { useKonHandler } from '../../utils'
import { isMobile } from 'react-device-detect'
import { patternFS } from '../../utils/konami'

export default function ScreenMenu(props: ScreenMenuProps) {
    const [showFS, setShowFS] = useState(false)
    const current = useRef(0)
    const { t } = useTranslation()

    const { konHandler } = useKonHandler({
        pattern: patternFS,
        current: current,
        callback: setShowFS,
    })

    useEffect(() => {
        document.addEventListener('keydown', konHandler, false)
        return () => {
            document.removeEventListener('keydown', konHandler, false)
        }
    }, [konHandler])

    return (
        <>
            {!isMobile && (
                <div>
                    {showFS && (
                        <Tooltip
                            title={t('FullscreenMode')}
                            aria-label="fullscreen"
                            placement="top"
                        >
                            <Fab
                                style={{
                                    color: 'white',
                                    background: 'none',
                                    boxShadow: 'none',
                                }}
                                size="small"
                                onClick={props.setFullscreen}
                            >
                                <FullscreenIcon />
                            </Fab>
                        </Tooltip>
                    )}
                </div>
            )}
        </>
    )
}
