import { HighlightScreenStatus } from '../state/screen'
import { GAME_STATE } from '../store/session/types'

export const isGame = (gameState: GAME_STATE) => ({
    paused: gameState === GAME_STATE.PAUSED,
    ready: gameState === GAME_STATE.READY,
    loading: gameState === GAME_STATE.LOADING,
    inSelectionScreen: gameState === GAME_STATE.SELECTING,
    idle: gameState === GAME_STATE.PAUSED || gameState === GAME_STATE.SELECTING,
})

export const showLoading = (
    gameState: GAME_STATE,
    highlightStatus: HighlightScreenStatus
) =>
    isGame(gameState).loading &&
    highlightStatus === HighlightScreenStatus.NotHighlighting
