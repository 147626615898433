import React, { useEffect, useState, FC, useCallback } from 'react'
import Faces from '../Faces'

import { MobileGameControls } from './MobileGameControls'
import { useDeviceOrientation } from '../../utils'

import { User } from '../../state/faces'
import GameScreen from './GameScreen'
import { usePausePlay } from '../../utils/GameRoomHooks'
import { MobileGameSettingsModal } from './MobileSettingsModal'

export type UsersDataType = {
    nickname: string
    photoUrl: string
    uid: string
    userSessionID: string
}

export type SectionType = 'pause' | 'layout' | 'load' | 'reset' | 'switch'

export interface MobileGameRoomProps {
    users: any
    allUsers: { [index: string]: User }
    onSettingsOpen: () => void
    handleSetFullscreen: () => void
}

const MobileGameRoom: FC<MobileGameRoomProps> = ({
    users,
    allUsers,
    onSettingsOpen,
    handleSetFullscreen,
}) => {
    const [showChat, setShowChat] = useState<boolean>(false)
    const [section, setSection] = useState<SectionType>('pause')
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const { handlePauseGame } = usePausePlay()

    const [showRoomSettings, setShowRoomSettings] = useState<boolean>(false)

    const [isDynamic, setIsDynamic] = useState<boolean>(false)
    const orientation = useDeviceOrientation()
    const [prevOrientation, setPrevOrientation] = useState(orientation)

    useEffect(() => {
        document.body.classList.add('mobilegameroom')

        return () => {
            document.body.classList.remove('mobilegameroom')
        }
    }, [])

    //This is to reset state when the device rotated at layout section
    // FIXME: It is possible that this will be triggered twice. We should find a better way to do this.
    useEffect(() => {
        return () => {
            if (
                orientation &&
                section === 'layout' &&
                prevOrientation !== orientation
            ) {
                setSection('pause')
            }
        }
    }, [orientation, section, prevOrientation])

    useEffect(() => {
        setPrevOrientation(orientation)
    }, [orientation])

    const handleShowRoomSettings = useCallback(() => {
        setShowRoomSettings(true)
        handlePauseGame()
    }, [handlePauseGame])

    return (
        <>
            <div className="mobile-gameroom">
                <div className="faces-section">
                    <Faces
                        mobile={true}
                        users={users}
                        allUsers={allUsers}
                        onSettingsDialogOpen={onSettingsOpen}
                    />
                </div>
                {orientation.includes('portrait') && (
                    <>
                        <GameScreen
                            section={section}
                            setSection={setSection}
                            showChat={showChat}
                            setShowChat={setShowChat}
                            allUsers={allUsers}
                            handleSetFullscreen={handleSetFullscreen}
                            isDynamic={isDynamic}
                            setIsDynamic={setIsDynamic}
                            showOptions={showOptions}
                            handleShowRoomSettings={handleShowRoomSettings}
                            setShowOptions={setShowOptions}
                        />
                        <MobileGameControls
                            section={section}
                            setShowChat={setShowChat}
                            isDynamic={isDynamic}
                            handleOptions={setShowOptions}
                            showOptions={showOptions}
                        />
                    </>
                )}

                {orientation.includes('landscape') && (
                    <>
                        <MobileGameControls
                            section={section}
                            setShowChat={setShowChat}
                            isDynamic={isDynamic}
                            handleOptions={setShowOptions}
                            showOptions={showOptions}
                        >
                            <GameScreen
                                section={section}
                                setSection={setSection}
                                showChat={showChat}
                                setShowChat={setShowChat}
                                allUsers={allUsers}
                                handleSetFullscreen={handleSetFullscreen}
                                isDynamic={isDynamic}
                                setIsDynamic={setIsDynamic}
                                showOptions={showOptions}
                                handleShowRoomSettings={handleShowRoomSettings}
                                setShowOptions={setShowOptions}
                            />
                        </MobileGameControls>
                    </>
                )}
            </div>
            <MobileGameSettingsModal
                showRoomSettings={showRoomSettings}
                setShowRoomSettings={setShowRoomSettings}
            />
        </>
    )
}

export default MobileGameRoom
