import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './style.module.scss'

export interface TextLinkProps {
    className?: string
    onClick?: () => void
}

const TextLink: FC<TextLinkProps> = ({
    onClick,
    children,
    className,
    ...rest
}) => (
    <div
        onClick={onClick}
        className={classnames(styles.link, className)}
        {...rest}
    >
        {children}
    </div>
)

export default TextLink
