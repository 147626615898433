import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import Item from './Item'
import './GameListSection.scss'
import { Game } from '../../../state/starfox'

interface ISlider {
    category: string
    gamesInCategory: Game[]
    handleGameSelect: (gameId: string) => void
}

// Note: add byog item option if it is necessary

const GameListSection: FC<ISlider> = ({
    category,
    gamesInCategory,
    handleGameSelect,
}) => {
    return (
        <div>
            <Typography
                style={{ paddingBottom: '48px', paddingTop: '28px' }}
                variant="h5"
                component="div"
            >
                {category}
            </Typography>

            <div className="selecting-game-row">
                {gamesInCategory.map((game) => (
                    <Item.GameItem
                        key={game.id}
                        game={game}
                        handleGameSelect={handleGameSelect}
                    />
                ))}
            </div>
        </div>
    )
}

export default GameListSection
