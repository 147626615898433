import { AlertStateInterface } from '../../state/alert'

export interface HomeState {
    alert: AlertStateInterface
    country: string
    search: string
    maintenance: boolean
    maintenanceEnd: string
    serverListReady: boolean
    isGameModalOpened: boolean
    gameModalInstance: string
    closestCluster: string
}

// Group all action types under a single enum
export enum HomeActionType {
    country = 'HOME:SET_COUNTRY',
    search = 'HOME:SET_SEARCH',
    maintenance = 'HOME:MAINTENANCE',
    maintenanceEnd = 'HOME:MAINTENANCE_END',
    serverListReady = 'HOME:MAINTENANCE_SERVER_LIST_READY',
    isGameModalOpened = 'HOME:IS_GAME_MODAL_OPENED',
    gameModalInstance = 'HOME:GAME_MODAL_INSTANCE',
    closestCluster = 'HOME:CLOSEST_CLUSTER',
}

// Actions to be dispatched
export type SetCountry = {
    type: HomeActionType.country
    payload: string
}

export type SetSearch = {
    type: HomeActionType.search
    payload: string
}

export type SetMaintenance = {
    type: HomeActionType.maintenance
    payload: boolean
}

export type SetMaintenanceEnd = {
    type: HomeActionType.maintenanceEnd
    payload: string
}

export type SetServerListReady = {
    type: HomeActionType.serverListReady
    payload: boolean
}

export type SetIsGameModalOpened = {
    type: HomeActionType.isGameModalOpened
    payload: boolean
}

export type SetGameModalInstance = {
    type: HomeActionType.gameModalInstance
    payload: string
}

export type SetClosestCluster = {
    type: HomeActionType.closestCluster
    payload: string
}

// TODO: translate action types above into full actions
export type HomeAction =
    | SetCountry
    | SetSearch
    | SetMaintenance
    | SetMaintenanceEnd
    | SetServerListReady
    | SetIsGameModalOpened
    | SetGameModalInstance
    | SetClosestCluster
