import styles from './styles.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'
import { languages } from './constants'
import { Dropdown } from '../../../stories'

interface IUpdateLanguage {
    lang: string
    isModalOpen: boolean
    setIsModalOpen: (val: boolean) => void
    refreshUser: () => void
}

const UpdateLanguage: React.FC<IUpdateLanguage> = ({
    lang,
    isModalOpen,
    setIsModalOpen,
    refreshUser,
}) => {
    const { i18n, t } = useTranslation()
    const [selectedLang, setSelectedLang] = useState(lang)
    const [isLangVisible, setIsLangVisible] = useState<boolean>(false)

    const handleLanguageSelect = (id: string) => {
        setSelectedLang(id)
        setIsLangVisible(false)
    }

    const handleSuccess = () => {
        i18n.changeLanguage(selectedLang)
        refreshUser()
        setIsModalOpen(false)
    }

    const languageData = Object.values(languages)
        .filter(({ code }) => code !== selectedLang)
        .map(({ code, text }) => ({
            value: text,
            id: code,
        }))

    const handleModalHide = () => {
        setIsModalOpen(false)
        setIsLangVisible(false)
    }

    return (
        <Modal
            onHide={handleModalHide}
            show={isModalOpen}
            centered
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('Language')}</Modal.Title>
            </Modal.Header>

            <div className={styles.modalInputWrapper}>
                <div>
                    <label>{t('Language')}</label>
                    <Dropdown
                        data={languageData}
                        show={isLangVisible}
                        onSelect={handleLanguageSelect}
                        selectedItem={languages[selectedLang].text}
                        onToggle={() => setIsLangVisible(!isLangVisible)}
                    />
                </div>
                <Button
                    block
                    type="submit"
                    className="btn-large"
                    onClick={handleSuccess}
                >
                    {t('Save')}
                </Button>
            </div>
        </Modal>
    )
}

export default UpdateLanguage
