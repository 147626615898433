import React from 'react'
import style from './styles.module.scss'
import useVideoPlayback from './useVideoPlayback'
import Logo from '../../components/GameLogo'

const videoSrc = (id: string) =>
    `https://fassets.piepacker.com/videos-preview/${encodeURIComponent(
        id
    )}_featured_large.mp4`

const VideoBackground = () => {
    const { videoRef, mediaId, playNextVideo } = useVideoPlayback()

    return (
        <div className={style.videoWrapper}>
            <video
                muted
                autoPlay
                key={mediaId}
                ref={videoRef}
                className={style.video}
                onEnded={playNextVideo}
                src={videoSrc(mediaId)}
            ></video>
            <div className={style.image}>
                <Logo gameID={mediaId} logoKind={style.logo} />
            </div>
        </div>
    )
}

export default VideoBackground
