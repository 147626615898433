import {
    OPEN_MODAL,
    CLOSE_MODAL,
    ModalState,
    ModalActionTypes,
    CONFIRM_MODAL_BAN,
    CONFIRM_MODAL_MUTE,
    CONFIRM_MODAL_RESET,
} from './types'
import { ConfirmDialog } from '../../state/confirm'

export const INITIAL_STATE: ModalState = {
    isOpen: false,
    onClose: null,
    message: '',
    confirmModal: {
        type: ConfirmDialog.None,
    },
}

export default function (
    state = INITIAL_STATE,
    action: ModalActionTypes
): ModalState {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                ...action.payload,
                isOpen: true,
            }
        case CLOSE_MODAL:
            return {
                ...state,
                isOpen: false,
            }
        case CONFIRM_MODAL_BAN:
            return {
                ...state,
                confirmModal: {
                    type: ConfirmDialog.Ban,
                    ...action.payload,
                },
            }
        case CONFIRM_MODAL_MUTE:
            return {
                ...state,
                confirmModal: {
                    type: ConfirmDialog.Mute,
                    ...action.payload,
                },
            }
        case CONFIRM_MODAL_RESET:
            return {
                ...state,
                confirmModal: {
                    type: ConfirmDialog.None,
                },
            }
        default:
            return state
    }
}
