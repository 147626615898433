import React, {
    MutableRefObject,
    ForwardRefRenderFunction,
    forwardRef,
} from 'react'
import cls from 'classnames'
// import { useSelector } from 'react-redux'
// import { State } from '../../store/types'
import style from './style.module.scss'
import { isMobile } from 'react-device-detect'
interface VideoProps {
    className?: string
    onlyPlaceholder: boolean
    showAvatar?: boolean
    [x: string]: any
}

const Video: ForwardRefRenderFunction<any, VideoProps> = (
    { className, onlyPlaceholder = false, showAvatar, otherProps },
    ref: MutableRefObject<any>
) => {
    if (onlyPlaceholder) {
        return <div className={cls(style.video, className)} />
    }

    return (
        <>
            <video
                ref={ref}
                className={cls(
                    style.video,
                    className,
                    showAvatar && style.none
                )}
                {...otherProps}
            />
            {showAvatar && (
                <video
                    src="https://fassets.piepacker.com/random/avatar.mp4"
                    className={cls(
                        isMobile ? style.avatarMobileVideo : style.avatarVideo
                    )}
                    autoPlay
                    loop
                />
            )}
        </>
    )
}

export default forwardRef(Video)
