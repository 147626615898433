import FaceExpressionsEvaluator from '../contrib/WebARRocksFace/helpers/WebARRocksFaceExpressionsEvaluator.js'

let _spec = null
const _defaultSpec = {
    onMouthOpen: null,
    onMouthClose: null,

    onSmileStart: null,
    onSmileEnd: null,

    onEyeLeftClose: null,
    onEyeLeftOpen: null,

    onEyeRightClose: null,
    onEyeRightOpen: null,

    onWinkStart: null,
    onWinkEnd: null,

    onEyebrowsUpStart: null,
    onEyebrowsUpEnd: null,

    onEyebrowsDownStart: null,
    onEyebrowsDownEnd: null,
}

function init_evaluators(WEBARROCKSFACE) {
    // run WEBARROCKSFACE.get_LMLabels() in the web console
    // to get landmarks labels provided by the current neural network

    // MOUTH OPEN:
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'OPEN_MOUTH',
        {
            // landmarks for NN_FACE_XXX neural networks:
            //refLandmarks: ['lowerLipTop', 'chin'],
            //landmarks: ['lowerLipTop', 'upperLipBot'],

            // landmarks for autobones neural networks:
            refLandmarks: ['lowerLipBot', 'chin'],
            landmarks: ['lowerLipBot', 'upperLipTop'],
            range: [0.8, 1.3],
            isInv: false,
            isDebug: false,
        }
    )

    // MOUTH SMILE:
    FaceExpressionsEvaluator.add_expressionEvaluator(WEBARROCKSFACE, 'SMILE', {
        refLandmarks: ['mouthLeft', 'mouthRight'],
        landmarks: ['lowerLipBot', 'upperLipTop'],
        range: [0.2, 0.3], // originally [0.22, 0.35] but smile was too hard to detect
        isInv: true,
        isDebug: false,
    })

    // OPEN/CLOSE EYES:
    const closeEyeEvaluatorParams = {
        range: [0.3, 0.6], //[0.4, 0.7] -> value for NN_AUTOBONES_8.json,
        //
        isInv: true,
        delayMinMs: 500,
    }
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'CLOSE_LEFT_EYE',
        Object.assign(
            {
                refLandmarks: ['leftEyeTopFixed', 'leftEyeBot'],
                landmarks: ['leftEyeTop', 'leftEyeBot'],
            },
            closeEyeEvaluatorParams
        )
    )
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'CLOSE_RIGHT_EYE',
        Object.assign(
            {
                refLandmarks: ['rightEyeTopFixed', 'rightEyeBot'],
                landmarks: ['rightEyeTop', 'rightEyeBot'],
            },
            closeEyeEvaluatorParams
        )
    )

    // EYEBROWS UP:
    const eyebrowUpEvaluatorParams = {
        range: [0.95, 1.4],
        isDebug: false,
        delayMinMs: 500,
    }
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROW_RIGHT_UP',
        Object.assign(
            {
                refLandmarks: ['rightEyeTopFixed', 'rightEyeBrowCenterFixed'],
                landmarks: ['rightEyeTopFixed', 'rightEyeBrowCenter'],
            },
            eyebrowUpEvaluatorParams
        )
    )
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROW_LEFT_UP',
        Object.assign(
            {
                refLandmarks: ['leftEyeTopFixed', 'leftEyeBrowCenterFixed'],
                landmarks: ['leftEyeTopFixed', 'leftEyeBrowCenter'],
            },
            eyebrowUpEvaluatorParams
        )
    )

    // EYEBROWS DOWN:
    const eyebrowDownEvaluatorParams = {
        range: [0.7, 0.9], // [0.8, 1.0] -> value for NN_AUTOBONES_8.json
        isInv: true,
        isDebug: false,
        delayMinMs: 500,
    }
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROW_RIGHT_DOWN',
        Object.assign(
            {
                refLandmarks: ['rightEyeTopFixed', 'rightEyeBrowCenterFixed'],
                landmarks: ['rightEyeTopFixed', 'rightEyeBrowCenter'],
            },
            eyebrowDownEvaluatorParams
        )
    )
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROW_LEFT_DOWN',
        Object.assign(
            {
                refLandmarks: ['leftEyeTopFixed', 'leftEyeBrowCenterFixed'],
                landmarks: ['leftEyeTopFixed', 'leftEyeBrowCenter'],
            },
            eyebrowDownEvaluatorParams
        )
    )

    // COMPOSITE EVALUATORS:
    FaceExpressionsEvaluator.add_expressionEvaluator(WEBARROCKSFACE, 'WINK', {
        computeFrom: ['CLOSE_LEFT_EYE', 'CLOSE_RIGHT_EYE'],
        operator: 'MIN',
        isDebug: false,
        delayMinMs: 500,
    })
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROWS_UP',
        {
            computeFrom: ['EYEBROW_RIGHT_UP', 'EYEBROW_LEFT_UP'],
            operator: 'MAX',
            isDebug: false,
            delayMinMs: 500,
        }
    )
    FaceExpressionsEvaluator.add_expressionEvaluator(
        WEBARROCKSFACE,
        'EYEBROWS_DOWN',
        {
            computeFrom: ['EYEBROW_RIGHT_DOWN', 'EYEBROW_LEFT_DOWN'],
            operator: 'MIN',
            isDebug: false,
            delayMinMs: 500,
        }
    )
}

function init_triggers() {
    FaceExpressionsEvaluator.add_trigger('OPEN_MOUTH', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onMouthOpen,
        onEnd: _spec.onMouthClose,
    })

    FaceExpressionsEvaluator.add_trigger('SMILE', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onSmileStart,
        onEnd: _spec.onSmileEnd,
    })

    FaceExpressionsEvaluator.add_trigger('CLOSE_RIGHT_EYE', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onEyeRightClose,
        onEnd: _spec.onEyeRightOpen,
    })

    FaceExpressionsEvaluator.add_trigger('CLOSE_LEFT_EYE', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onEyeLeftClose,
        onEnd: _spec.onEyeLeftOpen,
    })

    FaceExpressionsEvaluator.add_trigger('WINK', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onWinkStart,
        onEnd: _spec.onWinkEnd,
    })
    FaceExpressionsEvaluator.add_trigger('EYEBROWS_UP', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onEyebrowsUpStart,
        onEnd: _spec.onEyebrowsUpEnd,
    })
    FaceExpressionsEvaluator.add_trigger('EYEBROWS_DOWN', {
        threshold: 0.5,
        hysteresis: 0.1,
        onStart: _spec.onEyebrowsDownStart,
        onEnd: _spec.onEyebrowsDownEnd,
    })
}

const that = {
    init: function (WEBARROCKSFACE, spec) {
        _spec = Object.assign({}, _defaultSpec, spec)
        FaceExpressionsEvaluator.destroy()
        init_evaluators(WEBARROCKSFACE)
        init_triggers()
    },

    update(WEBARROCKSFACE, detectState) {
        const expressionsValues = FaceExpressionsEvaluator.evaluate_expressions(
            WEBARROCKSFACE,
            detectState
        )
        FaceExpressionsEvaluator.run_triggers(expressionsValues)
    },
}

export default that
