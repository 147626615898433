export type bootstrapColor =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'

export interface AlertStateInterface {
    message: string
    variant: bootstrapColor
}

export const EmptyAlert: AlertStateInterface = {
    message: '',
    variant: 'primary',
}

export enum AlertType {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
    Success = 'Success',
    Idle = 'Idle',
}

export interface AlertInterface {
    id?: string
    type?: AlertType
    icon?: string
    message: string
    autoClose?: boolean
}

export enum GameLobbyErrorType {
    ROOM_FULL = 'LobbyFullError', // if room is full
    TIMEOUT = 'LobbyTimeoutError', // if room is not healthy (timeout). We display an error and allow the user to go home if the room is unhealthy after clicking on 'Enter room'
    CREATE_ROOM_FORBIDDEN = 'LobbyForbiddenError', // if the user tries to create a room without being logged in
    ROOM_FAILURE = 'LobbyRoomFailure', // if the creation of the room failed
    USER_BANNED = 'LobbyUserBanned', // if the user has been banned
}
