import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../components/ui'

const Loader: React.FC = () => {
    const { t } = useTranslation()
    return (
        <>
            <Spinner className="redeemcode_modal_spinner" />
            <span className="redeemcode_modal_loading">
                {t('RedeemModalLoading')}
            </span>
        </>
    )
}

export default Loader
