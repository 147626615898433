import store from '../index'

// Make sure to have all functions which refer to the store in an external
// module, such as with this connection function, otherwise you'll
// have recursive effect which will fail tests.

// connectionInitialized checks if the connection is initialized
export const connectionInitialized = (): boolean => {
    return !!store.getState().socket.conn
}
