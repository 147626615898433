import React, { useEffect, FC, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useChime, useConferenceSettings } from '../../../conference/hooks'
import style from './style.module.scss'
import { State } from '../../../store/types'
import { Participant, Participants } from '../../../state/conference'
import FaceMask from '../FaceMaskWrapper'
import { User, Player } from '../../../state/faces'
import { Video } from '../../Video'
import { piepie, copyTextToClipboard } from '../../../utils'
import { useTranslation } from 'react-i18next'
import * as Socket from '../../../network/socket'
import { ChimeMessageTopics } from '../../../conference/ChimeSdkWrapper'
import { useParticipants } from './useParticipants'
import { useLoudestParticipant } from './useLoudestParticipant'
import { ConferenceContainer, ConferenceTile } from '../../../stories'
import { setIsVideoFeedEnabled } from '../../../store/conference/actions'
import { RoomAccess } from '../../GameSettings'
import { confirmModalBan, confirmModalMute } from '../../../store/modal/actions'
interface FacesVideoProps {
    viewMode: any
    width: number
    onSettingsDialogOpen?: () => void
    allUsers: { [index: string]: User }
    invitationLink?: string
    onListViewRequest?: (userId?: string) => void
    onPlayerSettingsOpen?: () => void
    players: Player[]
    mobile?: boolean
}

const FacesVideo: FC<FacesVideoProps> = ({
    viewMode,
    onSettingsDialogOpen,
    allUsers,
    width,
    invitationLink,
    onListViewRequest,
    onPlayerSettingsOpen,
    players,
    mobile = false,
}) => {
    const nbCamera = useRef(0)
    const videoElements: HTMLVideoElement[] = []
    const { t } = useTranslation()

    const {
        participants,
    }: {
        participants: Participants<Participant>
    } = useSelector((state: State) => state.conference)
    const isHost = useSelector((state: State) => state.session.isHost)
    const chime = useChime()
    const dispatch = useDispatch()

    const participantList = useParticipants(allUsers)
    const loudestParticipant = useLoudestParticipant()

    const { Access } = useSelector((state: State) => state.session.session)
    const isPrivateRoom = Access === RoomAccess.PRIVATE
    const {
        isCameraAvailable,
        isCameraGranted,
        isMicrophoneAvailable,
        isMicrophoneGranted,
        isVideoFeedEnabled,
    } = useSelector((state: State) => state.conference)
    const {
        isCameraActive,
        toggleIsCameraActive,
        isMicrophoneActive,
        toggleIsMicrophoneActive,
    } = useConferenceSettings()

    useEffect(() => {
        piepie.log('VIDEO_FEED: show participants video feed: ', isPrivateRoom)
        chime.sendMessage(ChimeMessageTopics.VIDEO_FEED, isPrivateRoom)
        dispatch(setIsVideoFeedEnabled(isPrivateRoom))
    }, [chime, isPrivateRoom, dispatch])

    const removeLocalPlayer = useCallback((index) => {
        Socket.removeLocalPlayer(index)
    }, [])

    useEffect(() => {
        Object.values(participants).forEach((participant) => {
            const videoElement = videoElements[participant.tileId - 1]
            // Here we do want to rebind an element EVEN if the src object is not null
            // BECAUSE it may be an inactive stream
            if (
                !participant.isLocal &&
                participant.stream &&
                videoElement &&
                (!videoElement.srcObject ||
                    !(videoElement.srcObject as MediaStream).active)
            ) {
                piepie.log(
                    'CHIME: Adding stream to an video element, videoElement: ',
                    videoElement
                )
                piepie.log(
                    'CHIME: Adding stream to an video element, participant.stream: ',
                    participant.stream
                )
                videoElement.srcObject = participant.stream
                chime.audioVideo.bindVideoElement(
                    participant.tileId,
                    videoElement
                )
            }
        })
        nbCamera.current = 0
    }, [participants, chime.audioVideo, videoElements])

    return (
        <>
            <ConferenceContainer
                variant={viewMode}
                width={width}
                onInviteClick={() => {
                    copyTextToClipboard(invitationLink)
                }}
                inviteClickLabel={t('CopiedToClipboard')}
                onSeeAllClick={() => {
                    onListViewRequest()
                }}
            >
                {participantList.map((participant) => {
                    const highlighted =
                        participant.userId === loudestParticipant

                    const currentPlayer = players.find(
                        (player) => player.usid === participant.id
                    )

                    // MAIN PLAYER
                    if (participant.type === 'local') {
                        return (
                            <ConferenceTile
                                nickname={participant?.userData?.nickname}
                                nicknameColor={
                                    participant.userExtraData?.NicknameColor
                                }
                                playerIndex={participant.playerIndex}
                                id="main-player"
                                onClick={onPlayerSettingsOpen}
                                variant={mobile ? 'circleLarge' : 'default'}
                            >
                                <ConferenceTile.Content
                                    placeholder={currentPlayer?.photoUrl}
                                    showPlaceholder={!isCameraActive}
                                >
                                    <FaceMask
                                        className={style.face}
                                        playerIndex={participant.playerIndex}
                                        userSessionID={participant.userId}
                                        showVideoFeed={isVideoFeedEnabled}
                                    />
                                </ConferenceTile.Content>
                                <ConferenceTile.LocalMenu
                                    onSettingsDialogOpen={onSettingsDialogOpen}
                                    isCameraGranted={isCameraGranted}
                                    isCameraAvailable={isCameraAvailable}
                                    isCameraActive={isCameraActive}
                                    toggleIsCameraActive={toggleIsCameraActive}
                                    isMicrophoneGranted={isMicrophoneGranted}
                                    isMicrophoneAvailable={
                                        isMicrophoneAvailable
                                    }
                                    isMicrophoneActive={isMicrophoneActive}
                                    toggleIsMicrophoneActive={
                                        toggleIsMicrophoneActive
                                    }
                                />
                            </ConferenceTile>
                        )
                    }

                    // LOCAL GUESTS
                    if (participant.type === 'localGuest') {
                        return (
                            <ConferenceTile
                                nickname={participant.userData?.nickname}
                                nicknameColor={
                                    participant.userExtraData?.NicknameColor
                                }
                                playerIndex={participant.playerIndex}
                                id={`local-guest-${participant.playerIndex}`}
                                onClick={() => {
                                    onListViewRequest(participant.userId)
                                }}
                            >
                                <ConferenceTile.Content
                                    placeholder="https://fassets.piepacker.com/random/LocalPlayerCameraPlaceholder.png"
                                    showPlaceholder={true}
                                ></ConferenceTile.Content>
                                {participant.isLocal && ( // Do not show the menu for local players of other remote players
                                    <ConferenceTile.LocalGuestMenu
                                        onRemove={(index) =>
                                            removeLocalPlayer(index)
                                        }
                                        playerIndex={participant.playerIndex}
                                    />
                                )}
                            </ConferenceTile>
                        )
                    }

                    // REMOTE PLAYERS
                    return (
                        <ConferenceTile
                            nickname={participant?.userData?.nickname}
                            nicknameColor={
                                participant.userExtraData?.NicknameColor
                            }
                            playerIndex={participant.playerIndex}
                            id={`remote-player-${participant.userId}`}
                            onClick={() => {
                                onListViewRequest(participant.userId)
                            }}
                            highlighted={highlighted}
                        >
                            <ConferenceTile.Content
                                placeholder={currentPlayer?.photoUrl}
                                showPlaceholder={!participant?.stream?.active}
                            >
                                <Video
                                    className={style.face}
                                    ref={(element: HTMLVideoElement) => {
                                        if (element) {
                                            videoElements[
                                                participant.tileId - 1
                                            ] = element
                                        }
                                    }}
                                    muted
                                    autoPlay
                                />
                            </ConferenceTile.Content>
                            <ConferenceTile.RemoteMenu
                                isHost={isHost}
                                onBan={() => {
                                    dispatch(
                                        confirmModalBan({
                                            UID: participant.userData.UID,
                                            UserSessionId:
                                                participant.userData
                                                    .UserSessionID,
                                            nickname:
                                                participant.userData.nickname,
                                        })
                                    )
                                }}
                                isMuted={participant.isMuted}
                                onMute={() =>
                                    dispatch(
                                        confirmModalMute({
                                            id: participant.id,
                                        })
                                    )
                                }
                            />
                        </ConferenceTile>
                    )
                })}
            </ConferenceContainer>
        </>
    )
}

export default FacesVideo
