import styles from './styles.module.scss'
import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterListIcon from '@material-ui/icons/FilterList'
import MoreFilters from '../Filters/MoreFilters'
import QuickAccess from '../Filters/QuickAccess'
import { FilterTag, Icon, SearchInput, ToggleButton } from '../../../stories'
import ExpandableSearch from '../../../stories/molecules/ExpandableSearchInput'
import homeSlice, {
    selectSearch,
    selectOpenRoom,
    selectSelectedTags,
    selectAllFiltersVisibility,
    selectIsListModified,
} from '../../../store/home/homeV2'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

interface IHeaderProps {
    gameTags: string[]
}

const Header: FC<IHeaderProps> = ({ gameTags }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false)

    const {
        setSearch,
        setSelectedTags,
        setOpenRoom,
        setAllFiltersVisibility,
        setResetFilters,
    } = homeSlice.actions

    const search = useSelector(selectSearch)
    const openRoom = useSelector(selectOpenRoom)
    const selectedTags = useSelector(selectSelectedTags)
    const isModalVisible = useSelector(selectAllFiltersVisibility)
    const isListModified = useSelector(selectIsListModified)
    const hideLogo = isSearchExpanded || isListModified

    useEffect(() => {
        if (!search) setIsSearchExpanded(false)
    }, [search])

    return (
        <header className={styles.header}>
            <div className={styles.headerNav}>
                <Link
                    className={classNames(styles.logoLink, {
                        [styles.hideLogo]: hideLogo,
                    })}
                    to={'/'}
                    onClick={() => dispatch(setResetFilters())}
                >
                    <div
                        className={classNames(styles.logo, {
                            [styles.hideLogo]: hideLogo,
                        })}
                        role="img"
                    >
                        <Icon
                            name={'piePieOutline'}
                            className={styles.logoPie}
                        />
                        <Icon
                            name={'jamMultiColor'}
                            className={styles.logoJam}
                        />
                    </div>
                </Link>
                <SearchInput
                    value={search}
                    onChange={(value) => dispatch(setSearch(value))}
                    onClose={() => dispatch(setSearch(''))}
                    placeholder={t('HomepageSearch')}
                    className={styles.searchDesktop}
                />
                <QuickAccess
                    gameTags={gameTags}
                    selectedTags={selectedTags}
                    handleSelectedTags={(tags) =>
                        dispatch(setSelectedTags(tags))
                    }
                />
                <div className={styles.moreFilter}>
                    <div
                        className={classNames(styles.arrowBack, {
                            [styles.isVisible]: hideLogo,
                        })}
                        onClick={() => {
                            setIsSearchExpanded(false)
                            dispatch(setResetFilters())
                        }}
                    >
                        <Icon name={'arrowBack'} />
                    </div>
                    <ExpandableSearch
                        isSearchExpanded={isSearchExpanded}
                        value={search}
                        onChange={(value) => dispatch(setSearch(value))}
                        onClose={() => {
                            dispatch(setSearch(''))
                            setIsSearchExpanded(false)
                        }}
                        onClick={() => setIsSearchExpanded(true)}
                        className={styles.searchMobile}
                    />

                    <div className={styles.switch}>
                        <label>{t('HomepageFiltersOpenRoomsOnly')}:</label>
                        <ToggleButton
                            size={'big'}
                            value={openRoom}
                            setValue={(isOpenRoom) =>
                                dispatch(setOpenRoom(isOpenRoom))
                            }
                        />
                    </div>
                    <FilterTag
                        className={styles.moreFilterTag}
                        onClick={() => dispatch(setAllFiltersVisibility(true))}
                    >
                        <FilterListIcon />
                    </FilterTag>
                    {isModalVisible && <MoreFilters gameTags={gameTags} />}
                </div>
            </div>
        </header>
    )
}

export default Header
