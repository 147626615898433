import { Euler, Matrix4, Object3D, Vector3 } from 'three'

const SETTINGS = {
    // Torso dynamic rotation:
    torsoRotX: 18, // in degrees
    torsoRotYFactor: 0.5,

    torsoBaseNeck: [0, -58, -18], // position of the base of the neck in the torso ref
    torsoTranslateY: 70,
}

const degToRad = Math.PI / 180

// Torso parent THREE object:
let _torso = null
let _spec = null
let _isForcedTorso = false

// Allocate THREE objects used for computations:
const _torsoEuler = new Euler()
const _threeBaseNeckWorldPos = new Vector3()
const _torsoMatrixWorld = new Matrix4()

const is_child = (threeParent, threeChild) => {
    let isChild = false
    threeParent.traverse((threeNode) => {
        isChild = isChild || threeNode === threeChild
    })
    return isChild
}

const init = (spec) => {
    const { threeObject3D, torsoOccluderParent } = spec
    if (_spec && _spec.torsoOccluderParent && _torso) {
        _torso.remove(_spec.torsoOccluderParent)
    }
    _spec = spec
    _isForcedTorso = false

    // extract torso parent object:
    _torso = null
    threeObject3D.traverse((node) => {
        if (node.name.includes('_Torso') && node.name !== '__forced_Torso') {
            if (_torso) {
                // who is the parent: node or _torso?
                if (is_child(node, _torso)) {
                    _torso = node
                } else if (!is_child(_torso, node)) {
                    throw new Error(
                        'Multiple sibling objects have _Torso in their name, so we cannot extract the parent torso object.'
                    )
                }
            } else {
                _torso = node
            }
        }
    })

    if (!_torso && spec.isForcedTorso) {
        _isForcedTorso = true
        _torso = new Object3D()
        _torso.name = '__forced_Torso'
        threeObject3D.add(_torso)
    }

    if (!_torso) return
    console.log('TORSO Found')
    window.debugTorso = _torso

    _torso.matrixAutoUpdate = false
    _torso.frustumCulled = false
    _torso.add(torsoOccluderParent)
}

const update_torsoPose = () => {
    if (!_torso || !_torso.parent) {
        return
    }
    const { threeObject3D, torsoOccluderParent } = _spec
    const headMatrixWorld = threeObject3D.matrixWorld

    // compute rotation part:
    _torsoEuler.setFromRotationMatrix(headMatrixWorld, 'YZX')
    _torsoEuler._x = degToRad * SETTINGS.torsoRotX // > 0 since most people have a camera with a low angle view
    _torsoEuler._y *= SETTINGS.torsoRotYFactor // lower rotation around vertical axis
    _torsoEuler._z = 0 // always 0
    _torso.matrixWorld.makeRotationFromEuler(_torsoEuler)

    // compute base neck point position in World ref:
    _threeBaseNeckWorldPos.fromArray(SETTINGS.torsoBaseNeck) // make origin of torso match with base neck
    _threeBaseNeckWorldPos.applyMatrix4(headMatrixWorld)

    // put torso a bit higher:
    _threeBaseNeckWorldPos.setY(
        _threeBaseNeckWorldPos.y + SETTINGS.torsoTranslateY
    )
    _torso.matrixWorld.setPosition(_threeBaseNeckWorldPos)

    if (_torso.isSkinnedMesh) {
        //_torso.matrixWorld
    }

    _torsoMatrixWorld.copy(_torso.matrixWorld)

    if (torsoOccluderParent.children.length > 0) {
        const torsoOccluder = torsoOccluderParent.children[0]
        if (!torsoOccluder.userData.isSet) {
            torsoOccluder.onBeforeRender = () => {
                torsoOccluder.matrixWorld.copy(_torsoMatrixWorld)
            }
            torsoOccluder.matrixAutoUpdate = false
            torsoOccluder.userData.isSet = true
        }
    }

    // compute torso matrix from its matrixWorld:
    _torso.matrix
        .copy(_torso.parent.matrixWorld)
        .invert()
        .multiply(_torso.matrixWorld)

    // update matrix world for all children
    _torso.updateWorldMatrix(false, true)
}

const update = () => {
    if (!_torso) {
        return
    }
    update_torsoPose()
}

const is_forced = () => {
    return _isForcedTorso
}

export default {
    init,
    is_forced,
    update,
}
