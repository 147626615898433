import styles from './styles.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import * as selector from '../state/selectors'
import { setCurrentBadge } from '../../../firebase'

const FeaturedBadge: React.FC = () => {
    const { t } = useTranslation()
    const badgeList = useSelector(selector.getBadgeList)
    const currentBadge = useSelector(selector.getCurrentBadge)

    return (
        <>
            {currentBadge && (
                <div className="flex-spacebetween">
                    <div>
                        <p className={styles.microFont}>{t('FeaturedBadge')}</p>
                        <div className="font-20">
                            <img
                                alt="badge"
                                className="badge-img"
                                src={currentBadge.ImageUrl}
                            />
                            {currentBadge.Name}
                        </div>
                    </div>
                    <DropdownButton
                        menuAlign="right"
                        variant="secondary"
                        title={currentBadge.Name}
                        className="dropdown-selector"
                    >
                        {badgeList
                            .filter((badge) => badge.Name !== currentBadge.Name)
                            .map((badge) => (
                                <Dropdown.Item
                                    key={badge.ID}
                                    onClick={() => setCurrentBadge(badge)}
                                >
                                    <span>
                                        <img
                                            alt="badge"
                                            className="badge-img"
                                            src={badge.ImageUrl}
                                        />
                                        {badge.Name}
                                    </span>
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                </div>
            )}
        </>
    )
}

export default FeaturedBadge
