import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames'
import React from 'react'
import Slider from '../Slider'
import { featuredCards, horizontalCards, squareCards } from './constants'
import styles from './styles.module.scss'
import { useMatchMedia } from '../hooks/useMatchMedia'

const GridItem = ({ category, children }) => (
    <div
        className={classNames(styles.gridCarousel, {
            [styles.gridCarouselSmall]:
                horizontalCards.includes(category) ||
                squareCards.includes(category),
            [styles.gridCarouselLarge]: featuredCards.includes(category),
        })}
    >
        {children}
    </div>
)

const GridList = ({ children }) => (
    <div className={styles.gridList}>{children}</div>
)

const GridRow = ({ title, children }) => {
    const [windowWidth] = useWindowSize()
    const col = useMatchMedia()

    return (
        <Slider
            title={title}
            className={styles.row}
            style={{
                gridAutoColumns: windowWidth > 1200 && `${col}%`,
            }}
        >
            {children}
        </Slider>
    )
}
export default {
    Item: GridItem,
    List: GridList,
    Row: GridRow,
}
