const GetConfigInfos = (function(){

  function create_GL(){
    const cv = document.createElement('canvas');
    cv.setAttribute('width', 1);
    cv.setAttribute('height', 1);
    return cv.getContext('webgl', {});
  }


  const that = {
    get_userAgent: function(){
       return window['navigator']['userAgent'];
    },

    get_platform: function(){
      return window['navigator']['platform'];
    },

    get_gpu: function(gl){
      let gpu = "UNDEF";
      const glDebugInfoExt = gl['getExtension']("webgl_debug_renderer_info");
      if (glDebugInfoExt && glDebugInfoExt['UNMASKED_RENDERER_WEBGL']){
        gpu = gl['getParameter'](glDebugInfoExt['UNMASKED_RENDERER_WEBGL'])
      }
      return gpu;
    },

    get_all: function(glArg, prefixArg){
      const prefix = prefixArg || '';
      const infos = [
        prefix + 'navigator.userAgent = ' + that.get_userAgent(),
        prefix + 'navigator.platform = ' + that.get_platform()
      ];
      const gl = glArg || create_GL();
      if (gl){
        infos.push(
          prefix + 'GPU = ' + that.get_gpu(gl)
        );
      }
      return infos;
    }
  };

  return that;
})();

export default GetConfigInfos;