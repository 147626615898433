import style from './style.module.scss'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FloatingOverlay, Carousel, HintIcon } from '../../stories'
import { State } from '../../store/types'
import { ControllerType } from '../../stories/atoms/HintIcon/HintIcon'
import { getLanguage } from '../../utils'
import { useTranslation } from 'react-i18next'

const HintSortMap = {
    A: 0,
    B: 1,
    X: 2,
    Y: 3,
    L1: 4,
    R1: 5,
    L2: 6,
    R2: 7,
    UP: 8,
    DOWN: 9,
    LEFT: 10,
    RIGHT: 11,
    JOYSTICKLEFT: 12,
    JOYSTICKRIGHT: 13,
    START: 14,
    SELECT: 15,
}

interface GameRoomHintsProps {
    onClose: () => void
    autoClose?: boolean
}

const GameRoomHints: FC<GameRoomHintsProps> = ({
    onClose,
    autoClose = false,
}) => {
    const gameControls = useSelector(
        (state: State) => state.session.game.Controls
    )

    const language = getLanguage()
    const { t } = useTranslation()

    const buildHintList = (controllerType = ControllerType.KeyboardQWERTY) => {
        if (gameControls) {
            return (
                <ul className={style.hintsWrapper}>
                    {Object.entries(gameControls)
                        .filter(([, value]) => value.EN !== '')
                        .sort(([a], [b]) => HintSortMap[a] - HintSortMap[b])
                        .map(([key, value]) => (
                            <HintIcon
                                className={style.hint}
                                controllerType={controllerType}
                                buttonName={key}
                                label={value.EN} // TODO: Localize
                            />
                        ))}
                </ul>
            )
        }
    }

    return (
        <FloatingOverlay
            top={window.innerHeight - 385 - 8}
            left={window.innerWidth - 276 - 8}
            width={276}
            height={385}
            onClose={onClose}
            autoClose={autoClose}
        >
            <Carousel startIndex={language === 'FR' ? 1 : 0}>
                <Carousel.Item header={t('Qwerty')} index={0}>
                    {buildHintList()}
                </Carousel.Item>
                <Carousel.Item header={t('Azerty')} index={1}>
                    {buildHintList(ControllerType.KeyboardAZERTY)}
                </Carousel.Item>
                <Carousel.Item header="Nintendo" index={2}>
                    {buildHintList(ControllerType.Nintendo)}
                </Carousel.Item>
                <Carousel.Item header="XBox" index={3}>
                    {buildHintList(ControllerType.Xbox)}
                </Carousel.Item>
                <Carousel.Item header="PlayStation" index={4}>
                    {buildHintList(ControllerType.Playstation)}
                </Carousel.Item>
            </Carousel>
        </FloatingOverlay>
    )
}

export default GameRoomHints
