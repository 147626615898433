import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ESLDetails({ selectedDropdownItem }) {
    const { t } = useTranslation()

    return (
        <div className="ESLDetails">
            <div className="ESLSpecialEventContainer">
                <div className="ESLSpecialEventTitle">{t('SpecialEvent')}</div>
                <div className="ESLSpecialEventSubTitle">
                    Piepacker
                    {selectedDropdownItem.logoImg && (
                        <>
                            +
                            <img
                                src={
                                    'https://fassets.piepacker.com/esl-assets/' +
                                    selectedDropdownItem.logoImg +
                                    '.png'
                                }
                                alt=""
                                title=""
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="ESLTodayEventContainer">
                <img
                    src={
                        'https://fassets.piepacker.com/esl-assets/' +
                        selectedDropdownItem.todayImg +
                        '.png'
                    }
                    alt=""
                    title=""
                />
                <div className="ESLTodayEventTitle">
                    {t(selectedDropdownItem.title)}
                </div>
            </div>
        </div>
    )
}
