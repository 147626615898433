import React, {
    FC,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { useConferenceSettings } from '../../conference/hooks'
import { VideoPreview } from '../Video'
import { State } from '../../store/types'
import { isMobile } from 'react-device-detect'
import style from './style.module.scss'
import { Button, Icon } from '../../stories'
interface GameLobbyVideoProps {
    setSettingsOpen: Dispatch<SetStateAction<boolean>>
    showAvatar?: boolean
}

const GameLobbyVideo: FC<GameLobbyVideoProps> = ({
    setSettingsOpen,
    showAvatar = false,
}) => {
    const {
        isCameraAvailable,
        isMicrophoneAvailable,
        isCameraGranted,
        isMicrophoneGranted,
    } = useSelector((state: State) => state.conference)

    const {
        isCameraActive,
        toggleIsCameraActive,
        isMicrophoneActive,
        toggleIsMicrophoneActive,
    } = useConferenceSettings()

    useEffect(() => {
        const cameraActive = isCameraGranted && isCameraAvailable
        analytics.track('cameraActive', { cameraActive: cameraActive })
    }, [isCameraGranted, isCameraAvailable])

    // handleTopSettingsClick will display the Settings Modal on click
    const handleTopSettingsClick = useCallback(() => {
        setSettingsOpen(true)
    }, [setSettingsOpen])

    return (
        <div className="vc-container">
            <VideoPreview
                className={isMobile ? style.videoMobile : 'video'}
                showAvatar={showAvatar}
            />
            <div className={style.bottomSettings}>
                <Button
                    className={style.menuButton}
                    aria-label="Camera"
                    variant="round"
                    color="secondary"
                    data-test="LobyCamera"
                    disabled={!isCameraGranted || !isCameraAvailable}
                    onClick={() => {
                        if (isCameraAvailable) toggleIsCameraActive()
                    }}
                >
                    {isCameraActive && isCameraAvailable ? (
                        <Icon name="cam" />
                    ) : (
                        <Icon name="camOff" />
                    )}
                </Button>
                <Button
                    className={style.menuButton}
                    aria-label="Microphone"
                    variant="round"
                    color="secondary"
                    data-test="LobyMicrophone"
                    disabled={!isMicrophoneGranted || !isMicrophoneAvailable}
                    onClick={() => {
                        if (isMicrophoneAvailable) toggleIsMicrophoneActive()
                    }}
                >
                    {isMicrophoneActive && isMicrophoneAvailable ? (
                        <Icon name="mic" />
                    ) : (
                        <Icon name="micOff" data-test="LobyMicrophoneIconOff" />
                    )}
                </Button>
                {!isMobile && (
                    <Button
                        className={style.menuButton}
                        aria-label="Settings"
                        data-test="LobySettings"
                        variant="round"
                        color="secondary"
                        onClick={handleTopSettingsClick}
                    >
                        <Icon name="settings" />
                    </Button>
                )}
            </div>
        </div>
    )
}

export default GameLobbyVideo
