import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { State } from '../../../store/types'
import { Button, Icon, Modal } from '../../../stories'
import style from './style.module.scss'
import { usePausePlay } from '../../../utils/GameRoomHooks'

interface SavestatesProps {
    setSection: (x: string) => void
    itemsList: number[]
    loadGame: (x) => void
    gameName: string
    user: firebase.User | null
    refresh: () => void
    sliderLoading: boolean
    setShowOptions: (state) => void
}

export const Savestates: FC<SavestatesProps> = ({
    setSection,
    itemsList,
    loadGame,
    gameName,
    user,
    refresh,
    sliderLoading,
    setShowOptions,
}) => {
    const savestates = useSelector((state: State) => state.session.saveStates)
    const [saveState, setSaveState] = useState('')
    const { t } = useTranslation()
    const { handleResumeGame } = usePausePlay()

    const [show, setShow] = useState(false)
    const ModalFooter = (
        <Button color="secondary" size="large" onClick={handleDelete}>
            {t('Delete')}
        </Button>
    )

    const handleSection = () => {
        handleResumeGame()
        setSection('pause')
    }

    const handleLoad = (name) => {
        loadGame(name)
    }

    const remove = useCallback(async () => {
        const storageRef = firebase.storage().ref()

        const savestatesRef = storageRef.child(
            `/user/${user.uid}/savestates/${gameName}/${saveState}.state`
        )
        const screenshotsRef = storageRef.child(
            `/user/${user.uid}/screenshots/${gameName}/${saveState}.png`
        )

        await savestatesRef.delete()
        await screenshotsRef.delete()
        refresh()
    }, [saveState, user.uid, gameName, refresh])

    const handleRemove = (item) => {
        setSaveState(item.name)
        setShow(true)
    }

    function handleDelete() {
        remove()
        setShow(false)
    }

    return (
        <div className={style.loadContainer}>
            <div>
                <Button variant="round" onClick={handleSection}>
                    <Icon name="arrowBack" />
                </Button>
            </div>

            <div className={style.sliderContainer}>
                {sliderLoading ? (
                    <div className={style.loading}>
                        <svg
                            className={style.loadingSpinner}
                            height="100"
                            width="100"
                            viewBox="0 0 100 100"
                        >
                            <circle
                                className="loader-svg animate"
                                cx="50"
                                cy="50"
                                r="45"
                            ></circle>
                        </svg>
                        <span className={style.loadingText}>
                            {t('LoadingSaves')}
                        </span>
                    </div>
                ) : (
                    <div className={style.slider}>
                        {itemsList?.map((i) => {
                            return (
                                <div>
                                    <img
                                        onClick={() => {
                                            handleSection()
                                            handleLoad(savestates[i].name)
                                            setShowOptions(false)
                                        }}
                                        src={savestates[i].url}
                                        alt={savestates[i].name}
                                    />
                                    <Icon
                                        name="delete"
                                        onClick={() =>
                                            handleRemove(savestates[i])
                                        }
                                    />
                                    <p className={style.date}>
                                        {savestates[i].date}
                                    </p>
                                    <p className={style.time}>
                                        {savestates[i].time}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <Modal
                size="lg"
                show={show}
                onClose={() => setShow(false)}
                title={t('SaveDelete')}
                footer={ModalFooter}
            >
                <p>{t('SaveDeleteTitle')}</p>
            </Modal>
        </div>
    )
}
