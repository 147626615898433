import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { HighlightScreenStatus, SessionType } from '../../state/screen'
import { ScreenType } from '../../components/Screen/screen-props'

export enum ScreenEvent {
    Saved,
    SaveFailed,
    Loaded,
    Paused,
    Resumed,
    Error,
    WebGLContextLoss,
}

export interface ScreenState {
    events: ScreenEvent[]
    // highlightScreenStatus indicates the presence of the highlight screen, which super imposes over
    // the game screen in case of status changes, such as disconnections.
    highlightScreenStatus: HighlightScreenStatus
    soundOutputActivated: boolean
    screenType: ScreenType
    sessionType: SessionType
    openReload: boolean
    webGLContextLost: boolean
    theater: boolean
    fullscreen: boolean
    gameLoading: boolean
    startGame: boolean
}

export const initialState = {
    events: [],
    highlightScreenStatus: HighlightScreenStatus.NotHighlighting,
    soundOutputActivated: true,
    screenType: ScreenType.NONE,
    sessionType: SessionType.LOCAL,
    openReload: false,
    webGLContextLost: false,
    theater: false,
    fullscreen: false,
    startGame: false,
    gameLoading: false,
}

export const addScreenEvent = createAction<ScreenEvent>('AddScreenEvent')

const screenSlice = createSlice({
    name: 'screen',
    initialState: initialState,
    reducers: {
        addScreenEvent: (state, action) => {
            state.events = [...state.events, action.payload]
        },
        setHighlightScreenStatus: (
            state,
            action: PayloadAction<HighlightScreenStatus>
        ) => {
            state.highlightScreenStatus = action.payload
        },
        setSoundOutputActivated: (state, action: PayloadAction<boolean>) => {
            state.soundOutputActivated = action.payload
        },
        setScreenType: (state, action: PayloadAction<ScreenType>) => {
            state.screenType = action.payload
        },
        setSessionType: (state, action: PayloadAction<SessionType>) => {
            state.sessionType = action.payload
        },
        setOpenReload: (state, action: PayloadAction<boolean>) => {
            state.openReload = action.payload
        },
        setWebGLContextLost: (state, action: PayloadAction<boolean>) => {
            state.webGLContextLost = action.payload
        },
        setTheater: (state, action: PayloadAction<boolean>) => {
            state.theater = action.payload
        },
        setFullscreen: (state, action: PayloadAction<boolean>) => {
            state.fullscreen = action.payload
        },
        setGameLoading: (state, action: PayloadAction<boolean>) => {
            state.gameLoading = action.payload
        },
        setStartGame: (state, action: PayloadAction<boolean>) => {
            state.startGame = action.payload
        },
    },
})

export type ScreenAction =
    | ReturnType<typeof screenSlice.actions.addScreenEvent>
    | ReturnType<typeof screenSlice.actions.setHighlightScreenStatus>
    | ReturnType<typeof screenSlice.actions.setSoundOutputActivated> // XX
    | ReturnType<typeof screenSlice.actions.setScreenType>
    | ReturnType<typeof screenSlice.actions.setSessionType>
    | ReturnType<typeof screenSlice.actions.setOpenReload>
    | ReturnType<typeof screenSlice.actions.setWebGLContextLost> // XX
    | ReturnType<typeof screenSlice.actions.setTheater>
    | ReturnType<typeof screenSlice.actions.setFullscreen>
    | ReturnType<typeof screenSlice.actions.setGameLoading> // XX
    | ReturnType<typeof screenSlice.actions.setStartGame>

export default screenSlice
