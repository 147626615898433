export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CONFIRM_MODAL_BAN = 'CONFIRM_MODAL_BAN'
export const CONFIRM_MODAL_MUTE = 'CONFIRM_MODAL_MUTE'
export const CONFIRM_MODAL_RESET = 'CONFIRM_MODAL_RESET'

export interface ModalState {
    isOpen: boolean
    onClose: () => void | null
    message: string
    confirmModal?: any
}

export interface OpenModalProps {
    onClose: () => void
    message: string
}

export interface OpenModalAction {
    type: typeof OPEN_MODAL
    payload: OpenModalProps
}

export interface CloseModalAction {
    type: typeof CLOSE_MODAL
}

export interface ConfirmModalBanAction {
    type: typeof CONFIRM_MODAL_BAN
    payload: {
        UID: string
        UserSessionId: string
        nickname?: string
    }
}

export interface ConfirmModalMuteAction {
    type: typeof CONFIRM_MODAL_MUTE
    payload: {
        id: string
    }
}

export interface ConfirmModalResetAction {
    type: typeof CONFIRM_MODAL_RESET
}

export type ModalActionTypes =
    | OpenModalAction
    | CloseModalAction
    | ConfirmModalBanAction
    | ConfirmModalMuteAction
    | ConfirmModalResetAction
