import React, { useState } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useModalFilters } from './useModalFilters'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Modal, ToggleButton } from '../../../stories'
import Dropdown from './Dropdown'
import Tags from './Tags'

const NUMBER_OF_PLAYERS = [1, 2, 3, 4]

const MoreFilters = ({ gameTags }) => {
    const { t } = useTranslation()
    const {
        handleSave,
        handleReset,
        isModalVisible,
        handleModalClose,
        tempSelectedTags,
        setTempSelectedTags,
        setTempOpenRoom,
        tempOpenRoom,
        tempNumberOfPlayers,
        setTempNumberOfPlayers,
    } = useModalFilters()
    const [inputValue, setInputValue] = useState('')

    return (
        <Modal
            show={isModalVisible}
            className={styles.modal}
            contentClassName={styles.modalContent}
        >
            <div className={styles.modalBody}>
                <div className={styles.modalDropdownContainer}>
                    <div
                        className={styles.modalItems}
                        style={{ marginBottom: 0 }}
                    >
                        <label>{t('HomepageFiltersTags')}</label>
                        <Dropdown
                            inputValue={inputValue}
                            onInputChange={(_, value) => setInputValue(value)}
                            value={''}
                            options={gameTags}
                            onClose={(_) => setInputValue('')}
                            placeholder={t('HomePageSelectTags')}
                            getOptionLabel={(option) => t(option)}
                            onChange={(_, newValue) =>
                                !tempSelectedTags.includes(newValue) &&
                                setTempSelectedTags([
                                    ...tempSelectedTags,
                                    newValue,
                                ])
                            }
                        />
                    </div>
                    {tempSelectedTags.length > 0 && (
                        <div className={styles.modalSelectedTags}>
                            {tempSelectedTags.map((tag) => (
                                <Tags
                                    key={tag}
                                    tag={tag}
                                    selectedTags={tempSelectedTags}
                                    handleSelectedTags={setTempSelectedTags}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.modalItems}>
                    <label>{t('HomepageFiltersNumberofPlayers')}</label>
                    <Dropdown
                        className={styles.playerSelect}
                        value={tempNumberOfPlayers}
                        options={NUMBER_OF_PLAYERS}
                        getOptionLabel={(option) =>
                            option === 0
                                ? t('HomePageSelectPlayers')
                                : t('PlayerWithCount', { count: option })
                        }
                        onChange={(_, newValue) =>
                            setTempNumberOfPlayers(newValue)
                        }
                    />
                </div>
                <div className={styles.modalItems}>
                    <label>{t('HomepageFiltersOpenRoomsOnly')}</label>
                    <ToggleButton
                        size={'big'}
                        value={tempOpenRoom}
                        setValue={setTempOpenRoom}
                    />
                </div>
            </div>
            <div className={styles.modalFooterWrapper}>
                <div className={styles.modalFooter}>
                    <div
                        role="button"
                        onClick={handleReset}
                        className={styles.modalFooterTitle}
                    >
                        {t('HomepageFiltersClearFilters')}
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                block
                                type="submit"
                                className={classNames(
                                    'btn-large',
                                    styles.cancel
                                )}
                                onClick={handleModalClose}
                            >
                                {t('HomepageFiltersCancel')}
                            </Button>
                        </div>
                        <div>
                            <Button
                                block
                                type="submit"
                                className={classNames('btn-large')}
                                onClick={handleSave}
                            >
                                {t('HomepageFiltersDone')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MoreFilters
