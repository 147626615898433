import { isChrome } from 'react-device-detect'

export const getOS = () => {
    // linux? ios?
    let OSName = 'unknown'
    if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'win'
    else if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'mac'
    else if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'linux'
    else if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'android'
    return OSName
}

export const getBrowser = () => {
    let browserName = 'unknown'
    if (navigator.userAgent.indexOf('Firefox') !== -1) browserName = 'firefox'
    if (navigator.userAgent.indexOf('Chrome') !== -1) browserName = 'chrome'
    if (navigator.userAgent.indexOf('Edge') !== -1) browserName = 'edge'
    if (navigator.userAgent.indexOf('Version/') !== -1) browserName = 'safari'
    if (navigator.userAgent.indexOf('UCBrowser') !== -1) browserName = 'uc'
    return browserName
}

// canUseWebP:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
export function canUseWebP(callback: any, feature = 'lossy') {
    const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
            'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }
    const img = new Image()
    img.onload = function () {
        const result = img.width > 0 && img.height > 0
        callback(result, feature)
    }
    img.onerror = function () {
        callback(false, feature)
    }
    img.src = 'data:image/webp;base64,' + kTestImages[feature]
}

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
)

export const isChromewithH264 = () => {
    if (process.env.REACT_APP_ENV === 'DEV') return isChrome // Enable browsers like Chromium or Brave on DEV env

    // Enabling browsers like Chromium with h264 compatibility of Brave
    // References:
    // - https://github.com/Modernizr/Modernizr/blob/master/feature-detects/video.js
    // - https://stackoverflow.com/questions/7451635/how-to-detect-supported-video-formats-for-the-html5-video-tag
    const testEl = document.createElement('video')
    const h264 =
        '' !==
        (testEl.canPlayType('video/mp4; codecs="avc1.42E01E"') ||
            testEl.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"'))

    return isChrome && h264
}

export const isChromeTooOld = (minumumVersion = 69) => {
    if (!isChrome) return false

    const chromeVersion = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)

    return chromeVersion
        ? parseInt(chromeVersion[2], 10) < minumumVersion
        : false
}

export const isEnvProd = process.env.REACT_APP_ENV === 'PROD'
export const isEnvStaging = process.env.REACT_APP_ENV === 'STAGING'
export const isEnvDev = process.env.REACT_APP_ENV === 'DEV'
export const isPreview = !isEnvProd || process.env.REACT_APP_PREVIEW === 'true'

export const transactionHostnameMap = {
    DEV: `subscribe-dot-piepacker-staging.appspot.com`,
    STAGING: `subscribe-dot-piepacker-staging.appspot.com`,
    PROD: `subscribe-dot-esoteric-fx-261813.appspot.com`,
}

export const isMobileEnabled = Boolean(process.env.REACT_APP_MOBILEENABLED)
