import { useMemo } from 'react'
import { removeDuplicates } from '../../../utils'
import { TImmutableProcessGameList } from '../types'

const useGameTags: TImmutableProcessGameList<string[]> = (gamesList) => {
    return useMemo(
        () => removeDuplicates(gamesList.flatMap((g) => g.Tags)),
        [gamesList]
    )
}

export default useGameTags
