import cls from 'classnames'
import React, { FC, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MenuItem from './MenuItem'
import { menuItems } from './constants'
import styles from './styles.module.scss'
import { getUserAvatar } from '../../../UserSettings/state/selectors'
import { languages } from '../../../UserSettings/PlatformPreference/constants'
import { LocalStorage } from '../../../../state/storage'
import { Link } from 'react-router-dom'
import { Icon } from '../../../../stories'
import alertSlice from '../../../../store/alert/alert'
import { AlertType } from '../../../../state/alert'
import { logoutUser } from '../../../../firebase'
import store from '../../../../store'

const Desktop: FC = () => {
    const { t, i18n } = useTranslation()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const avatar = useSelector(getUserAvatar)
    const preferredLanguage = localStorage.getItem(LocalStorage.i18nextLng)

    const languageData = Object.values(languages)

    const onError = useCallback((error: string) => {
        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Error,
                message: error,
                autoClose: false,
            })
        )
    }, [])
    const onLogout = useCallback(async () => {
        await logoutUser(onError)
    }, [onError])

    return (
        <aside
            className={cls(styles.aside, { [styles.expanded]: isExpanded })}
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
        >
            <div className={cls(styles.menu)}>
                <ul>
                    {menuItems.map((item) => (
                        <MenuItem {...item} key={item.link} />
                    ))}
                </ul>
                <div className={styles.menuItemFooter}>
                    <Link to="/settings/UserProfile">
                        <Avatar
                            src={avatar}
                            className={cls(styles.menuItemAvatar)}
                        />
                    </Link>
                    <div className={styles.menuItemLanguage}>
                        {isExpanded ? (
                            languageData.map(({ code }) => (
                                <span
                                    className={cls({
                                        [styles.selected]:
                                            preferredLanguage === code,
                                    })}
                                    key={code}
                                    onClick={() => {
                                        i18n.changeLanguage(code)
                                        analytics.track('Set Language', {
                                            Language: code,
                                        })
                                    }}
                                >
                                    {code.toUpperCase()}
                                </span>
                            ))
                        ) : (
                            <span className={styles.selected}>
                                {preferredLanguage?.toUpperCase()}
                            </span>
                        )}
                    </div>
                    <div
                        className={cls(styles.menuItem, styles.menuItemLogout)}
                        onClick={onLogout}
                    >
                        <div className={styles.menuItemIcon} role="img">
                            <Icon name={'logout'} />
                        </div>
                        <div className={cls(styles.menuItemContent)}>
                            {t('Logout')}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Desktop
