export enum ConfirmDialog {
    None,
    Reset,
    Quit,
    Error,
    CloseAccount,
    BYOGWrongType,
    BYOGTooLarge,
    BYOGUploadFailed,
    SaveDelete,
    Override,
    OverridePremium,
    Ban,
    Mute,
    SwitchGame,
}

export interface ConfirmModalsProps {
    confirmDialog: ConfirmDialog
    open: boolean
    username?: string
    onClose: () => void
    onValidate?: () => void
    onConfirm?: (values) => void
    container?: HTMLElement
    // for displaying game name to be switched
    gameTitle?: string
}
