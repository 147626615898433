import styles from './styles.module.scss'
import React, { useCallback } from 'react'
import { Container } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Menu from './Menu'
import { MenuItems } from './types'
import UserProfile from './UserProfile'
import PlanPayments from './PlanPayments'
import PrivacySafety from './PrivacySafety'
import { Icon } from '../../stories'
import PlatformPreference from './PlatformPreference'
import Connections from './Connections'
import * as userActions from '../../store/user/actions'
import { getUserExtra } from '../../firebase'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { piepie } from '../../utils'
import { useDispatch } from 'react-redux'

enum Logs {
    UserRefreshed = '[ACCOUNT] user refreshed',
}

const menuItems = Object.values(MenuItems)

const UserSettings: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { tabId } = useParams<{ tabId: MenuItems }>()

    const refreshUser = useCallback(() => {
        const user = firebase.auth().currentUser
        dispatch(userActions.setUser(user))
        getUserExtra(user.uid).then((user) => {
            dispatch(userActions.setUserExtra(user))
        })
        piepie.log(Logs.UserRefreshed)
    }, [dispatch])

    const handleBackClick = () => history.push('/')

    return (
        <div data-test="SettingsPage" className={styles.userSettings}>
            <Menu menus={menuItems} currentMenu={tabId} />
            <Container maxWidth="md" className={styles.menuContainer}>
                <div className={styles.close} onClick={handleBackClick}>
                    <div className={styles.closeIcon}>
                        <Icon name="close" />
                    </div>
                    <p className={styles.closeMessage}>{t('BackToHome')}</p>
                </div>
                {tabId === MenuItems.UserProfile && (
                    <UserProfile refreshUser={refreshUser} />
                )}
                {tabId === MenuItems.PlanPayments && <PlanPayments />}
                {tabId === MenuItems.PrivacySafety && <PrivacySafety />}
                {tabId === MenuItems.PlatformPreference && (
                    <PlatformPreference refreshUser={refreshUser} />
                )}
                {tabId === MenuItems.Connections && (
                    <Connections refreshUser={refreshUser} />
                )}
            </Container>
        </div>
    )
}

export default UserSettings
