import { GameLobbyErrorType } from '../../state/alert'
import {
    Game,
    SessionFromFirebase,
    UserExtraFromFirebase,
    UserSessionFromFirebase,
} from '../../state/starfox'
import { Device, LocalPlayer } from '../../handlers/InputHandler'
import { SaveStatesType } from '../../state/saveStates'
import { NetworkQuality } from '../../pages/Game/hooks'

export enum SessionActiveEnum {
    No = 0, // means we know for sure that there is no active session
    Maybe, // we know there once was a session with this id, but don't know if still active
    Yes, // session exists and is active. We don't know if the user can access the session yet.
    YesWS, // session exists and is active. Websocket connection also exist.
    Failed, // session has started but is now in a failed state
    CannotConnect, // don't know, cannot connect
    Forbidden, // received 403 from the server. Usually means no id token.
}

export enum GAME_STATE {
    LOADING = 'LOADING',
    READY = 'READY',
    PAUSED = 'PAUSED',
    PLAYING = 'PLAYING',
    SELECTING = 'SELECTING',
}

export type PlayerIndex = { UserSessionID: string; PlayerIdx: number[] }
export type PlayerIndices = { [userSessionID: string]: number[] }
export type Players = { [index: string]: UserExtraFromFirebase }

export interface SessionState {
    gameName: string
    sessionID: string
    userSessionID: string
    cluster: string
    session: SessionFromFirebase
    userSession: UserSessionFromFirebase
    conferenceURL: string
    // isHost should be:
    //  - true if a session exists and the user is this session's originator
    //  - false if a session exists and the user is not the session's originator
    //  - undefined otherwise
    isHost: boolean
    playerIndices: PlayerIndices
    devices: Device[]
    localPlayers: LocalPlayer[]
    networkQuality: NetworkQuality
    byog: boolean
    gameCore: string
    gameFile: string
    game: Game
    originalFileName: string
    players: Players
    isESL: boolean
    lobbyError: GameLobbyErrorType
    disableSavestates: boolean
    gameState: GAME_STATE
    sessionActive: SessionActiveEnum
    saveStates: SaveStatesType[]
    isSavedState: boolean
}

export enum SessionActionType {
    clearSession = 'SESSION:CLEAR_SESSION',
    setGameName = 'SESSION:SET_GAME_NAME',
    setSessionId = 'SESSION:SET_SESSION_ID',
    setCluster = 'SESSION:SET_CLUSTER',
    setUserSessionId = 'SESSION:SET_USER_SESSION_ID',
    setConferenceUrl = 'SESSION:SET_CONFERENCE_URL',
    setSession = 'SESSION:SET_SESSION',
    setUserSession = 'SESSION:SET_USER_SESSION',
    setIsHost = 'SESSION:SET_IS_HOST',
    setPlayerIndices = 'SESSION:SET_PLAYER_INDICES',
    SetDevices = 'SESSION:SET_DEVICES',
    SetLocalPlayers = 'SESSION:SET_LOCAL_PLAYERS',
    SetNetworkQuality = 'SESSION:SET_NETWORK_QUALITY',
    SetBYOG = 'SESSION:SET_BYOG',
    SetGameCore = 'SESSION:SET_GAME_CORE',
    SetGameFile = 'SESSION:SET_GAME_FILE',
    SetOriginalFileName = 'SESSION:SET_ORIGINAL_FILE_NAME',
    setPlayer = 'SESSION:SET_PLAYER',
    removePlayer = 'SESSION:REMOVE_PLAYER',
    setIsESL = 'SESSION:SET_IS_ESL',
    setGame = 'SESSION:SET_GAME',
    setDisableSavestates = 'SESSION:SET_DISABLE_SAVESTATES',
    setGameStateSelecting = 'SESSION:SET_GAME_STATE_SELECTING',
    setGameStatePaused = 'SESSION:SET_GAME_STATE_PAUSED',
    setGameStateLoading = 'SESSION:SET_GAME_STATE_LOADING',
    setGameStateReady = 'SESSION:SET_GAME_STATE_READY',
    setSessionActive = 'SESSION:SET_SESSION_ACTIVE',
    setSaveStates = 'SESSION:SET_SAVE_STATES',
    setIsSavedState = 'SESSION:SET_IS_SAVED',
    setLobbyError = 'SESSION:SET_LOBBY_ERROR',
}

export type ClearSessionAction = {
    type: SessionActionType.clearSession
}

export type SetGameNameAction = {
    type: SessionActionType.setGameName
    payload: string
}

export type SetClusterAction = {
    type: SessionActionType.setCluster
    payload: string
}

export type SetSessionIdAction = {
    type: SessionActionType.setSessionId
    payload: string
}

export type SetUserSessionIdAction = {
    type: SessionActionType.setUserSessionId
    payload: string
}

export type SetConferenceUrlAction = {
    type: SessionActionType.setConferenceUrl
    payload: string
}

export type SetSessionAction = {
    type: SessionActionType.setSession
    payload: any
}

export type SetUserSessionAction = {
    type: SessionActionType.setUserSession
    payload: any
}

export type SetIsHostAction = {
    type: SessionActionType.setIsHost
    payload: boolean
}

export type SetOriginalFileNameAction = {
    type: SessionActionType.SetOriginalFileName
    payload: string
}

export type SetPlayerIndicesAction = {
    type: SessionActionType.setPlayerIndices
    payload: PlayerIndex[]
}

export type SetDevices = {
    type: SessionActionType.SetDevices
    payload: Device[]
}

export type SetLocalPlayers = {
    type: SessionActionType.SetLocalPlayers
    payload: LocalPlayer[]
}

export type SetNetworkQuality = {
    type: SessionActionType.SetNetworkQuality
    payload: NetworkQuality
}

export type SetBYOG = {
    type: SessionActionType.SetBYOG
    payload: boolean
}

export type SetGameCore = {
    type: SessionActionType.SetGameCore
    payload: string
}

export type SetGameFile = {
    type: SessionActionType.SetGameFile
    payload: string
}

export type SetPlayer = {
    type: SessionActionType.setPlayer
    uid: string
    payload: UserExtraFromFirebase
}

export type RemovePlayer = {
    type: SessionActionType.removePlayer
    uid: string
}

export type SetIsESL = {
    type: SessionActionType.setIsESL
    payload: boolean
}

export type SetGameAction = {
    type: SessionActionType.setGame
    payload: Game
}

export type SetDisableSavestates = {
    type: SessionActionType.setDisableSavestates
    payload: boolean
}

export type SetGameStateSelecting = {
    type: SessionActionType.setGameStateSelecting
}
export type setGameStatePaused = {
    type: SessionActionType.setGameStatePaused
}
export type SetGameStateLoading = {
    type: SessionActionType.setGameStateLoading
}
export type SetGameStateReady = {
    type: SessionActionType.setGameStateReady
}
export type SetSessionActiveAction = {
    type: SessionActionType.setSessionActive
    payload: SessionActiveEnum
}
export type SetLobbyError = {
    type: SessionActionType.setLobbyError
    payload: GameLobbyErrorType
}

export type SetSaveStatesAction = {
    type: SessionActionType.setSaveStates
    payload: SaveStatesType[]
}

export type SetIsSavedStateAction = {
    type: SessionActionType.setIsSavedState
    payload: boolean
}

export type SessionAction =
    | ClearSessionAction
    | SetGameNameAction
    | SetClusterAction
    | SetSessionIdAction
    | SetUserSessionIdAction
    | SetConferenceUrlAction
    | SetSessionAction
    | SetUserSessionAction
    | SetIsHostAction
    | SetPlayerIndicesAction
    | SetDevices
    | SetLocalPlayers
    | SetNetworkQuality
    | SetBYOG
    | SetGameCore
    | SetGameFile
    | SetPlayer
    | RemovePlayer
    | SetIsESL
    | SetGameAction
    | SetDisableSavestates
    | SetGameStateSelecting
    | setGameStatePaused
    | SetGameStateLoading
    | SetGameStateReady
    | SetSessionActiveAction
    | SetSaveStatesAction
    | SetLobbyError
    | SetIsSavedStateAction
    | SetOriginalFileNameAction
