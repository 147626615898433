import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Controller from './Controller'
import Subscription from './Subscription'
import RegionLanguage from './RegionLanguage'

interface IPlatformPreference {
    refreshUser: () => void
}

const PlatformPreference: React.FC<IPlatformPreference> = ({ refreshUser }) => {
    const { t } = useTranslation()

    return (
        <>
            <p className={styles.pageTitle}>{t('PlatformPreference')}</p>
            <RegionLanguage refreshUser={refreshUser} />
            <div className={styles.ruler}></div>
            <Subscription refreshUser={refreshUser} />
            <div className={styles.ruler}></div>
            <Controller refreshUser={refreshUser} />
        </>
    )
}

export default PlatformPreference
