import React, { FC, ReactNode } from 'react'
import style from './style.module.scss'
import { Button, Icon } from '../..'
import cls from 'classnames'

// ----------------------------------------------------------------------
// Local Player Menu
// ----------------------------------------------------------------------

interface LocalMenuProps {
    onSettingsDialogOpen: () => void
    isCameraGranted: boolean
    isCameraAvailable: boolean
    isCameraActive: boolean
    toggleIsCameraActive: () => void
    isMicrophoneGranted: boolean
    isMicrophoneAvailable: boolean
    isMicrophoneActive: boolean
    toggleIsMicrophoneActive: () => void
}

export const LocalMenu: FC<LocalMenuProps> = ({
    onSettingsDialogOpen,
    isCameraGranted,
    isCameraAvailable,
    isCameraActive,
    toggleIsCameraActive,
    isMicrophoneGranted,
    isMicrophoneAvailable,
    isMicrophoneActive,
    toggleIsMicrophoneActive,
}) => {
    return (
        <div className={style.conferenceTileMenu}>
            <Button
                className={style.menuButton}
                aria-label="Camera"
                variant="round"
                color="secondary"
                disabled={!isCameraGranted || !isCameraAvailable}
                onClick={() => {
                    if (isCameraAvailable) toggleIsCameraActive()
                }}
            >
                {isCameraActive && isCameraAvailable ? (
                    <Icon name="cam" />
                ) : (
                    <Icon name="camOff" />
                )}
            </Button>

            <Button
                className={style.menuButton}
                aria-label="Microphone"
                variant="round"
                color="secondary"
                disabled={!isMicrophoneGranted || !isMicrophoneAvailable}
                onClick={() => {
                    if (isMicrophoneAvailable) toggleIsMicrophoneActive()
                }}
            >
                {isMicrophoneActive && isMicrophoneAvailable ? (
                    <Icon name="mic" />
                ) : (
                    <Icon name="micOff" />
                )}
            </Button>
            <Button
                className={style.menuButton}
                aria-label="Settings"
                variant="round"
                color="secondary"
                onClick={onSettingsDialogOpen}
            >
                <Icon name="settings" />
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------
// Local Guest Player Menu
// ----------------------------------------------------------------------

interface LocalGuestMenuProps {
    onRemove: (playerIndex: number) => void
    playerIndex: number
}

export const LocalGuestMenu: FC<LocalGuestMenuProps> = ({
    onRemove,
    playerIndex,
}) => {
    return (
        <div className={style.conferenceTileMenu}>
            <Button
                className={style.menuButton}
                aria-label="Remove"
                variant="round"
                color="secondary"
                data-test={`FaceLocalPlayerRemove${playerIndex + 1}`}
                onClick={() => onRemove(playerIndex)}
            >
                <Icon name="close" />
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------
// Remote Player Menu
// ----------------------------------------------------------------------

interface RemoteMenuProps {
    onBan: () => void
    isMuted: boolean
    onMute: () => void
    isHost: boolean
}

export const RemoteMenu: FC<RemoteMenuProps> = ({
    onBan,
    isMuted,
    onMute,
    isHost,
}) => {
    return (
        <div className={style.conferenceTileMenu}>
            {isHost && (
                <Button
                    className={style.menuButton}
                    aria-label="Ban"
                    variant="round"
                    color="secondary"
                    data-test="banButton"
                    onClick={onBan}
                >
                    <Icon name="remove" />
                </Button>
            )}

            <Button
                className={style.menuButton}
                aria-label="Mute"
                variant="round"
                color="secondary"
                disabled={isMuted}
                onClick={onMute}
            >
                {isMuted ? <Icon name="micOff" /> : <Icon name="mic" />}
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------
// Tile content
// ----------------------------------------------------------------------

interface ConferenceTileContentProps {
    children?: ReactNode
    placeholder?: string
    showPlaceholder?: boolean
}

export const ConferenceTileContent: FC<ConferenceTileContentProps> = ({
    children,
    placeholder,
    showPlaceholder = false,
}) => {
    return (
        <div className={cls(style.conferenceTileContent)}>
            {placeholder && showPlaceholder && (
                <img
                    src={placeholder}
                    alt="placeholder"
                    className={style.conferenceTilePlaceholder}
                />
            )}
            {children}
        </div>
    )
}

export interface ConferenceTileChildren {
    LocalMenu: FC<LocalMenuProps>
    LocalGuestMenu: FC<LocalGuestMenuProps>
    RemoteMenu: FC<RemoteMenuProps>
    Content: FC<ConferenceTileContentProps>
}

export type ConferenceContentType =
    | LocalMenuProps
    | LocalGuestMenuProps
    | RemoteMenuProps
    | ConferenceTileContentProps
