import { useCallback, useEffect, useMemo, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getAllGames, selectedGames } from '../firebase'
import { Game } from '../state/starfox'
import {
    piepie,
    isEnvProd,
    isLocalhost,
    isByogMode,
    shallowEqual,
    lastOpenRoomsCached,
    setLastOpenRoomsCached,
} from './index'
import { isPremium } from './subscription'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../store/types'
import { getOpenRooms } from '../donkey/donkey'
import { setOpenRooms, setOpenRoomsGames } from '../store/home/homeV2'
import compareDesc from 'date-fns/compareDesc'
import { coreToConsole } from '../components/PublicRoomsList/utils'

export const BYOG_TAG_NAME = 'BYOG'

const GAME_CATEGORIES = [
    'RecentlyPlayed',
    'Featured',
    'Latest',
    'BYOG',
    'Multiplayer',
    'Platformer',
    'Shooter',
    'Sports',
    'Fighting',
    'Puzzle',
    'Racing',
    'Indie',
    'AllGames',
]

export function useGamesList() {
    const [gamesList, setGamesList] = useState<Game[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        let isFetching = true
        const getGames = async () => {
            try {
                // 1) get all games fro db, filtered by availability and licensing
                const games: Game[] = []

                const snapshot = await getAllGames()

                if (!isFetching) return

                snapshot.forEach((doc) => {
                    const game = { id: doc.id, ...doc.data() } as Game
                    // NOTE: removing competition games here. if reuse is
                    // planned, consider extending
                    if (game.Available && !game.Competition) {
                        if (isEnvProd && !isLocalhost) {
                            if (game.Whitelist?.includes(window.location.host))
                                games.push(game)
                        } else {
                            games.push(game)
                        }
                    }
                })

                piepie.log('[HOME] number of games:', games.length)
                setGamesList(games)
                setIsLoading(false)
            } catch (e: unknown) {
                piepie.error(e)
            }
        }
        getGames()
        return () => {
            isFetching = false
        }
    }, [])
    return { gamesList, isLoading }
}

export function useRecentlyPlayedGames(games: ReadonlyArray<Game>) {
    const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<Game[]>([])
    useEffect(() => {
        let isFetching = true
        const getRecentlyPlayedGames = async () => {
            try {
                const selectedPlayedGames = await selectedGames(
                    firebase.auth().currentUser.uid
                )
                if (!isFetching) return
                const recentGames = games.filter(
                    (g: Game) =>
                        selectedPlayedGames
                            .map((game) => game.GameID)
                            .includes(g.id) && !isByogMode(g.id)
                )

                const recentGamesWithStoppedDate = recentGames.map((game) => ({
                    ...game,
                    Stopped: selectedPlayedGames.filter(
                        (selectedGame) => selectedGame.GameID === game.id
                    )[0]?.Stopped,
                }))
                const sortedRecentGames = [...recentGamesWithStoppedDate].sort(
                    (a, b) =>
                        // @ts-ignore
                        compareDesc(a.Stopped?.seconds, b.Stopped?.seconds)
                )
                piepie.log(
                    '[HOME] number of recently played games:',
                    recentGames.length
                )
                setRecentlyPlayedGames(sortedRecentGames)
            } catch (e: unknown) {
                piepie.error(e)
            }
        }
        getRecentlyPlayedGames()
        return () => {
            isFetching = false
        }
    }, [games])
    return recentlyPlayedGames
}

export function useGamesCategories(
    recentlyPlayedGames: Game[],
    categories: string[] = GAME_CATEGORIES
) {
    const { userRole, features } = useSelector((state: State) => ({
        userRole: state.user.extra ?? null,
        features: state.user.features,
    }))

    const gameCategories = useMemo(
        () =>
            categories.filter((cat) => {
                if (cat === 'RecentlyPlayed')
                    return recentlyPlayedGames.length > 0
                if (cat === 'BYOG') return isPremium(userRole) || features.BYOG
                else return true
            }),
        [userRole, features, recentlyPlayedGames.length, categories]
    )
    return gameCategories
}

export function useTrendingGames(games: ReadonlyArray<Game>) {
    const [trendingGames, setTrendingGames] = useState<Game[]>([])
    const dispatch = useDispatch()

    const getTrendingGames = useCallback(async () => {
        try {
            const roomsOpened = await getOpenRooms()
            const openRoomsByGame = {}
            for (const room of roomsOpened) {
                if (!room.IsCurrentBYOG) {
                    !openRoomsByGame[room.CurrentGame]
                        ? (openRoomsByGame[room.CurrentGame] = 1)
                        : openRoomsByGame[room.CurrentGame]++
                } else {
                    const system = coreToConsole(
                        room.Games[room.Games.length - 1]?.GameCore
                    )
                    !openRoomsByGame[system]
                        ? (openRoomsByGame[system] = 1)
                        : openRoomsByGame[system]++
                }
            }
            const openedRoomsGames = games.filter((game) => {
                for (const room of roomsOpened) {
                    if (room.CurrentGame === game.id) return true
                    else if (
                        room.IsCurrentBYOG &&
                        game.Categories?.includes('BYOG') &&
                        coreToConsole(
                            room.Games[room.Games.length - 1].GameCore
                        ) === game.id
                    )
                        return true
                }
                return false
            })
            // sorting from most to least open rooms
            openedRoomsGames.sort((a, b) => {
                return openRoomsByGame[b.id] - openRoomsByGame[a.id]
            })
            piepie.log(
                '[HOME] number of trending games:',
                openedRoomsGames.length
            )
            // if the roomsOpened are the same as previously, we won't update the store values
            if (shallowEqual(lastOpenRoomsCached, roomsOpened)) return
            setLastOpenRoomsCached(roomsOpened)
            dispatch(setOpenRooms(roomsOpened))
            dispatch(setOpenRoomsGames(openedRoomsGames))
            setTrendingGames(
                openedRoomsGames.length > 1 ? openedRoomsGames : []
            )
        } catch (e: unknown) {
            piepie.error(e)
        }
    }, [games, dispatch])

    useEffect(() => {
        let isFetching = true
        getTrendingGames()
        const interval = setInterval(() => {
            if (!isFetching) return
            getTrendingGames()
        }, 15000) //we refresh the open room list every 15s

        return () => {
            isFetching = false
            clearInterval(interval)
        }
    }, [getTrendingGames])
    return trendingGames
}

export function getAllGamesCategory(games: ReadonlyArray<Game>) {
    const allGames = games?.filter(
        (game) =>
            !game.Categories?.includes('BYOG') &&
            !game.Categories?.includes('Mobile-Featured') &&
            !game.Categories?.includes('Featured')
    )

    return allGames
}

export function getRecentlyAddedGamesCategory(games: ReadonlyArray<Game>) {
    const recentlyAdded = games?.filter((game) => game.IsNew)

    return recentlyAdded
}
