import { isMobile, isBrowser } from 'react-device-detect'
import { DeviceType } from '../state/device'

export const getDeviceType = () => {
    if (isMobile) {
        return DeviceType.Mobile
    }

    if (isBrowser) {
        return DeviceType.Desktop
    }
}
