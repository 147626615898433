import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Participant, Participants } from '../../../state/conference'
import { State } from '../../../store/types'
import { User } from '../../../state/faces'

export const useParticipants = (allUsers: { [index: string]: User }) => {
    const [participantList, setParticipantList] = useState([])

    const {
        participants,
    }: {
        participants: Participants<Participant>
    } = useSelector((state: State) => state.conference)

    const playersIndex = useSelector(
        (state: State) => state.session.playerIndices
    )

    const playersExtraData = useSelector(
        (state: State) => state.session.players
    )

    const localPlayers = useSelector(
        (state: State) => state.session.localPlayers
    )

    const localUSID = useSelector((state: State) => state.session.userSessionID)

    // Executed when player index or the participants object changes (slower execution time - executed rarely)
    useEffect(() => {
        const tempParticipantList = []
        const tempLocalPlayersList = []

        const isRemotePlayer = (index, usid) => {
            return localUSID !== usid && playersIndex[usid][0] === index
        }

        const isLocalhost = (index) => {
            if (localPlayers.length === 0) return true

            for (let i = 0; i < localPlayers.length; i++) {
                if (
                    localPlayers[i].localhost &&
                    localPlayers[i].index === index
                )
                    return true
            }
            return false
        }

        Object.entries(participants).forEach(([id, participant]) => {
            const currentUser = allUsers[participant.userId]
            const currentUserExtra = playersExtraData[currentUser?.UID]

            let index = undefined
            if (!playersIndex[participant.userId]) {
                index = undefined
            } else {
                index = playersIndex[participant.userId][0]
                let isLocal = false
                for (let i = 0; i < localPlayers.length; i++) {
                    if (index === localPlayers[i].index) {
                        isLocal = true
                        break
                    }
                }
                if (isLocal) {
                    for (let i = 0; i < localPlayers.length; i++) {
                        if (localPlayers[i].localhost) {
                            index = localPlayers[i].index
                            break
                        }
                    }
                }
            }

            // People with camera
            if (participant.isLocal) {
                tempParticipantList.push({
                    ...participant,
                    userData: { ...currentUser },
                    userExtraData: { ...currentUserExtra },
                    id,
                    position: -1, // Always in first position
                    playerIndex: index,
                    type: 'local',
                })
            } else {
                tempParticipantList.push({
                    ...participant,
                    userData: { ...currentUser },
                    userExtraData: { ...currentUserExtra },
                    id,
                    position: index,
                    playerIndex: index,
                    type: 'remote',
                })
            }

            // All the other local players associated to this participant (if any)
            playersIndex[participant.userId]?.forEach((playerIndex) => {
                if (
                    isLocalhost(playerIndex) ||
                    isRemotePlayer(playerIndex, participant.userId)
                ) {
                    return
                }

                tempLocalPlayersList.push({
                    ...participant,
                    userData: { ...currentUser, nickname: 'Local Guest' }, // TODO: Add a proper nickname
                    userExtraData: { ...currentUserExtra },
                    position: playerIndex,
                    playerIndex,
                    type: 'localGuest',
                })
            })
        })

        const sortedParticipants = [
            ...tempParticipantList,
            ...tempLocalPlayersList,
        ].sort((a, b) => {
            const aPosition = a.position === undefined ? Infinity : a.position
            const bPosition = b.position === undefined ? Infinity : b.position
            return aPosition - bPosition
        })

        setParticipantList(sortedParticipants)
    }, [
        participants,
        playersIndex,
        localPlayers,
        localUSID,
        allUsers,
        playersExtraData,
    ])

    return participantList
}
