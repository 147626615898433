import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { validateNickname } from '../../../utils'

Yup.addMethod(Yup.string, 'nickname', validateNickname)

export const useValidation = () => {
    const { t } = useTranslation()

    return Yup.object({
        email: Yup.string()
            .trim()
            .email(t('InvalidEmail'))
            .required(t('Required')),
        displayName: Yup.string()
            .trim()
            .required(t('Required'))
            .min(3, t('NicknameLengthError'))
            .max(12, t('NicknameLengthError'))
            .nickname(t('DisplayNameErrorMessage')),
        password: Yup.string()
            .required(t('NoPasswordProvided'))
            .min(8, t('PasswordTooShort'))
            .max(128, t('MaxPasswordLengthError'))
            .weakPassword(t('WeakPassword')),
        passwordConfirmation: Yup.string()
            .required(t('NoPasswordConfirmProvided'))
            .oneOf([Yup.ref('password'), null], t('PasswordsDontMatch')),
    })
}
