import { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../store/types'
import { usePausePlay } from './GameRoomHooks'
import { isGame } from './loading'

export default function useKeyboardPause() {
    const { gameState } = useSelector((state: State) => state.session)
    const { handleResumeGame, handlePauseGame } = usePausePlay()
    const keyDownHandler = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (isGame(gameState).idle) {
                    handleResumeGame()
                }
                if (isGame(gameState).ready) {
                    handlePauseGame()
                }
            }
        },
        [gameState, handlePauseGame, handleResumeGame]
    )
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler)

        return () => {
            window.removeEventListener('keydown', keyDownHandler)
        }
    }, [keyDownHandler])
}
