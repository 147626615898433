import LUTIdentity from '../assets/LUTs/identity.png'
import LUTGrayscale from '../assets/LUTs/grayscale.png'
import LUTSaturate from '../assets/LUTs/saturate.png'
import LUTInvert from '../assets/LUTs/invert.png'

// To create a new LUT, open ../assets/LUTs/identity.png
// Apply all color transforms you want and save it as a new PNG image

const LUTS = {
    grayscale: LUTGrayscale,
    saturate: LUTSaturate,
    invert: LUTInvert,
    identity: LUTIdentity,
}

const _fragmentShaderSource = `
uniform sampler2D uun_source, uun_LUT;

varying vec2 vUV;


vec4 lookup(vec4 textureColor, sampler2D lookupTable) {
  //textureColor = clamp(textureColor, 0.0, 1.0);

  mediump float blueColor = textureColor.b * 63.0;

  mediump vec2 quad1;
  quad1.y = floor(floor(blueColor) / 8.0);
  quad1.x = floor(blueColor) - (quad1.y * 8.0);

  mediump vec2 quad2;
  quad2.y = floor(ceil(blueColor) / 8.0);
  quad2.x = ceil(blueColor) - (quad2.y * 8.0);

  highp vec2 texPos1;
  texPos1.x = (quad1.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
  texPos1.y = (quad1.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

  // LUT flip Y:
  //texPos1.y = 1.0 - texPos1.y;

  highp vec2 texPos2;
  texPos2.x = (quad2.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
  texPos2.y = (quad2.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

  // LUT flip Y:
  //texPos2.y = 1.0-texPos2.y;

  lowp vec4 newColor1 = texture2D(lookupTable, texPos1);
  lowp vec4 newColor2 = texture2D(lookupTable, texPos2);

  lowp vec4 newColor = mix(newColor1, newColor2, fract(blueColor));
  return newColor;
}

void main(void){
  vec4 color = texture2D(uun_source, vUV);
  gl_FragColor = lookup(color, uun_LUT);

  // DEBUG ZONE
  //gl_FragColor = texture2D(uun_LUT, vUV);
}`

let _gl = null
let _glLUTTexture = null

const init = (threeHelper, videoPostprocessingSettings) => {
    // For debugging - force Video WebGL effect
    /*videoPostprocessingSettings = {
      //LUT: 'invert'
      LUT: 'saturate'
      //LUT: 'identity' // to debug -> rendering should not be affected by LUT
    }//*/

    if (!videoPostprocessingSettings) {
        threeHelper.set_defaultRenderVideoShader()
        if (_glLUTTexture) {
            _gl.deleteTexture(_glLUTTexture)
            _glLUTTexture = null
        }
        return
    }

    _gl = threeHelper.get_videoGL()

    // compile shader and use it as video postprocessing shader:
    const glLUTShaderProgram = threeHelper.update_renderVideoShader(
        _fragmentShaderSource
    )

    // set LUT texture sampler:
    _gl.useProgram(glLUTShaderProgram)
    const glUniformLUT = _gl.getUniformLocation(glLUTShaderProgram, 'uun_LUT')
    _gl.uniform1i(glUniformLUT, 1)

    // create WebGL LUT Texture:
    const img = new Image()
    img.onload = () => {
        _glLUTTexture = _gl.createTexture()
        _gl.bindTexture(_gl.TEXTURE_2D, _glLUTTexture)
        _gl.texImage2D(
            _gl.TEXTURE_2D,
            0,
            _gl.RGBA,
            _gl.RGBA,
            _gl.UNSIGNED_BYTE,
            img
        )
        _gl.texParameteri(_gl.TEXTURE_2D, _gl.TEXTURE_MAG_FILTER, _gl.NEAREST)
        _gl.texParameteri(_gl.TEXTURE_2D, _gl.TEXTURE_MIN_FILTER, _gl.NEAREST)
        _gl.bindTexture(_gl.TEXTURE_2D, null)
    }
    img.src = LUTS[videoPostprocessingSettings.LUT]
}

const update = () => {
    if (_glLUTTexture !== null) {
        _gl.activeTexture(_gl.TEXTURE1)
        _gl.bindTexture(_gl.TEXTURE_2D, _glLUTTexture)
    }
}

export default {
    init,
    update,
}
