import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SignedInState } from '../../state/app'
import { Game, SessionFromFirebase } from '../../state/starfox'
import { UnavailableReasonEnum } from '../../state/unavailable'
import { State } from '../types'

export type TSortOptions = 'Name' | 'Recent' | 'Popular' | ''

export type TSortBy = {
    kind: TSortOptions
}

export interface HomeState {
    search: string
    sortBy: TSortBy
    openRoom: boolean
    selectedTags: string[]
    numberofPlayers: number
    isAllFiltersVisible: boolean
    signedIn: SignedInState
    reason: UnavailableReasonEnum
    openRoomsGames: Game[]
    openRooms: SessionFromFirebase[]
    categoryDisplayed: string
}

export const initialState: HomeState = {
    search: '',
    selectedTags: [],
    openRoom: false,
    numberofPlayers: 0,
    isAllFiltersVisible: false,
    sortBy: { kind: '' },
    signedIn: SignedInState.NotSignedIn,
    reason: UnavailableReasonEnum.None,
    openRoomsGames: [],
    openRooms: [],
    categoryDisplayed: null,
}

const homeSlice = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        setSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload
        },
        setSelectedTags: (state, action: PayloadAction<string[]>) => {
            state.selectedTags = action.payload
        },
        setOpenRoom: (state, action: PayloadAction<boolean>) => {
            state.openRoom = action.payload
        },
        setNumberOfPlayers: (state, action: PayloadAction<number>) => {
            state.numberofPlayers = action.payload
        },
        setSortBy: (state, action: PayloadAction<TSortOptions>) => {
            state.sortBy.kind = action.payload
        },
        setAllFiltersVisibility: (state, action: PayloadAction<boolean>) => {
            state.isAllFiltersVisible = action.payload
        },
        setResetFilters: (state) => {
            state.search = initialState.search
            state.openRoom = initialState.openRoom
            state.selectedTags = initialState.selectedTags
            state.numberofPlayers = initialState.numberofPlayers
            state.categoryDisplayed = initialState.categoryDisplayed
        },
        setSignedIn: (state, action: PayloadAction<SignedInState>) => {
            state.signedIn = action.payload
        },
        setReason: (state, action: PayloadAction<UnavailableReasonEnum>) => {
            state.reason = action.payload
        },
        setOpenRoomsGames: (state, action: PayloadAction<Game[]>) => {
            state.openRoomsGames = action.payload
        },
        setOpenRooms: (state, action: PayloadAction<SessionFromFirebase[]>) => {
            state.openRooms = action.payload
        },
        setCategoryDisplayed: (state, action: PayloadAction<string>) => {
            state.categoryDisplayed = action.payload
        },
    },
})

// actions
export const {
    setSortBy,
    setSearch,
    setOpenRoom,
    setResetFilters,
    setSelectedTags,
    setNumberOfPlayers,
    setAllFiltersVisibility,
    setSignedIn,
    setReason,
    setOpenRoomsGames,
    setOpenRooms,
    setCategoryDisplayed,
} = homeSlice.actions

// selectors
export const selectSearch = (state: State) => state.homeV2.search
export const selectSelectedTags = (state: State) => state.homeV2.selectedTags
export const selectOpenRoom = (state: State) => state.homeV2.openRoom
export const selectNumberOfPlayers = (state: State) =>
    state.homeV2.numberofPlayers
export const selectAllFiltersVisibility = (state: State) =>
    state.homeV2.isAllFiltersVisible
export const selectSortBy = (state: State) => state.homeV2.sortBy.kind
export const selectSignedIn = (state: State) => state.homeV2.signedIn
export const selectReason = (state: State) => state.homeV2.reason
export const selectOpenRoomsGames = (state: State) =>
    state.homeV2.openRoomsGames
export const selectOpenRooms = (state: State) => state.homeV2.openRooms
export const selectCategoryDisplayed = (state: State) =>
    state.homeV2.categoryDisplayed

export const selectIsListModified = createSelector(
    selectSearch,
    selectSelectedTags,
    selectOpenRoom,
    selectNumberOfPlayers,
    selectSortBy,
    selectCategoryDisplayed,
    (
        search,
        selectedTags,
        openRoom,
        numberofPlayers,
        sortBy,
        categoryDisplayed
    ) =>
        openRoom ||
        !!search ||
        selectedTags.length > 0 ||
        numberofPlayers !== initialState.numberofPlayers ||
        sortBy !== initialState.sortBy.kind ||
        categoryDisplayed !== initialState.categoryDisplayed
)

export default homeSlice
