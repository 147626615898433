import React from 'react'
import { useTranslation } from 'react-i18next'

const RedeemFail: React.FC = () => {
    const { t } = useTranslation()
    return (
        <>
            <img
                className="redeemcode_modal_image"
                src="https://storage.googleapis.com/public-wagashi/random/Piepiemoji-LoudlyCrying.svg"
                alt=""
            />
            <p className="redeemcode_modal_description">
                {t('RedeemModalMessageFail')}
            </p>
        </>
    )
}

export default RedeemFail
