import style from './style.module.scss'
import React, {
    FC,
    Children,
    isValidElement,
    ReactNode,
    ReactElement,
} from 'react'
import cls from 'classnames'
import {
    ConferenceContentType,
    LocalMenu,
    LocalGuestMenu,
    RemoteMenu,
    ConferenceTileContent,
    ConferenceTileChildren,
} from './ConferenceTileContent'

type ConferenceTileVariant =
    | 'default'
    | 'square'
    | 'circle'
    | 'circleSmall'
    | 'circleLarge'

export interface ConferenceTileProps {
    className?: string
    id?: string
    nickname: string
    nicknameColor?: string
    playerIndex?: number
    variant?: ConferenceTileVariant
    children?: ReactNode
    onClick?: () => void
    highlighted?: boolean
}

const ConferenceTile: FC<ConferenceTileProps> & ConferenceTileChildren = ({
    className,
    id,
    children,
    nickname,
    nicknameColor,
    playerIndex,
    variant = 'default',
    onClick,
    highlighted,
}) => {
    let mainContent = undefined
    let menu = undefined

    const otherComponents = Children.map(children, (child) => {
        if (!isValidElement<ConferenceContentType>(child)) {
            return child // Render if it is another react component
        }

        const elementChild: ReactElement<ConferenceContentType> = child

        if (typeof elementChild.type === 'function') {
            switch (elementChild.type.name) {
                case ConferenceTileContent.name:
                    mainContent = elementChild
                    break
                case LocalMenu.name:
                case RemoteMenu.name:
                case LocalGuestMenu.name:
                    menu = elementChild
                    break
                default:
                    return elementChild
            }
        } else {
            // Other react component
            return elementChild
        }
    })

    return (
        <div
            className={cls(
                style.conferenceTile,
                style[variant],
                className,
                highlighted && style.highlighted
            )}
            key={id}
        >
            <div className={style.conferenceTileFaceWrapper}>
                {variant !== 'default' && variant !== 'square' && (
                    <button
                        className={style.menuToggle}
                        aria-label="Menu"
                        onClick={onClick}
                    />
                )}
                {mainContent}
                {otherComponents}
                {variant !== 'circleSmall' && variant !== 'circleLarge' && (
                    <span
                        key="nickname"
                        className={cls(style.nickname, style[nicknameColor])}
                    >
                        {nickname}
                    </span>
                )}
                {(variant === 'default' || variant === 'square') &&
                    (playerIndex !== undefined || playerIndex !== null) && (
                        <span key="playerIndex" className={style.playerIndex}>
                            P{playerIndex + 1}
                        </span>
                    )}
            </div>
            {(variant === 'default' || variant === 'square') && menu}
        </div>
    )
}

ConferenceTile.LocalMenu = LocalMenu
ConferenceTile.LocalGuestMenu = LocalGuestMenu
ConferenceTile.RemoteMenu = RemoteMenu
ConferenceTile.Content = ConferenceTileContent

export default ConferenceTile
