import styles from './style.module.scss'

import React, { FC } from 'react'
import cls from 'classnames'
import { useHistory } from 'react-router-dom'

import { Icon } from '../..'

interface BackButtonInterface {
    className?: string
    onClick?: () => void
}

const BackButton: FC<BackButtonInterface> = ({ className, onClick }) => {
    const history = useHistory()

    return (
        <button
            className={cls(className, styles.backButton)}
            onClick={() => (onClick ? onClick() : history.push('/'))}
        >
            <Icon
                width={24}
                height={24}
                name="arrowBack"
                className={styles.back}
            />
            <Icon
                width={24}
                height={24}
                name="piePie"
                className={styles.piepie}
            />
        </button>
    )
}

export default BackButton
