import React, { FC } from 'react'
import cls from 'classnames'
import style from './style.module.scss'
import {
    Button,
    ControlButtons,
    Icon,
    Joystick,
    Spacing,
} from '../../../stories'

import {
    joystickControlValues,
    getButtons,
    handleSelectControl,
    handleStartControl,
} from '../../../handlers/MobileInputHandler'
import { Keys } from '../../../state/Keys'
import store from '../../../store'

interface MobileGameControlsLandscapeProps {
    children?: React.ReactNode
    section: string
    handleShowChat: () => void
    handleOptions: (state) => void
    isDynamic: boolean
    showOptions: boolean
}

const MobileGameControlsLandscape: FC<MobileGameControlsLandscapeProps> = ({
    section,
    children,
    handleShowChat,
    handleOptions,
    isDynamic,
    showOptions,
}) => {
    const controls = store.getState().session.game.Controls

    return (
        <div className={cls(style.controlSection)}>
            <div
                className={cls(
                    style.controlGroup,
                    section === 'layout' && style.layout
                )}
            >
                <div>
                    <div
                        className={cls(
                            style.joystickContainer,
                            section === 'layout' && isDynamic && style.highlight
                        )}
                    >
                        <Joystick
                            controlGroup={joystickControlValues}
                            containerStyle={{
                                height: '200px',
                                background: 'transparent',
                            }}
                            position={{ left: '25%', top: '50%' }}
                            isDynamic={isDynamic}
                        />
                    </div>
                </div>

                <div className={style.gameSection}>{children}</div>
                <div
                    className={cls(
                        section === 'layout' && style.nodisplaybuttons
                    )}
                >
                    <ControlButtons
                        shoulderButtons={getButtons(controls, [
                            Keys.L1,
                            Keys.R1,
                            Keys.L2,
                            Keys.R2,
                        ])}
                        controlGroup={getButtons(controls, [
                            Keys.A,
                            Keys.B,
                            Keys.X,
                            Keys.Y,
                        ])}
                    />
                </div>
            </div>

            <div className={style.playPauseGroup}>
                <Button variant="mini" onClick={handleShowChat}>
                    <Icon name="chat" />
                </Button>
                <Spacing right="3" inline />

                <Button
                    variant="mini"
                    onClick={() => handleOptions((state) => !state)}
                    style={showOptions ? { border: '3px solid #8941EA' } : {}}
                >
                    <Icon name="menu" />
                </Button>

                <Spacing right="3" inline />

                <div className={style.startSelectGroup}>
                    {controls?.SELECT?.EN && (
                        <Button
                            variant="mini"
                            onTouchStart={handleSelectControl}
                            onTouchEnd={() => handleSelectControl(false)}
                        >
                            Select
                        </Button>
                    )}
                    {controls?.START?.EN && (
                        <Button
                            variant="mini"
                            onTouchStart={handleStartControl}
                            onTouchEnd={() => handleStartControl(false)}
                        >
                            Start
                        </Button>
                    )}
                    <Spacing right="3" inline />
                </div>
            </div>
        </div>
    )
}

export default MobileGameControlsLandscape
