// eslint-disable-next-line import/no-webpack-loader-syntax
import vertexShaderSource from '!!raw-loader!../shaders/animation2DFeature_vert.gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
import fragmentShaderSource from '!!raw-loader!../shaders/animation2DFeature_frag.gl'

// To make decryption work, we need to use the same (tweaked) THREE
// everywhere
//import * as THREE from 'three'
let THREE = null

const MIN_SIZE = 1.0
const EPSILON = 0.001
const DT_MAX = 0.7 // in seconds

const is_visible = (threeNode) => {
    let sGeom = 1.0 // sGeom is the base size of the screen geometry
    if (threeNode.geometry) {
        const bb = threeNode.geometry.boundingBox
        sGeom = Math.max(
            bb.max.x - bb.min.x,
            bb.max.y - bb.min.y,
            bb.max.z - bb.min.z
        )
    }

    // scaleBone is the scale applied by the skeleton (some masks are using a skeleton to change the scale)
    let scaleBone = 1.0
    if (threeNode.isSkinnedMesh && threeNode.skeleton.bones.length !== 0) {
        threeNode.skeleton.bones.forEach((bone) => {
            scaleBone = Math.min(
                scaleBone,
                bone.scale.x,
                bone.scale.y,
                bone.scale.z
            )
        })
    }

    // scale is the scale applied by rigid transforms
    const scale = Math.pow(threeNode.matrixWorld.determinant(), 0.33)

    // size is the total size of the screen
    const size = scaleBone * scale * sGeom

    return size > MIN_SIZE
}

const toggle_materialVisibility = (mat, isVisible) => {
    const opacity = isVisible ? 1.0 : 0.0
    if (mat.isMeshBasicMaterial) {
        mat.opacity = opacity
        return true
    }
    if (mat.uniforms && mat.uniforms.uAlphaFactor) {
        mat.uniforms.uAlphaFactor.value = opacity
        return true
    }
    return false
}

const get_bindToScreenFactors = (
    threeRenderer,
    threeGeometry,
    bindToScreenMode
) => {
    const factors = new THREE.Vector2(1.0, 1.0) // X,Y scale factors

    // get screen size:
    threeGeometry.computeBoundingBox()
    const bb = threeGeometry.boundingBox
    const screenWidth = bb.max.x - bb.min.x
    let screenHeight = bb.max.y - bb.min.y
    let screenAspectRatio = Math.abs(screenWidth / screenHeight)

    if (screenHeight === 0 || screenAspectRatio > 1.0 / EPSILON) {
        screenHeight = bb.max.z - bb.min.z
        screenAspectRatio = Math.abs(screenWidth / screenHeight)
    }
    if (screenHeight === 0 || screenAspectRatio > 1.0 / EPSILON) {
        throw new Error(
            'Error in Animation2DFeature - cannot estimate screen size'
        )
    }

    // get renderer size:
    const rendererSize = new THREE.Vector2()
    threeRenderer.getSize(rendererSize)
    const rendererAspectRatio = rendererSize.x / rendererSize.y

    console.log(
        'INFO in Animation2DFeature: bindToScreenMode = ',
        bindToScreenMode
    )
    //bindToScreenMode = 'width'

    switch (bindToScreenMode) {
        case 'width':
            factors.setY(rendererAspectRatio / screenAspectRatio) //TODO
            break
        case 'height':
            factors.setX(screenAspectRatio / rendererAspectRatio) //TODO
            break
        case 'stretch':
        default:
            // keep default value
            break
    }
    return factors
}

const setup_material = (
    threeNode,
    threeRenderer,
    isBindToScreen,
    bindToScreenMode
) => {
    //return // disable mat replacement

    threeNode.frustumCulled = false

    const mat = threeNode.material

    if (
        (!isBindToScreen && mat.isBasicMaterial) ||
        (isBindToScreen && mat.isShaderMaterial)
    ) {
        return
    }

    let newMat = null
    if (isBindToScreen) {
        const defines = {}
        const uniforms = {
            map: { value: mat.map },
            uvTransform: { value: mat.map.matrix },
            bindToScreenFactors: {
                value: get_bindToScreenFactors(
                    threeRenderer,
                    threeNode.geometry,
                    bindToScreenMode
                ),
            },
            uAlphaFactor: { value: 1.0 },
        }
        newMat = new THREE.ShaderMaterial({
            defines,
            uniforms,
            lights: false,
            depthWrite: mat.depthWrite,
            depthTest: mat.depthTest,
            side: mat.side,
            transparent: true, //mat.transparent,
            vertexShader: vertexShaderSource,
            fragmentShader: fragmentShaderSource,
        })
    } else {
        // a meshbasic material is not affected by the lights
        // so it is more suited for this kind of effect
        newMat = new THREE.MeshBasicMaterial({
            name: mat.name,
            color: 0xffffff,
            depthWrite: mat.depthWrite,
            depthTest: mat.depthTest,
            side: mat.side,
            transparent: true, //mat.transparent
        })
    }

    newMat.map = mat.map
    threeNode.material = newMat
}

const set_anim2D = (threeNode, globals) => {
    // extract animation 2D parameters:
    const re = /Animation2D_([0-9]+)_([0-9]+)_([0-9]+)_([0-9]+)/
    const name = threeNode.name
    const found = name.match(re)
    // const namePrefix = name.split('_').shift()
    const nCols = found[1],
        nRows = found[2],
        nFrames = found[3],
        nFPS = found[4] - 1 // slow down a bit animation

    // extract bindToScreen parameters:
    let isBindToScreen = name.indexOf('_bindToScreen') !== -1
    let bindToScreenMode = 'stretch'
    if (isBindToScreen) {
        let nameParsed = name.split('_')
        nameParsed = nameParsed.splice(nameParsed.indexOf('bindToScreen')) // remove elements before "bindToScreen"
        nameParsed.shift() // remove "bindToScreen" element
        bindToScreenMode = nameParsed.shift()
    }

    // extract loop parameter:
    const isLoop = name.indexOf('_loop') !== -1

    // get texture:
    const animatedTexture = threeNode.material.map
    animatedTexture.repeat.set(1.0 / nRows, 1.0 / nCols)

    // We need to use a separate clock from the global clock
    // otherwise getElapsedTime will disturb getDelta() called in index.jsx
    const threeClock = new THREE.Clock()

    let timeOffset = threeClock.getElapsedTime()
    let timePrevious = timeOffset
    let timePreviousRendering = timeOffset

    setup_material(
        threeNode,
        globals.threeRenderer,
        isBindToScreen,
        bindToScreenMode
    )

    threeNode.onBeforeRender = function (
        renderer,
        scene,
        camera,
        geometry,
        material
    ) {
        const time = threeClock.getElapsedTime() // in seconds
        const dtLoop = time - timePrevious
        timePrevious = time

        if (!is_visible(threeNode)) {
            return
        }
        let timeElapsed = time - timeOffset // time elapsed since the beginning of the animation
        let dt = timeElapsed - timePreviousRendering // time between 2 renderings

        if (dt > DT_MAX) {
            // we need to reset the 2D animation since it has not been displayed for a while
            console.log('2D Animation restart', time, timeOffset)
            const timeAlreadyPlaying = dtLoop > DT_MAX ? 0.0 : dtLoop / 2.0
            timeOffset = time - timeAlreadyPlaying
            timeElapsed = -timeAlreadyPlaying
        }
        timePreviousRendering = timeElapsed

        // pick right frame at the right place on the texture:
        let nFramesElapsed = Math.floor(Math.max(0.0, timeElapsed) * nFPS)
        if (!isLoop) {
            // mask animation2D plane at the end:
            toggle_materialVisibility(
                threeNode.material,
                nFramesElapsed < nFrames
            )
            //nFramesElapsed = Math.min(nFramesElapsed, nFrames - 1)
        }
        let frameInd = nFramesElapsed % nFrames

        // for debugging: block to a frame:
        //frameInd = 20

        const frameY = Math.floor(frameInd / nCols)
        const frameX = frameInd - frameY * nCols
        animatedTexture.offset.set(frameX / nRows, frameY / nRows)

        if (threeNode.material.isShaderMaterial) {
            animatedTexture.updateMatrix()
        }
    }
}

const init = (spec, three) => {
    THREE = three

    const { threeObject3D } = spec
    threeObject3D.traverse((node) => {
        if (
            (node.isMesh || node.isSkinnedMesh) &&
            node.name.indexOf('Animation2D_') !== -1
        ) {
            set_anim2D(node, spec.globals)
        }
    })
}

export default {
    init,
}
