import React, {
    Children,
    cloneElement,
    useState,
    FC,
    ReactNode,
    ReactElement,
} from 'react'
import { Icon, Button } from '../../'
import style from './style.module.scss'

interface CarouselItemProps {
    header: string
    children: ReactNode
    activeIndex?: number
    index?: number
    setActiveIndex?: (index) => void
    length?: number
}

interface CarouselProps {
    children: ReactNode
    startIndex?: number
}

const CarouselItem = ({
    header,
    children,
    activeIndex,
    index,
    setActiveIndex,
    length,
}) => {
    const updatePrevIndex = () => {
        if (activeIndex === 0) {
            setActiveIndex(length - 1)
        } else {
            setActiveIndex((i: number) => i - 1)
        }
    }

    const updateNextIndex = () => {
        if (activeIndex === length - 1) {
            setActiveIndex(0)
        } else {
            setActiveIndex((i: number) => i + 1)
        }
    }
    return (
        <>
            {activeIndex === index && (
                <div className={style.sliderItem}>
                    <div className={style.header}>
                        <Button variant="clear" onClick={updatePrevIndex}>
                            <Icon name="arrowLeft" />
                        </Button>
                        {header}
                        <Button variant="clear" onClick={updateNextIndex}>
                            <Icon name="arrowRight" />
                        </Button>
                    </div>
                    <div className={style.body}>{children}</div>
                </div>
            )}
        </>
    )
}

const Carousel: FC<CarouselProps> & {
    Item: FC<CarouselItemProps>
} = ({ children, startIndex = 0 }) => {
    const [activeIndex, setActiveIndex] = useState<number>(startIndex)
    const length = Children.count(children)

    return (
        <div className={style.contentSlider}>
            {Children.map(
                children,
                (child: ReactElement<CarouselItemProps>) => {
                    return cloneElement(child, {
                        setActiveIndex,
                        activeIndex,
                        length,
                    })
                }
            )}
        </div>
    )
}

Carousel.Item = CarouselItem

export default Carousel
