export const GameNamesForAPI = {
    MicroMages: 'Micro Mages ESL',
    XenoCrisis: 'Xeno Crisis ESL',
    ArseneBomber: 'Arsene Bomber Dev',
    ArseneBomberGamescom: 'Arsene Bomber Gamescom',
    ArseneBomberTournament: 'Arsene Bomber Tournament',
    ArseneBomberLeaderboardRush: 'Arsene Bomber Leaderboard Rush',
    MicroMagesLeaderboardRush: 'Micro Mages Leaderboard Rush',
}

export const CurrentEventName = GameNamesForAPI.MicroMagesLeaderboardRush

export enum EVENT_TAB {
    NEXT_EVENT,
    CLICK_AND_PLAY,
    VIEW_LEADERBOARD,
}

export const GamesForLeaderboard = {
    MicroMages: {
        name: 'GWB Micro Mages',
        title: 'MicroMagesDate',
        todayImg: 'TodayEvent',
        logoImg: 'GWBLogo',
        backgroundImg: 'GWBLeaderboardBG.jpg',
        tabs: [],
        nextEvent: 'NextEventAB',
        gameLink: GameNamesForAPI.MicroMages,
        numberOfPlayers: [1, 2, 3, 4],
    },
    XenoCrisis: {
        name: 'GWB Xeno Crisis',
        title: 'XenoCrisisDate',
        todayImg: 'TodayEventXC',
        logoImg: 'GWBLogo',
        backgroundImg: 'GWBLeaderboardBG.jpg',
        tabs: [],
        nextEvent: 'NextEventAB',
        gameLink: GameNamesForAPI.XenoCrisis,
        numberOfPlayers: [1, 2],
    },
    ArseneBomber: {
        name: 'GWB Arsene Bomber',
        title: 'ArseneBomberDate',
        todayImg: 'TodayEventAB2',
        logoImg: 'GWBLogo',
        backgroundImg: 'GWBLeaderboardBG.jpg',
        tabs: [],
        nextEvent: '',
        gameLink: GameNamesForAPI.ArseneBomber,
        numberOfPlayers: [2, 3, 4],
        defaultPlayerModeIndex: 2,
    },
    ArseneBomberGamescom: {
        name: 'Gamescom Arsene Bomber',
        title: 'ArseneBomberGamescomDate',
        todayImg: 'TodayEventAB2',
        logoImg: 'GamescomLogoWhite',
        backgroundImg: 'GamescomLeaderboardBG.png',
        tabs: [],
        nextEvent: '',
        gameLink: GameNamesForAPI.ArseneBomberGamescom,
        numberOfPlayers: [2, 3, 4],
        defaultPlayerModeIndex: 1,
    },
    ArseneBomberTournament: {
        name: 'Arsene Bomber',
        title: 'ArseneBomberDate',
        todayImg: 'TodayEventAB2',
        logoImg: 'GWBLogo',
        backgroundImg: 'GWBLeaderboardBG.jpg',
        tabs: [EVENT_TAB.CLICK_AND_PLAY, EVENT_TAB.VIEW_LEADERBOARD],
        nextEvent: '',
        gameLink: GameNamesForAPI.ArseneBomberTournament,
        numberOfPlayers: [2, 3, 4],
        defaultPlayerModeIndex: 2,
    },
    ArseneBomberLeaderboardRush: {
        name: 'Arsene Bomber Leaderboard Rush',
        title: 'ArseneBomberLeaderboardRushDate',
        todayImg: 'TodayEventAB2',
        logoImg: null,
        backgroundImg: null,
        tabs: [],
        nextEvent: '',
        gameLink: GameNamesForAPI.ArseneBomberLeaderboardRush,
        numberOfPlayers: [2, 3, 4],
        defaultPlayerModeIndex: 2,
    },
    MicroMagesLeaderboardRush: {
        name: 'Micro Mages Leaderboard Rush',
        title: 'MicroMagesLeaderboardRushDate',
        todayImg: 'TodayEvent',
        logoImg: null,
        backgroundImg: null,
        tabs: [EVENT_TAB.CLICK_AND_PLAY, EVENT_TAB.VIEW_LEADERBOARD],
        nextEvent: '',
        gameLink: GameNamesForAPI.MicroMagesLeaderboardRush,
        numberOfPlayers: [1, 2, 3, 4],
        defaultPlayerModeIndex: 0,
    },
}

export const ordinalSuffixOf = (i: number) => {
    if (!i) return

    if (i === 1) {
        return 'RankFirst'
    }

    if (i === 2) {
        return 'RankSecond'
    }

    if (i === 3) {
        return 'RankThird'
    }

    const j = i % 10
    const k = i % 100

    if (j === 1 && k !== 11) {
        return 'RankXFirst'
    }

    if (j === 2 && k !== 12) {
        return 'RankXSecond'
    }

    if (j === 3 && k !== 13) {
        return 'RankXThird'
    }

    return 'RankX'
}
