import { ChimeInterface } from '../../state/conference'
import {
    SET_MEETING_ID,
    SET_CHIME_MEETING_ID,
    SET_REGION,
    SET_NAME,
    SET_ERROR,
    SET_IS_LOADING,
    SET_SND_BANDWIDTH,
    SET_RCV_BANDWIDTH,
    SET_IS_CAMERA_ACTIVE,
    SET_IS_MICROPHONE_ACTIVE,
    SET_IS_SPEAKER_ACTIVE,
    RESET_STATE,
    ADD_PARTICIPANT,
    REMOVE_PARTICIPANT,
    ConferenceActionTypes,
    EDIT_PARTICIPANT,
    EDIT_PARTICIPANT_MUTED,
    EDIT_PARTICIPANT_VOLUME,
    UPDATE_CAMERA_RESOLUTION,
    SET_IS_DEVICE_BINDING_COMPLETE,
    SET_IS_CAMERA_AVAILABLE,
    SET_IS_MICROPHONE_AVAILABLE,
    SET_IS_CAMERA_GRANTED,
    SET_IS_MICROPHONE_GRANTED,
    SET_VIRTUAL_CAMERA_STREAM,
    UPDATE_AUDIO_INPUT_DEVICES,
    UPDATE_VIDEO_INPUT_DEVICES,
    UPDATE_AUDIO_OUTPUT_DEVICES,
    SET_CURRENT_AUDIO_INPUT_DEVICE,
    SET_CURRENT_VIDEO_INPUT_DEVICE,
    SET_CURRENT_AUDIO_OUTPUT_DEVICE,
    SET_IS_SPEAKER_AVAILABLE,
    SET_IS_SPEAKER_GRANTED,
    SET_IS_VIDEO_FEED_ENABLED,
} from './types'

export const INITIAL_STATE: ChimeInterface = {
    meetingId: '',
    chimeMeetingId: '',
    region: '',
    error: '',
    name: '',
    availableReceiveBandwidth: 0,
    availableSendBandwidth: 0,
    isLoading: false,
    isCameraActive: false,
    isSpeakerActive: false,
    isMicrophoneActive: false,
    participants: {},
    participantsVolume: {},
    cameraResolution: { width: 640, height: 360 },
    isCameraAvailable: undefined,
    isMicrophoneAvailable: undefined,
    isSpeakerAvailable: undefined,
    isCameraGranted: undefined,
    isMicrophoneGranted: undefined,
    isSpeakerGranted: undefined,
    isDeviceBindingComplete: false,
    devices: {
        currentAudioInputDevice: null,
        currentAudioOutputDevice: null,
        currentVideoInputDevice: null,
        audioInputDevices: [],
        audioOutputDevices: [],
        videoInputDevices: [],
    },
    virtualCameraStream: null,
    isVideoFeedEnabled: false,
}

export default function (
    state = INITIAL_STATE,
    action: ConferenceActionTypes
): ChimeInterface {
    switch (action.type) {
        case SET_MEETING_ID:
            return {
                ...state,
                meetingId: action.payload,
            }
        case SET_CHIME_MEETING_ID:
            return {
                ...state,
                chimeMeetingId: action.payload,
            }
        case SET_REGION:
            return {
                ...state,
                region: action.payload,
            }
        case SET_NAME:
            return {
                ...state,
                name: action.payload,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_SND_BANDWIDTH:
            return {
                ...state,
                availableSendBandwidth: action.payload,
            }
        case SET_RCV_BANDWIDTH:
            return {
                ...state,
                availableReceiveBandwidth: action.payload,
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case SET_IS_DEVICE_BINDING_COMPLETE:
            return {
                ...state,
                isDeviceBindingComplete: action.payload,
            }
        case SET_IS_CAMERA_ACTIVE:
            return {
                ...state,
                isCameraActive: action.payload,
            }
        case SET_IS_MICROPHONE_ACTIVE:
            return {
                ...state,
                isMicrophoneActive: action.payload,
            }
        case SET_IS_SPEAKER_ACTIVE:
            return {
                ...state,
                isSpeakerActive: action.payload,
            }
        case ADD_PARTICIPANT:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    [action.payload.userId]: {
                        ...state.participants[action.payload.userId],
                        userId: action.payload.userId,
                        isLocal: action.payload.isLocal,
                    },
                },
            }
        case REMOVE_PARTICIPANT:
            const newParticipants = { ...state.participants }
            delete newParticipants[action.payload]
            return {
                ...state,
                participants: newParticipants,
            }
        case EDIT_PARTICIPANT:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    ...action.payload,
                },
            }
        case EDIT_PARTICIPANT_MUTED:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    [action.payload.externalUserId]: {
                        ...state.participants[action.payload.externalUserId],
                        isMuted: action.payload.isMuted,
                    },
                },
            }
        case EDIT_PARTICIPANT_VOLUME:
            return {
                ...state,
                participantsVolume: {
                    ...state.participantsVolume,
                    [action.payload.userId]: action.payload.volume,
                },
            }
        case UPDATE_CAMERA_RESOLUTION:
            return {
                ...state,
                cameraResolution: {
                    ...state.devices,
                    ...action.payload,
                },
            }
        case SET_IS_CAMERA_AVAILABLE:
            return {
                ...state,
                isCameraAvailable: action.payload,
            }
        case SET_IS_MICROPHONE_AVAILABLE:
            return {
                ...state,
                isMicrophoneAvailable: action.payload,
            }
        case SET_IS_CAMERA_GRANTED:
            return {
                ...state,
                isCameraGranted: action.payload,
            }
        case SET_IS_MICROPHONE_GRANTED:
            return {
                ...state,
                isMicrophoneGranted: action.payload,
            }
        case SET_VIRTUAL_CAMERA_STREAM:
            return {
                ...state,
                virtualCameraStream: action.payload,
            }
        case UPDATE_AUDIO_OUTPUT_DEVICES:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    audioOutputDevices: action.payload,
                },
            }
        case UPDATE_AUDIO_INPUT_DEVICES:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    audioInputDevices: action.payload,
                },
            }
        case UPDATE_VIDEO_INPUT_DEVICES:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    videoInputDevices: action.payload,
                },
            }
        case SET_CURRENT_AUDIO_INPUT_DEVICE:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    currentAudioInputDevice: action.payload,
                },
            }
        case SET_CURRENT_VIDEO_INPUT_DEVICE:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    currentVideoInputDevice: action.payload,
                },
            }
        case SET_CURRENT_AUDIO_OUTPUT_DEVICE:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    currentAudioOutputDevice: action.payload,
                },
            }
        case SET_IS_SPEAKER_AVAILABLE:
            return {
                ...state,
                isSpeakerAvailable: action.payload,
            }
        case SET_IS_SPEAKER_GRANTED:
            return {
                ...state,
                isSpeakerGranted: action.payload,
            }
        case SET_IS_VIDEO_FEED_ENABLED:
            return {
                ...state,
                isVideoFeedEnabled: action.payload,
            }
        case RESET_STATE:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}
