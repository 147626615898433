import { Game } from '../../../state/starfox'

export const SORT_BY_ORDER = {
    asc: 'asc' as const,
    desc: 'desc' as const,
}

export const SORT_BY_RELEASE_YEAR = 'ReleaseYear' as const
export const SORT_BY_ALPHABET = 'Alphabetically' as const
export const SORT_BY_OPTIONS = { SORT_BY_RELEASE_YEAR, SORT_BY_ALPHABET }

export type TTypeOfGame = string
export type TNumberOfPlayers = 0 | 2 | 3 | 4
export type TSortBy = {
    order: typeof SORT_BY_ORDER['asc' | 'desc'] | ''
    kind:
        | typeof SORT_BY_OPTIONS['SORT_BY_ALPHABET' | 'SORT_BY_RELEASE_YEAR']
        | ''
}

export type TSearchFilterSortState = {
    search: string
    typeOfGame: TTypeOfGame
    numberOfPlayers: TNumberOfPlayers
    sortBy: TSortBy
}

export type TUseSearchFilterSortAction =
    | {
          type: 'updateSearch'
          payload: { search: TSearchFilterSortState['search'] }
      }
    | {
          type: 'updateTypeOfGame'
          payload: { typeOfGame: TSearchFilterSortState['typeOfGame'] }
      }
    | {
          type: 'updateNumberOfPlayers'
          payload: {
              numberOfPlayers: TSearchFilterSortState['numberOfPlayers']
          }
      }
    | {
          type: 'updateSortBy'
          payload: { sortBy: TSearchFilterSortState['sortBy'] }
      }

export type TImmutableProcessGameList<T = Array<Game>> = (
    gamesList: ReadonlyArray<Game>
) => T
