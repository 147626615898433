import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import ReconnectingWebSocket, {
    Event as RecwsEvent,
} from 'reconnecting-websocket'
import store, { actions } from '../store'
import { clearSessionState } from '../starfox/starfox'
import {
    CreateCoreGameString,
    GameState,
    getDeviceType,
    makeCreateCorePayloadString,
    parseCoreReadyPayloadString,
    piepie,
    sleep,
} from '../utils'
import { globalPubSub, PubSubEvent } from '../event/event'
import { ScreenType } from '../components/Screen/screen-props'
import * as Socket from '../network/socket'
import { SessionType } from '../state/screen'
import { Team } from '../components/ESLModals/useGameAchievements'
import { LocalStorage } from '../state/storage'
import { RTCPEventName } from '../state/network'
import { AuthModalType } from '../store/auth/types'
import rtcpSlice from '../store/rtcp/rtcp'
import socketSlice from '../store/socket/socket'
import { connectionInitialized } from '../store/socket/utils'
import screenSlice from '../store/screen/screen'

/**
 * WebSocket connection module.
 *
 *  Needs init() call.
 *
 * @version 1
 */

// TODO: remove asap, utilize core_removed
let gameSwitching = false
// init should only happen once the session is active
export const init = async (): Promise<void> => {
    // create connection
    let idToken = localStorage.getItem(LocalStorage.idToken)
    if (!idToken) idToken = await firebase.auth().currentUser.getIdToken()
    const conn = makeWebSocketConnection(idToken)

    // Set ping
    conn.onopen = () => {
        piepie.log('[SOCKET] opening connection')
        // !to add destructor if SPA
        store.dispatch(socketSlice.actions.startPingLoop(sendPing))
    }
    conn.onerror = (errorEvent: RecwsEvent) => {
        piepie.error(
            `[SOCKET] websocket connection failed: ${errorEvent.target}`
        )
        store.dispatch(socketSlice.actions.setDisconnected())
    }
    conn.onclose = (ev: CloseEvent) => {
        piepie.log(
            `[SOCKET] websocket connection closed: code=${ev.code}, reason=${ev.reason}, wasClean=${ev.wasClean}`
        )
        store.dispatch(socketSlice.actions.setDisconnected())
        store.dispatch(socketSlice.actions.incRetryCount())
        if (store.getState().socket.retryCount > 20) {
            // Can't connect after 20 retry. The POD is likely dead anyway, let's abort
            // Call close manually to stop reconnecting.
            // Note: in theory it shouldn't recall this event but don't quote me
            conn.close(666, 'RIP')
            piepie.log(`[SOCKET] websocket connection killed`)
            // Probably need to do something similar as rcv_quit
            // Displays a modal with the connection failed message. On close reloads the page.
            store.dispatch(
                actions.modal.openModal({
                    message: 'ConnectionFailed',
                    onClose: () => {
                        store.dispatch(actions.modal.closeModal())
                        // if connection failed, go back to home
                        window.location.href = '/'
                    },
                })
            )
        }
    }
    // limit ws logs to 200 characters
    const preprocessWsLog = (id: string, msg: string): string => {
        if (id === 'list_effects') {
            return msg
        }
        if (msg.length > 200) {
            return msg.substring(0, 200) + '...'
        }
        return msg
    }
    // Message received from server
    conn.onmessage = async (response: { data: string }) => {
        const data = JSON.parse(response.data)
        // const message = data.id
        const message = data.Id

        if (message !== 'heartbeat' && message !== '') {
            piepie.log(
                `[SOCKET] message recv: '${message}': ${preprocessWsLog(
                    message,
                    data.data
                )}`
            )
        }

        switch (message) {
            case 'number_cd':
                store.dispatch(
                    socketSlice.actions.setNumberCd(parseInt(data.data, 10))
                )
                break
            case 'ban':
                clearSessionState()
                store.dispatch(screenSlice.actions.setTheater(false))
                store.dispatch(screenSlice.actions.setFullscreen(false))
                window.location.href = `/?modalType=${AuthModalType.Banned}`
                break
            case 'kickout':
                clearSessionState()
                store.dispatch(screenSlice.actions.setTheater(false))
                store.dispatch(screenSlice.actions.setFullscreen(false))
                store.dispatch(
                    actions.modal.openModal({
                        message: 'KickoutModal',
                        onClose: () => {
                            store.dispatch(actions.modal.closeModal())
                            window.location.href = '/'
                        },
                    })
                )
                setTimeout(() => {
                    window.location.href = '/'
                }, 5000) // wait 5s
                break
            case 'init_remote':
                piepie.log('[SOCKET] step 1: recv init_remote')
                store.dispatch(
                    screenSlice.actions.setScreenType(ScreenType.CLOUD)
                )
                store.dispatch(
                    screenSlice.actions.setSessionType(SessionType.STREAM)
                )
                break
            case 'init_local':
                piepie.log('[SOCKET] step 1: recv init_local')
                globalPubSub.pub(PubSubEvent.GAME_LOCAL_INITIALIZED, {})
                store.dispatch(
                    screenSlice.actions.setScreenType(ScreenType.LOCAL)
                )
                store.dispatch(
                    screenSlice.actions.setSessionType(SessionType.LOCAL)
                )
                break
            case 'init_transition':
                piepie.log('[SOCKET] step 2: recv init_transition')
                store.dispatch(
                    screenSlice.actions.setScreenType(ScreenType.CLOUD)
                )
                store.dispatch(
                    screenSlice.actions.setSessionType(SessionType.STREAM)
                )
                piepie.log('[SOCKET] step 2: send uploadSaved')
                Socket.uploadSaved()
                break
            case 'webrtc_ready':
                piepie.log('[SOCKET] step 3: recv webrtc_ready')
                globalPubSub.pub(PubSubEvent.GAME_REMOTE_INITIALIZED, {})
                store.dispatch(
                    rtcpSlice.actions.addEvent({
                        Name: RTCPEventName.MEDIA_STREAM_INITIALIZED,
                        Data: '',
                    })
                )
                store.dispatch(
                    screenSlice.actions.setScreenType(ScreenType.CLOUD)
                )
                break
            case 'icecandidate':
                piepie.log('[SOCKET] step 4.x: recv ice candidate')
                store.dispatch(
                    rtcpSlice.actions.addEvent({
                        Name: RTCPEventName.ICE_CANDIDATE,
                        Data: data.data,
                    })
                )
                break
            case 'input_ready':
                piepie.log('[SOCKET] step 6.x: recv input ready')
                store.dispatch(rtcpSlice.actions.setInputReady(true))
                piepie.log('[SOCKET] connection ready')
                store.dispatch(socketSlice.actions.setConnectionReady(true))
                break
            case 'sdp':
                piepie.log('[SOCKET] step 5: recv sdp')
                store.dispatch(
                    rtcpSlice.actions.addEvent({
                        Name: RTCPEventName.MEDIA_STREAM_SDP_AVAILABLE,
                        Data: data.data,
                    })
                )
                break
            case 'error':
                piepie.error('[SOCKET] server error:', data)
                break
            case 'heartbeat':
                // reserved
                break
            case 'rcv_start':
                // store.dispatch(actions.session.setGameStateReady())
                // globalPubSub.pub(PubSubEvent.GAME_ROOM_AVAILABLE, '')
                break
            case 'player_idx_update':
                store.dispatch(
                    actions.session.setPlayerIndices(JSON.parse(data.data))
                )
                globalPubSub.pub(PubSubEvent.NEW_PLAYER_INDICES, '')
                break
            case 'rcv_switch_indices':
                globalPubSub.pub(
                    PubSubEvent.RCV_SWITCH_INDICES,
                    JSON.parse(data.data)
                )
                break
            case 'rcv_save':
                globalPubSub.pub(PubSubEvent.GAME_SAVED, '')
                break
            case 'rcv_pause':
                globalPubSub.pub(PubSubEvent.GAME_PAUSED, '')
                store.dispatch(actions.session.setGameStatePaused())
                break
            case 'rcv_reset':
                globalPubSub.pub(PubSubEvent.GAME_RESET, '')
                store.dispatch(actions.session.setGameStateReady())
                break
            case 'rcv_resume':
                globalPubSub.pub(PubSubEvent.GAME_RESUMED, '')
                store.dispatch(actions.session.setGameStateReady())
                break
            case 'rcv_check_pause':
                if (data.data === 'true') {
                    store.dispatch(actions.session.setGameStatePaused())
                }
                break
            case 'rcv_load':
                globalPubSub.pub(PubSubEvent.GAME_LOADED, '')
                store.dispatch(actions.session.setGameStateReady())
                break
            case 'rcv_quit':
                store.dispatch(actions.session.clearSession())
                clearSessionState()
                // If a user is trying to log out redirect them directly to the home page.
                if (window.location.hash === '#logout') {
                    window.location.href = '/'
                    return
                }
                // However, if the host has left the game first show users an alert about it,
                // and then redirect them to the home page.

                // The reason why we are using the localStorage instead of Redux here is that on the game page
                // we are using a prompt component that is being called when the user tries to leave the game page
                // and it cleans the Redux session store.
                const isHost = localStorage.getItem(LocalStorage.isHost)
                if (isHost !== 'true') {
                    const onClose = () => {
                        store.dispatch(actions.modal.closeModal())
                        window.location.href = store.getState().user?.user
                            ?.isAnonymous
                            ? '/?modalType=SignUpMenu'
                            : '/'
                    }
                    store.dispatch(screenSlice.actions.setTheater(false))
                    store.dispatch(screenSlice.actions.setFullscreen(false))
                    store.dispatch(
                        actions.modal.openModal({
                            message: 'HostLeft',
                            onClose: onClose,
                        })
                    )
                    await sleep(5000)
                    onClose()
                }
                break
            case 'checkLatency':
                store.dispatch(
                    socketSlice.actions.setCurPacketId(data.packet_id)
                )
                globalPubSub.pub(PubSubEvent.LATENCY_CHECK_REQUESTED, {
                    packetId: store.getState().socket.curPacketId,
                    addresses: data.data.split(','),
                })
                break
            case 'achievement':
                const gameAchievements = JSON.parse(data.data)
                globalPubSub.pub(
                    PubSubEvent.GAME_ACHIEVEMENTS,
                    gameAchievements
                )
                break
            case 'recv_broadcast':
                const recvBroadcastData = JSON.parse(data.data)

                switch (recvBroadcastData.event) {
                    case PubSubEvent.TAB_VISIBILITY_CHANGED:
                        globalPubSub.pub(PubSubEvent.TAB_VISIBILITY_CHANGED, {
                            userId: recvBroadcastData.userId,
                            isVisible: recvBroadcastData.isVisible,
                        })
                }
                break
            case 'potato':
                const hotPotatoData = JSON.parse(data.data)
                globalPubSub.pub(PubSubEvent.GAME_HOTPOTATO, hotPotatoData)
                break
            case 'core_not_ready':
                store.dispatch(actions.session.setGameStateLoading())
                if (gameSwitching) return
                const { gameName, byog, gameCore, gameFile } =
                    store.getState().session
                const payloadString = makeCreateCorePayloadString({
                    gameName,
                    byog,
                    gameCore,
                    gameFile,
                })
                Socket.sendGame(payloadString)
                break
            case 'core_ready':
                // const coreReadyState = JSON.parse(data)
                const [gameId, gameState] = parseCoreReadyPayloadString(
                    data.data
                )
                store.dispatch(actions.session.setGameName(gameId))
                /* initializing proper view for newly connected users or ones
                that refreshed the page */
                switch (gameState) {
                    case GameState.GAME_PAUSED:
                        store.dispatch(actions.session.setGameStatePaused())
                        /* NOTE: currently imitate pubsub rcv_pause behaviour.
                        remove after refactoring pubsub */
                        break
                    case GameState.GAME_SELECT:
                        /* game selecting screen is a next step after pressing pause. */
                        store.dispatch(actions.session.setGameStateSelecting())
                        break
                    case GameState.GAME_RUNNING:
                        store.dispatch(actions.session.setGameStateReady())
                        break
                    default:
                    // store.dispatch(actions.session.setGameStateReady())
                }
                // TODO: handle different cases of ready (selecting game, played, paused)
                break
            case 'core_removed':
                store.dispatch(actions.session.setGameName(null))
                // not possible to switch from one byog game to another
                if (store.getState().session.byog)
                    store.dispatch(actions.session.setBYOG(false))
                break
            /*
                rcv_switch_game_selecting and rcv_switch_game_idle are purely
                internal messages sent by host and to be broadcasted to guests
                within pause
            */
            case 'rcv_switch_game_selecting':
                store.dispatch(actions.session.setGameStateSelecting())
                break
            case 'rcv_switch_game_idle':
                store.dispatch(actions.session.setGameStatePaused())
                break

            case 'disconnected':
                // when the core (e.g. lemmings) is disconnected or simply doesn't exist (in between games).
                break
        }
    }
    store.dispatch(socketSlice.actions.setConnection(conn))
}

function makeWebSocketConnection(idToken: string): ReconnectingWebSocket {
    const paramString = `sessionId=${
        store.getState().session.sessionID
    }&userSessionId=${store.getState().session.userSessionID}&idToken=${btoa(
        idToken
    )}&deviceType=${getDeviceType()}`

    const domainRegex =
        /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    const domainStartRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)$/
    const options = {
        maxReconnectionDelay: 1000,
        minReconnectionDelay: 1000,
        minUptime: 5000,
        reconnectionDelayGrowFactor: 1.3,
        connectionTimeout: 4000,
        maxRetries: Infinity,
        maxEnqueuedMessages: Infinity,
        startClosed: false,
        debug: false,
    }
    const bandicootName = store.getState().session.session.Bandicoot
    const clusterDomain = store
        .getState()
        .session.session.Cluster.split('.')
        .slice(1)
        .join('.')
    if (!domainStartRegex.test(bandicootName)) {
        throw new Error("'" + bandicootName + "' is not set properly")
    }
    if (!domainRegex.test(clusterDomain)) {
        throw new Error("'" + clusterDomain + "' is not set properly")
    }
    const bandicootURL =
        (process.env.REACT_APP_SECURE === 'true' ? 'wss' : 'ws') +
        '://' +
        bandicootName +
        '.' +
        clusterDomain
    return new ReconnectingWebSocket(
        bandicootURL + '/ws?' + paramString,
        [],
        options
    )
}

interface WSPacketInterface {
    id: string
    data: string
    user_session_id: string
}

// TODO: format the package with time
export const send = async (id: string, data?: string) => {
    if (!connectionInitialized()) {
        globalPubSub.pub(
            PubSubEvent.NOTIFY_ERROR,
            'connection is not ready yet'
        )
        return
    }
    // every user should be signed in (anonymously or otherwise)
    // thus it is ok to require firebase.auth().currentUser to be defined
    if (!firebase.auth().currentUser) {
        globalPubSub.pub(
            PubSubEvent.NOTIFY_ERROR,
            'cannot send if firebase user unset'
        )
        return
    }
    const wsPacket: WSPacketInterface = {
        id: id,
        data: data,
        user_session_id: store.getState().session.userSessionID,
    }
    if (id !== 'heartbeat') {
        piepie.log(`[SOCKET] message sent: ${id}`)
    }
    store.getState().socket.conn.send(JSON.stringify(wsPacket))
}

export const sendBinary = async (id: string, data: Uint8Array) => {
    // FIXME: factorize code with send
    if (!connectionInitialized()) {
        globalPubSub.pub(
            PubSubEvent.NOTIFY_ERROR,
            'connection is not ready yet'
        )
        return
    }
    // every user should be signed in (anonymously or otherwise)
    // thus it is ok to require firebase.auth().currentUser to be defined
    if (!firebase.auth().currentUser) {
        globalPubSub.pub(
            PubSubEvent.NOTIFY_ERROR,
            'cannot send if firebase user unset'
        )
        return
    }

    const wsPacket: WSPacketInterface = {
        id: id,
        data: '',
        user_session_id: store.getState().session.userSessionID,
    }
    // Convert the string to an UINT8 array
    const header_json = new TextEncoder().encode(JSON.stringify(wsPacket))

    // Build the packet
    const packet = new Uint8Array(2 + header_json.length + data.length)
    packet.set([header_json.length & 0xff], 0)
    packet.set([(header_json.length >> 8) & 0xff], 1)
    packet.set(header_json, 2)
    packet.set(data, 2 + header_json.length)

    piepie.log(`[SOCKET] binary message sent`)
    store.getState().socket.conn.send(packet)
}

export const sendFps = (value) => send('fps', value)
export const sendPing = () => send('heartbeat', Date.now().toString())
export const saveGame = () => send('save')
export const ban = (user) => send('ban', user)
export const kickout = (user) => send('kickout', user)
export const loadGame = (name) => send('load', name)
export const pauseGame = () => send('pause')
export const switchIndices = (newIndices) => send('switch_indices', newIndices)
export const resumeGame = () => send('resume')
export const resetGame = () => send('reset')
export const checkPause = () => send('check_pause')
export const uploadSaved = () => send('uploaded_save', 'auto')
export const sendGame = (payloadString: CreateCoreGameString) => {
    send('create_core', payloadString)
}
export const sendQueryNumberCd = () => send('query_number_cd')
export const sendSwitchCd = (cd: number) => send('switch_cd', cd.toString())

export const startGame = () => send('start')
export const quitGame = () => send('send_quit')
export const addLocalPlayer = () => send('new_local_player')
export const removeLocalPlayer = (id: number) =>
    send('remove_local_player', id.toString())
export const autoSave = (autosave) => sendBinary('autosave', autosave)
export const tabVisibilityChanged = (
    isVisible: boolean,
    userId: string
): Promise<void> => {
    return send(
        'broadcast',
        JSON.stringify({
            event: PubSubEvent.TAB_VISIBILITY_CHANGED,
            isVisible,
            userId,
        })
    )
}
export const saveScore = (team: Team) => {
    return send('save_score', JSON.stringify(team))
}

export const switchGame = (gamePayloadString: CreateCoreGameString) => {
    // TODO: hack need to be fixed
    gameSwitching = true
    send('switch_game', gamePayloadString)
    store.dispatch(rtcpSlice.actions.clearRTCP())
}
export const switchGameSelecting = () => send('switch_game_selecting')
export const switchGameIdle = () => send('switch_game_idle')
