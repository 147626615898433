import styles from './mood.module.scss'
import React, { FC, useState } from 'react'
import { ICard } from './types'
import classNames from 'classnames'
import { Icon } from '../../../stories'
import { getSessionMoodByLabel } from '../../../state/starfox'

const Mood: FC<Partial<ICard>> = ({ mood, handleOpenGameModal, className }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div
            className={classNames(styles.card, className)}
            onClick={() => {
                handleOpenGameModal()
                analytics.track('Click on Mood Card', { Mood: mood })
            }}
            onMouseEnter={() => {
                setIsHovered(true)
                analytics.track('Mouse Mood Card', { Mood: mood })
            }}
            onTouchStart={() =>
                analytics.track('Touch Mood Card', { Mood: mood })
            }
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={styles.cardBackground}></div>
            <div className={styles.innerCard}>
                <Icon
                    name={`${getSessionMoodByLabel(mood).icon}Mood`}
                    className={classNames(styles.moodIcon)}
                />
                <Icon
                    name={`${getSessionMoodByLabel(mood).icon}MoodHover`}
                    className={classNames(styles.moodIconHover, {
                        [styles.show]: isHovered,
                    })}
                />
            </div>
        </div>
    )
}

export default Mood
