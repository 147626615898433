import { State } from '../../../store/types'
import { serverLocation } from '../PlatformPreference/constants'

export const getUserAvatar = (state: State): string =>
    state.user.user ? state.user.user.photoURL : ''

export const getCurrentBadge = (state: State) =>
    state.user.extra ? state.user.extra.CurrentBadge : null

export const getUserName = (state: State) =>
    state.user.user ? state.user.user.displayName : ''

export const getNicknameColor = (state: State) =>
    state.user.extra ? state.user.extra.NicknameColor : null

export const getUserEmail = (state: State) =>
    state.user.user ? state.user.user.email : ''

export const getBadgeList = (state: State) =>
    state.user.extra ? state.user.extra.BadgeList : []

export const getColorList = (state: State) =>
    state.user.extra ? state.user.extra.ColorList : []

export const getKickstarterBacker = (state: State) =>
    state.user.extra ? state.user.extra.KickstarterBacker : null

export const getFeatures = (state: State) =>
    state.user.extra ? state.user.extra.Features : null

export const getPreferredGames = (state: State) =>
    state.user.extra ? state.user.extra.PreferredGames : []

export const getBirthday = (state: State) =>
    state.user.extra && state.user.extra.Birthday
        ? new Date(state.user.extra.Birthday.seconds * 1000)
        : null

export const getKeyboard = (state: State) =>
    state.user.extra ? state.user.extra.Keyboard : null

export const getTwitchLinked = (state: State) =>
    state.user.extra ? state.user.extra.TwitchLinked : null

export const getSubscribedToNews = (state: State) =>
    state.user.extra ? state.user.extra.SubscribedToNews : false

export const getServer = (state: State) =>
    state.user.extra
        ? state.user.extra.PreferredCluster
        : serverLocation.Default.text
