import { useCallback, useState } from 'react'
import { LocalStorage } from '../../state/storage'

export const useVolume = () => {
    const [volume, setVolume] = useState<number>(
        localStorage.getItem(LocalStorage.volume)
            ? parseInt(localStorage.getItem(LocalStorage.volume))
            : 30
    )

    const updateVolume = useCallback((volume: number) => {
        setVolume(volume)
        localStorage.setItem(LocalStorage.volume, volume.toString())
    }, [])

    return { volume, updateVolume }
}
