import React, { FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface SwitchInterface {
    className?: string
    checked?: boolean
    onChange?: (boolean) => void
    disabled?: boolean
    label?: string
}

const Switch: FC<SwitchInterface> = ({
    className,
    checked = false,
    onChange,
    disabled = false,
    label,
}) => {
    return (
        <div className={cls(style.switch, className)}>
            {label && <span className={style.switchLabel}>{label}</span>}
            <label className={style.switchWrapper}>
                <input
                    className={style.switchInput}
                    type="checkbox"
                    disabled={disabled}
                    checked={checked}
                    onChange={() => {
                        if (onChange) onChange(!checked)
                    }}
                />
                <span className={style.switchElement}></span>
            </label>
        </div>
    )
}

export default Switch
