import React, { useCallback, FC, useState } from 'react'
import { GameroomControls } from '../../stories'
import { usePausePlay, useSaveStates } from '../../utils/GameRoomHooks'
import { switchGameSelecting, saveGame } from '../../network/socket'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../store/types'
import { actions } from '../../store'
import { isGame, showLoading } from '../../utils/loading'
import { ConfirmDialog } from '../../state/confirm'
import ConfirmModal from '../ConfirmModal'
import { useTranslation } from 'react-i18next'
import GameRoomHints from './GameRoomHints'
import { GameroomModalSectionType } from '../../state/gameroomModalSection'
import gameroomModalSectionSlice from '../../store/gameroomModalSection/gameroomModalSection'
import { ScreenType } from '../Screen/screen-props'

interface GameRoomControlsProps {
    handleSetTheater: () => void
    isTheater: boolean
    volume: number
    updateVolume: (state: number) => void
}

const GameRoomControls: FC<GameRoomControlsProps> = ({
    handleSetTheater,
    isTheater,
    volume,
    updateVolume,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const gameState = useSelector((state: State) => state.session.gameState)
    const highlightStatus = useSelector(
        (state: State) => state.screen.highlightScreenStatus
    )
    const disableSavestates = useSelector(
        (state: State) => state.session.disableSavestates
    ) as boolean

    const isHost = useSelector(
        (state: State) => state.session.isHost
    ) as boolean

    const gameroomModalSection = useSelector(
        (state: State) => state.gameroomModalSection.gameroomModalSection
    )

    const screenType = useSelector((state: State) => state.screen.screenType)

    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )

    const [showHints, setShowHints] = useState(true)
    const [hintsAutoClose, setHintsAutoClose] = useState(true)

    const { handlePauseGame, handleResumeGame } = usePausePlay()

    const handlePausePlayButton = useCallback(() => {
        if (gameroomModalSection !== GameroomModalSectionType.PAUSE) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.PAUSE
                )
            )
            handlePauseGame()
        } else if (
            gameroomModalSection === GameroomModalSectionType.PAUSE &&
            isGame(gameState).paused
        ) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
            handleResumeGame()
        }
    }, [
        gameroomModalSection,
        gameState,
        handlePauseGame,
        handleResumeGame,
        dispatch,
    ])

    const handleDisplaySaveStatesButton = useCallback(() => {
        if (gameroomModalSection !== GameroomModalSectionType.LOAD) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.LOAD
                )
            )
            handlePauseGame()
        } else if (
            gameroomModalSection === GameroomModalSectionType.LOAD &&
            isGame(gameState).paused
        ) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
            handleResumeGame()
        }
    }, [
        gameroomModalSection,
        gameState,
        handlePauseGame,
        handleResumeGame,
        dispatch,
    ])

    const handleDisplaySettingsButton = useCallback(() => {
        if (gameroomModalSection !== GameroomModalSectionType.SETTINGS) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.SETTINGS
                )
            )
            handlePauseGame()
        } else if (
            gameroomModalSection === GameroomModalSectionType.SETTINGS &&
            isGame(gameState).paused
        ) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
            handleResumeGame()
        }
    }, [
        gameroomModalSection,
        gameState,
        handlePauseGame,
        handleResumeGame,
        dispatch,
    ])

    const handleSwitchGameButton = useCallback(() => {
        //     this line was on the StartNewGameButton.tsx which is deleted
        //     if (game?.Competition) return null

        if (gameroomModalSection !== GameroomModalSectionType.SWITCH) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.SWITCH
                )
            )
            handlePauseGame()
            switchGameSelecting()
            dispatch(actions.session.setGameStateSelecting())
        } else if (
            gameroomModalSection === GameroomModalSectionType.SWITCH &&
            isGame(gameState).idle
        ) {
            dispatch(
                gameroomModalSectionSlice.actions.setGameroomModal(
                    GameroomModalSectionType.NONE
                )
            )
            handleResumeGame()
        }
    }, [
        dispatch,
        gameroomModalSection,
        gameState,
        handlePauseGame,
        handleResumeGame,
    ])

    const handleLocalSendSaveState = useCallback(() => {
        dispatch(actions.session.setIsSavedState(true))
    }, [dispatch])

    const handleSave =
        screenType === ScreenType.CLOUD ? saveGame : handleLocalSendSaveState

    const { handleSaveStates: handleQuickSave, removeLast } = useSaveStates(
        handleSave,
        setConfirmDialog,
        null
    )

    const isPaused =
        isGame(gameState).paused || showLoading(gameState, highlightStatus)

    const handleShowHints = useCallback(() => {
        setShowHints(!showHints)
        setHintsAutoClose(false)
    }, [setShowHints, showHints, setHintsAutoClose])

    const onConfirmModalValidate = useCallback(() => {
        if (confirmDialog === ConfirmDialog.Override) {
            handleSave()
            removeLast()
        }
        setConfirmDialog(ConfirmDialog.None)
        handleResumeGame()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmDialog, handleSave, removeLast])

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const gameControls = useSelector(
        (state: State) => state.session.game.Controls
    )

    const tooltipTexts = {
        play: t('Resume'),
        pause: t('Pause'),
        displaySaveStates: t('DisplaySaveStates'),
        switchGame: t('SwitchGameTooltip'),
        quickSave: t('QuickSave'),
        settings: t('Settings'),
        showHints: t('HelpTooltip'),
        theaterMode: t('TheaterMode'),
    }

    return (
        <>
            <GameroomControls
                isHost={isHost}
                isPaused={isPaused}
                isTheater={isTheater}
                volume={volume}
                setVolume={updateVolume}
                handlePlayPause={handlePausePlayButton}
                handleQuickSave={handleQuickSave}
                disableSavestates={disableSavestates}
                handleDisplaySaveStates={handleDisplaySaveStatesButton}
                handleSwitchGame={handleSwitchGameButton}
                handleShowHints={handleShowHints}
                handleTwitch={() => {
                    return
                }}
                handleSettings={handleDisplaySettingsButton}
                handleSetTheater={handleSetTheater}
                tooltipTexts={tooltipTexts}
                gameroomModalSection={gameroomModalSection}
            />

            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalValidate}
                onClose={onConfirmModalClose}
            />

            {showHints && gameControls && (
                <GameRoomHints
                    autoClose={hintsAutoClose}
                    onClose={() => {
                        setShowHints(false)
                        setHintsAutoClose(false)
                    }}
                />
            )}
        </>
    )
}

export default GameRoomControls
