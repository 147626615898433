import styles from './style.module.scss'

import React, { FC, useEffect } from 'react'
import classNames from 'classnames'
import { AlertInterface } from '../../../state/alert'

import { Alert } from '../../atoms/Alert'

export enum AlertAlign {
    Left = 'left',
    Right = 'right',
}

interface AlertStackProps {
    className?: string
    align?: AlertAlign
    max?: number
    alertList?: AlertInterface[]
    onAlertDelete: (id: AlertInterface['id']) => void
}

const AlertStack: FC<AlertStackProps> = ({
    className,
    align = 'right',
    max = 3,
    alertList = [],
    onAlertDelete,
}) => {
    useEffect(() => {
        if (alertList.length >= max) {
            let autoClosableCount = 0
            let closableCount = 0
            alertList.forEach((alert) => {
                if (alert.autoClose === undefined || alert.autoClose === true) {
                    autoClosableCount += 1
                    if (autoClosableCount >= max) {
                        onAlertDelete(alert.id)
                    }
                }
                if (
                    alert.autoClose !== undefined ||
                    alert.autoClose === false
                ) {
                    closableCount += 1
                    if (closableCount >= max) {
                        onAlertDelete(alert.id)
                    }
                }
            })
        }
    }, [alertList, max, onAlertDelete])

    return (
        <ul
            className={classNames(
                styles.alertStack,
                className,
                align === AlertAlign.Left && styles.alignLeft
            )}
            data-test="AlertContainer"
        >
            {alertList.map((alert) => {
                return (
                    <li
                        data-cy={`alert-${alert.type}`}
                        className={styles.alertElement}
                        key={alert.id}
                    >
                        <Alert {...alert} onClose={onAlertDelete} />
                    </li>
                )
            })}
        </ul>
    )
}

export default AlertStack
