import React, { FunctionComponent, useEffect } from 'react'

// Add the Firebase products that you want to use
/* eslint-disable import/no-duplicates */
import 'firebase/firestore'
import 'firebase/auth'
/* eslint-enable */
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Layout from './pages/Home/Layout'
import Game from './pages/Game'
import Events from './pages/Events'
import ResetPasswordPage from './pages/ResetPassword'
import InviteBetaPage from './pages/InviteBeta'
import Unavailable from './pages/Unavailable'
import NotFound from './pages/NotFound/'
import AppLayout from './components/AppLayout'
import { UnavailableReasonEnum } from './state/unavailable'
import { useSelector, useDispatch } from 'react-redux'
import { State } from './store/types'
import {
    NavbarTheme,
    SignedInState,
    // TODO: move styles into a theme context
} from './state/app'
import BYOG from './pages/BYOG'
import { getUserExtra } from './firebase'
import { user } from './store/actions'

import UserSettings from './pages/UserSettings'
import Registration from './pages/Registration'
// import Checkout from './pages/Checkout'

export interface RouterPropsInterface {
    signedIn: SignedInState
    reason: UnavailableReasonEnum
    isSignInStateKnown: boolean
}

const hideHome = (signedIn: SignedInState): boolean => {
    if (
        process.env.REACT_APP_ENV === 'PROD' &&
        process.env.REACT_APP_PREVIEW !== 'true'
    ) {
        // never hide home
        return false
    } else {
        // hide home if not signed in
        return signedIn !== SignedInState.SignedIn
    }
}

const Router: FunctionComponent<RouterPropsInterface> = ({
    signedIn,
    reason,
    isSignInStateKnown,
}) => {
    const dispatch = useDispatch()
    const authUser = useSelector((state: State) => state.user.user)

    useEffect(() => {
        // TODO: Maybe this call is done too many times? Check better!
        if (signedIn === SignedInState.SignedIn) {
            getUserExtra()
                .then((userExtra) => {
                    dispatch(user.setUserExtra(userExtra))
                    if (
                        process.env.REACT_APP_PREVIEW === 'true' &&
                        process.env.REACT_APP_ENV === 'STAGING' && // preview staging is restricted, preview should not be
                        !window.location.pathname.startsWith('/game') && // To allow people to join rooms if not whitelisted
                        !userExtra.PreviewWhitelisted
                    ) {
                        window.location.href = `https://jam.gg?redirect=NotPreview`
                    }

                    if (
                        (process.env.REACT_APP_ENV === 'STAGING' ||
                            process.env.REACT_APP_ENV === 'DEV') &&
                        !userExtra.Whitelisted
                    ) {
                        window.location.href = `https://jam.gg?redirect=NotPreview`
                    }
                })
                .catch(() => {
                    // In this case the user cannot access the userExtra database even if he/she is signed In
                    if (
                        process.env.REACT_APP_PREVIEW === 'true' &&
                        !window.location.pathname.startsWith('/game')
                    ) {
                        window.location.href = `https://jam.gg?redirect=NotPreview`
                    }
                })
        }
    }, [signedIn, dispatch])

    const renderIndexPage = () => {
        if (!isSignInStateKnown) {
            return
        }

        if (signedIn === SignedInState.SignedIn && !hideHome(signedIn)) {
            return (
                <AppLayout theme={NavbarTheme.None} signedIn={signedIn}>
                    <Layout />
                </AppLayout>
            )
        }

        return (
            <AppLayout signedIn={signedIn} theme={NavbarTheme.None}>
                <Registration />
            </AppLayout>
        )
    }

    const renderEventsPage = () => {
        if (!isSignInStateKnown) {
            return
        }

        if (signedIn === SignedInState.SignedIn && !hideHome(signedIn)) {
            return (
                <AppLayout signedIn={signedIn}>
                    <Events />
                </AppLayout>
            )
        }

        return (
            <AppLayout signedIn={signedIn} theme={NavbarTheme.None}>
                <Registration />
            </AppLayout>
        )
    }

    const renderGameComponent = () => {
        if (!isSignInStateKnown || !authUser) {
            return
        }

        // After submitting the "Enter as a guest" form we are attaching displayName and email to the anonymous user.
        // However, email is not reliable because if it's being used by another account already we won't be able to attach it.
        // Hence we use displayName to differentiate subscribed users from non-subscribed ones.
        if (
            signedIn === SignedInState.SignedInAnonymously &&
            !authUser.displayName &&
            authUser.isAnonymous
        ) {
            return {
                component: renderIndexPage(),
            }
        }

        if (reason === UnavailableReasonEnum.None) {
            return {
                component: <Game />,
                theme: NavbarTheme.Transparent,
                displayEventTab: !authUser.isAnonymous,
            }
        }

        return {
            component: (
                <Modal.Dialog>
                    <Unavailable reason={reason} />
                </Modal.Dialog>
            ),
            theme: NavbarTheme.Dark,
            displayEventTab: false,
        }
    }

    const gameRoute = renderGameComponent()

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    {renderIndexPage()}
                </Route>
                <Route exact path="/events">
                    {renderEventsPage()}
                </Route>
                <Route exact path="/confirm-signup">
                    {renderIndexPage()}
                </Route>
                <Route exact path="/game/:gameId">
                    <AppLayout
                        signedIn={signedIn}
                        displayEventTab={gameRoute?.displayEventTab}
                        theme={gameRoute?.theme}
                        hideAlerts={true}
                        hideNavbar={true}
                    >
                        {gameRoute?.component}
                    </AppLayout>
                </Route>
                <Route exact path="/byog">
                    {signedIn !== SignedInState.SignedIn ? (
                        <Redirect to="/" />
                    ) : (
                        <AppLayout signedIn={signedIn} hideNavbar backButton>
                            {isSignInStateKnown &&
                                (reason === UnavailableReasonEnum.None ? (
                                    <BYOG />
                                ) : (
                                    <Modal.Dialog>
                                        <Unavailable reason={reason} />
                                    </Modal.Dialog>
                                ))}
                        </AppLayout>
                    )}
                </Route>

                <Route path="/settings/:tabId">
                    {signedIn !== SignedInState.SignedIn ? (
                        <Redirect to="/" />
                    ) : (
                        <AppLayout signedIn={signedIn} theme={NavbarTheme.None}>
                            <UserSettings />
                        </AppLayout>
                    )}
                </Route>
                <Route path="/reset-password-page*">
                    <AppLayout signedIn={signedIn} displayEventTab={false}>
                        <ResetPasswordPage />
                    </AppLayout>
                </Route>
                <Route path="/invite-beta-page*">
                    <AppLayout signedIn={signedIn} displayEventTab={false}>
                        <InviteBetaPage />
                    </AppLayout>
                </Route>
                {/* <Route path="/checkout">
                    <AppLayout signedIn={signedIn} displayEventTab={false}>
                        <Checkout />
                    </AppLayout>
                </Route> */}
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Router
