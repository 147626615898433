import makeReducer from '../makeReducer'
import * as types from './types'

export const initialState: types.HomeState = {
    alert: {
        message: '',
        variant: 'primary',
    },
    country: null,
    search: '',
    maintenance: false,
    maintenanceEnd: '',
    serverListReady: false,
    isGameModalOpened: false,
    gameModalInstance: '',
    closestCluster: '',
}

const handlers = {
    [types.HomeActionType.country](
        state: types.HomeState,
        action: types.SetCountry
    ) {
        return {
            ...state,
            country: action.payload,
        }
    },
    [types.HomeActionType.search](
        state: types.HomeState,
        action: types.SetSearch
    ) {
        return {
            ...state,
            search: action.payload,
        }
    },
    [types.HomeActionType.maintenance](
        state: types.HomeState,
        action: types.SetMaintenance
    ) {
        return {
            ...state,
            maintenance: action.payload,
        }
    },
    [types.HomeActionType.maintenanceEnd](
        state: types.HomeState,
        action: types.SetMaintenanceEnd
    ) {
        return {
            ...state,
            maintenanceEnd: action.payload,
        }
    },
    [types.HomeActionType.serverListReady](
        state: types.HomeState,
        action: types.SetServerListReady
    ) {
        return {
            ...state,
            serverListReady: action.payload,
        }
    },
    [types.HomeActionType.isGameModalOpened](
        state: types.HomeState,
        action: types.SetIsGameModalOpened
    ) {
        return {
            ...state,
            isGameModalOpened: action.payload,
        }
    },
    [types.HomeActionType.gameModalInstance](
        state: types.HomeState,
        action: types.SetGameModalInstance
    ) {
        return {
            ...state,
            gameModalInstance: action.payload,
        }
    },
    [types.HomeActionType.closestCluster](
        state: types.HomeState,
        action: types.SetClosestCluster
    ) {
        return {
            ...state,
            closestCluster: action.payload,
        }
    },
}

export default makeReducer<types.HomeState, types.HomeAction>(
    initialState,
    handlers
)
