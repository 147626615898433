import React, { useEffect, FC, useRef } from 'react'
import classnames from 'classnames'
import styles from './style.module.scss'

interface OwnProps {
    /**
     * Custom classname on input
     */
    className?: string
    limit: number
    value: string
    setValue: (value: string) => void
}

type LimitedTextAreaProps = OwnProps &
    React.TextareaHTMLAttributes<HTMLTextAreaElement> &
    React.DOMAttributes<HTMLTextAreaElement>

const LimitedTextArea: FC<LimitedTextAreaProps> = ({
    className = '',
    limit,
    value,
    setValue,
    ...rest
}) => {
    const textAreaRef = useRef(null)

    useEffect(() => {
        const textArea = textAreaRef.current

        const detectSpaceBar = (e) => {
            if (e.keyCode === 32) {
                e.stopPropagation()
            }
        }
        if (textArea) {
            textArea.addEventListener('keydown', detectSpaceBar)
        }

        return () => {
            if (textArea) {
                textArea.removeEventListener('keydown', detectSpaceBar)
            }
        }
    }, [textAreaRef])

    return (
        <div className={classnames(styles.container)}>
            <textarea
                ref={textAreaRef}
                onChange={(event) => {
                    setValue(event.target.value)
                }}
                className={classnames(styles.limitedTextArea, className)}
                value={value}
                maxLength={limit}
                wrap="hard"
                {...rest}
            />
            <p
                className={classnames(styles.limit, {
                    [styles.limitAlmostReached]:
                        value.length >= (limit * 80) / 100,
                })}
            >
                {value.length}/{limit}
            </p>
        </div>
    )
}

export default LimitedTextArea
