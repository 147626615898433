import style from './style.module.scss'
import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import SettingsModalVideoPreview from './SettingsModalVideoPreview'
import SettingsModalCamera from './SettingsModalCamera'
import SettingsModalMicrophone from './SettingsModalMicrophone'
import SettingsModalSpeaker from './SettingsModalSpeaker'
import { Modal } from '../../stories'

export interface SettingsModalsProps {
    open: boolean
    onClose: () => void
    preview: boolean
}

const SettingsModal: FC<SettingsModalsProps> = ({
    open,
    onClose,
    preview = true,
}) => {
    const { t } = useTranslation()
    return (
        <Modal
            onClose={onClose}
            show={open}
            title={t('Settings')}
            contentClassName={style.settingsModalContent}
            bodyClassName={style.settingsModalBody}
        >
            <SettingsModalVideoPreview preview={preview} />

            <SettingsModalCamera />

            <SettingsModalMicrophone />

            <SettingsModalSpeaker />
        </Modal>
    )
}

export default SettingsModal
