import classNames from 'classnames'
import React, { FC } from 'react'
import style from './style.module.scss'

interface ToggleButtonProps {
    className?: string
    value: boolean
    setValue: (value: boolean) => void
    size?: 'normal' | 'big'
    disabled?: boolean
}

const ToggleButton: FC<ToggleButtonProps> = ({
    value,
    setValue,
    size = 'normal',
    className,
    disabled = false,
}) => {
    const handleToggle = (e) => {
        if (!disabled) setValue(e.target.checked)
    }
    return (
        <>
            <label
                className={classNames(style.switch, className, {
                    [style.big]: size === 'big',
                })}
            >
                <input
                    disabled={disabled}
                    type="checkbox"
                    checked={value}
                    onChange={handleToggle}
                />
                <span className={style.slider}></span>
            </label>
        </>
    )
}

export default ToggleButton
