import cls from 'classnames'
import styles from './style.module.scss'
import React from 'react'
import { Icon } from '../Icon'

export interface TagProps {
    title: string
    icon?: string
    isSelected: boolean
    onClick: () => void
    className?: string
    disabled?: boolean
}

const Tag: React.FC<TagProps> = ({
    title,
    icon = '',
    isSelected,
    onClick,
    disabled,
    className,
}) => (
    <button
        data-testid={'tag'}
        disabled={disabled}
        onClick={onClick}
        className={cls(className, styles.tags, isSelected && styles.selected)}
    >
        {icon && <Icon name={icon} />}
        {title}
    </button>
)

export default Tag
