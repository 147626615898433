import styles from './styles.module.scss'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Media from './Media'
import { cardBgUrl } from './constants'
import { ICard, CardKind } from './types'
import { FilterTag, Icon } from '../../../stories'
import { useWindowSize } from '@react-hook/window-size'
import classnames from 'classnames'

const CardBody: FC<ICard & CardKind & { loadVideo: boolean }> = ({
    game,
    category,
    loadVideo,
    availablePublicRooms,
    ...props
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowSize()
    const smallWindowSize = 768
    const isBYOG = game.Categories?.includes('BYOG')
    const showVideo = windowWidth > smallWindowSize && !isBYOG

    return (
        <div
            className={classnames(styles.innerCard, {
                [styles.bgWhite]: isBYOG,
            })}
        >
            {loadVideo && showVideo && (
                <Media gameId={game.id} kind={props.kind} />
            )}
            <div className={styles.cardHeader}>
                {game.IsNew && (
                    <FilterTag
                        size="small"
                        theme="danger"
                        className={styles.newTag}
                    >
                        {t('New')?.toUpperCase()}
                    </FilterTag>
                )}
                <div className={styles.cardActivty}>
                    {availablePublicRooms > 0 && (
                        <FilterTag size="small">
                            <div className={styles.cardActivtyItem}>
                                <Icon name="people" />
                                <span>{availablePublicRooms}</span>
                            </div>
                        </FilterTag>
                    )}
                </div>
            </div>
            <picture
                className={classnames({
                    [styles.cardPicture]: !isBYOG,
                    [styles.cardPictureBYOG]: isBYOG,
                })}
            >
                <img
                    src={
                        isBYOG
                            ? cardBgUrl(game.System, 'landscape')
                            : cardBgUrl(game.id, props.kind)
                    }
                    alt={game.id.split(' ').join('-') + '-thumbnail'}
                    className={classnames({
                        [styles.cardImg]: !isBYOG,
                        [styles.cardImgBYOG]: isBYOG,
                    })}
                />
            </picture>
            {['featured', 'recent'].includes(props.kind) && (
                <div className={styles.cardDescription}>
                    <div className={styles.cardTitle}>{props.title}</div>
                    <div>
                        {props.kind === 'featured' && (
                            <div className={styles.cardSubTitle}>
                                {props.introText}
                            </div>
                        )}
                        {props.kind === 'recent' && props.date && (
                            <FilterTag
                                theme="transparent"
                                size="small"
                                className={styles.dateTag}
                            >
                                {props.date}
                            </FilterTag>
                        )}
                    </div>
                </div>
            )}
            {props.kind === 'generic' && (
                <div className={styles.cardDescription}>
                    <div className={styles.cardTitle}>{props.title}</div>
                </div>
            )}
        </div>
    )
}

export default CardBody
