import React, { useRef } from 'react'

export default function Logo({
    logoKind,
    gameID,
}: {
    logoKind: string
    gameID: string
}) {
    const logoImg = useRef(null)
    const baseURL =
        'https://fassets.piepacker.com/logos/' + encodeURIComponent(gameID)

    return (
        <div className={logoKind}>
            <picture className="logo-img" ref={logoImg}>
                <source srcSet={baseURL + `.webp`} type="image/webp" />
                <source srcSet={baseURL + `.png`} type="image/png" />
                <img
                    src={baseURL + `.png`}
                    alt={gameID.split(' ').join('-') + '-logo'}
                    className="logo-img"
                    ref={logoImg}
                />
            </picture>
        </div>
    )
}
