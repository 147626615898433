import React, { FC, Suspense } from 'react'
import { IconComponents } from './IconComponents'

export type IconName = keyof typeof IconComponents

interface IconProps {
    name: string
    fill?: string
    [rootHtmlAttributes: string]: any
}

const Icon: FC<IconProps> = ({
    name,
    fill = '#FFF',
    ...rootHtmlAttributes
}) => {
    const IconComponent = IconComponents[name]

    if (!IconComponent) return null // If the icon is not found, render nothing

    return (
        <Suspense fallback={<></>}>
            <IconComponent fill={fill} {...rootHtmlAttributes} />
        </Suspense>
    )
}

export default Icon
