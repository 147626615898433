import { useCallback, useRef } from 'react'
import store from '../../store'
import { State } from '../../store/types'
import { formatDate, isByogMode } from '../../utils'
import firebase from 'firebase/compat/app'
import { getSession } from '../../firebase'
import {
    FREE_SAVE_LIMIT,
    isPremium,
    PREMIUM_SAVE_LIMIT,
} from '../../utils/subscription'
import screenSlice from '../../store/screen/screen'
import { useSelector } from 'react-redux'
import { SaveStatesType } from '../../state/saveStates'

import { ConfirmDialog } from '../../state/confirm'
import { usePausePlay } from './usePausePlay'

export const useSaveStates = (
    callback: () => void,
    setConfirmDialog?: (state) => void,
    setShowOverrideDialog?: (state) => void
) => {
    const { handlePauseGame } = usePausePlay()
    const features = useSelector((state: State) => state.user.features)
    const pendingRemove = useRef(null)
    const isHost = useSelector((state: State) => state.session.isHost)
    const game = useSelector((state: State) => state.session.game)

    const handleSaveStates = useCallback(async () => {
        if (!isHost) {
            return
        }
        const storageRef = firebase.storage().ref()

        let gameName = game?.id
        const user = store.getState().user.extra

        if (isByogMode(gameName)) {
            const session = await getSession(store.getState().session.sessionID)
            const lastGameIndex = session.Games.length - 1
            gameName = 'BYOG/' + session.Games[lastGameIndex].GameID
        }

        const savestatesRef = storageRef.child(
            `/user/${user.ID}/savestates/${gameName}`
        )

        const stt = await savestatesRef.listAll()
        const premium = isPremium(user) || features.UNLIMITEDSAVESTATES
        const limit = premium ? PREMIUM_SAVE_LIMIT : FREE_SAVE_LIMIT
        const dialog = premium
            ? ConfirmDialog.OverridePremium
            : ConfirmDialog.Override

        const savestates: SaveStatesType[] = []

        Object.values(stt.items).forEach((item) => {
            if (item.name.endsWith('.state')) {
                const name = item.name.split('.state')[0]
                if (
                    name.indexOf('auto') === -1 &&
                    name.indexOf('.crc') === -1
                ) {
                    const chunks = name.split('-')
                    const gmtDate = `${chunks[0]}/${formatDate(
                        chunks[1]
                    )}/${formatDate(chunks[2])}`
                    const gmtTime = `${formatDate(chunks[3])}:${formatDate(
                        chunks[4]
                    )}:${formatDate(chunks[5])}`
                    const gmt = new Date(`${gmtDate} ${gmtTime} GMT`)

                    savestates[name] = {
                        name: name,
                        date: '',
                        time: '',
                        gmt: gmt,
                        url: '',
                    }
                }
            }
        })

        const saves: SaveStatesType[] = []
        for (const k of Object.keys(savestates)) {
            saves[savestates[k].gmt.getTime()] = {
                name: savestates[k].name,
                date: savestates[k].date,
                time: savestates[k].time,
                gmt: savestates[k].gmt,
                url: savestates[k].url,
            }
        }

        if (Object.keys(saves).length < limit) {
            // we just save if the user did not reach the limit
            callback()
        } else {
            // if the user reached the limit, we ask to override the last one
            const key = Object.keys(saves).sort().reverse()[0]
            pendingRemove.current = saves[key].name
            store.dispatch(screenSlice.actions.setTheater(false))
            store.dispatch(screenSlice.actions.setFullscreen(false))
            handlePauseGame()
            setShowOverrideDialog && setShowOverrideDialog(true)
            setConfirmDialog && setConfirmDialog(dialog)
        }
    }, [
        game,
        callback,
        features,
        isHost,
        setConfirmDialog,
        setShowOverrideDialog,
        handlePauseGame,
    ])

    const removeLast = useCallback(async () => {
        if (!pendingRemove.current) return
        const storageRef = firebase.storage().ref()
        let gameName = store.getState().session.gameName
        const user = store.getState().user.extra
        if (isByogMode(gameName)) {
            const session = await getSession(store.getState().session.sessionID)
            const lastGameIndex = session.Games.length - 1
            gameName = 'BYOG/' + session.Games[lastGameIndex].GameID
        }
        const savestatesRef = storageRef.child(
            `/user/${user.ID}/savestates/${gameName}/${pendingRemove.current}.state`
        )
        const screenshotsRef = storageRef.child(
            `/user/${user.ID}/screenshots/${gameName}/${pendingRemove.current}.png`
        )
        await savestatesRef.delete()
        await screenshotsRef.delete()
    }, [])

    return { handleSaveStates, removeLast }
}
