import { subscribeToChatMessages } from '../../../firebase'
import { Message } from '../../../state/faces'

export const unsubscribeToChatMessage = (
    sessionID: string,
    userSessionID: string,
    setMessageGroups: (x) => void
) => {
    subscribeToChatMessages(sessionID).onSnapshot((snapshot) => {
        let lastUserSessionID = ''
        const messageGroups = []
        let group = { UserSessionID: '', messages: [], fromself: false }

        snapshot.forEach((doc) => {
            const msg = doc.data() as Message

            msg.fromself = msg.UserSessionID === userSessionID

            if (lastUserSessionID !== msg.UserSessionID) {
                if (lastUserSessionID !== '') {
                    messageGroups.push(group)
                }

                group = {
                    UserSessionID: msg.UserSessionID,
                    messages: [],
                    fromself: msg.UserSessionID === userSessionID,
                }
                lastUserSessionID = msg.UserSessionID
            }
            group.messages.push(msg)
        })

        if (group.messages.length > 0) messageGroups.push(group)

        setMessageGroups(messageGroups)
    })
}
