import React, { FC } from 'react'
import styles from './styles.module.scss'
import { getVideoMedia } from './constants'
import { Game } from '../../../state/starfox'

export interface MediaProps {
    gameId: Game['id']
    kind: string
}

const Media: FC<MediaProps> = ({ gameId, kind }) => {
    const videoUrl = getVideoMedia({ gameId, kind })
    return (
        <video
            loop
            muted
            autoPlay
            key={gameId}
            className={styles.cardVideo}
            src={videoUrl}
        ></video>
    )
}

export default Media
