import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Select, MenuItem } from '@material-ui/core'
import { Icon } from '../../stories'
import style from './styles.module.scss'
import { LocalStorage } from '../../state/storage'
import {
    selectCurrentView,
    selectShowLanguageSelect,
    setCurrentView,
} from '../../store/auth/authV2'
import { State } from '../../store/types'
import { AuthModalType } from '../../store/auth/types'
import { useLocation } from 'react-router-dom'
import { languages } from './constants'

const registrationTitle = {
    Login: 'SignInToContinue',
    SignUp: 'SignUp',
    SignUpMenu: 'SignUp',
    EnterAsGuest: 'JoinAsAGuest',
    Banned: 'YouHaveBeenBanned',
}

const Title: FC = () => {
    const { i18n, t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const view = useSelector(selectCurrentView)
    const lang = localStorage.getItem(LocalStorage.i18nextLng)
    const authUser = useSelector((state: State) => state.user.user)
    const showLanguageSelect = useSelector(selectShowLanguageSelect)
    const showEnterAsGuestArrow =
        location.pathname !== '/' &&
        !authUser?.displayName &&
        view !== AuthModalType.EnterAsGuest
    const handleLanguageSelect = (e) => i18n.changeLanguage(e.target.value)

    return registrationTitle[view] ? (
        <div className={style.titleWrapper}>
            <div className={style.flexAlign}>
                {showEnterAsGuestArrow && (
                    <div
                        className={style.topBackArrow}
                        onClick={() =>
                            dispatch(setCurrentView(AuthModalType.EnterAsGuest))
                        }
                    >
                        <Icon name="arrowBack" />
                    </div>
                )}
                {view === AuthModalType.SignUp && !showEnterAsGuestArrow && (
                    <div
                        className={style.topBackArrow}
                        onClick={() =>
                            dispatch(setCurrentView(AuthModalType.SignUpMenu))
                        }
                    >
                        <Icon name="arrowBack" />
                    </div>
                )}
                <div className={style.title}>{t(registrationTitle[view])}</div>
            </div>
            {showLanguageSelect && (
                <Select
                    value={lang}
                    defaultValue={lang}
                    disableUnderline
                    onChange={handleLanguageSelect}
                    className={style.nativeDropDown}
                    classes={{
                        select: style.nativeDropDownSelect,
                    }}
                    MenuProps={{
                        classes: {
                            paper: style.nativeDropDownPaper,
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {languages.map(({ code, text }) => (
                        <MenuItem value={code} key={code}>
                            {text}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </div>
    ) : null
}

export default Title
