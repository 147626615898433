import React, { FC, useState, useEffect } from 'react'
import * as Axios from 'axios'
import firebase from 'firebase/compat/app'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { AlertType } from '../../state/alert'
import { Alert, TextLink } from '../../stories'
import { AuthModalType } from '../../store/auth/types'
import { setCurrentView } from '../../store/auth/authV2'
import { logAxiosErrorResponse } from '../../utils/http'
import { getTransactionEmailServiceHostname, useQuery } from '../../utils'

const ConfirmEmail: FC = () => {
    const { t } = useTranslation()
    const urlParams = useQuery()
    const location = useLocation()
    const dispatch = useDispatch()
    const [isLinkBroken, setIsLinkBroken] = useState(false)
    const [apiError, setApiError] = useState('')
    const [apiErrorStatus, setApiErrorStatus] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const user = firebase.auth().currentUser
        if (user?.displayName && user?.email) {
            window.location.href = '/'
            return
        }

        const OOBToken = urlParams.get('OOBtoken')
        const emailLink = urlParams.get('emailLink')

        if (!OOBToken || !emailLink) {
            setIsLinkBroken(true)
            return
        }

        analytics.track('ConfirmEmail', {})

        const confirmEmail = async () => {
            try {
                await Axios.default.post(
                    `${getTransactionEmailServiceHostname()}/confirmEmail`,
                    {
                        emailHash: emailLink,
                        oobtoken: OOBToken,
                    }
                )
                window.location.href = '/?emailConfirmed'
                return
            } catch (error) {
                setApiErrorStatus(error.response?.status)
                setApiError(error.response?.data)
                logAxiosErrorResponse(error)
                // We set isLoading to false only when the request fails, so we can display an error message and the link to sign up.
                // In case of a successful response, we only want to display an informational message about that the email confirmation being in progress.
                // If we set isLoading to true in case of the successful response while redirecting to the login page
                // user will be able to see a piece of information that is designed for the failure case.
                setIsLoading(false)
            }
        }
        confirmEmail()
    }, [location.search, urlParams])

    return (
        <div>
            <div className={style.titleWrapper}>
                <h1 className={style.title}>
                    {isLoading
                        ? t('ConfirmingEmailAddress')
                        : t('EmailConfirmation')}
                </h1>
            </div>
            {isLinkBroken && (
                <Alert
                    className={style.alert}
                    message={t('AuthConfirmLinkBroken')}
                    animate={false}
                    type={AlertType.Error}
                />
            )}
            {apiError && (
                <Alert
                    className={style.alert}
                    message={apiError}
                    animate={false}
                    type={AlertType.Error}
                />
            )}
            {isLoading && (
                <div className={style.altFooter}>{t('WontTakeLong')}</div>
            )}
            {!isLoading && (
                <div className={style.altFooter}>
                    {apiErrorStatus && apiErrorStatus === 400 ? (
                        <TextLink
                            onClick={() =>
                                dispatch(setCurrentView(AuthModalType.Login))
                            }
                        >
                            <b>{t('SignIn')}</b>
                        </TextLink>
                    ) : (
                        <Trans i18nKey="PleaseSignUpAgain">
                            Please try
                            <TextLink
                                onClick={() =>
                                    dispatch(
                                        setCurrentView(AuthModalType.SignUpMenu)
                                    )
                                }
                            >
                                <b>{t('SignUp')}</b>
                            </TextLink>
                            again.
                        </Trans>
                    )}
                </div>
            )}
        </div>
    )
}

export default ConfirmEmail
