import { useState, useEffect, useCallback, useRef } from 'react'

const createScrollStopListener = (element, callback) => {
    let removed = false
    let handle = null
    const onScroll = () => {
        if (handle) {
            clearTimeout(handle)
        }
        handle = setTimeout(callback, 60)
    }
    element.addEventListener('scroll', onScroll)
    return () => {
        if (removed) {
            return
        }
        removed = true
        if (handle) {
            clearTimeout(handle)
        }
        element.removeEventListener('scroll', onScroll)
    }
}

export const useSlider = () => {
    const sliderRef = useRef<HTMLDivElement>(null)
    const [prevDisable, setPrevDisable] = useState<boolean>(true)
    const [nextDisable, setNextDisable] = useState<boolean>(true)
    const [totalInViewport, setTotalInViewport] = useState(0)
    const [page, setPage] = useState(0)
    const clientWidth = sliderRef.current?.clientWidth
    const itemGap = 16
    const itemWidth = sliderRef.current?.firstElementChild?.clientWidth
    const scrollBy = (itemWidth + itemGap) * totalInViewport
    const totalItems = sliderRef.current?.childElementCount

    useEffect(() => {
        setTotalInViewport(Math.floor(clientWidth / Math.max(itemWidth, 1)))
    }, [clientWidth, itemWidth])

    useEffect(() => {
        const destroyListener = createScrollStopListener(
            sliderRef.current,
            () => {
                const currentPage = Math.ceil(
                    (sliderRef.current.scrollLeft - itemGap * totalInViewport) /
                        scrollBy
                )
                setPage(currentPage)
            }
        )
        return () => destroyListener()
    }, [page, scrollBy, totalInViewport, sliderRef])

    const intersectionCallback: IntersectionObserverCallback = useCallback(
        (entries) => {
            const { current } = sliderRef
            entries.forEach(({ target, isIntersecting }): void => {
                const isFirstItemVisible = target === current?.firstElementChild
                const isLastItemVisible = target === current?.lastElementChild

                if (isFirstItemVisible) {
                    setPrevDisable(isIntersecting)
                }
                if (isLastItemVisible) {
                    setNextDisable(isIntersecting)
                }
            })
        },
        [sliderRef]
    )

    useEffect(() => {
        const { current } = sliderRef
        const observer = new IntersectionObserver(intersectionCallback, {
            root: current,
            threshold: 0.95,
        })

        if ('IntersectionObserver' in window) {
            Array(current).forEach((item) => {
                if (item.firstElementChild && item.lastElementChild) {
                    observer.observe(item.firstElementChild)
                    observer.observe(item.lastElementChild)
                }
            })
        }
        return () => {
            observer.disconnect()
        }
    }, [sliderRef, intersectionCallback])

    const handlePrev = useCallback(
        () => (sliderRef.current.scrollLeft -= scrollBy),
        [scrollBy, sliderRef]
    )

    const handleNext = useCallback(
        () => (sliderRef.current.scrollLeft += scrollBy),
        [scrollBy, sliderRef]
    )

    const handleDotClick = useCallback(
        (position: number) => {
            setPage(position)
            sliderRef.current.scrollLeft = scrollBy * position
        },
        [scrollBy, sliderRef]
    )

    return {
        prevDisable,
        nextDisable,
        handlePrev,
        handleNext,
        sliderRef,
        dots: Math.ceil(totalItems / totalInViewport),
        page,
        handleDotClick,
    }
}
