import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { EVENT_TAB } from '../../utils'
import { useHistory } from 'react-router-dom'

export default function ESLTabs({ selectedDropdownItem, setSelectedESLTab }) {
    const { t } = useTranslation()
    const history = useHistory()

    const baseURLESL = 'url(https://fassets.piepacker.com/esl-assets/'

    const handleClickOnESLTab = useCallback(async () => {
        analytics.track('GameStarted', {
            game: selectedDropdownItem.gameLink,
        })

        history.push('/game/' + selectedDropdownItem.gameLink)
    }, [history, selectedDropdownItem])

    return (
        <div className="ESLTabs">
            {selectedDropdownItem.tabs.includes(EVENT_TAB.NEXT_EVENT) && (
                <div
                    onMouseOver={() => setSelectedESLTab(EVENT_TAB.NEXT_EVENT)}
                    className="ESLTabItemImage"
                    style={{
                        background:
                            baseURLESL +
                            t(selectedDropdownItem.nextEvent) +
                            '.png) 0 -15px no-repeat',
                        backgroundSize: 'cover',
                    }}
                ></div>
            )}
            {selectedDropdownItem.tabs.includes(EVENT_TAB.CLICK_AND_PLAY) && (
                <div
                    onClick={handleClickOnESLTab}
                    onMouseOver={() =>
                        setSelectedESLTab(EVENT_TAB.CLICK_AND_PLAY)
                    }
                    className="ESLTabItemImage ESLTabItemImageClickable"
                    style={{
                        background:
                            baseURLESL +
                            t('ClickPlay') +
                            '.svg) 0 -15px no-repeat',
                        backgroundSize: 'cover',
                    }}
                ></div>
            )}
            {selectedDropdownItem.tabs.includes(EVENT_TAB.VIEW_LEADERBOARD) && (
                <div
                    onMouseOver={() =>
                        setSelectedESLTab(EVENT_TAB.VIEW_LEADERBOARD)
                    }
                    className="ESLTabItemImage"
                    style={{
                        background:
                            baseURLESL +
                            t('Leaderboard') +
                            '.svg) 0 -15px no-repeat',
                        backgroundSize: 'cover',
                    }}
                ></div>
            )}
        </div>
    )
}
