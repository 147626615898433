import React, { useRef, useCallback, useEffect, useState, FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface DragAndDropProps {
    setFile: (file: File) => void
    className?: string
}

const DragAndDrop: FC<DragAndDropProps> = ({
    setFile,
    className = '',
    children,
}) => {
    const dropRef = useRef(null)
    const isFetched = useRef(false)
    const dragCounter = useRef(0)
    const [dragging, setDragging] = useState(false)

    const handleDrag = useCallback((e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleDragIn = useCallback((e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current += 1
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }, [])

    const handleDragOut = useCallback((e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current -= 1
        if (dragCounter.current > 0) return
        setDragging(false)
    }, [])

    const handleDrop = useCallback((e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFile(e.dataTransfer.files[0])
            e.dataTransfer.clearData()
            dragCounter.current = 0
        }
        // we disable the eslint here because if we add the dependency then the function won't work as expected and the drag will fail
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const div = dropRef.current
        if (!isFetched.current) {
            // on mount
            isFetched.current = true
            div.addEventListener('dragenter', handleDragIn)
            div.addEventListener('dragleave', handleDragOut)
            div.addEventListener('dragover', handleDrag)
            div.addEventListener('drop', handleDrop)
        }

        return () => {
            // on unmount
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
            dragCounter.current = 0
        }
    }, [handleDragIn, handleDragOut, handleDrag, handleDrop])

    return (
        <div className={cls(style.dragContainer, className)} ref={dropRef}>
            <div
                className={
                    dragging
                        ? style.onDragContainerActive
                        : style.onDragContainer
                }
            >
                {dragging && <div className={style.onDrag} />}
                {children}
            </div>
        </div>
    )
}

export default DragAndDrop
