import React, { FC, useState } from 'react'
import cls from 'classnames'
import { useFormik } from 'formik'
import style from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import UpdateCheck from './UpdateCheck'
import { useAlert } from '../useAlert'
import PrivacyCheck from '../PrivacyCheck'
import { useValidation } from './validation'
import { Button, Input } from '../../../stories'
import AlternativeSignIn from '../AlternativeSignIn'
import { usePrivacyCheck } from '../usePrivacyCheck'
import { handleEmailSignUp } from '../../../store/auth/services'

const SignUpWithEmail: FC = () => {
    useAlert()
    const { t } = useTranslation()
    const location = useLocation()
    const [subscribedtoUpdates, setSubscribedtoUpdates] = useState(false)
    const { privacyError, setPrivacyError, checkPrivacy, setCheckPrivacy } =
        usePrivacyCheck()

    const validationSchema = useValidation()

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: '',
            displayName: '',
            passwordConfirmation: '',
        },

        onSubmit: async (values, { setSubmitting }) => {
            if (!checkPrivacy) {
                setPrivacyError(true)
                setSubmitting(false)
                return
            }

            await handleEmailSignUp({ values, location, subscribedtoUpdates })
            setSubmitting(false)
        },
    })

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        isSubmitting,
    } = formik
    const { email, password, displayName, passwordConfirmation } = values

    return (
        <form className={style.form} onSubmit={handleSubmit}>
            <div className={style.inputWrapper}>
                <div>
                    <label>{t('EmailAddress')}</label>
                    <Input
                        name="email"
                        value={email}
                        onChange={handleChange}
                        autoComplete={'email'}
                        placeholder={t('EmailAddress')}
                    />
                    {touched.email && errors.email && (
                        <div className={style.errorMsg}>{errors.email}</div>
                    )}
                </div>
                <div>
                    <label>{t('Nickname')}</label>
                    <Input
                        name="displayName"
                        value={displayName}
                        placeholder={t('YourNickname')}
                        onChange={handleChange}
                    />
                    {touched.displayName && errors.displayName && (
                        <div className={style.errorMsg}>
                            {errors.displayName}
                        </div>
                    )}
                </div>
                <div>
                    <label>{t('Password')}</label>
                    <Input
                        name="password"
                        type="password"
                        value={password}
                        placeholder={t('Password')}
                        onChange={handleChange}
                        autoComplete={'current-password'}
                    />
                    {touched.password && errors.password && (
                        <div className={style.errorMsg}>{errors.password}</div>
                    )}
                </div>
                <div>
                    <label>{t('ConfirmPassword')}</label>
                    <Input
                        name="passwordConfirmation"
                        type="password"
                        value={passwordConfirmation}
                        placeholder={t('Password')}
                        onChange={handleChange}
                    />
                    {touched.passwordConfirmation &&
                        errors.passwordConfirmation && (
                            <div className={style.errorMsg}>
                                {errors.passwordConfirmation}
                            </div>
                        )}
                </div>
            </div>
            <div className={style.checkUpdatesContainer}>
                <UpdateCheck
                    checkUpdates={subscribedtoUpdates}
                    setCheckUpdates={setSubscribedtoUpdates}
                />
                <PrivacyCheck
                    privacyError={privacyError}
                    hasAgreedToTerms={checkPrivacy}
                    setPrivacyError={setPrivacyError}
                    setHasAgreedToTerms={setCheckPrivacy}
                />
            </div>
            <Button
                className={cls(style.btn, style.submitButton)}
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {t('SignUp')}
            </Button>
            <div className={style.altFooterWrapper}>
                <AlternativeSignIn />
            </div>
        </form>
    )
}

export default SignUpWithEmail
