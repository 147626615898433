import { piepie } from '../utils'

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS
        dataLayer: any
    }
}

const init = (env: string): void => {
    piepie.log('segment init')
    if (env === 'PROD' && process.env.REACT_APP_PREVIEW === 'false') {
        window.analytics.load(ProdSegmentAPIKey)
    } else if (env === 'PROD' && process.env.REACT_APP_PREVIEW === 'true') {
        window.analytics.load(PreviewSegmentAPIKey)
    } else {
        window.analytics.load(StagingSegmentAPIKey)
    }
    window.analytics.page({
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        availScreenHeight: window.screen.availHeight,
        availScreenWidth: window.screen.availWidth,
    })
    return
}

export const ProdSegmentAPIKey = 'Py3jGiWhev3rDnrX4F7HdyEBQXSatstM'
export const StagingSegmentAPIKey = 'Ih6dB7Bc9wa9xOqFNDqlaVLEyes8ciAB'

// Preview as a dedicated source when in prod
export const PreviewSegmentAPIKey = '39MqsyMvK2rGqtaiBPSMdzo7HtZGn8pJ'

export default {
    page: window.analytics.page,
    track: (event: string, properties?: any): void => {
        piepie.log('segment track', event, properties)
        window.analytics.track(event, properties)
    },
    identify: (userId: string, traits?: any): void => {
        piepie.log('segment identify', userId, traits)
        window.analytics.identify(userId, traits)
    },
    init,
}
