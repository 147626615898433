import { CookieStorage } from '../state/storage'

const OneDay = 1 * 24 * 60 * 60 * 1000

export const setCookie = (
    name: CookieStorage,
    value: string,
    expiration = OneDay
): void => {
    const d = new Date()
    d.setTime(d.getTime() + expiration)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export const getCookie = (cname: CookieStorage): string => {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export const deleteCookie = (name: CookieStorage): void => {
    const date = new Date()
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}
