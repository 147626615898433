import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
import { Drawer } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { MenuItems } from '../types'
import { Icon } from '../../../stories'
import { isEnvDev, isEnvStaging } from '../../../utils'

interface IOwnProps {
    menus: MenuItems[]
    currentMenu: MenuItems
}

const Menu: React.FC<IOwnProps> = ({ menus, currentMenu }) => {
    const { t } = useTranslation()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const handleDrawerToggle = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const handleItemClick = (menu: MenuItems) => {
        handleDrawerToggle()
    }

    return (
        <div>
            <div
                className={cls(
                    styles.burgerIcon,
                    sidebarOpen && styles.burgerIconAnimate
                )}
                onClick={handleDrawerToggle}
            >
                <Icon name="menu" />
            </div>
            <Drawer
                variant="temporary"
                open={sidebarOpen}
                onClose={handleDrawerToggle}
                classes={{
                    paper: styles.drawerPaper,
                    root: styles.mobileDrawer,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <ul className={styles.settingsMenu}>
                    {menus.map((menu) => (
                        <li
                            key={menu}
                            onClick={() => handleItemClick(menu)}
                            className={cls(styles.option, {
                                [styles.active]: currentMenu === menu,
                            })}
                        >
                            <span>{t(menu)}</span>
                        </li>
                    ))}
                </ul>
            </Drawer>
            <Drawer
                variant="permanent"
                classes={{
                    paper: styles.drawerPaper,
                    root: styles.desktopDrawer,
                }}
                open
            >
                <ul className={styles.settingsMenu}>
                    {menus.map(
                        (menu) =>
                            /* for now we hide Connections on prod */
                            (menu !== MenuItems.Connections ||
                                isEnvDev ||
                                isEnvStaging) && (
                                <Link
                                    to={menu}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li
                                        key={menu}
                                        className={cls(styles.option, {
                                            [styles.active]:
                                                currentMenu === menu,
                                        })}
                                    >
                                        <span>{t(menu)}</span>
                                    </li>
                                </Link>
                            )
                    )}
                </ul>
            </Drawer>
        </div>
    )
}

export default Menu
