import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum GamePadStatus {
    Connected,
    Disconnected,
}

export interface GamePadEvent {
    status: GamePadStatus
    gamepad: Gamepad
}

export enum SwitchPlayerIdxEventType {
    RequestSwitchIndices,
    ReceiveSwitchIndices,
}

export interface SwitchPlayerIdxEvent {
    type: SwitchPlayerIdxEventType
    indices: number[]
}

export enum SwitchPlayerIndxRequest {
    Initial, // no game pad detected
    Connected,
    Disconnected,
}

export interface InputState {
    gamePadEvents: GamePadEvent[]
    switchPlayerIdxEvents: SwitchPlayerIdxEvent[]
    localHost: number
}

export const initialState = {
    switchPlayerIdxEvents: [],
    gamePadEvents: [],
    localHost: -1,
}

const inputSlice = createSlice({
    name: 'input',
    initialState: initialState,
    reducers: {
        addGamePadEvent: (state, action: PayloadAction<GamePadEvent>) => {
            state.gamePadEvents.push(action.payload)
        },
        addSwitchPlayerIdxEvent: (
            state,
            action: PayloadAction<SwitchPlayerIdxEvent>
        ) => {
            state.switchPlayerIdxEvents.push(action.payload)
        },
        setLocalHost: (state, action: PayloadAction<number>) => {
            state.localHost = action.payload
        },
    },
})

export type InputAction =
    | ReturnType<typeof inputSlice.actions.addGamePadEvent>
    | ReturnType<typeof inputSlice.actions.addSwitchPlayerIdxEvent>
    | ReturnType<typeof inputSlice.actions.setLocalHost>

export default inputSlice
