const DEFAULT_OPTIONS = {
    pow: 3,
    w: 0.1,
}
//Object.assign(DEFAULT_OPTIONS, {pow: 3, w:10})

function create_decryptionMatrix(THREE, id, pow) {
    // take last 9 characters and get their ascii codes to create l
    const lastChars = id.slice(-9)
    const b = []
    for (let i = 0; i < 9; ++i) {
        const v = lastChars[i].charCodeAt()
        b.push(Math.pow(v, pow))
    }

    // use naming of the README.md
    const B = new THREE.Matrix3().set(
        b[0],
        b[1],
        b[2],
        b[3],
        b[4],
        b[5],
        b[6],
        b[7],
        b[8]
    )

    // create M', which is a strictly diagonally dominant matrix, so invertible
    const MPrim = B.clone()
    const me = MPrim.elements // elements are stored transposed in THREE
    me[0] += me[3] + me[6] + 1
    me[4] += me[1] + me[7] + 1
    me[8] += me[2] + me[5] + 1

    // normalize
    const maxDiag = Math.max(me[0], me[4], me[8])
    const M = MPrim.clone().multiplyScalar(1.0 / maxDiag)

    return M
}

// in GLSL all float should end by .
function floatify(str) {
    const arrStr = str.split(',')
    const arrStrFloatified = arrStr.map((v) => {
        return v.includes('.') ? v : v + '.'
    })
    return arrStrFloatified.join(',')
}

function untweak_three(THREE) {
    THREE.ShaderChunk.begin_vertex = 'vec3 transformed = vec3( position );'
}

function tweak_threeToDecrypt(THREE, userId, optionsArg = DEFAULT_OPTIONS) {
    const options = Object.assign({}, DEFAULT_OPTIONS, optionsArg)
    const M = create_decryptionMatrix(THREE, userId, options.pow)

    // convert decryption params to string to be inserted into GLSL shader code:
    const wStr = floatify(options.w.toString())
    const MStr = floatify(M.elements.toString())

    // now we will apply the inverse operation as GLBEncryptor.apply_encryptTransforms
    // but in the begin_vertex shader chunk
    const GLSLDecryptTransform = `
  // original begin_vertex shader chunk:
  vec3 transformed = vec3( position );

  float isEncrypted = step(2.0, dot(normal, normal));
  float normalFactor = 1.0 / (1.0 + isEncrypted);
  //isEncrypted = 1.0;// normalFactor = 1.0;
  vec3 decryptedTransformed = mat3(${MStr}) * transformed;
  decryptedTransformed /= vec3(1.1) + cos(normal*normalFactor * ${wStr});
  transformed = mix(transformed, decryptedTransformed, isEncrypted);
  `
    THREE.ShaderChunk.begin_vertex = GLSLDecryptTransform
}

function extract_n00bFingerPrint(gltf) {
    let fp = null
    gltf.scene.traverse((threeNode) => {
        if (
            threeNode &&
            threeNode.userData &&
            threeNode.userData.PiepackerUserId
        ) {
            fp = threeNode.userData.PiepackerUserId
        }
    })
    return fp
}

function extract_l33tFingerPrint(gltf) {
    // extract geometry encoding the fingerprint:
    let fpGeom = null
    gltf.scene.traverse((threeNode) => {
        if (
            threeNode &&
            threeNode.isMesh &&
            threeNode.name === '1337F1n63RPr1n7'
        ) {
            fpGeom = threeNode.geometry
        }
    })
    if (!fpGeom) return null

    // decode the fingerprint:
    let fp = ''
    const arr = fpGeom.index.array
    for (let i = 0; i < arr.length; ++i) {
        const asciiCode = arr[i]
        if (asciiCode === 256) {
            break
        }
        fp += String.fromCharCode(asciiCode)
    }
    return fp
}

export default {
    tweak_threeToDecrypt,
    untweak_three,
    extract_n00bFingerPrint,
    extract_l33tFingerPrint,
}
