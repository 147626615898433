import React, { useState, FC } from 'react'
import firebase from 'firebase/compat/app'
import * as Axios from 'axios'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import 'firebase/auth'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'
import { InputText } from '../Form/fields'
import style from './style.module.scss'
import { getTransactionEmailServiceHostname, MARIO_API_URL } from '../../utils'
import { validateNickname } from '../../utils/validation'
import inlineStyle from './inlineStyles'
import { useDispatch } from 'react-redux'
import { closeAuthModal, openAuthModal } from '../../store/auth/actions'
import analytics from '../../analytics'
import { LocalStorage } from '../../state/storage'
import { AuthModalType } from '../../store/auth/types'
import { logAxiosErrorResponse } from '../../utils/http'

Yup.addMethod(Yup.string, 'nickname', validateNickname)

const GuestSubscriptionForm: FC = () => {
    const { t } = useTranslation()
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const [privacyError, setPrivacyError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [apiError, setApiError] = useState('')
    const dispatch = useDispatch()

    const onSubmit = async ({ displayName }) => {
        setApiError('')
        if (!checkPrivacy) {
            setPrivacyError(true)
            return
        }

        const user = firebase.auth().currentUser
        const trimmedDisplayName = displayName.replace(/  +/g, ' ').trim()
        try {
            await Axios.default.post(
                `${getTransactionEmailServiceHostname()}/bodyguard`,
                {
                    uid: user.uid,
                    text: trimmedDisplayName,
                }
            )
        } catch (error) {
            logAxiosErrorResponse(error)
            setApiError(error.response.data)
            return
        }
        setIsSubmitting(true)
        analytics.track('EnterAsGuest', {
            displayName: trimmedDisplayName,
            uid: user.uid,
        })
        try {
            await Axios.default.post(`${MARIO_API_URL}/users/enter-as-guest`, {
                id: user.uid,
                displayName: trimmedDisplayName,
                language:
                    i18next.language ||
                    window.localStorage.getItem(LocalStorage.i18nextLng) ||
                    'en',
            })
            dispatch(closeAuthModal())
            await user.updateProfile({ displayName })
            window.location.reload()
        } catch (error) {
            logAxiosErrorResponse(error)
            setApiError(error.response.data)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <div className={style.top} data-test="GuestSubscriptionForm">
                <h1 className={style.heading}>{t('EnterAsAGuest')}</h1>

                <p className={style.description}>
                    <Trans i18nKey="EnterAsGuestDescription">
                        <Button
                            variant="link"
                            onClick={() =>
                                dispatch(
                                    openAuthModal({
                                        modalType: AuthModalType.SignUpMenu,
                                    })
                                )
                            }
                            className={style.buttonLink}
                        >
                            Create your account
                        </Button>{' '}
                        or enter a nickname to continue as guest.
                    </Trans>
                </p>
            </div>

            <Formik
                initialValues={{
                    displayName: '',
                }}
                validationSchema={Yup.object({
                    displayName: Yup.string()
                        .trim()
                        .required('Required')
                        .min(3, t('NicknameLengthError'))
                        .max(12, t('NicknameLengthError'))
                        // @ts-ignore: Yup types are incomplete.
                        .nickname(t('DisplayNameErrorMessage')),
                })}
                onSubmit={onSubmit}
            >
                <Formk className={style.form}>
                    {apiError && (
                        <Alert
                            variant="danger"
                            data-test="GuestSubscriptionApiError"
                        >
                            {apiError}
                        </Alert>
                    )}

                    <Form.Group>
                        <Field
                            data-test="GuestSubscriptionNicknameInput"
                            label={t('Nickname')}
                            placeholder={t('Nickname')}
                            name="displayName"
                            component={InputText}
                            style={inlineStyle.input}
                        />
                    </Form.Group>

                    <div className={style.checkboxes}>
                        <div className={style.checkbox}>
                            <input
                                data-test="GuestSubscriptionPrivacyCheckbox"
                                type="checkbox"
                                id="checkPrivacy"
                                checked={checkPrivacy}
                                onChange={() => {
                                    if (!checkPrivacy) {
                                        setPrivacyError(false)
                                    }

                                    setCheckPrivacy(!checkPrivacy)
                                }}
                            />
                            <label htmlFor="checkPrivacy">
                                <Trans i18nKey="PrivacyAgreement">
                                    I agree to Piepacker's
                                    <a
                                        className={style.link}
                                        href="https://jam.gg/pages/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        privacy policy
                                    </a>
                                    and
                                    <a
                                        className={style.link}
                                        href="https://jam.gg/pages/legal"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        terms of use
                                    </a>
                                </Trans>
                            </label>
                        </div>
                        {privacyError && (
                            <div
                                className={style.errorMessage}
                                data-test="GuestSubscriptionPrivacyError"
                            >
                                {t('PrivacyCheckboxError')}
                            </div>
                        )}
                    </div>

                    <Form.Group>
                        <Button
                            data-test="GuestSubscriptionSubmitButton"
                            className="btn-large"
                            type="submit"
                            block
                            disabled={isSubmitting}
                            style={inlineStyle.input}
                        >
                            {t('Enter')}
                        </Button>
                    </Form.Group>
                </Formk>
            </Formik>

            <div className={style.smallText}>
                {t('AlreadyHaveAccount')}{' '}
                <Button
                    variant="link"
                    onClick={() =>
                        dispatch(
                            openAuthModal({
                                modalType: AuthModalType.Login,
                            })
                        )
                    }
                    className={style.buttonLink}
                >
                    {t('SignIn')}
                </Button>
            </div>
        </>
    )
}

export default GuestSubscriptionForm
