import { getUserFromStarfox } from '../firebase'
import { piepie } from '../utils'
import { User } from '../state/faces'
import { UserSessionFromFirebase } from '../state/starfox'

type Player = { [attendeeId: number]: User }

export default class VideoCamHandler {
    // refreshUsers() is used to set player index and nickname in the GamePage users
    refreshUsers = async (
        sessionData: UserSessionFromFirebase[],
        callback: (Player) => void
    ): Promise<void> => {
        //The core informations (starfox) are only used to fill the player index
        const users: Player = {}
        // for player nicknames
        for (const [, userSession] of Object.entries(sessionData)) {
            // Update UserSessionID and PlayerIdx in the user
            if (!userSession) continue
            const user = await getUserFromStarfox(userSession.UID)
            if (!user) continue
            const userSessionID = userSession.ID
            users[userSessionID] = {}
            users[userSessionID].nickname = user.User.DisplayName
            users[userSessionID].UserSessionID = userSessionID
        }

        piepie.log('[refreshUsers] Users updated:', users)
        //This callback method must be setUsers from the GamePage
        callback(users)
    }
}
