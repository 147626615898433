import { useSelector } from 'react-redux'
import { State } from '../../store/types'
import useChime from './useChime'
import { piepie } from '../../utils'

interface useConferenceSettingsReturnType {
    isCameraActive: boolean
    toggleIsCameraActive: () => void
    onCameraChange: (props: MediaDeviceInfo) => void
    onCameraStreamChange: (stream: MediaStream) => void
    onCameraStreamStop: () => void
    isMicrophoneActive: boolean
    toggleIsMicrophoneActive: () => void
    onMicrophoneChange: (props: MediaDeviceInfo) => void
    isSpeakerActive: boolean
    toggleIsSpeakerActive: () => void
    onSpeakerChange: (props: MediaDeviceInfo) => void
}

/**
 * Exposes helper functions for changing/toggling audio-video devices.
 */
const useConferenceSettings = (): useConferenceSettingsReturnType => {
    const { isCameraActive, isMicrophoneActive, isSpeakerActive } = useSelector(
        (state: State) => state.conference
    )
    const chime = useChime()

    const toggleIsCameraActive = async () => {
        piepie.log(
            'XOX CHIME: toggleIsCameraActive: setting isCameraActive to: ',
            !isCameraActive
        )
        analytics.track('cameraActive', { cameraActive: !isCameraActive })
        await chime.toggleVideoInputDevice(!isCameraActive)
    }

    const onCameraChange = async (device: MediaDeviceInfo) => {
        piepie.log(
            'XOX CHIME: onCameraChange: setting current video input device to: ',
            device
        )
        await chime.chooseVideoInputDevice(device)
    }

    // onCameraStreamChange accepts a media stream. when applying masks, this function can be
    // called on the media stream of the face augmented with the masks.
    const onCameraStreamChange = async (stream: MediaStream) => {
        piepie.log(
            'XOX CHIME: onCameraStreamChange: added stream to current video input device: ',
            stream
        )
        await chime.chooseVirtualCameraInputDevice(stream)
        chime.audioVideo.startLocalVideoTile()
    }

    const onCameraStreamStop = async () => {
        piepie.log('XOX CHIME: onCameraStreamStop')
        await chime.chooseVirtualCameraInputDevice(null)
        chime.audioVideo.stopLocalVideoTile()
    }

    const toggleIsMicrophoneActive = async () => {
        piepie.log(
            'CHIME: toggleIsMicrophoneActive: setting isMicrophoneActive to: ',
            !isMicrophoneActive
        )
        analytics.track('micActive', { micActive: !isMicrophoneActive })
        await chime.toggleAudioInputDevice(!isMicrophoneActive)
    }

    const onMicrophoneChange = async (device: MediaDeviceInfo) => {
        try {
            piepie.log(
                'CHIME: onMicrophoneChange: setting current audio input device to: ',
                device
            )
            await chime.chooseAudioInputDevice(device)
        } catch (error) {
            piepie.error(
                'CHIME: onMicrophoneChange: trying to switch microphone but it failed --',
                error
            )
        }
    }

    const toggleIsSpeakerActive = async () => {
        await chime.toggleAudioOutputDevice(!isSpeakerActive)
        piepie.log(
            'CHIME: toggleIsSpeakerActive: setting isSpeakerActive to: ',
            !isSpeakerActive
        )
    }

    const onSpeakerChange = async (device: MediaDeviceInfo) => {
        piepie.log(
            'CHIME: onSpeakerChange: setting current audio output device to: ',
            device
        )
        await chime.chooseAudioOutputDevice(device)
    }

    return {
        isCameraActive,
        toggleIsCameraActive,
        onCameraChange,
        onCameraStreamChange,
        onCameraStreamStop,
        isMicrophoneActive,
        toggleIsMicrophoneActive,
        onMicrophoneChange,
        isSpeakerActive,
        toggleIsSpeakerActive,
        onSpeakerChange,
    }
}

export default useConferenceSettings
