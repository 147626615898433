import * as Axios from 'axios'
import store from '../store'
import { setMaintenance, setMaintenanceEnd } from '../store/home/actions'
import { isEnvProd } from './env'
import { piepie } from './piepie'
import { logAxiosErrorResponse } from './http'

export const checkMaintenance = async (): Promise<void> => {
    const API_URL = isEnvProd
        ? 'https://maintenance-jamgg-dot-esoteric-fx-261813.appspot.com'
        : 'https://maintenance-jamgg-dot-piepacker-staging.appspot.com'

    try {
        const result = await Axios.default.post(API_URL)
        if (result?.data?.trim() !== 'ok') {
            const date = new Date(result.data.trim())
            const formattedDate = date.toLocaleString('en-GB', {
                hour: 'numeric',
                hour12: true,
                timeZone: 'Europe/Rome',
                timeZoneName: 'short',
            })
            store.dispatch(setMaintenanceEnd(formattedDate))
            store.dispatch(setMaintenance(true))
        } else {
            store.dispatch(setMaintenanceEnd(''))
            store.dispatch(setMaintenance(false))
        }
    } catch (error) {
        logAxiosErrorResponse(error)
        piepie.error('Checking maintenance mode has failed', error)
        store.dispatch(setMaintenanceEnd(''))
        store.dispatch(setMaintenance(false))
    }
}
