import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as Axios from 'axios'
import { piepie, defaultStarfoxURL } from '../utils'
import { SignedInState } from '../state/app'
import store from '../store'
import { AlertType } from '../state/alert'
import { LocalStorage } from '../state/storage'
import alertSlice from '../store/alert/alert'
import { logAxiosErrorResponse } from '../utils/http'

export async function logoutUser(onError: (error: string) => void) {
    try {
        analytics.track('Logout', {
            email: firebase.auth().currentUser.email,
        })
        await firebase.auth().signOut()
        localStorage.removeItem(LocalStorage.idToken)
    } catch (err) {
        onError(err.message)
    }
}

export async function deleteUser(onError: (error: string) => void) {
    try {
        // TODO: reducing to sign out only. Archiving would be a better approach for future troubleshooting.
        // Use an authed endpoint on starfox, rather than through firebase.
        const conf = await authorizedConfig()
        await Axios.default.post(
            `${defaultStarfoxURL()}/api/firebaseUser/archive`,
            null,
            conf
        )
        analytics.track('Archive', {
            email: firebase.auth().currentUser.email,
        })
        await firebase.auth().signOut()
    } catch (err) {
        logAxiosErrorResponse(err)
        onError(err.message)
    }
}

export async function authorizedConfig(): Promise<Axios.AxiosRequestConfig> {
    let idToken = localStorage.getItem(LocalStorage.idToken)
    if (!idToken) idToken = await firebase.auth().currentUser.getIdToken()
    const cfg: Axios.AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${idToken}` },
    }
    return cfg
}

export async function listenAuthChange(
    callback: (user: firebase.User) => void
): Promise<SignedInState | void> {
    firebase.auth().onAuthStateChanged(async function (user) {
        try {
            if (!user) {
                const userCredential = await firebase.auth().signInAnonymously()
                callback(userCredential.user)
            } else {
                callback(user)
            }
        } catch (error) {
            store.dispatch(
                alertSlice.actions.push({
                    type: AlertType.Error,
                    message: error.message,
                    autoClose: false,
                })
            )
        }
    })
}

export enum LOGIN_METHOD {
    PASSWORD,
    FACEBOOK,
    GOOGLE,
}

export async function loginUserFacebook(
    onSuccess: () => void,
    onError: (error: string) => void
) {
    try {
        const provider = new firebase.auth.FacebookAuthProvider()
        const result = await firebase.auth().signInWithPopup(provider)
        // Track New Users with Facebook Signup
        const crationTimestamp = Date.parse(
            firebase.auth().currentUser?.metadata?.creationTime
        )
        const lastsigninTimestamp = Date.parse(
            firebase.auth().currentUser?.metadata?.lastSignInTime
        )
        if (Math.abs(lastsigninTimestamp - crationTimestamp) < 1000) {
            analytics.track('Signup with Facebook', {
                email: firebase.auth().currentUser?.email,
                creationfirebase: crationTimestamp,
                lastsigninfirebase: lastsigninTimestamp,
            })
        } else {
            analytics.track('Login with Facebook', {
                email: firebase.auth().currentUser?.email,
                creationfirebase: crationTimestamp,
                lastsigninfirebase: lastsigninTimestamp,
            })
        }
        piepie.log({ result })
        onSuccess && onSuccess()
    } catch (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
            firebase
                .auth()
                .fetchSignInMethodsForEmail(error.email)
                .then((methods) => {
                    if (methods[0] === 'password') {
                        error.method = LOGIN_METHOD.PASSWORD
                    } else {
                        error.method = LOGIN_METHOD.GOOGLE
                    }

                    onError(error)
                })
        } else {
            onError(error)
        }
    }
}

export async function logInUserGoogle(
    onSuccess: () => void,
    onError: (error: string) => void
) {
    try {
        const provider = new firebase.auth.GoogleAuthProvider()
        const result = await firebase.auth().signInWithPopup(provider)
        // Track New Users with Google Signup
        const crationTimestamp = Date.parse(
            firebase.auth().currentUser?.metadata?.creationTime
        )
        const lastsigninTimestamp = Date.parse(
            firebase.auth().currentUser?.metadata?.lastSignInTime
        )
        if (Math.abs(lastsigninTimestamp - crationTimestamp) < 1000) {
            analytics.track('Signup with Google', {
                email: firebase.auth().currentUser?.email,
                creationfirebase: crationTimestamp,
                lastsigninfirebase: lastsigninTimestamp,
            })
        } else {
            analytics.track('Login with Google', {
                email: firebase.auth().currentUser?.email,
                creationfirebase: crationTimestamp,
                lastsigninfirebase: lastsigninTimestamp,
            })
        }
        piepie.log({ result })
        onSuccess && onSuccess()
    } catch (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
            error.method = LOGIN_METHOD.FACEBOOK
        }

        onError(error)
    }
}

export async function LogAndLink(method, data, onSuccess, onError) {
    switch (method) {
        case LOGIN_METHOD.PASSWORD:
            firebase
                .auth()
                .signInWithEmailAndPassword(data.email, data.password)
                .then((result) => {
                    return result.user.linkWithCredential(data.credential)
                })
                .then(() => {
                    analytics.track('Link Facebook with email / password', {
                        email: firebase.auth().currentUser.email,
                    })

                    onSuccess()
                })
                .catch((error) => {
                    onError(error)
                })

            break
        case LOGIN_METHOD.GOOGLE:
            firebase
                .auth()
                .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(function (result) {
                    result.user
                        .linkWithCredential(data.credential)
                        .then(function () {
                            analytics.track('Link Facebook with Google', {
                                email: firebase.auth().currentUser.email,
                            })

                            onSuccess()
                        })
                })
                .catch((error) => {
                    onError(error)
                })

            break
        case LOGIN_METHOD.FACEBOOK:
            firebase
                .auth()
                .signInWithPopup(new firebase.auth.FacebookAuthProvider())
                .then(function (result) {
                    result.user
                        .linkWithCredential(data.credential)
                        .then(function () {
                            analytics.track('Link Google with Facebook', {
                                email: firebase.auth().currentUser.email,
                            })

                            onSuccess()
                        })
                })
                .catch((error) => {
                    onError(error)
                })

            break
    }
}

/**
 * Refreshes the auth token in the Firebase and updates the local storage
 */
export async function refreshFirebaseToken(): Promise<void> {
    firebase
        .auth()
        .currentUser.getIdToken(/** forceRefresh */ true)
        .then(function (idToken) {
            piepie.log('Firebase idToken has been refreshed.')
            localStorage.setItem(LocalStorage.idToken, idToken)
        })
        .catch(function (error) {
            piepie.error(error)
        })
}
