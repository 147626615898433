import React, { FC } from 'react'
import cls from 'classnames'
import style from './style.module.scss'
import {
    Button,
    ControlButtons,
    Icon,
    Joystick,
    Spacing,
} from '../../../stories'

import {
    joystickControlValues,
    getButtons,
    handleSelectControl,
    handleStartControl,
} from '../../../handlers/MobileInputHandler'
import { Keys } from '../../../state/Keys'
import store from '../../../store'

const joystickContainerStyle = (section, isDynamic) => {
    return {
        width: '200px',
        height: '100%',
        borderRadius: '6px',
        background:
            section === 'layout' && isDynamic
                ? 'rgba(255,255,255, 0.7)'
                : 'transparent',
    }
}

interface MobileGameControlsPortraitProps {
    handleShowChat: () => void
    isDynamic: boolean
    handleOptions: (state) => void
    section: string
    showOptions: boolean
}

const MobileGameControlsPortrait: FC<MobileGameControlsPortraitProps> = ({
    handleShowChat,
    isDynamic,
    handleOptions,
    section,
    showOptions,
}) => {
    const controls = store.getState().session.game.Controls

    return (
        <div className={cls(style.controlSection)}>
            <div className={style.controlGroup}>
                <Joystick
                    controlGroup={joystickControlValues}
                    containerStyle={joystickContainerStyle(section, isDynamic)}
                    isDynamic={isDynamic}
                />
                <ControlButtons
                    shoulderButtons={getButtons(controls, [
                        Keys.L1,
                        Keys.R1,
                        Keys.L2,
                        Keys.R2,
                    ])}
                    controlGroup={getButtons(controls, [
                        Keys.Y,
                        Keys.B,
                        Keys.X,
                        Keys.A,
                    ])}
                />
            </div>

            <div className={style.playPauseGroup}>
                <Button
                    variant="square"
                    onClick={() => handleOptions((state) => !state)}
                    style={showOptions ? { border: '3px solid #8941EA' } : {}}
                >
                    <Icon name="menu" />
                </Button>

                <div className={style.startSelectGroup}>
                    {controls?.SELECT?.EN && (
                        <Button
                            variant="mini"
                            onTouchStart={handleSelectControl}
                            onTouchEnd={() => handleSelectControl(false)}
                        >
                            Select
                        </Button>
                    )}

                    {controls?.START?.EN && (
                        <>
                            <Spacing right="3" inline />
                            <Button
                                variant="mini"
                                onTouchStart={handleStartControl}
                                onTouchEnd={() => handleStartControl(false)}
                            >
                                Start
                            </Button>
                        </>
                    )}
                </div>

                <Button variant="elliptical" onClick={handleShowChat}>
                    <Icon name="chat" />
                </Button>
            </div>
        </div>
    )
}

export default MobileGameControlsPortrait
