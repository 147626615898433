import React, { FC } from 'react'
import { Avatar, ChatMessage } from '../..'
import style from './style.module.scss'
import cls from 'classnames'

type message = {
    Text: string
}

interface ChatDialogProps {
    messages: message[]
    color: 'primary' | 'secondary'
    title?: string
    avatarSrc?: string
    goldLetters?: boolean
}

const ChatDialog: FC<ChatDialogProps> = ({
    messages = [],
    color = 'primary',
    title = '',
    avatarSrc = '',
    goldLetters = false,
}) => {
    return (
        <div className={cls(style.dialog, style[color])}>
            {avatarSrc && (
                <div className={style.avatar}>
                    <Avatar variant="xs" src={avatarSrc} alt="avatar" />
                </div>
            )}
            <div className={cls(style.container, style[color])}>
                <p className={cls(style.title, goldLetters && style.gold)}>
                    {title}
                </p>
                <div className={cls(style.msgContainer, style[color])}>
                    {messages?.map((message) => (
                        <ChatMessage message={message.Text} color={color} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ChatDialog
