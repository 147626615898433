import styles from './style.module.scss'

import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { AlertInterface, AlertType } from '../../../state/alert'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../atoms/Icon'

interface AlertProps extends AlertInterface {
    className?: string
    onClose?(id: AlertInterface['id']): void
    animate?: boolean
    seeMoreLink?: string
}

const Alert: FC<AlertProps> = ({
    className,
    id,
    type = AlertType.Info,
    icon,
    message,
    autoClose = true,
    animate = true,
    seeMoreLink = null,
    onClose,
}) => {
    const { t } = useTranslation()

    const [closing, setClosing] = useState(false)
    let fillClass

    if (!icon) {
        switch (type) {
            case AlertType.Error:
                icon = 'error'
                fillClass = styles.red
                break
            case AlertType.Success:
                icon = 'success'
                fillClass = styles.green
                break
            case AlertType.Warning:
                icon = 'warning'
                fillClass = styles.yellow
                break
            case AlertType.Info:
            default:
                icon = 'info'
                break
        }
    }

    return (
        <div
            className={classNames({
                [styles.alert]: true,
                [className]: true,
                [styles.animationAuto]: animate && autoClose,
                [styles.animationShow]: animate && !autoClose,
                [styles.animationClose]: animate && closing,
                [styles.visible]: !animate || (!autoClose && !closing),
            })}
            data-test={`AlertElement-${type}`}
            onAnimationEnd={() => {
                if (autoClose && onClose) onClose(id)
                if (closing) onClose(id)
            }}
        >
            <Icon
                className={classNames(styles.alertIcon, fillClass)}
                name={icon}
            />
            <p className={styles.alertMessageWrapper}>
                <span
                    data-cy="alert-message"
                    className={styles.alertMessage}
                    data-message={message}
                >
                    {t(message)}
                    {seeMoreLink && (
                        <a
                            href={t(seeMoreLink)}
                            target="__blank"
                            rel="noopener"
                        >
                            {t('seeMoreText')}
                        </a>
                    )}
                </span>
            </p>
            {!autoClose && onClose && (
                <button
                    className={styles.close}
                    onClick={() => setClosing(true)}
                >
                    <Icon name="close" className={styles.closeIcon} />
                </button>
            )}
        </div>
    )
}

export default Alert
