import { Grid } from '@material-ui/core'
import React, { FC } from 'react'
import GamesListScreen from './GamesListScreen'
import PleaseWaitForHostImage from '../GamesList/assets/PiepiemojiBasicSmile.png'

import { Alert } from '../../stories'
import { AlertType } from '../../state/alert'

import './GamesListScreen.scss'
import { useTranslation } from 'react-i18next'

const GameListScreenOverlay: FC = () => {
    return <HostScreenOverlay />
}

export const GuestScreenOverlay: FC<Record<string, unknown>> = () => {
    const { t } = useTranslation()
    return (
        <div className="wait-for-host">
            <img
                height="100"
                className="wait-for-host-image"
                src={PleaseWaitForHostImage}
                alt=""
            />
            <Alert
                autoClose={false}
                animate={false}
                className="wait-for-host-alert-message"
                type={AlertType.Idle}
                message={t('SwitchGameGuestWait')}
            />
        </div>
    )
}

const HostScreenOverlay: FC = () => {
    return (
        <Grid classes={{ container: 'game-list-wrapper' }} container>
            <GamesListScreen />
        </Grid>
    )
}

export default GameListScreenOverlay
