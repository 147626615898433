import makeReducer from '../makeReducer'
import * as types from './types'
import { SessionActiveEnum } from './types'
import { NetworkQuality } from '../../pages/Game/hooks/useNetworkProbe'

// TODO: remove type casting by fixing `session` and `userSession` values
export const initialState = {
    gameName: '',
    sessionID: '',
    userSessionID: '',
    session: {},
    cluster: '',
    userSession: {},
    conferenceURL: '',
    isHost: undefined,
    playerIndices: {},
    devices: [],
    localPlayers: [],
    networkQuality: NetworkQuality.Unknown,
    byog: false,
    gameCore: '',
    gameFile: '',
    originalFileName: '',
    players: {},
    isESL: false,
    lobbyError: undefined,
    game: {},
    disableSavestates: false,
    gameState: types.GAME_STATE.LOADING,
    sessionActive: SessionActiveEnum.Maybe,
    saveStates: [],
    isSavedState: false,
} as types.SessionState

export const handlers = {
    [types.SessionActionType.clearSession](
        state: types.SessionState // Leave for type enforcement
    ) {
        return {
            ...initialState,
            // TODO: move those out of the session redux.
            // We need to preserve them beyond clear session calls to preserve business logic.
            game: state.game,
            gameCore: state.gameCore,
            gameFile: state.gameFile,
            byog: state.byog,
            gameName: state.gameName,
        }
    },
    [types.SessionActionType.setGameName](
        state: types.SessionState,
        action: types.SetGameNameAction
    ) {
        return {
            ...state,
            gameName: action.payload,
        }
    },
    [types.SessionActionType.setCluster](
        state: types.SessionState,
        action: types.SetClusterAction
    ) {
        return {
            ...state,
            cluster: action.payload,
        }
    },
    [types.SessionActionType.setSessionId](
        state: types.SessionState,
        action: types.SetSessionIdAction
    ) {
        return {
            ...state,
            sessionID: action.payload,
        }
    },
    [types.SessionActionType.setUserSessionId](
        state: types.SessionState,
        action: types.SetUserSessionIdAction
    ) {
        return {
            ...state,
            userSessionID: action.payload,
        }
    },
    [types.SessionActionType.setConferenceUrl](
        state: types.SessionState,
        action: types.SetConferenceUrlAction
    ) {
        return {
            ...state,
            conferenceURL: action.payload,
        }
    },
    [types.SessionActionType.setSession](
        state: types.SessionState,
        action: types.SetSessionAction
    ) {
        return {
            ...state,
            session: action.payload,
        }
    },
    [types.SessionActionType.setUserSession](
        state: types.SessionState,
        action: types.SetUserSessionAction
    ) {
        return {
            ...state,
            userSession: action.payload,
        }
    },
    [types.SessionActionType.setIsHost](
        state: types.SessionState,
        action: types.SetIsHostAction
    ) {
        return {
            ...state,
            isHost: action.payload,
        }
    },
    [types.SessionActionType.setPlayerIndices](
        state: types.SessionState,
        action: types.SetPlayerIndicesAction
    ) {
        return {
            ...state,
            playerIndices: action.payload.reduce(
                (indices, playerIndex) => ({
                    ...indices,
                    [playerIndex.UserSessionID]: playerIndex.PlayerIdx,
                }),
                {}
            ),
        }
    },
    [types.SessionActionType.SetDevices](
        state: types.SessionState,
        action: types.SetDevices
    ) {
        return {
            ...state,
            devices: action.payload,
        }
    },
    [types.SessionActionType.SetLocalPlayers](
        state: types.SessionState,
        action: types.SetLocalPlayers
    ) {
        return {
            ...state,
            localPlayers: action.payload,
        }
    },
    [types.SessionActionType.SetNetworkQuality](
        state: types.SessionState,
        action: types.SetNetworkQuality
    ) {
        return {
            ...state,
            networkQuality: action.payload,
        }
    },
    [types.SessionActionType.SetBYOG](
        state: types.SessionState,
        action: types.SetBYOG
    ) {
        return {
            ...state,
            byog: action.payload,
        }
    },
    [types.SessionActionType.SetGameCore](
        state: types.SessionState,
        action: types.SetGameCore
    ) {
        return {
            ...state,
            gameCore: action.payload,
        }
    },
    [types.SessionActionType.SetGameFile](
        state: types.SessionState,
        action: types.SetGameFile
    ) {
        return {
            ...state,
            gameFile: action.payload,
        }
    },
    [types.SessionActionType.setPlayer](
        state: types.SessionState,
        action: types.SetPlayer
    ) {
        return {
            ...state,
            players: {
                ...state.players,
                [action.uid]: action.payload,
            },
        }
    },
    [types.SessionActionType.removePlayer](
        state: types.SessionState,
        action: types.RemovePlayer
    ) {
        const newPlayers = { ...state.players }
        delete newPlayers[action.uid]

        return {
            ...state,
            players: newPlayers,
        }
    },
    [types.SessionActionType.setIsESL](
        state: types.SessionState,
        action: types.SetIsESL
    ) {
        return {
            ...state,
            isESL: action.payload,
        }
    },
    [types.SessionActionType.setDisableSavestates](
        state: types.SessionState,
        action: types.SetDisableSavestates
    ) {
        return {
            ...state,
            disableSavestates: action.payload,
        }
    },
    [types.SessionActionType.setGame](
        state: types.SessionState,
        action: types.SetGameAction
    ) {
        return {
            ...state,
            game: action.payload,
        }
    },
    [types.SessionActionType.setGameStateSelecting](state: types.SessionState) {
        return {
            ...state,
            gameState: types.GAME_STATE.SELECTING,
        }
    },
    [types.SessionActionType.setGameStatePaused](
        state: types.SessionState
        /* _action: types.SetHideGamesList */
    ) {
        return {
            ...state,
            gameState: types.GAME_STATE.PAUSED,
        }
    },
    [types.SessionActionType.setGameStateLoading](
        state: types.SessionState
        /* _action: types.SetShowGamesList */
    ) {
        return {
            ...state,
            gameState: types.GAME_STATE.LOADING,
        }
    },
    [types.SessionActionType.setGameStateReady](
        state: types.SessionState
        /* _action: types.SetHideGamesList */
    ) {
        return {
            ...state,
            gameState: types.GAME_STATE.READY,
        }
    },
    [types.SessionActionType.setSessionActive](
        state: types.SessionState,
        action: types.SetSessionActiveAction
    ) {
        return {
            ...state,
            sessionActive: action.payload,
        }
    },
    [types.SessionActionType.setSaveStates](
        state: types.SessionState,
        action: types.SetSaveStatesAction
    ) {
        return {
            ...state,
            saveStates: action.payload,
        }
    },
    [types.SessionActionType.setIsSavedState](
        state: types.SessionState,
        action: types.SetIsSavedStateAction
    ) {
        return {
            ...state,
            isSavedState: action.payload,
        }
    },
    [types.SessionActionType.setLobbyError](
        state: types.SessionState,
        action: types.SetLobbyError
    ) {
        return {
            ...state,
            lobbyError: action.payload,
        }
    },
    [types.SessionActionType.SetOriginalFileName](
        state: types.SessionState,
        action: types.SetOriginalFileNameAction
    ) {
        return {
            ...state,
            originalFileName: action.payload,
        }
    },
}

export default makeReducer<types.SessionState, types.SessionAction>(
    initialState,
    handlers
)
