import React from 'react'
import { useWindowSize } from '@react-hook/window-size'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Menu: React.FC = () => {
    const [windowWidth] = useWindowSize()
    return windowWidth > 768 ? <Desktop /> : <Mobile />
}

export default Menu
