import { useEffect, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { useTranslation } from 'react-i18next'
import store from '../store'
import { AlertInterface, AlertType } from '../state/alert'
import { SignedInState } from '../state/app'
import { useSelector } from 'react-redux'
import { State } from '../store/types'
import { isMobileEnabled } from '.'
import alertSlice from '../store/alert/alert'

export const MIN_WIDTH = 800
export const MIN_HEIGHT = 600

interface useSmallScreenAlertProps {
    signedIn: SignedInState
}

const SMALL_SCREEN_ALERT_KEYWORD = 'AlertEnlargeScreen'

export function useSmallScreenAlert({
    signedIn,
}: useSmallScreenAlertProps): void {
    const [windowWidth, windowHeight] = useWindowSize()
    const { t } = useTranslation()

    const alertList = useSelector((state: State) => state.alert.list)
    useEffect(() => {
        // Show the alert only if the player is logged in
        if (signedIn === SignedInState.NotSignedIn || isMobileEnabled) {
            return
        }

        // Don't display a notification for smaller screens while authenticating.
        const authRoutes = ['/', '/confirm-signup']
        if (
            signedIn === SignedInState.SignedInAnonymously &&
            authRoutes.includes(window.location.pathname)
        ) {
            return
        }

        const outOfBound: boolean =
            windowWidth < MIN_WIDTH || windowHeight < MIN_HEIGHT
        const smallScreenAlertId: AlertInterface['id'] | undefined =
            alertList.find(
                (alert) => alert.message === SMALL_SCREEN_ALERT_KEYWORD
            )?.id
        if (outOfBound) {
            // Prevent to fire showAlert every time that the user modifies the window width
            if (typeof smallScreenAlertId === 'undefined')
                store.dispatch(
                    alertSlice.actions.push({
                        type: AlertType.Error,
                        message: SMALL_SCREEN_ALERT_KEYWORD,
                        autoClose: false,
                    })
                )
        } else {
            if (typeof smallScreenAlertId === 'string')
                store.dispatch(alertSlice.actions.delete(smallScreenAlertId))
        }
    }, [windowWidth, windowHeight, signedIn, t, alertList])
}

export enum Breakpoint {
    xs = 576,
    sm = 768,
    md = 992,
    lg = 1200,
    xl = 1440,
    xxl = 1880,
}

const computeBreakpoint = (windowWidth: number): Breakpoint => {
    if (windowWidth < Breakpoint.xs) return Breakpoint.xs
    if (windowWidth < Breakpoint.sm) return Breakpoint.sm
    if (windowWidth < Breakpoint.md) return Breakpoint.md
    if (windowWidth < Breakpoint.lg) return Breakpoint.lg
    if (windowWidth < Breakpoint.xl) return Breakpoint.xl
    return Breakpoint.xxl
}

export function useBreakpoint(): Breakpoint {
    const [windowWidth] = useWindowSize()
    const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(
        computeBreakpoint(windowWidth)
    )

    useEffect(() => {
        setCurrentBreakpoint(computeBreakpoint(windowWidth))
    }, [windowWidth])

    return currentBreakpoint
}
