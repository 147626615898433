import React, { useState } from 'react'

import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

import { useTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import { Button, Modal, Alert } from 'react-bootstrap'
import { Avatar } from '@material-ui/core'
import { piepie } from '../utils'

export default function EditAvatar({ userAvatar, onSuccess }) {
    const [submitError, setSubmitError] = useState({
        error: false,
        message: '',
    })

    const [file, setFile] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const { t } = useTranslation()

    const onAvatarUpload = () => {
        const user = firebase.auth().currentUser
        const storageRef = firebase.storage().ref()
        const userAvatarRef = storageRef.child(`/user/${user.uid}/avatar`)
        setSubmitting(true)
        userAvatarRef
            .put(file)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    user.updateProfile({
                        photoURL: downloadURL,
                    })
                        .then(() => {
                            onSuccess()
                            setSubmitting(false)
                        })
                        .catch((error) => {
                            piepie.error(
                                '[ACCOUNT] avatar edition failed on update:',
                                error.message
                            )
                            setSubmitError({
                                error: true,
                                message: error.message,
                            })
                            setSubmitting(false)
                            // An error happened.
                        })
                })
            })
            .catch((error) => {
                piepie.error('[ACCOUNT] avatar edition failed:', error.message)
                setSubmitError({ error: true, message: error.message })
                setSubmitting(false)
                // An error happened.
            })
    }

    const logoUploader = () => {
        const avatarStyle = { width: 300, height: 300, margin: '0px auto 24px' }

        if (file) {
            return (
                <div>
                    <Avatar
                        style={avatarStyle}
                        src={URL.createObjectURL(file)}
                    />
                    <Button
                        className="btn-large"
                        disabled={submitting}
                        onClick={onAvatarUpload}
                        type="submit"
                        block
                    >
                        {t('Save')}
                    </Button>
                </div>
            )
        } else {
            return (
                <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Avatar style={avatarStyle} src={userAvatar} />
                                <p>{t('DragAndDropAvatar')}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            )
        }
    }

    return (
        <>
            <Modal.Title>{t('EditAvatarTitle')}</Modal.Title>

            {submitError.error && (
                <Alert variant="danger">{submitError.message}</Alert>
            )}

            {logoUploader()}
        </>
    )
}
