import { useState, useRef, useEffect, useLayoutEffect } from 'react'

const useIsomorphicEffect =
    typeof window === 'undefined' ? useEffect : useLayoutEffect

interface Props {
    duration: number
    startAt: number
    onComplete?: () => void
    cleanUp?: boolean
}

export const useElapsedTime = ({
    duration,
    startAt = 0,
    onComplete,
    cleanUp = false,
}: Props) => {
    const [displayTime, setDisplayTime] = useState<number>(startAt)
    const elapsedTimeRef = useRef<number>(0)
    const startAtRef = useRef<number>(startAt)
    const requestRef = useRef<number>(null)
    const previousTimeRef = useRef<number>(null)

    const loop = (time?: number) => {
        const timeSec = time / 1000

        if (previousTimeRef.current === null) {
            previousTimeRef.current = timeSec
            requestRef.current = requestAnimationFrame(loop)
            return
        }

        // get current elapsed time
        const deltaTime = timeSec - previousTimeRef.current
        const currentElapsedTime = elapsedTimeRef.current + deltaTime

        // update refs with the current elapsed time
        previousTimeRef.current = timeSec
        elapsedTimeRef.current = currentElapsedTime

        // set current display time by adding the elapsed time on top of the startAt time
        const currentDisplayTime = startAtRef.current + currentElapsedTime

        const totalTime = startAtRef.current + currentElapsedTime
        const isCompleted = totalTime >= duration
        setDisplayTime(isCompleted ? duration : currentDisplayTime)

        // repeat animation if not completed
        if (!isCompleted) {
            requestRef.current = requestAnimationFrame(loop)
        } else {
            onComplete && onComplete()
            requestRef.current && cancelAnimationFrame(requestRef.current)
            previousTimeRef.current = null
        }
    }

    useIsomorphicEffect(() => {
        if (!cleanUp) {
            requestRef.current = requestAnimationFrame(loop)
        }

        return () => {
            requestRef.current && cancelAnimationFrame(requestRef.current)
            previousTimeRef.current = null
        }
    }, [cleanUp])

    return { elapsedTime: displayTime }
}
