import React, { FC } from 'react'
import style from './style.module.scss'
import cls from 'classnames'

interface AvatarProps {
    variant: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    src: string
    alt: string
    [rootHtmlAttributes: string]: any
}

const Avatar: FC<AvatarProps> = ({
    variant = 'sm',
    alt = '',
    src = '',
    ...rootHtmlAttributes
}) => {
    return (
        <div
            {...rootHtmlAttributes}
            className={cls(style.avatar, style[variant])}
        >
            <img alt={alt} src={src} className={style.image} />
        </div>
    )
}

export default Avatar
