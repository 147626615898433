// Inline styles required for customizing Bootstrap elements.
// We'll remove them when we get rid of the Bootstrap and built the design system.
export default {
    input: {
        height: 42,
        fontSize: 14,
    },
    specialInput: {
        height: 42,
        fontSize: 14,
        margin: '20px 0 5px',
    },
    button: {
        height: 44,
    },
    specialButton: {
        height: 44,
        marginTop: '20px',
    },
}
