import style from './style.module.scss'

import React, {
    ReactNode,
    FC,
    Children,
    ReactElement,
    isValidElement,
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    cloneElement,
} from 'react'
import cls from 'classnames'

import ConferenceTile, {
    ConferenceTileProps,
} from '../ConferenceTile/ConferenceTile'
import { Button, Icon } from '../../'
import { array_move, useDeviceOrientation } from '../../../utils'

type ConferenceContainerVariant = 'default' | 'solo' | 'hidden' | 'mobile'
interface ConferenceContainerProps {
    className?: string
    children?: ReactNode
    width: number
    onInviteClick?: () => void
    inviteClickLabel?: string
    inviteClickLabelTimeout?: number
    onSeeAllClick?: () => void
    maxTiles?: number
    variant?: ConferenceContainerVariant
}

const ConferenceContainer: FC<ConferenceContainerProps> = ({
    className,
    children = [],
    width,
    onInviteClick,
    inviteClickLabel,
    inviteClickLabelTimeout = 3000,
    onSeeAllClick,
    maxTiles = 8,
    variant = 'default',
}) => {
    const bodyRef = useRef<HTMLDivElement>(null)
    const [bodyHeight, setBodyHeight] = useState(0)
    const orientation = useDeviceOrientation()
    const [bodyTiles, setBodyTiles] = useState([])
    const [footerTiles, setFooterTiles] = useState([])
    const [bodyFilledHeight, setBodyFilledHeight] = useState(0)
    const [footerMaxVisibleTiles, setFooterMaxVisibleTiles] = useState(0)
    const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false)

    let filteredChildren = Children.map(children, (child) => {
        if (!isValidElement<ConferenceTileProps>(child)) {
            return // Do not accept other components
        }
        const elementChild: ReactElement<ConferenceTileProps> = child
        if (
            typeof elementChild.type === 'function' &&
            elementChild.type.name === ConferenceTile.name
        ) {
            return elementChild
        }
    }).filter((child) => child !== undefined)

    useLayoutEffect(() => {
        function updateSize() {
            setBodyHeight(bodyRef.current?.clientHeight || 0)
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    useEffect(() => {
        if (bodyRef.current && variant !== 'hidden') {
            setBodyHeight(bodyRef.current?.clientHeight || 0)
        }
    }, [variant])

    useEffect(() => {
        // Constants
        const TILE_SPACING = 8
        const TILE_SQUARE_MIN_SIZE = 124 + TILE_SPACING
        const PARTICIPANTS_NUMBER = filteredChildren.length || 0
        const TILE_DEFAULT_MIN_ASPECTRATIO = 3 / 2
        const TILE_DEAFULT_MAX_ASPECTRATIO = 1 // Or 4/5?
        const TILE_CIRCLE_SIZE = 50
        const TILE_CIRCLE_SPACING = 8

        const tileDefaultMinHeight =
            width / TILE_DEFAULT_MIN_ASPECTRATIO + TILE_SPACING
        const tileDefaultMaxHeight =
            width / TILE_DEAFULT_MAX_ASPECTRATIO + TILE_SPACING

        // ---------------------------------------------------------------------
        // ALTERNATIVE VIEW MODES:

        if (variant === 'hidden') return

        if (variant === 'solo') {
            setBodyTiles([
                {
                    index: 0,
                    height: tileDefaultMinHeight - TILE_SPACING,
                    variant: 'default',
                },
            ])
            setFooterTiles([])
            return
        }

        if (variant === 'mobile') {
            setBodyTiles([
                {
                    index: 0,
                    variant: 'circleLarge',
                },
                {
                    index: 1,
                    variant: 'circle',
                },
                {
                    index: 2,
                    variant: 'circle',
                },
            ])
            setFooterTiles([])
            return
        }

        // ---------------------------------------------------------------------
        // DEFAULT VIEW MODE:

        // Compute number of visible tiles in the footer (if any)
        let tempFooterMaxVisibleTiles = Math.floor(
            (width - TILE_CIRCLE_SIZE) /
                (TILE_CIRCLE_SIZE + TILE_CIRCLE_SPACING)
        ) // remove the size of the invite button from the tab width, and then divide by the spacing

        const availableSlots =
            Math.floor(bodyHeight / tileDefaultMinHeight) || 1 // available slots for minimum height default tiles

        // CASE 1 ---------------------------------------------------------------------
        // All the participants can be shown full width in the body

        if (availableSlots >= PARTICIPANTS_NUMBER) {
            // All the participants can be shown full width in the body
            let tileDefaultHeight = Math.floor(bodyHeight / PARTICIPANTS_NUMBER)
            if (tileDefaultHeight > tileDefaultMaxHeight)
                tileDefaultHeight = tileDefaultMaxHeight

            // Set the height of all participants tiles to: tileDefaultHeight - TILE_SPACING
            const tempBodyTiles = Array.from(
                Array(PARTICIPANTS_NUMBER).keys()
            ).map((index) => {
                return {
                    index,
                    height: tileDefaultHeight - TILE_SPACING,
                    variant: 'default',
                }
            })
            setFooterMaxVisibleTiles(tempFooterMaxVisibleTiles)
            setBodyTiles(tempBodyTiles)
            setFooterTiles([])
            setBodyFilledHeight(
                PARTICIPANTS_NUMBER * tileDefaultHeight + TILE_SPACING
            )
            return
        }

        // If we are here, not all tiles can be shown full width in the body

        const tileSquareSize =
            Math.floor((width - TILE_SPACING) / 2) + TILE_SPACING

        // CASE 2 ---------------------------------------------------------------------
        // 2 columns view cannot be enabled due to a limited width
        // -> Show as many tiles as possible full width, the others will be in the footer

        if (tileSquareSize < TILE_SQUARE_MIN_SIZE) {
            let tileDefaultHeight = Math.floor(bodyHeight / availableSlots)
            if (tileDefaultHeight > tileDefaultMaxHeight)
                tileDefaultHeight = tileDefaultMaxHeight

            const tempBodyTiles = Array.from(Array(availableSlots).keys()).map(
                (index) => {
                    return {
                        index,
                        height: tileDefaultHeight - TILE_SPACING,
                        variant: 'default',
                    }
                }
            )

            const footerTilesCount = PARTICIPANTS_NUMBER - availableSlots
            if (footerTilesCount > tempFooterMaxVisibleTiles) {
                tempFooterMaxVisibleTiles -= 1 // Remove one spot because the (+) button will be shown there
            }

            const tempFooterTiles = Array.from(
                Array(PARTICIPANTS_NUMBER - availableSlots).keys()
            ).map((index) => {
                return {
                    index: index + availableSlots,
                    visible: index < tempFooterMaxVisibleTiles,
                }
            })

            setFooterMaxVisibleTiles(tempFooterMaxVisibleTiles)
            setBodyTiles(tempBodyTiles)
            setFooterTiles(tempFooterTiles)
            const tempBodyFilledHeight = tempBodyTiles.reduce(
                (totHeight, tile) => totHeight + tile.height + TILE_SPACING,
                TILE_SPACING
            )
            setBodyFilledHeight(tempBodyFilledHeight)
            return
        }

        // If we are here, this means that we can enable 2 columns view!

        // CASE 3 ---------------------------------------------------------------------
        // 2 columns CAN be enabled!
        // -> Place as many full-width tiles as possible,
        // otherwise try to add them as smaller squared tiles
        // otherwise put them in the footer

        let bodyOtherParticipantsHeight = bodyHeight - tileDefaultMinHeight // Compute the space available for the other participants (the main player is not included)
        if (bodyOtherParticipantsHeight < 0) bodyOtherParticipantsHeight = 0 // In case there is no space, it might me negative
        let tileSquareCount =
            Math.floor(bodyOtherParticipantsHeight / tileSquareSize) * 2 // Number of square tile participants that can be shown in the body
        if (tileSquareCount > PARTICIPANTS_NUMBER - 1)
            tileSquareCount = PARTICIPANTS_NUMBER - 1 // Remove the main player from the count
        let tileDefaultCount = 1 // The main player has a default tile (full width)
        let bodyUnusedSpace =
            bodyOtherParticipantsHeight -
            Math.ceil(tileSquareCount / 2) * tileSquareSize // Compute the unused space in the body

        // Try to occupy as much space as possible with default (full width) tiles
        while (bodyUnusedSpace > tileDefaultMinHeight) {
            tileDefaultCount += 1
            bodyUnusedSpace -= tileDefaultMinHeight
            tileSquareCount -= 1

            if (tileSquareCount % 2 === 0) {
                // The row has been deleted, we have to increase the bodyUnusedSpace by the height of a square tile
                bodyUnusedSpace += tileSquareSize
            }
        }

        if (
            tileSquareCount % 2 !== 0 &&
            bodyUnusedSpace + tileSquareSize > tileDefaultMinHeight
        ) {
            // We can transform the last square tile into a default (full-width) tile
            tileSquareCount -= 1
            bodyUnusedSpace -= tileDefaultMinHeight
            tileDefaultCount += 1
            bodyUnusedSpace += tileSquareSize
        }

        let tileDefaultHeight = tileDefaultMinHeight
        if (bodyUnusedSpace > 0) {
            tileDefaultHeight += Math.floor(bodyUnusedSpace / tileDefaultCount)
            if (tileDefaultHeight > tileDefaultMaxHeight)
                tileDefaultHeight = tileDefaultMaxHeight
        }

        const tempBodyDefaultTiles = Array.from(
            Array(tileDefaultCount).keys()
        ).map((index) => {
            return {
                index,
                height: tileDefaultHeight - TILE_SPACING,
                variant: 'default',
            }
        })

        let tempBodySquareTiles = []
        if (tileSquareCount > 0) {
            tempBodySquareTiles = Array.from(Array(tileSquareCount).keys()).map(
                (index) => {
                    return {
                        index: index + tileDefaultCount,
                        height: tileSquareSize - TILE_SPACING,
                        variant: 'square',
                    }
                }
            )
        }

        let tempFooterTiles = []
        if (PARTICIPANTS_NUMBER - tileDefaultCount - tileSquareCount > 0) {
            const footerTilesCount =
                PARTICIPANTS_NUMBER - tileDefaultCount - tileSquareCount
            if (footerTilesCount > tempFooterMaxVisibleTiles) {
                tempFooterMaxVisibleTiles -= 1 // Remove one spot because the (+) button will be shown there
            }

            tempFooterTiles = Array.from(
                Array(
                    PARTICIPANTS_NUMBER - tileDefaultCount - tileSquareCount
                ).keys()
            ).map((index) => {
                return {
                    index: index + tileDefaultCount + tileSquareCount,
                    visible: index < tempFooterMaxVisibleTiles,
                }
            })
        }

        setFooterMaxVisibleTiles(tempFooterMaxVisibleTiles)
        setBodyTiles([...tempBodyDefaultTiles, ...tempBodySquareTiles])
        setFooterTiles(tempFooterTiles)

        const tempBodyFilledHeight =
            tempBodyDefaultTiles.reduce(
                (totHeight, tile) => totHeight + tile.height + TILE_SPACING,
                TILE_SPACING
            ) +
            Math.ceil(tileSquareCount / 2) * tileSquareSize

        setBodyFilledHeight(tempBodyFilledHeight)
    }, [bodyHeight, width, children, filteredChildren.length, variant])

    // Count the number of square tiles in the body and if they are odd, add a square invite button in the body and not in the footer
    const showSquareInvite =
        bodyTiles.filter((tile) => tile.variant === 'square').length % 2 !== 0

    const showOthersTileButton = footerMaxVisibleTiles < footerTiles.length

    const highlightedTileIndex = filteredChildren.findIndex(
        (child) => child.props.highlighted
    )
    if (highlightedTileIndex >= bodyTiles.length) {
        filteredChildren = array_move(filteredChildren, highlightedTileIndex, 1)
    }

    const onInviteClickHandler = () => {
        analytics.track('Invite Guest')
        setShowCopiedToClipboard(true)
        if (onInviteClick) onInviteClick()
    }

    useEffect(() => {
        let timeout = null
        if (showCopiedToClipboard) {
            timeout = setTimeout(() => {
                setShowCopiedToClipboard(false)
            }, inviteClickLabelTimeout)
        }

        return () => {
            if (timeout) clearTimeout(timeout)
        }
    }, [showCopiedToClipboard, inviteClickLabelTimeout])

    const isParticipantInFooter = footerTiles.length > 0

    if (variant !== 'mobile')
        return (
            <div
                className={cls(
                    style.conferenceContainer,
                    className,
                    style[variant]
                )}
                style={{ width: width }}
            >
                <div className={style.body} ref={bodyRef}>
                    {bodyTiles[0] && (
                        <div
                            key="main-player"
                            className={style.tileWrapper}
                            style={{
                                height: bodyTiles[0].height,
                                width: '100%',
                            }}
                        >
                            {children[0]}
                        </div>
                    )}
                    {bodyTiles.map(
                        ({ index, height, variant }) =>
                            filteredChildren[index] &&
                            index !== 0 && (
                                <div
                                    key={filteredChildren[index].props.id}
                                    className={style.tileWrapper}
                                    style={{
                                        height,
                                        width:
                                            variant === 'square'
                                                ? height
                                                : '100%',
                                    }}
                                >
                                    {cloneElement(filteredChildren[index], {
                                        ...filteredChildren[index].props,
                                        variant,
                                    })}
                                </div>
                            )
                    )}
                    {showSquareInvite && (
                        <Button
                            className={style.squareButton}
                            onClick={onInviteClickHandler}
                            disabled={filteredChildren.length >= maxTiles}
                        >
                            {!showCopiedToClipboard ? (
                                <Icon name="personAdd" />
                            ) : (
                                <Icon name="paste" />
                            )}
                        </Button>
                    )}
                </div>
                {variant === 'default' && (
                    <div
                        className={style.footer}
                        style={{ top: bodyFilledHeight }}
                    >
                        {isParticipantInFooter &&
                            footerTiles.map(
                                ({ index, visible }) =>
                                    visible &&
                                    filteredChildren[index] && (
                                        <div
                                            key={index}
                                            className={style.tileWrapper}
                                        >
                                            {cloneElement(
                                                filteredChildren[index],
                                                {
                                                    ...filteredChildren[index]
                                                        .props,
                                                    variant: 'circleSmall',
                                                }
                                            )}
                                        </div>
                                    )
                            )}
                        {showOthersTileButton && (
                            <Button
                                className={style.roundButton}
                                variant="round"
                                onClick={onSeeAllClick}
                            >
                                +{footerTiles.length - footerMaxVisibleTiles}
                            </Button>
                        )}
                        {!showSquareInvite && (
                            <Button
                                className={
                                    isParticipantInFooter
                                        ? style.roundButton
                                        : style.fullWidthButton
                                }
                                variant={
                                    isParticipantInFooter
                                        ? 'round'
                                        : 'contained'
                                }
                                size={
                                    isParticipantInFooter ? undefined : 'large'
                                }
                                onClick={onInviteClickHandler}
                                disabled={filteredChildren.length >= maxTiles}
                            >
                                {!showCopiedToClipboard ? (
                                    <Icon name="personAdd" />
                                ) : isParticipantInFooter ||
                                  !inviteClickLabel ? (
                                    <Icon name="paste" />
                                ) : (
                                    <span>{inviteClickLabel}</span>
                                )}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        )

    const mobileButtons = (
        <div className={style.mobileButtons}>
            {filteredChildren.length > 3 && (
                <Button
                    onClick={onSeeAllClick}
                    variant="round"
                    color="secondary"
                >
                    {filteredChildren.length - 3}P
                </Button>
            )}
            <Button
                onClick={onInviteClickHandler}
                variant="round"
                color="light"
            >
                {!showCopiedToClipboard ? (
                    <Icon name="personAdd" />
                ) : (
                    <Icon name="paste" />
                )}
            </Button>
        </div>
    )

    // Return mobile version
    return (
        <div
            className={cls(
                style.conferenceContainer,
                className,
                style[variant]
            )}
            style={{ width: width }}
        >
            <div className={style.mobileLeft}>
                {bodyTiles[0] && (
                    <div
                        key="main-player"
                        className={style.tileWrapper}
                        style={
                            !variant.includes('mobile')
                                ? {
                                      height: bodyTiles[0].height,
                                      width: '100%',
                                  }
                                : {}
                        }
                    >
                        {children[0]}
                    </div>
                )}
                {orientation.includes('landscape') && mobileButtons}
            </div>
            <div className={style.mobileRight}>
                <div className={style.mobileOtherParticipants}>
                    {bodyTiles.map(
                        ({ index, variant }) =>
                            filteredChildren[index] &&
                            index !== 0 && (
                                <div
                                    key={filteredChildren[index].props.id}
                                    className={style.tileWrapper}
                                >
                                    {cloneElement(filteredChildren[index], {
                                        ...filteredChildren[index].props,
                                        variant,
                                    })}
                                </div>
                            )
                    )}
                </div>
                {orientation.includes('portrait') && mobileButtons}
            </div>
        </div>
    )
}

export default ConferenceContainer
