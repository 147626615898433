import React, { useEffect, useRef } from 'react'

import { Outline } from '@react-three/postprocessing'

// import react postprocessing outline effect useful to draw contour for toonShaded masks
import { KernelSize } from 'postprocessing' // to play with outline effect width

//  Postprocessing effect composer for toon shading
//  see https://vanruesc.github.io/postprocessing/public/docs/class/src/effects/OutlineEffect.js~OutlineEffect.html
//  and https://vanruesc.github.io/postprocessing/public/demo/#outline
//  and https://github.com/vanruesc/postprocessing/blob/main/demo/src/demos/OutlineDemo.js
//  and https://github.com/vanruesc/postprocessing/blob/main/src/effects/OutlineEffect.js
//  and https://github.com/pmndrs/react-postprocessing/blob/master/api.md#outline---
// eslint-disable-next-line
const PostprocessingOutline = (props) => {
    const outlineRef = useRef()

    useEffect(() => {
        const effectOutline = outlineRef.current
        if (!effectOutline) {
            return
        }
        props.globals.threeOutlineEffect = effectOutline

        // we have to tweak the outlinePass to take account of skinning
        const { maskPass } = effectOutline
        const maskMaterial = maskPass.overrideMaterial
        maskMaterial.skinning = true
        maskMaterial.needsUpdate = true

        // this is a very dirty hook to set the width of the border
        const update_texelSize = (mat) => {
            const { width, height } = effectOutline.resolution
            const texelSizeX = props.outlineThickness / width
            const texelSizeY = props.outlineThickness / height
            mat.uniforms.texelSize.value.set(texelSizeX, texelSizeY)
        }
        const outlineMat = effectOutline.outlinePass.getFullscreenMaterial()
        update_texelSize(outlineMat)
        outlineMat.onBeforeCompile = update_texelSize
    })

    if (props.outlineThickness === 0) {
        console.warn(
            'OUTLINE THICKNESS = 0. Please disable postprocessing effect if outline thickness = 0'
        )
        return null
    }

    // to make it faster to compute, we compute the effect at half resolution if resolutionFactor = 0.5:
    const resolutionFactor = 1

    return (
        <Outline
            ref={outlineRef}
            edgeStrength={2}
            pulseSpeed={0.0}
            visibleEdgeColor={0x000000}
            hiddenEdgeColor={0x000000}
            kernelSize={KernelSize.VERY_SMALL}
            width={props.resolution.width * resolutionFactor}
            height={props.resolution.height * resolutionFactor}
            blur={false}
            xRay={true}
        />
    )
}

export default PostprocessingOutline
