import rootReducer from './reducer'
import subscribe from './subscriptions'
import * as actions from './actions'
import * as types from './types'
import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
// import { piepie } from '../utils/'

export { actions, types }

// Through the action transformer, the logging is calibrated to avoid lines that
// are too long and circular dependencies.
// const actionTransformerFunc = (action) => {
//     const cache = []
//     // replacer is used to reduce verbosity of logging and to avoid circular references.
//     const replacer = (key, value) => {
//         if (typeof value === 'object' && value !== null) {
//             // Duplicate reference found, discard key
//             if (cache.includes(value)) return

//             // Store value in our collection
//             cache.push(value)
//         }
//         if (typeof value !== 'string') {
//             return value
//         }
//         if (value.length < 32) {
//             return value
//         }
//         return value.substring(0, 32) + '...'
//     }
//     return JSON.stringify(action, replacer)
// }

// innerLogger is used to configure the redux logger to send logs to datadog
// const innerLogger = {
//     // it is possible that piepie is not ready when the innerlogger is initialized
//     // thus we add the ? notation and call a function. In worst case, nothing is logged.
//     log: (...args) => piepie?.log(...args),
//     info: (...args) => piepie?.info(...args),
//     warn: (...args) => piepie?.warn(...args),
//     // redux logger uses collapsing, which we can inherit from console.
//     group: console.group,
//     groupCollapsed: console.groupCollapsed,
//     groupEnd: console.groupEnd,
// }

// const titleFormatter = (
//     formattedAction: any,
//     formattedTime: string,
//     took: number
// ): string => {
//     return `${formattedTime}: ${JSON.parse(formattedAction).type
//         } (took ${took}ms)`
// }

// const logger = createLogger({
//     // ...options
//     collapsed: true,
//     colors: {
//         title: false,
//         prevState: false,
//         action: false,
//         nextState: false,
//         error: false,
//     },
//     logger: innerLogger,
//     actionTransformer: actionTransformerFunc,
//     titleFormatter: titleFormatter,
// })

function middlewares(): any[] {
    // if (process.env.REACT_APP_ENV === 'DEV') {
    //     return [thunkMiddleware]
    // }
    return [thunkMiddleware]
}

function createStore(initialState: any = {}): types.Store {
    const store = configureStore({
        reducer: rootReducer,
        middleware: middlewares(),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initialState,
    })
    subscribe(store)
    return store
}

const store = createStore()

export default store
