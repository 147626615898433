import * as axios from 'axios'
import { IRedeemModalData } from '../PlanPayments/types'
import { MARIO_API_URL } from '../../../utils/mario'
import { LocalStorage } from '../../../state/storage'
import { logAxiosErrorResponse } from '../../../utils/http'

type TGetRedeemedCode = (code: string) => Promise<IRedeemModalData>

export const getRedeemedItems: TGetRedeemedCode = async (redeemCode) => {
    const idToken = localStorage.getItem(LocalStorage.idToken)
    try {
        const { status, data }: IRedeemModalData = await axios.default.post(
            `${MARIO_API_URL}/store/redeem`,
            {},
            {
                headers: {
                    authorization: `Bearer ${idToken}`,
                },
                params: {
                    code: redeemCode,
                },
            }
        )
        return { status, data }
    } catch (error) {
        logAxiosErrorResponse(error)
        // @luca probably there something better we can do
        return undefined
    }
}

export const getUserFeatures = async () => {
    const idToken = localStorage.getItem(LocalStorage.idToken)
    try {
        const { data } = await axios.default.get(
            `${MARIO_API_URL}/users/features`,
            {
                headers: {
                    authorization: `Bearer ${idToken}`,
                },
            }
        )

        const features = data.features
        return features
    } catch (error) {
        logAxiosErrorResponse(error)
    }
}
