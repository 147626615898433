import { MeshBasicMaterial, Vector2, WebGLRenderer } from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

let _renderer = null,
    _composer = null,
    _camera = null,
    _scene = null
let _blurEffectX = null,
    _blurEffectY = null
let _threeVideoMaskMaterial = null

const get_blurShader = (dxy, N) => {
    return {
        uniforms: {
            tDiffuse: { value: null },
            dxy: { value: new Vector2().fromArray(dxy) },
        },

        vertexShader: /* glsl */ `

      varying vec2 vUv;

      void main() {

        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

      }`,

        fragmentShader: /* glsl */ `

      uniform vec2 dxy;

      uniform sampler2D tDiffuse;
      const float N = ${N.toFixed(1)};

      varying vec2 vUv;

      void main() {

        vec4 sum = vec4(0.0);
        float sumK = 0.0;
        for (float i = -N; i<=N; i+=1.0){
          vec2 duv = i*dxy;
          float k = exp(-length(duv));
          sum += texture2D( tDiffuse, vUv + duv);
          sumK += k;
        }
        
        gl_FragColor = sum / sumK;

      }`,
    }
}

const init = (spec) => {
    // init background removal stuffs:
    _renderer = new WebGLRenderer({
        preserveDrawingBuffer: true,
        antialias: false,
        alpha: true,
    })
    _renderer.setSize(spec.resolution.width, spec.resolution.height)
    _threeVideoMaskMaterial = new MeshBasicMaterial({
        color: 0xffffff,
    })
    const NPix = Math.floor(spec.resolution.width * spec.blurBorderWidth)
    _blurEffectX = new ShaderPass(
        get_blurShader([1.0 / spec.resolution.width, 0.0], NPix)
    )
    _blurEffectY = new ShaderPass(
        get_blurShader([0, 1.0 / spec.resolution.height], NPix)
    )
    //document.body.appendChild(_renderer.domElement)
}

const update = (scene, camera) => {
    if (scene !== _scene || camera !== _camera) {
        _composer = new EffectComposer(_renderer)
        _composer.addPass(new RenderPass(scene, camera))
        _composer.addPass(_blurEffectX)
        _composer.addPass(_blurEffectY)
        _scene = scene
        _camera = camera
    }

    _camera.layers.disable(0)
    _camera.layers.disable(1)
    _camera.layers.enable(2)

    _scene.overrideMaterial = _threeVideoMaskMaterial
    _composer.render()
    _scene.overrideMaterial = null

    // restore state:
    _camera.layers.enable(0)
    _camera.layers.enable(1)
    _camera.layers.disable(2)
}

const get_domElement = () => {
    return _renderer.domElement
}

const resize = (resolution) => {
    if (_composer && _renderer) {
        _renderer.setSize(resolution.width, resolution.height)
        _composer.setSize(resolution.width, resolution.height)
    }
}

export default {
    init,
    get_domElement,
    update,
    resize,
}
