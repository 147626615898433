import React, { FC, useEffect, useState } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import * as Axios from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getTransactionEmailServiceHostname } from '../../utils'
import { useLocation } from 'react-router-dom'
import style from './style.module.scss'
import { useDispatch } from 'react-redux'

import { openAuthModal } from '../../store/auth/actions'
import analytics from '../../analytics'
import { AuthModalType } from '../../store/auth/types'
import { logAxiosErrorResponse } from '../../utils/http'

const ConfirmEmail: FC = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLinkBroken, setIsLinkBroken] = useState(false)
    const [apiError, setApiError] = useState('')
    const [apiErrorStatus, setApiErrorStatus] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const user = firebase.auth().currentUser
        if (user?.displayName && user?.email) {
            window.location.href = '/'
            return
        }

        const urlParams = new URLSearchParams(location.search)
        const OOBToken = urlParams.get('OOBtoken')
        const emailLink = urlParams.get('emailLink')

        if (!OOBToken || !emailLink) {
            setIsLinkBroken(true)
            return
        }
        analytics.track('ConfirmEmail', {})

        const confirmEmail = async () => {
            try {
                await Axios.default.post(
                    `${getTransactionEmailServiceHostname()}/confirmEmail`,
                    {
                        emailHash: emailLink,
                        oobtoken: OOBToken,
                    }
                )
                window.location.href = '/?emailConfirmed'
                return
            } catch (error) {
                setApiErrorStatus(error.response?.status)
                setApiError(error.response?.data)
                logAxiosErrorResponse(error)
                // We set isLoading to false only when the request fails, so we can display an error message and the link to sign up.
                // In case of a successful response, we only want to display an informational message about that the email confirmation being in progress.
                // If we set isLoading to true in case of the successful response while redirecting to the login page
                // user will be able to see a piece of information that is designed for the failure case.
                setIsLoading(false)
            }
        }

        confirmEmail()
    }, [location.search])

    return (
        <div>
            <div className={style.top}>
                <h1 className={style.heading}>
                    {isLoading ? t('ConfirmingEmail') : t('EmailConfirmation')}
                </h1>
            </div>

            {isLinkBroken && (
                <div className={style.form}>
                    <Alert variant="danger">{t('AuthConfirmLinkBroken')}</Alert>
                </div>
            )}

            {apiError && (
                <div className={style.form}>
                    <Alert variant="danger">{apiError}</Alert>
                </div>
            )}

            {!isLoading && (
                <div className={style.smallText}>
                    {apiErrorStatus && apiErrorStatus === 400 ? (
                        <Button
                            variant="link"
                            onClick={() =>
                                dispatch(
                                    openAuthModal({
                                        modalType: AuthModalType.Login,
                                    })
                                )
                            }
                            className={style.buttonLink}
                        >
                            {t('SignIn')}
                        </Button>
                    ) : (
                        <Trans i18nKey="PleaseSignUpAgain">
                            Please try
                            <Button
                                variant="link"
                                onClick={() =>
                                    dispatch(
                                        openAuthModal({
                                            modalType: AuthModalType.SignUpMenu,
                                        })
                                    )
                                }
                                className={style.buttonLink}
                            >
                                signin up
                            </Button>
                            again.
                        </Trans>
                    )}
                </div>
            )}
        </div>
    )
}

export default ConfirmEmail
