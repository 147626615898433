import styles from './style.module.scss'
import cls from 'classnames'

import React, {
    ReactNode,
    ReactElement,
    useRef,
    FC,
    MouseEvent,
    Children,
} from 'react'
import { rand } from '../../../utils'

import { Icon } from '../../../stories'

export enum FacesVideoMode {
    Video = 'video',
    Chat = 'chat',
    Mask = 'mask',
    Multiplayer = 'multiplayer',
}

const icons = {
    video: 'cam',
    chat: 'chat',
    masks: 'mask',
    multiplayer: 'people',
}

interface onChangePayload {
    currentIndex: number
    oldIndex: number
    currentData: any
}

type TabProps = {
    children?: ReactNode
    name: string
    data?: any
    notification?: boolean
    className?: string
    type?: string
    scroll?: boolean
    hidden?: boolean
}

type TabsProps = {
    children:
        | ReactElement<TabProps | ContentProps>[]
        | ReactElement<TabProps | ContentProps>
    index?: number
    className?: string
    onChange?: (e: MouseEvent, prop: onChangePayload) => void
}

const FacesTabs: FC<TabsProps> = ({
    children,
    index = 0,
    onChange,
    className,
}) => {
    const tabRandomId = useRef(rand())
    const currentTab: any = Children.toArray(children)[index]

    return (
        <div className={cls(styles.tabs, className)}>
            <ul role="tablist" className={styles.tabList}>
                {Children.toArray(children)
                    .filter(
                        (reactElement: ReactElement<TabProps | ContentProps>) =>
                            reactElement.props.type === 'FacesTab'
                    )
                    .map(
                        (
                            reactElement: ReactElement<TabProps>,
                            tabIndex: number
                        ) => {
                            return (
                                <li
                                    className={cls(
                                        styles.tabElement,
                                        reactElement.props.hidden &&
                                            styles.hidden
                                    )}
                                    role="tab"
                                    aria-selected={tabIndex === index}
                                    aria-controls={`tab-${reactElement.props.name}-${tabRandomId.current}-tab`}
                                    id={`tab-${reactElement.props.name}-${tabRandomId.current}-nav`}
                                    key={`tab-${reactElement.props.name}`}
                                >
                                    <button
                                        data-test={
                                            'FaceTab' +
                                            reactElement.props.name.toUpperCase()
                                        }
                                        className={styles.tabButton}
                                        onClick={(e) => {
                                            if (onChange)
                                                onChange(e, {
                                                    currentIndex: tabIndex,
                                                    oldIndex: index,
                                                    currentData:
                                                        reactElement.props.data,
                                                })
                                        }}
                                    >
                                        <Icon
                                            className={styles.tabIcon}
                                            name={
                                                icons[reactElement.props.name]
                                            }
                                        />

                                        {reactElement.props.notification && (
                                            <i
                                                className={styles.notification}
                                            ></i>
                                        )}
                                    </button>
                                </li>
                            )
                        }
                    )}
            </ul>
            <div
                tabIndex={0}
                role="tabpanel"
                aria-labelledby={`tab-${currentTab.props.name}-${tabRandomId.current}-nav`}
                id={`tab-${currentTab.props.name}-${tabRandomId.current}-tab`}
                className={cls(
                    styles.wrapper,
                    currentTab.props.scroll && styles.wrapperScroll
                )}
            >
                {Children.toArray(children).filter(
                    (reactElement: ReactElement<TabProps | ContentProps>) =>
                        reactElement.props.type === 'FacesContent'
                )}
            </div>
        </div>
    )
}

export const FacesTab: FC<TabProps> = ({ children, className }) => {
    return <div className={className}>{children}</div>
}

FacesTab.defaultProps = { type: 'FacesTab' }

export const FacesContent: FC<ContentProps> = ({ children, className }) => {
    return <div className={className}>{children}</div>
}

type ContentProps = {
    children: ReactNode
    className?: string
    type?: string
}

FacesContent.defaultProps = { type: 'FacesContent' }

export default FacesTabs
