export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'

export interface AuthModalState {
    isOpen?: boolean
    onClose?: () => void
    modalType?: AuthModalType
    closable?: boolean
    isTransparent?: boolean
}

export interface OpenAuthModalAction {
    type: typeof OPEN_AUTH_MODAL
    payload: AuthModalState
}

export interface CloseAuthModalAction {
    type: typeof CLOSE_AUTH_MODAL
}

export enum AuthModalType {
    Login = 'Login',
    SignUp = 'SignUp',
    SignUpMenu = 'SignUpMenu',
    ResetPassword = 'ResetPassword',
    EnterAsGuest = 'EnterAsGuest',
    ConfirmEmail = 'ConfirmEmail',
    Banned = 'Banned',
}

export type AuthModalActionTypes = OpenAuthModalAction | CloseAuthModalAction
