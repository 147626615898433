import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useChime } from '../../conference/hooks'
import { State } from '../../store/types'
import { piepie } from '../../utils'
import { Video } from './'

interface VideoFeedProps {
    className?: string
}

const VideoFeed: FC<VideoFeedProps> = ({ className }) => {
    const chime = useChime()
    const { isCameraActive, devices, isDeviceBindingComplete } = useSelector(
        (state: State) => state.conference
    )
    const videoElementRef = useRef<HTMLVideoElement>()

    useEffect(() => {
        if (
            !videoElementRef.current ||
            !chime.audioVideo ||
            !isDeviceBindingComplete
        ) {
            return
        }
        const tile = chime.audioVideo.getLocalVideoTile()
        if (tile && tile.id) {
            if (isCameraActive) {
                piepie.log(
                    'CHIME: VIDEOFEED -- Starting video feed, currentVideoInputDevice:',
                    devices.currentVideoInputDevice,
                    'using this HTML element:',
                    videoElementRef.current
                )
                chime.audioVideo.bindVideoElement(
                    tile.id(),
                    videoElementRef.current
                )
            } else {
                piepie.log(
                    'CHIME: VIDEOFEED -- Stoping video feed on element:',
                    videoElementRef.current
                )
                chime.audioVideo.unbindVideoElement(tile.id())
            }
        }

        return
    }, [
        chime.audioVideo,
        videoElementRef,
        isCameraActive,
        devices.currentVideoInputDevice,
        isDeviceBindingComplete,
    ])

    useEffect(() => {
        return () => {
            if (chime.audioVideo && !isCameraActive) {
                const tile = chime.audioVideo.getLocalVideoTile()
                piepie.log('CHIME: VIDEOFEED UNMOUNT -- Stoping video feed')
                chime.audioVideo.unbindVideoElement(tile.id())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Video ref={videoElementRef} className={className} />
}

export default VideoFeed
