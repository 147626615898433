import * as crypto from 'crypto'

// NOTE: This is used in firebase.ts and starfox.ts. Maybe it's better to move this in the src/services folder with the starfox and firebase files

// Key is the key format used to represent piepacker objects
// Keys are always 21 bytes (or 28 char in base64) and always be URL safe when base64 encoded
export function NewKey(objType: number) {
    let piepackerKey
    for (let i = 0; i < 10000; i++) {
        piepackerKey = crypto.randomBytes(21)
        piepackerKey.set([objType], 20)
        const b64Encoded = piepackerKey.toString('base64')
        let skip = false
        for (const c of b64Encoded) {
            if (c === '+' || c === '/') {
                skip = true
            }
        }
        if (skip) {
            continue
        }
        return b64Encoded
    }
    throw new Error('unable to create key, should never happen')
}

// function ObjType(key: string) {
//     return Buffer.from(key, 'base64').readInt8(20)
// }

export const OBJTYPE = {
    UNKNOWN: 0,
    LINK: 1,
    SESSION: 2,
    USR_SESSION: 3,
    USR: 4,
    VERIFY_TOKEN: 5,
}

// updateUrlWithParam allows to add or remove params in the url
// e.g. ?details=<gameId>&search=<search_string>
/**
 * @key key of the param
 * @v value of the param, if empty then it removes the param from the URL
 */
export const updateUrlWithParam = (key: string, v: string) => {
    key = encodeURIComponent(key)
    if (v) {
        const value = encodeURIComponent(v)
        let s = document.location.search
        const kvp = key + '=' + value
        const r = new RegExp('(&|\\?)' + key + '=[^]*')
        s = s.replace(r, '$1' + kvp)
        if (!RegExp.$1) {
            s += (s.length > 0 ? '&' : '?') + kvp
        }
        window.history.pushState(null, null, s)
    } else {
        const url = new URL(window.location.href)
        const search = url.searchParams.get(key)
        if (!search) return
        let s = document.location.search
        const r = new RegExp('[?|&]' + key + '=([^&]+)')
        s = s.replace(r, '')
        if (s[s.length - 1] === '&') s = s.slice(0, -1)
        s = s === '?' ? '' : s
        window.history.pushState(null, null, window.location.pathname + s)
    }
}
