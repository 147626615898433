import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Spacing } from '../../../stories'
import style from './style.module.scss'
import { usePausePlay } from '../../../utils/GameRoomHooks'

import * as Socket from '../../../network/socket'

const Reset = ({ setSection, setShowOptions }) => {
    const { t } = useTranslation()
    const { handleResumeGame } = usePausePlay()
    const handleSection = () => {
        handleResumeGame()
        setSection('pause')
    }
    const handleReset = () => {
        Socket.resetGame()
        setSection('pause')
        setShowOptions(false)
    }
    return (
        <div className={style.layoutContainer}>
            <div className={style.layoutHeader}>
                <Button variant="round" onClick={handleSection}>
                    <Icon name="arrowBack" />
                </Button>
                <p className={style.headerText}>{t('Reset')}</p>
                <Spacing right="3" inline />
                <Icon name="pausePie" width="1.5rem" height="1.5rem" />
            </div>

            <div className={style.resetConfirmation}>
                <div>
                    <p>{t('ResetConfirm')}</p>
                    <p>{t('ResetGame')}</p>
                </div>

                <Button color="primary" size="large" onClick={handleReset}>
                    {t('Reset')}
                </Button>
            </div>
        </div>
    )
}

export default Reset
