import i18n from '../i18n'

export function validateNickname(message: string): any {
    return this.test('nickname', message, function (value: string) {
        const { path, createError } = this
        const nicknameRegex = /^[a-zA-Z0-9 ]*$/
        const isValid = nicknameRegex.test(value)
        if (!isValid) {
            return createError({
                path,
                message,
            })
        }

        return true
    })
}

export function validatePassword(message?: string): any {
    return this.test('weakPassword', message, function (value: string) {
        const { path, createError } = this

        if (!value) {
            return
        }

        if (value.startsWith(' ') || value.endsWith(' ')) {
            return createError({
                path,
                message: i18n.t('PasswordErrorSpace'),
            })
        }

        const weakPasswords = [
            '12345678',
            'password',
            '11111111',
            '00000000',
            'picture1',
        ]
        const isValid = !weakPasswords.includes(value)
        if (!isValid) {
            return createError({
                path,
                message: i18n.t('WeakPassword'),
            })
        }

        return true
    })
}
