import React from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import style from '../styles.module.scss'
import { Button, Input } from '../../../stories'
import { useFormik } from 'formik'
import { useAlert } from '../useAlert'
import PrivacyCheck from '../PrivacyCheck'
import { useValidation } from './validation'
import { usePrivacyCheck } from '../usePrivacyCheck'
import AlternativeSignIn from '../AlternativeSignIn'
import AlternativeSignUp from '../AlternativeSignUp'
import { handleGuestSignIn } from '../../../store/auth/services'

const GuestSignIn = () => {
    const { t } = useTranslation()
    useAlert()
    const { privacyError, setPrivacyError, checkPrivacy, setCheckPrivacy } =
        usePrivacyCheck()

    const validationSchema = useValidation()

    const formik = useFormik({
        validationSchema,
        initialValues: {
            displayName: '',
        },

        onSubmit: async (values, { setSubmitting }) => {
            if (!checkPrivacy) {
                setPrivacyError(true)
                setSubmitting(false)
                return
            }
            await handleGuestSignIn(values)
            setSubmitting(false)
        },
    })

    const { handleSubmit, handleChange, values, isSubmitting, errors } = formik
    const { displayName } = values

    return (
        <form className={style.form} onSubmit={handleSubmit}>
            <div className={style.inputWrapper}>
                <div>
                    <label>{t('Nickname')}</label>
                    <Input
                        name="displayName"
                        value={displayName}
                        placeholder={t('YourNickname')}
                        onChange={handleChange}
                    />
                    {errors.displayName && (
                        <div className={style.errorMsg}>
                            {errors.displayName}
                        </div>
                    )}
                </div>
            </div>
            <div className={style.checkUpdatesContainer}>
                <PrivacyCheck
                    privacyError={privacyError}
                    hasAgreedToTerms={checkPrivacy}
                    setPrivacyError={setPrivacyError}
                    setHasAgreedToTerms={setCheckPrivacy}
                />
            </div>
            <Button
                className={cls(style.btn, style.submitButton)}
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {t('Continue')}
            </Button>
            <div className={style.altFooterWrapper}>
                <AlternativeSignIn />
                <AlternativeSignUp />
            </div>
        </form>
    )
}

export default GuestSignIn
