import React, { FC, useCallback, useState } from 'react'
import style from './style.module.scss'
import { Button, Icon } from '../../../stories'
import { useTranslation } from 'react-i18next'
import { saveGame } from '../../../network/socket'
import { showLoading } from '../../../utils/loading'
import { useSelector } from 'react-redux'
import { State } from '../../../store/types'
import { GAME_STATE } from '../../../store/session/types'
import { usePausePlay, useSaveStates } from '../../../utils/GameRoomHooks'
import { ConfirmDialog } from '../../../state/confirm'
import ConfirmModal from '../../ConfirmModal'

interface PauseOverlayProps {
    setSection: (x: string) => void
    handleShowRoomSettings: () => void
}

export const PauseOverlay: FC<PauseOverlayProps> = ({
    setSection,
    handleShowRoomSettings,
}) => {
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>(
        ConfirmDialog.None
    )

    const { t } = useTranslation()
    const status = useSelector((state: State) => state.session.gameState)
    const highlightStatus = useSelector(
        (state: State) => state.screen.highlightScreenStatus
    )

    const isHost = useSelector((state: State) => state.session.isHost)

    const { handlePausePlay } = usePausePlay(setSection)

    const { handleSaveStates, removeLast } = useSaveStates(
        saveGame,
        setConfirmDialog
    )

    const { handleResumeGame, handlePauseGame } = usePausePlay()

    const hasNotYetPlayed = showLoading(status, highlightStatus)
    const isPaused = status === GAME_STATE.PAUSED

    const handlePausePlayState = () => {
        handlePausePlay(false, false)
    }

    const onConfirmModalValidate = useCallback(() => {
        if (confirmDialog === ConfirmDialog.Override) {
            saveGame()
            removeLast()
        }
        setConfirmDialog(ConfirmDialog.None)
        handleResumeGame()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmDialog, saveGame, removeLast])

    const onConfirmModalClose = useCallback(() => {
        setConfirmDialog(ConfirmDialog.None)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={style.containerButtons}>
                {isHost && (
                    <>
                        <span onClick={handlePausePlayState}>
                            {isPaused || hasNotYetPlayed ? (
                                <Button variant="ellipticalWithIcon">
                                    <Icon name="plainPlay" /> {t('Play')}
                                </Button>
                            ) : (
                                <Button variant="ellipticalWithIcon">
                                    <Icon name="plainPause" /> {t('Pause')}
                                </Button>
                            )}
                        </span>

                        <Button
                            variant="ellipticalWithIcon"
                            onClick={() => {
                                handlePauseGame()
                                setSection('load')
                            }}
                        >
                            <Icon name="load" /> {t('Load')}
                        </Button>

                        <Button
                            variant="ellipticalWithIcon"
                            onClick={() => {
                                handleSaveStates()
                            }}
                        >
                            <Icon name="quickSave" /> {t('Save')}
                        </Button>

                        <Button
                            variant="ellipticalWithIcon"
                            onClick={() => {
                                handlePauseGame()
                                setSection('reset')
                            }}
                        >
                            <Icon name="replay" /> {t('Reset')}
                        </Button>

                        <Button
                            variant="ellipticalWithIcon"
                            onClick={() => {
                                handlePauseGame()
                                setSection('switch')
                            }}
                        >
                            <Icon name="cached" /> {t('Games')}
                        </Button>

                        <Button
                            variant="ellipticalWithIcon"
                            onClick={() => {
                                handleShowRoomSettings()
                            }}
                        >
                            <Icon name="settings" /> {t('Room')}
                        </Button>
                    </>
                )}

                <Button
                    variant="ellipticalWithIcon"
                    onClick={() => {
                        handlePauseGame()
                        setSection('layout')
                    }}
                >
                    <Icon name="videoGame" /> {t('Layout')}
                </Button>
            </div>

            <ConfirmModal
                confirmDialog={confirmDialog}
                open={confirmDialog !== ConfirmDialog.None}
                onValidate={onConfirmModalValidate}
                onClose={onConfirmModalClose}
            />
        </>
    )
}
