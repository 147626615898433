import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { TextLink } from '../../stories'
import { AuthModalType } from '../../store/auth/types'
import { setCurrentView } from '../../store/auth/authV2'

const AlternativeSignIn: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleCtaClick = () => dispatch(setCurrentView(AuthModalType.Login))

    return (
        <div className={style.altFooter}>
            <small>{t('AlreadyHaveAccount')}</small>
            <TextLink onClick={handleCtaClick}>
                <b>{t('SignIn')}</b>
            </TextLink>
        </div>
    )
}

export default AlternativeSignIn
