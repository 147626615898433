import { useState } from 'react'

export const usePrivacyCheck = () => {
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const [privacyError, setPrivacyError] = useState(false)

    return {
        privacyError,
        setPrivacyError,
        checkPrivacy,
        setCheckPrivacy,
    }
}
