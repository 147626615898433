import React, { FC } from 'react'
import { BadgeInterface } from '../../state/starfox'

import style from './style.module.scss'
import cls from 'classnames'

export enum BadgeSize {
    Regular = 'md',
    Small = 'sm',
    ExtraSmall = 'xsm',
}
interface BadgesProps {
    size?: BadgeSize
    badgeList?: BadgeInterface[]
}

const Badges: FC<BadgesProps> = ({
    size = BadgeSize.Regular,
    badgeList = [],
}) => {
    return (
        <div className={style.Badges}>
            {badgeList.map((b) => {
                if (!b) return null
                return (
                    <img
                        className={cls(style.image, style[size])}
                        key={b.ID}
                        src={b.ImageUrl}
                        alt={b.Name}
                        title={b.Name}
                    />
                )
            })}
        </div>
    )
}

export default Badges
