import { piepie } from '../../utils'

const PROG_RGB = 0
const PROG_BGR = 1
const PROG_MERGE_RGB = 2
const PROG_MERGE_BGR = 3

const vertShaderScript = `
    precision mediump float;

    attribute vec2 vert;
    attribute vec2 vertTexCoord;

    varying vec2 fragTexCoord;

    void main() {
        fragTexCoord = vertTexCoord;
        gl_Position = vec4(vert, 0.0, 1.0);
    }
    `

let fragShaderRGBScript = `
    precision mediump float;

    uniform vec2 OutputSize;
    uniform vec2 InputSize;
    uniform sampler2D Texture;
    varying vec2 fragTexCoord;

    void main() {
        vec4 c = texture2D(Texture, fragTexCoord);
        gl_FragColor = vec4(c.rgb, 1.0);
    }
    `

let fragShaderBGRScript = `
    precision mediump float;

    uniform vec2 OutputSize;
    uniform vec2 InputSize;
    uniform sampler2D Texture;
    varying vec2 fragTexCoord;

    void main() {
        vec4 c = texture2D(Texture, fragTexCoord);
        gl_FragColor = vec4(c.bgr, 1.0);
    }
    `
let fragShaderMergeRGBScript = `
    precision mediump float;

    uniform vec2 OutputSize;
    uniform vec2 InputSize;
    uniform sampler2D Texture;
    uniform sampler2D PrevTexture;
    varying vec2 fragTexCoord;

    void main() {
        vec4 c0 = texture2D(Texture, fragTexCoord);
        vec4 c1 = texture2D(PrevTexture, fragTexCoord);
        vec4 c  = (c0 + c1) * 0.5;
        gl_FragColor = vec4(c.rgb, 1.0);
    }
    `

let fragShaderMergeBGRScript = `
    precision mediump float;

    uniform vec2 OutputSize;
    uniform vec2 InputSize;
    uniform sampler2D Texture;
    uniform sampler2D PrevTexture;
    varying vec2 fragTexCoord;

    void main() {
        vec4 c0 = texture2D(Texture, fragTexCoord);
        vec4 c1 = texture2D(PrevTexture, fragTexCoord);
        vec4 c  = (c0 + c1) * 0.5;
        gl_FragColor = vec4(c.bgr, 1.0);
    }
    `

const vertices = new Float32Array([
    //  X, Y, U, V
    -1.0,
    -1.0,
    0.0,
    1.0, // left-bottom

    -1.0,
    1.0,
    0.0,
    0.0, // left-top

    1.0,
    -1.0,
    1.0,
    1.0, // right-bottom

    1.0,
    1.0,
    1.0,
    0.0, // right-top
])

export let frames = 0
export const setFrames = (f) => {
    frames = f
}

export default class Video {
    canvasContainer: any
    canvas: any
    gl: any
    nogl: any
    useGL: boolean
    vao: any
    vbo: any
    texID: Array<any>
    format: number
    pitch: number
    pixFmt: any
    pixType: any
    bpp: number
    width: number
    height: number
    data: any
    locationPrograms: Array<Map<string, number>>
    programs: Array<any>
    currentProgram: number
    intFmt: any
    wfactor: number
    hfactor: number
    overscanLeft: number
    overscanRight: number
    overscanTop: number
    overscanBottom: number
    blendFrame: number
    blendPrevFrame: number

    onCanvasChange: (canvas: HTMLCanvasElement) => void

    constructor(
        canvasContainer,
        onCanvasChange,
        wfactor,
        hfactor,
        overscanLeft,
        overscanRight,
        overscanTop,
        overscanBottom,
        blendFrame
    ) {
        this.canvasContainer = canvasContainer
        this.onCanvasChange = onCanvasChange
        this.wfactor = wfactor
        this.hfactor = hfactor
        this.overscanLeft = overscanLeft
        this.overscanRight = overscanRight
        this.overscanTop = overscanTop
        this.overscanBottom = overscanBottom
        this.blendFrame = blendFrame
        this.blendPrevFrame = 0

        this.useGL = true
        this.nogl = null
        this.gl = null

        this.vao = null
        this.vbo = null
        this.texID = [null, null]

        this.format = 0
        this.pitch = 0
        this.pixFmt = null
        this.pixType = null
        this.bpp = 0

        this.width = 0
        this.height = 0

        this.data = null

        this.locationPrograms = [new Map(), new Map(), new Map(), new Map()]
        this.programs = [null, null]
        this.currentProgram = PROG_RGB

        this.initCanvas.bind(this)
        this.canvas = this.initCanvas()

        try {
            this.configure()
        } catch (e) {
            piepie.log('[Video]: configure caugh exception ! ', e)
        }

        this.checkCanUseGL()
        // Is it legal to always request a 2d context
        this.nogl = this.canvas.getContext('2d')

        this.canvas.addEventListener(
            'webglcontextlost',
            (event) => {
                piepie.log('[Video]: webglcontextlost')
                event.preventDefault()

                // Use 2d context fallback
                this.gl = null
                this.canvas = this.initCanvas()
                this.canvas.setAttribute('data-context-type', '2d')
                this.nogl = this.canvas.getContext('2d')
                this.onCanvasChange(this.canvas)
            },
            false
        )

        // FIXME: Do we need this? When we context is lost we transition to 2D context now. Should we try to re-create a webgl2 canvas?
        this.canvas.addEventListener(
            'webglcontextrestored',
            this.configure.bind(this),
            false
        )

        this.onCanvasChange(this.canvas)
    }

    checkCanUseGL() {
        if (this.gl == null) {
            this.useGL = false
        }
    }

    updateFilter(index) {
        this.bindTexture(index)

        this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_MIN_FILTER,
            this.gl.NEAREST
        )
        this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_MAG_FILTER,
            this.gl.NEAREST
        )
        this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_WRAP_S,
            this.gl.CLAMP_TO_EDGE
        )
        this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_WRAP_T,
            this.gl.CLAMP_TO_EDGE
        )
    }

    setPixelFormatUnsafe(format) {
        piepie.log('[Video]: Set Pixel Format: ', format)
        this.format = format

        this.checkCanUseGL()
        if (!this.useGL) {
            // no webgl we are done
            return
        }

        switch (format) {
            case 0: // RETRO_PIXEL_FORMAT_0RGB1555
                this.intFmt = this.gl.RGB
                this.pixFmt = this.gl.UNSIGNED_SHORT_5_5_5_1
                this.pixType = this.gl.BGRA
                this.bpp = 2
                this.gl.pixelStorei(
                    this.gl.UNPACK_ROW_LENGTH,
                    this.pitch / this.bpp
                )
                return true
            case 1: // RETRO_PIXEL_FORMAT_XRGB8888
                this.intFmt = this.gl.RGBA
                this.pixFmt = this.gl.UNSIGNED_BYTE
                this.pixType = this.gl.RGBA
                this.bpp = 4
                this.gl.pixelStorei(
                    this.gl.UNPACK_ROW_LENGTH,
                    this.pitch / this.bpp
                )
                // temporary workaround for PSX games, in order to have right colors
                if (this.blendFrame) {
                    this.useProgram(PROG_MERGE_BGR)
                } else {
                    this.useProgram(PROG_BGR)
                }
                this.updateVertexAttributes()
                return true
            case 2: // RETRO_PIXEL_FORMAT_RGB565
                this.intFmt = this.gl.RGB
                this.pixFmt = this.gl.UNSIGNED_SHORT_5_6_5
                this.pixType = this.gl.RGB
                this.bpp = 2
                this.gl.pixelStorei(
                    this.gl.UNPACK_ROW_LENGTH,
                    this.pitch / this.bpp
                )
                return true
            default:
                piepie.log('[Video]: Unknown pixel format: ', format)
        }

        this.gl.pixelStorei(this.gl.UNPACK_ROW_LENGTH, this.pitch / this.bpp)
        return false
    }

    setPixelFormat(format) {
        try {
            this.setPixelFormatUnsafe(format)
        } catch (e) {
            piepie.log('[Video]: setPixelFormat caugh exception ! ', e)
            this.useGL = false
        }
    }

    setInputSize(width, height, pitch) {
        this.width = width / this.wfactor
        this.height = height / this.hfactor
        this.pitch = pitch
    }

    setInputDataUnsafe(data) {
        if (!data) return

        this.checkCanUseGL()

        if (
            this.overscanLeft === 0 &&
            this.overscanRight === 0 &&
            this.overscanTop === 0 &&
            this.overscanBottom === 0
        ) {
            // No overscan
            this.data = data
        } else {
            let pixel_to_index
            if (this.format === 1) {
                pixel_to_index = 4 // 32 bits color, 1 pixel per 4 index
            } else {
                pixel_to_index = 1 // 16 bits color, 1 pixel per index
            }
            const offset =
                this.overscanLeft * pixel_to_index +
                this.pitch * this.overscanTop
            this.data = data.subarray(offset)
            this.width = this.width - this.overscanLeft - this.overscanRight
            this.height = this.height - this.overscanTop - this.overscanBottom
        }

        if (this.useGL) {
            // Without blending frame, upload of texture can be defered at render time
            // which allow to skip some them
            // However blending frame needs to have 2 consecutive frames, so we can't skip
            // any texture upload
            if (this.blendFrame) {
                this.uploadTexture(this.blendPrevFrame)
                this.blendPrevFrame = ~this.blendPrevFrame & 1
            }
        }
    }

    setInputData(data) {
        try {
            this.setInputDataUnsafe(data)
        } catch (e) {
            piepie.log('[Video]: setInputData caugh exception ! ', e)
            this.useGL = false
        }
    }

    initCanvas() {
        if (this.canvas) {
            // Delete the canvas is previously initialized
            this.canvas.remove()
        }

        const canvas = document.createElement('canvas')
        canvas.setAttribute('data-test', 'CanvasGame')
        canvas.setAttribute('tab-index', '1')
        this.canvasContainer.appendChild(canvas)
        return canvas
    }

    configure() {
        piepie.log('[Video]: configure')
        // Context reset can happen at any time, even during context creation. You
        // must ensure context will properly restored
        //
        // getContext will return a singleton from the canvas so it is fine to call
        // it again
        this.gl = this.canvas.getContext('webgl2', {
            preserveDrawingBuffer: true, // this is needed for screenshots to work
        })

        if (!(this.gl && this.gl instanceof WebGL2RenderingContext)) {
            piepie.error('ERROR: your browser have no WebGL2 support!')
            this.gl = null
            this.checkCanUseGL()
            return
        }

        this.canvas.setAttribute('data-context-type', 'webgl2')

        this.gl.clearColor(0, 0, 0, 0)

        this.buildProgram(fragShaderRGBScript, PROG_RGB)
        this.buildProgram(fragShaderBGRScript, PROG_BGR)
        this.buildProgram(fragShaderMergeRGBScript, PROG_MERGE_RGB)
        this.buildProgram(fragShaderMergeBGRScript, PROG_MERGE_BGR)

        if (this.blendFrame) {
            this.useProgram(PROG_MERGE_RGB)
        } else {
            this.useProgram(PROG_RGB)
        }

        // Configure the vertex data
        this.vao = this.gl.createVertexArray()
        this.gl.bindVertexArray(this.vao)

        this.vbo = this.gl.createBuffer()
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vbo)
        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            vertices,
            this.gl.STATIC_DRAW,
            0
        )

        this.updateVertexAttributes()

        // Some cores won't call SetPixelFormat, provide default values
        this.setPixelFormat(this.format)

        this.texID[0] = this.gl.createTexture()
        this.updateFilter(0)

        if (this.blendFrame) {
            this.texID[1] = this.gl.createTexture()
            this.updateFilter(1)
        }

        this.updateViewport()
    }

    createShader(type, script) {
        const shader = this.gl.createShader(type)

        this.gl.shaderSource(shader, script)
        this.gl.compileShader(shader)

        if (
            !this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS) &&
            !this.gl.isContextLost()
        )
            throw new Error(
                `Shader compilation failed: ${this.gl.getShaderInfoLog(shader)}`
            )

        return shader
    }

    newProgram(vertexShader, fragmentShader) {
        const program = this.gl.createProgram()

        this.gl.attachShader(program, vertexShader)
        this.gl.attachShader(program, fragmentShader)

        this.gl.linkProgram(program)

        if (
            !this.gl.getProgramParameter(program, this.gl.LINK_STATUS) &&
            !this.gl.isContextLost()
        )
            throw new Error(`Shader linking failed: ${this.gl.getError()}`)

        return program
    }

    buildProgram(fragShaderScript, index) {
        const fragShader = this.createShader(
            this.gl.FRAGMENT_SHADER,
            fragShaderScript
        )
        const vertShader = this.createShader(
            this.gl.VERTEX_SHADER,
            vertShaderScript
        )
        const prog = this.newProgram(fragShader, vertShader)
        this.programs[index] = prog

        this.useProgram(index)

        // Note: it is faster to cache location in the app rather than relying on the server/driver
        const textureUniform = this.gl.getUniformLocation(prog, `Texture`)
        this.gl.uniform1i(textureUniform, 0)

        const prevTextureUniform = this.gl.getUniformLocation(
            prog,
            `PrevTexture`
        )
        if (prevTextureUniform) {
            this.gl.uniform1i(prevTextureUniform, 1)
        }

        // Modern (#300 es) shader can put the attrib location in the shader directly
        this.locationPrograms[index].set(
            `VA_TEX`,
            this.gl.getAttribLocation(prog, `vertTexCoord`)
        )
        this.locationPrograms[index].set(
            `VA_POS`,
            this.gl.getAttribLocation(prog, `vert`)
        )

        this.locationPrograms[index].set(
            `TextureSize`,
            this.gl.getUniformLocation(prog, `TextureSize`)
        )
        this.locationPrograms[index].set(
            `InputSize`,
            this.gl.getUniformLocation(prog, `InputSize`)
        )
        this.locationPrograms[index].set(
            `OutputSize`,
            this.gl.getUniformLocation(prog, `OutputSize`)
        )
    }

    updateCanvasSize() {
        const width = Math.max(1, this.width)
        const height = Math.max(1, this.height)

        if (this.canvas) {
            this.canvas.width = width
            this.canvas.height = height
        }
    }

    updateViewport() {
        const width = Math.max(1, this.width)
        const height = Math.max(1, this.height)

        if (this.gl.resize) this.gl.resize(width, height)

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vbo)
        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            vertices,
            this.gl.STATIC_DRAW,
            0
        )

        this.gl.viewport(0, 0, width, height)
    }

    updateUniform() {
        this.gl.uniform2f(
            this.locationPrograms[this.currentProgram].get(`TextureSize`),
            this.width,
            this.height
        )
        this.gl.uniform2f(
            this.locationPrograms[this.currentProgram].get(`InputSize`),
            this.width,
            this.height
        )
        this.gl.uniform2f(
            this.locationPrograms[this.currentProgram].get(`OutputSize`),
            this.width,
            this.height
        )
    }

    updateVertexAttributes() {
        // Modern (#300 es) shader can put the attrib location in the shader directly
        const vertAttrib =
            this.locationPrograms[this.currentProgram].get(`VA_POS`)
        this.gl.enableVertexAttribArray(vertAttrib)
        this.gl.vertexAttribPointer(
            vertAttrib,
            2,
            this.gl.FLOAT,
            false,
            4 * 4,
            0
        )

        const texCoordAttrib =
            this.locationPrograms[this.currentProgram].get(`VA_TEX`)
        this.gl.enableVertexAttribArray(texCoordAttrib)
        this.gl.vertexAttribPointer(
            texCoordAttrib,
            2,
            this.gl.FLOAT,
            false,
            4 * 4,
            2 * 4
        )
    }

    useProgram(index) {
        this.currentProgram = index
        this.gl.useProgram(this.programs[this.currentProgram])
    }

    bindTexture(index) {
        this.gl.activeTexture(this.gl.TEXTURE0 + index)
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.texID[index])
    }

    uploadTexture(index) {
        this.bindTexture(index)

        this.gl.pixelStorei(this.gl.UNPACK_ROW_LENGTH, this.pitch / this.bpp)

        this.gl.texImage2D(
            this.gl.TEXTURE_2D,
            0,
            this.intFmt,
            this.width,
            this.height,
            0,
            this.pixType,
            this.pixFmt,
            this.data
        )
    }

    renderNoGL() {
        const front_buffer = this.nogl.createImageData(
            this.canvas.width,
            this.canvas.height
        )
        const buf = front_buffer.data
        let out = 0
        switch (this.format) {
            case 2: // RETRO_PIXEL_FORMAT_RGB565
                for (let y = 0; y < this.height; y++) {
                    // in_line index is in pixel, but this.pitch is in byte
                    const in_line = (this.pitch / 2) * y
                    out = this.canvas.width * 4 * y
                    for (let x = 0; x < this.width; x++) {
                        const input = in_line + x // data is uint16 array, so pixel indexed
                        const color = this.data[input]
                        buf[out] = (color & 0xf800) >> 8
                        out++
                        buf[out] = (color & 0x07e0) >> 3
                        out++
                        buf[out] = (color & 0x001f) << 3
                        out++
                        buf[out] = 255
                        out++
                    }
                }
                break
            case 1: // RETRO_PIXEL_FORMAT_XRGB8888
                for (let y = 0; y < this.height; y++) {
                    const in_line = this.pitch * y
                    out = this.canvas.width * 4 * y
                    for (let x = 0; x < this.width; x++) {
                        // Swap RED and BLUE channel
                        const input = in_line + x * 4 // data is uint8 array
                        buf[out] = this.data[input + 2]
                        out++
                        buf[out] = this.data[input + 1]
                        out++
                        buf[out] = this.data[input + 0]
                        out++
                        buf[out] = 255
                        out++
                    }
                }
                break
            default:
                break
        }
        createImageBitmap(front_buffer, 0, 0, this.width, this.height).then(
            (image) => {
                this.nogl.drawImage(
                    image,
                    0,
                    0,
                    this.canvas.width,
                    this.canvas.height
                )
            }
        )
    }

    renderGL() {
        this.updateViewport()

        this.gl.clear(this.gl.COLOR_BUFFER_BIT)

        this.useProgram(this.currentProgram)
        this.updateUniform()

        // Without blending frame, upload of texture can be defered at render time
        // which allow to skip some them
        // However blending frame needs to have 2 consecutive frames, so we can't skip
        // any texture upload
        if (this.blendFrame) {
            this.bindTexture(0)
            this.bindTexture(1)
        } else {
            this.uploadTexture(0)
        }

        this.gl.bindVertexArray(this.vao)

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vbo)

        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4)
    }

    render() {
        frames++

        if (
            !this.data ||
            this.width === 0 ||
            this.height === 0 ||
            this.pitch === 0
        )
            return

        this.updateCanvasSize()

        this.checkCanUseGL()
        if (this.useGL) {
            try {
                this.renderGL()
            } catch (e) {
                piepie.log('[Video]: render caugh exception ! ', e)
                this.useGL = false
            }
        } else {
            this.renderNoGL()
        }
    }
}
