import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertType } from '../../state/alert'
import * as Socket from '../../network/socket'

import store, { actions } from '../../store'
import alertSlice from '../../store/alert/alert'
import { State } from '../../store/types'
import { piepie } from '../../utils'

import { isGame } from '../../utils/loading'
import { isMobile } from 'react-device-detect'
import retroSlice from '../../store/retro/retro'
import { ScreenType } from '../../components/Screen/screen-props'

export const usePausePlay = (setMobileSection?: (state: string) => void) => {
    const dispatch = useDispatch()
    const gameState = useSelector((state: State) => state.session.gameState)
    const screenType = useSelector((state: State) => state.screen.screenType)

    const handlePauseGame = useCallback(() => {
        if (screenType === ScreenType.CLOUD) {
            piepie.log('[STREAMING SCREEN] pause game')
            Socket.pauseGame()
        } else {
            dispatch(retroSlice.actions.setPaused(true))
            piepie.log('[SCREEN] pause game')
        }
        store.dispatch(actions.session.setGameStatePaused())
        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Info,
                icon: 'pause',
                message: 'GamePaused',
            })
        )
    }, [dispatch, screenType])

    const handleResumeGame = useCallback(() => {
        if (screenType === ScreenType.CLOUD) {
            piepie.log('[STREAMING SCREEN] resume game')
            Socket.resumeGame()
        } else {
            dispatch(retroSlice.actions.setPaused(false))
            piepie.log('[SCREEN] resume game')
        }
        store.dispatch(actions.session.setGameStateReady())
        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Info,
                icon: 'play',
                message: 'GameResumed',
            })
        )
    }, [dispatch, screenType])

    const handlePausePlay = useCallback(
        (onlyPause = false, onlyResume = false) => {
            if (isGame(gameState).paused) {
                if (onlyPause) return
                handleResumeGame()
            } else {
                if (onlyResume) return
                handlePauseGame()
                isMobile && setMobileSection('pause')
            }
        },
        [gameState, setMobileSection, handlePauseGame, handleResumeGame]
    )

    return {
        handlePausePlay,
        handlePauseGame,
        handleResumeGame,
    }
}
