import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import { PubSubEvent, globalPubSub } from '../event/event'
import { ConfirmationType } from '../components/Screen/Confirmation'
import { Dispatch } from 'react'
import { piepie } from '../utils'
import * as Socket from '../network/socket'
import md5 from 'crypto-js/md5'

// This Handler is used only for the local mode
export default class SaveHandler {
    retro: any
    gameName: string
    uid: string
    canvas: HTMLCanvasElement
    currentUpload: firebase.storage.UploadTask
    setConfirmNotif: Dispatch<ConfirmationType>
    resume: () => void
    startAutosave: () => void
    SramHash: string

    constructor(
        gameName: string,
        uid: string,
        canvas: HTMLCanvasElement,
        setConfirmNotif: Dispatch<ConfirmationType>,
        resume: () => void,
        startAutosave: () => void
    ) {
        piepie.log('[SAVE HANDLER] init')
        this.retro = null
        this.gameName = gameName
        this.uid = uid
        this.canvas = canvas
        this.setConfirmNotif = setConfirmNotif
        this.resume = resume
        this.startAutosave = startAutosave
        this.currentUpload = null
        this.SramHash = 'invalid'
    }

    // setRetro is used to set retro (libretro)
    setRetro = (retro: any) => {
        this.retro = retro
    }

    setCanvas = (canvas: HTMLCanvasElement) => {
        this.canvas = canvas
    }

    // loadSaveState loads a savestate in the emulator from firebase storage
    loadSaveState = async (saveName: string) => {
        const storageRef = firebase.storage().ref()
        try {
            const savestateRef = storageRef.child(
                `user/${this.uid}/savestates/${this.gameName}/${saveName}.state`
            )
            const url = await savestateRef.getDownloadURL()
            const resp = await fetch(url)
            const blob = await resp.blob()
            const arrayBuf = await blob.arrayBuffer()
            piepie.log('[SAVE HANDLER] savestate loaded:', saveName)
            this.retro.setState(arrayBuf)
        } catch (e) {
            piepie.error(
                '[SAVE HANDLER] error unable to load savestate',
                saveName + ':',
                e
            )
        }
        this.startAutosave()
        this.resume()
    }

    // dataURLtoBlob converts a string dataUrl to a Blob
    dataURLtoBlob = (dataurl: string) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    }

    // checkAutoSaveExists checks if an automatic savestate already exists, then ask the user if he wants to reload it
    checkAutoSaveExists = async (callback) => {
        const storageRef = firebase.storage().ref()
        const savestatesRef = storageRef.child(
            `user/${this.uid}/savestates/${this.gameName}`
        )
        const stt = await savestatesRef.listAll()
        for (const [, itemRef] of Object.entries(stt.items)) {
            const name = itemRef.name.replace('.state', '')
            if (name.indexOf('auto') >= 0) {
                piepie.log('[SAVE HANDLER] autosave exists')
                this.setConfirmNotif(ConfirmationType.Reload)
                callback(true)
                return
            }
        }
        piepie.log('[SAVE HANDLER] autosave does not exist')
        callback(false)
    }

    // senSaveState sends savestate to firebase storage (with a screenshot for manual savestates)
    sendSaveState = async () => {
        const storageRef = firebase.storage().ref()
        const date = new Date()
        const name = `${date.getUTCFullYear()}-${
            date.getUTCMonth() + 1
        }-${date.getUTCDate()}-${date.getUTCHours()}-${date.getUTCMinutes()}-${date.getUTCSeconds()}`
        piepie.log('[SAVE HANDLER] send savestate', name)
        const savestateRef = storageRef.child(
            `user/${this.uid}/savestates/${this.gameName}/${name}.state`
        )
        const screenshotsRef = storageRef.child(
            `user/${this.uid}/screenshots/${this.gameName}/${name}.png`
        )
        try {
            const screenshot = this.canvas.toDataURL('png')
            this.send(screenshotsRef, this.dataURLtoBlob(screenshot))
            const time = Date.now()
            const arrayBuf = this.retro.getState() as ArrayBuffer
            this.send(savestateRef, arrayBuf)
            globalPubSub.pub(PubSubEvent.GAME_SAVED, '')
            console.log("durée d'une save:", Date.now() - time)
            piepie.log('[SAVE HANDLER] game saved successfully')
        } catch (e) {
            piepie.error('[SAVE HANDLER] error game save failed:', e)
            globalPubSub.pub(PubSubEvent.GAME_SAVE_FAILED, '')
        }
    }

    // sendAutoSaveState sends autosave to lemmings
    sendAutoSaveState = () => {
        try {
            const savestate = this.retro.getState() as ArrayBuffer
            Socket.autoSave(new Uint8Array(savestate))
        } catch (e) {
            piepie.error(
                '[SAVE HANDLER] error unable to get auto savestate:',
                e
            )
        }
    }

    // loadSRAM loads a savefile in the emulator
    loadSRAM = async () => {
        try {
            const storageRef = firebase.storage().ref()
            const savefilesRef = storageRef.child(
                `user/${this.uid}/savefiles/${this.gameName}/${this.gameName}.srm`
            )
            const url = await savefilesRef.getDownloadURL()
            const resp = await fetch(url)
            const blob = await resp.blob()
            const arrayBuf = await blob.arrayBuffer()
            if (this.retro) {
                this.retro.setSRAM(new Uint8Array(arrayBuf))
                piepie.log('[SAVE HANDLER] save file loaded successfully')
            } else {
                piepie.error(
                    `[SAVE HANDLER] could not load save file: retro is null`
                )
            }
        } catch (e) {
            piepie.error(`[SAVE HANDLER] could not load save file: ${e}`)
        }
    }

    // sendSRAM sends a savefile to firebase storage
    sendSRAM = async () => {
        const arrayBuf = this.retro.getSRAM()
        const hash = new md5(arrayBuf).toString()
        if (hash === this.SramHash) {
            return
        }
        this.SramHash = hash

        const storageRef = firebase.storage().ref()
        const savefilesRef = storageRef.child(
            `user/${this.uid}/savefiles/${this.gameName}/${this.gameName}.srm`
        )
        await this.send(savefilesRef, new Uint8Array(arrayBuf))
    }

    // send sends the data to the firebase storage reference
    send = async (
        ref: firebase.storage.Reference,
        data: ArrayBuffer | Blob | Uint8Array
    ) => {
        this.currentUpload = ref.put(data)
        await this.currentUpload
    }

    // cancelUpload cancels the current upload
    cancelUpload = () => {
        this.currentUpload?.cancel()
        piepie.log('[SAVE HANDLER] upload canceled')
    }

    // deinit
    deinit = () => {
        this.cancelUpload()
    }
}
