import React, { FC } from 'react'

import Tag, { Tags } from '../Tag'
import i18next from 'i18next'
import { LocalStorage } from '../../state/storage'
import { Game } from '../../state/starfox'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import ReadMore from '../ReadMore'

interface IGameDescription {
    game: Game
}

const GameDescription: FC<IGameDescription> = ({ game }) => {
    const { t } = useTranslation()
    const currentLanguage =
        i18next.language || window.localStorage.getItem(LocalStorage.i18nextLng)
    let lang = currentLanguage.includes('fr') ? 'FR' : 'EN'
    lang = currentLanguage.includes('es') ? 'ES' : lang
    lang = currentLanguage.includes('de') ? 'DE' : lang
    lang = currentLanguage.includes('pt') ? 'PT' : lang

    const image = () => (
        <img
            id={game.Publisher + '-logo'}
            alt={game.Publisher}
            className={style.publisherLogo}
            src={`https://fassets.piepacker.com/publisher-logos/${encodeURIComponent(
                game.Publisher
            )}.svg?organizationId=942630971205`}
        ></img>
    )

    return (
        <div className={style.gameDescription}>
            <div>
                <h2 className={style.title}>
                    {game.DisplayName} ({game.ReleaseYear})
                </h2>
            </div>
            <div className={style.cardDescription}>
                {game.id && (
                    <div className={style.coverImg}>
                        <img
                            className={style.coverImg}
                            alt={game.id}
                            src={`https://fassets.piepacker.com/cover/${encodeURIComponent(
                                game.id
                            )}.jpg?organizationId=942630971205`}
                        />
                    </div>
                )}
                <div className={style.details}>
                    <div className={style.gameInfoMetaData}>
                        {game.Publisher && (
                            <>
                                {game.PublisherLink ? (
                                    <a
                                        href={game.PublisherLink}
                                        target="__blank"
                                        rel="noopener"
                                    >
                                        {image()}
                                    </a>
                                ) : (
                                    image()
                                )}
                            </>
                        )}
                        <div className={style.gameConsole}>{game.System}</div>
                    </div>
                    <ReadMore
                        text={
                            game.Description[lang] === ''
                                ? game.Description['EN']
                                : game.Description[lang]
                        }
                        limit={150}
                        className={style.cardLongDescription}
                    />
                    {!!game.Tags?.length && (
                        <Tags>
                            {game.Tags.map((value) => {
                                return (
                                    <Tag className={style.cardTag} key={value}>
                                        {t(value).toLocaleLowerCase()}
                                    </Tag>
                                )
                            })}
                        </Tags>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GameDescription
