import * as types from './types'

export function setCountry(
    payload: types.SetCountry['payload']
): types.SetCountry {
    return {
        type: types.HomeActionType.country,
        payload,
    }
}

export function setSearch(
    payload: types.SetSearch['payload']
): types.SetSearch {
    return {
        type: types.HomeActionType.search,
        payload,
    }
}

export function setMaintenance(
    payload: types.SetMaintenance['payload']
): types.SetMaintenance {
    return {
        type: types.HomeActionType.maintenance,
        payload,
    }
}

export function setMaintenanceEnd(
    payload: types.SetMaintenanceEnd['payload']
): types.SetMaintenanceEnd {
    return {
        type: types.HomeActionType.maintenanceEnd,
        payload,
    }
}

export function setServerListReady(
    payload: types.SetServerListReady['payload']
): types.SetServerListReady {
    return {
        type: types.HomeActionType.serverListReady,
        payload,
    }
}

export function setIsGameModalOpened(
    payload: types.SetIsGameModalOpened['payload']
): types.SetIsGameModalOpened {
    return {
        type: types.HomeActionType.isGameModalOpened,
        payload,
    }
}

export function setGameModalInstance(
    payload: types.SetGameModalInstance['payload']
): types.SetGameModalInstance {
    return {
        type: types.HomeActionType.gameModalInstance,
        payload,
    }
}

export function setClosestCluster(
    payload: types.SetClosestCluster['payload']
): types.SetClosestCluster {
    return {
        type: types.HomeActionType.closestCluster,
        payload,
    }
}
