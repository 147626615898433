import React, { useState, FC } from 'react'
import cls from 'classnames'
import { useLocation } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Form, Alert } from 'react-bootstrap'
import 'firebase/auth'
import * as Axios from 'axios'
import { Formik, Field, Form as Formk } from 'formik'
import * as Yup from 'yup'
import { InputText } from '../Form/fields'
import {
    getTransactionEmailServiceHostname,
    setCookie,
    useQuery,
} from '../../utils'
import { validateNickname, validatePassword } from '../../utils/validation'
import style from './style.module.scss'
import inlineStyle from './inlineStyles'
import { openAuthModal } from '../../store/auth/actions'
import { useDispatch } from 'react-redux'
import analytics from '../../analytics'
import { CookieStorage } from '../../state/storage'
import { AuthModalType } from '../../store/auth/types'
import { logAxiosErrorResponse } from '../../utils/http'
import { setSubscribedToNewsForNewUser } from '../../firebase'

Yup.addMethod(Yup.string, 'nickname', validateNickname)
Yup.addMethod(Yup.string, 'weakPassword', validatePassword)

const SignUp: FC = () => {
    const location = useLocation()
    const urlParams = useQuery()
    const dispatch = useDispatch()
    const [apiError, setApiError] = useState('')
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const [apiSuccess, setApiSuccess] = useState('')
    const [checkUpdates, setCheckUpdates] = useState(true)
    const [privacyError, setPrivacyError] = useState(false)
    const { t } = useTranslation()

    const onSubmit = async (values: {
        email: string
        displayName: string
        password: string
    }) => {
        const { email, displayName, password } = values

        const trimmedEmail = email.trim()
        const trimmedDisplayName = displayName.replace(/  +/g, ' ').trim()

        if (!checkPrivacy) {
            setPrivacyError(true)
            return
        }

        setApiError('')
        setApiSuccess('')

        try {
            await Axios.default.post(
                `${getTransactionEmailServiceHostname()}/bodyguard`,
                {
                    email: email,
                    uid: 'signup',
                    text: trimmedDisplayName,
                }
            )
        } catch (error) {
            logAxiosErrorResponse(error)
            setApiError(error.response.data)
            return
        }

        try {
            analytics.track('Signup with Email', {
                email: trimmedEmail,
                displayName: trimmedDisplayName,
            })
            const result = await Axios.default.post(
                `${getTransactionEmailServiceHostname()}/signup`,
                {
                    email: trimmedEmail,
                    displayName: trimmedDisplayName,
                    password,
                    utm_source: urlParams.get('utm_source'),
                    utm_medium: urlParams.get('utm_medium'),
                    utm_campaign: urlParams.get('utm_campaign'),
                    utm_content: urlParams.get('utm_content'),
                    utm_term: urlParams.get('utm_term'),
                }
            )
            setApiSuccess(result.data)
            setSubscribedToNewsForNewUser(checkUpdates, email)

            try {
                window.dataLayer.push({
                    event: 'new_signup',
                    authenticationMethod: 'email',
                })
                analytics.track('Datalayer Trigger', {
                    event: 'new_signup',
                    authenticationMethod: 'email',
                    status: 'success',
                })
            } catch (error) {
                analytics.track('Datalayer Trigger', {
                    event: 'new_signup',
                    authenticationMethod: 'email',
                    status: 'failed',
                })
            }

            // The user signed up on the "Enter as a guest page". Meaning someone invited them to play.
            // Hence they expect to be redirected to the specific game page after finishing the sign up process.
            if (location.pathname.includes('/game/')) {
                // If the user confirms the email address after one hour, most likely,
                // the specific game session has ended, and they are not expecting to be redirected to that game page anymore.
                const oneHour = 3600 * 1000
                setCookie(
                    CookieStorage.intendedUrl,
                    window.location.href,
                    oneHour
                )
            }
        } catch (error) {
            logAxiosErrorResponse(error)
            setApiError(t(error.response.data))
        }
    }

    return (
        <>
            <div className={style.top}>
                <Button
                    variant="link"
                    className={cls(style.buttonLink, style.topBackArrow)}
                    onClick={() =>
                        dispatch(
                            openAuthModal({
                                modalType: AuthModalType.SignUpMenu,
                            })
                        )
                    }
                >
                    <ArrowBackIcon />
                </Button>
                <h1 className={style.heading}>{t('SignUpTitle')}</h1>
            </div>

            <Formik
                initialValues={{
                    email: '',
                    displayName: '',
                    password: '',
                    passwordConfirmation: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .trim()
                        .email(t('InvalidEmail'))
                        .required(t('Required')),
                    displayName: Yup.string()
                        .trim()
                        .required(t('Required'))
                        .min(3, t('NicknameLengthError'))
                        .max(12, t('NicknameLengthError'))
                        // @ts-ignore: Yup types are incomplete.
                        .nickname(t('DisplayNameErrorMessage')),
                    password: Yup.string()
                        .required(t('NoPasswordProvided'))
                        .min(8, t('PasswordTooShort'))
                        .max(128, t('MaxPasswordLengthError'))
                        // @ts-ignore: Yup types are incomplete.
                        .weakPassword(t('WeakPassword')),
                    passwordConfirmation: Yup.string()
                        .required(t('NoPasswordConfirmProvided'))
                        .oneOf(
                            [Yup.ref('password'), null],
                            t('PasswordsDontMatch')
                        ),
                })}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Formk className={style.form}>
                        {apiError && <Alert variant="danger">{apiError}</Alert>}
                        {apiSuccess && (
                            <Alert variant="success">{apiSuccess}</Alert>
                        )}

                        <Form.Group>
                            <Field
                                label={t('EmailAddress')}
                                placeholder={t('EmailAddress')}
                                name="email"
                                id="email"
                                component={InputText}
                                style={inlineStyle.input}
                                autoComplete={'email'}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Field
                                label={t('Nickname')}
                                placeholder={t('Nickname')}
                                name="displayName"
                                component={InputText}
                                style={inlineStyle.input}
                                autoComplete={'username'}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Field
                                name="password"
                                label={t('Password')}
                                placeholder={t('Password')}
                                type="password"
                                id="password-input"
                                component={InputText}
                                style={inlineStyle.input}
                                autoComplete={'new-password'}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Field
                                name="passwordConfirmation"
                                label={t('PasswordConfirmation')}
                                placeholder={t('PasswordConfirmation')}
                                type="password"
                                component={InputText}
                                style={inlineStyle.input}
                                autoComplete={'new-password'}
                            />
                        </Form.Group>

                        <div className={style.checkboxes}>
                            <div className={style.checkbox}>
                                <input
                                    data-test="GuestSubscriptionPrivacyCheckbox"
                                    type="checkbox"
                                    id="checkPrivacy"
                                    checked={checkPrivacy}
                                    onChange={() => {
                                        if (!checkPrivacy) {
                                            setPrivacyError(false)
                                        }

                                        setCheckPrivacy(!checkPrivacy)
                                    }}
                                />
                                <label htmlFor="checkPrivacy">
                                    <Trans i18nKey="PrivacyAgreement">
                                        I agree to Piepacker's
                                        <a
                                            className={style.link}
                                            href="https://jam.gg/pages/privacy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            privacy policy
                                        </a>
                                        and
                                        <a
                                            className={style.link}
                                            href="https://jam.gg/pages/legal"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            terms of use
                                        </a>
                                    </Trans>
                                </label>
                            </div>
                            {privacyError && (
                                <div
                                    className={style.errorMessage}
                                    data-test="GuestSubscriptionPrivacyError"
                                >
                                    {t('PrivacyCheckboxError')}
                                </div>
                            )}
                            <div className={style.checkbox}>
                                <input
                                    name="checkUpdates"
                                    type="checkbox"
                                    id="checkUpdates"
                                    checked={checkUpdates}
                                    onChange={() =>
                                        setCheckUpdates(!checkUpdates)
                                    }
                                />
                                <label
                                    htmlFor="checkUpdates"
                                    className={style.checkboxLabel}
                                >
                                    {t('GetLatestUpdates')}
                                </label>
                            </div>
                        </div>

                        <Form.Group>
                            <Button
                                className="btn-large"
                                disabled={isSubmitting || !!apiSuccess}
                                type="submit"
                                block
                                style={inlineStyle.button}
                            >
                                {t('SignUp')}
                            </Button>
                        </Form.Group>
                    </Formk>
                )}
            </Formik>
        </>
    )
}

export default SignUp
