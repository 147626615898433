import { I18nLabel } from './i18n'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// The following interfaces contain the writeable fields of models.Session and models.UserSession

// SessionFromFirebase and SessionInterface correspond to the same object,
// but due to firebase restrictions the field names are not written the same
// TODO: unify the session types (requires modifictions in starfox)
export interface SessionFromFirebase {
    ID: string
    Access?: string
    Bandicoot?: string
    CurrentGame?: string
    Banned?: string[]
    Created?: Date
    Cluster: string
    Description?: string
    IsCurrentBYOG?: boolean
    Language?: string
    NumberOfPlayers?: number
    Healthy?: boolean
    Failure?: boolean
    Originator: string
    OriginatorUserSession: string
    Started?: Date | string // this date may be returned as string when output is sent from starfox. TODO: investigate
    Stopped?: Date | string // this date may be returned as string when output is sent from starfox. TODO: investigate
    TerminationSuccessful?: boolean
    Uids: string[]
    UserSessions: string[]
    Games: GameSessionFromFirebase[]
    Mood?: SessionMoodType | ''
    RoomTitle?: string
}

export interface SessionRequestsFromFirebase {
    SessionID: string
    Requests: {
        UID: string
        Timestamp?: Date | string
    }[]
}

export interface SessionAcceptsFromFirebase {
    SessionID: string
    Originator: string
    Accepts: {
        UID: string
        Timestamp?: Date | string
    }[]
}

export interface GameSessionFromFirebase {
    BYOG?: boolean
    GameID: string
    GameFile?: string
    GameCore?: string
    Started?: Date
    Stopped?: Date
}

export interface Controls {
    A?: I18nLabel
    B?: I18nLabel
    Y?: I18nLabel
    X?: I18nLabel
    START?: I18nLabel
    SELECT?: I18nLabel
    L1?: I18nLabel
    L2?: I18nLabel
    R1?: I18nLabel
    R2?: I18nLabel
    UP?: I18nLabel
    DOWN?: I18nLabel
    LEFT?: I18nLabel
    RIGHT?: I18nLabel
    JOYSTICKLEFT?: I18nLabel
}

export interface ControlsTouchIcons {
    A?: string
    B?: string
    Y?: string
    X?: string
    START?: string
    SELECT?: string
    L1?: string
    L2?: string
    R1?: string
    R2?: string
    UP?: string
    DOWN?: string
    LEFT?: string
    RIGHT?: string
    JOYSTICKLEFT?: string
}

export interface Game {
    id: string // although not present in the DB, this field is used here
    Available: boolean
    BlendFrame: number
    Categories: string[]
    ControllerInfo: string[]
    Competition: boolean
    Core: string
    Description: any
    Disabled?: boolean
    DisableSavestates: boolean
    DisplayName: string
    ExcludedCountries?: string[]
    InsertCoin: boolean
    File: string
    GradientColor: string
    Event: string
    IsNew: boolean
    Licensed: boolean
    LocalSupported: boolean
    OnboardingDate: any
    Options: Map<string, string>
    OverscanBottom: number
    OverscanLeft: number
    OverscanRight: number
    OverscanTop: number
    Players: number
    Position: number
    Publisher: string
    PublisherLink: string
    ROM: string
    DockerImage: string
    Partner: boolean
    Rating: number
    ReleaseYear: number
    ScalingFactor: number
    SkipFrames: number
    SplitScreen: string
    SharedPad: string
    SndMono: boolean
    System: string
    Tags: string[]
    Whitelist: string[]
    Controls?: Controls
    ControlsTouchIcons?: ControlsTouchIcons
    ShortDescription: any
}

// Same here for UserSession
export interface UserSessionFromFirebase {
    IPv4: string
    IPv6?: string
    ID: string
    SessionID: string
    Joined?: Date[]
    PlayerIdx?: number
    Left?: Date[]
    UserAgent: string
    UserEmail?: string
    UID?: string
    LocalPlayers?: number
}

export interface UserInterfaceFromFirebase {
    ID: string
    User: {
        DisplayName: string
        Email: string
        EmailVerified: boolean
        Metadata: {
            CreationTime: string
            LastSignInTime: string
        }
        UID: string
        PhotoURL: string
    }
}

// UserExtraFromFirebase contains the user information not found in firebase auth.
// NOTE: this is not an exhaustive list, but rather only the fields used by NFS.
// For an exhaustive list, see https://github.com/piepacker/wagashi/blob/master/lib/models/user.go#L26
// NOTE: For future userExtra fields used in NFS, update this data structure.

export enum KickstarterBackerType {
    KSSubscription = 'KSSubscription',
    Basic = 'Basic',
    Advanced = 'Advanced',
    PiereaderAccess = 'PiereaderAccess',
    Infinite = 'Infinite',
    AllInclusive = 'AllInclusive',
    Pietrix = 'Pietrix',
    ElonMust = 'ElonMust',
    Admin = 'admin',
}

export enum UserRoleType {
    Guest = 'Guest',
    Regular = 'Regular',
    Plus = 'Plus',
    Piepacker = 'Piepacker',
}

export interface BadgeInterface {
    ID: string
    ImageUrl: string
    Name: string
}

export enum AllType {
    All = 'All',
}

export enum NicknameColorType {
    None = '',
    Gold = 'Gold',
}

export enum PreferredGameType {
    Adventure = 'Adventure',
    MMORPG = 'MMORPG',
    Platformer = 'Platformer',
    Fighting = 'Fighting',
    Racing = 'Racing',
    FPS = 'FPS',
    RPG = 'RPG',
    Sports = 'Sports',
}

export enum SessionMoodType {
    Social = 'Social',
    Hermits = 'Hermits',
    Discovery = 'Discovery',
    Competitive = 'Competitive',
}

export interface SessionMoodObjectType {
    label: SessionMoodType
    icon: string
    order: number
}

export const MoodSocial: SessionMoodObjectType = {
    label: SessionMoodType.Social,
    icon: 'social',
    order: 1,
}

export const MoodHermits: SessionMoodObjectType = {
    label: SessionMoodType.Hermits,
    icon: 'hermits',
    order: 2,
}

export const MoodDiscovery: SessionMoodObjectType = {
    label: SessionMoodType.Discovery,
    icon: 'discovery',
    order: 3,
}

export const MoodCompetitive: SessionMoodObjectType = {
    label: SessionMoodType.Competitive,
    icon: 'competitive',
    order: 4,
}

export const SessionMoods = [
    MoodSocial,
    MoodHermits,
    MoodDiscovery,
    MoodCompetitive,
]

export const getSortedSessionMoods = (): SessionMoodType[] => {
    SessionMoods.sort((a, b) => (a.order > b.order ? 1 : -1))
    const moods: SessionMoodType[] = []
    for (const mood of SessionMoods) {
        moods.push(mood.label)
    }
    return moods
}

export const getSessionMoodByLabel = (
    label: SessionMoodType
): SessionMoodObjectType => {
    for (const mood of SessionMoods) {
        if (mood.label === label) return mood
    }
    return null
}

export interface FeaturesInterface {
    BYOG?: boolean
    REWIND?: boolean
    UNLIMITEDSAVESTATES?: boolean
    HDGRAPHICS?: boolean
}

export interface FirestoreDateRangeInterface {
    StartDate: firebase.firestore.Timestamp
    EndDate: firebase.firestore.Timestamp
}

export interface UserFeaturesInterface {
    [featureName: string]: {
        ID: string
        Validity: Array<FirestoreDateRangeInterface>
    }
}

export type MaskType = KickstarterBackerType | UserRoleType | AllType

export interface UserExtraFromFirebase {
    ID: string
    Whitelisted: boolean
    PreviewWhitelisted: boolean
    Language: string
    KickstarterBacker?: KickstarterBackerType
    SubscribedToNews?: boolean
    Events: string[]
    Role: UserRoleType
    BadgeList?: BadgeInterface[]
    PreferredCluster?: string
    ColorList?: NicknameColorType[]
    CurrentBadge?: BadgeInterface
    NicknameColor: NicknameColorType
    Masks: string[]
    ESLGuestPlayer: boolean
    LowPerf?: boolean
    Features?: UserFeaturesInterface
    PreferredGames?: PreferredGameType[]
    Birthday?: any
    Keyboard?: string
    TwitchLinked?: boolean
}
