import { AlertType } from '../state/alert'
import { GameSessionFromFirebase } from '../state/starfox'
import store from '../store'
import { isEnvDev, piepie } from '../utils'
import { db } from './'
import alertSlice from '../store/alert/alert'

type TSelectedGames = Pick<GameSessionFromFirebase, 'GameID' | 'Stopped'>

// selectedGames returns the game ids played by the user with uid over the last seven days,
// order by latest played
export async function selectedGames(uid: string): Promise<TSelectedGames[]> {
    const dayMillis = 24 * 60 * 60 * 1000

    try {
        // get the sessions played by the user, filtering out any sessions older than 7 days
        const sessions: TSelectedGames[] = []
        const snapshot = await db
            .collection('sessions')
            .where('Uids', 'array-contains', uid)
            .orderBy('Created', 'desc')
            .where('Created', '>=', new Date(Date.now() - 7 * dayMillis))
            .get()
        snapshot.forEach((doc) => {
            const games = doc.data().Games as GameSessionFromFirebase[]
            if (games !== null && typeof games !== 'undefined') {
                for (const game of Object.values(games)) {
                    sessions.push({
                        GameID: game.GameID,
                        Stopped: game.Stopped,
                    })
                }
            }
        })
        const uniqueSession = sessions.filter(
            (value, index, self) =>
                index === self.findIndex((game) => game.GameID === value.GameID)
        )
        return uniqueSession
    } catch (error) {
        piepie.error(error)
        store.dispatch(
            alertSlice.actions.push({
                type: AlertType.Error,
                message: error.message,
                autoClose: false,
            })
        )
    }
    return
}

const getCollectionsNameBasedOnEnv = () => {
    if (isEnvDev) {
        return `games-${process.env.REACT_APP_DEVNAME}`
    }
    return 'games'
}

export async function getGame(gameId: string) {
    const doc = await db
        .collection(getCollectionsNameBasedOnEnv())
        .doc(gameId)
        .get()

    return doc
}

export async function getAllGames() {
    const snapshot = await db
        .collection(getCollectionsNameBasedOnEnv())
        .orderBy('Position')
        .get()

    return snapshot
}
